import { useState, useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'

/** Renders a scalable height input group with input control, character limit and corresponding Feedback for handling invalid inputs
* @param {string} value is a value that can be passed to the input
* @param {string} className classes to pass to the Form.Group
* @param {string} name of the input box
* @param {function} inputValue takes value onChange
* @param {boolean} disabled property to control the state of the Form.Control
* @param {boolean} isInvalid to inform about the validity of the entry
* @param {boolean} isValid to inform about the validity of the entry
* @param {string} errorText to set the feedback text if isInvalid is true
* @param {boolean} isCounterHidden to hide the "remaining chars" text if true
* @param {int} textBoxLimit controls how many chars can be typed in the textarea
* @param {boolean} isResizeable controls if the textarea can be resized
* @param {string} counterText text to display with the characters left
* @param {string} height textarea height in pixels 
*/
export default function InputTextArea({ value, className, name, inputValue, disabled, isInvalid, isValid, errorText, isCounterHidden, textBoxLimit, isResizeable = false, counterText, height = "100px" }) {
    const [inputText, setInputText] = useState("")
    const [counter, setCounter] = useState(textBoxLimit)
    const heightRef = useRef()

    useEffect(() => {
        setInputText(value)
        setCounter(textBoxLimit - value.length)
        if (heightRef.current?.style) heightRef.current.style.height = height
    }, [value, textBoxLimit, height])

    function handleChange(e) {
        let receivedInput = e.target.value

        if (!isCounterHidden && receivedInput.length <= textBoxLimit) {
            setCounter(textBoxLimit - receivedInput.length)
            setInputText(receivedInput)
            inputValue(receivedInput)
        } else if (isCounterHidden) {
            setInputText(receivedInput)
            inputValue(receivedInput)
        }
    }

    return (
        <Form.Group className={className + ' position-relative'}>
            <Form.Control ref={heightRef} as="textarea" className={(!isResizeable) ? 'disable-resize shadow-none' : 'shadow-none'} disabled={disabled} isInvalid={isInvalid} isValid={isValid} name={name} value={inputText} onChange={(e) => { handleChange(e) }} />
            <Form.Control.Feedback type="invalid" tooltip>
                {errorText}
            </Form.Control.Feedback>
            {(!isCounterHidden && !disabled) && <div className="text-muted float-end position-absolute charleft-pos"> {counterText} {counter} </div>}
        </Form.Group>
    )
}