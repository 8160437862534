import RangeGroup from '../../../../components/shared/rangegroup/RangeGroup.js'
import {optionalValidationTypes, toastTypes} from '../../../../helpers/constants.js'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import SwitchGroup from "../../../shared/switchgroup/SwitchGroup.js";
import {Button} from "react-bootstrap";
import InputGroupWithMessages from "../../../shared/inputgroupwithmessages/InputGroupWithMessages.js";
import ComboBoxWithMessages from "../../../shared/comboboxwithmessages/ComboBoxWithMessages.js";
import {
    addParameterIfChanged,
    getParameterToShow,
    updateFieldIfChanged,
    validateParameter
} from "../../../../helpers/settingsHelper.js";
import {InfoCircleFill} from 'react-bootstrap-icons';
import {cloneDeep} from "lodash";
import PopOverDialog from "../../../shared/popoverdialog/PopOverDialog.js";
import {paramNames} from "../../../parameters/parameterSpec.js";
import {addToast} from "../../../../helpers/reduxstore/reducers/toastReducer.js";
import {useDispatch} from "react-redux";
import {isBasic} from "../../../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";
import SettingsSubHeader from "../../settingscomponents/SettingsSubHeader.js";

/** Displays the configuration page for setpoints settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device)
 */
export default function JESetpointsSettings({device, className, save}) {
    const {t} = useTranslation(['settingspage', 'common'])
    const dispatch = useDispatch()
    const {accounts} = useMsal();

    const [oldDeviceState, setOldDeviceState] = useState(cloneDeep(device))

    const [airflowControlLimitMin, setAirflowControlLimitMin] = useState(getParameterToShow(device, paramNames.minimum_flow_percent, t))
    const [airflowControlLimitMax, setAirflowControlLimitMax] = useState(getParameterToShow(device, paramNames.maximum_flow_percent, t))
    const [highRoomControlLimitMin, setHighRoomControlLimitMin] = useState(getParameterToShow(device, paramNames.high_room_temperature_low_limit, t))
    const [highRoomControlLimitMax, setHighRoomControlLimitMax] = useState(getParameterToShow(device, paramNames.high_room_temperature_high_limit, t))
    const [temperatureSetpoint, setTemperatureSetpoint] = useState(getParameterToShow(device, paramNames.default_temperature, t))
    const [airflowSetpoint, setAirflowSetpoint] = useState(getParameterToShow(device, paramNames.default_airflow, t))
    const [allowBoost, setAllowBoost] = useState(getParameterToShow(device, paramNames.allow_boost, t))
    const [pirAfterRunTime, setPirAfterRunTime] = useState(getParameterToShow(device, paramNames.pir_afterrun_time, t))
    const [co2ControlMode, setCo2ControlMode] = useState(getParameterToShow(device, paramNames.co2_control_mode, t))
    const [co2ControlLimitMin, setCo2ControlLimitMin] = useState(getParameterToShow(device, paramNames.co2_min, t))
    const [co2ControlLimitMax, setCo2ControlLimitMax] = useState(getParameterToShow(device, paramNames.co2_max, t))
    const [tvocControlMode, setTvocControlMode] = useState(getParameterToShow(device, paramNames.tvoc_control_mode, t))
    const [tvocControlLimitMin, setTvocControlLimitMin] = useState(getParameterToShow(device, paramNames.r_tvocmin, t))
    const [tvocControlLimitMax, setTvocControlLimitMax] = useState(getParameterToShow(device, paramNames.r_tvocmax, t))
    const [roomVolume, setRoomVolume] = useState(getParameterToShow(device, paramNames.room_volume, t))
    const [roomAdaptiveAirflow, setRoomAdaptiveAirflow] = useState(getParameterToShow(device, paramNames.adaptive_airflow_throw_setpoint, t))

    useEffect(() => {
        // When device is updated after write, update parameters to show / hide messages
        updateFieldIfChanged(setAirflowControlLimitMin, oldDeviceState, device, paramNames.minimum_flow_percent, t)
        updateFieldIfChanged(setAirflowControlLimitMax, oldDeviceState, device, paramNames.maximum_flow_percent, t)
        updateFieldIfChanged(setHighRoomControlLimitMin, oldDeviceState, device, paramNames.high_room_temperature_low_limit, t)
        updateFieldIfChanged(setHighRoomControlLimitMax, oldDeviceState, device, paramNames.high_room_temperature_high_limit, t)
        updateFieldIfChanged(setTemperatureSetpoint, oldDeviceState, device, paramNames.default_temperature, t)
        updateFieldIfChanged(setAirflowSetpoint, oldDeviceState, device, paramNames.default_airflow, t)
        updateFieldIfChanged(setAllowBoost, oldDeviceState, device, paramNames.allow_boost, t)
        updateFieldIfChanged(setPirAfterRunTime, oldDeviceState, device, paramNames.pir_afterrun_time, t)
        updateFieldIfChanged(setCo2ControlMode, oldDeviceState, device, paramNames.co2_control_mode, t)
        updateFieldIfChanged(setCo2ControlLimitMin, oldDeviceState, device, paramNames.co2_min, t)
        updateFieldIfChanged(setCo2ControlLimitMax, oldDeviceState, device, paramNames.co2_max, t)
        updateFieldIfChanged(setTvocControlMode, oldDeviceState, device, paramNames.tvoc_control_mode, t)
        updateFieldIfChanged(setTvocControlLimitMin, oldDeviceState, device, paramNames.r_tvocmin, t)
        updateFieldIfChanged(setTvocControlLimitMax, oldDeviceState, device, paramNames.r_tvocmax, t)
        updateFieldIfChanged(setRoomVolume, oldDeviceState, device, paramNames.room_volume, t)
        updateFieldIfChanged(setRoomAdaptiveAirflow, oldDeviceState, device, paramNames.adaptive_airflow_throw_setpoint, t)

        setOldDeviceState(cloneDeep(device))
    }, [device])

    /**
     * validates parameters. Adds messages to viewmodels if fail. returns true if all is validated, otherwise false.
     * @returns {boolean}
     */
    const validateParameters = () => {
        let validatedList = [true]
        validatedList.push(validateParameter(paramNames.minimum_flow_percent, airflowControlLimitMin, setAirflowControlLimitMin, t,
            [{type: optionalValidationTypes.leftLessThanRight, rightModel: airflowControlLimitMax}]))
        validatedList.push(validateParameter(paramNames.maximum_flow_percent, airflowControlLimitMax, setAirflowControlLimitMax, t))
        validatedList.push(validateParameter(paramNames.high_room_temperature_low_limit, highRoomControlLimitMin, setHighRoomControlLimitMin, t,
            [{type: optionalValidationTypes.leftLessThanRight, rightModel: highRoomControlLimitMax}]))
        validatedList.push(validateParameter(paramNames.high_room_temperature_high_limit, highRoomControlLimitMax, setHighRoomControlLimitMax, t))
        validatedList.push(validateParameter(paramNames.default_temperature, temperatureSetpoint, setTemperatureSetpoint, t))
        validatedList.push(validateParameter(paramNames.default_airflow, airflowSetpoint, setAirflowSetpoint, t))
        validatedList.push(validateParameter(paramNames.allow_boost, allowBoost, setAllowBoost, t))
        validatedList.push(validateParameter(paramNames.pir_afterrun_time, pirAfterRunTime, setPirAfterRunTime, t))
        validatedList.push(validateParameter(paramNames.co2_control_mode, co2ControlMode, setCo2ControlMode, t))
        validatedList.push(validateParameter(paramNames.co2_min, co2ControlLimitMin, setCo2ControlLimitMin, t,
            [{type: optionalValidationTypes.leftLessThanRight, rightModel: co2ControlLimitMax}]))
        validatedList.push(validateParameter(paramNames.co2_max, co2ControlLimitMax, setCo2ControlLimitMax, t))
        validatedList.push(validateParameter(paramNames.tvoc_control_mode, tvocControlMode, setTvocControlMode, t))
        validatedList.push(validateParameter(paramNames.r_tvocmin, tvocControlLimitMin, setTvocControlLimitMin, t,
            [{type: optionalValidationTypes.leftLessThanRight, rightModel: tvocControlLimitMax}]))
        validatedList.push(validateParameter(paramNames.r_tvocmax, tvocControlLimitMax, setTvocControlLimitMax, t))
        validatedList.push(validateParameter(paramNames.room_volume, roomVolume, setRoomVolume, t))
        validatedList.push(validateParameter(paramNames.adaptive_airflow_throw_setpoint, roomAdaptiveAirflow, setRoomAdaptiveAirflow, t))
        return validatedList.every(v => v)
    }

    const getValuesToSave = () => {
        const updated_parameters = {}
        addParameterIfChanged(updated_parameters, paramNames.minimum_flow_percent, airflowControlLimitMin.value, device)
        addParameterIfChanged(updated_parameters, paramNames.maximum_flow_percent, airflowControlLimitMax.value, device)
        addParameterIfChanged(updated_parameters, paramNames.high_room_temperature_low_limit, highRoomControlLimitMin.value, device)
        addParameterIfChanged(updated_parameters, paramNames.high_room_temperature_high_limit, highRoomControlLimitMax.value, device)
        addParameterIfChanged(updated_parameters, paramNames.default_temperature, temperatureSetpoint.value, device)
        addParameterIfChanged(updated_parameters, paramNames.default_airflow, airflowSetpoint.value, device)
        addParameterIfChanged(updated_parameters, paramNames.allow_boost, allowBoost.value, device)
        addParameterIfChanged(updated_parameters, paramNames.pir_afterrun_time, pirAfterRunTime.value, device)
        addParameterIfChanged(updated_parameters, paramNames.co2_control_mode, co2ControlMode.value, device)
        addParameterIfChanged(updated_parameters, paramNames.co2_min, co2ControlLimitMin.value, device)
        addParameterIfChanged(updated_parameters, paramNames.co2_max, co2ControlLimitMax.value, device)
        addParameterIfChanged(updated_parameters, paramNames.tvoc_control_mode, tvocControlMode.value, device)
        addParameterIfChanged(updated_parameters, paramNames.r_tvocmin, tvocControlLimitMin.value, device)
        addParameterIfChanged(updated_parameters, paramNames.r_tvocmax, tvocControlLimitMax.value, device)
        addParameterIfChanged(updated_parameters, paramNames.room_volume, roomVolume.value, device)
        addParameterIfChanged(updated_parameters, paramNames.adaptive_airflow_throw_setpoint, roomAdaptiveAirflow.value, device)
        console.log(updated_parameters)
        return updated_parameters
    }

    function saveIfValidated(saveForGroup) {
        if (!validateParameters()) return

        let valuesToSave = getValuesToSave();
        if (Object.keys(valuesToSave).length === 0) {
            dispatch(addToast({
                id: "SetpointsSettingsInfoToast",
                type: toastTypes.info,
                title: "No changes",
                body: "No changes to parameters",
                timeSeconds: 3
            }))
            return
        }
        save(valuesToSave, saveForGroup);
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation title={t('settingspage:setpoints_settings.title')}
                                        subTitle={t('settingspage:setpoints_settings.description')}/>

            <SettingsSubHeader>{t('settingspage:setpoints_settings.environment_settings')}</SettingsSubHeader>
            <div className="d-flex flex-wrap justify-content-xs-center">
                <div className='w-300px'>
                    <RangeGroup
                        label={t('settingspage:parameters.airflow_control_limits')}
                        className='p-4 m-auto'
                        unit='%'
                        leftValue={airflowControlLimitMin.value}
                        leftOnChange={(e) => setAirflowControlLimitMin({value: e.target.value, messages: []})}
                        leftPlaceholder='Min %'
                        leftMessages={airflowControlLimitMin.messages}
                        rightValue={airflowControlLimitMax.value}
                        rightOnChange={(e) => setAirflowControlLimitMax({value: e.target.value, messages: []})}
                        rightPlaceholder='Max %'
                        rightMessages={airflowControlLimitMax.messages}
                        type='number'
                        disabled={device.slave}
                        infoelement={<PopOverDialog
                            buttonPosition='position-absolute'
                            popOverTitel={t('settingspage:parameters.airflow_control_limits')}
                            popOverContent={t('settingspage:infomodals.airflow_control_limits')}
                            trigger={['hover', 'touch']}
                            contentAsHtml={true}/>}
                    />
                </div>
                {!isBasic(accounts) &&
                    <div className='w-300px'>
                        <RangeGroup
                            label={t('settingspage:parameters.high_room_temp_control_limits')}
                            className='p-4 m-auto'
                            unit='°C'
                            leftValue={highRoomControlLimitMin.value}
                            leftOnChange={(e) => setHighRoomControlLimitMin({value: e.target.value})}
                            leftMessages={highRoomControlLimitMin.messages}
                            rightValue={highRoomControlLimitMax.value}
                            rightOnChange={(e) => setHighRoomControlLimitMax({value: e.target.value})}
                            rightMessages={highRoomControlLimitMax.messages}
                            type='number'
                            disabled={device.slave}
                            infoelement={<PopOverDialog
                                popOverTitel={t('settingspage:parameters.high_room_temp_control_limits')}
                                popOverContent={t('settingspage:infomodals.high_room_temp_control_limits')}
                                trigger={['hover', 'touch']} style={{padding: "0.25rem 0"}}
                                contentAsHtml={true}/>}
                        />
                    </div>
                }
                <div className='w-300px'>
                    <InputGroupWithMessages
                        label={t('settingspage:parameters.temperature_setpoint')}
                        className='p-4 m-auto'
                        value={temperatureSetpoint.value}
                        unit='°C'
                        disabled={device.slave}
                        inputValue={(e) => setTemperatureSetpoint({value: e.target.value})}
                        placeholder={t('common:units.degrees')}
                        messages={temperatureSetpoint.messages}
                        type='number'
                        infoelement={<PopOverDialog
                            popOverTitel={t('settingspage:parameters.temperature_setpoint')}
                            popOverContent={t('settingspage:infomodals.temperature_setpoint')}
                            trigger={['hover', 'touch']}
                            contentAsHtml={true}/>}
                    />
                </div>
                <div className='w-300px'>
                    <InputGroupWithMessages
                        label={t('settingspage:parameters.airflow_setpoint')}
                        className='p-4 m-auto'
                        value={airflowSetpoint.value}
                        unit='%'
                        disabled={device.slave}
                        inputValue={(e) => setAirflowSetpoint({value: e.target.value})}
                        placeholder={t('common:units.percentage')}
                        messages={airflowSetpoint.messages}
                        min={0}
                        max={100}
                        type='number'
                        infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.airflow_setpoint')}
                                                    popOverContent={t('settingspage:infomodals.airflow_setpoint')}
                                                    trigger={['hover', 'touch']}
                                                    contentAsHtml={true}/>}
                    />
                </div>
                <div className='w-300px'>
                    <SwitchGroup
                        label={t('settingspage:parameters.allow_boost')}
                        className='p-4 m-auto'
                        checked={allowBoost.value === "1"}
                        disabled={device.slave}
                        onChange={(e) => setAllowBoost({value: e.target.checked ? "1" : "0"})}
                        messages={allowBoost.messages}
                        infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.allow_boost')}
                                                    popOverContent={t('settingspage:infomodals.allow_boost')}
                                                    trigger={['hover', 'touch']}
                                                    contentAsHtml={true}/>}
                    />
                </div>
                {!isBasic(accounts) &&
                    <div className='w-300px'>
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.pir_after_run_time')}
                            className='p-4 m-auto'
                            value={pirAfterRunTime.value}
                            unit={t('common:units.minutes')}
                            inputValue={(e) => setPirAfterRunTime({value: e.target.value})}
                            disabled={device.slave}
                            placeholder={t('common:units.minutes')}
                            messages={pirAfterRunTime.messages}
                            type='number'
                            infoelement={<PopOverDialog
                                popOverTitel={t('settingspage:parameters.pir_after_run_time')}
                                popOverContent={t('settingspage:infomodals.pir_after_run_time')}
                                trigger={['hover', 'touch']}
                                contentAsHtml={true}/>}
                        />
                    </div>
                }
            </div>

            {device.installed_components.co2_sensor &&
                <div className={'p-2 w-100'}>
                    <SettingsSubHeader>{t('settingspage:setpoints_settings.co2_sensor_settings')}</SettingsSubHeader>
                    <div className="d-flex flex-wrap justify-content-xs-center">
                        {!isBasic(accounts) &&
                            <div className='w-300px'>
                                <ComboBoxWithMessages
                                    className='p-4 m-auto'
                                    label={t('settingspage:parameters.co2_control_mode')}
                                    inputValue={(e) => setCo2ControlMode({value: e.target.value})}
                                    value={co2ControlMode.value}
                                    disabled={device.slave}
                                    options={[
                                        {
                                            id: 0,
                                            option: t('settingspage:filter_settings.control_mode_options.no')
                                        },
                                        {
                                            id: 1,
                                            option: t('settingspage:filter_settings.control_mode_options.level')
                                        },
                                        {
                                            id: 2,
                                            option: t('settingspage:filter_settings.control_mode_options.rate_of_change')
                                        },
                                        {
                                            id: 3,
                                            option: t('settingspage:filter_settings.control_mode_options.combined')
                                        }
                                    ]}
                                    messages={co2ControlMode.messages}
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.co2_control_mode')}
                                        popOverContent={t('settingspage:infomodals.co2_control_mode')}
                                        trigger={['hover', 'touch']} size={'lg'} contentAsHtml={true}/>}
                                />
                            </div>
                        }
                        <div className='w-300px'>
                            <RangeGroup
                                label={t('settingspage:parameters.co2_control_limit')}
                                className='p-4 m-auto'
                                unit='ppm'
                                leftValue={co2ControlLimitMin.value}
                                leftOnChange={(e) => setCo2ControlLimitMin({value: e.target.value})}
                                leftPlaceholder={'Min ' + t('common:general.value')}
                                leftMessages={co2ControlLimitMin.messages}
                                rightValue={co2ControlLimitMax.value}
                                rightOnChange={(e) => setCo2ControlLimitMax({value: e.target.value})}
                                rightPlaceholder={'Max ' + t('common:general.value')}
                                rightMessages={co2ControlLimitMax.messages}
                                type='number'
                            />
                        </div>
                    </div>
                </div>
            }
            {device.installed_components.tvoc_sensor &&
                <div className={'p-2 w-100'}>
                    <SettingsSubHeader>{t('settingspage:setpoints_settings.tvoc_sensor_settings')}</SettingsSubHeader>
                    <div className="d-flex flex-wrap justify-content-xs-center">
                        <div className='w-300px'>
                            <ComboBoxWithMessages
                                className='p-4 m-auto'
                                label={t('settingspage:parameters.tvoc_control_mode')}
                                inputValue={(e) => setTvocControlMode({value: e.target.value})}
                                value={tvocControlMode.value}
                                disabled={device.slave}
                                options={[
                                    {id: 0, option: t('settingspage:filter_settings.control_mode_options.no')},
                                    {
                                        id: 1,
                                        option: t('settingspage:filter_settings.control_mode_options.level')
                                    },
                                    {
                                        id: 2,
                                        option: t('settingspage:filter_settings.control_mode_options.rate_of_change')
                                    },
                                    {
                                        id: 3,
                                        option: t('settingspage:filter_settings.control_mode_options.combined')
                                    }
                                ]}
                                messages={tvocControlMode.messages}
                                infoelement={<PopOverDialog
                                    popOverTitel={t('settingspage:parameters.tvoc_control_mode')}
                                    popOverContent={t('settingspage:infomodals.tvoc_control_mode')}
                                    trigger={['hover', 'touch']} size={'lg'} contentAsHtml={true}/>}
                            />
                        </div>
                        <div className='w-300px'>
                            <RangeGroup
                                label={t('settingspage:parameters.tvoc_control_limit')}
                                className='p-4 m-auto'
                                unit='ppb'
                                leftValue={tvocControlLimitMin.value}
                                leftOnChange={(e) => setTvocControlLimitMin({value: e.target.value})}
                                leftPlaceholder={'Min ' + t('common:general.value')}
                                leftMessages={tvocControlLimitMin.messages}
                                rightValue={tvocControlLimitMax.value}
                                rightOnChange={(e) => setTvocControlLimitMax({value: e.target.value})}
                                rightPlaceholder={'Max ' + t('common:general.value')}
                                rightMessages={tvocControlLimitMax.messages}
                                type='number'
                            />
                        </div>
                    </div>
                </div>
            }
            {(device.installed_components.co2_sensor || device.installed_components.tvoc_sensor || device.device_twin.reported[paramNames.ao_7] === "28") && !isBasic(accounts) &&
                <div className={'p-2 w-100'}>
                    <SettingsSubHeader>{t('settingspage:setpoints_settings.room_settings')}</SettingsSubHeader>
                    <div className="d-flex flex-wrap justify-content-xs-center">
                        {device.device_twin.reported[paramNames.ao_7] === "28" &&
                            <div className='w-300px'>
                                <InputGroupWithMessages
                                    label={t('settingspage:parameters.room_adaptive_airflow_setpoint')}
                                    className='p-4 m-auto'
                                    value={roomAdaptiveAirflow.value}
                                    unit={t('common:units.meters')}
                                    inputValue={(e) => setRoomAdaptiveAirflow({value: e.target.value})}
                                    messages={roomAdaptiveAirflow.messages}
                                    type='number'
                                    step={0.1}
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.room_adaptive_airflow_setpoint')}
                                        popOverContent={t('settingspage:infomodals.room_adaptive_airflow_setpoint')}
                                        trigger={['hover', 'touch']}
                                        contentAsHtml={true}/>}
                                />
                            </div>
                        }
                        {(device.installed_components.co2_sensor || device.installed_components.tvoc_sensor) &&
                            <div className='w-300px'>
                                <InputGroupWithMessages
                                    label={t('settingspage:parameters.room_volume')}
                                    className='p-4 m-auto'
                                    value={roomVolume.value}
                                    unit='m<sup>3</sup>'
                                    inputValue={(e) => setRoomVolume({value: e.target.value})}
                                    messages={roomVolume.messages}
                                    type='number'
                                    step={0.1}
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.room_volume')}
                                        popOverContent={t('settingspage:infomodals.room_volume')}
                                        trigger={['hover', 'touch']}
                                        contentAsHtml={true}/>}
                                />
                            </div>
                        }
                    </div>
                </div>
            }

            <div className="alert alert-info" hidden={!device.slave}>
                <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:slave-device-settings')}
                {device.master_serial_number &&
                    <a href={"/device/" + device.master_serial_number + "/configurations/setpoints"}>{device.master_serial_number}</a>}
            </div>

            {/*=== Button container*/}
            <div className='d-inline-block float-end p-2'>
                <Button variant='outline-secondary' disabled={device.slave} onClick={() => saveIfValidated(false)}
                        className='me-2'>
                    {t('settingspage:save_device')}</Button>
                <Button variant='outline-secondary' disabled={device.slave} onClick={() => saveIfValidated(true)}>
                    {t('settingspage:save_group')}</Button>
            </div>
        </div>
    )
}