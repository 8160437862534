import {useTranslation} from 'react-i18next'
import {Funnel} from 'react-bootstrap-icons'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import ChartSelectorDropdownItem from './dropdownitem/ChartSelectorDropdownItem.js'
import {cloneDeep} from "lodash";

/** Dropdown selector with graph options
 * @param {string} className classes to wrap the component
 * @param {object[]} dropdownOptions list of options that can be viewed in the dropdown
 * @param {string[]} deviceSelections gets the graphs currently selected in the dropdown
 * @param {function} setDeviceSelections sets the graphs currently selected in the dropdown
 */
export default function TelemetryChartsDeviceSelector({
                                                          className, dropdownOptions,
                                                          deviceSelections, setDeviceSelections
                                                      }) {
    const {t} = useTranslation(['dashboard'])

    const addDeviceSelection = () => {
        setDeviceSelections([...deviceSelections, "New"])
    }

    const removeDeviceSelection = (idx) => {
        const clone = cloneDeep(deviceSelections)
        clone.splice(idx, 1)
        setDeviceSelections(clone)
    }

    const changeDeviceSelection = ({idx, value}) => {
        const clone = cloneDeep(deviceSelections)
        clone[idx] = value
        setDeviceSelections(clone)
    }

    return (
        <div className={className}>
            <Dropdown className="text-end d-inline-block col-6">
                <Dropdown.Toggle type="button" id="dropdown-basic" variant='outline-secondary'>
                    <Funnel className="me-1"/>{t('dashboard:dropDownDeviceSelector.toggle_text')}
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2 px-3 shadow " align="right" id="dropdown-menu-align-right"
                               style={{width: "700px"}}>

                    <span className=" text-bold col-12 py-2 d-block">
                        {t('dashboard:dropDownDeviceSelector.title')}
                    </span>

                    {deviceSelections.map((serialNr, idx) => {
                        let getSortedDropDownOptions = () => {
                            // .toSorted is too new for some users
                            dropdownOptions.sort((a, b) => a.serial_number.localeCompare(b.serial_number))
                            return dropdownOptions
                        }
                        return <ChartSelectorDropdownItem
                            key={`${serialNr}_${idx}`} idx={idx} onChange={changeDeviceSelection}
                            removeSelection={removeDeviceSelection} id={serialNr}
                            options={getSortedDropDownOptions()
                                .map(device => ({
                                    id: device.serial_number,
                                    option: `${device.serial_number}: ${device.name}`
                                }))}
                            isReadOnly={idx === 0} value={serialNr}
                            placeholder={t('dashboard:dropDownDeviceSelector.placeholder')} className="my-2"
                        />;
                    })}

                    <Button className="w-100 mt-2" onClick={addDeviceSelection}
                            variant='outline-secondary' type="submit">
                        {t('dashboard:dropDownDeviceSelector.submit_button')}
                    </Button>

                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}