// noinspection EqualityComparisonWithCoercionJS

import {awareParamNames} from "../../../parameters/awareParamSpec.js";
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from "react-bootstrap";
import ComboBoxWithMessages from "../../../shared/comboboxwithmessages/ComboBoxWithMessages.js";
import {addParameterIfChanged} from "../../../../helpers/awareSettingsHelper.js";
import {getParameterToShow, updateFieldIfChanged} from "../../../../helpers/settingsHelper.js";
import {cloneDeep} from "lodash";
import PopOverDialog from "../../../shared/popoverdialog/PopOverDialog.js";
import {addToast} from "../../../../helpers/reduxstore/reducers/toastReducer.js";
import {toastTypes} from "../../../../helpers/constants.js";
import {useDispatch} from "react-redux";
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";

/** Displays the configuration page for setpoints settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device)
 */
export default function AwareStartPrioritySettings({device, className, save}) {
    const {t} = useTranslation(['settingspage', 'common'])
    const dispatch = useDispatch()

    const [oldDeviceState, setOldDeviceState] = useState(cloneDeep(device))
    const [priority1, setPriority1] = useState(getParameterToShow(device, awareParamNames.start_priority_1, t))
    const [priority2, setPriority2] = useState(getParameterToShow(device, awareParamNames.start_priority_2, t))
    const [priority3, setPriority3] = useState(getParameterToShow(device, awareParamNames.start_priority_3, t))
    const [priority4, setPriority4] = useState(getParameterToShow(device, awareParamNames.start_priority_4, t))
    const [priority5, setPriority5] = useState(getParameterToShow(device, awareParamNames.start_priority_5, t))
    const [priority6, setPriority6] = useState(getParameterToShow(device, awareParamNames.start_priority_6, t))

    const [startDependency, setStartDependency] = useState(getParameterToShow(device, awareParamNames.start_priority_dependency, t))
    // const [nightCoolIndependent, setNightCoolIndependent] = useState(getParameterToShow(device, awareParamNames.start_priority_night_cooling_independent, t))

    useEffect(() => {
        // When device is updated after write, update parameters to show / hide messages
        updateFieldIfChanged(setPriority1, oldDeviceState, device, awareParamNames.start_priority_1, t)
        updateFieldIfChanged(setPriority2, oldDeviceState, device, awareParamNames.start_priority_2, t)
        updateFieldIfChanged(setPriority3, oldDeviceState, device, awareParamNames.start_priority_3, t)
        updateFieldIfChanged(setPriority4, oldDeviceState, device, awareParamNames.start_priority_4, t)
        updateFieldIfChanged(setPriority5, oldDeviceState, device, awareParamNames.start_priority_5, t)
        updateFieldIfChanged(setPriority6, oldDeviceState, device, awareParamNames.start_priority_6, t)
        updateFieldIfChanged(setStartDependency, oldDeviceState, device, awareParamNames.start_priority_dependency, t)
        // updateFieldIfChanged(setNightCoolIndependent, oldDeviceState, device, awareParamNames.start_priority_night_cooling_independent, t)
        setOldDeviceState(cloneDeep(device))
    }, [device])


    const getValuesToSave = () => {
        const updated_parameters = {}
        addParameterIfChanged(updated_parameters, awareParamNames.start_priority_1, priority1.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.start_priority_2, priority2.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.start_priority_3, priority3.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.start_priority_4, priority4.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.start_priority_5, priority5.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.start_priority_6, priority6.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.start_priority_dependency, startDependency.value == 1 ? true : false, device)
        // addParameterIfChanged(updated_parameters, awareParamNames.start_priority_night_cooling_independent, nightCoolIndependent.value == 1 ? true : false, device)
        console.log(updated_parameters)
        return updated_parameters
    }

    function saveIfChanged(saveForGroup) {
        let valuesToSave = getValuesToSave();
        if (Object.keys(valuesToSave).length === 0) {
            dispatch(addToast({
                id: "StartPrioritySettingsInfoToast",
                type: toastTypes.info,
                title: "No changes",
                body: "No changes to parameters",
                timeSeconds: 3
            }))
            return
        }
        save(valuesToSave, saveForGroup);
    }


    let startPriorityOptions = [
        {id: 0, option: t('settingspage:start_priorities.options.None')},
        {id: 1, option: t('settingspage:start_priorities.options.ExternalStart')},
        {id: 2, option: t('settingspage:start_priorities.options.PIR')},
        // {id: 3, option: t('settingspage:start_priorities.options.0-10VFlow')},
        // {id: 4, option: t('settingspage:start_priorities.options.UI')},
        // {id: 5, option: t('settingspage:start_priorities.options.CO2')},
        {id: 6, option: t('settingspage:start_priorities.options.Timer')}
    ];

    const setPriorityText = (optionVal, num) => {
        const foundOption = startPriorityOptions.find(o => o.id == optionVal)
        if (!foundOption) return Array(2).fill('\xa0').join('') + t('settingspage:priority') + " " + num
        let text = foundOption.option
        let textLength = text.length
        if(textLength<9) {
            return Array(10-textLength).fill('\xa0').join('') + text
        }
        if(textLength > 13) {
            return text.substring(0, 13)
        }
        return text
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation title={t('settingspage:start_priorities.title')}
                            subTitle={t('settingspage:start_priorities.description')}/>

            <div className='my-2'>
                <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 1920 300">
                    <g>
                        <rect className="cls-24" x="224.48" y="64" width="90" height="90" transform="translate(1.85 222.47) rotate(-45)"/>
                        <rect className="cls-9" x="224.48" y="64" width="90" height="90" transform="translate(1.85 222.47) rotate(-45)"/>
                    </g>
                    <text className="cls-14" transform="translate(220.24 114.91)">{setPriorityText(priority2.value, 2)}</text>
                    <g>
                        <rect className="cls-24" x="420.78" y="64" width="90" height="90" transform="translate(59.35 361.28) rotate(-45)"/>
                        <rect className="cls-9" x="420.78" y="64" width="90" height="90" transform="translate(59.35 361.28) rotate(-45)"/>
                    </g>
                    <text className="cls-14" transform="translate(415.54 114.91)">{setPriorityText(priority3.value, 3)}</text>
                    <g>
                        <rect className="cls-24" x="617.77" y="64" width="90" height="90" transform="translate(117.05 500.57) rotate(-45)"/>
                        <rect className="cls-9" x="617.77" y="64" width="90" height="90" transform="translate(117.05 500.57) rotate(-45)"/>
                    </g>
                    <text className="cls-14" transform="translate(614.54 114.91)">{setPriorityText(priority4.value, 4)}</text>
                    <g>
                        <rect className="cls-24" x="19.35" y="64" width="90" height="90" transform="translate(-58.23 77.43) rotate(-45)"/>
                        <rect className="cls-9" x="19.35" y="64" width="90" height="90" transform="translate(-58.23 77.43) rotate(-45)"/>
                    </g>
                    <text className="cls-14" transform="translate(15.12 114.91)">{setPriorityText(priority1.value, 1)}</text>
                    <g>
                        <rect className="cls-24" x="812.35" y="63.81" width="90" height="90" transform="translate(174.17 638.11) rotate(-45)"/>
                        <rect className="cls-9" x="812.35" y="63.81" width="90" height="90" transform="translate(174.17 638.11) rotate(-45)"/>
                    </g>
                    <text className="cls-14" transform="translate(815.12 114.72)">{setPriorityText(priority5.value, 5)}</text>
                    <g>
                        <rect className="cls-24" x="1010.35" y="63.81" width="90" height="90" transform="translate(232.16 778.11) rotate(-45)"/>
                        <rect className="cls-9" x="1010.35" y="63.81" width="90" height="90" transform="translate(232.16 778.11) rotate(-45)"/>
                    </g>
                    <text className="cls-14" transform="translate(1007.12 114.72)">{setPriorityText(priority6.value, 6)}</text>
                    <g>
                        <line className="cls-9" x1="128.48" y1="109.5" x2="130.98" y2="109.5"/>
                        <line className="cls-4" x1="135.84" y1="109.5" x2="201.54" y2="109.5"/>
                        <line className="cls-9" x1="203.98" y1="109.5" x2="206.48" y2="109.5"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="332.98" y1="108.5" x2="335.48" y2="108.5"/>
                        <line className="cls-7" x1="340.4" y1="108.5" x2="397.02" y2="108.5"/>
                        <line className="cls-9" x1="399.48" y1="108.5" x2="401.98" y2="108.5"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="528.98" y1="108.5" x2="531.48" y2="108.5"/>
                        <line className="cls-10" x1="536.48" y1="108.5" x2="593.98" y2="108.5"/>
                        <line className="cls-9" x1="596.48" y1="108.5" x2="598.98" y2="108.5"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="725.98" y1="108.5" x2="728.48" y2="108.5"/>
                        <line className="cls-12" x1="733.32" y1="108.5" x2="789.05" y2="108.5"/>
                        <line className="cls-9" x1="791.48" y1="108.5" x2="793.98" y2="108.5"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="920.98" y1="108.5" x2="923.48" y2="108.5"/>
                        <line className="cls-5" x1="928.55" y1="108.5" x2="986.94" y2="108.5"/>
                        <line className="cls-9" x1="989.48" y1="108.5" x2="991.98" y2="108.5"/>
                    </g>
                    <text className="cls-15" transform="translate(151.58 102.76)"><tspan x="0" y="0">No</tspan></text>
                    <text className="cls-15" transform="translate(354.32 102.72)"><tspan x="0" y="0">No</tspan></text>
                    <text className="cls-15" transform="translate(548.32 101.72)"><tspan x="0" y="0">No</tspan></text>
                    <text className="cls-15" transform="translate(744.32 101.72)"><tspan x="0" y="0">No</tspan></text>
                    <text className="cls-15" transform="translate(942.32 102)"><tspan x="0" y="0">No</tspan></text>
                    <rect className="cls-8" x="1209.98" y="36.5" width="125" height="125" rx="60.5" ry="60.5"/>
                    <g>
                        <line className="cls-9" x1="64.48" y1="2.5" x2="66.98" y2="2.49"/>
                        <line className="cls-3" x1="71.98" y1="2.48" x2="1049.98" y2=".51"/>
                        <line className="cls-9" x1="1052.48" y1=".51" x2="1054.98" y2=".5"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="64.14" y1="38.33" x2="64.2" y2="35.83"/>
                        <line className="cls-6" x1="64.3" y1="31.42" x2="64.87" y2="7.2"/>
                        <line className="cls-9" x1="64.92" y1="5" x2="64.98" y2="2.5"/>
                        <polygon className="cls-23" points="69.16 36.98 63.98 45.5 59.19 36.75 69.16 36.98"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="63.98" y1="215.18" x2="64.04" y2="212.68"/>
                        <line className="cls-1" x1="64.16" y1="207.25" x2="64.86" y2="177.39"/>
                        <line className="cls-9" x1="64.92" y1="174.68" x2="64.98" y2="172.18"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="1054.98" y1="47.5" x2="1055.03" y2="45"/>
                        <line className="cls-11" x1="1055.13" y1="40.33" x2="1055.87" y2="5.33"/>
                        <line className="cls-9" x1="1055.92" y1="3" x2="1055.98" y2=".5"/>
                    </g>
                    <text className="cls-15" transform="translate(1022.71 23.72)"><tspan x="0" y="0">No</tspan></text>
                    <g>
                        <line className="cls-9" x1="63.98" y1="215.5" x2="66.48" y2="215.5"/>
                        <line className="cls-2" x1="71.47" y1="215.49" x2="1266.98" y2="213.51"/>
                        <line className="cls-9" x1="1269.48" y1="213.5" x2="1271.98" y2="213.5"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="1271.68" y1="209.18" x2="1271.74" y2="206.68"/>
                        <line className="cls-6" x1="1271.84" y1="202.28" x2="1272.4" y2="178.06"/>
                        <line className="cls-9" x1="1272.45" y1="175.85" x2="1272.51" y2="173.36"/>
                        <polygon className="cls-23" points="1277.46 174.93 1272.68 166.18 1267.49 174.7 1277.46 174.93"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="268.68" y1="215.18" x2="268.74" y2="212.68"/>
                        <line className="cls-1" x1="268.86" y1="207.25" x2="269.56" y2="177.39"/>
                        <line className="cls-9" x1="269.62" y1="174.68" x2="269.68" y2="172.18"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="465.68" y1="215.18" x2="465.74" y2="212.68"/>
                        <line className="cls-1" x1="465.86" y1="207.25" x2="466.56" y2="177.39"/>
                        <line className="cls-9" x1="466.62" y1="174.68" x2="466.68" y2="172.18"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="662.68" y1="214.18" x2="662.74" y2="211.68"/>
                        <line className="cls-1" x1="662.86" y1="206.25" x2="663.56" y2="176.39"/>
                        <line className="cls-9" x1="663.62" y1="173.68" x2="663.68" y2="171.18"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="856.68" y1="214.18" x2="856.74" y2="211.68"/>
                        <line className="cls-1" x1="856.86" y1="206.25" x2="857.56" y2="176.39"/>
                        <line className="cls-9" x1="857.62" y1="173.68" x2="857.68" y2="171.18"/>
                    </g>
                    <g>
                        <line className="cls-9" x1="1054.68" y1="214.18" x2="1054.74" y2="211.68"/>
                        <line className="cls-1" x1="1054.86" y1="206.25" x2="1055.56" y2="176.39"/>
                        <line className="cls-9" x1="1055.62" y1="173.68" x2="1055.68" y2="171.18"/>
                    </g>
                    <text className="cls-16" transform="translate(1225.05 108.17)">Device start</text>
                    <text className="cls-15" transform="translate(1133.06 210.72)">Yes</text>
                    <text className="cls-15" transform="translate(942.06 210.72)">Yes</text>
                    <text className="cls-15" transform="translate(745.06 209.72)">Yes</text>
                    <text className="cls-15" transform="translate(550.06 210.72)">Yes</text>
                    <text className="cls-15" transform="translate(348.48 209.72)">Yes</text>
                    <text className="cls-15" transform="translate(151.06 209.72)">Yes</text>
                </svg>

                <div className="mt-sm-3 d-flex flex-wrap">
                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 1'}
                            inputValue={(e) => setPriority1({value: e.target.value})}
                            value={priority1.value}
                            options={startPriorityOptions}
                            messages={priority1.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 2'}
                            inputValue={(e) => setPriority2({value: e.target.value})}
                            value={priority2.value}
                            options={startPriorityOptions}
                            messages={priority2.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 3'}
                            inputValue={(e) => setPriority3({value: e.target.value})}
                            value={priority3.value}
                            options={startPriorityOptions}
                            messages={priority3.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 4'}
                            inputValue={(e) => setPriority4({value: e.target.value})}
                            value={priority4.value}
                            options={startPriorityOptions}
                            messages={priority4.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 5'}
                            inputValue={(e) => setPriority5({value: e.target.value})}
                            value={priority5.value}
                            options={startPriorityOptions}
                            messages={priority5.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 6'}
                            inputValue={(e) => setPriority6({value: e.target.value})}
                            value={priority6.value}
                            options={startPriorityOptions}
                            messages={priority6.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.start_dependency')}
                            inputValue={(e) => setStartDependency({value: e.target.value == 1 ? true : false})}
                            value={startDependency.value ? 1 : 0}
                            options={[
                                {id: 0, option: t('settingspage:parameters.start_dependency_options.independent')},
                                {id: 1, option: t('settingspage:parameters.start_dependency_options.dependent')}
                            ]}
                            messages={startDependency.messages}
                            infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.start_dependency')}
                                                        popOverContent={t('settingspage:infomodals.start_dependency')}
                                                        trigger={['hover', 'touch']} contentAsHtml={true} size={'lg'}/>}
                        />
                    </div>

                    {/*<div className='w-300px'>*/}
                    {/*    <ComboBoxWithMessages*/}
                    {/*        className='p-4 m-auto'*/}
                    {/*        label={t('settingspage:parameters.night_cool_independent')}*/}
                    {/*        inputValue={(e) => setNightCoolIndependent({value: e.target.value == 1 ? true : false})}*/}
                    {/*        value={nightCoolIndependent.value ? 1 : 0}*/}
                    {/*        options={[*/}
                    {/*            {id: 0, option: t('common:general.no')},*/}
                    {/*            {id: 1, option: t('common:general.yes')}*/}
                    {/*        ]}*/}
                    {/*        messages={nightCoolIndependent.messages}*/}
                    {/*    />*/}
                    {/*</div>*/}

                </div>

            </div>

            {/*=== Button container*/}
            <div className='d-inline-block float-end p-2'>
                <Button variant='outline-secondary' onClick={() => saveIfChanged(false)} disabled={device.slave} className='me-2'>
                    {t('settingspage:save_device')}</Button>
                <Button variant='outline-secondary' onClick={() => saveIfChanged(true)} disabled={device.slave}>
                    {t('settingspage:save_group')}</Button>
            </div>
        </div>
    )
}