import React from 'react'
import {Trash} from 'react-bootstrap-icons'
import ComboBox from '../../../../../components/shared/combobox/ComboBox'

/** Element for selecting a chart option
 * @param {int} idx
 * @param {string[{id: string, option: string}]} options
 * @param placeholder
 * @param className
 * @param removeSelection
 * @param onChange
 * @param value
 * @param {boolean} isReadOnly
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChartSelectorDropdownItem({
                                                      idx, options = [], placeholder, className, removeSelection,
                                                      onChange, value, isReadOnly = false}) {
    const dropdownChange = ele => {
        return onChange({idx: idx, value: ele.target.value});
    }

    return (
        <div className={className + ' d-flex align-items-center'}>
            <div className="cursor-pointer" style={{width: '30px'}}>
                {!isReadOnly && <Trash onClick={() => removeSelection(idx)}/>}
            </div>
            <div className="d-inline-block w-100">
                <ComboBox disabled={isReadOnly} inputValue={dropdownChange} value={value} className="col-12"
                          blankChoice={placeholder} sm={true} options={options} hasLabel={false}/>
            </div>
        </div>
    )
}
