import React, {useEffect, useState} from "react";
import {getStatisticsList} from "../../../api/api.js";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import HeaderCell from "../../../components/shared/headercell/HeaderCell.js";

export default function AdminOperationsStatistics() {
    const [statisticsList, setStatisticsList] = useState([])

    useEffect(() => {
        getStatisticsList().then((response) => {
            setStatisticsList(response.statisticsList.sort((a, b) => new Date(b.date) - new Date(a.date)))
        })
    }, [])

    const hideProps = ["rowKey", "timestamp", "notificationDefinitionIdList"]

    return <div className='ms-3'>
        <div className={'mt-2 mb-2 d-flex'}>
            <TableContainer className='pe-0 ps-0 me-2' sx={{flex: '1'}}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow hover>
                            {Object.keys(statisticsList?.[0] ?? {}).filter(k => !hideProps.includes(k)).map((obj, i) => {
                                let sx = {backgroundColor: "#ededed", borderRight: "1px solid #d9d9d9"}
                                if (i === 0) {
                                    sx = {
                                        backgroundColor: "#ededed",
                                        borderRight: "1px solid #d9d9d9",
                                        borderTopLeftRadius: "0.8rem"
                                    }
                                } else if (i === Object.keys(statisticsList[0]).length - 1) {
                                    sx = {backgroundColor: "#ededed", borderTopRightRadius: "0.8rem"}
                                }
                                return <HeaderCell headerObj={{name: obj, sortValue: ""}} key={obj} sx={sx} alignment={"center"}
                                                   className={'d-table-cell'}/>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statisticsList.map(row => (
                            <TableRow key={row.rowKey}
                                      sx={{
                                          backgroundColor: 'white',
                                          "td": {
                                              borderBottom: "1px solid #d9d9d9",
                                              borderTop: "1px solid #d9d9d9",
                                              borderLeft: "1px solid #d9d9d9"
                                          },
                                          "td:hover": {
                                              cursor: 'pointer'
                                          },
                                          "td:last-child": {
                                              borderRight: "1px solid #d9d9d9"
                                          }
                                      }}
                            >
                                {Object.entries(row).filter(e => !hideProps.includes(e[0])).map(([k, v]) =>
                                    <TableCell key={k} align="left" className={'d-table-cell p-2'}>
                                        <div className='mb-1'>{v}</div>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>
}
