import React from 'react'
import {useTranslation} from 'react-i18next'
import Spinner from "react-bootstrap/Spinner"
import {Button} from "react-bootstrap"
import {ChevronDown, ChevronUp} from "react-bootstrap-icons"
import {isAwareDevice} from '../../../helpers/helperfunctions.js'

export default function UpdateFirmwareTableBody({
                                                    className, deviceList, deviceListLoading,
                                                    toggleRowChecked, toggleAllShownRows, sort, sortHandler
                                                }) {
    const {t} = useTranslation(['firmwarepage'])

    const deviceHeaders = [
        {
            name: t("firmwarepage:updatetable.headers.serial_number"),
            sortValue: "serial_number",
            type: "string",
            headerClass: ""
        },
        {
            name: t("firmwarepage:updatetable.headers.name"),
            sortValue: "name",
            type: "string",
            headerClass: ""
        },
        {
            name: t("firmwarepage:updatetable.headers.firmware_version"),
            sortValue: "device_twin.reported.applicationVersion",
            type: "string",
            headerClass: ""
        },
        {
            name: t("firmwarepage:updatetable.headers.type"),
            sortValue: "model",
            type: "string",
            headerClass: ""
        },
        {
            name: t("firmwarepage:updatetable.headers.path"),
            sortValue: "",
            type: "string",
            headerClass: "d-lg-table-cell d-none"
        },
        {
            name: t("firmwarepage:updatetable.headers.status"),
            sortValue: "connected",
            type: "string",
            headerClass: "d-lg-table-cell d-none"
        },
        {
            name: t("firmwarepage:updatetable.headers.update_status"),
            sortValue: "device_twin.reported.firmwareStatus",
            type: "string",
            headerClass: ""
        },
    ]

    function getFirmwareStatus(row) {
        const {device_twin, serial_number} = row
        const isAware = isAwareDevice(serial_number)
        if (isAware && device_twin.desired.applicationVersion == null || device_twin.desired.applicationVersion === device_twin.reported.applicationVersion) {
            return "Complete"
        }
        return row?.device_twin?.reported?.firmware_update ?? row?.device_twin?.reported?.firmwareStatus
    }

    function getApplicationVersionString(row) {
        const {device_twin, serial_number} = row
        const {reported, desired} = device_twin || {}
        const {applicationVersion, firmwareStatus} = reported || {}
        const {applicationVersion: desiredApplicationVersion} = desired || {}

        if (applicationVersion === null) return "N/A"

        const isAware = isAwareDevice(serial_number)
        if (isAware) {
            if (desiredApplicationVersion == null || desiredApplicationVersion === applicationVersion || firmwareStatus == 'Complete') {
                return applicationVersion
            }
            return `${applicationVersion} -> ${desiredApplicationVersion}`
        } else {
            const hasDesiredVersion = desiredApplicationVersion && firmwareStatus == 'Complete'
            return `${applicationVersion}${desiredApplicationVersion == null || hasDesiredVersion ? "" : ` -> ${desiredApplicationVersion}`}`
        }
    }

    return <div className={className}>
        <table className='w-100 airmaster-table list-view'>
            <thead>
            <tr>
                <th>
                    <div className='d-flex justify-content-center pe-1'>
                        <input type='checkbox' readOnly={true} disabled={deviceList.length === 0}
                               checked={deviceList.length !== 0 && deviceList.every(d => d.checked)}
                               style={{height: '18px', width: '18px'}}
                               onChange={() => toggleAllShownRows()}/>
                    </div>
                </th>
                {deviceHeaders.map((header, idx) =>
                    <th key={idx} className={header.headerClass}>
                        <Button onClick={() => sortHandler(header)}
                                variant='outline-primary'
                                active={sort?.sortValue === header.sortValue}
                                style={{borderRadius: '10px', padding: '0.75rem', paddingLeft: '1.5rem'}}
                                className='d-flex w-100 text-nowrap'>
                            <span>{header.name}</span>
                            {sort?.sortValue === header.sortValue && <>
                                {sort.sortDirection === "desc" ?
                                    <ChevronDown className='filter-chevron'/> :
                                    <ChevronUp className='filter-chevron'/>}
                            </>}
                        </Button>
                    </th>
                )}
            </tr>
            </thead>
            <tbody>
            {deviceListLoading && <tr className='bg-transparent'>
                <td className='p-4' colSpan={7}>
                    <div className='d-flex justify-content-center align-items-center' style={{flex: '1'}}>
                        <Spinner style={{height: '50px', width: '50px'}} animation="border" variant="primary"
                                 size='lg'/>
                    </div>
                </td>
            </tr>}
            {!deviceListLoading && deviceList.map(row =>
                <tr key={row.id} className={`cursor-pointer`}
                    onClick={() => toggleRowChecked(row, !row.checked)}>
                    <td className='d-table-cell' align="left">
                        <input readOnly={true} type='checkbox' checked={row.checked}/>
                    </td>
                    <td className='d-table-cell' align="left">{row.serial_number}</td>
                    <td className='d-table-cell' align="left">{row.name}</td>
                    <td className='d-table-cell' align="left">
                        {getApplicationVersionString(row)}
                    </td>
                    <td className='d-table-cell' align="left">{row.model}</td>
                    <td className='d-lg-table-cell d-none' align="left">{row.path}</td>
                    <td
                        className={`d-lg-table-cell d-none ${row.connected ? 'color-telemetry-very-good' : 'color-telemetry-very-bad'}`}
                        align="left">{t('common:connectionstate.' + row.connected)}</td>
                    <td className='d-table-cell'
                        align="left">{getFirmwareStatus(row)}</td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
}