import i18n from "i18next";
import httpBackend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {storageConstants, syslang} from "./constants";

/** returns default browser language or english if not supported */
function getDefaultBrowserLanguage() {
    if (syslang.map(l => l.id).includes(navigator.language))
        return navigator.language;
    return "en"
}

/** **i18n** setup of internationalization with i18next
 * for formatting, see https://www.i18next.com/translation-function/formatting
 */
i18n
    .use(initReactI18next)
    .use(httpBackend)
    .init({
        compatibilityJSON: 'v3',
        supportedLngs: syslang.map(a => a.id) + (process.env.REACT_APP_DEBUG_I18N === "true" ? ["citest"] : []),
        ns: ['userpage', 'common', 'grouppage', 'overviewpage', 'navigationbar', 'inputerrors', 'toast', 'telemetry', 'parameters', 'devicelogs', 'settingspage'],
        backend: {
          loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
        },
        // if fallbackLng is citest, then it will show placeholder values if we select language with no language file
        fallbackLng: process.env.REACT_APP_DEBUG_I18N === "true" ? "citest" : getDefaultBrowserLanguage(),
        keySeparator: ".",
        // debug: process.env.REACT_APP_DEBUG_I18N === "true",
        interpolation: {
            escapeValue: false, // not needed for react
        },
    });

// formatter example. Use: t('common.somekey', lowercase)
// i18n.services.formatter.add('lowercase', (value, lng, options) => {
//     return value.toLowerCase();
// });

/**
 * gets cached user language if exists, otherwise fallbackLng
 * @returns {string}
 */
function getCurrentLanguage() {
    return localStorage.getItem(storageConstants.userLanguage) ?? i18n.language;
}

/**
 * Set and cache user language
 * @param language
 * @returns {Promise<TFunction>}
 */
function changeLanguage(language) {
    let tempLang = language
    if (!syslang.map(l => l.id).includes(tempLang)) {
        console.warn(`language ${tempLang} was not included in syslang array ${JSON.stringify(syslang)}`)
        tempLang = "en"
    }
    localStorage.setItem(storageConstants.userLanguage, tempLang);
    return i18n.changeLanguage(tempLang);
}

function onLanguageChanged(callback) {
    return i18n.on('languageChanged', callback);
}

export {i18n, getCurrentLanguage, changeLanguage, onLanguageChanged};
