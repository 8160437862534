import React, {useEffect, useRef} from 'react'
import {Hdd} from 'react-bootstrap-icons'
import {dragStartEventHandler, toggleEditStyles, toggleGrabbingCursor} from '../interactions/Interactions'

/** Represents a device in the hierarchy
 * @param {object} node object for the actual device to display
 * @param {[object]} selectedNodeList list of objects to pass with the current selected nodes
 * @param {function} selectNode Method to call when the node has been selected
 * @param {boolean} isDraggable Allows to drag items
 * @returns
 */
export default function DeviceNode({node, selectedNodeList, selectNode, isDraggable = false}) {
    const eleRef = useRef()
    useEffect(() => {
        toggleEditStyles(isDraggable, eleRef)
    }, [selectedNodeList, isDraggable])
    return (
        <div
            draggable={isDraggable}
            onMouseDown={() => isDraggable ? toggleGrabbingCursor(eleRef) : undefined}
            onMouseUp={() => isDraggable ? toggleGrabbingCursor(eleRef) : undefined}
            onDragStart={(e) => dragStartEventHandler(e, node, eleRef, node.type)}
            ref={eleRef} onClick={e => selectNode(node, e)}
            style={{fontSize: '17px', padding: '1px'}}
            className={`text-noselect list-unstyled cursor-pointer hover-effect d-flex align-items-center ${selectedNodeList.map(n => n.id).includes(node.id) ? 'text-secondary' : 'text-black'}`}>
            <Hdd display='inline' className='d-xs-none me-2'/>{node.name}
        </div>
    )
}
