import React from 'react'
import {Hdd} from 'react-bootstrap-icons'

/** Represents a device in the hierarchy
 * @param {object} node object for the actual device to display
 * @param {object} selectedNode selected node
 * @param {function} selectNode Method to call when the node has been selected
 * @returns
 */
export default function NotificationsItemDeviceNode({node, selectedNode, selectNode}) {
    return (
        <li onClick={() => selectNode(node)}
            className={`text-noselect list-unstyled cursor-pointer hover-effect ${selectedNode?.id === node.id ? 'node-active' : ''}`}>
            <Hdd display='inline' className='me-2'/>{node.name}
        </li>
    )
}
