import {combineReducers, configureStore} from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import userAdministrationReducer from './reducers/userAdministrationReducer';
import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';
import localStorage from "redux-persist/es/storage";
import {persistStore} from "redux-persist";
import loadingReducer from './reducers/loadingReducer';
import groupReducer from './reducers/groupReducer';
import deviceReducer from './reducers/deviceReducer';
import telemetryReducer from './reducers/telemetryReducer';
import deviceLogReducer from './reducers/deviceLogReducer';
import technicalParamsReducer from "./reducers/technicalParamsReducer.js";
import filterReducer from "./reducers/filterReducer.js";
import thunk from "redux-thunk";
import toastReducer from "./reducers/toastReducer.js";

/************ REDUX PERSIST CONFIG *************/
const rootConfig = {
    key: 'root',
    storage: storageSession, // defaults to sessionstorage for web
};
const filterConfig = {
    key: 'filter',
    storage: localStorage,
};

// Reducers use immer behind the scenes. Docs: https://redux-toolkit.js.org/usage/immer-reducers
const appReducer = combineReducers({
    user: userReducer,
    userAdministration: userAdministrationReducer,
    loading: loadingReducer,
    toast: toastReducer,
    group: groupReducer,
    device: deviceReducer,
    telemetry: telemetryReducer,
    deviceLog: deviceLogReducer,
    technicalParams: technicalParamsReducer,
    filter: persistReducer(filterConfig, filterReducer)
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_REDUX') {
        console.log("RESET_REDUX action called")
        storageSession.removeItem("persist:root")
        localStorage.removeItem("persist:filter")
        state = undefined;
    }
    return appReducer(state, action);
};

export const store = configureStore({
    reducer: persistReducer(rootConfig, rootReducer),
    middleware: [thunk],
    devTools: process.env.NODE_ENV !== 'production'
})
export const persistor = persistStore(store);