import {createSlice} from "@reduxjs/toolkit";

/***************** STATE ****************/
const initialState = {
    toastList: [], // [{id:string, type:string, title:string, body:string, timeSeconds: int}]
};

/************ STATE SLICE **************/

const toastSlice = createSlice({
    name: 'toast',
    initialState: initialState,
    reducers: {
        addToast(state, action) {
            const newToast = action.payload
            if (state.toastList.find(t => t.id === newToast.id)) return
            state.toastList = [
                ...state.toastList,
                {
                    ...newToast,
                    id: newToast.id ?? Math.random().toString(36).slice(2),
                    addedTimeMs: Date.now()
                }]
        },
        removeToastById(state, action) {
            state.toastList = state.toastList.filter(t => t.id !== action.payload)
        },
    }
})

/**** EXPORTED ACTIONS AND REDUCERS ****/

export default toastSlice.reducer;
export const {addToast, removeToastById} = toastSlice.actions;