import React from 'react'
import ProfileImageSelector from '../../../../../../components/shared/profileimageselector/ProfileImageSelector'

/** Table dependency that is used to populate a username with a prefixed image
 * @param {object} data object with data for the given row object (Must include givenname, surname and fullname) */
export default function UserName({data}) {
    return (
        <div className='d-flex m-0'>
            <div style={{height: "30px", width: '30px'}} className='col-3 p-0'>
                <ProfileImageSelector placeholderFontSize="sm" givenName={data.userData.givenName}
                                      surname={data.userData.surname} grayscale={true}/>
            </div>
            <div className='ps-2 pe-0 col-9 text-truncate align-self-center'>
                <span className='ps-1'>
                    {data.userData.fullName}
                </span>
            </div>
        </div>
    )
}