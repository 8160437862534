import React from 'react'
import { Translation } from 'react-i18next'

/**
 * **StatusBadge** is a table dependency in '../UserManagement.js' which injects the user sign-up status into the table cell. The component distinguish between, "Accepted" and "PendingAcceptance".
 * 
 * @param {string} externalUserState externalUserState describes the sign-up status of the user
 * @returns a badge with a color to resemble the user sign-up status
 */
function StatusBadge({externalUserState}) {
    function decideVariant() {
        switch (externalUserState) {
            case 'Accepted':
                return 'color-telemetry-very-good'
            case 'PendingAcceptance':
                return 'color-telemetry-bad'
            default:
                return 'color-telemetry-very-bad'
        }
    }
    function decideText(cell) {
        if (cell === "Accepted") {
            return 'user_management.table.badge.accepted'
        } else if (cell === "PendingAcceptance") {
            return 'user_management.table.badge.pendingAcceptance'
        } else {
            return 'user_management.table.badge.unknown'
        }
    }
    return (
        <Translation ns={['userpage']}>
            {(t) =>
                <span className={decideVariant()}> {t(decideText(externalUserState))} </span>
            }
        </Translation >
    )
}
export default StatusBadge