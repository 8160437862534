import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import DropdownActions from './tabledependencies/dropdownactions/DropdownActions.js';
import StatusBadge from './tabledependencies/statusbadge/StatusBadge.js';
import UserName from './tabledependencies/username/UserName.js';
import {hasAnyRole} from "../../../../helpers/authHelper.js";
import {formatDateTimeString, getComparator} from "../../../../helpers/helperfunctions.js";
import {roleConstants} from "../../../../helpers/constants.js";
import {Button} from "react-bootstrap";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";

/** Renders the body for the user groups table
 * @param {object[]} userListToShow list with user objects
 * @param {int} rowsPerPage decides the number of rows to show in each page of the table
 * @param {int} page decides the current page to view in the table
 * @param {function} openEditModal function to trigger the visibility of the edit user modal
 * @param {function} openDeleteModal function to trigger the visibility of the delete user modal
 * @param {function} openPrivilegesmodal function to trigger if the user tries to delete a user, which is not allowed to be deleted
 * @param {function} sendReinvite function to trigger the visibility of the re-invite user modal
 * @param {object} accounts MSAL logged-in user object
 */
export default function UserTableBody({
                                          userListToShow, rowsPerPage, page, openEditModal, openDeleteModal,
                                          openPrivilegesmodal, sendReinvite, accounts
                                      }) {
    const [sort, setSort] = useState({sortDirection: 'desc', sortValue: 'userData.created'})

    const {t} = useTranslation(["common", "userpage"])
    const tableHeaders = [
        {
            name: t('userpage:user_management.table.columns.name'),
            sortValue: "userData.fullName",
            type: "string",
            headerClass: "d-none d-lg-table-cell"
        },
        {
            name: t('userpage:user_management.table.columns.mail'),
            sortValue: 'userData.mail',
            type: "string",
            headerClass: ""
        },
        {
            name: t('userpage:user_management.table.columns.role'),
            sortValue: 'userData.appRole',
            type: "string",
            headerClass: "d-none d-sm-table-cell"
        },
        {
            name: t('userpage:user_management.table.columns.status'),
            sortValue: 'userData.externalUserState',
            type: "string",
            headerClass: "d-none d-xxl-table-cell"
        },
        {
            name: t('userpage:user_management.table.columns.lng'),
            sortValue: 'userData.preferredLanguage',
            type: "string",
            headerClass: "d-none d-xxl-table-cell"
        },
        {
            name: t('userpage:user_management.table.columns.created'),
            sortValue: 'userData.created',
            type: "string",
            headerClass: "d-none d-xxl-table-cell"
        }
    ]

    const sortHandler = (headerData) => {
        setSort({
            sortDirection: sort.sortDirection === 'asc' ? 'desc' : "asc",
            sortValue: headerData.sortValue
        })
    }

    const filterTableData = (users) =>
        users.sort(getComparator(sort.sortDirection, sort.sortValue)).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)

    return (
        <table className='w-100 airmaster-table list-view'>
            <thead>
            <tr>
                {tableHeaders.map((header, idx) =>
                    <th key={idx} className={header.headerClass}>
                        <Button onClick={() => sortHandler(header)}
                                variant='outline-primary'
                                active={sort?.sortValue === header.sortValue}
                                style={{borderRadius: '10px', padding: '0.5rem', paddingLeft: '1.5rem'}}
                                className='d-flex w-100 text-nowrap'>
                            <span>{header.name}</span>
                            {sort?.sortValue === header.sortValue && <>
                                {sort.sortDirection === "desc" ?
                                    <ChevronDown className='filter-chevron'/> :
                                    <ChevronUp className='filter-chevron'/>}
                            </>}
                        </Button>
                    </th>
                )}
                <th></th>
            </tr>
            </thead>
            <tbody>
            {/*{loadingList && <tr className='bg-transparent'>*/}
            {/*    <td className='p-4' colSpan={7}>*/}
            {/*        <div className='d-flex justify-content-center align-items-center'*/}
            {/*             style={{flex: '1'}}>*/}
            {/*            <Spinner style={{height: '50px', width: '50px'}} animation="border"*/}
            {/*                     variant="primary"*/}
            {/*                     size='lg'></Spinner>*/}
            {/*        </div>*/}
            {/*    </td>*/}
            {/*</tr>}*/}
            {filterTableData(userListToShow).map((user, idx) =>
                <tr key={idx} className='cursor-pointer'>
                    <td className='d-none d-lg-table-cell text-truncate'><UserName data={user}/></td>
                    <td className='overflow-xs-wrap-anywhere'>{user.userData.mail}</td>
                    <td className='d-none d-sm-table-cell'>{user.userData.appRole ? t(`common:appRoles.${user.userData.appRole}`) : "N/A"}</td>
                    <td className='d-none d-xxl-table-cell'>
                        <StatusBadge externalUserState={user.userData.externalUserState}/>
                    </td>
                    <td className='d-none d-xxl-table-cell'>{t(`common:lng.${user.userData.preferredLanguage}`)}</td>
                    <td className='d-none d-xxl-table-cell'>{formatDateTimeString(user.userData.created, true, false)}</td>
                    <td>
                        {hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical]) &&
                            <DropdownActions data={user} priviledgesHandler={openPrivilegesmodal}
                                             userRole={accounts[0].idTokenClaims.roles}
                                             deleteHandler={() => openDeleteModal(user.userId)}
                                             editHandler={() => openEditModal(user)}
                                             reInviteHandler={() => sendReinvite(user.userData.mail)}/>
                        }
                    </td>
                </tr>
            )}
            </tbody>
        </table>

        // <TableContainer style={{flex: '1'}} className='pe-0 ps-0 mb-4 min-height-250px'>
        //     <Table stickyHeader aria-label="simple table">
        //         <TableHead sx={{"th": {paddingBottom: "10px", color: "black"}}}>
        //             <TableRow hover>
        //                 {tableHeaders.map(header =>
        //                     <HeaderCell key={header.name} sortHandler={sortHandler} headerObj={header}
        //                                 sort={sort} className={header.headerClass}/>)}
        //             </TableRow>
        //         </TableHead>
        //         <TableBody>
        //             {filterTableData(userListToShow).map((user) =>
        //                     <TableRow key={user.userId} sx={{
        //                         "td": {
        //                             paddingBottom: "10px",
        //                             paddingTop: "10px"
        //                         }
        //                     }}>
        //                         <TableCell className='d-none d-sm-table-cell text-truncate'>
        //                             <UserName data={user}/>
        //                         </TableCell>
        //                         <TableCell>
        //                             {user.userData.mail}
        //                         </TableCell>
        //                         <TableCell className='d-none d-sm-table-cell'>
        //                             {user.userData.appRole != '' ? t(`common:appRoles.${user.userData.appRole}`) : "N/A"}
        //                         </TableCell>
        //                         <TableCell className='d-none d-xxl-table-cell'>
        //                             <StatusBadge externalUserState={user.userData.externalUserState}/>
        //                         </TableCell>
        //                         <TableCell className='d-none d-xxl-table-cell'>
        //                             {t(`common:lng.${user.userData.preferredLanguage}`)}
        //                         </TableCell>
        //                         <TableCell className='d-none d-xxl-table-cell'>
        //                             {formatDateTimeString(user.userData.created, true, false)}
        //                         </TableCell>
        //                         <TableCell>
        //                             {hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical]) &&
        //                                 <DropdownActions data={user} priviledgesHandler={openPrivilegesmodal}
        //                                                  userRole={accounts[0].idTokenClaims.roles}
        //                                                  deleteHandler={() => openDeleteModal(user.userId)}
        //                                                  editHandler={() => openEditModal(user)}
        //                                                  reInviteHandler={() => sendReinvite(user.userData.mail)}/>
        //                             }
        //                         </TableCell>
        //                     </TableRow>
        //                 )}
        //             </TableBody>
        //         </Table>
        //     </TableContainer>

    )
}