import React from 'react'

/** @param {JSX.Element | string} title of the TopInformation
 * @param {JSX.Element | string} subTitle subtitle of the TopInformation
 * @param {string} className classes added to the component */
export default function DeviceConfigTopInformation({title, subTitle, className = ""}) {
    return (
        <div id='top-information' className={`${className} mb-4`}>
            <div data-testid='title' className='font-weight-500' style={{fontSize:'1.5rem'}}>{title}</div>
            <div className='mb-0' data-testid='subTitle'>{subTitle}</div>
        </div>
    )
}