import {useTranslation} from 'react-i18next';
import {Area, AreaChart, CartesianGrid, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {formatDateTimeString, getTicks, getTimeDiffHours} from '../../../../../helpers/helperfunctions';
import {LoadSpinner} from "../../../../shared/loader/LoadSpinner.js";
import {useSelector} from "react-redux";
import {loaders} from "../../../../../helpers/constants.js";
import {Button} from 'react-bootstrap'
import React, {useEffect, useState} from 'react'

/**
 * @param {string|number} lineKey
 * @param telemetryList
 * @param title
 * @param tooltip
 * @param translationPrefix
 * @param optionsLength
 * @param {string} startTime UTC date ISO string for the start date
 * @param {string} endTime UTC date ISO string for the end date
 * @param {string} yStepType 'category' or 'continuous'
 * @returns {JSX.Element}
 * @constructor
 */
export default function StepChart({
                                      lineKey = 'pv', telemetryList, title, tooltip,
                                      translationPrefix, optionsLength, startTime, endTime, yStepType = 'category'
                                  }) {
    const {loading} = useSelector(state => state.loading)
    const {t} = useTranslation(['telemetry'])

    const initialXDomainState = [() => new Date(startTime).getTime(), () => new Date(endTime).getTime()]
    const [xDomain, setXDomain] = useState(initialXDomainState)

    const [telemetryToShowList, setTelemetryToShowList] = useState([])
    useEffect(() => {
        setTelemetryToShowList(telemetryList.map(d => ({...d, ts: new Date(d.ts).getTime()})))
    }, [telemetryList])

    const [zoomRefMouseDown, setZoomRefMouseDown] = useState(0)
    const [zoomRefMouseUp, setZoomRefMouseUp] = useState(0)
    const resetZoom = () => {
        setTelemetryToShowList(telemetryList.map(d => ({...d, ts: new Date(d.ts).getTime()})))
        setXDomain(initialXDomainState)
    }

    const zoom = () => {
        if (zoomRefMouseDown === zoomRefMouseUp || zoomRefMouseUp === 0) {
            setZoomRefMouseDown(0)
            setZoomRefMouseUp(0)
            return
        }
        const [leftTs, rightTs] = zoomRefMouseDown > zoomRefMouseUp ? [zoomRefMouseUp, zoomRefMouseDown] : [zoomRefMouseDown, zoomRefMouseUp]
        let zoomStart = telemetryToShowList.findIndex(t => t.ts === leftTs)
        let zoomEnd = telemetryToShowList.findIndex(t => t.ts === rightTs) + 1
        setTelemetryToShowList(telemetryToShowList.slice(zoomStart, zoomEnd))
        setXDomain([() => leftTs, () => rightTs])
        setZoomRefMouseDown(0)
        setZoomRefMouseUp(0)
    }
    let timeDiff = getTimeDiffHours(
        telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[0].ts : startTime,
        telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[telemetryToShowList.length - 1].ts : endTime
    )

    return (
        <>
            <div className="d-flex align-content-center">
                <span className="text-bold ps-3" style={{fontSize: '20px'}}>{title}</span>
                {telemetryToShowList.length !== telemetryList.length &&
                    <Button onClick={resetZoom} className='ms-2' size='sm' variant='outline-secondary'>Reset Zoom</Button>
                }
            </div>
            {loading.includes(loaders.getTelemetry) &&
                <LoadSpinner style={{marginTop: "100px", marginBottom: "100px"}} height='60px' width='60px'/>
            }
            {(!loading.includes(loaders.getTelemetry)) &&
                <div className='col-12'>
                    <div className='col-12'>
                        <ResponsiveContainer width='100%' height={450}>
                            <AreaChart data={telemetryToShowList}
                                       margin={{top: 35, right: 30, left: 50, bottom: 0,}}
                                       onMouseDown={e => e?.activeLabel && setZoomRefMouseDown(e.activeLabel)}
                                       onMouseMove={e => zoomRefMouseDown && e?.activeLabel && setZoomRefMouseUp(e.activeLabel)}
                                       onMouseUp={zoom}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="ts"
                                       scale="time"
                                       type="number"
                                       ticks={getTicks(
                                           telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[0].ts : startTime,
                                           telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[telemetryToShowList.length - 1].ts : endTime
                                           , 8)}
                                       tickFormatter={e => formatDateTimeString(e, timeDiff > 24, timeDiff <= (24 * 4))}
                                       domain={xDomain}
                                />
                                {yStepType === 'category' && <>
                                    <YAxis domain={[0, optionsLength - 1]} tickCount={optionsLength} interval={0}
                                           tickFormatter={(e) => t(`telemetry:${translationPrefix}_${e}`)} width={150}/>
                                    <Tooltip content={e => tooltip({...e, translationPrefix, title})}/>
                                </>}
                                {yStepType === 'continuous' && <>
                                    <YAxis domain={[0, optionsLength]} width={150}/>
                                    <Tooltip content={e => tooltip(e, [lineKey])}/>
                                </>}
                                <defs>
                                    <linearGradient id="noSplitColor" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset='100%' stopColor="rgb(103, 167, 221)" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <Area type="stepAfter" isAnimationActive={false} activeDot={{r: 4}} dot={false}
                                      dataKey={lineKey}
                                      stroke={`rgba(103, 167, 221, 0.7)`} fill={`url(#noSplitColor)`}/>

                                {zoomRefMouseDown && zoomRefMouseUp ? (
                                    <ReferenceArea
                                        fill={"gray"}
                                        x1={zoomRefMouseDown}
                                        x2={zoomRefMouseUp}
                                        strokeOpacity={0.3}
                                    />
                                ) : null}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            }
        </>
    );
}