import {useTranslation} from 'react-i18next';
import DeviceStatusAirflowTemperatureBox from './airflowtemperaturebox/DeviceStatusAirflowTemperatureBox.js';
import React from "react";
import {isAwareDevice, roundToFractionDigits} from "../../../../../helpers/helperfunctions.js";
import {paramNames} from "../../../../parameters/parameterSpec.js";
import {awareTelemNames} from '../../../../parameters/awareTelemSpec.js';
import recircHeatingGraphic from '../../../../../images/svg/recirc_heating.svg';
import recircCoolingGraphic from '../../../../../images/svg/recirc_cooling.svg';
import recircGraphic from '../../../../../images/svg/recirc.svg';
import ventilationGraphic from '../../../../../images/svg/ventilation.svg';
import ventHeatingGraphic from '../../../../../images/svg/vent_heating.svg';
import ventCoolingGraphic from '../../../../../images/svg/vent_cooling.svg';
import {themeColors} from "../../../../../helpers/constants.js";

/** Renders the section on the dashboard that views the airflow temperatures across the device
 * @param {object} device
 * @param {string} outlet temperature of the outlet air
 * @param {boolean} outletHasError determines if a warning triangle should be shown next to the temperature
 * @param {string} inlet temperature of the inlet air
 * @param {boolean} inletHasError determines if a warning triangle should be shown next to the temperature
 * @param {string} outside temperature of the outside air
 * @param {boolean} outsideHasError determines if a warning triangle should be shown next to the temperature
 * @param {string} room temperature of the room air
 * @param {boolean} roomHasError determines if a warning triangle should be shown next to the temperature
 */
export default function DeviceStatusAirflowSection({
                                                       device, outlet, outletHasError, inlet,
                                                       inletHasError, outside, outsideHasError,
                                                       room, roomHasError
                                                   }) {
    const {t} = useTranslation(["dashboard"])
    
    const rc_states = {
        recirc: 1,
        vent: 2,
        recirc_heat: 3,
        vent_heat: 4,
        recirc_cool: 5,
        vent_cool: 6
    }
    
    const isAware = isAwareDevice(device.serial_number);
    const rc_state = device?.latest_telemetry?.[awareTelemNames.rc_state];
  
    const showState = (state) => isAware ? rc_state == rc_states[state] : false;
    const jeCooling = isAware ? false : device?.latest_telemetry?.[paramNames.cooling_percent] > 0
    
    const showRecirculation = isAware ? showState('recirc') : false;
    const showVentilation = isAware ? showState('vent') : false;
    const showRecirculationHeating = isAware ? showState('recirc_heat') : false;
    const showRecirculationCooling = isAware ? showState('recirc_cool') : false;
    const showVentilationHeating = isAware ? showState('vent_heat') : !jeCooling;
    const showVentilationCooling = isAware ? showState('vent_cool') : jeCooling;

    let secondary = themeColors.getSecondary();
    let outsideColor = secondary
    let outletColor = secondary
    let inletColor = secondary
    let roomColor = secondary

    if (showRecirculationHeating || showVentilationHeating) {
        outsideColor = "#526684"
        outletColor = "#526684"
        inletColor = "#a53333"
        roomColor = "#a53333"
    }

    if (showRecirculationCooling || showVentilationCooling) {
        outsideColor = "#a53333"
        outletColor = "#a53333"
        inletColor = "#526684"
        roomColor = "#a53333"
    }

    const graphicWidth = "80px";

    const hasSupplyExtract = isAwareDevice(device.serial_number) || device.device_twin.reported?.parameters[paramNames.mode] === "1"

    function getSupplyValue() {
        if(!device.connected) return "-"
        return isAwareDevice(device.serial_number)
            ? String(parseInt(device?.latest_telemetry?.[awareTelemNames.fan_sup_flow]))
            : String(device?.latest_telemetry?.[paramNames.supply_flow]);
    }

    function getExtractValue() {
        if(!device.connected) return "-"
        return isAwareDevice(device.serial_number)
            ? String(parseInt(device?.latest_telemetry?.[awareTelemNames.fan_ext_flow]))
            : String(device?.latest_telemetry?.[paramNames.extraction_flow]);
    }

    return (
        <div className='boxstyling w-100  d-flex flex-column justify-content-evenly rounded bg-primarybg-dim p-2'
             style={{flex: '1'}}>
            <div className='d-flex justify-content-around'>
                <DeviceStatusAirflowTemperatureBox hasError={outletHasError} value={roundToFractionDigits(outlet, 1)}
                                                   color={outletColor}
                                                   extractsupply={hasSupplyExtract ?
                                                    <div style={{height: '24px'}}></div> : <></>}
                                                   title={t('dashboard:status.temperatures.outlet')}/>
                <DeviceStatusAirflowTemperatureBox hasError={inletHasError} value={roundToFractionDigits(inlet, 1)}
                                                   color={inletColor}
                                                   extractsupply={hasSupplyExtract ? <div
                                                       className='text-secondary'>{t('dashboard:status.control_flow.supply')} {getSupplyValue()} m<sup>3</sup>/h
                                                   </div> : <></>}
                                                   title={t('dashboard:status.temperatures.inlet')}/>
            </div>
            <div className='d-flex justify-content-center'>
                {showVentilation &&
                    <img style={{width: graphicWidth}} src={ventilationGraphic} alt="ventilationGraphic"/>
                }
                {showRecirculation &&
                    <img style={{width: graphicWidth}} src={recircGraphic} alt="recircGraphic"/>
                }
                {showRecirculationCooling &&
                    <img style={{width: graphicWidth}} src={recircCoolingGraphic} alt="recircCoolingGraphic"/>
                }
                {showRecirculationHeating &&
                    <img style={{width: graphicWidth}} src={recircHeatingGraphic} alt="recircHeatingGraphic"/>
                }
                {showVentilationCooling &&
                    <img style={{width: graphicWidth}} src={ventCoolingGraphic} alt="ventCoolingGraphic"/>
                }
                {showVentilationHeating &&
                    <img style={{width: graphicWidth}} src={ventHeatingGraphic} alt="ventHeatingGraphic"/>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <DeviceStatusAirflowTemperatureBox hasError={outsideHasError} value={roundToFractionDigits(outside, 1)}
                                                   color={outsideColor}
                                                   extractsupply={hasSupplyExtract ?
                                                       <div style={{height: '24px'}}></div> : <></>}
                                                   title={t('dashboard:status.temperatures.outside')}/>
                <DeviceStatusAirflowTemperatureBox hasError={roomHasError} value={roundToFractionDigits(room, 1)}
                                                   color={roomColor}
                                                   extractsupply={hasSupplyExtract ? <div
                                                    className='text-secondary'>{t('dashboard:status.control_flow.extraction')} {getExtractValue()} m<sup>3</sup>/h
                                                    </div> : <></>}
                                                   title={t('dashboard:status.temperatures.room')}/>
            </div>
        </div>
    )
}

