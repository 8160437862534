import React from 'react'
import Form from 'react-bootstrap/Form'

/** Form group with corresponding label and automatic population of options
 * @param {string} className styles the entire group
 * @param {string} label sets the label
 * @param {boolean} disabled disables the combobox
 * @param {string} value sets the value of the combobox, given there pre-exists a value
 * @param {string} name sets the name of the combobox
 * @param {function(inputValue)} inputValue to forward the input from the combobox on change
 * @param {array[{id: '', option: ''}]} options selections for the combobox
 * @param {string} blankChoice sets the text of the placeholder selection
 * @param {boolean} hasLabel determines if the label should be removed completely
 */
export default function ComboBox({ className, label, disabled, value, name, inputValue, options, blankChoice, errorText, isInvalid = false, hasLabel = true, sm = false, style }) {
    return (
        <Form.Group className={className} style={style}>
            {hasLabel && <Form.Label>{label}</Form.Label>}
            <Form.Control as="select" isInvalid={isInvalid} className={`form-select shadow-none ${sm ? 'btn-sm' : 'btn-md'}`} disabled={disabled} value={value ?? ""} name={name} onChange={inputValue}>
                <option key='blankChoice' hidden value>{blankChoice}</option>
                {options.map(e => <option value={e.id} key={e.id}>{e.option}</option>)}
            </Form.Control>
            <Form.Control.Feedback type="invalid" tooltip>
                {errorText}
            </Form.Control.Feedback>
        </Form.Group>
    )
}