import React, {useEffect, useRef, useState} from 'react'
import {DashCircleFill, PlusCircle} from 'react-bootstrap-icons'
import DeviceNode from '../devicenode/DeviceNode'
import {dragStartEventHandler, dropHandler, toggleEditStyles, toggleGrabbingCursor} from '../interactions/Interactions'

/** Represents an individual group node in the hierarchy - Each node will render additional nodes if either devices or group children exists
 * @param {object} node object for the actual node and its corresponding devices and children
 * @param {[object]} selectedNodeList list of objects to pass with the current selected nodes
 * @param {function} selectNode Method to call when the node has been selected
 * @param {boolean} allowDragover Allows to perform dragover on items in the same tree
 * @param {boolean} isDraggable Allows to drag items
 * @param {function} itemDropped Method to call with an object, when a given object has been dropped in the hierarchy
 * @param {boolean} shouldDisplayDevices Determines if devices should be rendered in the tree
 * @param {boolean} canDragReadOnly
 * @param {function(string)} toggleGroupOpen open group with id
 * @param {object} groupIsOpenSet whether group should be open = not collapsed
 * @param {object} isVisibleSet set of ids denoting whether groups or devices should be visible to user
 */
export default function GroupNode({
                                      node, selectedNodeList, selectNode, allowDragover,
                                      isDraggable, itemDropped, shouldDisplayDevices, canDragReadOnly,
                                      toggleGroupOpen, groupIsOpenSet, isVisibleSet
                                  }) {
    const [canEdit, setCanEdit] = useState(true)
    const selectRef = useRef()

    useEffect(() => {
        toggleEditStyles(isDraggable, selectRef, canEdit)
    }, [selectedNodeList, isDraggable])

    useEffect(() => {
        setCanEdit(node.canEdit !== 0 || canDragReadOnly);
    }, [node, canDragReadOnly])

    const handleDragEnter = (e) => {
        e.currentTarget.classList.add('dragover-style')
        e.preventDefault()
    }
    const handleDragExit = (e) => {
        e.currentTarget.classList.remove('dragover-style')
        e.preventDefault()
    }
    const handleDragover = (e) => {
        if (node.canEdit || e.dataTransfer.types.includes('device')) {
            e.preventDefault()
        }
    }

    function filterSearchedGroups() {
        if (!isVisibleSet) return node.children
        return node.children.filter(g => isVisibleSet[g.id])
    }

    function filterSearchedDevices() {
        if (!isVisibleSet) return node.devices
        return node.devices.filter(device => isVisibleSet[device.id])
    }

    return (
        <React.Fragment key={node.id}>
            <li
                className="text-noselect list-unstyled cursor-pointer hover-effect text-secondary children-pointer-events-none"
                style={{padding: '1px'}} ref={selectRef}
                draggable={canEdit ? isDraggable : false}
                onMouseDown={() => (isDraggable && canEdit) ? toggleGrabbingCursor(selectRef) : undefined}
                onMouseUp={() => (isDraggable && canEdit) ? toggleGrabbingCursor(selectRef) : undefined}
                onDragStart={e => dragStartEventHandler(e, node, selectRef, node.type)}
                onDrop={e => allowDragover ? dropHandler(e, node, itemDropped, handleDragExit, selectedNodeList) : undefined}
                onDragOver={allowDragover ? handleDragover : undefined}
                onDragEnter={allowDragover ? handleDragEnter : undefined}
                onDragLeave={allowDragover ? handleDragExit : undefined}
                onClick={e => {
                    toggleGroupOpen(node.id)
                    selectNode(node, e)
                }}
            >
                {groupIsOpenSet[node.id] ? <DashCircleFill display='inline' className='me-2'/> :
                    <PlusCircle display='inline' className='me-2'/>}
                <span className='text-bold text-black'>{node.name}</span>
            </li>
            {groupIsOpenSet[node.id] && <div className="unstyled ps-3 ms-2 border-start mb-0">
                {filterSearchedGroups().map(childGroup =>
                    <GroupNode key={"frag-grp" + childGroup.id} node={childGroup}
                               shouldDisplayDevices={shouldDisplayDevices}
                               selectedNodeList={selectedNodeList} selectNode={selectNode} itemDropped={itemDropped}
                               allowDragover={allowDragover} isDraggable={isDraggable}
                               toggleGroupOpen={toggleGroupOpen} groupIsOpenSet={groupIsOpenSet}
                               isVisibleSet={isVisibleSet}
                    />
                )}
                {shouldDisplayDevices && filterSearchedDevices().map(device =>
                    <DeviceNode key={"frag-dev" + device.id} node={device}
                                selectedNodeList={selectedNodeList}
                                selectNode={selectNode} isDraggable={isDraggable}/>
                )}
            </div>}
        </React.Fragment>
    )
}
