import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import React from 'react';
import HeaderCell from '../../../components/shared/headercell/HeaderCell.js';
import {formatDateTimeString} from "../../../helpers/helperfunctions.js";

/**
 * @param className
 * @param {[object]} emailList
 * @param {{sortValue: string, sortDirection: 'asc' | 'desc'}} sort
 * @param {function} sortHandler
 * @returns {JSX.Element}
 * @constructor
 */
export default function AdminOperationsEmailTableBody({className, emailList, sort, sortHandler}) {

    const tableHeaderList = [
        {
            name: "Sendetidspunkt",
            sortValue: "timestamp",
            type: "string",
            display: "d-table-cell"
        },
        {
            name: "Success",
            sortValue: "success",
            type: "string",
            display: "d-table-cell"
        },
        {
            name: 'Fra',
            sortValue: "from",
            type: "string",
            display: "d-table-cell"
        },
        {
            name: 'Til',
            sortValue: "to",
            type: "string",
            display: "d-table-cell"
        },
        {
            name: 'Titel',
            sortValue: "subject",
            type: "string",
            display: "d-table-cell"
        },
        {
            name: 'størrelse',
            sortValue: "messageSize",
            type: "int",
            display: "d-table-cell"
        }
    ]

    return (
        <div className={className}>
            <TableContainer className='pe-0 ps-0' sx={{}}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow hover>
                            {tableHeaderList.map((obj, i) => {
                                let sx = {backgroundColor: "#ededed", borderRight: "1px solid #d9d9d9"}
                                if (i === 0) {
                                    sx = {
                                        backgroundColor: "#ededed",
                                        borderRight: "1px solid #d9d9d9",
                                        borderTopLeftRadius: "0.8rem"
                                    }
                                } else if (i === tableHeaderList.length - 1) {
                                    sx = {backgroundColor: "#ededed", borderTopRightRadius: "0.8rem"}
                                }
                                return <HeaderCell key={obj.name} sortHandler={sortHandler} headerObj={obj}
                                                   sort={sort} sx={sx} alignment={"center"}
                                                   className={obj.display}/>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emailList.map(row => (
                            <TableRow
                                key={row.timestamp}
                                sx={{
                                    backgroundColor: 'white',
                                    "td": {
                                        borderBottom: "1px solid #d9d9d9",
                                        borderTop: "1px solid #d9d9d9",
                                        borderLeft: "1px solid #d9d9d9"
                                    },
                                    "td:last-child": {
                                        borderRight: "1px solid #d9d9d9"
                                    }
                                }}>

                                <TableCell className='d-table-cell' align="left">{formatDateTimeString(row.timestamp, true, true, true)}</TableCell>
                                <TableCell className='d-table-cell' align="left">{row.rejected.length < 4 ? "Ja" : "Nej"}</TableCell>
                                <TableCell className='d-table-cell' align="left">{row.from}</TableCell>
                                <TableCell className='d-table-cell' align="left">{JSON.parse(row.to)[0]}</TableCell>
                                <TableCell className='d-table-cell' align="left">{row.subject}</TableCell>
                                <TableCell className='d-table-cell' align="left">{row.messageSize}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}