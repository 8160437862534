import {useState} from 'react'
import Checkbox from '../../../../components/shared/checkbox/CheckBox'
import FromToDatePickers from '../../../shared/datepickers/FromToDatePickers.js'
import RadioButton from '../../../shared/radiobutton/RadioButton.js'
import {Button} from "react-bootstrap";
import {useTranslation} from 'react-i18next'
import {hasAnyRole, isViewer} from "../../../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";
import {roleConstants} from "../../../../helpers/constants.js";
import moment from "moment";
import {DatePeriods} from "../TelemetryCharts.js";

/** Displays the selector for the periods in the telemetry charts component. The following component can toggle between selecting discrete dates and an actual date timespan.
 * @param {string} className classes to wrap the component
 * @param {object} periodFilter
 * @param {function(object)} setPeriodFilter
 * @param {boolean} showDatesFilter
 * @param {function(boolean)} setShowDatesFilter
 * */
export default function PeriodSelector({
                                           className, periodFilter, setPeriodFilter,
                                           showDatesFilter, setShowDatesFilter
                                       }) {
    const {t} = useTranslation(['dashboard'])
    const {accounts} = useMsal();

    const [tempContinuousStartDate, setTempContinuousStartDate] = useState("")
    const [tempContinuousEndDate, setTempContinuousEndDate] = useState("")

    const submitDayPickers = () => {
        setPeriodFilter({
            ...periodFilter,
            continuousStartDate: tempContinuousStartDate,
            continuousEndDate: moment(tempContinuousEndDate).endOf("day").toISOString()
        })
    }

    const setPeriodFilterDiscreteValue = (value) => {
        setPeriodFilter({
            ...periodFilter,
            discretePeriodSelected: value
        })
    };

    function setShowDatesFilterAndMaybeRefresh(newValue) {
        // As a small hack to get graph to refresh when going back to discrete, we set periodFilter to itself so the useEffect runs
        if (newValue === false) setPeriodFilter({...periodFilter})
        setShowDatesFilter(newValue)
    }

    return (
        <div className={className} style={{gap: "10px"}}>
            {hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic, roleConstants.Premium]) &&
                <Checkbox controlId="dateSelector" label={t('dashboard:periodSelector.show_dates_checkbox')}
                          className='align-self-center me-md-2 mb-xs-2' checked={showDatesFilter}
                          onChange={e => setShowDatesFilterAndMaybeRefresh(e.target.checked)}/>
            }

            <div className={`${showDatesFilter ? 'd-none' : 'd-flex'}`}>
                <RadioButton className='me-2' value={DatePeriods.Day}
                             setSelectedValue={() => setPeriodFilterDiscreteValue(DatePeriods.Day)}
                             selectedValue={periodFilter.discretePeriodSelected}
                             label={t('dashboard:periodSelector.day')}/>
                <RadioButton className='me-2' value={DatePeriods.Week}
                             setSelectedValue={() => setPeriodFilterDiscreteValue(DatePeriods.Week)}
                             selectedValue={periodFilter.discretePeriodSelected}
                             label={t('dashboard:periodSelector.week')}/>
                {!isViewer(accounts) &&
                    <RadioButton value={DatePeriods.Month}
                                 setSelectedValue={() => setPeriodFilterDiscreteValue(DatePeriods.Month)}
                                 selectedValue={periodFilter.discretePeriodSelected}
                                 label={t('dashboard:periodSelector.month')}/>
                }
            </div>

            <FromToDatePickers
                className={`${showDatesFilter ? 'd-flex align-items-center' : 'd-none'}`}
                monthSpan={6} yearSpan={1} fromValue={tempContinuousStartDate} toValue={tempContinuousEndDate}
                setFromDate={setTempContinuousStartDate} setToDate={setTempContinuousEndDate}
            />

            <Button onClick={submitDayPickers} variant='outline-secondary'
                    className={`${showDatesFilter ? 'd-flex' : 'd-none'} ms-2`}>
                {t('dashboard:periodSelector.submit_datepickers')}
            </Button>
        </div>
    )
}
