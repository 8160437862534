import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import 'react-phone-input-2/lib/style.css'
import UserManagement from '../../components/userpage/usermanagement/UserManagement.js'
import {fetchAdministeredUsersList} from '../../helpers/reduxstore/reducers/userAdministrationReducer.js'
import {useMsal} from '@azure/msal-react'
import UserProfile from '../../components/userpage/userprofile/UserProfile.js'
import useSetLocation from "../../helpers/hooks/useSetLocation.js"
import {hasAnyRole} from "../../helpers/authHelper.js"
import {roleConstants} from "../../helpers/constants.js"
import {Col} from "react-bootstrap"

/** Renders user details for the user to edit.
 * The userdetails is saved in a global state, making it available for other components to use */
export default function UserPage() {
    useSetLocation()
    const dispatch = useDispatch()
    const {accounts} = useMsal()

    useEffect(() => {
        if (accounts[0]) dispatch(fetchAdministeredUsersList())
    }, [accounts])

    return (
        <div className='container-fluid'>
            {/*<TopInformation className='row' title={t('userpage:info_section.info_section_title')}*/}
            {/*                subTitle={t('userpage:info_section.info_section_p')}/>*/}
            <div className="d-block d-lg-flex py-4">
                {!hasAnyRole(accounts, [roleConstants.Viever, roleConstants.Basic]) &&
                    <>
                        <Col
                            className='col-md-12 col-lg-4 col-sm-12 col-12 col-xxl-4 me-0 ps-0 pe-md-0 pe-sm-0 pe-0 pe-lg-2 mw-lg-600'>
                            <UserProfile/>
                        </Col>
                        <Col
                            className=' pb-sm-4 pb-4 pb-md-4 pb-lg-0 col-lg-8 col-md-12 col-xxl-8 pe-0 col-sm-12 col-12 pe-0 ps-sm-0 ps-0 ps-md-0 ps-lg-2 pt-sm-4 pt-md-4 pt-lg-0 pt-4'>
                            <UserManagement/>
                        </Col>
                    </>
                }
                {hasAnyRole(accounts, [roleConstants.Viever, roleConstants.Basic]) &&
                    <UserProfile style={{maxWidth: "800px"}}/>}
            </div>
        </div>
    )
}