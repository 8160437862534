
/** This file has been generated. If you see errors or missing parameters, don't change this file. Instead, fix generate_aware_parameters_specification_from_json.js and regenerate it. */
export const awareParamSpec = {
    "default_flow": {
        "id": "default_flow",
        "param_no": 10000,
        "parent": 2000,
        "name": "Default Flow",
        "name_orig": "Default Flow",
        "desc": "Flow set, if the actual starting signal does not provide one (i.e. PIR, START etc.)",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "default_temp": {
        "id": "default_temp",
        "param_no": 10001,
        "parent": 2000,
        "name": "Default Temp",
        "name_orig": "Default Temp",
        "desc": "UDT set, if the actual starting signal does not provide one (i.e. PIR, START etc.)",
        "type": "decimal",
        "min": "-30.0",
        "max": "50.0",
        "res": "",
        "default": "0.0",
        "unit": "°C",
        "options": ""
    },
    "delta_temp_heating": {
        "id": "delta_temp_heating",
        "param_no": 10002,
        "parent": 3000,
        "name": "Delta temp heating",
        "name_orig": "Delta temp heating",
        "desc": "The sum of 'target room temperature' and this value (hence a negative value) becomes the actual target temperature when heating",
        "type": "decimal",
        "min": "-20.0",
        "max": "0.0",
        "res": "",
        "default": "-1.0",
        "unit": "°C",
        "options": ""
    },
    "delta_temp_cooling": {
        "id": "delta_temp_cooling",
        "param_no": 10003,
        "parent": 3000,
        "name": "Delta temp cooling",
        "name_orig": "Delta temp cooling",
        "desc": "The sum of 'target room temperature' and this value becomes the actual target temperature when cooling",
        "type": "decimal",
        "min": "0.0",
        "max": "20.0",
        "res": "",
        "default": "1.0",
        "unit": "°C",
        "options": ""
    },
    "min_flow_setpoint": {
        "id": "min_flow_setpoint",
        "param_no": 10004,
        "parent": 3000,
        "name": "Min Flow Setpoint",
        "name_orig": "Min Flow Setpoint",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "max_flow_setpoint": {
        "id": "max_flow_setpoint",
        "param_no": 10005,
        "parent": 3000,
        "name": "Max Flow Setpoint",
        "name_orig": "Max Flow Setpoint",
        "desc": "OBS. can be overridden by internal procedures",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "min_temp_setpoint": {
        "id": "min_temp_setpoint",
        "param_no": 10006,
        "parent": 3000,
        "name": "Min Temp Setpoint",
        "name_orig": "Min Temp Setpoint",
        "desc": "",
        "type": "decimal",
        "min": "10.0",
        "max": "35.0",
        "res": "",
        "default": "18.0",
        "unit": "°C",
        "options": ""
    },
    "max_temp_setpoint": {
        "id": "max_temp_setpoint",
        "param_no": 10007,
        "parent": 3000,
        "name": "Max Temp Setpoint",
        "name_orig": "Max Temp Setpoint",
        "desc": "",
        "type": "decimal",
        "min": "10.0",
        "max": "35.0",
        "res": "",
        "default": "28.0",
        "unit": "°C",
        "options": ""
    },
    "co2_control_enabled": {
        "id": "co2_control_enabled",
        "param_no": 10008,
        "parent": 4000,
        "name": "CO2 Control Enabled",
        "name_orig": "CO2 Control Enabled",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "co2_control_min": {
        "id": "co2_control_min",
        "param_no": 10009,
        "parent": 4000,
        "name": "CO2 Control Min",
        "name_orig": "CO2 Control Min",
        "desc": "CO2 concentration above this level, will cause a positive override of the flow",
        "type": "int",
        "min": "400",
        "max": "5000",
        "res": "",
        "default": "650",
        "unit": "ppm",
        "options": ""
    },
    "co2_control_max": {
        "id": "co2_control_max",
        "param_no": 10010,
        "parent": 4000,
        "name": "CO2 Control Max",
        "name_orig": "CO2 Control Max",
        "desc": "The CO2 level causing maximum air flow",
        "type": "int",
        "min": "400",
        "max": "5000",
        "res": "",
        "default": "850",
        "unit": "ppm",
        "options": ""
    },
    "pir_enabled": {
        "id": "pir_enabled",
        "param_no": 10011,
        "parent": 5000,
        "name": "PIR Enabled",
        "name_orig": "PIR Enabled",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "pir_afterrun_timer": {
        "id": "pir_afterrun_timer",
        "param_no": 10012,
        "parent": 5000,
        "name": "PIR Afterrun Timer",
        "name_orig": "PIR Afterrun Timer",
        "desc": "The amount of time the PIR start signal continuous to be active after last presence detection (max 99 hours)",
        "type": "int",
        "min": "0",
        "max": "1440",
        "res": "",
        "default": "30",
        "unit": "min",
        "options": ""
    },
    "extern_start_delay_time": {
        "id": "extern_start_delay_time",
        "param_no": 10013,
        "parent": 6000,
        "name": "Extern Start Delay Time",
        "name_orig": "Extern Start Delay Time",
        "desc": "Delay in seconds before extern start signal is accepted",
        "type": "int",
        "min": "0",
        "max": "1440",
        "res": "",
        "default": "5",
        "unit": "s",
        "options": ""
    },
    "extern_start_afterrun_timer": {
        "id": "extern_start_afterrun_timer",
        "param_no": 10014,
        "parent": 6000,
        "name": "Extern Start Afterrun Timer",
        "name_orig": "Extern Start Afterrun Timer",
        "desc": "The amount of time the external start signal continuous to be active after last detection (max 99 hours)",
        "type": "int",
        "min": "0",
        "max": "1440",
        "res": "",
        "default": "10",
        "unit": "min",
        "options": ""
    },
    "extern_start_auto_off_timer": {
        "id": "extern_start_auto_off_timer",
        "param_no": 10015,
        "parent": 6000,
        "name": "Extern Start Auto Off Timer",
        "name_orig": "Extern Start Auto Off Timer",
        "desc": "The amount after which the external start signal is canceled, even though pyhsical signal is still present (max 99 hours)",
        "type": "int",
        "min": "0",
        "max": "1440",
        "res": "",
        "default": "480",
        "unit": "min",
        "options": ""
    },
    "extern_start_analog_in_threshold_voltage": {
        "id": "extern_start_analog_in_threshold_voltage",
        "param_no": 10016,
        "parent": 6000,
        "name": "Extern Start Analog In Threshold Voltage",
        "name_orig": "Extern Start Analog In Threshold Voltage",
        "desc": "The threshold Voltage for witch extern start is activated from analog in",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "1500",
        "unit": "mV",
        "options": ""
    },
    "allow_boost": {
        "id": "allow_boost",
        "param_no": 10017,
        "parent": 7000,
        "name": "Allow Boost",
        "name_orig": "Allow Boost",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "True",
        "unit": "-",
        "options": ""
    },
    "boost_afterrun_time": {
        "id": "boost_afterrun_time",
        "param_no": 10018,
        "parent": 7000,
        "name": "Boost Afterrun Time",
        "name_orig": "Boost Afterrun Time",
        "desc": "max 99 hours",
        "type": "int",
        "min": "0",
        "max": "1440",
        "res": "",
        "default": "30",
        "unit": "min",
        "options": ""
    },
    "boost_auto_off_time": {
        "id": "boost_auto_off_time",
        "param_no": 10019,
        "parent": 7000,
        "name": "Boost Auto Off Time",
        "name_orig": "Boost Auto Off Time",
        "desc": "max 99 hours",
        "type": "int",
        "min": "0",
        "max": "1440",
        "res": "",
        "default": "0",
        "unit": "min",
        "options": ""
    },
    "boost_max_sf_pct": {
        "id": "boost_max_sf_pct",
        "param_no": 10020,
        "parent": 7000,
        "name": "Boost Max SF pct",
        "name_orig": "Boost Max SF pct",
        "desc": "max supply fan voltage when Boost is requested",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "70.0",
        "unit": "%",
        "options": ""
    },
    "boost_max_ef_pct": {
        "id": "boost_max_ef_pct",
        "param_no": 10021,
        "parent": 7000,
        "name": "Boost Max EF pct",
        "name_orig": "Boost Max EF pct",
        "desc": "max exhaust fan voltage when Boost is requested",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "80.0",
        "unit": "%",
        "options": ""
    },
    "start_priority_1": {
        "id": "start_priority_1",
        "param_no": 10022,
        "parent": 8000,
        "name": "Start priority 1",
        "name_orig": "Start priority 1",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "6",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_2": {
        "id": "start_priority_2",
        "param_no": 10023,
        "parent": 8000,
        "name": "Start priority 2",
        "name_orig": "Start priority 2",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "6",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_3": {
        "id": "start_priority_3",
        "param_no": 10024,
        "parent": 8000,
        "name": "Start priority 3",
        "name_orig": "Start priority 3",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "6",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_4": {
        "id": "start_priority_4",
        "param_no": 10025,
        "parent": 8000,
        "name": "Start priority 4",
        "name_orig": "Start priority 4",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "6",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_5": {
        "id": "start_priority_5",
        "param_no": 10026,
        "parent": 8000,
        "name": "Start priority 5",
        "name_orig": "Start priority 5",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "6",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_6": {
        "id": "start_priority_6",
        "param_no": 10027,
        "parent": 8000,
        "name": "Start priority 6",
        "name_orig": "Start priority 6",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "6",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_dependency": {
        "id": "start_priority_dependency",
        "param_no": 10028,
        "parent": 8000,
        "name": "Start Priority Dependency",
        "name_orig": "Start Priority Dependency",
        "desc": "Whether the start priorities are independent or dependent on each other",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "start_priority_night_cooling_independent": {
        "id": "start_priority_night_cooling_independent",
        "param_no": 10029,
        "parent": 8000,
        "name": "Start Priority Night Cooling Independent",
        "name_orig": "Start Priority Night Cooling Independent",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "True",
        "unit": "-",
        "options": ""
    },
    "status_led_active_start_time": {
        "id": "status_led_active_start_time",
        "param_no": 10030,
        "parent": 10000,
        "name": "Status LED Active Start Time",
        "name_orig": "Status LED Active Start Time",
        "desc": "Minutes since midnight",
        "type": "int",
        "min": "0",
        "max": "1439",
        "res": "",
        "default": "0",
        "unit": "min",
        "options": ""
    },
    "status_led_active_stop_time": {
        "id": "status_led_active_stop_time",
        "param_no": 10031,
        "parent": 10000,
        "name": "Status LED Active Stop Time",
        "name_orig": "Status LED Active Stop Time",
        "desc": "Minutes since midnight",
        "type": "int",
        "min": "0",
        "max": "1439",
        "res": "",
        "default": "0",
        "unit": "min",
        "options": ""
    },
    "status_led_on_when_running_normal": {
        "id": "status_led_on_when_running_normal",
        "param_no": 10032,
        "parent": 10000,
        "name": "Status LED On When Running Normal",
        "name_orig": "Status LED On When Running Normal",
        "desc": "Controls whether the LED should be green when no warnings or alarms are present",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "status_led_installed": {
        "id": "status_led_installed",
        "param_no": 10033,
        "parent": 10000,
        "name": "Status LED Installed",
        "name_orig": "Status LED Installed",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "timer_schedule_1_enabled": {
        "id": "timer_schedule_1_enabled",
        "param_no": 10195,
        "parent": 11000,
        "name": "Timer Schedule 1 Enabled",
        "name_orig": "Timer Schedule 1 Enabled",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "timer_schedule_1_mode": {
        "id": "timer_schedule_1_mode",
        "param_no": 10034,
        "parent": 11000,
        "name": "Timer Schedule 1 Mode",
        "name_orig": "Timer Schedule 1 Mode",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Standby Permanent",
            "1": "Comfort",
            "2": "Night Cooling",
            "3": "Holiday"
        }
    },
    "timer_schedule_1_days": {
        "id": "timer_schedule_1_days",
        "param_no": 10035,
        "parent": 11000,
        "name": "Timer Schedule 1 Days",
        "name_orig": "Timer Schedule 1 Days",
        "desc": "{0=[Monday|Inactive|Active], 1=[Tuesday|Inactive|Active], 2=[wednesday|Inactive|Active], 3=[Thursday|Inactive|Active, 4=[Friday|Inactive|Active, 5=[Saturday|Inactive|Active, 6=[Sunday|Inactive|Active}",
        "type": "int",
        "min": "0",
        "max": "127",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": ""
    },
    "timer_schedule_1_start_time": {
        "id": "timer_schedule_1_start_time",
        "param_no": 10036,
        "parent": 11000,
        "name": "Timer Schedule 1 Start Time",
        "name_orig": "Timer Schedule 1 Start Time",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "1439",
        "res": "",
        "default": "0",
        "unit": "min",
        "options": ""
    },
    "timer_schedule_1_duration": {
        "id": "timer_schedule_1_duration",
        "param_no": 10037,
        "parent": 11000,
        "name": "Timer Schedule 1 Duration",
        "name_orig": "Timer Schedule 1 Duration",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "1439",
        "res": "",
        "default": "0",
        "unit": "min",
        "options": ""
    },
    "timer_schedule_1_flow": {
        "id": "timer_schedule_1_flow",
        "param_no": 10038,
        "parent": 11000,
        "name": "Timer Schedule 1 Flow",
        "name_orig": "Timer Schedule 1 Flow",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "timer_schedule_1_temperature": {
        "id": "timer_schedule_1_temperature",
        "param_no": 10039,
        "parent": 11000,
        "name": "Timer Schedule 1 Temperature",
        "name_orig": "Timer Schedule 1 Temperature",
        "desc": "",
        "type": "decimal",
        "min": "8.0",
        "max": "50.0",
        "res": "",
        "default": "23.0",
        "unit": "°C",
        "options": ""
    },
    "timer_schedule_2_enabled": {
        "id": "timer_schedule_2_enabled",
        "param_no": 10196,
        "parent": 12000,
        "name": "Timer Schedule 2 Enabled",
        "name_orig": "Timer Schedule 2 Enabled",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "timer_schedule_2_mode": {
        "id": "timer_schedule_2_mode",
        "param_no": 10040,
        "parent": 12000,
        "name": "Timer Schedule 2 Mode",
        "name_orig": "Timer Schedule 2 Mode",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "Standby Permanent",
            "1": "Comfort",
            "2": "Night Cooling",
            "3": "Holiday"
        }
    },
    "timer_schedule_2_days": {
        "id": "timer_schedule_2_days",
        "param_no": 10041,
        "parent": 12000,
        "name": "Timer Schedule 2 Days",
        "name_orig": "Timer Schedule 2 Days",
        "desc": "{0=[Monday|Inactive|Active], 1=[Tuesday|Inactive|Active], 2=[wednesday|Inactive|Active], 3=[Thursday|Inactive|Active, 4=[Friday|Inactive|Active, 5=[Saturday|Inactive|Active, 6=[Sunday|Inactive|Active}",
        "type": "int",
        "min": "0",
        "max": "127",
        "res": "",
        "default": "31",
        "unit": "-",
        "options": ""
    },
    "timer_schedule_2_start_time": {
        "id": "timer_schedule_2_start_time",
        "param_no": 10042,
        "parent": 12000,
        "name": "Timer Schedule 2 Start Time",
        "name_orig": "Timer Schedule 2 Start Time",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "1439",
        "res": "",
        "default": "420",
        "unit": "min",
        "options": ""
    },
    "timer_schedule_2_duration": {
        "id": "timer_schedule_2_duration",
        "param_no": 10043,
        "parent": 12000,
        "name": "Timer Schedule 2 Duration",
        "name_orig": "Timer Schedule 2 Duration",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "1439",
        "res": "",
        "default": "600",
        "unit": "min",
        "options": ""
    },
    "timer_schedule_2_flow": {
        "id": "timer_schedule_2_flow",
        "param_no": 10044,
        "parent": 12000,
        "name": "Timer Schedule 2 Flow",
        "name_orig": "Timer Schedule 2 Flow",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "timer_schedule_2_temperature": {
        "id": "timer_schedule_2_temperature",
        "param_no": 10045,
        "parent": 12000,
        "name": "Timer Schedule 2 Temperature",
        "name_orig": "Timer Schedule 2 Temperature",
        "desc": "",
        "type": "decimal",
        "min": "8.0",
        "max": "50.0",
        "res": "",
        "default": "23.0",
        "unit": "°C",
        "options": ""
    },
    "time_zone": {
        "id": "time_zone",
        "param_no": 10046,
        "parent": 13000,
        "name": "Time Zone",
        "name_orig": "Time Zone",
        "desc": "",
        "type": "string",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "Europe/Copenhagen",
        "unit": "-",
        "options": ""
    },
    "night_cooling_low_limit": {
        "id": "night_cooling_low_limit",
        "param_no": 10047,
        "parent": 14000,
        "name": "Night Cooling Low Limit",
        "name_orig": "Night Cooling Low Limit",
        "desc": "Night cooling: Low limit. Daytime temperature to maintain Night Cooling",
        "type": "decimal",
        "min": "0.0",
        "max": "50.0",
        "res": "",
        "default": "23.0",
        "unit": "°C",
        "options": ""
    },
    "night_cooling_high_limit": {
        "id": "night_cooling_high_limit",
        "param_no": 10048,
        "parent": 14000,
        "name": "Night Cooling High Limit",
        "name_orig": "Night Cooling High Limit",
        "desc": "Night cooling: High limit. Daytime temperature to initiate Night Cooling",
        "type": "decimal",
        "min": "0.0",
        "max": "50.0",
        "res": "",
        "default": "26.0",
        "unit": "°C",
        "options": ""
    },
    "night_cooling_min_ot": {
        "id": "night_cooling_min_ot",
        "param_no": 10049,
        "parent": 14000,
        "name": "Night Cooling Min OT",
        "name_orig": "Night Cooling Min OT",
        "desc": "Night cooling: minimum outside temperature",
        "type": "decimal",
        "min": "-20.0",
        "max": "50.0",
        "res": "",
        "default": "5.0",
        "unit": "°C",
        "options": ""
    },
    "external_emergency_function": {
        "id": "external_emergency_function",
        "param_no": 10051,
        "parent": 15000,
        "name": "External Emergency Function",
        "name_orig": "External Emergency Function",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "None",
            "1": "Warning",
            "2": "Alarm"
        }
    },
    "external_emergency_contact": {
        "id": "external_emergency_contact",
        "param_no": 10052,
        "parent": 15000,
        "name": "External Emergency Contact",
        "name_orig": "External Emergency Contact",
        "desc": "if the External Emergency contact is \"Normal Open\" or \"Normal Closed\"",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "NO",
            "1": "NC"
        }
    },
    "unit_name": {
        "id": "unit_name",
        "param_no": 10194,
        "parent": 16000,
        "name": "Unit Name",
        "name_orig": "Unit Name",
        "desc": "Displayed name of the unit",
        "type": "string",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "",
        "unit": "-",
        "options": ""
    },
    "manual_override_time": {
        "id": "manual_override_time",
        "param_no": 10067,
        "parent": 16000,
        "name": "Manual Override Time",
        "name_orig": "Manual Override Time",
        "desc": "OBS: the 99h max, is due to limits in the App (visual implementation)",
        "type": "int",
        "min": "0",
        "max": "99",
        "res": "",
        "default": "12",
        "unit": "hour",
        "options": ""
    },
    "co2_level_grey": {
        "id": "co2_level_grey",
        "param_no": 10053,
        "parent": 17000,
        "name": "CO2 Level Grey",
        "name_orig": "CO2 Level Grey",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "300",
        "unit": "ppm",
        "options": ""
    },
    "co2_level_blue": {
        "id": "co2_level_blue",
        "param_no": 10054,
        "parent": 17000,
        "name": "CO2 Level Blue",
        "name_orig": "CO2 Level Blue",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "400",
        "unit": "ppm",
        "options": ""
    },
    "co2_level_dark_green": {
        "id": "co2_level_dark_green",
        "param_no": 10055,
        "parent": 17000,
        "name": "CO2 Level Dark Green",
        "name_orig": "CO2 Level Dark Green",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "500",
        "unit": "ppm",
        "options": ""
    },
    "co2_level_light_green": {
        "id": "co2_level_light_green",
        "param_no": 10056,
        "parent": 17000,
        "name": "CO2 Level Light Green",
        "name_orig": "CO2 Level Light Green",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "600",
        "unit": "ppm",
        "options": ""
    },
    "co2_level_yellow": {
        "id": "co2_level_yellow",
        "param_no": 10057,
        "parent": 17000,
        "name": "CO2 Level Yellow",
        "name_orig": "CO2 Level Yellow",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "1000",
        "unit": "ppm",
        "options": ""
    },
    "co2_level_orange": {
        "id": "co2_level_orange",
        "param_no": 10058,
        "parent": 17000,
        "name": "CO2 Level Orange",
        "name_orig": "CO2 Level Orange",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "2000",
        "unit": "ppm",
        "options": ""
    },
    "co2_level_red": {
        "id": "co2_level_red",
        "param_no": 10059,
        "parent": 17000,
        "name": "CO2 Level Red",
        "name_orig": "CO2 Level Red",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "5000",
        "unit": "ppm",
        "options": ""
    },
    "tvoc_level_grey": {
        "id": "tvoc_level_grey",
        "param_no": 10060,
        "parent": 17000,
        "name": "TVOC Level Grey",
        "name_orig": "TVOC Level Grey",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "65",
        "unit": "ppb",
        "options": ""
    },
    "tvoc_level_blue": {
        "id": "tvoc_level_blue",
        "param_no": 10061,
        "parent": 17000,
        "name": "TVOC Level Blue",
        "name_orig": "TVOC Level Blue",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "100",
        "unit": "ppb",
        "options": ""
    },
    "tvoc_level_dark_green": {
        "id": "tvoc_level_dark_green",
        "param_no": 10062,
        "parent": 17000,
        "name": "TVOC Level Dark Green",
        "name_orig": "TVOC Level Dark Green",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "150",
        "unit": "ppb",
        "options": ""
    },
    "tvoc_level_light_green": {
        "id": "tvoc_level_light_green",
        "param_no": 10063,
        "parent": 17000,
        "name": "TVOC Level Light Green",
        "name_orig": "TVOC Level Light Green",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "200",
        "unit": "ppb",
        "options": ""
    },
    "tvoc_level_yellow": {
        "id": "tvoc_level_yellow",
        "param_no": 10064,
        "parent": 17000,
        "name": "TVOC Level Yellow",
        "name_orig": "TVOC Level Yellow",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "220",
        "unit": "ppb",
        "options": ""
    },
    "tvoc_level_orange": {
        "id": "tvoc_level_orange",
        "param_no": 10065,
        "parent": 17000,
        "name": "TVOC Level Orange",
        "name_orig": "TVOC Level Orange",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "1100",
        "unit": "ppb",
        "options": ""
    },
    "tvoc_level_red": {
        "id": "tvoc_level_red",
        "param_no": 10066,
        "parent": 17000,
        "name": "TVOC Level Red",
        "name_orig": "TVOC Level Red",
        "desc": "",
        "type": "int",
        "min": "0",
        "max": "10000",
        "res": "",
        "default": "2200",
        "unit": "ppb",
        "options": ""
    },
    "am_unit_type": {
        "id": "am_unit_type",
        "param_no": 10050,
        "parent": 19000,
        "name": "AM Unit Type",
        "name_orig": "AM Unit Type",
        "desc": "The type of AM unit that the unit is.",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "2",
        "unit": "-",
        "options": {
            "0": "AMX4 Prototype",
            "1": "AMX4 Field Test",
            "2": "AMX4",
            "3": "AM Unit Max"
        }
    },
    "ccm_installed": {
        "id": "ccm_installed",
        "param_no": 10204,
        "parent": 19000,
        "name": "CCM Installed",
        "name_orig": "CCM Installed",
        "desc": "Customer Connection Module installed",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "co2_sensor_installed_ai": {
        "id": "co2_sensor_installed_ai",
        "param_no": 10068,
        "parent": 20000,
        "name": "CO2 Sensor Installed AI",
        "name_orig": "CO2 Sensor Installed AI",
        "desc": "CO2 sensor installed: Air Inlet",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "co2_sensor_installed_ar": {
        "id": "co2_sensor_installed_ar",
        "param_no": 10069,
        "parent": 20000,
        "name": "CO2 Sensor Installed AR",
        "name_orig": "CO2 Sensor Installed AR",
        "desc": "CO2 sensor installed: Air Room",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "co2_sensor_installed_ae": {
        "id": "co2_sensor_installed_ae",
        "param_no": 10070,
        "parent": 20000,
        "name": "CO2 Sensor Installed AE",
        "name_orig": "CO2 Sensor Installed AE",
        "desc": "CO2 sensor installed: Air Exhaust",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "co2_sensor_installed_ao": {
        "id": "co2_sensor_installed_ao",
        "param_no": 10071,
        "parent": 20000,
        "name": "CO2 Sensor Installed AO",
        "name_orig": "CO2 Sensor Installed AO",
        "desc": "CO2 sensor installed: Air Outside",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "analog_co2_sensor_ppm_range_min": {
        "id": "analog_co2_sensor_ppm_range_min",
        "param_no": 10072,
        "parent": 20000,
        "name": "Analog CO2 Sensor ppm Range Min",
        "name_orig": "Analog CO2 Sensor ppm Range Min",
        "desc": "Minimum CO2 concentration for the CO2 sensor",
        "type": "int",
        "min": "0",
        "max": "65000",
        "res": "",
        "default": "0",
        "unit": "ppm",
        "options": ""
    },
    "analog_co2_sensor_ppm_range_max": {
        "id": "analog_co2_sensor_ppm_range_max",
        "param_no": 10073,
        "parent": 20000,
        "name": "Analog CO2 Sensor ppm Range Max",
        "name_orig": "Analog CO2 Sensor ppm Range Max",
        "desc": "Maximum CO2 concentration for the CO2 sensor",
        "type": "int",
        "min": "0",
        "max": "65000",
        "res": "",
        "default": "2000",
        "unit": "ppm",
        "options": ""
    },
    "analog_co2_sensor_volt_range_min": {
        "id": "analog_co2_sensor_volt_range_min",
        "param_no": 10074,
        "parent": 20000,
        "name": "Analog CO2 Sensor Volt Range Min",
        "name_orig": "Analog CO2 Sensor Volt Range Min",
        "desc": "Minimum Volt for the CO2 sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "10.0",
        "res": "",
        "default": "0.0",
        "unit": "V",
        "options": ""
    },
    "analog_co2_sensor_volt_range_max": {
        "id": "analog_co2_sensor_volt_range_max",
        "param_no": 10075,
        "parent": 20000,
        "name": "Analog CO2 Sensor Volt Range Max",
        "name_orig": "Analog CO2 Sensor Volt Range Max",
        "desc": "Maximum Volt for the CO2 sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "10.0",
        "res": "",
        "default": "10.0",
        "unit": "V",
        "options": ""
    },
    "aa_mode": {
        "id": "aa_mode",
        "param_no": 10076,
        "parent": 22000,
        "name": "AA Mode",
        "name_orig": "AA Mode",
        "desc": "Adaptive Airflow Installed",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "aa_run_time": {
        "id": "aa_run_time",
        "param_no": 10077,
        "parent": 22000,
        "name": "AA Run Time",
        "name_orig": "AA Run Time",
        "desc": "RunTime, is the time it takes the actuator to move from end2end",
        "type": "int",
        "min": "0",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "sec",
        "options": ""
    },
    "aa_upward_direction": {
        "id": "aa_upward_direction",
        "param_no": 10078,
        "parent": 22000,
        "name": "AA Upward Direction",
        "name_orig": "AA Upward Direction",
        "desc": "Rotation direction to point the Adaptive Airflow Damper Upward",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "aa_cw_rotation": {
        "id": "aa_cw_rotation",
        "param_no": 10079,
        "parent": 22000,
        "name": "AA CW Rotation",
        "name_orig": "AA CW Rotation",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "aa_ccw_rotation": {
        "id": "aa_ccw_rotation",
        "param_no": 10080,
        "parent": 22000,
        "name": "AA CCW Rotation",
        "name_orig": "AA CCW Rotation",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "aa_self_calibration": {
        "id": "aa_self_calibration",
        "param_no": 10081,
        "parent": 22000,
        "name": "AA Self Calibration",
        "name_orig": "AA Self Calibration",
        "desc": "The number of actuator moves allowed, before the actuator performs a self-calibration",
        "type": "int",
        "min": "0",
        "max": "65500",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": ""
    },
    "bp_mode": {
        "id": "bp_mode",
        "param_no": 10082,
        "parent": 23000,
        "name": "BP Mode",
        "name_orig": "BP Mode",
        "desc": "Bypass installed",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "bp_run_time": {
        "id": "bp_run_time",
        "param_no": 10083,
        "parent": 23000,
        "name": "BP Run Time",
        "name_orig": "BP Run Time",
        "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
        "type": "int",
        "min": "0",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "sec",
        "options": ""
    },
    "bp_open_direction": {
        "id": "bp_open_direction",
        "param_no": 10084,
        "parent": 23000,
        "name": "BP Open Direction",
        "name_orig": "BP Open Direction",
        "desc": "Open Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "bp_cw_rotation": {
        "id": "bp_cw_rotation",
        "param_no": 10085,
        "parent": 23000,
        "name": "BP CW Rotation",
        "name_orig": "BP CW Rotation",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "bp_ccw_rotation": {
        "id": "bp_ccw_rotation",
        "param_no": 10086,
        "parent": 23000,
        "name": "BP CCW Rotation",
        "name_orig": "BP CCW Rotation",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "bp_self_calibration": {
        "id": "bp_self_calibration",
        "param_no": 10087,
        "parent": 23000,
        "name": "BP Self Calibration",
        "name_orig": "BP Self Calibration",
        "desc": "The number of actuator moves allowed, before the actuator performs a self-calibration",
        "type": "int",
        "min": "0",
        "max": "65500",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": ""
    },
    "bp_cw_offset": {
        "id": "bp_cw_offset",
        "param_no": 10088,
        "parent": 23000,
        "name": "BP CW Offset",
        "name_orig": "BP CW Offset",
        "desc": "TC: CW Offset [ms]. Calibration of time for CW rotation (Designed for compensation for \"inertia\" in the damper+actuator)",
        "type": "int",
        "min": "-999",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "ms",
        "options": ""
    },
    "bp_ccw_offset": {
        "id": "bp_ccw_offset",
        "param_no": 10089,
        "parent": 23000,
        "name": "BP CCW Offset",
        "name_orig": "BP CCW Offset",
        "desc": "TC: CCW Offset [ms]. Calibration of time for CCW rotation (Designed for compensation for \"inertia\" in the damper+actuator)",
        "type": "int",
        "min": "-999",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "ms",
        "options": ""
    },
    "bp_min_movement": {
        "id": "bp_min_movement",
        "param_no": 10090,
        "parent": 23000,
        "name": "BP Min Movement",
        "name_orig": "BP Min Movement",
        "desc": "Minimum movement time for the bypass damper, to avoid flutter",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "5.0",
        "unit": "%",
        "options": ""
    },
    "mds_mode": {
        "id": "mds_mode",
        "param_no": 10091,
        "parent": 24000,
        "name": "MDS Mode",
        "name_orig": "MDS Mode",
        "desc": "Main Damper installed",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "mds_run_time": {
        "id": "mds_run_time",
        "param_no": 10092,
        "parent": 24000,
        "name": "MDS Run Time",
        "name_orig": "MDS Run Time",
        "desc": "The time it takes for the MD damper to operate from 0-100%",
        "type": "int",
        "min": "0",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "s",
        "options": ""
    },
    "mds_open_direction": {
        "id": "mds_open_direction",
        "param_no": 10093,
        "parent": 24000,
        "name": "MDS Open Direction",
        "name_orig": "MDS Open Direction",
        "desc": "Open Main Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "mds_cw_rotation": {
        "id": "mds_cw_rotation",
        "param_no": 10094,
        "parent": 24000,
        "name": "MDS CW Rotation",
        "name_orig": "MDS CW Rotation",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "mds_ccw_rotation": {
        "id": "mds_ccw_rotation",
        "param_no": 10095,
        "parent": 24000,
        "name": "MDS CCW Rotation",
        "name_orig": "MDS CCW Rotation",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "mde_mode": {
        "id": "mde_mode",
        "param_no": 10096,
        "parent": 24000,
        "name": "MDE Mode",
        "name_orig": "MDE Mode",
        "desc": "ON handles both \"TimeControlled\" and \"Self Return\"",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "mde_run_time": {
        "id": "mde_run_time",
        "param_no": 10097,
        "parent": 24000,
        "name": "MDE Run Time",
        "name_orig": "MDE Run Time",
        "desc": "The time it takes for the MD damper to operate from 0-100%",
        "type": "int",
        "min": "0",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "s",
        "options": ""
    },
    "mde_open_direction": {
        "id": "mde_open_direction",
        "param_no": 10098,
        "parent": 24000,
        "name": "MDE Open Direction",
        "name_orig": "MDE Open Direction",
        "desc": "Open Main Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "mde_cw_rotation": {
        "id": "mde_cw_rotation",
        "param_no": 10099,
        "parent": 24000,
        "name": "MDE CW Rotation",
        "name_orig": "MDE CW Rotation",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "mde_ccw_rotation": {
        "id": "mde_ccw_rotation",
        "param_no": 10100,
        "parent": 24000,
        "name": "MDE CCW Rotation",
        "name_orig": "MDE CCW Rotation",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "md_min_opening": {
        "id": "md_min_opening",
        "param_no": 10101,
        "parent": 24000,
        "name": "MD Min Opening",
        "name_orig": "MD Min Opening",
        "desc": "The minimum MD open% before setting \"status = running\"",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "50.0",
        "unit": "%",
        "options": ""
    },
    "rci_mode": {
        "id": "rci_mode",
        "param_no": 10102,
        "parent": 25000,
        "name": "RCI Mode",
        "name_orig": "RCI Mode",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "rci_run_time": {
        "id": "rci_run_time",
        "param_no": 10103,
        "parent": 25000,
        "name": "RCI Run Time",
        "name_orig": "RCI Run Time",
        "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
        "type": "int",
        "min": "0",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "sec",
        "options": ""
    },
    "rci_open_direction": {
        "id": "rci_open_direction",
        "param_no": 10104,
        "parent": 25000,
        "name": "RCI Open Direction",
        "name_orig": "RCI Open Direction",
        "desc": "Open Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "rci_cw_rotation": {
        "id": "rci_cw_rotation",
        "param_no": 10105,
        "parent": 25000,
        "name": "RCI CW Rotation",
        "name_orig": "RCI CW Rotation",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "rci_ccw_rotation": {
        "id": "rci_ccw_rotation",
        "param_no": 10106,
        "parent": 25000,
        "name": "RCI CCW Rotation",
        "name_orig": "RCI CCW Rotation",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "rco_mode": {
        "id": "rco_mode",
        "param_no": 10107,
        "parent": 25000,
        "name": "RCO Mode",
        "name_orig": "RCO Mode",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "rco_run_time": {
        "id": "rco_run_time",
        "param_no": 10108,
        "parent": 25000,
        "name": "RCO Run Time",
        "name_orig": "RCO Run Time",
        "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
        "type": "int",
        "min": "0",
        "max": "999",
        "res": "",
        "default": "0",
        "unit": "sec",
        "options": ""
    },
    "rco_open_direction": {
        "id": "rco_open_direction",
        "param_no": 10109,
        "parent": 25000,
        "name": "RCO Open Direction",
        "name_orig": "RCO Open Direction",
        "desc": "Open Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "rco_cw_rotation": {
        "id": "rco_cw_rotation",
        "param_no": 10110,
        "parent": 25000,
        "name": "RCO CW Rotation",
        "name_orig": "RCO CW Rotation",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "rco_ccw_rotation": {
        "id": "rco_ccw_rotation",
        "param_no": 10111,
        "parent": 25000,
        "name": "RCO CCW Rotation",
        "name_orig": "RCO CCW Rotation",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "fan_supply_control": {
        "id": "fan_supply_control",
        "param_no": 10112,
        "parent": 26000,
        "name": "Fan Supply Control",
        "name_orig": "Fan Supply Control",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Voltage",
            "2": "PWM",
            "3": "Modbus"
        }
    },
    "fan_supply_tacho_ppr": {
        "id": "fan_supply_tacho_ppr",
        "param_no": 10113,
        "parent": 26000,
        "name": "Fan Supply Tacho PPR",
        "name_orig": "Fan Supply Tacho PPR",
        "desc": "Tacho Pulses pr. Revolution",
        "type": "int",
        "min": "0",
        "max": "255",
        "res": "",
        "default": "0",
        "unit": "puls/revolution",
        "options": ""
    },
    "fan_supply_min_pct": {
        "id": "fan_supply_min_pct",
        "param_no": 10114,
        "parent": 26000,
        "name": "Fan Supply Min pct",
        "name_orig": "Fan Supply Min pct",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "fan_supply_vent_max_pct": {
        "id": "fan_supply_vent_max_pct",
        "param_no": 10197,
        "parent": 26000,
        "name": "Fan Supply Vent Max pct",
        "name_orig": "Fan Supply Vent Max pct",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "fan_supply_recirc_max_pct": {
        "id": "fan_supply_recirc_max_pct",
        "param_no": 10198,
        "parent": 26000,
        "name": "Fan Supply Recirc Max pct",
        "name_orig": "Fan Supply Recirc Max pct",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "fan_extraction_control": {
        "id": "fan_extraction_control",
        "param_no": 10116,
        "parent": 26000,
        "name": "Fan Extraction Control",
        "name_orig": "Fan Extraction Control",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Voltage",
            "2": "PWM",
            "3": "Modbus"
        }
    },
    "fan_extraction_tacho_ppr": {
        "id": "fan_extraction_tacho_ppr",
        "param_no": 10117,
        "parent": 26000,
        "name": "Fan Extraction Tacho PPR",
        "name_orig": "Fan Extraction Tacho PPR",
        "desc": "Tacho Pulses pr. Revolution",
        "type": "int",
        "min": "0",
        "max": "255",
        "res": "",
        "default": "0",
        "unit": "puls/revolution",
        "options": ""
    },
    "fan_extraction_min_pct": {
        "id": "fan_extraction_min_pct",
        "param_no": 10118,
        "parent": 26000,
        "name": "Fan Extraction Min pct",
        "name_orig": "Fan Extraction Min pct",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "fan_extraction_vent_max_pct": {
        "id": "fan_extraction_vent_max_pct",
        "param_no": 10199,
        "parent": 26000,
        "name": "Fan Extraction Vent Max pct",
        "name_orig": "Fan Extraction Vent Max pct",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "fan_extraction_recirc_max_pct": {
        "id": "fan_extraction_recirc_max_pct",
        "param_no": 10200,
        "parent": 26000,
        "name": "Fan Extraction Recirc Max pct",
        "name_orig": "Fan Extraction Recirc Max pct",
        "desc": "",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "comfort_heater_type": {
        "id": "comfort_heater_type",
        "param_no": 10120,
        "parent": 27000,
        "name": "Comfort Heater Type",
        "name_orig": "Comfort Heater Type",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Electric",
            "2": "Water"
        }
    },
    "pre_heater_installed": {
        "id": "pre_heater_installed",
        "param_no": 10121,
        "parent": 27000,
        "name": "Pre Heater Installed",
        "name_orig": "Pre Heater Installed",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Electric",
            "2": "Water"
        }
    },
    "virtual_pre_heater_options": {
        "id": "virtual_pre_heater_options",
        "param_no": 10122,
        "parent": 27000,
        "name": "Virtual Pre Heater Options",
        "name_orig": "Virtual Pre Heater Options",
        "desc": "Comfort Heaters that are allowed for Virtual Pre Heater",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Electric",
            "2": "Water",
            "3": "Electric or Water"
        }
    },
    "rh_sensor_installed_ai": {
        "id": "rh_sensor_installed_ai",
        "param_no": 10123,
        "parent": 28000,
        "name": "RH Sensor Installed AI",
        "name_orig": "RH Sensor Installed AI",
        "desc": "RH sensor installed: Air Inlet",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "rh_sensor_installed_ar": {
        "id": "rh_sensor_installed_ar",
        "param_no": 10124,
        "parent": 28000,
        "name": "RH Sensor Installed AR",
        "name_orig": "RH Sensor Installed AR",
        "desc": "RH sensor installed: Air Room",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "rh_sensor_installed_ae": {
        "id": "rh_sensor_installed_ae",
        "param_no": 10125,
        "parent": 28000,
        "name": "RH Sensor Installed AE",
        "name_orig": "RH Sensor Installed AE",
        "desc": "RH sensor installed: Air Exhaust",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "rh_sensor_installed_ao": {
        "id": "rh_sensor_installed_ao",
        "param_no": 10126,
        "parent": 28000,
        "name": "RH Sensor Installed AO",
        "name_orig": "RH Sensor Installed AO",
        "desc": "RH sensor installed: Air Outside",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "analog_rh_sensor_pct_range_min": {
        "id": "analog_rh_sensor_pct_range_min",
        "param_no": 10127,
        "parent": 28000,
        "name": "Analog RH Sensor pct Range Min",
        "name_orig": "Analog RH Sensor pct Range Min",
        "desc": "Minimum RH concentration for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "analog_rh_sensor_pct_range_max": {
        "id": "analog_rh_sensor_pct_range_max",
        "param_no": 10128,
        "parent": 28000,
        "name": "Analog RH Sensor pct Range Max",
        "name_orig": "Analog RH Sensor pct Range Max",
        "desc": "Maximum RH concentration for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "100.0",
        "unit": "%",
        "options": ""
    },
    "analog_rh_sensor_volt_range_min": {
        "id": "analog_rh_sensor_volt_range_min",
        "param_no": 10129,
        "parent": 28000,
        "name": "Analog RH Sensor Volt Range Min",
        "name_orig": "Analog RH Sensor Volt Range Min",
        "desc": "Mimum Volt for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "10.0",
        "res": "",
        "default": "0.0",
        "unit": "V",
        "options": ""
    },
    "analog_rh_sensor_volt_range_max": {
        "id": "analog_rh_sensor_volt_range_max",
        "param_no": 10130,
        "parent": 28000,
        "name": "Analog RH Sensor Volt Range Max",
        "name_orig": "Analog RH Sensor Volt Range Max",
        "desc": "Maximum Volt for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "10.0",
        "res": "",
        "default": "10.0",
        "unit": "V",
        "options": ""
    },
    "hvac_mode": {
        "id": "hvac_mode",
        "param_no": 10131,
        "parent": 29000,
        "name": "HVAC Mode",
        "name_orig": "HVAC Mode",
        "desc": "HP Installed",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Ventilation only",
            "1": "Cooling",
            "2": "Heating",
            "3": "Cooling and Heating"
        }
    },
    "hvac_eev_installed": {
        "id": "hvac_eev_installed",
        "param_no": 10132,
        "parent": 29000,
        "name": "HVAC EEV Installed",
        "name_orig": "HVAC EEV Installed",
        "desc": "",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "hvac_eev_steps": {
        "id": "hvac_eev_steps",
        "param_no": 10133,
        "parent": 29000,
        "name": "HVAC EEV Steps",
        "name_orig": "HVAC EEV Steps",
        "desc": "Number of steps for the stepper drive to move the valve from end-to-end.",
        "type": "int",
        "min": "0",
        "max": "520",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": ""
    },
    "hvac_eev_calibration_steps": {
        "id": "hvac_eev_calibration_steps",
        "param_no": 10134,
        "parent": 29000,
        "name": "HVAC EEV Calibration Steps",
        "name_orig": "HVAC EEV Calibration Steps",
        "desc": "Number of steps for the stepper drive to ensure calibration towards closed position. Typically around end-to-end + [10-50] steps.",
        "type": "int",
        "min": "0",
        "max": "520",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": ""
    },
    "hvac_eev_calibration_time": {
        "id": "hvac_eev_calibration_time",
        "param_no": 10135,
        "parent": 29000,
        "name": "HVAC EEV Calibration Time",
        "name_orig": "HVAC EEV Calibration Time",
        "desc": "The time it takes to calibrate, OBS: must be equal to or larger than the time it takes!",
        "type": "int",
        "min": "0",
        "max": "255",
        "res": "",
        "default": "0",
        "unit": "s",
        "options": ""
    },
    "hvac_four_way_valve_active": {
        "id": "hvac_four_way_valve_active",
        "param_no": 10136,
        "parent": 29000,
        "name": "HVAC Four Way Valve Active",
        "name_orig": "HVAC Four Way Valve Active",
        "desc": "Activate to perform the selected task",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Cooling",
            "1": "Heating"
        }
    },
    "hvac_fc_power_mode": {
        "id": "hvac_fc_power_mode",
        "param_no": 10137,
        "parent": 29000,
        "name": "HVAC FC Power Mode",
        "name_orig": "HVAC FC Power Mode",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "Power Off",
            "1": "Go idle"
        }
    },
    "pressure_switch_installed_vic": {
        "id": "pressure_switch_installed_vic",
        "param_no": 10138,
        "parent": 30000,
        "name": "Pressure Switch Installed VIC",
        "name_orig": "Pressure Switch Installed VIC",
        "desc": "Pressure Switch Vapor Into Compressor",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Aware",
            "2": "Frequency Converter"
        }
    },
    "pressure_switch_installed_voc": {
        "id": "pressure_switch_installed_voc",
        "param_no": 10139,
        "parent": 30000,
        "name": "Pressure Switch Installed VOC",
        "name_orig": "Pressure Switch Installed VOC",
        "desc": "Pressure Switch Vapor Out of Compressor",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Aware",
            "2": "Frequency Converter"
        }
    },
    "pressure_transmitter_installed_vti": {
        "id": "pressure_transmitter_installed_vti",
        "param_no": 10140,
        "parent": 30000,
        "name": "Pressure Transmitter Installed VTI",
        "name_orig": "Pressure Transmitter Installed VTI",
        "desc": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "4",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_lbi": {
        "id": "pressure_transmitter_installed_lbi",
        "param_no": 10141,
        "parent": 30000,
        "name": "Pressure Transmitter Installed LBI",
        "name_orig": "Pressure Transmitter Installed LBI",
        "desc": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "4",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_vto": {
        "id": "pressure_transmitter_installed_vto",
        "param_no": 10142,
        "parent": 30000,
        "name": "Pressure Transmitter Installed VTO",
        "name_orig": "Pressure Transmitter Installed VTO",
        "desc": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "4",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_lbo": {
        "id": "pressure_transmitter_installed_lbo",
        "param_no": 10143,
        "parent": 30000,
        "name": "Pressure Transmitter Installed LBO",
        "name_orig": "Pressure Transmitter Installed LBO",
        "desc": "Pressure Tranmitter Liquid Bottom Outside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "4",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_vic": {
        "id": "pressure_transmitter_installed_vic",
        "param_no": 10144,
        "parent": 30000,
        "name": "Pressure Transmitter Installed VIC",
        "name_orig": "Pressure Transmitter Installed VIC",
        "desc": "Pressure Tranmitter Vapor Into Compressor",
        "type": "enum",
        "min": "0",
        "max": "4",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "analog_v_pressure_transmitter_bar_range_min": {
        "id": "analog_v_pressure_transmitter_bar_range_min",
        "param_no": 10145,
        "parent": 30000,
        "name": "Analog V Pressure Transmitter Bar Range Min",
        "name_orig": "Analog V Pressure Transmitter Bar Range Min",
        "desc": "Minimum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": "-100000",
        "max": "4000000",
        "res": "",
        "default": "0",
        "unit": "Bar",
        "options": ""
    },
    "analog_v_pressure_transmitter_bar_range_max": {
        "id": "analog_v_pressure_transmitter_bar_range_max",
        "param_no": 10146,
        "parent": 30000,
        "name": "Analog V Pressure Transmitter Bar Range Max",
        "name_orig": "Analog V Pressure Transmitter Bar Range Max",
        "desc": "Maximum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": "-100000",
        "max": "4000000",
        "res": "",
        "default": "0",
        "unit": "Bar",
        "options": ""
    },
    "analog_v_pressure_transmitter_volt_range_min": {
        "id": "analog_v_pressure_transmitter_volt_range_min",
        "param_no": 10147,
        "parent": 30000,
        "name": "Analog V Pressure Transmitter Volt Range Min",
        "name_orig": "Analog V Pressure Transmitter Volt Range Min",
        "desc": "Mimum Volt for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "10.0",
        "res": "",
        "default": "0.0",
        "unit": "V",
        "options": ""
    },
    "analog_v_pressure_transmitter_volt_range_max": {
        "id": "analog_v_pressure_transmitter_volt_range_max",
        "param_no": 10148,
        "parent": 30000,
        "name": "Analog V Pressure Transmitter Volt Range Max",
        "name_orig": "Analog V Pressure Transmitter Volt Range Max",
        "desc": "Maximum Volt for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "10.0",
        "res": "",
        "default": "10.0",
        "unit": "V",
        "options": ""
    },
    "analog_i_pressure_transmitter_bar_range_min": {
        "id": "analog_i_pressure_transmitter_bar_range_min",
        "param_no": 10149,
        "parent": 30000,
        "name": "Analog I Pressure Transmitter Bar Range Min",
        "name_orig": "Analog I Pressure Transmitter Bar Range Min",
        "desc": "Minimum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": "-100000",
        "max": "4000000",
        "res": "",
        "default": "0",
        "unit": "Bar",
        "options": ""
    },
    "analog_i_pressure_transmitter_bar_range_max": {
        "id": "analog_i_pressure_transmitter_bar_range_max",
        "param_no": 10150,
        "parent": 30000,
        "name": "Analog I Pressure Transmitter Bar Range Max",
        "name_orig": "Analog I Pressure Transmitter Bar Range Max",
        "desc": "Maximum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": "-100000",
        "max": "4000000",
        "res": "",
        "default": "0",
        "unit": "Bar",
        "options": ""
    },
    "analog_i_pressure_transmitter_ma_range_min": {
        "id": "analog_i_pressure_transmitter_ma_range_min",
        "param_no": 10151,
        "parent": 30000,
        "name": "Analog I Pressure Transmitter mA Range Min",
        "name_orig": "Analog I Pressure Transmitter mA Range Min",
        "desc": "Mimum Current for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "20.0",
        "res": "",
        "default": "4.0",
        "unit": "mA",
        "options": ""
    },
    "analog_i_pressure_transmitter_ma_range_max": {
        "id": "analog_i_pressure_transmitter_ma_range_max",
        "param_no": 10152,
        "parent": 30000,
        "name": "Analog I Pressure Transmitter mA Range Max",
        "name_orig": "Analog I Pressure Transmitter mA Range Max",
        "desc": "Maximum Current for the RH sensor",
        "type": "decimal",
        "min": "0.0",
        "max": "20.0",
        "res": "",
        "default": "20.0",
        "unit": "mA",
        "options": ""
    },
    "t_sensor_installed_ai": {
        "id": "t_sensor_installed_ai",
        "param_no": 10153,
        "parent": 31000,
        "name": "T Sensor Installed AI",
        "name_orig": "T Sensor Installed AI",
        "desc": "Temperature Air Inlet",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_ar": {
        "id": "t_sensor_installed_ar",
        "param_no": 10154,
        "parent": 31000,
        "name": "T Sensor Installed AR",
        "name_orig": "T Sensor Installed AR",
        "desc": "Temperature Air Room",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_ae": {
        "id": "t_sensor_installed_ae",
        "param_no": 10155,
        "parent": 31000,
        "name": "T Sensor Installed AE",
        "name_orig": "T Sensor Installed AE",
        "desc": "Temperature Air Exhaust",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_ao": {
        "id": "t_sensor_installed_ao",
        "param_no": 10156,
        "parent": 31000,
        "name": "T Sensor Installed AO",
        "name_orig": "T Sensor Installed AO",
        "desc": "Temperature Air Outside",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_aop": {
        "id": "t_sensor_installed_aop",
        "param_no": 10157,
        "parent": 31000,
        "name": "T Sensor Installed AOP",
        "name_orig": "T Sensor Installed AOP",
        "desc": "Temperature Air Out of Passive heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_aio": {
        "id": "t_sensor_installed_aio",
        "param_no": 10158,
        "parent": 31000,
        "name": "T Sensor Installed AIO",
        "name_orig": "T Sensor Installed AIO",
        "desc": "Temperature Air Into Outside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_aii": {
        "id": "t_sensor_installed_aii",
        "param_no": 10159,
        "parent": 31000,
        "name": "T Sensor Installed AII",
        "name_orig": "T Sensor Installed AII",
        "desc": "Temperature Air Into Inside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_vto": {
        "id": "t_sensor_installed_vto",
        "param_no": 10160,
        "parent": 31000,
        "name": "T Sensor Installed VTO",
        "name_orig": "T Sensor Installed VTO",
        "desc": "Temperature Vapor Top Outside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_vti": {
        "id": "t_sensor_installed_vti",
        "param_no": 10161,
        "parent": 31000,
        "name": "T Sensor Installed VTI",
        "name_orig": "T Sensor Installed VTI",
        "desc": "Temperature Vapor Top Inside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_voc": {
        "id": "t_sensor_installed_voc",
        "param_no": 10162,
        "parent": 31000,
        "name": "T Sensor Installed VOC",
        "name_orig": "T Sensor Installed VOC",
        "desc": "Temperature Vapor Out of Compressor",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_lbo": {
        "id": "t_sensor_installed_lbo",
        "param_no": 10163,
        "parent": 31000,
        "name": "T Sensor Installed LBO",
        "name_orig": "T Sensor Installed LBO",
        "desc": "Temperature Liquid Bottom Outside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_lbi": {
        "id": "t_sensor_installed_lbi",
        "param_no": 10164,
        "parent": 31000,
        "name": "T Sensor Installed LBI",
        "name_orig": "T Sensor Installed LBI",
        "desc": "Temperature Liquid Bottom Inside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_lie": {
        "id": "t_sensor_installed_lie",
        "param_no": 10165,
        "parent": 31000,
        "name": "T Sensor Installed LIE",
        "name_orig": "T Sensor Installed LIE",
        "desc": "Temperature Liquid Into Expansion unit",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_loe": {
        "id": "t_sensor_installed_loe",
        "param_no": 10166,
        "parent": 31000,
        "name": "T Sensor Installed LOE",
        "name_orig": "T Sensor Installed LOE",
        "desc": "Temperature Liquid Out of Expansion unit",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_pci": {
        "id": "t_sensor_installed_pci",
        "param_no": 10167,
        "parent": 31000,
        "name": "T Sensor Installed PCI",
        "name_orig": "T Sensor Installed PCI",
        "desc": "Temperature Phase Change Inside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_pco": {
        "id": "t_sensor_installed_pco",
        "param_no": 10168,
        "parent": 31000,
        "name": "T Sensor Installed PCO",
        "name_orig": "T Sensor Installed PCO",
        "desc": "Temperature Phase Change Outside heat exchanger",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_vic": {
        "id": "t_sensor_installed_vic",
        "param_no": 10169,
        "parent": 31000,
        "name": "T Sensor Installed VIC",
        "name_orig": "T Sensor Installed VIC",
        "desc": "Temperature Vapor Into Compressor",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "tvoc_sensor_installed_ai": {
        "id": "tvoc_sensor_installed_ai",
        "param_no": 10170,
        "parent": 32000,
        "name": "TVOC Sensor Installed AI",
        "name_orig": "TVOC Sensor Installed AI",
        "desc": "TVOC sensor installed: Air Inlet",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "tvoc_sensor_installed_ar": {
        "id": "tvoc_sensor_installed_ar",
        "param_no": 10171,
        "parent": 32000,
        "name": "TVOC Sensor Installed AR",
        "name_orig": "TVOC Sensor Installed AR",
        "desc": "TVOC sensor installed: Air Room",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "tvoc_sensor_installed_ae": {
        "id": "tvoc_sensor_installed_ae",
        "param_no": 10172,
        "parent": 32000,
        "name": "TVOC Sensor Installed AE",
        "name_orig": "TVOC Sensor Installed AE",
        "desc": "TVOC sensor installed: Air Exhaust",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "tvoc_sensor_installed_ao": {
        "id": "tvoc_sensor_installed_ao",
        "param_no": 10173,
        "parent": 32000,
        "name": "TVOC Sensor Installed AO",
        "name_orig": "TVOC Sensor Installed AO",
        "desc": "TVOC sensor installed: Air Outside",
        "type": "enum",
        "min": "0",
        "max": "1",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "cds_lvl_sensor_sup_phe": {
        "id": "cds_lvl_sensor_sup_phe",
        "param_no": 10179,
        "parent": 33000,
        "name": "CDS Lvl Sensor Sup PHE",
        "name_orig": "CDS Lvl Sensor Sup PHE",
        "desc": "w. fb.: With Feedback, wo. fb.: Without Feedback, Where 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": "0",
        "max": "7",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "cds_lvl_sensor_sup_ahe": {
        "id": "cds_lvl_sensor_sup_ahe",
        "param_no": 10180,
        "parent": 33000,
        "name": "CDS Lvl Sensor Sup AHE",
        "name_orig": "CDS Lvl Sensor Sup AHE",
        "desc": "w. fb.: With Feedback, wo. fb.: Without Feedback, Where 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": "0",
        "max": "7",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "cds_lvl_sensor_ext_phe": {
        "id": "cds_lvl_sensor_ext_phe",
        "param_no": 10181,
        "parent": 33000,
        "name": "CDS Lvl Sensor Ext PHE",
        "name_orig": "CDS Lvl Sensor Ext PHE",
        "desc": "w. fb.: With Feedback, wo. fb.: Without Feedback, Where 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": "0",
        "max": "7",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "cds_lvl_sensor_ext_ahe": {
        "id": "cds_lvl_sensor_ext_ahe",
        "param_no": 10182,
        "parent": 33000,
        "name": "CDS Lvl Sensor Ext AHE",
        "name_orig": "CDS Lvl Sensor Ext AHE",
        "desc": "w. fb.: With Feedback, wo. fb.: Without Feedback, Where 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": "0",
        "max": "7",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "propane_sensor_installed": {
        "id": "propane_sensor_installed",
        "param_no": 10205,
        "parent": 34000,
        "name": "Propane Sensor Installed",
        "name_orig": "Propane Sensor Installed",
        "desc": "Propane sensor installed",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "False",
        "unit": "-",
        "options": ""
    },
    "filter_test_mode": {
        "id": "filter_test_mode",
        "param_no": 10174,
        "parent": 36000,
        "name": "Filter Test Mode",
        "name_orig": "Filter Test Mode",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "3",
        "res": "",
        "default": "0",
        "unit": "-",
        "options": {
            "0": "Off",
            "1": "Timer",
            "2": "Lifetime",
            "3": "Timer and Lifetime"
        }
    },
    "filter_test_hours_warning": {
        "id": "filter_test_hours_warning",
        "param_no": 10175,
        "parent": 36000,
        "name": "Filter Test Hours Warning",
        "name_orig": "Filter Test Hours Warning",
        "desc": "Time before filter Warning in hours",
        "type": "int",
        "min": "0",
        "max": "8760",
        "res": "",
        "default": "0",
        "unit": "hour",
        "options": ""
    },
    "filter_test_hours_alarm": {
        "id": "filter_test_hours_alarm",
        "param_no": 10176,
        "parent": 36000,
        "name": "Filter Test Hours Alarm",
        "name_orig": "Filter Test Hours Alarm",
        "desc": "Time before filter Alarm in hours",
        "type": "int",
        "min": "0",
        "max": "8760",
        "res": "",
        "default": "0",
        "unit": "hour",
        "options": ""
    },
    "filter_test_max_lifetime_warning_period": {
        "id": "filter_test_max_lifetime_warning_period",
        "param_no": 10177,
        "parent": 36000,
        "name": "Filter Test Max Lifetime Warning Period",
        "name_orig": "Filter Test Max Lifetime Warning Period",
        "desc": "Warning period in months prior to the alarm.",
        "type": "int",
        "min": "0",
        "max": "12",
        "res": "",
        "default": "2",
        "unit": "month(s)",
        "options": ""
    },
    "filter_test_max_lifetime_alarm": {
        "id": "filter_test_max_lifetime_alarm",
        "param_no": 10178,
        "parent": 36000,
        "name": "Filter Test Max Lifetime Alarm",
        "name_orig": "Filter Test Max Lifetime Alarm",
        "desc": "Alarm is activated when the filter is \"Filter Max Life Time, Alarm\" old. (0 months disables the Filter Max Life Time Alarm)",
        "type": "int",
        "min": "0",
        "max": "48",
        "res": "",
        "default": "0",
        "unit": "month(s)",
        "options": ""
    },
    "nominal_max_flow": {
        "id": "nominal_max_flow",
        "param_no": 10186,
        "parent": 37000,
        "name": "Nominal Max Flow",
        "name_orig": "Nominal Max Flow",
        "desc": "Nominal max flow of the unit",
        "type": "decimal",
        "min": "0.0",
        "max": "2000.0",
        "res": "",
        "default": "0.0",
        "unit": "m3/h",
        "options": ""
    },
    "flow_control_mode": {
        "id": "flow_control_mode",
        "param_no": 10183,
        "parent": 38000,
        "name": "Flow Control Mode",
        "name_orig": "Flow Control Mode",
        "desc": "Select Flow Mode: Ventilator voltage percentage / PID flow controller",
        "type": "enum",
        "min": "0",
        "max": "2",
        "res": "",
        "default": "1",
        "unit": "-",
        "options": {
            "0": "Flow Control",
            "1": "Volt Control",
            "2": "Volt Direct"
        }
    },
    "supply_fan_flow_calibration": {
        "id": "supply_fan_flow_calibration",
        "param_no": 10184,
        "parent": 39000,
        "name": "Supply Fan Flow Calibration",
        "name_orig": "Supply Fan Flow Calibration",
        "desc": "Supply Fan Flow Calibration",
        "type": "decimal",
        "min": "-100.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "extraction_fan_flow_calibration": {
        "id": "extraction_fan_flow_calibration",
        "param_no": 10185,
        "parent": 39000,
        "name": "Extraction Fan Flow Calibration",
        "name_orig": "Extraction Fan Flow Calibration",
        "desc": "Extraction Fan Flow Calibration",
        "type": "decimal",
        "min": "-100.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "draft_strat_algo_enabled": {
        "id": "draft_strat_algo_enabled",
        "param_no": 10201,
        "parent": 40000,
        "name": "Draft and strat algorithm enabled",
        "name_orig": "Draft and strat algorithm enabled",
        "desc": "Enable or disable the draft and stratification algorithm",
        "type": "bool",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "True",
        "unit": "-",
        "options": ""
    },
    "min_inlet_damper_pos": {
        "id": "min_inlet_damper_pos",
        "param_no": 10202,
        "parent": 41000,
        "name": "Minimum inlet damper position.",
        "name_orig": "Minimum inlet damper position.",
        "desc": "Set the lower limit of the position of the inlet damper in percentage",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "0.0",
        "unit": "%",
        "options": ""
    },
    "max_inlet_damper_pos": {
        "id": "max_inlet_damper_pos",
        "param_no": 10203,
        "parent": 41000,
        "name": "Maximum inlet damper position.",
        "name_orig": "Maximum inlet damper position.",
        "desc": "Set the upper limit of the position of the inlet damper in percentage",
        "type": "decimal",
        "min": "0.0",
        "max": "100.0",
        "res": "",
        "default": "100.0",
        "unit": "%",
        "options": ""
    },
    "manufacturer_id": {
        "id": "manufacturer_id",
        "param_no": 10187,
        "parent": 42000,
        "name": "Manufacturer ID",
        "name_orig": "Manufacturer ID",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "13",
        "res": "",
        "default": "0",
        "unit": "",
        "options": {
            "0": "None",
            "1": "Airmaster AS",
            "2": "Airmaster AB",
            "3": "Airmaster Ltd",
            "4": "Airmaster AS_4",
            "5": "Helios Ventilateurs",
            "6": "Wesco AG",
            "7": "Airmaster B.V",
            "8": "SAV Systems Ltd.",
            "9": "Airflow Lufttechnik GmbH",
            "10": "Johann WERNIG KG",
            "11": "Airmaster Norge AS",
            "12": "Airmaster Norge AS_12",
            "13": "Airmaster AS_13"
        }
    },
    "manufacturer_phone": {
        "id": "manufacturer_phone",
        "param_no": 10188,
        "parent": 42000,
        "name": "Manufacturer Phone",
        "name_orig": "Manufacturer Phone",
        "desc": "",
        "type": "string",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "-",
        "unit": "-",
        "options": ""
    },
    "support_id": {
        "id": "support_id",
        "param_no": 10189,
        "parent": 42000,
        "name": "Support ID",
        "name_orig": "Support ID",
        "desc": "",
        "type": "enum",
        "min": "0",
        "max": "13",
        "res": "",
        "default": "0",
        "unit": "",
        "options": {
            "0": "None",
            "1": "Airmaster AS",
            "2": "Airmaster AB",
            "3": "Airmaster Ltd.",
            "4": "Airmaster AS_DUPLICATE",
            "5": "Helios Ventilateurs",
            "6": "Wesco AG",
            "7": "Itho Daalderop",
            "8": "SAV Systems Ltd.",
            "9": "Airflow Lufttechnik GmbH",
            "10": "Johann WERNIG KG",
            "11": "Sandviken Center -ventilasjonsservice AS",
            "12": "Airmaster AS Norge",
            "13": "AT-Air OY"
        }
    },
    "support_phone": {
        "id": "support_phone",
        "param_no": 10190,
        "parent": 42000,
        "name": "Support Phone",
        "name_orig": "Support Phone",
        "desc": "",
        "type": "string",
        "min": "0",
        "max": "0",
        "res": "",
        "default": "-",
        "unit": "-",
        "options": ""
    },
    "udf": {
        "id": "udf",
        "parent": 0,
        "name": "User Defined Air Flow",
        "name_orig": "User Defined Air Flow",
        "desc": "User Defined Air Flow",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "rqf": {
        "id": "rqf",
        "parent": 0,
        "name": "Requested Flow",
        "name_orig": "Requested Flow",
        "desc": "Requested Flow",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "udt": {
        "id": "udt",
        "parent": 0,
        "name": "User Defined Temperature",
        "name_orig": "User Defined Temperature",
        "desc": "User Defined Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "rqt": {
        "id": "rqt",
        "parent": 0,
        "name": "Requested Temperature",
        "name_orig": "Requested Temperature",
        "desc": "Requested Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "trt": {
        "id": "trt",
        "parent": 0,
        "name": "Target Room Temperature",
        "name_orig": "Target Room Temperature",
        "desc": "Target Room Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    }
}

export const awareParamNames = {
    "default_flow": "default_flow",
    "default_temp": "default_temp",
    "delta_temp_heating": "delta_temp_heating",
    "delta_temp_cooling": "delta_temp_cooling",
    "min_flow_setpoint": "min_flow_setpoint",
    "max_flow_setpoint": "max_flow_setpoint",
    "min_temp_setpoint": "min_temp_setpoint",
    "max_temp_setpoint": "max_temp_setpoint",
    "co2_control_enabled": "co2_control_enabled",
    "co2_control_min": "co2_control_min",
    "co2_control_max": "co2_control_max",
    "pir_enabled": "pir_enabled",
    "pir_afterrun_timer": "pir_afterrun_timer",
    "extern_start_delay_time": "extern_start_delay_time",
    "extern_start_afterrun_timer": "extern_start_afterrun_timer",
    "extern_start_auto_off_timer": "extern_start_auto_off_timer",
    "extern_start_analog_in_threshold_voltage": "extern_start_analog_in_threshold_voltage",
    "allow_boost": "allow_boost",
    "boost_afterrun_time": "boost_afterrun_time",
    "boost_auto_off_time": "boost_auto_off_time",
    "boost_max_sf_pct": "boost_max_sf_pct",
    "boost_max_ef_pct": "boost_max_ef_pct",
    "start_priority_1": "start_priority_1",
    "start_priority_2": "start_priority_2",
    "start_priority_3": "start_priority_3",
    "start_priority_4": "start_priority_4",
    "start_priority_5": "start_priority_5",
    "start_priority_6": "start_priority_6",
    "start_priority_dependency": "start_priority_dependency",
    "start_priority_night_cooling_independent": "start_priority_night_cooling_independent",
    "status_led_active_start_time": "status_led_active_start_time",
    "status_led_active_stop_time": "status_led_active_stop_time",
    "status_led_on_when_running_normal": "status_led_on_when_running_normal",
    "status_led_installed": "status_led_installed",
    "timer_schedule_1_enabled": "timer_schedule_1_enabled",
    "timer_schedule_1_mode": "timer_schedule_1_mode",
    "timer_schedule_1_days": "timer_schedule_1_days",
    "timer_schedule_1_start_time": "timer_schedule_1_start_time",
    "timer_schedule_1_duration": "timer_schedule_1_duration",
    "timer_schedule_1_flow": "timer_schedule_1_flow",
    "timer_schedule_1_temperature": "timer_schedule_1_temperature",
    "timer_schedule_2_enabled": "timer_schedule_2_enabled",
    "timer_schedule_2_mode": "timer_schedule_2_mode",
    "timer_schedule_2_days": "timer_schedule_2_days",
    "timer_schedule_2_start_time": "timer_schedule_2_start_time",
    "timer_schedule_2_duration": "timer_schedule_2_duration",
    "timer_schedule_2_flow": "timer_schedule_2_flow",
    "timer_schedule_2_temperature": "timer_schedule_2_temperature",
    "time_zone": "time_zone",
    "night_cooling_low_limit": "night_cooling_low_limit",
    "night_cooling_high_limit": "night_cooling_high_limit",
    "night_cooling_min_ot": "night_cooling_min_ot",
    "external_emergency_function": "external_emergency_function",
    "external_emergency_contact": "external_emergency_contact",
    "unit_name": "unit_name",
    "manual_override_time": "manual_override_time",
    "co2_level_grey": "co2_level_grey",
    "co2_level_blue": "co2_level_blue",
    "co2_level_dark_green": "co2_level_dark_green",
    "co2_level_light_green": "co2_level_light_green",
    "co2_level_yellow": "co2_level_yellow",
    "co2_level_orange": "co2_level_orange",
    "co2_level_red": "co2_level_red",
    "tvoc_level_grey": "tvoc_level_grey",
    "tvoc_level_blue": "tvoc_level_blue",
    "tvoc_level_dark_green": "tvoc_level_dark_green",
    "tvoc_level_light_green": "tvoc_level_light_green",
    "tvoc_level_yellow": "tvoc_level_yellow",
    "tvoc_level_orange": "tvoc_level_orange",
    "tvoc_level_red": "tvoc_level_red",
    "am_unit_type": "am_unit_type",
    "ccm_installed": "ccm_installed",
    "co2_sensor_installed_ai": "co2_sensor_installed_ai",
    "co2_sensor_installed_ar": "co2_sensor_installed_ar",
    "co2_sensor_installed_ae": "co2_sensor_installed_ae",
    "co2_sensor_installed_ao": "co2_sensor_installed_ao",
    "analog_co2_sensor_ppm_range_min": "analog_co2_sensor_ppm_range_min",
    "analog_co2_sensor_ppm_range_max": "analog_co2_sensor_ppm_range_max",
    "analog_co2_sensor_volt_range_min": "analog_co2_sensor_volt_range_min",
    "analog_co2_sensor_volt_range_max": "analog_co2_sensor_volt_range_max",
    "aa_mode": "aa_mode",
    "aa_run_time": "aa_run_time",
    "aa_upward_direction": "aa_upward_direction",
    "aa_cw_rotation": "aa_cw_rotation",
    "aa_ccw_rotation": "aa_ccw_rotation",
    "aa_self_calibration": "aa_self_calibration",
    "bp_mode": "bp_mode",
    "bp_run_time": "bp_run_time",
    "bp_open_direction": "bp_open_direction",
    "bp_cw_rotation": "bp_cw_rotation",
    "bp_ccw_rotation": "bp_ccw_rotation",
    "bp_self_calibration": "bp_self_calibration",
    "bp_cw_offset": "bp_cw_offset",
    "bp_ccw_offset": "bp_ccw_offset",
    "bp_min_movement": "bp_min_movement",
    "mds_mode": "mds_mode",
    "mds_run_time": "mds_run_time",
    "mds_open_direction": "mds_open_direction",
    "mds_cw_rotation": "mds_cw_rotation",
    "mds_ccw_rotation": "mds_ccw_rotation",
    "mde_mode": "mde_mode",
    "mde_run_time": "mde_run_time",
    "mde_open_direction": "mde_open_direction",
    "mde_cw_rotation": "mde_cw_rotation",
    "mde_ccw_rotation": "mde_ccw_rotation",
    "md_min_opening": "md_min_opening",
    "rci_mode": "rci_mode",
    "rci_run_time": "rci_run_time",
    "rci_open_direction": "rci_open_direction",
    "rci_cw_rotation": "rci_cw_rotation",
    "rci_ccw_rotation": "rci_ccw_rotation",
    "rco_mode": "rco_mode",
    "rco_run_time": "rco_run_time",
    "rco_open_direction": "rco_open_direction",
    "rco_cw_rotation": "rco_cw_rotation",
    "rco_ccw_rotation": "rco_ccw_rotation",
    "fan_supply_control": "fan_supply_control",
    "fan_supply_tacho_ppr": "fan_supply_tacho_ppr",
    "fan_supply_min_pct": "fan_supply_min_pct",
    "fan_supply_vent_max_pct": "fan_supply_vent_max_pct",
    "fan_supply_recirc_max_pct": "fan_supply_recirc_max_pct",
    "fan_extraction_control": "fan_extraction_control",
    "fan_extraction_tacho_ppr": "fan_extraction_tacho_ppr",
    "fan_extraction_min_pct": "fan_extraction_min_pct",
    "fan_extraction_vent_max_pct": "fan_extraction_vent_max_pct",
    "fan_extraction_recirc_max_pct": "fan_extraction_recirc_max_pct",
    "comfort_heater_type": "comfort_heater_type",
    "pre_heater_installed": "pre_heater_installed",
    "virtual_pre_heater_options": "virtual_pre_heater_options",
    "rh_sensor_installed_ai": "rh_sensor_installed_ai",
    "rh_sensor_installed_ar": "rh_sensor_installed_ar",
    "rh_sensor_installed_ae": "rh_sensor_installed_ae",
    "rh_sensor_installed_ao": "rh_sensor_installed_ao",
    "analog_rh_sensor_pct_range_min": "analog_rh_sensor_pct_range_min",
    "analog_rh_sensor_pct_range_max": "analog_rh_sensor_pct_range_max",
    "analog_rh_sensor_volt_range_min": "analog_rh_sensor_volt_range_min",
    "analog_rh_sensor_volt_range_max": "analog_rh_sensor_volt_range_max",
    "hvac_mode": "hvac_mode",
    "hvac_eev_installed": "hvac_eev_installed",
    "hvac_eev_steps": "hvac_eev_steps",
    "hvac_eev_calibration_steps": "hvac_eev_calibration_steps",
    "hvac_eev_calibration_time": "hvac_eev_calibration_time",
    "hvac_four_way_valve_active": "hvac_four_way_valve_active",
    "hvac_fc_power_mode": "hvac_fc_power_mode",
    "pressure_switch_installed_vic": "pressure_switch_installed_vic",
    "pressure_switch_installed_voc": "pressure_switch_installed_voc",
    "pressure_transmitter_installed_vti": "pressure_transmitter_installed_vti",
    "pressure_transmitter_installed_lbi": "pressure_transmitter_installed_lbi",
    "pressure_transmitter_installed_vto": "pressure_transmitter_installed_vto",
    "pressure_transmitter_installed_lbo": "pressure_transmitter_installed_lbo",
    "pressure_transmitter_installed_vic": "pressure_transmitter_installed_vic",
    "analog_v_pressure_transmitter_bar_range_min": "analog_v_pressure_transmitter_bar_range_min",
    "analog_v_pressure_transmitter_bar_range_max": "analog_v_pressure_transmitter_bar_range_max",
    "analog_v_pressure_transmitter_volt_range_min": "analog_v_pressure_transmitter_volt_range_min",
    "analog_v_pressure_transmitter_volt_range_max": "analog_v_pressure_transmitter_volt_range_max",
    "analog_i_pressure_transmitter_bar_range_min": "analog_i_pressure_transmitter_bar_range_min",
    "analog_i_pressure_transmitter_bar_range_max": "analog_i_pressure_transmitter_bar_range_max",
    "analog_i_pressure_transmitter_ma_range_min": "analog_i_pressure_transmitter_ma_range_min",
    "analog_i_pressure_transmitter_ma_range_max": "analog_i_pressure_transmitter_ma_range_max",
    "t_sensor_installed_ai": "t_sensor_installed_ai",
    "t_sensor_installed_ar": "t_sensor_installed_ar",
    "t_sensor_installed_ae": "t_sensor_installed_ae",
    "t_sensor_installed_ao": "t_sensor_installed_ao",
    "t_sensor_installed_aop": "t_sensor_installed_aop",
    "t_sensor_installed_aio": "t_sensor_installed_aio",
    "t_sensor_installed_aii": "t_sensor_installed_aii",
    "t_sensor_installed_vto": "t_sensor_installed_vto",
    "t_sensor_installed_vti": "t_sensor_installed_vti",
    "t_sensor_installed_voc": "t_sensor_installed_voc",
    "t_sensor_installed_lbo": "t_sensor_installed_lbo",
    "t_sensor_installed_lbi": "t_sensor_installed_lbi",
    "t_sensor_installed_lie": "t_sensor_installed_lie",
    "t_sensor_installed_loe": "t_sensor_installed_loe",
    "t_sensor_installed_pci": "t_sensor_installed_pci",
    "t_sensor_installed_pco": "t_sensor_installed_pco",
    "t_sensor_installed_vic": "t_sensor_installed_vic",
    "tvoc_sensor_installed_ai": "tvoc_sensor_installed_ai",
    "tvoc_sensor_installed_ar": "tvoc_sensor_installed_ar",
    "tvoc_sensor_installed_ae": "tvoc_sensor_installed_ae",
    "tvoc_sensor_installed_ao": "tvoc_sensor_installed_ao",
    "cds_lvl_sensor_sup_phe": "cds_lvl_sensor_sup_phe",
    "cds_lvl_sensor_sup_ahe": "cds_lvl_sensor_sup_ahe",
    "cds_lvl_sensor_ext_phe": "cds_lvl_sensor_ext_phe",
    "cds_lvl_sensor_ext_ahe": "cds_lvl_sensor_ext_ahe",
    "propane_sensor_installed": "propane_sensor_installed",
    "filter_test_mode": "filter_test_mode",
    "filter_test_hours_warning": "filter_test_hours_warning",
    "filter_test_hours_alarm": "filter_test_hours_alarm",
    "filter_test_max_lifetime_warning_period": "filter_test_max_lifetime_warning_period",
    "filter_test_max_lifetime_alarm": "filter_test_max_lifetime_alarm",
    "nominal_max_flow": "nominal_max_flow",
    "flow_control_mode": "flow_control_mode",
    "supply_fan_flow_calibration": "supply_fan_flow_calibration",
    "extraction_fan_flow_calibration": "extraction_fan_flow_calibration",
    "draft_strat_algo_enabled": "draft_strat_algo_enabled",
    "min_inlet_damper_pos": "min_inlet_damper_pos",
    "max_inlet_damper_pos": "max_inlet_damper_pos",
    "manufacturer_id": "manufacturer_id",
    "manufacturer_phone": "manufacturer_phone",
    "support_id": "support_id",
    "support_phone": "support_phone",
    "udf": "udf",
    "rqf": "rqf",
    "udt": "udt",
    "rqt": "rqt",
    "trt": "trt"
}