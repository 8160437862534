import ComboBox from "../../../components/shared/combobox/ComboBox.js";

export default function AdminOperationsJobFilterSearchBar({className, style, searchValue, setSearch, typeFilterOptionsList, typeFilter, setTypeFilter}) {
    return (
        <div className={className} style={style}>
            <ComboBox className={''} inputValue={e => setTypeFilter(e.target.value)}
                      value={typeFilter} options={typeFilterOptionsList} hasLabel={false}/>

            <div className='ms-auto'>
                <input className="form-control shadow-none" value={searchValue}
                       onChange={e => setSearch(e.target.value)}
                       type="search" autoFocus placeholder={'Søg...'}/>
            </div>
        </div>
    )
}