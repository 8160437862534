import React from 'react'
import {useTranslation} from 'react-i18next'
import PopOverDialog from '../../../../../components/shared/popoverdialog/PopOverDialog'
import {isAdmin, isTechnicalAdvanced} from '../../../../../helpers/authHelper.js'
import {getFormattedDate, convertEpochToDateTime} from '../../../../../helpers/helperfunctions.js'
import {useMsal} from '@azure/msal-react'

/** Displays the latest settings configurations of a device
 * @param {object} device
 * @param {string} startedby Started by from latest settings
 * @param {string} mode Systemmode from latest settings
 * @param {boolean} listViewChosen boolean to show or hide grid/list-view
 * @param {string} className custom classes to add to the wrapper
 * @param {boolean} isAware
 */
export default function DeviceOperation({device, startedby, mode, listViewChosen, className, isAware = false}) {
    const {t} = useTranslation(['overviewpage', "telemetry"])
    const {accounts} = useMsal();
    return <div className={className}>
        {listViewChosen ?
            <div className="text-nowrap">
                <span
                    className="pe-1 d-inline-block font-weight-bold"><span>{t('overviewpage:table.cells.operation.startedby')}: </span>
                    <span className='text-bold'>{isAware ? t('telemetry:AwareStartedBy_' + startedby) : t('telemetry:StartedBy_' + startedby)}</span></span>
                <PopOverDialog trigger={['hover', 'focus']}
                               popOverTitel={t('overviewpage:table.cells.device_details.popover_title')}
                               popOverContent={<span className="d-block">
                                   {t('overviewpage:table.cells.operation.systemmode')}:
                                   <b className='ms-1'>{isAware ? t('telemetry:AwareSystemMode_' + mode) : t('telemetry:SystemMode_' + mode)}</b></span>}/>
            </div>
            :
            <>
                <span className="d-inline-block font-weight-bold text-nowrap">
                    <span className='me-1'
                          style={{fontSize: '14px'}}>{t('overviewpage:table.cells.operation.startedby')}</span>
                    <span className='text-bold' style={{fontSize: '14px'}}>{isAware ? t('telemetry:AwareStartedBy_' + startedby) : t('telemetry:StartedBy_' + startedby)}</span>
                </span>
                <span className="d-block text-nowrap">
                    <span className='me-1'
                          style={{fontSize: '14px'}}>{t('overviewpage:table.cells.operation.systemmode')}</span>
                    <span className='text-bold' style={{fontSize: '14px'}}>{isAware ? t('telemetry:AwareSystemMode_' + mode) : t('telemetry:SystemMode_' + mode)}</span>
                </span>
                {(isAdmin(accounts) || isTechnicalAdvanced(accounts)) &&
                    <div className='position-absolute' style={{top: '50px'}}>
                        <PopOverDialog trigger={['hover', 'focus']} style={{marginBottom: '-12px'}}
                                       popOverTitel={'Sidst opdateret'}
                                       popOverContent={<div className="d-block">
                                           <div>Telemetri: {isAware ? convertEpochToDateTime(device?.latest_telemetry["time"]) : getFormattedDate(device?.latest_telemetry["commandTime"])}</div>
                                           <div>Parametre: {device?.device_twin?.reported?.commandTime ? getFormattedDate(device.device_twin.reported.commandTime) : 'Ukendt'}</div>
                                           <div style={{fontSize: '11px'}}>Kun synlig for admin / advanceret teknisk bruger</div>
                                       </div>}/>
                    </div>
                }
            </>
        }
    </div>
}