import React, {useEffect, useState} from 'react'
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'
import {formatDateTimeString, getTicks, getTimeDiffHours} from '../../../../../helpers/helperfunctions'
import CustomYAxis from '../customyaxis/CustomYAxis'
import ClickableLegends from '../legends/ClickableLegends'
import {loaders} from "../../../../../helpers/constants.js"
import {useSelector} from "react-redux"
import {LoadSpinner} from "../../../../shared/loader/LoadSpinner.js"
import {Button} from 'react-bootstrap'
import {chartColors} from "../../telemetryChartsHelper.js";

/** @param {object} tooltip
 * @param {string[]} lineKeys
 * @param {object[]} telemetryList
 * @param {string} title
 * @param {string} yPostFix
 * @param {string} startTime valid datetime
 * @param {string} endTime valid datetime
 * @returns {Element}
 * @constructor
 */
export default function MultiLineChart({
                                           tooltip, lineKeys = ['pv'], telemetryList, title,
                                           yPostFix = "", startTime, endTime
                                       }) {

    const {loading} = useSelector(state => state.loading)
    const [barProps, setBarProps] = useState({})

    const initialXDomainState = [() => new Date(startTime).getTime(), () => new Date(endTime).getTime()]
    const [xDomain, setXDomain] = useState(initialXDomainState)

    const [telemetryToShowList, setTelemetryToShowList] = useState([])
    useEffect(() => {
        setTelemetryToShowList(telemetryList.map(d => ({...d, ts: new Date(d.ts).getTime()})))
    }, [telemetryList])

    const [zoomRefMouseDown, setZoomRefMouseDown] = useState(0)
    const [zoomRefMouseUp, setZoomRefMouseUp] = useState(0)
    const resetZoom = () => {
        setTelemetryToShowList(telemetryList.map(d => ({...d, ts: new Date(d.ts).getTime()})))
        setXDomain(initialXDomainState)
    }

    const zoom = () => {
        if (zoomRefMouseDown === zoomRefMouseUp || zoomRefMouseUp === 0) {
            setZoomRefMouseDown(0)
            setZoomRefMouseUp(0)
            return
        }
        const [leftTs, rightTs] = zoomRefMouseDown > zoomRefMouseUp ? [zoomRefMouseUp, zoomRefMouseDown] : [zoomRefMouseDown, zoomRefMouseUp]
        let zoomStart = telemetryToShowList.findIndex(t => t.ts === leftTs)
        let zoomEnd = telemetryToShowList.findIndex(t => t.ts === rightTs) + 1
        setTelemetryToShowList(telemetryToShowList.slice(zoomStart, zoomEnd))
        setXDomain([() => leftTs, () => rightTs])
        setZoomRefMouseDown(0)
        setZoomRefMouseUp(0)
    }
    let timeDiff = getTimeDiffHours(
        telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[0].ts : startTime,
        telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[telemetryToShowList.length - 1].ts : endTime
    )

    const setLegendFilters = (key) => setBarProps({...barProps, [key]: !barProps[key]})

    /** if size is mobile, then stackdirection is column, so we need to resize legend container based on number of legends */
    function getLegendHeight() {
        if (window.innerWidth < 576)
            return 25 + lineKeys.length * 10
        return 35
    }

    return (
        <>
            <div className="d-flex align-content-center">
                <span className="text-bold ps-3" style={{fontSize: '20px'}}>{title}</span>
                {telemetryToShowList.length !== telemetryList.length &&
                    <Button onClick={resetZoom} className='ms-2' size='sm' variant='outline-secondary'>Reset Zoom</Button>
                }
            </div>
            {loading.includes(loaders.getTelemetry) &&
                <LoadSpinner style={{marginTop: "100px", marginBottom: "100px"}} height="60px" width="60px"/>
            }
            {!loading.includes(loaders.getTelemetry) &&
                <ResponsiveContainer width="100%" height={450}>
                    <LineChart width={500} height={300} data={telemetryToShowList}
                               margin={{top: 35, right: 30, left: 20, bottom: 0}}
                               onMouseDown={e => e?.activeLabel && setZoomRefMouseDown(e.activeLabel)}
                               onMouseMove={e => zoomRefMouseDown && e?.activeLabel && setZoomRefMouseUp(e.activeLabel)}
                               onMouseUp={zoom}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>

                        <XAxis dataKey="ts" scale="time" type="number"
                               ticks={getTicks(
                                   telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[0].ts : startTime,
                                   telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[telemetryToShowList.length - 1].ts : endTime
                                   , 8)}
                               tickFormatter={e => formatDateTimeString(e, timeDiff > 24, timeDiff <= (24 * 4))}
                               domain={xDomain}
                        />
                        <YAxis tick={<CustomYAxis postFix={yPostFix}/>}/>

                        <Tooltip content={(e) => tooltip(e, lineKeys)}/>
                        <Legend wrapperStyle={{fontSize: "12px"}}
                                content={<ClickableLegends toggleFilter={setLegendFilters} disabledFilters={barProps}/>}
                                height={getLegendHeight()}/>
                        {lineKeys.map((obj, index) =>
                            <Line key={obj} hide={barProps[obj]} type="monotone" isAnimationActive={false} dataKey={obj}
                                  stroke={chartColors[index]} activeDot={{r: 4}} dot={false}/>)}

                        {zoomRefMouseDown && zoomRefMouseUp ? (
                            <ReferenceArea
                                fill={"gray"}
                                x1={zoomRefMouseDown}
                                x2={zoomRefMouseUp}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </LineChart>
                </ResponsiveContainer>
            }
        </>
    )
}