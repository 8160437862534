import AdminOperationsEmailFilterSearchBar from "./AdminOperationsEmailFilterSearchBar.js";
import AdminOperationsEmailTableBody from "./AdminOperationsEmailTableBody.js";
import {useEffect, useState} from "react";
import {getComparator} from "../../../helpers/helperfunctions.js";

export default function AdminOperationsEmail({rawEmailList}) {
    const [search, setSearch] = useState("")
    const [sortObj, setSortObj] = useState({sortValue: "timestamp", sortDirection: 'desc'})
    const [filteredSortedEmailList, setFilteredSortedEmailList] = useState([])

    const sortHandler = (headerData) => {
        setSortObj({
            sortDirection: headerData.sortValue === sortObj.sortValue && sortObj.sortDirection === 'desc' ? 'asc' : "desc",
            sortValue: headerData.sortValue
        })
    }

    useEffect(() => {
        const sortedJobList = [...rawEmailList].sort(getComparator(sortObj.sortDirection, sortObj.sortValue));
        if (search) {
            const searchLower = search.toLowerCase()
            setFilteredSortedEmailList(sortedJobList.filter(row =>
                row.timestamp.includes(searchLower) ||
                (row.rejected.length < 4 ? "Ja" : "Nej").includes(searchLower) ||
                row.from.toLowerCase().includes(searchLower) ||
                row.to.toLowerCase().includes(searchLower) ||
                row.subject?.toLowerCase().includes(searchLower) ||
                row.messageSize.toString().toLowerCase().includes(searchLower)
            ))
        } else {
            setFilteredSortedEmailList(sortedJobList)
        }
    }, [rawEmailList, search, sortObj])

    function performSearch(searchTerm) {
        setSearch(searchTerm)
    }

    return (
        <div>
            <AdminOperationsEmailFilterSearchBar className={`d-flex justify-content-between align-items-center p-2`}
                                                 searchValue={search} performSearch={performSearch}/>
            <div className='text-nowrap small ps-3 mb-1'>{`Antal: ${filteredSortedEmailList.length}`}</div>
            <AdminOperationsEmailTableBody className={'mb-2'} emailList={filteredSortedEmailList} sort={sortObj}
                                           sortHandler={sortHandler}/>
        </div>
    )
}
