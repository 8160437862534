import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import ComboBox from '../../../../components/shared/combobox/ComboBox'
import GroupsFilterSelector from '../../../../components/shared/groupsfilterselector/GroupsFilterSelector.js'
import InformationModal from '../../../../components/shared/informationmodal/InformationModal'
import InputGroup from '../../../../components/shared/inputgroup/InputGroup.js'
import {syslang} from '../../../../helpers/constants';
import {getAssignableSystemRoles, mailValidator} from '../../../../helpers/helperfunctions'
import ModalTopText from '../../../../components/shared/modaltoptext/ModalTopText'
import PopOverDialog from '../../../../components/shared/popoverdialog/PopOverDialog'

/** Modal with input fields to add a new user. Allows to specify mail, role and language
 * @param {function} setVisibility parent function to control modal visibility
 * @param {function} addUser parent function to handle submit
 * @param {boolean} show property from parent to control visiblity of modal
 * @param {[string]} creatorRoleList roles of the user creating (Determines what roles the user can assign to a new user)
 * @returns Modal */
export default function AddUserModal({setVisibility, addUser, show, creatorRoleList}) {
    const [selectedgroups, setSelectedGroups] = useState([])
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    const [groupsError, setGroupsError] = useState(false)
    const [roleError, setRoleError] = useState(false)
    const [role, setRole] = useState(process.env.REACT_APP_APP_VIEWER)
    const [preferredLanguage, setPreferredLanguage] = useState("en")
    const {t} = useTranslation(['common','userpage', 'inputerrors'])

    const selectItem = (selected) => {
        setSelectedGroups(selected)
        if ((selected.length === 0 && role !== process.env.REACT_APP_APP_ADMIN) || selected.length > 0) setGroupsError(false)
    }

    const defineRole = (e) => {
        setRoleError(!e)
        setRole(e)
        if (e === process.env.REACT_APP_APP_ADMIN) setGroupsError(false)
    }
    const defineLanguage = (e) => {
        setPreferredLanguage(e)
    }
    const submit = () => {
        let validationResult = mailValidator(email)
        if (validationResult) {
            setEmailError(validationResult)
            return
        }
        if (!roleError) {
            if (role === process.env.REACT_APP_APP_ADMIN || (selectedgroups.length > 0 && role !== process.env.REACT_APP_APP_ADMIN)) {
                addUser(email, role, selectedgroups, preferredLanguage)
            } else {
                setGroupsError(true)
            }
        }
    }
    const dismissModal = () => {
        setGroupsError(false);
        setEmailError(false);
        setVisibility(false);
        setEmail("")
    }

    return (
        <InformationModal dialogClassName="group-selector-modal" show={show}
                          title={t('userpage:user_management.create_new_user_modal.modal_title')}
                          body={
                              <React.Fragment>
                                  <ModalTopText body={t('userpage:user_management.create_new_user_modal.body')}/>
                                  <div className="mt-2">
                                      <InputGroup isInvalid={!!emailError} errorText={emailError} inputValue={(e) => {
                                          setEmail(e.target.value);
                                          setEmailError('')
                                      }} name="mail" className="inputthirdcol ps-0 pe-xs-0" type="text" value={email}
                                                  placeholder={t('userpage:user_management.create_new_user_modal.inputs.placeholders.mail')}
                                                  label={t('userpage:user_management.create_new_user_modal.inputs.labels.mail')}/>
                                      <div className="inputthirdcol ps-0 pe-xs-0">
                                          <p className="d-inline-block mb-2">{t('userpage:user_management.create_new_user_modal.inputs.labels.role')}</p>
                                          <PopOverDialog
                                              popOverTitel={t('userpage:user_management.edit_user_modal.popover.header')}
                                              popOverContent={t('userpage:user_management.edit_user_modal.popover.body')}
                                              contentAsHtml={true}
                                              className="d-inline-block"/>
                                          <ComboBox isInvalid={roleError} inputValue={(e) => defineRole(e.target.value)}
                                                    name="role"
                                                    value={role} options={getAssignableSystemRoles(creatorRoleList)}
                                                    hasLabel={false}
                                                    blankChoice={t('userpage:user_management.create_new_user_modal.inputs.placeholders.role')}/>
                                      </div>
                                      <ComboBox inputValue={e => defineLanguage(e.target.value)}
                                                name="preferredLanguage" className="inputthirdcol px-0"
                                                value={preferredLanguage} options={syslang.map(l => ({id: l.id, option: t(`common:lng.${l.id}`)}))}
                                                label={t('userpage:user_management.create_new_user_modal.inputs.labels.lang')}
                                                blankChoice={t('userpage:user_management.create_new_user_modal.inputs.placeholders.lang')}/>
                                  </div>
                                  <div
                                      className={`transition-all admin-selected ${(role === process.env.REACT_APP_APP_ADMIN) ? 'fade-in' : 'fade-out'}`}>
                                      <div className="p-2" role="alert">
                                          {t('userpage:user_management.create_new_user_modal.admin_alert')}
                                      </div>
                                  </div>
                                  <GroupsFilterSelector selectItem={(items) => selectItem(items)} hasError={groupsError}
                                                        dropzoneBody={t('userpage:groupsselector.dropzone_body')}/>
                              </React.Fragment>
                          } size="lg"
                          accept={() => submit()}
                          dismiss={() => {
                              dismissModal()
                          }}/>
    )
}