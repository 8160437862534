import React, {useRef, useState} from 'react'
import FilterButton from '../../../components/shared/filterbutton/FilterButton'
import {useTranslation} from 'react-i18next'
import FilterModal from '../../../components/shared/filterModal/FilterModal'
import {useDispatch, useSelector} from "react-redux"
import ComboBox from "../../shared/combobox/ComboBox.js"
import {setFirmwareFilter} from "../../../helpers/reduxstore/reducers/filterReducer.js"
import {JE_AwareSwitch} from "../../../pages/firmware/JE_AwareSwitch.js"
import {SearchInput} from "../../shared/searchinput/SearchInput.js"

export default function DeviceFirmwareTableFilterSearch({
                                                            className, filterGroups, setFilterGroups,
                                                            firmwareVersionFilterValue,
                                                            firmwareListOptions, deviceTypeFilterValue,
                                                            deviceTypeFilterOptions, deviceFamily
                                                        }) {
    const dispatch = useDispatch()
    const {t} = useTranslation(['firmwarepage', 'common', 'telemetry'])
    const {firmwareFilter} = useSelector(state => state.filter)

    const [search, setSearch] = useState(firmwareFilter.searchString ?? '')
    const [filterModalVisibility, setFilterModalVisibility] = useState(false)
    const timeoutHandle = useRef(null)

    // when typing in search input, wait some time before sending to backend, so we don't spam too much
    function startSearchTimeout(searchValue) {
        setSearch(searchValue)
        if (timeoutHandle.current !== null) window.clearTimeout(timeoutHandle.current)
        timeoutHandle.current = setTimeout(() => {
            dispatch(setFirmwareFilter({searchString: searchValue}))
        }, 1000)
    }

    function setNewDeviceFamily(state) {
        dispatch(setFirmwareFilter({
            deviceFamily: state ? 'je' : 'aware',
            deviceTypeFilter: "",
            firmwareVersionFilter: ""
        }))
    }

    return (
        <div className={className}>
            <FilterModal selectedItemsList={filterGroups} show={filterModalVisibility}
                         dismiss={() => setFilterModalVisibility(false)}
                         submit={(filterGroupList) => {
                             setFilterGroups(filterGroupList)
                             setFilterModalVisibility(false)
                         }}
                         modalTitle={t('firmwarepage:filter_modal.title')}
                         modalBody={t('firmwarepage:filter_modal.body')}
                         dropzoneBody={t('firmwarepage:filter_modal.dropzone_body')}
            />
            <div className='mb-xs-2 min-w-300px'>
                <FilterButton showModal={() => setFilterModalVisibility(true)}
                              removeFilter={() => setFilterGroups([])} hasPopOver={true}
                              filterGroups={filterGroups}/>
            </div>

            <div className='ms-2 d-flex flex-column justify-content-center'>
                <JE_AwareSwitch value={deviceFamily === 'je'}
                                setValue={setNewDeviceFamily}/>
            </div>

            <ComboBox className={'ms-md-2 mb-xs-2'}
                      inputValue={(e) => dispatch(setFirmwareFilter({firmwareVersionFilter: e.target.value, page: 1}))}
                      value={firmwareVersionFilterValue} options={firmwareListOptions} hasLabel={false}/>

            <ComboBox className={'ms-md-2 mb-xs-2'}
                      inputValue={(e) => dispatch(setFirmwareFilter({deviceTypeFilter: e.target.value, page: 1}))}
                      value={deviceTypeFilterValue} options={deviceTypeFilterOptions} hasLabel={false}/>

            <div className='ms-auto'>
                <SearchInput setSearchString={e => startSearchTimeout(e.target.value)} searchString={search}
                             placeholder={t('common:search.placeholder')}/>
            </div>
        </div>
    )
}