import React, {useEffect, useState} from 'react';
import UserTableBody from './UserTableBody.js';
import UserTableHead from './UserTableHead.js';
import {cloneDeep} from 'lodash';
import TFooter from '../../../../components/shared/tfooter/TFooter';
import {useMsal} from '@azure/msal-react';
import {flattenTree, formatDateTimeString} from '../../../../helpers/helperfunctions'
import {roleConstants} from "../../../../helpers/constants.js";

/** Renders a table for the userprofile page, which allows for managing invited users
 * @param {string} className classes added to the component
 * @param {function} showAddUserModal function to show the add user modal
 * @param {object[]} administeredUsers list of users injected into the component that are subject to be shown in the table
 * @param {function} openEditModal function to show the edit user modal
 * @param {function} openDeleteModal function to show the delete user modal
 * @param {function} openPrivilegesmodal function to show a warning modal, if the user tries to delete a user which he is not allowed to delete
 * @param {function} sendReinvite function to show the re-invite user modal
 */
export default function UserTable({
                                      showAddUserModal, administeredUsers, openEditModal,
                                      openDeleteModal, openPrivilegesmodal, sendReinvite
                                  }) {
    const [selectedGroups, setSelectedGroups] = useState([])
    const [searchString, setSearchString] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [users, setUsers] = useState([])
    const {accounts} = useMsal();

    useEffect(() => {
        let filteredUsers = applyFilter(cloneDeep(administeredUsers), selectedGroups);
        let searchedUsers = searchInUserList(searchString, filteredUsers);
        setPage(1)
        setUsers(searchedUsers)
    }, [searchString, selectedGroups, administeredUsers])

    const searchInUserList = (search, data) => {
        return data.filter(obj => {
            let stringToSearch = `${obj.userData.appRole}${obj.userData.externalUserState}${obj.userData.fullName}${obj.userData.mail}${obj.userData.preferredLanguage}${formatDateTimeString(obj.userData.created, true, false)}`
            return stringToSearch.toString().toLowerCase().includes(search.toString().toLowerCase())
        })
    }

    const applyFilter = (administeredUserList, selectedGroups) => {
        if (selectedGroups.length === 0) return administeredUserList

        let result = flattenTree(selectedGroups).map(a => a.id);
        let filteredUsers = administeredUserList.filter(user => user.groups.some(r => result.includes(r)))

        // if user is admin, also show admins (not sure if this makes sense). Admins has access to all groups. Would you
        // expect to see admins when filtering for a group?
        if (accounts[0].idTokenClaims.roles.includes(roleConstants.Admin)) {
            filteredUsers = filteredUsers.concat(administeredUserList.filter(obj => obj.userData.appRole === "Administrator"))
        }
        return filteredUsers
    }

    return (
        <>
            <UserTableHead searchString={searchString} setSearchString={setSearchString}
                           showAddUserModal={showAddUserModal} selectedGroups={selectedGroups}
                           setSelectedGroups={setSelectedGroups} userListToShowNum={users.length}/>
            <div className='p-1 bg-primarybg-dim'>
                <UserTableBody accounts={accounts} userListToShow={users} rowsPerPage={rowsPerPage} page={page}
                                openEditModal={openEditModal} openDeleteModal={openDeleteModal}
                                openPrivilegesmodal={openPrivilegesmodal} sendReinvite={sendReinvite}/>
            </div>
            <TFooter className='mb-3' setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage}
                     dataLength={users.length} page={page}/>
        </>
    )
}