
/** This file has been generated. If you see errors or missing parameters, don't change this file. Instead, fix scripts/generate_je_parameters_specification_from_xml/generate_je_parameters_specification_from_xml.js and regenerate it. */
export const paramSpec = {
    "1": {
        "id": 1,
        "name": "rqf",
        "name_orig": "RQF",
        "desc": "Requested Flow (Calculated from all flow inputs)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "2": {
        "id": 2,
        "name": "rqt",
        "name_orig": "RQT",
        "desc": "Requested Temperature (Calculated from all temperature inputs)\nThis value does not contain \"DISPLACEMENT\" offset (see parameter 254)",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 0.1,
        "default": 19,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "3": {
        "id": 3,
        "name": "default_airflow",
        "name_orig": "Default Airflow",
        "desc": "Default airflow, value loaded power up, and eventually during stop (depend on settings)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Default Setpoints"
        ]
    },
    "4": {
        "id": 4,
        "name": "default_temperature",
        "name_orig": "Default Temperature",
        "desc": "Default temperature, value loaded power up, and eventually during stop (depend on settings)",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Default Setpoints"
        ]
    },
    "6": {
        "id": 6,
        "name": "loop1",
        "name_orig": "Loop1",
        "desc": "Which output to cycle through in Loop1",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ],
        "options": {
            "0": "Bp",
            "1": "ExtP",
            "2": "Fan"
        }
    },
    "7": {
        "id": 7,
        "name": "user_tool_access_level",
        "name_orig": "User Tool Access level",
        "desc": "Used by Viva and Orbit displays only",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Group",
            "1": "All"
        }
    },
    "8": {
        "id": 8,
        "name": "temp_0_2v",
        "name_orig": "Temp 0-2V",
        "desc": "CTS &#176;C inlet air 0 – 2 V (&#176;C decided from CTS)",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Analog"
        ]
    },
    "9": {
        "id": 9,
        "name": "temp_2_4v",
        "name_orig": "Temp 2-4V",
        "desc": "CTS &#176;C inlet air 2 – 4 V (&#176;C decided from CTS)",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Analog"
        ]
    },
    "10": {
        "id": 10,
        "name": "temp_4_6v",
        "name_orig": "Temp 4-6V",
        "desc": "CTS &#176;C inlet air 4 – 6 V (&#176;C decided from CTS)",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 21,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Analog"
        ]
    },
    "11": {
        "id": 11,
        "name": "temp_6_8v",
        "name_orig": "Temp 6-8V",
        "desc": "CTS &#176;C inlet air 6 – 8 V (&#176;C decided from CTS)",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 22,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Analog"
        ]
    },
    "12": {
        "id": 12,
        "name": "temp_8_10v",
        "name_orig": "Temp 8-10V",
        "desc": "CTS &#176;C inlet air 8 – 10 V (&#176;C decided from CTS)",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 23,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Analog"
        ]
    },
    "13": {
        "id": 13,
        "name": "bp_installed",
        "name_orig": "BP Installed",
        "desc": "Is a by-pass damper installed on the unit, and what type is it?\n(VC: Voltage Controlled / TC - Time Controlled)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ],
        "options": {
            "0": "No",
            "1": "VC",
            "2": "TC"
        }
    },
    "14": {
        "id": 14,
        "name": "bp_engage",
        "name_orig": "BP Engage",
        "desc": "Engage Automatic by-pass Control",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "15": {
        "id": 15,
        "name": "temp_diff",
        "name_orig": "Temp Diff",
        "desc": "By – pass &#176;C difference  (Temperature difference for starting by-pass)",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.5,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "16": {
        "id": 16,
        "name": "min_temp",
        "name_orig": "Min Temp",
        "desc": "By – pass minimum outside &#176;C (Minimum outside &#176;C to start by-pass)",
        "type": "decimal",
        "min": -10,
        "max": 25,
        "res": 0.5,
        "default": 5,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "17": {
        "id": 17,
        "name": "ht_rt_flow_increment",
        "name_orig": "HT RT Flow Increment",
        "desc": "High Temp Room Temp increment",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "18": {
        "id": 18,
        "name": "loop2",
        "name_orig": "Loop2",
        "desc": "Which output to cycle through in Loop2",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ],
        "options": {
            "0": "Bp",
            "1": "ExtP",
            "2": "Fan"
        }
    },
    "19": {
        "id": 19,
        "name": "t_measure",
        "name_orig": "T Measure",
        "desc": "By - pass mean measuring (Time measuring before acting)",
        "type": "int",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "20": {
        "id": 20,
        "name": "interval",
        "name_orig": "Interval",
        "desc": "By-pass damper interval – open and close",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "21": {
        "id": 21,
        "name": "wait",
        "name_orig": "Wait",
        "desc": "Wait time after regulating by-pass damper",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 15,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "22": {
        "id": 22,
        "name": "log_mode",
        "name_orig": "Log Mode",
        "desc": "Log Mode (Normal = Runtime, Survey = Unit Survey)\nN.B.: Unit Survey empty log, and log until end of survey",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog"
        ],
        "options": {
            "0": "Off",
            "1": "Norm.",
            "2": "Start Survey",
            "3": "Survey Running"
        }
    },
    "23": {
        "id": 23,
        "name": "cool_flow",
        "name_orig": "Cool Flow",
        "desc": "Minimum flow when activating cooling unit",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "24": {
        "id": 24,
        "name": "high_room_temperature_high_limit",
        "name_orig": "High Room Temperature, High Limit",
        "desc": "High Room Temperature. Increase airflow and lower inlet temperature",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 25,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "25": {
        "id": 25,
        "name": "high_room_temperature_low_limit",
        "name_orig": "High Room Temperature, Low Limit",
        "desc": "Low Room Temperature. Set normal airflow and set normal inlet temperature",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 24,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "26": {
        "id": 26,
        "name": "bp_fan_cor",
        "name_orig": "BP Fan Cor",
        "desc": "",
        "type": "int",
        "min": -99,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "27": {
        "id": 27,
        "name": "pir_afterrun_time",
        "name_orig": "PIR Afterrun Time",
        "desc": "The amount of time the PIR start signal continuous to be active after last precence detection",
        "type": "int",
        "min": 0,
        "max": 1080,
        "res": 1,
        "default": 30,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "PIR"
        ]
    },
    "28": {
        "id": 28,
        "name": "rt_timer",
        "name_orig": "RT Timer",
        "desc": "RT Timer. Wait state when software changes Inlet Temperature",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 30,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "29": {
        "id": 29,
        "name": "it_hyst",
        "name_orig": "IT Hyst.",
        "desc": "Hysteresis for regulating damper up/down due to low/high inlet temperature",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "30": {
        "id": 30,
        "name": "co2_sensor_installed",
        "name_orig": "CO2 Sensor Installed",
        "desc": "CO2 sensor installed (Mode for overwriting setpoint)",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "CO2"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "31": {
        "id": 31,
        "name": "rt_diff_high",
        "name_orig": "RT Diff High",
        "desc": "High temp: Necessary difference between room temp and inlet temp before forcing 100% ventilations level",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "32": {
        "id": 32,
        "name": "rt_diff_low",
        "name_orig": "RT Diff Low",
        "desc": "High temp: Minimum difference between room temp and inlet temp before resetting to normal ventilation level.",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "33": {
        "id": 33,
        "name": "ot_rt_diff",
        "name_orig": "OT-RT Diff",
        "desc": "High temp: Max difference between outside temp and room temp before decreasing by-pass damper opening",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "34": {
        "id": 34,
        "name": "exit_timer",
        "name_orig": "Exit Timer",
        "desc": "High temp: Shutdown timer. Need to run out before closing by-pass damper, when damper level reaches 0V",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "35": {
        "id": 35,
        "name": "loop3",
        "name_orig": "Loop3",
        "desc": "Which output to cycle through in Loop3",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ],
        "options": {
            "0": "Bp",
            "1": "ExtP",
            "2": "Fan"
        }
    },
    "36": {
        "id": 36,
        "name": "measuring_time",
        "name_orig": "Measuring Time",
        "desc": "Measuring Time (Inclusive setteling time)",
        "type": "int",
        "min": 1,
        "max": 255,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "37": {
        "id": 37,
        "name": "bp_initial",
        "name_orig": "BP Initial",
        "desc": "Initial By-pass position",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "38": {
        "id": 38,
        "name": "t_evaporate",
        "name_orig": "T Evaporate",
        "desc": "Evaporation Time between condensate tests",
        "type": "int",
        "min": 0,
        "max": 60,
        "res": 1,
        "default": 20,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Condensation"
        ]
    },
    "39": {
        "id": 39,
        "name": "supply_airflow_reduction",
        "name_orig": "Supply Airflow Reduction",
        "desc": "Condens sensor: % reducing intake airflow (% reducing intake airflow)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 50,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Condensation"
        ]
    },
    "40": {
        "id": 40,
        "name": "t_soft_start",
        "name_orig": "T Soft Start",
        "desc": "Condens sensor: waiting for normal airflow (Time to go before fan 1 run at normal speed after there is no longer condens signal)",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Condensation"
        ]
    },
    "41": {
        "id": 41,
        "name": "pid_log",
        "name_orig": "PID Log",
        "desc": "PID Log on/off",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog"
        ],
        "options": {
            "0": "OFF",
            "1": "ON"
        }
    },
    "42": {
        "id": 42,
        "name": "bp_final",
        "name_orig": "BP Final",
        "desc": "Final By-pass position (only if number of cycles > 1)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "43": {
        "id": 43,
        "name": "bp_cycles",
        "name_orig": "BP Cycles",
        "desc": "Number of cycles through the By-pass position loop",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "44": {
        "id": 44,
        "name": "extp_initial",
        "name_orig": "ExtP Initial",
        "desc": "Initial External Pressure Actuator Position",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "45": {
        "id": 45,
        "name": "allow_start_by_external_start",
        "name_orig": "Allow Start by External Start",
        "desc": "Start by External Start, also requires that External Start is a start signal",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "46": {
        "id": 46,
        "name": "not_in_use",
        "name_orig": "Not In Use",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 0,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": []
    },
    "47": {
        "id": 47,
        "name": "not_in_use_NAMEDUPLICATE_OF_46",
        "name_orig": "Not In Use",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 0,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": []
    },
    "48": {
        "id": 48,
        "name": "humidity_control_installed",
        "name_orig": "Humidity Control Installed",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Humidity"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "49": {
        "id": 49,
        "name": "humidity_control_enabled",
        "name_orig": "Humidity Control Enabled",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "50": {
        "id": 50,
        "name": "manual_override_time",
        "name_orig": "Manual Override Time",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 18,
        "res": 1,
        "default": 12,
        "showInTechnical": true,
        "unit": "hour",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Airlinq Panel",
            "General"
        ]
    },
    "51": {
        "id": 51,
        "name": "filter_test_mode",
        "name_orig": "Filter Test Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "default": 3,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ],
        "options": {
            "0": "Off",
            "1": "Timer",
            "2": "Tacho",
            "3": "Timer And Tacho",
            "4": "DP-switch only"
        }
    },
    "52": {
        "id": 52,
        "name": "life_span_warning",
        "name_orig": "Life Span Warning",
        "desc": "Time before filter Warning in hours",
        "type": "int",
        "min": 0,
        "max": 8760,
        "res": 1,
        "default": 1500,
        "showInTechnical": true,
        "unit": "hour",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ]
    },
    "53": {
        "id": 53,
        "name": "st_filter_test_max_difference",
        "name_orig": "ST Filter Test Max Difference",
        "desc": "Max difference in tacho measurement compared with last test",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 20,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "54": {
        "id": 54,
        "name": "current_filter_state",
        "name_orig": "Current Filter State",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ],
        "options": {
            "0": "green",
            "1": "yellow",
            "2": "red"
        }
    },
    "55": {
        "id": 55,
        "name": "life_span_alarm",
        "name_orig": "Life Span Alarm",
        "desc": "Time before filter Alarm in hours",
        "type": "int",
        "min": 0,
        "max": 8760,
        "res": 1,
        "default": 2000,
        "showInTechnical": true,
        "unit": "hour",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ]
    },
    "56": {
        "id": 56,
        "name": "extp_final",
        "name_orig": "ExtP Final",
        "desc": "Final External Pressure Actuator Position (only if number of cycles > 1)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "57": {
        "id": 57,
        "name": "disable_low_temp_alarm",
        "name_orig": "Disable Low Temp Alarm",
        "desc": "Disable the Low Temp Alarm for X minutes after leaving the Start Procedure",
        "type": "int",
        "min": 0,
        "max": 60,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp Alarm"
        ]
    },
    "58": {
        "id": 58,
        "name": "retry_low_temp_alarm",
        "name_orig": "Retry Low Temp Alarm",
        "desc": "Time Between Automated Start Attemps if unit was stopped due to Low Temp Alarm",
        "type": "int",
        "min": 0,
        "max": 18,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp Alarm"
        ]
    },
    "59": {
        "id": 59,
        "name": "min_fan_voltage",
        "name_orig": "Min Fan Voltage",
        "desc": "Minimum allowed fan speed",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 1.2,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "60": {
        "id": 60,
        "name": "low_inlet",
        "name_orig": "Low Inlet",
        "desc": "Low temp: &#176;C difference for reducing fan level (&#176;C difference between decided and actual &#176;C before the fan level is reduced)",
        "type": "decimal",
        "min": 0,
        "max": 60,
        "res": 0.5,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "61": {
        "id": 61,
        "name": "intake_airflow_reduction",
        "name_orig": "Intake Airflow Reduction",
        "desc": "Low temp: % reducing intake airflow (% the intake airflow is reduced with when the inlet  &#176;C is low)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "62": {
        "id": 62,
        "name": "min_outlet",
        "name_orig": "Min Outlet",
        "desc": "Low temp: Minimum outlet (Lowest accepted &#176;C outlet air before the unit is stopped and alarm is set)",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 0.5,
        "default": -2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "63": {
        "id": 63,
        "name": "wait_low",
        "name_orig": "Wait Low",
        "desc": "Wait time in low inlet: General wait time in low inlet process",
        "type": "int",
        "min": 0,
        "max": 60,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "64": {
        "id": 64,
        "name": "low_outlet",
        "name_orig": "Low Outlet",
        "desc": "Low temp: Low outlet temperature. Decrease Vent 1 speed",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 0.5,
        "default": 1,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "65": {
        "id": 65,
        "name": "inlet_increment",
        "name_orig": "Inlet Increment",
        "desc": "Low temp: Inlet temp. rising. Increase Vent 1 speed",
        "type": "decimal",
        "min": 0,
        "max": 60,
        "res": 0.5,
        "default": 1,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "66": {
        "id": 66,
        "name": "outlet_increment",
        "name_orig": "Outlet Increment",
        "desc": "Low temp: Outlet temp. rising. Increase Vent 1 speed",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 0.5,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "67": {
        "id": 67,
        "name": "ph__max_etv",
        "name_orig": "PH  Max ETV",
        "desc": "Heating surface: Preheat temperature. Activate pre-heating surface below this temperature",
        "type": "decimal",
        "min": -20,
        "max": 50,
        "res": 0.5,
        "default": 4,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "68": {
        "id": 68,
        "name": "ph_sf_reduction",
        "name_orig": "PH SF Reduction",
        "desc": "Heating surface: Preheat airflow. Vent 1 has to be decreased to this airflow before activating pre-heating surface.",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 90,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "69": {
        "id": 69,
        "name": "min_inlet",
        "name_orig": "Min Inlet",
        "desc": "Low temp : Minimum inlet &#176;C (Lowest accepted &#176;C inlet air before the unit is stopped and alarm is set)",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "70": {
        "id": 70,
        "name": "flow_alarm",
        "name_orig": "Flow Alarm",
        "desc": "Alarm flow difference (Difference betweed set and actual airflow before flow alarm is set)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 5,
        "default": 20,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ]
    },
    "71": {
        "id": 71,
        "name": "normal_interval",
        "name_orig": "Normal Interval",
        "desc": "Runtime Log Interval",
        "type": "int",
        "min": 1,
        "max": 9999,
        "res": 1,
        "default": 300,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog"
        ]
    },
    "72": {
        "id": 72,
        "name": "reboot",
        "name_orig": "Reboot",
        "desc": "Reboots the unit",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Misc"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "73": {
        "id": 73,
        "name": "st_settling_time",
        "name_orig": "ST Settling Time",
        "desc": "Time for the flow to settle during the filter test",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "74": {
        "id": 74,
        "name": "unit_survey_period",
        "name_orig": "Unit Survey Period",
        "desc": "Unit Survey Log Time Period, Count Down, to show remaining time.\nShown as h up to 240 h above shown in days.",
        "type": "int",
        "min": 1,
        "max": 32300,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog"
        ]
    },
    "75": {
        "id": 75,
        "name": "nc_it",
        "name_orig": "NC IT",
        "desc": "Night Cooling: Fixed Inlet Temperature during Night Cooling",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 16,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "D-BMS"
        ]
    },
    "76": {
        "id": 76,
        "name": "unit_survey_interval",
        "name_orig": "Unit Survey Interval",
        "desc": "Unit Survey Log Interval",
        "type": "int",
        "min": 1,
        "max": 3600,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog"
        ]
    },
    "77": {
        "id": 77,
        "name": "bp_run_time",
        "name_orig": "BP Run Time",
        "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ]
    },
    "78": {
        "id": 78,
        "name": "nc_min_ot",
        "name_orig": "NC Min OT",
        "desc": "",
        "type": "decimal",
        "min": -20,
        "max": 50,
        "res": 0.5,
        "default": 5,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ]
    },
    "79": {
        "id": 79,
        "name": "ch_water_soft_start",
        "name_orig": "CH Water Soft Start",
        "desc": "Case a water comfort heater is installed, start gradually over this period",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 180,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "80": {
        "id": 80,
        "name": "nc_flow",
        "name_orig": "NC Flow",
        "desc": "Night cooling: Fixed Airflow during Night Cooling",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "D-BMS"
        ]
    },
    "81": {
        "id": 81,
        "name": "nc_high_limit",
        "name_orig": "NC High Limit",
        "desc": "Night cooling: High limit. Daytime temperature to initiate Night Cooling",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 26,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ]
    },
    "82": {
        "id": 82,
        "name": "nc_low_limit",
        "name_orig": "NC Low Limit",
        "desc": "Night cooling: Low limit. Daytime temperature to maintain Night Cooling",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 23,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ]
    },
    "83": {
        "id": 83,
        "name": "min_flow",
        "name_orig": "Min Flow",
        "desc": "Minimum fan 1 speed when decreasing speed due to low temperature",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 50,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp Alarm"
        ]
    },
    "84": {
        "id": 84,
        "name": "language",
        "name_orig": "Language",
        "desc": "1=English, 2=Danish, 3=Dutch, 4=German, 5=Swedish, 6=French, 7=Norwegian, 8=Finnish",
        "type": "enum",
        "min": 1,
        "max": 8,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ],
        "options": {
            "1": "English",
            "2": "Danish",
            "3": "Dutch",
            "4": "German",
            "5": "Swedish",
            "6": "French",
            "7": "Norwegian",
            "8": "Finnish"
        }
    },
    "85": {
        "id": 85,
        "name": "comfort_heater",
        "name_orig": "Comfort Heater",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Heating Surfaces"
        ],
        "options": {
            "0": "No",
            "1": "Electric",
            "2": "Water"
        }
    },
    "86": {
        "id": 86,
        "name": "hm_flow",
        "name_orig": "HM Flow",
        "desc": "Holiday Airflow Level",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 5,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Holiday Mode"
        ]
    },
    "87": {
        "id": 87,
        "name": "hm_temperature",
        "name_orig": "HM Temperature",
        "desc": "Holiday Ventilation Temperature",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Holiday Mode"
        ]
    },
    "88": {
        "id": 88,
        "name": "extp_cycles",
        "name_orig": "ExtP Cycles",
        "desc": "Number of cycles through the External Pressure actuator positon loop",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "89": {
        "id": 89,
        "name": "fan_initial",
        "name_orig": "Fan Initial",
        "desc": "Initial Fan % (both fans. % according to max motor voltage)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "90": {
        "id": 90,
        "name": "start_dependency",
        "name_orig": "Start Dependency",
        "desc": "Whether the start priorities are independent or dependent",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "Independent",
            "1": "Dependent",
            "2": "1 Dependent + Independent"
        }
    },
    "91": {
        "id": 91,
        "name": "start_priority_1",
        "name_orig": "Start Priority 1",
        "desc": "Start priority 1",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 3,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "92": {
        "id": 92,
        "name": "start_priority_2",
        "name_orig": "Start Priority 2",
        "desc": "Start priority 2",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "93": {
        "id": 93,
        "name": "start_priority_3",
        "name_orig": "Start Priority 3",
        "desc": "Start priority 3",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "94": {
        "id": 94,
        "name": "start_priority_4",
        "name_orig": "Start Priority 4",
        "desc": "Start priority 4",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "95": {
        "id": 95,
        "name": "start_priority_5",
        "name_orig": "Start Priority 5",
        "desc": "Start priority 5",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 7,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "96": {
        "id": 96,
        "name": "started_by",
        "name_orig": "Started By",
        "desc": "Started By: Contains the Highest active start priority",
        "type": "enum",
        "min": 0,
        "max": 14,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "off",
            "1": "A-BMS",
            "2": "Panel",
            "3": "PIR",
            "4": "External start",
            "5": "CO2",
            "6": "Dependent",
            "7": "Halted",
            "8": "0-10V",
            "9": "D-BMS",
            "10": "Timer",
            "11": "Boost",
            "12": "TVOC",
            "13": "Room temperature",
            "14": "Airlinq Online"
        }
    },
    "97": {
        "id": 97,
        "name": "fan_final",
        "name_orig": "Fan Final",
        "desc": "Final Fan % (only if number of cycles > 1)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "98": {
        "id": 98,
        "name": "fan_cycles",
        "name_orig": "Fan Cycles",
        "desc": "Number of cycles through the Fan % loop",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ]
    },
    "99": {
        "id": 99,
        "name": "bp_tc_open",
        "name_orig": "BP TC: Open",
        "desc": "Open Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ],
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "100": {
        "id": 100,
        "name": "udf",
        "name_orig": "UDF",
        "desc": "User Defined Air Flow (flow menu)",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": []
    },
    "101": {
        "id": 101,
        "name": "minimum_flow_percent",
        "name_orig": "Minimum Flow %",
        "desc": "Minimum Airflow",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 40,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Control Limits"
        ]
    },
    "102": {
        "id": 102,
        "name": "maximum_flow_percent",
        "name_orig": "Maximum Flow %",
        "desc": "Maximum Airflow, can be overrided by internal procedures",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Control Limits"
        ]
    },
    "103": {
        "id": 103,
        "name": "udt",
        "name_orig": "UDT",
        "desc": "User Defined Inlet Temperature (temperature menu)",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 0.1,
        "default": 19,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": []
    },
    "104": {
        "id": 104,
        "name": "temperature_setpoint_minimum",
        "name_orig": "Temperature Setpoint Minimum",
        "desc": "Indbl&#230;sningstemperatur min",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "default": 14,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Control Limits"
        ]
    },
    "105": {
        "id": 105,
        "name": "temperature_setpoint_maximum",
        "name_orig": "Temperature Setpoint Maximum",
        "desc": "Indbl&#230;sningstemperatur max",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "default": 30,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Control Limits"
        ]
    },
    "106": {
        "id": 106,
        "name": "displacement",
        "name_orig": "Displacement",
        "desc": "Displacement Temperature offset (set to 'off' for mixing units)",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Displacement"
        ],
        "options": {
            "0": "OFF",
            "1": "0",
            "2": "-1",
            "3": "-2",
            "4": "-3",
            "5": "-4",
            "6": "-5"
        }
    },
    "107": {
        "id": 107,
        "name": "normal_ventilation_request",
        "name_orig": "Normal ventilation (Request)",
        "desc": "Normal ventilation (Request)",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "OFF",
            "1": "ON"
        }
    },
    "108": {
        "id": 108,
        "name": "vph_mode",
        "name_orig": "VPH Mode",
        "desc": "Virtual Pre Heater Mode",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Virtual Preheat"
        ],
        "options": {
            "0": "OFF",
            "1": "Green",
            "2": "Comfort"
        }
    },
    "109": {
        "id": 109,
        "name": "night_cool_request",
        "name_orig": "Night cool (Request)",
        "desc": "Night cool (Request)",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "OFF",
            "1": "ON"
        }
    },
    "110": {
        "id": 110,
        "name": "vph_max",
        "name_orig": "VPH Max",
        "desc": "Virtual Pre Heater, maximum opening of By-Pass",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 40,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Virtual Preheat"
        ]
    },
    "111": {
        "id": 111,
        "name": "holiday_moderequest",
        "name_orig": "Holiday mode(Request)",
        "desc": "Starttid baggrundsventilation (Request)",
        "type": "int",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": []
    },
    "112": {
        "id": 112,
        "name": "vph_step_size",
        "name_orig": "VPH Step Size",
        "desc": "Virtual Pre Heater, Step Size",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Virtual Preheat"
        ]
    },
    "113": {
        "id": 113,
        "name": "bp_vc_max_position",
        "name_orig": "BP VC: Max Position",
        "desc": "Voltage representing max physical by-pass position",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 10,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ]
    },
    "114": {
        "id": 114,
        "name": "bp_tc_cw_rotation",
        "name_orig": "BP TC: CW Rotation",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "115": {
        "id": 115,
        "name": "max_volt_sf",
        "name_orig": "Max Volt SF",
        "desc": "Supply Fan maximum volt",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 1,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "116": {
        "id": 116,
        "name": "bp_tc_ccw_rotation",
        "name_orig": "BP TC: CCW Rotation",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "117": {
        "id": 117,
        "name": "max_volt_ef",
        "name_orig": "Max Volt EF",
        "desc": "Exhaust Fan maximum volt",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 1,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "118": {
        "id": 118,
        "name": "co2_min",
        "name_orig": "CO2 Min",
        "desc": "CO2 concentration above this level, will cause a positive override of the flow",
        "type": "int",
        "min": 400,
        "max": 5000,
        "res": 50,
        "default": 500,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ]
    },
    "119": {
        "id": 119,
        "name": "co2_max",
        "name_orig": "CO2 Max",
        "desc": "The CO2 level causing maximum air flow",
        "type": "int",
        "min": 400,
        "max": 5000,
        "res": 50,
        "default": 1000,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ]
    },
    "120": {
        "id": 120,
        "name": "total_operating_hours",
        "name_orig": "Total Operating Hours",
        "desc": "Total Time Counter \n(changing this value requires factory password)",
        "type": "int",
        "min": 0,
        "max": 300000,
        "res": 10,
        "default": 0,
        "showInTechnical": true,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Misc"
        ]
    },
    "121": {
        "id": 121,
        "name": "operating_hours_since_last_service",
        "name_orig": "Operating Hours Since Last Service",
        "desc": "Time since last service",
        "type": "int",
        "min": 0,
        "max": 300000,
        "res": 10,
        "default": 0,
        "showInTechnical": true,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Misc"
        ]
    },
    "122": {
        "id": 122,
        "name": "co2_sensor_ppm_range_min",
        "name_orig": "CO2 Sensor ppm Range Min",
        "desc": "Minimum CO2 concentration for the CO2 sensor",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 50,
        "default": 0,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "CO2"
        ]
    },
    "123": {
        "id": 123,
        "name": "co2_sensor_ppm_range_max",
        "name_orig": "CO2 Sensor ppm Range Max",
        "desc": "Maximum CO2 concentration for the CO2 sensor",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 50,
        "default": 2000,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "CO2"
        ]
    },
    "124": {
        "id": 124,
        "name": "co2_sensor_volt_range_min",
        "name_orig": "CO2 Sensor Volt Range Min",
        "desc": "Mimum Volt for the CO2 sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "CO2"
        ]
    },
    "125": {
        "id": 125,
        "name": "co2_sensor_volt_range_max",
        "name_orig": "CO2 Sensor Volt Range Max",
        "desc": "Maximum Volt for the CO2 sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 10,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "CO2"
        ]
    },
    "126": {
        "id": 126,
        "name": "bp_vc_min_position",
        "name_orig": "BP VC: Min Position",
        "desc": "Voltage representing min physical by-pass position",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ]
    },
    "127": {
        "id": 127,
        "name": "test_program_time",
        "name_orig": "Test Program Time",
        "desc": "Time between steps in the factory test procedure",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ]
    },
    "128": {
        "id": 128,
        "name": "retries",
        "name_orig": "Retries",
        "desc": "Number of retries with the interval  \"T Evaporate\"",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Process Settings",
            "Condensation"
        ]
    },
    "129": {
        "id": 129,
        "name": "pid_sample_t",
        "name_orig": "PID Sample T",
        "desc": "PID Sample Time",
        "type": "decimal",
        "min": 0.01,
        "max": 10,
        "res": 0.01,
        "default": 0.5,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "130": {
        "id": 130,
        "name": "cc_icc_otv_rqt_temp_difference",
        "name_orig": "CC ICC OTV RQT Temp Difference",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "131": {
        "id": 131,
        "name": "cc_icc_min_cooling_time",
        "name_orig": "CC ICC Min Cooling Time",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 240,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "132": {
        "id": 132,
        "name": "cc_icc_temperature_timer",
        "name_orig": "CC ICC Temperature Timer",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 240,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "133": {
        "id": 133,
        "name": "cc_hg_temperature_max",
        "name_orig": "CC HG Temperature Max",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 60,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "134": {
        "id": 134,
        "name": "sv_total_steps",
        "name_orig": "SV Total Steps",
        "desc": "Danfoss ETS 6: Full steps = 240, 1/2 steps = 480, 1/4 steps = 960 etc.",
        "type": "int",
        "min": 1,
        "max": 30000,
        "res": 1,
        "default": 480,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Stepper Valve"
        ]
    },
    "135": {
        "id": 135,
        "name": "sv_calibration_steps",
        "name_orig": "SV Calibration Steps",
        "desc": "normally total steps + 10-50steps (Danfoss ETS 6: Full Steps = 255, 1/2 Steps = 510, 1/4 Steps = 1020 etc.)",
        "type": "int",
        "min": 1,
        "max": 30000,
        "res": 1,
        "default": 510,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Stepper Valve"
        ]
    },
    "136": {
        "id": 136,
        "name": "sv_moves_between_zeroing",
        "name_orig": "SV Moves Between Zeroing",
        "desc": "0 = disabled",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Stepper Valve"
        ]
    },
    "137": {
        "id": 137,
        "name": "sv_steps_per_second",
        "name_orig": "SV Steps Per Second",
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 1000,
        "res": 1,
        "default": 30,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Stepper Valve"
        ]
    },
    "138": {
        "id": 138,
        "name": "sv_step_size",
        "name_orig": "SV Step Size",
        "desc": "",
        "type": "enum",
        "min": 1,
        "max": 8,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Stepper Valve"
        ],
        "options": {
            "1": "Full Step",
            "2": "1/2 Step",
            "3": "1/4 Step",
            "4": "8 Microsteps",
            "5": "16 Microsteps",
            "6": "32 Microsteps",
            "7": "Full Step with power off",
            "8": "1/2 Step with power off"
        }
    },
    "139": {
        "id": 139,
        "name": "sv_open_direction_reversed",
        "name_orig": "SV Open Direction Reversed",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Stepper Valve"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "140": {
        "id": 140,
        "name": "cc_valve_pid_kp",
        "name_orig": "CC Valve PID KP",
        "desc": "Cooling Controller Valve, Proportional Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "141": {
        "id": 141,
        "name": "cc_valve_pid_ki",
        "name_orig": "CC Valve PID KI",
        "desc": "Cooling Controller Valve, Integral Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "142": {
        "id": 142,
        "name": "cc_valve_pid_kd",
        "name_orig": "CC Valve PID KD",
        "desc": "Cooling Controller Valve, Differential Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "143": {
        "id": 143,
        "name": "cc_valve_pid_ka",
        "name_orig": "CC Valve PID KA",
        "desc": "Cooling Controller Valve, Anti Windup Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "144": {
        "id": 144,
        "name": "cc_valve_pid_min_out",
        "name_orig": "CC Valve PID Min Out",
        "desc": "Cooling Controller Valve, minimum output",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "145": {
        "id": 145,
        "name": "cc_valve_pid_max_out",
        "name_orig": "CC Valve PID Max Out",
        "desc": "Cooling Controller Valve, maximum output",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "146": {
        "id": 146,
        "name": "cc_valve_pid_sample_time",
        "name_orig": "CC Valve PID Sample Time",
        "desc": "Cooling Controller Valve, sample time",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "147": {
        "id": 147,
        "name": "cc_valve_pid_reference",
        "name_orig": "CC Valve PID Reference",
        "desc": "Cooling Controller Valve, reference",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 0.1,
        "default": 3,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ]
    },
    "148": {
        "id": 148,
        "name": "cc_icc_pid_kp",
        "name_orig": "CC ICC PID KP",
        "desc": "Cooling Controller Inverter Controlled Cooling, Proportional Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Cooling"
        ]
    },
    "149": {
        "id": 149,
        "name": "cc_icc_pid_ki",
        "name_orig": "CC ICC PID KI",
        "desc": "Cooling Controller Inverter Controlled Cooling, Integral Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Cooling"
        ]
    },
    "150": {
        "id": 150,
        "name": "cc_icc_pid_kd",
        "name_orig": "CC ICC PID KD",
        "desc": "Cooling Controller Inverter Controlled Cooling, Differential Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Cooling"
        ]
    },
    "151": {
        "id": 151,
        "name": "cc_icc_pid_ka",
        "name_orig": "CC ICC PID KA",
        "desc": "Cooling Controller Inverter Controlled Cooling, Anti Windup Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Cooling"
        ]
    },
    "152": {
        "id": 152,
        "name": "cc_icc_pid_sample_time",
        "name_orig": "CC ICC PID Sample Time",
        "desc": "Cooling Controller Inverter Controlled Cooling, Sample Time",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Cooling"
        ]
    },
    "153": {
        "id": 153,
        "name": "cc_hg_pid_kp",
        "name_orig": "CC HG PID KP",
        "desc": "Cooling Controller HotGas, Proportional Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "154": {
        "id": 154,
        "name": "cc_hg_pid_ki",
        "name_orig": "CC HG PID KI",
        "desc": "Cooling Controller HotGas, Integral Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "155": {
        "id": 155,
        "name": "cc_hg_pid_kd",
        "name_orig": "CC HG PID KD",
        "desc": "Cooling Controller HotGas, Differential Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "156": {
        "id": 156,
        "name": "cc_hg_pid_ka",
        "name_orig": "CC HG PID KA",
        "desc": "Cooling Controller HotGas, Anti Windup Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "157": {
        "id": 157,
        "name": "cc_evt_pid_kp",
        "name_orig": "CC EVT PID KP",
        "desc": "Cooling Controller Evaporator Temp, Proportional Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Evaporator"
        ]
    },
    "158": {
        "id": 158,
        "name": "cc_evt_pid_ki",
        "name_orig": "CC EVT PID KI",
        "desc": "Cooling Controller Evaporator Temp, Integral Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Evaporator"
        ]
    },
    "159": {
        "id": 159,
        "name": "cc_evt_pid_kd",
        "name_orig": "CC EVT PID KD",
        "desc": "Cooling Controller Evaporator Temp, Differential Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Evaporator"
        ]
    },
    "160": {
        "id": 160,
        "name": "cc_evt_pid_ka",
        "name_orig": "CC EVT PID KA",
        "desc": "Cooling Controller Evaporator Temp, Anti Windup Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Evaporator"
        ]
    },
    "161": {
        "id": 161,
        "name": "cc_icc_mode",
        "name_orig": "CC ICC Mode",
        "desc": "Cooling Controller Inverter Controlled Cooling Mode",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ],
        "options": {
            "0": "Volt",
            "1": "Hz"
        }
    },
    "162": {
        "id": 162,
        "name": "cc_icc_inverter_min_voltage",
        "name_orig": "CC ICC Inverter Min Voltage",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "163": {
        "id": 163,
        "name": "cc_icc_inverter_max_voltage",
        "name_orig": "CC ICC Inverter Max Voltage",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "164": {
        "id": 164,
        "name": "cc_icc_inverter_min_frequency",
        "name_orig": "CC ICC Inverter Min Frequency",
        "desc": "",
        "type": "int",
        "min": 10,
        "max": 1000,
        "res": 1,
        "default": 42,
        "showInTechnical": true,
        "unit": "Hz",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "165": {
        "id": 165,
        "name": "cc_icc_inverter_max_frequency",
        "name_orig": "CC ICC Inverter Max Frequency",
        "desc": "",
        "type": "int",
        "min": 10,
        "max": 1000,
        "res": 1,
        "default": 160,
        "showInTechnical": true,
        "unit": "Hz",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "166": {
        "id": 166,
        "name": "cc_hg_pid_max_out",
        "name_orig": "CC HG PID Max Out",
        "desc": "Cooling Controller HotGas, PID max out",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 10,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "167": {
        "id": 167,
        "name": "cc_evt_pid_max_out",
        "name_orig": "CC EVT PID Max Out",
        "desc": "Cooling Controller Evaporator, PID max out",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 10,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Evaporator"
        ]
    },
    "168": {
        "id": 168,
        "name": "cc_t1",
        "name_orig": "CC T1",
        "desc": "Input function for cooling controller input temperature 1",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Cooling Controller",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp"
        }
    },
    "169": {
        "id": 169,
        "name": "cc_t2",
        "name_orig": "CC T2",
        "desc": "Input function for cooling controller input temperature 2",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Cooling Controller",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp"
        }
    },
    "170": {
        "id": 170,
        "name": "cc_t3",
        "name_orig": "CC T3",
        "desc": "Input function for cooling controller input temperature 3",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Cooling Controller",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp"
        }
    },
    "171": {
        "id": 171,
        "name": "cc_t4",
        "name_orig": "CC T4",
        "desc": "Input function for cooling controller input temperature 4",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Cooling Controller",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp"
        }
    },
    "174": {
        "id": 174,
        "name": "room_1_activation",
        "name_orig": "Room 1 Activation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ],
        "options": {
            "0": "None",
            "1": "Boost",
            "2": "PIR",
            "3": "Ext Start"
        }
    },
    "175": {
        "id": 175,
        "name": "room_2_activation",
        "name_orig": "Room 2 Activation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ],
        "options": {
            "0": "None",
            "1": "Boost",
            "2": "PIR",
            "3": "Ext Start"
        }
    },
    "176": {
        "id": 176,
        "name": "valve_position_neutral",
        "name_orig": "Valve Position Neutral",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 5,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "177": {
        "id": 177,
        "name": "valve_position_room_1",
        "name_orig": "Valve Position Room 1",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "178": {
        "id": 178,
        "name": "valve_position_room_2",
        "name_orig": "Valve Position Room 2",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 8,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "179": {
        "id": 179,
        "name": "humidity_sensor_1_temperature",
        "name_orig": "Humidity Sensor 1 Temperature",
        "desc": "Input function for humidity sensor 1 temperature",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp"
        }
    },
    "180": {
        "id": 180,
        "name": "humidity_sensor_2_temperature",
        "name_orig": "Humidity Sensor 2 Temperature",
        "desc": "Input function for humidity sensor 2 temperature",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp"
        }
    },
    "190": {
        "id": 190,
        "name": "fixed_displacement",
        "name_orig": "Fixed Displacement",
        "desc": "Fixed Displacement - requires \"Displacement != off\", and will fix the IT-Setpoint Displacement degrees below the measured RT.",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Displacement"
        ],
        "options": {
            "0": "off",
            "1": "on"
        }
    },
    "197": {
        "id": 197,
        "name": "state_bitfield",
        "name_orig": "State (bitfield)",
        "desc": "State BitField: {b0=HighTemp, b1=Defrost, b2=LowTemp, b3=Condens, b4=NightCool, b5=BackgroundVentilation, b6=Running, b7=Starting, b8=Stopping, b9=Stopped, b10=Test, b11=LoopTest, b12=FilterTest, b13=HighRoomTemp}",
        "type": "int",
        "min": 0,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "198": {
        "id": 198,
        "name": "masterslave_startstop",
        "name_orig": "Master/slave (start//stop)",
        "desc": "Master/slave (start//stop)",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "off",
            "1": "on"
        }
    },
    "199": {
        "id": 199,
        "name": "unit_alarm_status_bitfield",
        "name_orig": "Unit Alarm Status (bitfield)",
        "desc": "Unit Alarm Status (bitfield)",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "condensation_alarm",
                "name_orig": "Condensation Alarm",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "temperature_sensor_alarm",
                "name_orig": "Temperature Sensor Alarm",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "low_temperature_alarm",
                "name_orig": "Low Temperature Alarm",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "supply_fan_alarm",
                "name_orig": "Supply Fan Alarm",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "filteralarm",
                "name_orig": "FilterAlarm",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "extraction_fan_alarm",
                "name_orig": "Extraction Fan Alarm",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "cc_condensation_alarm",
                "name_orig": "CC Condensation Alarm",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "203": {
        "id": 203,
        "name": "bp_tc_self_calibration",
        "name_orig": "BP TC: Self Calibration",
        "desc": "The number of actuator moves allowed, before the actuator performs a self-calibration",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ]
    },
    "204": {
        "id": 204,
        "name": "min_volt_sf",
        "name_orig": "Min Volt SF",
        "desc": "Supply Fan minimum volt",
        "type": "decimal",
        "min": -10,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "205": {
        "id": 205,
        "name": "st_meas_time",
        "name_orig": "ST Meas Time",
        "desc": "Time, over which the Tacho pulses are measured",
        "type": "int",
        "min": 1,
        "max": 255,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "206": {
        "id": 206,
        "name": "st_sfstdtacho",
        "name_orig": "ST SFstdTacho",
        "desc": "The Supply Fan tacho signal for installed unit with clean filters [Pulses Per Minute]",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 1800,
        "showInTechnical": true,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "207": {
        "id": 207,
        "name": "min_volt_ef",
        "name_orig": "Min Volt EF",
        "desc": "Exhaust Fan minimum volt",
        "type": "decimal",
        "min": -10,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "208": {
        "id": 208,
        "name": "st_force_interval",
        "name_orig": "ST Force Interval",
        "desc": "Forced Filter Test Interval",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 24,
        "showInTechnical": true,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "209": {
        "id": 209,
        "name": "md_run_time",
        "name_orig": "MD Run Time",
        "desc": "The time it takes for the MD damper to operate from 0-100%",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "default": 75,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Main Damper"
        ]
    },
    "210": {
        "id": 210,
        "name": "flow_measurements_to_average",
        "name_orig": "Flow Measurements to Average",
        "desc": "Number of flowmeasurements for moving average",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "211": {
        "id": 211,
        "name": "md_tc_open",
        "name_orig": "MD TC: Open",
        "desc": "Open Main Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Main Damper"
        ],
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "212": {
        "id": 212,
        "name": "flow_hourlog",
        "name_orig": "Flow Hourlog",
        "desc": "Flow compensated hour counter (10 hour interval)",
        "type": "int",
        "min": 0,
        "max": 300000,
        "res": 10,
        "default": 0,
        "showInTechnical": true,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Misc"
        ]
    },
    "213": {
        "id": 213,
        "name": "st_test_flow",
        "name_orig": "ST Test Flow",
        "desc": "The Flow at which the test is conducted",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 70,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "214": {
        "id": 214,
        "name": "st_efstdtacho",
        "name_orig": "ST EFstdTacho",
        "desc": "The Extraction Fan tacho signal for installed unit with clean filters [Pulses Per Minute]",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 1800,
        "showInTechnical": true,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "215": {
        "id": 215,
        "name": "st_start_time",
        "name_orig": "ST Start Time",
        "desc": "The time where filter test is run, if the unit is running, otherwise a Filter test is forced at the next startup",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 720,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "216": {
        "id": 216,
        "name": "qsa_pid_kp",
        "name_orig": "QSA PID KP",
        "desc": "Supply Fan PID, Proportional Gain",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "217": {
        "id": 217,
        "name": "qsa_pid_ki",
        "name_orig": "QSA PID KI",
        "desc": "Supply Air Flow PID, Integral Gain",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0.2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "218": {
        "id": 218,
        "name": "qsa_pid_kd",
        "name_orig": "QSA PID KD",
        "desc": "Supply Air Flow PID, Differential Gain",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "219": {
        "id": 219,
        "name": "qsa_pid_ka",
        "name_orig": "QSA PID KA",
        "desc": "Supply Air Flow PID, Anti Windup Gain",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 10,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "220": {
        "id": 220,
        "name": "md_tc_cw_rot",
        "name_orig": "MD TC: CW Rot.",
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Main Damper"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "221": {
        "id": 221,
        "name": "md_tc_ccw_rot",
        "name_orig": "MD TC: CCW Rot.",
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Main Damper"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "222": {
        "id": 222,
        "name": "night_cool_independent",
        "name_orig": "Night Cool Independent",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "223": {
        "id": 223,
        "name": "qea_pid_kp",
        "name_orig": "QEA PID KP",
        "desc": "Extraction Air Flow PID, Proportional Gain",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "224": {
        "id": 224,
        "name": "qea_pid_ki",
        "name_orig": "QEA PID KI",
        "desc": "Extraction Air Flow PID, Integral Gain",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0.2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "225": {
        "id": 225,
        "name": "qea_pid_kd",
        "name_orig": "QEA PID KD",
        "desc": "Extraction Air Flow PID, Differential Gain",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "226": {
        "id": 226,
        "name": "qea_pid_ka",
        "name_orig": "QEA PID KA",
        "desc": "Extraction Flow PID, Anti Windup Gain",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 10,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "227": {
        "id": 227,
        "name": "supply_fan_tacho_signal_at_last_\"filter_test\"",
        "name_orig": "Supply Fan Tacho Signal at last \"Filter Test\"",
        "desc": "Supply Fan Tacho Signal at last \"Filter Test\"",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "228": {
        "id": 228,
        "name": "extraction_fan_tacho_signal_at_last_\"filter_test\"",
        "name_orig": "Extraction Fan Tacho Signal at last \"Filter Test\"",
        "desc": "Extraction Fan Tacho Signal at last \"Filter Test\"",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "229": {
        "id": 229,
        "name": "nominal_max_flow",
        "name_orig": "Nominal Max Flow",
        "desc": "Nominal Max Flow for this unit \\n Convert: \\n Flow [m&#179;/h] / 3,6 = Flow [l/s] \\n E.g.,      360 m&#179;/h / 3,6       = 100 l/s \\n \\n Flow [l/s] * 3,6 = Flow [m&#179;/h] \\n E.g.,      100 l/s * 3,6         = 360 m&#179;/h",
        "type": "decimal",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "230": {
        "id": 230,
        "name": "bp_tc_cw_offset",
        "name_orig": "BP TC: CW Offset",
        "desc": "TC:  CW Offset [ms]. Calibration of time for CW rotation (Designed for compensation for \"inertia\" in the damper+actuator)",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ]
    },
    "231": {
        "id": 231,
        "name": "bp_tc_ccw_offset",
        "name_orig": "BP TC: CCW Offset",
        "desc": "TC:  CCW Offset [ms]. Calibration of time for CCW rotation  (Designed for compensation for \"inertia\" in the damper+actuator)",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Bypass"
        ]
    },
    "232": {
        "id": 232,
        "name": "pre_heater",
        "name_orig": "Pre Heater",
        "desc": "Preheater installed/off",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Heating Surfaces"
        ],
        "options": {
            "0": "No",
            "1": "Electric"
        }
    },
    "233": {
        "id": 233,
        "name": "md_mode",
        "name_orig": "MD Mode",
        "desc": "\"Self Return\" covers both electrical and mechanical \"spring\" return... i.e. it is a \"Power off reverse function\".",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Main Damper"
        ],
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Self Return"
        }
    },
    "234": {
        "id": 234,
        "name": "high_temperature_delay",
        "name_orig": "High Temperature Delay",
        "desc": "High Temperature Delay (prevent HT after heating and startup)",
        "type": "int",
        "min": 0,
        "max": 60,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "General"
        ]
    },
    "235": {
        "id": 235,
        "name": "electrical_heater_delay",
        "name_orig": "Electrical Heater Delay",
        "desc": "Electrical Heater, Delay for returning to \"normal flow\" after usage",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 30,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "General"
        ]
    },
    "236": {
        "id": 236,
        "name": "electrical_heater_increase_flow",
        "name_orig": "Electrical Heater Increase Flow",
        "desc": "Increase Air Flow, case it is to low for Electrical Heating.",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "General"
        ]
    },
    "237": {
        "id": 237,
        "name": "electrical_heater_min_flow",
        "name_orig": "Electrical Heater Min Flow",
        "desc": "Stop Electrical Heaters if Supply Flow is less than this",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "General"
        ]
    },
    "238": {
        "id": 238,
        "name": "electrical_heater_min_tacho",
        "name_orig": "Electrical Heater Min Tacho",
        "desc": "Stop Electrical Heaters if Supply Fan Tacho is less than this",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "General"
        ]
    },
    "239": {
        "id": 239,
        "name": "mode",
        "name_orig": "Mode",
        "desc": "Select Flow Mode: Ventilator voltage percentage / PID flow controller",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ],
        "options": {
            "0": "Volt Direct",
            "1": "Flow Control",
            "2": "Volt Control"
        }
    },
    "240": {
        "id": 240,
        "name": "errorcodes_0_3",
        "name_orig": "ErrorCodes [0-3]",
        "desc": "Alarm[0-3]: 4xNibbles {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": []
    },
    "241": {
        "id": 241,
        "name": "errorcodes_4_7",
        "name_orig": "ErrorCodes [4-7]",
        "desc": "Alarm[4-7]: 4xNibbles {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": []
    },
    "242": {
        "id": 242,
        "name": "errorcodes_8_11",
        "name_orig": "ErrorCodes [8-11]",
        "desc": "Alarm[8-11]: 4xNibbles  {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": []
    },
    "243": {
        "id": 243,
        "name": "errorcodes_12_15",
        "name_orig": "ErrorCodes [12-15]",
        "desc": "Alarm[12-15]: 4xNibbles  {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": []
    },
    "244": {
        "id": 244,
        "name": "halt",
        "name_orig": "Halt",
        "desc": "Yes: all moving parts inside the unit cease moving and  \"Halted\" is indicated in display\nNo: Unit is in \"normal\" mode",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Misc"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "247": {
        "id": 247,
        "name": "power_failure___system_restore_state_unit_running_and_display_locked_bit_field",
        "name_orig": "Power failure - system restore state (unit running? and display locked?) (bit field)",
        "desc": "Power failure - system restore state (unit running? and display locked?) (bit field)",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "249": {
        "id": 249,
        "name": "reset_active_errors",
        "name_orig": "Reset Active Errors",
        "desc": "System flags (bit-field): bit0: reset active errors",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Misc"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "250": {
        "id": 250,
        "name": "air_handling_units",
        "name_orig": "Air Handling Units",
        "desc": "Expected number of ventilation units",
        "type": "int",
        "min": 1,
        "max": 20,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "251": {
        "id": 251,
        "name": "actual_number_of_air_handling_units",
        "name_orig": "Actual number of air handling units",
        "desc": "actual number of ventilation units",
        "type": "int",
        "min": 0,
        "max": 20,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": []
    },
    "252": {
        "id": 252,
        "name": "show_startup_guide",
        "name_orig": "Show Startup Guide",
        "desc": "Indicates whether startup guide should be shown on next startup",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ],
        "options": {
            "0": "Yes",
            "1": "No"
        }
    },
    "255": {
        "id": 255,
        "name": "software_version",
        "name_orig": "Software Version",
        "desc": "Software Version",
        "type": "decimal",
        "min": 0,
        "max": 32,
        "res": 0.001,
        "default": 5,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "256": {
        "id": 256,
        "name": "whether_filter_test_should_be_forced_during_startup_or_not",
        "name_orig": "Whether Filter Test should be forced during startup or not",
        "desc": "Whether Filter Test should be forced during startup or not",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "257": {
        "id": 257,
        "name": "communication_id",
        "name_orig": "Communication ID",
        "desc": "The units id on the internal communication bus, 0 = Master, 1-19 = slave",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "258": {
        "id": 258,
        "name": "group_id",
        "name_orig": "Group ID",
        "desc": "Which Group is this unit member of",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "259": {
        "id": 259,
        "name": "group_0_master",
        "name_orig": "Group 0 Master",
        "desc": "Id of groupmaster for Group 0",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "260": {
        "id": 260,
        "name": "group_1_master",
        "name_orig": "Group 1 Master",
        "desc": "Id of groupmaster for Group 1",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "261": {
        "id": 261,
        "name": "group_2_master",
        "name_orig": "Group 2 Master",
        "desc": "Id of groupmaster for Group 2",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "262": {
        "id": 262,
        "name": "group_3_master",
        "name_orig": "Group 3 Master",
        "desc": "Id of groupmaster for Group 3",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "263": {
        "id": 263,
        "name": "group_4_master",
        "name_orig": "Group 4 Master",
        "desc": "Id of groupmaster for Group 4",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "264": {
        "id": 264,
        "name": "group_5_master",
        "name_orig": "Group 5 Master",
        "desc": "Id of groupmaster for Group 5",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "265": {
        "id": 265,
        "name": "group_6_master",
        "name_orig": "Group 6 Master",
        "desc": "Id of groupmaster for Group 6",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "266": {
        "id": 266,
        "name": "group_7_master",
        "name_orig": "Group 7 Master",
        "desc": "Id of groupmaster for Group 7",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "267": {
        "id": 267,
        "name": "group_8_master",
        "name_orig": "Group 8 Master",
        "desc": "Id of groupmaster for Group 8",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "268": {
        "id": 268,
        "name": "group_9_master",
        "name_orig": "Group 9 Master",
        "desc": "Id of groupmaster for Group 9",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "269": {
        "id": 269,
        "name": "group_10_master",
        "name_orig": "Group 10 Master",
        "desc": "Id of groupmaster for Group 10",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "270": {
        "id": 270,
        "name": "group_11_master",
        "name_orig": "Group 11 Master",
        "desc": "Id of groupmaster for Group 11",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "271": {
        "id": 271,
        "name": "group_12_master",
        "name_orig": "Group 12 Master",
        "desc": "Id of groupmaster for Group 12",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "272": {
        "id": 272,
        "name": "group_13_master",
        "name_orig": "Group 13 Master",
        "desc": "Id of groupmaster for Group 13",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "273": {
        "id": 273,
        "name": "group_14_master",
        "name_orig": "Group 14 Master",
        "desc": "Id of groupmaster for Group 14",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "274": {
        "id": 274,
        "name": "group_15_master",
        "name_orig": "Group 15 Master",
        "desc": "Id of groupmaster for Group 15",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "275": {
        "id": 275,
        "name": "group_16_master",
        "name_orig": "Group 16 Master",
        "desc": "Id of groupmaster for Group 16",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "276": {
        "id": 276,
        "name": "group_17_master",
        "name_orig": "Group 17 Master",
        "desc": "Id of groupmaster for Group 17",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "277": {
        "id": 277,
        "name": "group_18_master",
        "name_orig": "Group 18 Master",
        "desc": "Id of groupmaster for Group 18",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "278": {
        "id": 278,
        "name": "group_19_master",
        "name_orig": "Group 19 Master",
        "desc": "Id of groupmaster for Group 19",
        "type": "int",
        "min": 0,
        "max": 19,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "BMS",
            "Airlinq"
        ]
    },
    "279": {
        "id": 279,
        "name": "stop",
        "name_orig": "Stop",
        "desc": "Disables all start priorities including timer",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "280": {
        "id": 280,
        "name": "t1",
        "name_orig": "T1",
        "desc": "Input function for input temperature 1",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "281": {
        "id": 281,
        "name": "t2",
        "name_orig": "T2",
        "desc": "Input function for input temperature 2",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "282": {
        "id": 282,
        "name": "t3",
        "name_orig": "T3",
        "desc": "Input function for input temperature 3",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "283": {
        "id": 283,
        "name": "t4",
        "name_orig": "T4",
        "desc": "Input function for input temperature 4",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "284": {
        "id": 284,
        "name": "t5",
        "name_orig": "T5",
        "desc": "Input function for input temperature 5\n (NA for AQC-LT)",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "285": {
        "id": 285,
        "name": "t6",
        "name_orig": "T6",
        "desc": "Input function for temperature input 5\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "286": {
        "id": 286,
        "name": "t7",
        "name_orig": "T7",
        "desc": "Input function for temperature input 7\n (NA for AQC-L and AQC-P pcb rev. 5)",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "287": {
        "id": 287,
        "name": "t8",
        "name_orig": "T8",
        "desc": "Input function for temperature input 8\n (NA for AQC-L and AQC-P pcb rev. 5)",
        "type": "enum",
        "min": 0,
        "max": 10,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Temperature"
        ],
        "options": {
            "0": "None",
            "1": "Inlet Temp",
            "2": "Room Temp",
            "3": "Exhaust Temp Ventilation Unit",
            "4": "Outside Temp",
            "5": "Evaporator Temp",
            "6": "Condenser Temp",
            "7": "Outside Temp Ventilation Unit",
            "8": "Evaporator Temp In",
            "9": "Evaporator Temp Out",
            "10": "General Purpose Temp}"
        }
    },
    "290": {
        "id": 290,
        "name": "ai_1",
        "name_orig": "AI 1",
        "desc": "Input function for input voltage (0-10V) 1",
        "type": "enum",
        "min": 0,
        "max": 21,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog inputs"
        ],
        "options": {
            "0": "None",
            "1": "CO2 Sensor 1",
            "2": "CO2 Sensor 2",
            "3": "CO2 Sensor 3",
            "4": "A-BMS Flow SetPoint",
            "5": "A-BMS Temperature SetPoint",
            "6": "0-10V Flow SetPoint",
            "7": "Humidity Indoor",
            "8": "Humidity Outdoor",
            "9": "Ext Start",
            "10": "A-BMS Start",
            "11": "PIR 1",
            "12": "Ventilation Floatswitch",
            "13": "Comfort Cooling Error",
            "14": "External Stop",
            "15": "Boost",
            "16": "Reset Filter Status",
            "17": "PIR 2",
            "18": "PIR 3",
            "19": "Differential Pressure Switch (NO)",
            "20": "Combined CO2 + TVOC + RH Room Sensor",
            "21": "Comfort Cooling Floatswitch"
        }
    },
    "291": {
        "id": 291,
        "name": "ai_2",
        "name_orig": "AI 2",
        "desc": "Input function for input voltage (0-10V) 2",
        "type": "enum",
        "min": 0,
        "max": 21,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog inputs"
        ],
        "options": {
            "0": "None",
            "1": "CO2 Sensor 1",
            "2": "CO2 Sensor 2",
            "3": "CO2 Sensor 3",
            "4": "A-BMS Flow SetPoint",
            "5": "A-BMS Temperature SetPoint",
            "6": "0-10V Flow SetPoint",
            "7": "Humidity Indoor",
            "8": "Humidity Outdoor",
            "9": "Ext Start",
            "10": "A-BMS Start",
            "11": "PIR 1",
            "12": "Ventilation Floatswitch",
            "13": "Comfort Cooling Error",
            "14": "External Stop",
            "15": "Boost",
            "16": "Reset Filter Status",
            "17": "PIR 2",
            "18": "PIR 3",
            "19": "Differential Pressure Switch (NO)",
            "20": "Combined CO2 + TVOC + RH Room Sensor",
            "21": "Comfort Cooling Floatswitch"
        }
    },
    "292": {
        "id": 292,
        "name": "ai_3",
        "name_orig": "AI 3",
        "desc": "Input function for voltage input (0-10V) 3\n (NA for AQC-L pcb rev. < 8 )",
        "type": "enum",
        "min": 0,
        "max": 21,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog inputs"
        ],
        "options": {
            "0": "None",
            "1": "CO2 Sensor 1",
            "2": "CO2 Sensor 2",
            "3": "CO2 Sensor 3",
            "4": "A-BMS Flow SetPoint",
            "5": "A-BMS Temperature SetPoint",
            "6": "0-10V Flow SetPoint",
            "7": "Humidity Indoor",
            "8": "Humidity Outdoor",
            "9": "Ext Start",
            "10": "A-BMS Start",
            "11": "PIR 1",
            "12": "Ventilation Floatswitch",
            "13": "Comfort Cooling Error",
            "14": "External Stop",
            "15": "Boost",
            "16": "Reset Filter Status",
            "17": "PIR 2",
            "18": "PIR 3",
            "19": "Differential Pressure Switch (NO)",
            "20": "Combined CO2 + TVOC + RH Room Sensor",
            "21": "Comfort Cooling Floatswitch"
        }
    },
    "293": {
        "id": 293,
        "name": "ai_4",
        "name_orig": "AI 4",
        "desc": "Input function for voltage input (0-10V) 4\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 21,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog inputs"
        ],
        "options": {
            "0": "None",
            "1": "CO2 Sensor 1",
            "2": "CO2 Sensor 2",
            "3": "CO2 Sensor 3",
            "4": "A-BMS Flow SetPoint",
            "5": "A-BMS Temperature SetPoint",
            "6": "0-10V Flow SetPoint",
            "7": "Humidity Indoor",
            "8": "Humidity Outdoor",
            "9": "Ext Start",
            "10": "A-BMS Start",
            "11": "PIR 1",
            "12": "Ventilation Floatswitch",
            "13": "Comfort Cooling Error",
            "14": "External Stop",
            "15": "Boost",
            "16": "Reset Filter Status",
            "17": "PIR 2",
            "18": "PIR 3",
            "19": "Differential Pressure Switch (NO)",
            "20": "Combined CO2 + TVOC + RH Room Sensor",
            "21": "Comfort Cooling Floatswitch"
        }
    },
    "294": {
        "id": 294,
        "name": "ai_5",
        "name_orig": "AI 5",
        "desc": "Input function for input voltage (0-10V) 5\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 21,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog inputs"
        ],
        "options": {
            "0": "None",
            "1": "CO2 Sensor 1",
            "2": "CO2 Sensor 2",
            "3": "CO2 Sensor 3",
            "4": "A-BMS Flow SetPoint",
            "5": "A-BMS Temperature SetPoint",
            "6": "0-10V Flow SetPoint",
            "7": "Humidity Indoor",
            "8": "Humidity Outdoor",
            "9": "Ext Start",
            "10": "A-BMS Start",
            "11": "PIR 1",
            "12": "Ventilation Floatswitch",
            "13": "Comfort Cooling Error",
            "14": "External Stop",
            "15": "Boost",
            "16": "Reset Filter Status",
            "17": "PIR 2",
            "18": "PIR 3",
            "19": "Differential Pressure Switch (NO)",
            "20": "Combined CO2 + TVOC + RH Room Sensor",
            "21": "Comfort Cooling Floatswitch"
        }
    },
    "296": {
        "id": 296,
        "name": "di_1",
        "name_orig": "DI 1",
        "desc": "Input function for digital input 1",
        "type": "enum",
        "min": 0,
        "max": 12,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital inputs"
        ],
        "options": {
            "0": "None",
            "1": "Ext Start",
            "2": "A-BMS Start",
            "3": "PIR 1",
            "4": "Ventilation Floatswitch",
            "5": "Comfort Cooling Error",
            "6": "External Stop",
            "7": "Boost",
            "8": "Reset Filter Status",
            "9": "PIR 2",
            "10": "PIR 3",
            "11": "Differential Pressure Switch (NO)",
            "12": "Comfort Cooling Floatswitch"
        }
    },
    "297": {
        "id": 297,
        "name": "di_2",
        "name_orig": "DI 2",
        "desc": "Input function for digital input 2\n (special input on AQC-L, see electrical documentation)",
        "type": "enum",
        "min": 0,
        "max": 12,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital inputs"
        ],
        "options": {
            "0": "None",
            "1": "Ext Start",
            "2": "A-BMS Start",
            "3": "PIR 1",
            "4": "Ventilation Floatswitch",
            "5": "Comfort Cooling Error",
            "6": "External Stop",
            "7": "Boost",
            "8": "Reset Filter Status",
            "9": "PIR 2",
            "10": "PIR 3",
            "11": "Differential Pressure Switch (NO)",
            "12": "Comfort Cooling Floatswitch"
        }
    },
    "298": {
        "id": 298,
        "name": "di_3",
        "name_orig": "DI 3",
        "desc": "Input function for digital input 3\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 12,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital inputs"
        ],
        "options": {
            "0": "None",
            "1": "Ext Start",
            "2": "A-BMS Start",
            "3": "PIR 1",
            "4": "Ventilation Floatswitch",
            "5": "Comfort Cooling Error",
            "6": "External Stop",
            "7": "Boost",
            "8": "Reset Filter Status",
            "9": "PIR 2",
            "10": "PIR 3",
            "11": "Differential Pressure Switch (NO)",
            "12": "Comfort Cooling Floatswitch"
        }
    },
    "299": {
        "id": 299,
        "name": "di_4",
        "name_orig": "DI 4",
        "desc": "Input function for digital input 4\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 12,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital inputs"
        ],
        "options": {
            "0": "None",
            "1": "Ext Start",
            "2": "A-BMS Start",
            "3": "PIR 1",
            "4": "Ventilation Floatswitch",
            "5": "Comfort Cooling Error",
            "6": "External Stop",
            "7": "Boost",
            "8": "Reset Filter Status",
            "9": "PIR 2",
            "10": "PIR 3",
            "11": "Differential Pressure Switch (NO)",
            "12": "Comfort Cooling Floatswitch"
        }
    },
    "300": {
        "id": 300,
        "name": "di_5",
        "name_orig": "DI 5",
        "desc": "Input function for digital input 5\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 12,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital inputs"
        ],
        "options": {
            "0": "None",
            "1": "Ext Start",
            "2": "A-BMS Start",
            "3": "PIR 1",
            "4": "Ventilation Floatswitch",
            "5": "Comfort Cooling Error",
            "6": "External Stop",
            "7": "Boost",
            "8": "Reset Filter Status",
            "9": "PIR 2",
            "10": "PIR 3",
            "11": "Differential Pressure Switch (NO)",
            "12": "Comfort Cooling Floatswitch"
        }
    },
    "301": {
        "id": 301,
        "name": "di_6",
        "name_orig": "DI 6",
        "desc": "Input function for digital input 6\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 12,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital inputs"
        ],
        "options": {
            "0": "None",
            "1": "Ext Start",
            "2": "A-BMS Start",
            "3": "PIR 1",
            "4": "Ventilation Floatswitch",
            "5": "Comfort Cooling Error",
            "6": "External Stop",
            "7": "Boost",
            "8": "Reset Filter Status",
            "9": "PIR 2",
            "10": "PIR 3",
            "11": "Differential Pressure Switch (NO)",
            "12": "Comfort Cooling Floatswitch"
        }
    },
    "303": {
        "id": 303,
        "name": "do_1_open_collector",
        "name_orig": "DO 1 (Open Collector)",
        "desc": "Output function for open-collector output 1\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "304": {
        "id": 304,
        "name": "do_2_open_collector",
        "name_orig": "DO 2 (Open Collector)",
        "desc": "Output function for open-collector output 2\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "305": {
        "id": 305,
        "name": "do_3_open_collector",
        "name_orig": "DO 3 (Open Collector)",
        "desc": "Output function for open-collector output 3\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "306": {
        "id": 306,
        "name": "do_4_open_collector",
        "name_orig": "DO 4 (Open Collector)",
        "desc": "Output function for open-collector output 4\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "307": {
        "id": 307,
        "name": "do_5_open_collector",
        "name_orig": "DO 5 (Open Collector)",
        "desc": "Output function for open-collector output 5\n (NA for AQC-L and AQC-P pcb rev. 5)",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "308": {
        "id": 308,
        "name": "do_k1_relay",
        "name_orig": "DO K1 (Relay)",
        "desc": "Output function for relay K1\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "309": {
        "id": 309,
        "name": "do_k2_relay",
        "name_orig": "DO K2 (Relay)",
        "desc": "Output function for relay K2\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "310": {
        "id": 310,
        "name": "do_k3_relay",
        "name_orig": "DO K3 (Relay)",
        "desc": "Output function for relay K3\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "311": {
        "id": 311,
        "name": "do_k4_relay",
        "name_orig": "DO K4 (Relay)",
        "desc": "Output function for relay K4\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "312": {
        "id": 312,
        "name": "do_k5_relay",
        "name_orig": "DO K5 (Relay)",
        "desc": "Output function for relay K5\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "313": {
        "id": 313,
        "name": "do_k6_relay",
        "name_orig": "DO K6 (Relay)",
        "desc": "Output function for relay K6\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "314": {
        "id": 314,
        "name": "do_k7_relay",
        "name_orig": "DO K7 (Relay)",
        "desc": "Output function for relay K7\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "315": {
        "id": 315,
        "name": "do_k8_relay",
        "name_orig": "DO K8 (Relay)",
        "desc": "Output function for relay K8\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "316": {
        "id": 316,
        "name": "do_k9_relay",
        "name_orig": "DO K9 (Relay)",
        "desc": "Output function for relay K9\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "317": {
        "id": 317,
        "name": "do_k10_relay",
        "name_orig": "DO K10 (Relay)",
        "desc": "Output function for relay K10\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "318": {
        "id": 318,
        "name": "do_k11_relay",
        "name_orig": "DO K11 (Relay)",
        "desc": "Output function for relay K11\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "319": {
        "id": 319,
        "name": "do_k12_relay",
        "name_orig": "DO K12 (Relay)",
        "desc": "Output function for relay K12\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "320": {
        "id": 320,
        "name": "do_led_1",
        "name_orig": "DO LED 1",
        "desc": "Output function for LED 1\n (NA for AQC-P pcb rev. 5)",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "321": {
        "id": 321,
        "name": "do_led_2",
        "name_orig": "DO LED 2",
        "desc": "Output function for LED 2\n (NA for AQC-P pcb rev. 5)",
        "type": "enum",
        "min": 0,
        "max": 24,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Digital outputs"
        ],
        "options": {
            "0": "None",
            "1": "Main Damper - Time Controlled L2",
            "2": "Main Damper - Time Controlled L3",
            "3": "Main Damper - Spring Self Return",
            "4": "Bypass Damper - Time Controlled L2",
            "5": "Bypass Damper - Time Controlled L3",
            "6": "Comfort Heater - Water",
            "7": "Comfort Heater - Electric",
            "8": "Comfort Cooling",
            "9": "PreHeater",
            "10": "Alarm",
            "11": "LED - Debug",
            "12": "LED - Filter Alarm",
            "13": "LED - Filter Warning",
            "14": "LED - Filter OK",
            "15": "LED - Indicator",
            "16": "Standby Relay",
            "17": "CC Standby Relay",
            "18": "Condensate Frost Protection",
            "19": "Flow Distribution Valve - Time Controlled L2",
            "20": "Flow Distribution Valve - Time Controlled L3",
            "21": "LED - Status",
            "22": "Window",
            "23": "CC Water - Time Controlled L2",
            "24": "CC Water - Time Controlled L3"
        }
    },
    "322": {
        "id": 322,
        "name": "ao_1",
        "name_orig": "AO 1",
        "desc": "Output function for Analog out 1",
        "type": "enum",
        "min": 0,
        "max": 35,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog outputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan",
            "4": "Bypass Damper",
            "5": "Inlet Damper",
            "6": "LoopTest Ext. Pressure",
            "7": "Test Ramp",
            "8": "Main Damper - Time Controlled L2",
            "9": "Main Damper - Time Controlled L3",
            "10": "Main Damper - Spring Self Return",
            "11": "Bypass Damper - Time Controlled L2",
            "12": "Bypass Damper - Time Controlled L3",
            "13": "Comfort Heater - Water",
            "14": "Comfort Heater - Electric",
            "15": "Comfort Cooling On/Off",
            "16": "PreHeater",
            "17": "Alarm",
            "18": "LED - Debug",
            "19": "LED - Filter Alarm",
            "20": "LED - Filter Warning",
            "21": "LED - Filter OK",
            "22": "Window",
            "23": "LED - Indicator",
            "24": "Cooling Inverter",
            "25": "Flow Distribution Valve",
            "26": "Standby Relay",
            "27": "CC Standby Relay",
            "28": "Adaptive Inlet",
            "29": "Condensate Frost Protection",
            "30": "Flow Distribution Valve - Time Controlled L2",
            "31": "Flow Distribution Valve - Time Controlled L3",
            "32": "LED - Status",
            "33": "CC Water - Time Controlled L2",
            "34": "CC Water - Time Controlled L3",
            "35": "Comfort Cooling 0-10V"
        }
    },
    "323": {
        "id": 323,
        "name": "ao_2",
        "name_orig": "AO 2",
        "desc": "Output function for Analog out 2",
        "type": "enum",
        "min": 0,
        "max": 35,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog outputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan",
            "4": "Bypass Damper",
            "5": "Inlet Damper",
            "6": "LoopTest Ext. Pressure",
            "7": "Test Ramp",
            "8": "Main Damper - Time Controlled L2",
            "9": "Main Damper - Time Controlled L3",
            "10": "Main Damper - Spring Self Return",
            "11": "Bypass Damper - Time Controlled L2",
            "12": "Bypass Damper - Time Controlled L3",
            "13": "Comfort Heater - Water",
            "14": "Comfort Heater - Electric",
            "15": "Comfort Cooling On/Off",
            "16": "PreHeater",
            "17": "Alarm",
            "18": "LED - Debug",
            "19": "LED - Filter Alarm",
            "20": "LED - Filter Warning",
            "21": "LED - Filter OK",
            "22": "Window",
            "23": "LED - Indicator",
            "24": "Cooling Inverter",
            "25": "Flow Distribution Valve",
            "26": "Standby Relay",
            "27": "CC Standby Relay",
            "28": "Adaptive Inlet",
            "29": "Condensate Frost Protection",
            "30": "Flow Distribution Valve - Time Controlled L2",
            "31": "Flow Distribution Valve - Time Controlled L3",
            "32": "LED - Status",
            "33": "CC Water - Time Controlled L2",
            "34": "CC Water - Time Controlled L3",
            "35": "Comfort Cooling 0-10V"
        }
    },
    "324": {
        "id": 324,
        "name": "ao_3",
        "name_orig": "AO 3",
        "desc": "Output function for Analog out 3",
        "type": "enum",
        "min": 0,
        "max": 35,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog outputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan",
            "4": "Bypass Damper",
            "5": "Inlet Damper",
            "6": "LoopTest Ext. Pressure",
            "7": "Test Ramp",
            "8": "Main Damper - Time Controlled L2",
            "9": "Main Damper - Time Controlled L3",
            "10": "Main Damper - Spring Self Return",
            "11": "Bypass Damper - Time Controlled L2",
            "12": "Bypass Damper - Time Controlled L3",
            "13": "Comfort Heater - Water",
            "14": "Comfort Heater - Electric",
            "15": "Comfort Cooling On/Off",
            "16": "PreHeater",
            "17": "Alarm",
            "18": "LED - Debug",
            "19": "LED - Filter Alarm",
            "20": "LED - Filter Warning",
            "21": "LED - Filter OK",
            "22": "Window",
            "23": "LED - Indicator",
            "24": "Cooling Inverter",
            "25": "Flow Distribution Valve",
            "26": "Standby Relay",
            "27": "CC Standby Relay",
            "28": "Adaptive Inlet",
            "29": "Condensate Frost Protection",
            "30": "Flow Distribution Valve - Time Controlled L2",
            "31": "Flow Distribution Valve - Time Controlled L3",
            "32": "LED - Status",
            "33": "CC Water - Time Controlled L2",
            "34": "CC Water - Time Controlled L3",
            "35": "Comfort Cooling 0-10V"
        }
    },
    "325": {
        "id": 325,
        "name": "ao_4",
        "name_orig": "AO 4",
        "desc": "Output function for Analog out 4\n (NA for AQC-L pcb rev. < 8 )",
        "type": "enum",
        "min": 0,
        "max": 35,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog outputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan",
            "4": "Bypass Damper",
            "5": "Inlet Damper",
            "6": "LoopTest Ext. Pressure",
            "7": "Test Ramp",
            "8": "Main Damper - Time Controlled L2",
            "9": "Main Damper - Time Controlled L3",
            "10": "Main Damper - Spring Self Return",
            "11": "Bypass Damper - Time Controlled L2",
            "12": "Bypass Damper - Time Controlled L3",
            "13": "Comfort Heater - Water",
            "14": "Comfort Heater - Electric",
            "15": "Comfort Cooling On/Off",
            "16": "PreHeater",
            "17": "Alarm",
            "18": "LED - Debug",
            "19": "LED - Filter Alarm",
            "20": "LED - Filter Warning",
            "21": "LED - Filter OK",
            "22": "Window",
            "23": "LED - Indicator",
            "24": "Cooling Inverter",
            "25": "Flow Distribution Valve",
            "26": "Standby Relay",
            "27": "CC Standby Relay",
            "28": "Adaptive Inlet",
            "29": "Condensate Frost Protection",
            "30": "Flow Distribution Valve - Time Controlled L2",
            "31": "Flow Distribution Valve - Time Controlled L3",
            "32": "LED - Status",
            "33": "CC Water - Time Controlled L2",
            "34": "CC Water - Time Controlled L3",
            "35": "Comfort Cooling 0-10V"
        }
    },
    "326": {
        "id": 326,
        "name": "ao_5",
        "name_orig": "AO 5",
        "desc": "Output function for Analog out 5\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 35,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog outputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan",
            "4": "Bypass Damper",
            "5": "Inlet Damper",
            "6": "LoopTest Ext. Pressure",
            "7": "Test Ramp",
            "8": "Main Damper - Time Controlled L2",
            "9": "Main Damper - Time Controlled L3",
            "10": "Main Damper - Spring Self Return",
            "11": "Bypass Damper - Time Controlled L2",
            "12": "Bypass Damper - Time Controlled L3",
            "13": "Comfort Heater - Water",
            "14": "Comfort Heater - Electric",
            "15": "Comfort Cooling On/Off",
            "16": "PreHeater",
            "17": "Alarm",
            "18": "LED - Debug",
            "19": "LED - Filter Alarm",
            "20": "LED - Filter Warning",
            "21": "LED - Filter OK",
            "22": "Window",
            "23": "LED - Indicator",
            "24": "Cooling Inverter",
            "25": "Flow Distribution Valve",
            "26": "Standby Relay",
            "27": "CC Standby Relay",
            "28": "Adaptive Inlet",
            "29": "Condensate Frost Protection",
            "30": "Flow Distribution Valve - Time Controlled L2",
            "31": "Flow Distribution Valve - Time Controlled L3",
            "32": "LED - Status",
            "33": "CC Water - Time Controlled L2",
            "34": "CC Water - Time Controlled L3",
            "35": "Comfort Cooling 0-10V"
        }
    },
    "327": {
        "id": 327,
        "name": "ao_6",
        "name_orig": "AO 6",
        "desc": "Output function for Analog out 6\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 35,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog outputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan",
            "4": "Bypass Damper",
            "5": "Inlet Damper",
            "6": "LoopTest Ext. Pressure",
            "7": "Test Ramp",
            "8": "Main Damper - Time Controlled L2",
            "9": "Main Damper - Time Controlled L3",
            "10": "Main Damper - Spring Self Return",
            "11": "Bypass Damper - Time Controlled L2",
            "12": "Bypass Damper - Time Controlled L3",
            "13": "Comfort Heater - Water",
            "14": "Comfort Heater - Electric",
            "15": "Comfort Cooling On/Off",
            "16": "PreHeater",
            "17": "Alarm",
            "18": "LED - Debug",
            "19": "LED - Filter Alarm",
            "20": "LED - Filter Warning",
            "21": "LED - Filter OK",
            "22": "Window",
            "23": "LED - Indicator",
            "24": "Cooling Inverter",
            "25": "Flow Distribution Valve",
            "26": "Standby Relay",
            "27": "CC Standby Relay",
            "28": "Adaptive Inlet",
            "29": "Condensate Frost Protection",
            "30": "Flow Distribution Valve - Time Controlled L2",
            "31": "Flow Distribution Valve - Time Controlled L3",
            "32": "LED - Status",
            "33": "CC Water - Time Controlled L2",
            "34": "CC Water - Time Controlled L3",
            "35": "Comfort Cooling 0-10V"
        }
    },
    "328": {
        "id": 328,
        "name": "ao_7",
        "name_orig": "AO 7",
        "desc": "Output function for Analog out 7\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 35,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Analog outputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan",
            "4": "Bypass Damper",
            "5": "Inlet Damper",
            "6": "LoopTest Ext. Pressure",
            "7": "Test Ramp",
            "8": "Main Damper - Time Controlled L2",
            "9": "Main Damper - Time Controlled L3",
            "10": "Main Damper - Spring Self Return",
            "11": "Bypass Damper - Time Controlled L2",
            "12": "Bypass Damper - Time Controlled L3",
            "13": "Comfort Heater - Water",
            "14": "Comfort Heater - Electric",
            "15": "Comfort Cooling On/Off",
            "16": "PreHeater",
            "17": "Alarm",
            "18": "LED - Debug",
            "19": "LED - Filter Alarm",
            "20": "LED - Filter Warning",
            "21": "LED - Filter OK",
            "22": "Window",
            "23": "LED - Indicator",
            "24": "Cooling Inverter",
            "25": "Flow Distribution Valve",
            "26": "Standby Relay",
            "27": "CC Standby Relay",
            "28": "Adaptive Inlet",
            "29": "Condensate Frost Protection",
            "30": "Flow Distribution Valve - Time Controlled L2",
            "31": "Flow Distribution Valve - Time Controlled L3",
            "32": "LED - Status",
            "33": "CC Water - Time Controlled L2",
            "34": "CC Water - Time Controlled L3",
            "35": "Comfort Cooling 0-10V"
        }
    },
    "329": {
        "id": 329,
        "name": "tacho_input_1",
        "name_orig": "Tacho Input 1",
        "desc": "Function of Tacho input 1",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Tacho inputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan"
        }
    },
    "330": {
        "id": 330,
        "name": "tacho_input_2",
        "name_orig": "Tacho Input 2",
        "desc": "Function of Tacho input 2",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Tacho inputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan"
        }
    },
    "331": {
        "id": 331,
        "name": "tacho_input_3",
        "name_orig": "Tacho Input 3",
        "desc": "Function of Tacho input 3\n (NA for AQC-L)",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "I/O Config",
            "Tacho inputs"
        ],
        "options": {
            "0": "None",
            "1": "Supply Fan",
            "2": "Extraction Fan",
            "3": "Recirculating Fan"
        }
    },
    "333": {
        "id": 333,
        "name": "panel_flow_function",
        "name_orig": "Panel Flow Function",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "General"
        ],
        "options": {
            "0": "Direct",
            "1": "None",
            "2": "D-BMS"
        }
    },
    "334": {
        "id": 334,
        "name": "panel_off_allowed",
        "name_orig": "Panel OFF Allowed",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 21,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "General"
        ],
        "options": {
            "0": "All",
            "1": "None",
            "2": "CID 160",
            "3": "CID 161",
            "4": "CID 162",
            "5": "CID 163",
            "6": "CID 164",
            "7": "CID 165",
            "8": "CID 166",
            "9": "CID 167",
            "10": "CID 168",
            "11": "CID 169",
            "12": "CID 170",
            "13": "CID 171",
            "14": "CID 172",
            "15": "CID 173",
            "16": "CID 174",
            "17": "CID 175",
            "18": "CID 176",
            "19": "CID 177",
            "20": "CID 178",
            "21": "CID 179"
        }
    },
    "335": {
        "id": 335,
        "name": "cc_mode",
        "name_orig": "CC Mode",
        "desc": "Comfort Cool Mode",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Settings"
        ],
        "options": {
            "0": "Off",
            "1": "Internal",
            "2": "External",
            "3": "Inverter",
            "4": "Water"
        }
    },
    "336": {
        "id": 336,
        "name": "cc_defrost_exit_temp",
        "name_orig": "CC Defrost Exit Temp",
        "desc": "Temperature that causes comfort cool to exit defrost mode",
        "type": "decimal",
        "min": -20,
        "max": 50,
        "res": 1,
        "default": 5,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "337": {
        "id": 337,
        "name": "cc_min_ot",
        "name_orig": "CC Min OT",
        "desc": "Comfort Cool minimum outside temperature",
        "type": "decimal",
        "min": -20,
        "max": 50,
        "res": 1,
        "default": 16,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "338": {
        "id": 338,
        "name": "cc_cdt_alarm_temp",
        "name_orig": "CC CDT Alarm Temp",
        "desc": "Comfort Cool Condenser alarm temperature",
        "type": "decimal",
        "min": -20,
        "max": 100,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "339": {
        "id": 339,
        "name": "cc_cdt_warning_temp",
        "name_orig": "CC CDT Warning Temp",
        "desc": "Comfort Cool Condenser warning temperature",
        "type": "decimal",
        "min": -20,
        "max": 100,
        "res": 1,
        "default": 58,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "340": {
        "id": 340,
        "name": "cc_evt_min___defrost_temp",
        "name_orig": "CC EVT min - Defrost Temp",
        "desc": "Comfort Cool defrost activation temperature",
        "type": "decimal",
        "min": -20,
        "max": 50,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Evaporator"
        ]
    },
    "341": {
        "id": 341,
        "name": "cc_defrost_warning_time",
        "name_orig": "CC Defrost Warning Time",
        "desc": "Comfort Cool defrost time before setting warning",
        "type": "int",
        "min": 0,
        "max": 480,
        "res": 1,
        "default": 120,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "342": {
        "id": 342,
        "name": "cc_compressor_wait_time",
        "name_orig": "CC Compressor Wait Time",
        "desc": "Comfort Cool time to wait between stop and start of compressor",
        "type": "int",
        "min": 0,
        "max": 30,
        "res": 1,
        "default": 3,
        "showInTechnical": true,
        "unit": "min",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "343": {
        "id": 343,
        "name": "cc_condensation_time",
        "name_orig": "CC Condensation Time",
        "desc": "Comfort Cool time delay before setting condensation warning",
        "type": "int",
        "min": 0,
        "max": 600,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "344": {
        "id": 344,
        "name": "hc_barometric_pressure_outside",
        "name_orig": "HC Barometric Pressure Outside",
        "desc": "Humidity Control barometric pressure outside",
        "type": "decimal",
        "min": 30,
        "max": 110,
        "res": 0.1,
        "default": 101.3,
        "showInTechnical": true,
        "unit": "kPa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "345": {
        "id": 345,
        "name": "hc_barometric_pressure_room",
        "name_orig": "HC Barometric Pressure Room",
        "desc": "Humidity Control barometric pressure room",
        "type": "decimal",
        "min": 30,
        "max": 110,
        "res": 0.1,
        "default": 101.3,
        "showInTechnical": true,
        "unit": "kPa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "346": {
        "id": 346,
        "name": "hc_d_ah_min_a",
        "name_orig": "HC d_AH_min_A",
        "desc": "Delta absolute humidity minimum A-coefficient",
        "type": "decimal",
        "min": "-9.9990000000000006",
        "max": "9.9990000000000006",
        "res": 0.001,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "347": {
        "id": 347,
        "name": "hc_d_ah_min_b",
        "name_orig": "HC d_AH_min_B",
        "desc": "Delta absolute humidity minimum B-coefficient",
        "type": "decimal",
        "min": "-9.9990000000000006",
        "max": "9.9990000000000006",
        "res": 0.001,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "348": {
        "id": 348,
        "name": "hc_d_ah_min_c",
        "name_orig": "HC d_AH_min_C",
        "desc": "Delta absolute humidity minimum C-coefficient",
        "type": "decimal",
        "min": -99.99,
        "max": 99.99,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "349": {
        "id": 349,
        "name": "hc_d_ah_max_a",
        "name_orig": "HC d_AH_max_A",
        "desc": "Delta absolute humidity maximum A-coefficient",
        "type": "decimal",
        "min": "-9.9990000000000006",
        "max": "9.9990000000000006",
        "res": 0.001,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "350": {
        "id": 350,
        "name": "hc_d_ah_max_b",
        "name_orig": "HC d_AH_max_B",
        "desc": "Delta absolute humidity maximum B-coefficient",
        "type": "decimal",
        "min": "-9.9990000000000006",
        "max": "9.9990000000000006",
        "res": 0.001,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "351": {
        "id": 351,
        "name": "hc_d_ah_max_c",
        "name_orig": "HC d_AH_max_C",
        "desc": "Delta absolute humidity maximum C-coefficient",
        "type": "decimal",
        "min": -99.99,
        "max": 99.99,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Humidity"
        ]
    },
    "352": {
        "id": 352,
        "name": "panel_start_timeout",
        "name_orig": "Panel Start Timeout",
        "desc": "Time from panel start request to automatic return to standby, 0 = no timeout, remains started until user stop.",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 4,
        "showInTechnical": true,
        "unit": "hour",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Airlinq Panel",
            "General"
        ]
    },
    "353": {
        "id": 353,
        "name": "panel_off_timeout",
        "name_orig": "Panel Off Timeout",
        "desc": "Time from panel standby request, to shift from off to standby mode",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "hour",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Airlinq Panel",
            "General"
        ]
    },
    "354": {
        "id": 354,
        "name": "off_with_timeout_request",
        "name_orig": "Off with timeout (request)",
        "desc": "Sets system in off mode, returns to standby after timeout",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "355": {
        "id": 355,
        "name": "end_manual_override_request",
        "name_orig": "End Manual Override (request)",
        "desc": "Ends manual override",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "356": {
        "id": 356,
        "name": "airmaster_serial_number_msb",
        "name_orig": "Airmaster Serial number MSB",
        "desc": "High 16bit of airmaster serial number",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "357": {
        "id": 357,
        "name": "airmaster_serial_number_lsb",
        "name_orig": "Airmaster Serial number LSB",
        "desc": "Low 16bit of airmaster serial number",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "358": {
        "id": 358,
        "name": "hm_supply_air",
        "name_orig": "HM Supply Air",
        "desc": "Source of supply air in Holiday mode",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 0,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Holiday Mode"
        ],
        "options": {
            "0": "Fan",
            "1": "Window"
        }
    },
    "359": {
        "id": 359,
        "name": "allow_boost",
        "name_orig": "Allow Boost",
        "desc": "Enable Boost function",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 0,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Boost"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "360": {
        "id": 360,
        "name": "boost_max_volt_in",
        "name_orig": "Boost Max Volt In",
        "desc": "max supply fan voltage when Boost is requested",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Boost"
        ]
    },
    "361": {
        "id": 361,
        "name": "boost_max_volt_out",
        "name_orig": "Boost Max Volt Out",
        "desc": "max exhaust fan voltage when Boost is requested",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Boost"
        ]
    },
    "362": {
        "id": 362,
        "name": "service_address",
        "name_orig": "Service Address",
        "desc": "sets the info shown on the AQD-P displays",
        "type": "enum",
        "min": 0,
        "max": 13,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ],
        "options": {
            "0": "None",
            "1": "Airmaster AS",
            "2": "Airmaster AB",
            "3": "Airmaster Ltd.",
            "4": "Airmaster AS",
            "5": "Helios Ventilateurs",
            "6": "Wesco AG",
            "7": "Airmaster B.V.",
            "8": "SAV Systems Ltd.",
            "9": "Airflow Lufttechnik GmbH",
            "10": "Johann WERNIG KG",
            "11": "Sandviken Center -ventilasjonsservice AS",
            "12": "Airmaster AS Norge",
            "13": "AT-Air OY"
        }
    },
    "363": {
        "id": 363,
        "name": "vph_options",
        "name_orig": "VPH Options",
        "desc": "Virtual Pre Heater Options",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Heating Surfaces"
        ],
        "options": {
            "0": "N/A",
            "1": "Electric",
            "2": "Water",
            "3": "Electric or Water"
        }
    },
    "364": {
        "id": 364,
        "name": "last_filter_change_sec_utc_msb",
        "name_orig": "Last Filter Change sec utc MSB",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "365": {
        "id": 365,
        "name": "last_filter_change_sec_utc_lsb",
        "name_orig": "Last Filter Change sec utc LSB",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "366": {
        "id": 366,
        "name": "co2_low",
        "name_orig": "CO2 Low",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 50,
        "default": 900,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "367": {
        "id": 367,
        "name": "co2_mid",
        "name_orig": "CO2 Mid",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 50,
        "default": 1200,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "368": {
        "id": 368,
        "name": "start_priority_6",
        "name_orig": "Start Priority 6",
        "desc": "Start priority 6",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "369": {
        "id": 369,
        "name": "start_priority_7",
        "name_orig": "Start Priority 7",
        "desc": "Start priority 7",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "370": {
        "id": 370,
        "name": "simulate_mode",
        "name_orig": "Simulate Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "371": {
        "id": 371,
        "name": "simulated_it",
        "name_orig": "Simulated IT",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 50,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "372": {
        "id": 372,
        "name": "simulated_rt",
        "name_orig": "Simulated RT",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 50,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "373": {
        "id": 373,
        "name": "simulated_gpt",
        "name_orig": "Simulated GPT",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 50,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "374": {
        "id": 374,
        "name": "simulated_ot",
        "name_orig": "Simulated OT",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 50,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "375": {
        "id": 375,
        "name": "simulated_evt",
        "name_orig": "Simulated EVT",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 100,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "376": {
        "id": 376,
        "name": "simulated_cdt",
        "name_orig": "Simulated CdT",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 100,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "377": {
        "id": 377,
        "name": "simulated_otv",
        "name_orig": "Simulated OTV",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 50,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "378": {
        "id": 378,
        "name": "simulated_etv",
        "name_orig": "Simulated ETV",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 50,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "379": {
        "id": 379,
        "name": "simulated_am_float_switch",
        "name_orig": "Simulated AM Float Switch",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ],
        "options": {
            "0": "Inactive",
            "1": "Active"
        }
    },
    "380": {
        "id": 380,
        "name": "simulated_cc_float_switch",
        "name_orig": "Simulated CC Float Switch",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ],
        "options": {
            "0": "Inactive",
            "1": "Active"
        }
    },
    "381": {
        "id": 381,
        "name": "simulated_external_start_input",
        "name_orig": "Simulated External Start Input",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ],
        "options": {
            "0": "Inactive",
            "1": "Active"
        }
    },
    "382": {
        "id": 382,
        "name": "simulated_abms_start",
        "name_orig": "Simulated ABMS Start",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ],
        "options": {
            "0": "Inactive",
            "1": "Active"
        }
    },
    "383": {
        "id": 383,
        "name": "simulated_pir_input",
        "name_orig": "Simulated Pir Input",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ],
        "options": {
            "0": "Inactive",
            "1": "Active"
        }
    },
    "384": {
        "id": 384,
        "name": "simulated_external_stop_input",
        "name_orig": "Simulated External Stop Input",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ],
        "options": {
            "0": "Inactive",
            "1": "Active"
        }
    },
    "385": {
        "id": 385,
        "name": "simulated_abms_temperature_setpoint",
        "name_orig": "Simulated ABMS Temperature Setpoint",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "386": {
        "id": 386,
        "name": "simulated_abms_flow_setpoint",
        "name_orig": "Simulated ABMS Flow Setpoint",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "387": {
        "id": 387,
        "name": "simulated_internal_co2",
        "name_orig": "Simulated Internal CO2",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 800,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "388": {
        "id": 388,
        "name": "simulated_analog_co2_1",
        "name_orig": "Simulated Analog CO2 1",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "389": {
        "id": 389,
        "name": "simulated_analog_co2_2",
        "name_orig": "Simulated Analog CO2 2",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "390": {
        "id": 390,
        "name": "simulated_analog_co2_3",
        "name_orig": "Simulated Analog CO2 3",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "391": {
        "id": 391,
        "name": "simulated_0_10v_flow",
        "name_orig": "Simulated 0-10V Flow",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "392": {
        "id": 392,
        "name": "simulated_supply_fan",
        "name_orig": "Simulated Supply Fan",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 1000,
        "showInTechnical": true,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "393": {
        "id": 393,
        "name": "simulated_extraction_fan",
        "name_orig": "Simulated Extraction Fan",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 1000,
        "showInTechnical": true,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "394": {
        "id": 394,
        "name": "simulated_recirculating_fan",
        "name_orig": "Simulated Recirculating Fan",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "395": {
        "id": 395,
        "name": "simulated_supply_airflow",
        "name_orig": "Simulated Supply Airflow",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "397": {
        "id": 397,
        "name": "simulated_extraction_airflow",
        "name_orig": "Simulated Extraction Airflow",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "398": {
        "id": 398,
        "name": "simulated_relative_humidity_supply",
        "name_orig": "Simulated Relative Humidity Supply",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "399": {
        "id": 399,
        "name": "simulated_relative_humidity_extraction",
        "name_orig": "Simulated Relative Humidity Extraction",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "400": {
        "id": 400,
        "name": "d_bms_type",
        "name_orig": "D-BMS Type",
        "desc": "Digital BMS Type",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "None",
            "1": "Modbus",
            "2": "KNX",
            "3": "BACnet MS/TP",
            "4": "BACnet IP",
            "5": "Lon",
            "6": "Ethernet"
        }
    },
    "402": {
        "id": 402,
        "name": "modbus_address",
        "name_orig": "Modbus Address",
        "desc": "ModBus Slave Address",
        "type": "int",
        "min": 1,
        "max": 247,
        "res": 1,
        "default": 3,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ]
    },
    "403": {
        "id": 403,
        "name": "modbus_baud_rate",
        "name_orig": "Modbus Baud Rate",
        "desc": "ModBus BaudRate",
        "type": "enum",
        "min": 0,
        "max": 5,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "9600",
            "1": "19200",
            "2": "38400",
            "3": "57600",
            "4": "115200",
            "5": "230400"
        }
    },
    "404": {
        "id": 404,
        "name": "modbus_parity",
        "name_orig": "Modbus Parity",
        "desc": "ModBus Parity",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "None (2 Stop bits)",
            "1": "Odd (1 Stop bit)",
            "2": "Even (1 Stop bit)"
        }
    },
    "405": {
        "id": 405,
        "name": "bacnet_mstp_address",
        "name_orig": "Bacnet MS/TP Address",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ]
    },
    "406": {
        "id": 406,
        "name": "bacnet_mstp_baud_rate",
        "name_orig": "Bacnet MS/TP Baud Rate",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "9600",
            "1": "19200",
            "2": "38400",
            "3": "76800"
        }
    },
    "407": {
        "id": 407,
        "name": "simulated_evt_in",
        "name_orig": "Simulated EVT_IN",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 100,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "408": {
        "id": 408,
        "name": "simulated_evt_out",
        "name_orig": "Simulated EVT_OUT",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 100,
        "res": 0.1,
        "default": 20,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "409": {
        "id": 409,
        "name": "simulated_hotgas_temperature",
        "name_orig": "Simulated Hotgas Temperature",
        "desc": "",
        "type": "decimal",
        "min": -50,
        "max": 100,
        "res": 0.1,
        "default": 50,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "412": {
        "id": 412,
        "name": "co2_control_mode",
        "name_orig": "CO2 Control Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ],
        "options": {
            "0": "No",
            "1": "Level",
            "2": "RateOfChange",
            "3": "Combined"
        }
    },
    "413": {
        "id": 413,
        "name": "a_bms_defined_temperature",
        "name_orig": "A-BMS Defined Temperature",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Analog"
        ],
        "options": {
            "0": "Disabled",
            "1": "A-BMS",
            "2": "General"
        }
    },
    "414": {
        "id": 414,
        "name": "allow_start_by_pir",
        "name_orig": "Allow Start by PIR",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "415": {
        "id": 415,
        "name": "allow_start_by_co2",
        "name_orig": "Allow Start by CO2",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "416": {
        "id": 416,
        "name": "allow_start_by_timer",
        "name_orig": "Allow Start by Timer",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "417": {
        "id": 417,
        "name": "allow_start_by_panel",
        "name_orig": "Allow Start by Panel",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "420": {
        "id": 420,
        "name": "normal_powerup_count",
        "name_orig": "Normal Powerup Count",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "Debug"
        ]
    },
    "421": {
        "id": 421,
        "name": "watchdog_powerup_count",
        "name_orig": "Watchdog Powerup Count",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "Debug"
        ]
    },
    "422": {
        "id": 422,
        "name": "hardfault_count",
        "name_orig": "Hardfault Count",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "Debug"
        ]
    },
    "423": {
        "id": 423,
        "name": "sf_pid_kp",
        "name_orig": "SF PID KP",
        "desc": "Supply Fan Voltage PID, Proportional Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "424": {
        "id": 424,
        "name": "sf_pid_ki",
        "name_orig": "SF PID KI",
        "desc": "Supply Fan Voltage PID, Integral Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "425": {
        "id": 425,
        "name": "sf_pid_kd",
        "name_orig": "SF PID KD",
        "desc": "Supply Fan Voltage PID, Differential Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "426": {
        "id": 426,
        "name": "sf_pid_ka",
        "name_orig": "SF PID KA",
        "desc": "Supply Fan Voltage PID, Anti Windup Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "427": {
        "id": 427,
        "name": "ef_pid_kp",
        "name_orig": "EF PID KP",
        "desc": "Extraction Fan Voltage PID, Proportional Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "428": {
        "id": 428,
        "name": "ef_pid_ki",
        "name_orig": "EF PID KI",
        "desc": "Extraction Fan Voltage PID, Integral Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "429": {
        "id": 429,
        "name": "ef_pid_kd",
        "name_orig": "EF PID KD",
        "desc": "Extraction Fan Voltage PID, Differential Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "430": {
        "id": 430,
        "name": "ef_pid_ka",
        "name_orig": "EF PID KA",
        "desc": "Extraction Fan Voltage PID, Anti Windup Gain",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "431": {
        "id": 431,
        "name": "fan_voltage_control_pid_sample_t",
        "name_orig": "Fan Voltage Control PID Sample T",
        "desc": "Fan Voltage Control PID Sample Time seconds",
        "type": "decimal",
        "min": 0.01,
        "max": 10,
        "res": 0.01,
        "default": 0.5,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ]
    },
    "432": {
        "id": 432,
        "name": "filter_max_life_time_alarm",
        "name_orig": "Filter Max Life Time, Alarm",
        "desc": "Alarm is activated when the filter is \"Filter Max Life Time, Alarm\" old. (0 months disables the Filter Max Life Time Alarm)",
        "type": "int",
        "min": 0,
        "max": 48,
        "res": 1,
        "default": 14,
        "showInTechnical": true,
        "unit": "month(s)",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ]
    },
    "433": {
        "id": 433,
        "name": "air_handling_unit_pulseskwh",
        "name_orig": "Air Handling Unit, Pulses/kWh",
        "desc": "EM 10: 1-phase = 1000 pulses/kWh \nEM 23: 3-phase = 100 pulses/kWh",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 1000,
        "showInTechnical": true,
        "unit": "Pulses/kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Power Meter"
        ]
    },
    "434": {
        "id": 434,
        "name": "aqc_p_total_power_consumption_watt_hours_msb",
        "name_orig": "AQC-P Total Power Consumption watt hours MSB",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "435": {
        "id": 435,
        "name": "comfort_cooling_pulseskwh",
        "name_orig": "Comfort Cooling, Pulses/kWh",
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 1000,
        "showInTechnical": true,
        "unit": "Pulses/kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Power Meter"
        ]
    },
    "436": {
        "id": 436,
        "name": "aqc_c_total_power_consumption_watt_hours_msb",
        "name_orig": "AQC-C Total Power Consumption watt hours MSB",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "437": {
        "id": 437,
        "name": "rtc_prescaler",
        "name_orig": "RTC Prescaler",
        "desc": "RTC-Prescaler Value from Production-Test",
        "type": "int",
        "min": 500,
        "max": 1000,
        "res": 1,
        "default": 764,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ]
    },
    "438": {
        "id": 438,
        "name": "timezone___offset_from_utc_in_minutes",
        "name_orig": "TimeZone - offset from UTC in minutes",
        "desc": "",
        "type": "int",
        "min": -1440,
        "max": 1440,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Settings"
        ]
    },
    "439": {
        "id": 439,
        "name": "automatic_daylight_saving_time",
        "name_orig": "Automatic Daylight Saving Time",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Settings"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "440": {
        "id": 440,
        "name": "timer_1_days",
        "name_orig": "Timer 1 days",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 31,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 1"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "441": {
        "id": 441,
        "name": "timer_1_flags",
        "name_orig": "Timer 1 flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 17,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 1"
        ],
        "options": [
            {
                "name": "standard_ventilation",
                "name_orig": "Standard Ventilation",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "442": {
        "id": 442,
        "name": "timer_1_start_timer_minutes_since_midnight",
        "name_orig": "Timer 1 start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 420,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 1"
        ]
    },
    "443": {
        "id": 443,
        "name": "timer_1_duration_minutes",
        "name_orig": "Timer 1 duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 600,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 1"
        ]
    },
    "444": {
        "id": 444,
        "name": "timer_1_flow_percent",
        "name_orig": "Timer 1 flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 1"
        ]
    },
    "445": {
        "id": 445,
        "name": "timer_1_temperature_c",
        "name_orig": "Timer 1 temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 1"
        ]
    },
    "446": {
        "id": 446,
        "name": "timer_2_days",
        "name_orig": "Timer 2 days",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 31,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 2"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "447": {
        "id": 447,
        "name": "timer_2_flags",
        "name_orig": "Timer 2 flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 2"
        ],
        "options": [
            {
                "name": "standard_ventilation",
                "name_orig": "Standard Ventilation",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "448": {
        "id": 448,
        "name": "timer_2_start_timer_minutes_since_midnight",
        "name_orig": "Timer 2 start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 420,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 2"
        ]
    },
    "449": {
        "id": 449,
        "name": "timer_2_duration_minutes",
        "name_orig": "Timer 2 duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 600,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 2"
        ]
    },
    "450": {
        "id": 450,
        "name": "timer_2_flow_percent",
        "name_orig": "Timer 2 flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 40,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 2"
        ]
    },
    "451": {
        "id": 451,
        "name": "timer_2_temperature_c",
        "name_orig": "Timer 2 temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 2"
        ]
    },
    "452": {
        "id": 452,
        "name": "timer_3_days",
        "name_orig": "Timer 3 days",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 96,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 3"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "453": {
        "id": 453,
        "name": "timer_3_flags",
        "name_orig": "Timer 3 flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 3"
        ],
        "options": [
            {
                "name": "standard_ventilation",
                "name_orig": "Standard Ventilation",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "454": {
        "id": 454,
        "name": "timer_3_start_timer_minutes_since_midnight",
        "name_orig": "Timer 3 start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 420,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 3"
        ]
    },
    "455": {
        "id": 455,
        "name": "timer_3_duration_minutes",
        "name_orig": "Timer 3 duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 600,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 3"
        ]
    },
    "456": {
        "id": 456,
        "name": "timer_3_flow_percent",
        "name_orig": "Timer 3 flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 3"
        ]
    },
    "457": {
        "id": 457,
        "name": "timer_3_temperature_c",
        "name_orig": "Timer 3 temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 3"
        ]
    },
    "458": {
        "id": 458,
        "name": "timer_4_days",
        "name_orig": "Timer 4 days",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 96,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 4"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "459": {
        "id": 459,
        "name": "timer_4_flags",
        "name_orig": "Timer 4 flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 4"
        ],
        "options": [
            {
                "name": "standard_ventilation",
                "name_orig": "Standard Ventilation",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "460": {
        "id": 460,
        "name": "timer_4_start_timer_minutes_since_midnight",
        "name_orig": "Timer 4 start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 420,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 4"
        ]
    },
    "461": {
        "id": 461,
        "name": "timer_4_duration_minutes",
        "name_orig": "Timer 4 duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 600,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 4"
        ]
    },
    "462": {
        "id": 462,
        "name": "timer_4_flow_percent",
        "name_orig": "Timer 4 flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 40,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 4"
        ]
    },
    "463": {
        "id": 463,
        "name": "timer_4_temperature_c",
        "name_orig": "Timer 4 temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 4"
        ]
    },
    "464": {
        "id": 464,
        "name": "timer_5_days",
        "name_orig": "Timer 5 days",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 127,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 5"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "465": {
        "id": 465,
        "name": "timer_5_flags",
        "name_orig": "Timer 5 flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 5"
        ],
        "options": [
            {
                "name": "standard_ventilation",
                "name_orig": "Standard Ventilation",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "466": {
        "id": 466,
        "name": "timer_5_start_timer_minutes_since_midnight",
        "name_orig": "Timer 5 start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 420,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 5"
        ]
    },
    "467": {
        "id": 467,
        "name": "timer_5_duration_minutes",
        "name_orig": "Timer 5 duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 600,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 5"
        ]
    },
    "468": {
        "id": 468,
        "name": "timer_5_flow_percent",
        "name_orig": "Timer 5 flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 5"
        ]
    },
    "469": {
        "id": 469,
        "name": "timer_5_temperature_c",
        "name_orig": "Timer 5 temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 5"
        ]
    },
    "470": {
        "id": 470,
        "name": "timer_6_days",
        "name_orig": "Timer 6 days",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 127,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 6"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "471": {
        "id": 471,
        "name": "timer_6_flags",
        "name_orig": "Timer 6 flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 6"
        ],
        "options": [
            {
                "name": "standard_ventilation",
                "name_orig": "Standard Ventilation",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "472": {
        "id": 472,
        "name": "timer_6_start_timer_minutes_since_midnight",
        "name_orig": "Timer 6 start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 420,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 6"
        ]
    },
    "473": {
        "id": 473,
        "name": "timer_6_duration_minutes",
        "name_orig": "Timer 6 duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 600,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 6"
        ]
    },
    "474": {
        "id": 474,
        "name": "timer_6_flow_percent",
        "name_orig": "Timer 6 flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 40,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 6"
        ]
    },
    "475": {
        "id": 475,
        "name": "timer_6_temperature_c",
        "name_orig": "Timer 6 temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 6"
        ]
    },
    "476": {
        "id": 476,
        "name": "timer_7_days",
        "name_orig": "Timer 7 days",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 127,
        "res": 1,
        "default": 127,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 7"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "477": {
        "id": 477,
        "name": "timer_7_flags",
        "name_orig": "Timer 7 flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 7"
        ],
        "options": [
            {
                "name": "standard_ventilation",
                "name_orig": "Standard Ventilation",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "478": {
        "id": 478,
        "name": "timer_7_start_timer_minutes_since_midnight",
        "name_orig": "Timer 7 start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 7"
        ]
    },
    "479": {
        "id": 479,
        "name": "timer_7_duration_minutes",
        "name_orig": "Timer 7 duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 1439,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 7"
        ]
    },
    "480": {
        "id": 480,
        "name": "timer_7_flow_percent",
        "name_orig": "Timer 7 flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 40,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 7"
        ]
    },
    "481": {
        "id": 481,
        "name": "timer_7_temperature_c",
        "name_orig": "Timer 7 temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 19,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Schedule 7"
        ]
    },
    "482": {
        "id": 482,
        "name": "timer_8_night_cool_days",
        "name_orig": "Timer 8 (night cool) days",
        "desc": "",
        "type": "bitmask",
        "min": 127,
        "max": 127,
        "res": 1,
        "default": 127,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Night Cooling"
        ],
        "options": [
            {
                "name": "monday",
                "name_orig": "Monday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "tuesday",
                "name_orig": "Tuesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "wednesday",
                "name_orig": "wednesday",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "thursday",
                "name_orig": "Thursday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "friday",
                "name_orig": "Friday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "saturday",
                "name_orig": "Saturday",
                "pos": "Activ",
                "neg": "Inactive"
            },
            {
                "name": "sunday",
                "name_orig": "Sunday",
                "pos": "Activ",
                "neg": "Inactive"
            }
        ]
    },
    "483": {
        "id": 483,
        "name": "timer_8_night_cool_flags",
        "name_orig": "Timer 8 (night cool) flags",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 255,
        "res": 1,
        "default": 18,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Night Cooling"
        ],
        "options": [
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "night_cool",
                "name_orig": "Night Cool",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "egg_timer",
                "name_orig": "Egg Timer",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "enabled",
                "name_orig": "Enabled",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "unused",
                "name_orig": "unused",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "internal_flag",
                "name_orig": "internal flag",
                "pos": "Active",
                "neg": "Inactive"
            }
        ]
    },
    "484": {
        "id": 484,
        "name": "timer_8_night_cool_start_timer_minutes_since_midnight",
        "name_orig": "Timer 8 (night cool) start timer minutes since midnight",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Night Cooling"
        ]
    },
    "485": {
        "id": 485,
        "name": "timer_8_night_cool_duration_minutes",
        "name_orig": "Timer 8 (night cool) duration minutes",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "default": 360,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Night Cooling"
        ]
    },
    "486": {
        "id": 486,
        "name": "timer_8_night_cool_flow_percent",
        "name_orig": "Timer 8 (night cool) flow %",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Night Cooling"
        ]
    },
    "487": {
        "id": 487,
        "name": "timer_8_night_cool_temperature_c",
        "name_orig": "Timer 8 (night cool) temperature C",
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "default": 16,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Timer",
            "Night Cooling"
        ]
    },
    "488": {
        "id": 488,
        "name": "database_version",
        "name_orig": "Database Version",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 1151,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "489": {
        "id": 489,
        "name": "rtc_calibration",
        "name_orig": "RTC-Calibration",
        "desc": "RTC-Calibration Value from Production-Test",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ]
    },
    "490": {
        "id": 490,
        "name": "d_bms_auto_vent",
        "name_orig": "D-BMS auto vent.",
        "desc": "Digital BMS automatic ventilation request",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "491": {
        "id": 491,
        "name": "d_bms_nightcool",
        "name_orig": "D-BMS nightcool",
        "desc": "Digital BMS nightcool request",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "492": {
        "id": 492,
        "name": "d_bms_holiday_mode",
        "name_orig": "D-BMS Holiday mode",
        "desc": "Digital BMS Holiday mode ventilation request",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "493": {
        "id": 493,
        "name": "d_bms_airflow",
        "name_orig": "D-BMS airflow",
        "desc": "Digital BMS airflow %",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ]
    },
    "494": {
        "id": 494,
        "name": "d_bms_temperature",
        "name_orig": "D-BMS temperature",
        "desc": "Digital BMS temperature",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 0.1,
        "default": 19,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ]
    },
    "495": {
        "id": 495,
        "name": "d_bms_co2_ppm",
        "name_orig": "D-BMS CO2 PPM",
        "desc": "Digital BMS CO2 PPM",
        "type": "int",
        "min": -1,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": []
    },
    "496": {
        "id": 496,
        "name": "reset_filter_status",
        "name_orig": "Reset Filter Status",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "497": {
        "id": 497,
        "name": "run_filter_calibration",
        "name_orig": "Run Filter Calibration",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "498": {
        "id": 498,
        "name": "start_test_procedure",
        "name_orig": "Start Test Procedure",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "499": {
        "id": 499,
        "name": "start_looptest",
        "name_orig": "Start Looptest",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "Loop Test"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "500": {
        "id": 500,
        "name": "stop_all_units",
        "name_orig": "Stop all units",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "501": {
        "id": 501,
        "name": "operating_mode_request",
        "name_orig": "Operating Mode Request",
        "desc": "intended to be used by Airlinq online API, write only param, will set the appropiate flags to enter the requested mode, readback will not return a valid value",
        "type": "enum",
        "min": 0,
        "max": 7,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "OFF",
            "1": "Standby",
            "2": "Manual Start",
            "3": "Holiday Mode",
            "4": "Auto",
            "5": "unused",
            "6": "Night Cool",
            "7": "Boost"
        }
    },
    "502": {
        "id": 502,
        "name": "panel_flow_request_for_d_bms",
        "name_orig": "Panel Flow Request For D-BMS",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "GROUP",
        "guiGroupNameList": []
    },
    "503": {
        "id": 503,
        "name": "operating_mode_request_airlinq_online",
        "name_orig": "Operating Mode Request Airlinq online",
        "desc": "Intended to be used wtih Airlinq Online homepage, once the request has been handled the MSB will be set to indicate the request has been handled",
        "type": "enum",
        "min": 0,
        "max": 7,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "OFF",
            "1": "Standby",
            "2": "Manual Start",
            "3": "Holiday Mode",
            "4": "Auto",
            "5": "unused",
            "6": "Night Cool",
            "7": "Boost"
        }
    },
    "513": {
        "id": 513,
        "name": "sf_a_coefficient",
        "name_orig": "SF A Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "514": {
        "id": 514,
        "name": "sf_b_coefficient",
        "name_orig": "SF B Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "515": {
        "id": 515,
        "name": "sf_c_coefficient",
        "name_orig": "SF C Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "516": {
        "id": 516,
        "name": "sf_d_coefficient",
        "name_orig": "SF D Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "517": {
        "id": 517,
        "name": "sf_e_coefficient",
        "name_orig": "SF E Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "518": {
        "id": 518,
        "name": "sf_f_coefficient",
        "name_orig": "SF F Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "519": {
        "id": 519,
        "name": "sf_g_coefficient",
        "name_orig": "SF G Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "520": {
        "id": 520,
        "name": "sf_h_coefficient",
        "name_orig": "SF H Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "521": {
        "id": 521,
        "name": "sf_i_coefficient",
        "name_orig": "SF I Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "522": {
        "id": 522,
        "name": "ef_a_coefficient",
        "name_orig": "EF A Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "523": {
        "id": 523,
        "name": "ef_b_coefficient",
        "name_orig": "EF B Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "524": {
        "id": 524,
        "name": "ef_c_coefficient",
        "name_orig": "EF C Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "525": {
        "id": 525,
        "name": "ef_d_coefficient",
        "name_orig": "EF D Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "526": {
        "id": 526,
        "name": "ef_e_coefficient",
        "name_orig": "EF E Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "527": {
        "id": 527,
        "name": "ef_f_coefficient",
        "name_orig": "EF F Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "528": {
        "id": 528,
        "name": "ef_g_coefficient",
        "name_orig": "EF G Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "529": {
        "id": 529,
        "name": "ef_h_coefficient",
        "name_orig": "EF H Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "530": {
        "id": 530,
        "name": "ef_i_coefficient",
        "name_orig": "EF I Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "531": {
        "id": 531,
        "name": "sf_x1x2_calculation",
        "name_orig": "SF X1/X2 Calculation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 15,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ],
        "options": {
            "0": "X1: 1' | X2: 1'",
            "1": "X1: 1' | X2: ln'",
            "2": "X1: 1' | X2: 1/'",
            "3": "X1: 1' | X2: 1/ln'",
            "4": "X1: ln' | X2: 1'",
            "5": "X1: ln' | X2: ln'",
            "6": "X1: ln' | X2: 1/'",
            "7": "X1: ln' | X2: 1/ln'",
            "8": "X1: 1/' | X2: 1'",
            "9": "X1: 1/' | X2: ln'",
            "10": "X1: 1/' | X2: 1/'",
            "11": "X1: 1/' | X2: 1/ln'",
            "12": "X1: 1/ln' | X2: 1'",
            "13": "X1: 1/ln' | X2: ln'",
            "14": "X1: 1/ln' | X2: 1/'",
            "15": "X1: 1/ln' | X2: 1/ln'"
        }
    },
    "532": {
        "id": 532,
        "name": "ef_x1x2_calculation",
        "name_orig": "EF X1/X2 Calculation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 15,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ],
        "options": {
            "0": "X1: 1' | X2: 1'",
            "1": "X1: 1' | X2: ln'",
            "2": "X1: 1' | X2: 1/'",
            "3": "X1: 1' | X2: 1/ln'",
            "4": "X1: ln' | X2: 1'",
            "5": "X1: ln' | X2: ln'",
            "6": "X1: ln' | X2: 1/'",
            "7": "X1: ln' | X2: 1/ln'",
            "8": "X1: 1/' | X2: 1'",
            "9": "X1: 1/' | X2: ln'",
            "10": "X1: 1/' | X2: 1/'",
            "11": "X1: 1/' | X2: 1/ln'",
            "12": "X1: 1/ln' | X2: 1'",
            "13": "X1: 1/ln' | X2: ln'",
            "14": "X1: 1/ln' | X2: 1/'",
            "15": "X1: 1/ln' | X2: 1/ln'"
        }
    },
    "533": {
        "id": 533,
        "name": "sf_bypass_opening",
        "name_orig": "SF Bypass Opening",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "534": {
        "id": 534,
        "name": "ef_bypass_opening",
        "name_orig": "EF Bypass Opening",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "535": {
        "id": 535,
        "name": "sf_bypass_correction",
        "name_orig": "SF Bypass Correction",
        "desc": "",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "536": {
        "id": 536,
        "name": "ef_bypass_correction",
        "name_orig": "EF Bypass Correction",
        "desc": "",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "537": {
        "id": 537,
        "name": "st_supply_fan_voltage",
        "name_orig": "ST Supply Fan Voltage",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "538": {
        "id": 538,
        "name": "st_extraction_fan_voltage",
        "name_orig": "ST Extraction Fan Voltage",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "539": {
        "id": 539,
        "name": "st_supply_fan_dp_reference",
        "name_orig": "ST Supply Fan DP Reference",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 550,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "540": {
        "id": 540,
        "name": "st_extraction_fan_dp_reference",
        "name_orig": "ST Extraction Fan DP Reference",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 550,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "541": {
        "id": 541,
        "name": "cc_icc_subtemp",
        "name_orig": "CC ICC SubTemp",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "542": {
        "id": 542,
        "name": "cc_compressor_max_time_between_master_messages",
        "name_orig": "CC Compressor Max Time Between Master Messages",
        "desc": "",
        "type": "int",
        "min": 2,
        "max": 300,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ]
    },
    "543": {
        "id": 543,
        "name": "filter_max_life_time_warning",
        "name_orig": "Filter Max Life Time, Warning",
        "desc": "Warning period in months prior to the alarm.",
        "type": "int",
        "min": 0,
        "max": 12,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "month(s)",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Settings"
        ]
    },
    "544": {
        "id": 544,
        "name": "boost_afterrun_time",
        "name_orig": "Boost Afterrun Time",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1080,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Boost"
        ]
    },
    "545": {
        "id": 545,
        "name": "boost_auto_off_time",
        "name_orig": "Boost Auto Off Time",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1080,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "min",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Boost"
        ]
    },
    "546": {
        "id": 546,
        "name": "external_stop",
        "name_orig": "External Stop",
        "desc": "Note: requires external stop input setup in I/O Config menu",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Process Settings",
            "External Stop"
        ],
        "options": {
            "0": "Group",
            "1": "All"
        }
    },
    "547": {
        "id": 547,
        "name": "sf_calibration",
        "name_orig": "SF Calibration",
        "desc": "",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "548": {
        "id": 548,
        "name": "ef_calibration",
        "name_orig": "EF Calibration",
        "desc": "",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "549": {
        "id": 549,
        "name": "adaptive_airflow_throw_setpoint",
        "name_orig": "Adaptive Airflow Throw Setpoint",
        "desc": "The setpoint is typically the distance between the inlet diffuser and the opposite wall.\nThe throw will automatically be adjusted to the setpoint value.",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "m",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Room Details"
        ]
    },
    "550": {
        "id": 550,
        "name": "aa_narrow_a",
        "name_orig": "AA Narrow A",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Adaptive Airflow"
        ]
    },
    "551": {
        "id": 551,
        "name": "aa_narrow_b",
        "name_orig": "AA Narrow B",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Adaptive Airflow"
        ]
    },
    "552": {
        "id": 552,
        "name": "aa_wide_a",
        "name_orig": "AA Wide A",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Adaptive Airflow"
        ]
    },
    "553": {
        "id": 553,
        "name": "aa_wide_b",
        "name_orig": "AA Wide B",
        "desc": "",
        "type": "decimal",
        "min": -64992,
        "max": 64992,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Adaptive Airflow"
        ]
    },
    "554": {
        "id": 554,
        "name": "aa_wide_position",
        "name_orig": "AA Wide Position",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Adaptive Airflow"
        ]
    },
    "555": {
        "id": 555,
        "name": "aa_narrow_position",
        "name_orig": "AA Narrow Position",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Adaptive Airflow"
        ]
    },
    "556": {
        "id": 556,
        "name": "nc_allow_cc",
        "name_orig": "NC Allow CC",
        "desc": "Allow Comfort Cooling during Nightcool periode",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "557": {
        "id": 557,
        "name": "condensate_frost_protection_on_temperature",
        "name_orig": "Condensate Frost Protection On Temperature",
        "desc": "",
        "type": "decimal",
        "min": -20,
        "max": 20,
        "res": 0.1,
        "default": 2,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Condensation"
        ]
    },
    "558": {
        "id": 558,
        "name": "condensate_frost_protection_hysteresis",
        "name_orig": "Condensate Frost Protection Hysteresis",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 20,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Condensation"
        ]
    },
    "559": {
        "id": 559,
        "name": "ph_pid_kp",
        "name_orig": "PH PID KP",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "560": {
        "id": 560,
        "name": "ph_pid_ki",
        "name_orig": "PH PID KI",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "561": {
        "id": 561,
        "name": "ph_pid_kd",
        "name_orig": "PH PID KD",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "562": {
        "id": 562,
        "name": "ph_pid_ka",
        "name_orig": "PH PID KA",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "563": {
        "id": 563,
        "name": "ph_pid_min",
        "name_orig": "PH PID Min",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "564": {
        "id": 564,
        "name": "ph_pid_max",
        "name_orig": "PH PID Max",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "565": {
        "id": 565,
        "name": "ph_pid_sample_time",
        "name_orig": "PH PID Sample Time",
        "desc": "",
        "type": "int",
        "min": 10,
        "max": 30000,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "566": {
        "id": 566,
        "name": "ch_pid_kp",
        "name_orig": "CH PID KP",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "567": {
        "id": 567,
        "name": "ch_pid_ki",
        "name_orig": "CH PID KI",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "568": {
        "id": 568,
        "name": "ch_pid_kd",
        "name_orig": "CH PID KD",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "569": {
        "id": 569,
        "name": "ch_pid_ka",
        "name_orig": "CH PID KA",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "570": {
        "id": 570,
        "name": "ch_pid_min",
        "name_orig": "CH PID Min",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "571": {
        "id": 571,
        "name": "ch_pid_max",
        "name_orig": "CH PID Max",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "572": {
        "id": 572,
        "name": "ch_pid_sample_time",
        "name_orig": "CH PID Sample Time",
        "desc": "",
        "type": "int",
        "min": 10,
        "max": 30000,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "573": {
        "id": 573,
        "name": "ph_pwm_period",
        "name_orig": "PH PWM Period",
        "desc": "Note: when using analog output for PWM signal set PWM period to minimum 10000ms",
        "type": "int",
        "min": 1000,
        "max": 30000,
        "res": 1,
        "default": 1000,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ]
    },
    "574": {
        "id": 574,
        "name": "ch_pwm_period",
        "name_orig": "CH PWM Period",
        "desc": "Note: when using analog output for PWM signal set PWM period to minimum 10000ms",
        "type": "int",
        "min": 1000,
        "max": 30000,
        "res": 1,
        "default": 1000,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "575": {
        "id": 575,
        "name": "group_master_na_warning",
        "name_orig": "Group Master NA Warning",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "576": {
        "id": 576,
        "name": "pcb_number",
        "name_orig": "PCB Number",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ],
        "options": {
            "0": "Unknown",
            "843": "JE843",
            "861": "JE861",
            "862": "JE862",
            "865": "JE865",
            "892": "JE892",
            "901": "JE901"
        }
    },
    "577": {
        "id": 577,
        "name": "pcb_revision",
        "name_orig": "PCB Revision",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 20,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ],
        "options": {
            "0": "Unknown",
            "1": "A",
            "2": "B",
            "3": "C",
            "4": "D",
            "5": "E",
            "6": "F",
            "7": "G",
            "8": "H",
            "9": "I",
            "10": "J",
            "11": "K",
            "12": "L",
            "13": "M",
            "14": "N",
            "15": "O",
            "16": "P",
            "17": "Q",
            "18": "R",
            "19": "S",
            "20": "T"
        }
    },
    "578": {
        "id": 578,
        "name": "cc_icc_standby_wait_on",
        "name_orig": "CC ICC Standby Wait On",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 600,
        "res": 1,
        "default": 30,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "579": {
        "id": 579,
        "name": "cc_icc_standby_wait_off",
        "name_orig": "CC ICC Standby Wait Off",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 600,
        "res": 1,
        "default": 180,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Settings"
        ]
    },
    "580": {
        "id": 580,
        "name": "dp_min",
        "name_orig": "DP Min",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 550,
        "res": 0.1,
        "default": 1,
        "showInTechnical": true,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "581": {
        "id": 581,
        "name": "x2_input",
        "name_orig": "X2 Input",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ],
        "options": {
            "0": "dP_ref",
            "1": "tac"
        }
    },
    "582": {
        "id": 582,
        "name": "dp_scale",
        "name_orig": "DP Scale",
        "desc": "",
        "type": "decimal",
        "min": 0.01,
        "max": 100,
        "res": 0.01,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "583": {
        "id": 583,
        "name": "tacho_scale",
        "name_orig": "Tacho Scale",
        "desc": "",
        "type": "decimal",
        "min": 0.01,
        "max": 100,
        "res": 0.01,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Measure"
        ]
    },
    "584": {
        "id": 584,
        "name": "rtmin",
        "name_orig": "Rtmin",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 18,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "Displacement"
        ]
    },
    "585": {
        "id": 585,
        "name": "auto_calibration_time",
        "name_orig": "Auto calibration time",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 250,
        "res": 1,
        "default": 25,
        "showInTechnical": true,
        "unit": "hour",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Filter",
            "Self Test"
        ]
    },
    "586": {
        "id": 586,
        "name": "ethernet_ip",
        "name_orig": "Ethernet IP",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 0,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "dhcp",
            "1": "static"
        }
    },
    "597": {
        "id": 597,
        "name": "ethernet_iots_gateway_port_number",
        "name_orig": "Ethernet IOTS Gateway Port Number",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": 1,
        "default": 55556,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ]
    },
    "598": {
        "id": 598,
        "name": "it_hysteresis_for_cc_re_init",
        "name_orig": "IT Hysteresis for CC re-init",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "599": {
        "id": 599,
        "name": "tc_valve_position_neutral",
        "name_orig": "TC: Valve Position Neutral",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 50,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "600": {
        "id": 600,
        "name": "tc_valve_position_room_1",
        "name_orig": "TC: Valve Position Room 1",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 20,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "601": {
        "id": 601,
        "name": "tc_valve_position_room_2",
        "name_orig": "TC: Valve Position Room 2",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 80,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "602": {
        "id": 602,
        "name": "tc_run_time",
        "name_orig": "TC: Run Time",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "default": 75,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "603": {
        "id": 603,
        "name": "tc_cw_rotation",
        "name_orig": "TC: CW Rotation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "604": {
        "id": 604,
        "name": "tc_ccw_rotation",
        "name_orig": "TC: CCW Rotation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "605": {
        "id": 605,
        "name": "tc_0percent_position",
        "name_orig": "TC: 0% Position",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ],
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "606": {
        "id": 606,
        "name": "tc_calibration_position",
        "name_orig": "TC: Calibration Position",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ],
        "options": {
            "0": "0%",
            "1": "100%"
        }
    },
    "607": {
        "id": 607,
        "name": "tc_self_calibration",
        "name_orig": "TC: Self Calibration",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "608": {
        "id": 608,
        "name": "tc_cw_offset",
        "name_orig": "TC: CW Offset",
        "desc": "",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "609": {
        "id": 609,
        "name": "tc_ccw_offset",
        "name_orig": "TC: CCW Offset",
        "desc": "",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "610": {
        "id": 610,
        "name": "mode_NAMEDUPLICATE_OF_239",
        "name_orig": "Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ],
        "options": {
            "0": "Off",
            "1": "VC",
            "2": "TC"
        }
    },
    "611": {
        "id": 611,
        "name": "off_position",
        "name_orig": "Off Position",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow Distribution"
        ]
    },
    "612": {
        "id": 612,
        "name": "hgt_a_coeff",
        "name_orig": "HGT_A_coeff",
        "desc": "",
        "type": "decimal",
        "min": -999.9,
        "max": 999.9,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "613": {
        "id": 613,
        "name": "hgt_b_coeff",
        "name_orig": "HGT_B_coeff",
        "desc": "",
        "type": "decimal",
        "min": -999.9,
        "max": 999.9,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ]
    },
    "614": {
        "id": 614,
        "name": "screensaver_company_logo_id",
        "name_orig": "Screensaver Company Logo ID",
        "desc": "Defines which company logo to use in screensaver",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ],
        "options": {
            "0": "None",
            "1": "Airmaster",
            "2": "Itho",
            "3": "Wernig",
            "4": "Airflow"
        }
    },
    "615": {
        "id": 615,
        "name": "screensaver_airquality_mode",
        "name_orig": "Screensaver Airquality Mode",
        "desc": "Defines how the Airquality information is shown",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ],
        "options": {
            "0": "Disabled",
            "1": "Graph",
            "2": "Graph + Text",
            "3": "Graph + Text (Large)"
        }
    },
    "616": {
        "id": 616,
        "name": "screensaver_logo",
        "name_orig": "Screensaver Logo",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 0,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ],
        "options": {
            "0": "None",
            "1": "Company"
        }
    },
    "617": {
        "id": 617,
        "name": "sl_enabled_time_periode_start_minutes_since_midnight",
        "name_orig": "SL Enabled Time Periode Start (minutes since midnight)",
        "desc": "Start time for status LED enabled time periode, the LED is only allowed to be on during the enabled time periode",
        "type": "int",
        "min": 0,
        "max": 1440,
        "res": 1,
        "default": 420,
        "showInTechnical": true,
        "unit": "min.",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Status LED"
        ]
    },
    "618": {
        "id": 618,
        "name": "sl_enabled_time_periode_end_minutes_since_midnight",
        "name_orig": "SL Enabled Time Periode End (minutes since midnight)",
        "desc": "End time for status LED enabled time periode, the LED is only allowed to be on during the enabled time periode",
        "type": "int",
        "min": 0,
        "max": 1440,
        "res": 1,
        "default": 1320,
        "showInTechnical": true,
        "unit": "min.",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Status LED"
        ]
    },
    "619": {
        "id": 619,
        "name": "sl_state_during_normal_operation",
        "name_orig": "SL State During Normal Operation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Status LED"
        ],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "620": {
        "id": 620,
        "name": "bp_fan_cor_a_coefficient",
        "name_orig": "BP Fan Cor A Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "621": {
        "id": 621,
        "name": "bp_fan_cor_b_coefficient",
        "name_orig": "BP Fan Cor B Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "622": {
        "id": 622,
        "name": "bp_fan_cor_c_coefficient",
        "name_orig": "BP Fan Cor C Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "623": {
        "id": 623,
        "name": "bp_fan_cor_low_limit",
        "name_orig": "BP Fan Cor Low Limit",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "624": {
        "id": 624,
        "name": "bp_fan_cor_high_limit",
        "name_orig": "BP Fan Cor High Limit",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "625": {
        "id": 625,
        "name": "nc_mode",
        "name_orig": "NC Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ],
        "options": {
            "0": "NC Normal",
            "1": "NC Boost",
            "2": "NC Hybrid",
            "3": "NC Boost Hybrid"
        }
    },
    "626": {
        "id": 626,
        "name": "nc_boost_sfv",
        "name_orig": "NC Boost SFV",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ]
    },
    "627": {
        "id": 627,
        "name": "nc_boost_efv",
        "name_orig": "NC Boost EFV",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ]
    },
    "628": {
        "id": 628,
        "name": "nc_rtmin",
        "name_orig": "NC RTmin",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 16,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ]
    },
    "629": {
        "id": 629,
        "name": "nc_deltat",
        "name_orig": "NC DeltaT",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Night Cooling",
            "General"
        ]
    },
    "630": {
        "id": 630,
        "name": "cc_valve_pid_ke",
        "name_orig": "CC Valve PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Valve"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "631": {
        "id": 631,
        "name": "cc_icc_pid_ke",
        "name_orig": "CC ICC PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Cooling"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "632": {
        "id": 632,
        "name": "cc_hg_pid_ke",
        "name_orig": "CC HG PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Hot Gas"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "633": {
        "id": 633,
        "name": "cc_evt_pid_ke",
        "name_orig": "CC EVT PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Inverter",
            "Evaporator"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "634": {
        "id": 634,
        "name": "qsa_pid_ke",
        "name_orig": "QSA PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "635": {
        "id": 635,
        "name": "qea_pid_ke",
        "name_orig": "QEA PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "636": {
        "id": 636,
        "name": "sf_pid_ke",
        "name_orig": "SF PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "637": {
        "id": 637,
        "name": "ef_pid_ke",
        "name_orig": "EF PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "638": {
        "id": 638,
        "name": "ph_pid_ke",
        "name_orig": "PH PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Preheater"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "639": {
        "id": 639,
        "name": "ch_pid_ke",
        "name_orig": "CH PID KE",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ],
        "options": {
            "0": "1",
            "1": "RQF/100"
        }
    },
    "640": {
        "id": 640,
        "name": "control_algorithm_cooling",
        "name_orig": "Control Algorithm, Cooling",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Comfort Cooling",
            "Settings"
        ],
        "options": {
            "0": "PID0",
            "1": "PID1"
        }
    },
    "641": {
        "id": 641,
        "name": "control_algorithm_heating",
        "name_orig": "Control Algorithm, Heating",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "General"
        ],
        "options": {
            "0": "PID0",
            "1": "PID1"
        }
    },
    "642": {
        "id": 642,
        "name": "control_algorithm_flow_control",
        "name_orig": "Control Algorithm, Flow Control",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Flow",
            "Control"
        ],
        "options": {
            "0": "PID0",
            "1": "PID1"
        }
    },
    "643": {
        "id": 643,
        "name": "self_test_tacho_alarm_count",
        "name_orig": "Self test tacho alarm count",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "Debug"
        ]
    },
    "644": {
        "id": 644,
        "name": "self_test_tacho_warning_count",
        "name_orig": "Self test tacho warning count",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "Debug"
        ]
    },
    "645": {
        "id": 645,
        "name": "self_test_differential_pressure_alarm_count",
        "name_orig": "Self test differential pressure alarm count",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 9999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "Debug"
        ]
    },
    "646": {
        "id": 646,
        "name": "p_stop",
        "name_orig": "P stop",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 300,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "647": {
        "id": 647,
        "name": "p_sf_total",
        "name_orig": "P SF total",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "648": {
        "id": 648,
        "name": "p_sf",
        "name_orig": "P SF",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "649": {
        "id": 649,
        "name": "p_ef_total",
        "name_orig": "P EF total",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "650": {
        "id": 650,
        "name": "p_ef",
        "name_orig": "P EF",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "651": {
        "id": 651,
        "name": "p_fans",
        "name_orig": "P fans",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "652": {
        "id": 652,
        "name": "dp_sf",
        "name_orig": "dP SF",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "653": {
        "id": 653,
        "name": "q_sf",
        "name_orig": "Q SF",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 3200,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "654": {
        "id": 654,
        "name": "q_sf_deviation",
        "name_orig": "Q SF deviation",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "655": {
        "id": 655,
        "name": "dp_ef",
        "name_orig": "dP EF",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "656": {
        "id": 656,
        "name": "q_ef",
        "name_orig": "Q EF",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 3200,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "657": {
        "id": 657,
        "name": "q_ef_deviation",
        "name_orig": "Q EF deviation",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "658": {
        "id": 658,
        "name": "sfp",
        "name_orig": "SFP",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "J/m³",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Performance Test Results"
        ]
    },
    "659": {
        "id": 659,
        "name": "ch_water_protection_otv",
        "name_orig": "CH Water Protection OTV",
        "desc": "To prevent damage caused by freezing, due to low outside temperature.\nNote: this check is always active regardless of \"disable low temp alarm\" timer",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp Alarm"
        ]
    },
    "660": {
        "id": 660,
        "name": "ch_water_protection_rt__it",
        "name_orig": "CH Water Protection RT / IT",
        "desc": "To prevent damage caused by freezing, due to low RT or IT.\nNote: this check is always active regardless of \"disable low temp alarm\" timer",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "default": 10,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp Alarm"
        ]
    },
    "661": {
        "id": 661,
        "name": "flash_write_error_count",
        "name_orig": "Flash Write error count",
        "desc": "",
        "type": "int",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "Debug"
        ]
    },
    "662": {
        "id": 662,
        "name": "standby_power",
        "name_orig": "Standby Power",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 300,
        "res": 0.01,
        "default": 0,
        "showInTechnical": true,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ]
    },
    "663": {
        "id": 663,
        "name": "ambient_pressure",
        "name_orig": "Ambient Pressure",
        "desc": "",
        "type": "int",
        "min": 700,
        "max": 1200,
        "res": 1,
        "default": 1013,
        "showInTechnical": true,
        "unit": "mBar",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ]
    },
    "664": {
        "id": 664,
        "name": "r_tvocmin",
        "name_orig": "R_TVOCmin",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 65,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "TVOC"
        ]
    },
    "665": {
        "id": 665,
        "name": "r_tvocmax",
        "name_orig": "R_TVOCmax",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 220,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "TVOC"
        ]
    },
    "666": {
        "id": 666,
        "name": "simulated_airquality",
        "name_orig": "Simulated Airquality",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 200,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Simulation"
        ]
    },
    "667": {
        "id": 667,
        "name": "fco2",
        "name_orig": "FCO2",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 14950,
        "showInTechnical": true,
        "unit": "m3/h / g/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ]
    },
    "668": {
        "id": 668,
        "name": "f_tvoc",
        "name_orig": "F_TVOC",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 15360,
        "showInTechnical": true,
        "unit": "m3/h / mg/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "TVOC"
        ]
    },
    "669": {
        "id": 669,
        "name": "room_volume",
        "name_orig": "Room Volume",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 3000,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "m3",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "Room Details"
        ]
    },
    "670": {
        "id": 670,
        "name": "co2_n_avg_now",
        "name_orig": "CO2 n avg now",
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ]
    },
    "671": {
        "id": 671,
        "name": "co2_n_avg_bw",
        "name_orig": "CO2 n avg bw",
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 500,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ]
    },
    "672": {
        "id": 672,
        "name": "tvoc_n_avg_now",
        "name_orig": "TVOC n avg now",
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 100,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "TVOC"
        ]
    },
    "673": {
        "id": 673,
        "name": "tvoc_n_avg_bw",
        "name_orig": "TVOC n avg bw",
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 500,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "TVOC"
        ]
    },
    "674": {
        "id": 674,
        "name": "bp_location",
        "name_orig": "BP Location",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ],
        "options": {
            "0": "Supply",
            "1": "Extraction"
        }
    },
    "675": {
        "id": 675,
        "name": "tvoc_control_mode",
        "name_orig": "TVOC Control Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "TVOC"
        ],
        "options": {
            "0": "No",
            "1": "Level",
            "2": "RateOfChange",
            "3": "Combined"
        }
    },
    "676": {
        "id": 676,
        "name": "electric_heater_afterrun",
        "name_orig": "Electric Heater Afterrun",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 600,
        "res": 1,
        "default": 60,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Heating Surfaces"
        ]
    },
    "677": {
        "id": 677,
        "name": "co2_high",
        "name_orig": "CO2 High",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 50,
        "default": 2000,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "678": {
        "id": 678,
        "name": "co2_warning",
        "name_orig": "CO2 Warning",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 50,
        "default": 5000,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "679": {
        "id": 679,
        "name": "tvoc_low",
        "name_orig": "TVOC Low",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 1,
        "default": 65,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "680": {
        "id": 680,
        "name": "tvoc_mid",
        "name_orig": "TVOC Mid",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 1,
        "default": 220,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "681": {
        "id": 681,
        "name": "tvoc_high",
        "name_orig": "TVOC High",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 1,
        "default": 660,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "682": {
        "id": 682,
        "name": "tvoc_warning",
        "name_orig": "TVOC Warning",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 32000,
        "res": 1,
        "default": 2200,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ]
    },
    "683": {
        "id": 683,
        "name": "tvoc_start",
        "name_orig": "TVOC Start",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 220,
        "showInTechnical": true,
        "unit": "ppb",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "TVOC"
        ]
    },
    "684": {
        "id": 684,
        "name": "tvoc_sensor_installed",
        "name_orig": "TVOC Sensor Installed",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "TVOC"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "685": {
        "id": 685,
        "name": "start_priority_8",
        "name_orig": "Start Priority 8",
        "desc": "Start priority 8",
        "type": "enum",
        "min": 0,
        "max": 9,
        "res": 1,
        "default": 8,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "Start Priority"
        ],
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "BMS",
            "3": "PIR",
            "4": "0-10V Flow",
            "5": "Panel",
            "6": "CO2",
            "7": "Timer",
            "8": "TVOC",
            "9": "Room Temperature"
        }
    },
    "686": {
        "id": 686,
        "name": "allow_start_by_tvoc",
        "name_orig": "Allow Start by TVOC",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "687": {
        "id": 687,
        "name": "rh_room_sensor_installed",
        "name_orig": "RH Room Sensor Installed",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Humidity"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "688": {
        "id": 688,
        "name": "co2_calibration",
        "name_orig": "CO2 Calibration",
        "desc": "To calibrate digital CO2 sensor, first set to zero, then set to the current CO2 level. Note: Sensirion recommends 6 minutes of runtime before doing calibration",
        "type": "int",
        "min": 0,
        "max": 2000,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Factory",
            "General"
        ]
    },
    "689": {
        "id": 689,
        "name": "co2_start",
        "name_orig": "CO2 Start",
        "desc": "Start operation for this unit \\n (Default value: 0 ppm) \\n  \\n CO2 Start = 0 ppm \\n Start operation at CO2 Min + 10% \\n  \\n CO2 Start = x ppm \\n Start operation at x ppm",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Operation",
            "CO2"
        ]
    },
    "690": {
        "id": 690,
        "name": "pir_start_delay",
        "name_orig": "PIR Start delay",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 240,
        "res": 1,
        "default": 3,
        "showInTechnical": true,
        "unit": "min.",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Operation",
            "PIR"
        ]
    },
    "691": {
        "id": 691,
        "name": "rt_start_offset",
        "name_orig": "RT Start Offset",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "default": 2,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "GROUP",
        "guiGroupNameList": [
            "Process Settings",
            "High Temp"
        ]
    },
    "692": {
        "id": 692,
        "name": "screensaver_show_flow",
        "name_orig": "Screensaver Show Flow",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "GLOBAL",
        "guiGroupNameList": [
            "Airlinq Panel",
            "Orbit"
        ],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "693": {
        "id": 693,
        "name": "allow_start_by_room_temperatur",
        "name_orig": "Allow Start by Room Temperatur",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "GROUP",
        "guiGroupNameList": [
            "BMS",
            "Digital"
        ],
        "options": {
            "0": "No",
            "1": "Yes"
        }
    },
    "694": {
        "id": 694,
        "name": "pir_1_mode",
        "name_orig": "PIR 1 Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "PIR"
        ],
        "options": {
            "0": "Auto",
            "1": "Active Low",
            "2": "Active High"
        }
    },
    "695": {
        "id": 695,
        "name": "pir_2_mode",
        "name_orig": "PIR 2 Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "PIR"
        ],
        "options": {
            "0": "Auto",
            "1": "Active Low",
            "2": "Active High"
        }
    },
    "696": {
        "id": 696,
        "name": "pir_3_mode",
        "name_orig": "PIR 3 Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "PIR"
        ],
        "options": {
            "0": "Auto",
            "1": "Active Low",
            "2": "Active High"
        }
    },
    "700": {
        "id": 700,
        "name": "threshold_room_temperature",
        "name_orig": "Threshold Room Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "701": {
        "id": 701,
        "name": "threshold_inlet_temperature",
        "name_orig": "Threshold Inlet Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "702": {
        "id": 702,
        "name": "threshold_outside_temperature",
        "name_orig": "Threshold Outside Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "703": {
        "id": 703,
        "name": "threshold_general_purpose_temperature",
        "name_orig": "Threshold General Purpose Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "704": {
        "id": 704,
        "name": "threshold_evaporator_temperature",
        "name_orig": "Threshold Evaporator Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "705": {
        "id": 705,
        "name": "threshold_condenser_temperature",
        "name_orig": "Threshold Condenser Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "706": {
        "id": 706,
        "name": "threshold_outside_temperature_ventilation",
        "name_orig": "Threshold Outside Temperature Ventilation",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "707": {
        "id": 707,
        "name": "threshold_exhaust_temperature_ventilation",
        "name_orig": "Threshold Exhaust Temperature Ventilation",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "708": {
        "id": 708,
        "name": "threshold_a_bms_temperature",
        "name_orig": "Threshold A-BMS Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "709": {
        "id": 709,
        "name": "threshold_co2_level_room",
        "name_orig": "Threshold CO2 Level Room",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 5000,
        "res": 1,
        "default": 50,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "710": {
        "id": 710,
        "name": "threshold_internal_co2_sensor",
        "name_orig": "Threshold Internal CO2 Sensor",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 5000,
        "res": 1,
        "default": 500,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "711": {
        "id": 711,
        "name": "threshold_a_co2_1",
        "name_orig": "Threshold A-CO2 #1",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 5000,
        "res": 1,
        "default": 500,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "712": {
        "id": 712,
        "name": "threshold_a_co2_2",
        "name_orig": "Threshold A-CO2 #2",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 5000,
        "res": 1,
        "default": 500,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "713": {
        "id": 713,
        "name": "threshold_a_co2_3",
        "name_orig": "Threshold A-CO2 #3",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 5000,
        "res": 1,
        "default": 500,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "714": {
        "id": 714,
        "name": "threshold_a_bms_flow_percent",
        "name_orig": "Threshold A-BMS Flow Percent",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "715": {
        "id": 715,
        "name": "threshold_comfort_heater_percent",
        "name_orig": "Threshold Comfort Heater Percent",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "716": {
        "id": 716,
        "name": "threshold_bypass_damper_percent",
        "name_orig": "Threshold Bypass Damper Percent",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "717": {
        "id": 717,
        "name": "threshold_adaptive_airflow",
        "name_orig": "Threshold Adaptive Airflow",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 10,
        "res": 0.1,
        "default": 0.1,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "718": {
        "id": 718,
        "name": "threshold_cooling_percent",
        "name_orig": "Threshold Cooling Percent",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "719": {
        "id": 719,
        "name": "threshold_pre_heater_percent",
        "name_orig": "Threshold Pre Heater Percent",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "720": {
        "id": 720,
        "name": "threshold_supply_fan_voltage",
        "name_orig": "Threshold Supply Fan Voltage",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 10,
        "res": 0.1,
        "default": 0.2,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "721": {
        "id": 721,
        "name": "threshold_extraction_fan_voltage",
        "name_orig": "Threshold Extraction Fan Voltage",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 10,
        "res": 0.1,
        "default": 0.2,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "722": {
        "id": 722,
        "name": "threshold_recirculation_fan_voltage",
        "name_orig": "Threshold Recirculation Fan Voltage",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 10,
        "res": 0.1,
        "default": 0.2,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "723": {
        "id": 723,
        "name": "threshold_supply_flow",
        "name_orig": "Threshold Supply Flow",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 10,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "724": {
        "id": 724,
        "name": "threshold_extraction_flow",
        "name_orig": "Threshold Extraction Flow",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 10,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "725": {
        "id": 725,
        "name": "threshold_supply_flow_1",
        "name_orig": "Threshold Supply Flow #1",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 10000,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "726": {
        "id": 726,
        "name": "threshold_supply_flow_2",
        "name_orig": "Threshold Supply Flow #2",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 10000,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "727": {
        "id": 727,
        "name": "threshold_supply_fan_rpm",
        "name_orig": "Threshold Supply Fan RPM",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 50,
        "showInTechnical": false,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "728": {
        "id": 728,
        "name": "threshold_extraction_fan_rpm",
        "name_orig": "Threshold Extraction Fan RPM",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 50,
        "showInTechnical": false,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "729": {
        "id": 729,
        "name": "threshold_recirculation_fan_rpm",
        "name_orig": "Threshold Recirculation Fan RPM",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 10000,
        "res": 1,
        "default": 50,
        "showInTechnical": false,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "730": {
        "id": 730,
        "name": "threshold_requested_temperature",
        "name_orig": "Threshold Requested Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "731": {
        "id": 731,
        "name": "threshold_requested_flow",
        "name_orig": "Threshold Requested Flow",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "732": {
        "id": 732,
        "name": "threshold_relative_humidity_supply_air",
        "name_orig": "Threshold Relative Humidity Supply Air",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "733": {
        "id": 733,
        "name": "threshold_relative_humidity_extraction_air",
        "name_orig": "Threshold Relative Humidity Extraction Air",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "734": {
        "id": 734,
        "name": "threshold_system_mode",
        "name_orig": "Threshold System Mode",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "735": {
        "id": 735,
        "name": "threshold_system_condition",
        "name_orig": "Threshold System Condition:",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "736": {
        "id": 736,
        "name": "threshold_system_alarm",
        "name_orig": "Threshold System Alarm:",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "737": {
        "id": 737,
        "name": "threshold_am_float_switch",
        "name_orig": "Threshold AM Float Switch",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "738": {
        "id": 738,
        "name": "threshold_manuel_start",
        "name_orig": "Threshold Manuel Start",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "739": {
        "id": 739,
        "name": "threshold_cc_float_switch",
        "name_orig": "Threshold CC Float switch",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "740": {
        "id": 740,
        "name": "threshold_a_bms_start",
        "name_orig": "Threshold A-BMS Start",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "741": {
        "id": 741,
        "name": "threshold_pir",
        "name_orig": "Threshold PIR",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "742": {
        "id": 742,
        "name": "threshold_alarm_input",
        "name_orig": "Threshold Alarm Input",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "743": {
        "id": 743,
        "name": "threshold_recirculation_damper",
        "name_orig": "Threshold Recirculation Damper",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "744": {
        "id": 744,
        "name": "threshold_main_air_damper",
        "name_orig": "Threshold Main Air Damper",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "745": {
        "id": 745,
        "name": "threshold_general_warning_id_0_15",
        "name_orig": "Threshold General warning id 0-15",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "746": {
        "id": 746,
        "name": "threshold_general_warning_id_16_31",
        "name_orig": "Threshold General warning id 16-31",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "747": {
        "id": 747,
        "name": "threshold_general_alarm_id_0_15",
        "name_orig": "Threshold General alarm id 0-15",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "748": {
        "id": 748,
        "name": "threshold_general_alarm_id_16_31",
        "name_orig": "Threshold General alarm id 16-31",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "749": {
        "id": 749,
        "name": "threshold_filter_remaining_service_life_days",
        "name_orig": "Threshold Filter, remaining service life [days]",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1000,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "days",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "750": {
        "id": 750,
        "name": "threshold_air_handling_unit_power_consumption",
        "name_orig": "Threshold Air Handling Unit Power Consumption",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "751": {
        "id": 751,
        "name": "threshold_air_handling_unit_power_consumption_decimal_points",
        "name_orig": "Threshold Air Handling Unit Power Consumption decimal points",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.001,
        "max": 0.999,
        "res": 0.001,
        "default": 0.1,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "752": {
        "id": 752,
        "name": "threshold_utc_time_in_seconds",
        "name_orig": "Threshold UTC Time in seconds",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 3600,
        "showInTechnical": false,
        "unit": "s",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "753": {
        "id": 753,
        "name": "threshold_air_handling_unit_power_consumption_NAMEDUPLICATE_OF_750",
        "name_orig": "Threshold Air Handling Unit Power Consumption",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 100,
        "showInTechnical": false,
        "unit": "Wh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "754": {
        "id": 754,
        "name": "threshold_remaining_service_life_percent",
        "name_orig": "Threshold Remaining Service Life [%]",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 101,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "755": {
        "id": 755,
        "name": "threshold_remaining_service_life_hours",
        "name_orig": "Threshold Remaining Service Life [Hours]",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "756": {
        "id": 756,
        "name": "threshold_evaporator_in_temperature",
        "name_orig": "Threshold Evaporator In Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "757": {
        "id": 757,
        "name": "threshold_evaporator_out_temperature",
        "name_orig": "Threshold Evaporator Out Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "758": {
        "id": 758,
        "name": "threshold_hotgas_temperature",
        "name_orig": "Threshold Hotgas Temperature",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 100,
        "res": 0.1,
        "default": 0.5,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "759": {
        "id": 759,
        "name": "threshold_stepper_valve_opening",
        "name_orig": "Threshold Stepper Valve Opening",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 1,
        "max": 100,
        "res": 0.1,
        "default": 100,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "760": {
        "id": 760,
        "name": "threshold_analog_in_1",
        "name_orig": "Threshold Analog in 1",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.01,
        "max": 10,
        "res": 0.01,
        "default": 10,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "761": {
        "id": 761,
        "name": "threshold_analog_in_2",
        "name_orig": "Threshold Analog in 2",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.01,
        "max": 10,
        "res": 0.01,
        "default": 10,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "762": {
        "id": 762,
        "name": "threshold_analog_in_3",
        "name_orig": "Threshold Analog in 3",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.01,
        "max": 10,
        "res": 0.01,
        "default": 10,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "763": {
        "id": 763,
        "name": "threshold_hardware_errors",
        "name_orig": "Threshold Hardware errors",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "764": {
        "id": 764,
        "name": "threshold_bacnet_device_id",
        "name_orig": "Threshold Bacnet Device Id",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "765": {
        "id": 765,
        "name": "threshold_cooling_unit_power_consumption",
        "name_orig": "Threshold Cooling Unit Power Consumption",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "766": {
        "id": 766,
        "name": "threshold_cooling_unit_power_consumption_decimal_points",
        "name_orig": "Threshold Cooling Unit Power Consumption decimal points",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.001,
        "max": 0.999,
        "res": 0.001,
        "default": 0.1,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "767": {
        "id": 767,
        "name": "threshold_cooling_unit_power_consumption_NAMEDUPLICATE_OF_765",
        "name_orig": "Threshold Cooling Unit Power Consumption",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 100,
        "showInTechnical": false,
        "unit": "Wh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "768": {
        "id": 768,
        "name": "threshold_supply_air_pressure_difference",
        "name_orig": "Threshold Supply air pressure difference",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 550,
        "res": 0.1,
        "default": 10,
        "showInTechnical": false,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "769": {
        "id": 769,
        "name": "threshold_extracton_air_pressure_difference",
        "name_orig": "Threshold Extracton air pressure difference",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.1,
        "max": 550,
        "res": 0.1,
        "default": 10,
        "showInTechnical": false,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "770": {
        "id": 770,
        "name": "threshold_simplified_operating_mode",
        "name_orig": "Threshold Simplified Operating Mode",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "771": {
        "id": 771,
        "name": "threshold_hardware_errors_lsb",
        "name_orig": "Threshold Hardware Errors LSB",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "772": {
        "id": 772,
        "name": "threshold_hardware_errors_msb",
        "name_orig": "Threshold Hardware Errors MSB",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "773": {
        "id": 773,
        "name": "threshold_air_handling_unit_current_power_consumption",
        "name_orig": "Threshold Air Handling Unit Current Power Consumption",
        "desc": "threshold for online logging",
        "type": "decimal",
        "min": 0.0099945068359375,
        "max": 1000,
        "res": 1e-10,
        "default": 18688,
        "showInTechnical": false,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "774": {
        "id": 774,
        "name": "threshold_air_quality_tvoc",
        "name_orig": "Threshold Air quality TVOC",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 10,
        "showInTechnical": false,
        "unit": "ppb",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "775": {
        "id": 775,
        "name": "threshold_air_quality_raw_signal",
        "name_orig": "Threshold Air quality RAW signal",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 32767,
        "res": 1,
        "default": 32767,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "776": {
        "id": 776,
        "name": "threshold_rqf_prodco2",
        "name_orig": "Threshold RQF_ProdCO2",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "777": {
        "id": 777,
        "name": "threshold_rqf_prodtvoc",
        "name_orig": "Threshold RQF_ProdTVOC",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "default": 5,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "778": {
        "id": 778,
        "name": "threshold_started_by",
        "name_orig": "Threshold Started By",
        "desc": "threshold for online logging",
        "type": "int",
        "min": 1,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Datalog",
            "Thresholds For Online Log"
        ]
    },
    "821": {
        "id": 821,
        "name": "sf_ppr",
        "name_orig": "SF PPR",
        "desc": "Number of tacho pulses pr. fan revolution (Pulses pr. Revolution).",
        "type": "int",
        "min": 1,
        "max": 99,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "Pulses/Rev.",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "822": {
        "id": 822,
        "name": "ef_ppr",
        "name_orig": "EF PPR",
        "desc": "Number of tacho pulses pr. fan revolution (Pulses pr. Revolution).",
        "type": "int",
        "min": 1,
        "max": 99,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "Pulses/Rev.",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "823": {
        "id": 823,
        "name": "sf_model",
        "name_orig": "SF Model",
        "desc": "This value is used to identify the installed fan.\nIn units produced before the introduction of this parameter, the parameter will be set to 0, during firmware update, which corresponds to unknown fan.\n2 = ebm-papst, 3 = Ziehl-Abegg, 4 = Belmont, 5 = Baluberg, 6 = CEBI, 7 = AFL Motors, 8 = Ecofit, 9 = Alternativ 1, 10 = Alternativ 2.",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "824": {
        "id": 824,
        "name": "ef_model",
        "name_orig": "EF Model",
        "desc": "This value is used to identify the installed fan.\nIn units produced before the introduction of this parameter, the parameter will be set to 0, during firmware update, which corresponds to unknown fan.\n2 = ebm-papst, 3 = Ziehl-Abegg, 4 = Belmont, 5 = Baluberg, 6 = CEBI, 7 = AFL Motors, 8 = Ecofit, 9 = Alternativ 1, 10 = Alternativ 2.",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Fan"
        ]
    },
    "825": {
        "id": 825,
        "name": "enthalpy_a_coefficient",
        "name_orig": "Enthalpy A Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "826": {
        "id": 826,
        "name": "enthalpy_b_coefficient",
        "name_orig": "Enthalpy B Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "827": {
        "id": 827,
        "name": "enthalpy_c_coefficient",
        "name_orig": "Enthalpy C Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "828": {
        "id": 828,
        "name": "enthalpy_d_coefficient",
        "name_orig": "Enthalpy D Coefficient",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "829": {
        "id": 829,
        "name": "enthalpy_safety_margin",
        "name_orig": "Enthalpy Safety Margin",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 16384,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "830": {
        "id": 830,
        "name": "enthalpy_threshold",
        "name_orig": "Enthalpy Threshold",
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "default": 15360,
        "showInTechnical": true,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Low Temp"
        ]
    },
    "831": {
        "id": 831,
        "name": "cc_condensate_severity",
        "name_orig": "CC Condensate Severity",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "Settings"
        ],
        "options": {
            "0": "Warning",
            "1": "Alarm"
        }
    },
    "832": {
        "id": 832,
        "name": "mode_NAMEDUPLICATE_OF_239",
        "name_orig": "Mode",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ],
        "options": {
            "0": "TC",
            "1": "VC"
        }
    },
    "833": {
        "id": 833,
        "name": "tc_runtime",
        "name_orig": "TC: Runtime",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "sec",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ]
    },
    "834": {
        "id": 834,
        "name": "tc_open",
        "name_orig": "TC: Open",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ],
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "835": {
        "id": 835,
        "name": "tc_cw_rotation_NAMEDUPLICATE_OF_603",
        "name_orig": "TC: CW Rotation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "836": {
        "id": 836,
        "name": "tc_ccw_rotation_NAMEDUPLICATE_OF_604",
        "name_orig": "TC: CCW Rotation",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "default": 1,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ],
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "837": {
        "id": 837,
        "name": "tc_self_calibration_NAMEDUPLICATE_OF_607",
        "name_orig": "TC: Self Calibration",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 30000,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ]
    },
    "838": {
        "id": 838,
        "name": "tc_cw_offset_NAMEDUPLICATE_OF_608",
        "name_orig": "TC: CW Offset",
        "desc": "",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ]
    },
    "839": {
        "id": 839,
        "name": "tc_ccw_offset_NAMEDUPLICATE_OF_609",
        "name_orig": "TC: CCW Offset",
        "desc": "",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "ms",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ]
    },
    "840": {
        "id": 840,
        "name": "vc_min_position",
        "name_orig": "VC: Min Position",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ]
    },
    "841": {
        "id": 841,
        "name": "vc_max_position",
        "name_orig": "VC: Max Position",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 10,
        "showInTechnical": true,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ]
    },
    "842": {
        "id": 842,
        "name": "engage",
        "name_orig": "Engage",
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Hardware Setup",
            "Comfort Cooling",
            "CC Water"
        ],
        "options": {
            "0": "False",
            "1": "True"
        }
    },
    "843": {
        "id": 843,
        "name": "ch_pid_adaptive_max_flow_pct_low",
        "name_orig": "CH PID Adaptive Max Flow pct Low",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "844": {
        "id": 844,
        "name": "ch_pid_adaptive_max_flow_pct_high",
        "name_orig": "CH PID Adaptive Max Flow pct High",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "845": {
        "id": 845,
        "name": "ch_pid_adaptive_max_ch_pct_low",
        "name_orig": "CH PID Adaptive Max CH pct Low",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "846": {
        "id": 846,
        "name": "ch_pid_adaptive_max_ch_pct_high",
        "name_orig": "CH PID Adaptive Max CH pct High",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": true,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [
            "Process Settings",
            "Heating",
            "Comfort Heater"
        ]
    },
    "1000": {
        "id": 1000,
        "name": "room_temperature",
        "name_orig": "Room Temperature",
        "desc": "RT - Room Temperature",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1001": {
        "id": 1001,
        "name": "inlet_temperature",
        "name_orig": "Inlet Temperature",
        "desc": "IT - Inlet Temperature",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1002": {
        "id": 1002,
        "name": "outside_temperature",
        "name_orig": "Outside Temperature",
        "desc": "OT - Outside Temperature",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1003": {
        "id": 1003,
        "name": "general_purpose_temperature",
        "name_orig": "General Purpose Temperature",
        "desc": "GPT - General Purpose Temperature",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1004": {
        "id": 1004,
        "name": "evaporator_temperature",
        "name_orig": "Evaporator Temperature",
        "desc": "EvT - Evaporator Temperature",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1005": {
        "id": 1005,
        "name": "condenser_temperature",
        "name_orig": "Condenser Temperature",
        "desc": "CdT - Condenser Temperature",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1006": {
        "id": 1006,
        "name": "outside_temperature_ventilation",
        "name_orig": "Outside Temperature Ventilation",
        "desc": "OTV - Outside Temperature Ventilation",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1007": {
        "id": 1007,
        "name": "exhaust_temperature_ventilation",
        "name_orig": "Exhaust Temperature Ventilation",
        "desc": "ETV - Exhaust Temperature Ventilation",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1008": {
        "id": 1008,
        "name": "a_bms_temperature",
        "name_orig": "A-BMS Temperature",
        "desc": "A-BMS Temperature",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1009": {
        "id": 1009,
        "name": "co2_level_room",
        "name_orig": "CO2 Level Room",
        "desc": "CO2 Level calculated from 0-10V input and 'I2C (J6)'",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1010": {
        "id": 1010,
        "name": "internal_co2_sensor",
        "name_orig": "Internal CO2 Sensor",
        "desc": "CO2 sensor on i2c bus (J6)",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1011": {
        "id": 1011,
        "name": "a_co2_1",
        "name_orig": "A-CO2 #1",
        "desc": "Analog CO2 Input #1",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1012": {
        "id": 1012,
        "name": "a_co2_2",
        "name_orig": "A-CO2 #2",
        "desc": "Analog CO2 Input #2",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1013": {
        "id": 1013,
        "name": "a_co2_3",
        "name_orig": "A-CO2 #3",
        "desc": "Analog CO2 Input #3",
        "type": "int",
        "min": 0,
        "max": 5000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "ppm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1014": {
        "id": 1014,
        "name": "a_bms_flow_percent",
        "name_orig": "A-BMS Flow Percent",
        "desc": "A-BMS Flow Percent",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1015": {
        "id": 1015,
        "name": "comfort_heater_percent",
        "name_orig": "Comfort Heater Percent",
        "desc": "Comfort Heater Percent",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1016": {
        "id": 1016,
        "name": "bypass_damper_percent",
        "name_orig": "Bypass Damper Percent",
        "desc": "Bypass Damper Percent",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1017": {
        "id": 1017,
        "name": "adaptive_airflow",
        "name_orig": "Adaptive Airflow",
        "desc": "Adaptive Airflow Voltage",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1018": {
        "id": 1018,
        "name": "cooling_percent",
        "name_orig": "Cooling Percent",
        "desc": "Cooling Percent",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1019": {
        "id": 1019,
        "name": "pre_heater_percent",
        "name_orig": "Pre Heater Percent",
        "desc": "Pre Heater Percent",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1020": {
        "id": 1020,
        "name": "supply_fan_voltage",
        "name_orig": "Supply Fan Voltage",
        "desc": "0-10V_out Supply Fan",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1021": {
        "id": 1021,
        "name": "extraction_fan_voltage",
        "name_orig": "Extraction Fan Voltage",
        "desc": "0-10V_out Extraction Fan",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1022": {
        "id": 1022,
        "name": "recirculation_fan_voltage",
        "name_orig": "Recirculation Fan Voltage",
        "desc": "0-10V_out Recirculation Fan",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1023": {
        "id": 1023,
        "name": "supply_flow",
        "name_orig": "Supply Flow",
        "desc": "Supply Flow",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1024": {
        "id": 1024,
        "name": "extraction_flow",
        "name_orig": "Extraction Flow",
        "desc": "Extraction Flow",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1025": {
        "id": 1025,
        "name": "supply_flow_1",
        "name_orig": "Supply Flow #1",
        "desc": "Supply Flow #1",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1026": {
        "id": 1026,
        "name": "supply_flow_2",
        "name_orig": "Supply Flow #2",
        "desc": "Supply Flow #2 'I2C (J6)'",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "m³/h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1027": {
        "id": 1027,
        "name": "supply_fan_rpm",
        "name_orig": "Supply Fan RPM",
        "desc": "Supply Fan Tacho [RPM]",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1028": {
        "id": 1028,
        "name": "extraction_fan_rpm",
        "name_orig": "Extraction Fan RPM",
        "desc": "Extraction Fan Tacho [RPM]",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1029": {
        "id": 1029,
        "name": "recirculation_fan_rpm",
        "name_orig": "Recirculation Fan RPM",
        "desc": "Recirculation Fan Tacho [RPM]",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "rpm",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1030": {
        "id": 1030,
        "name": "requested_temperature",
        "name_orig": "Requested Temperature",
        "desc": "Current Temperature Setpoint",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1031": {
        "id": 1031,
        "name": "requested_flow",
        "name_orig": "Requested Flow",
        "desc": "Current Flow Setpoint",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1032": {
        "id": 1032,
        "name": "relative_humidity_supply_air",
        "name_orig": "Relative Humidity Supply Air",
        "desc": "Relative Humidity Supply Air",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1033": {
        "id": 1033,
        "name": "relative_humidity_extraction_air",
        "name_orig": "Relative Humidity Extraction Air",
        "desc": "Relative Humidity Extraction Air",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1034": {
        "id": 1034,
        "name": "system_mode",
        "name_orig": "System Mode",
        "desc": "System Mode",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Stopped",
            "1": "Starting",
            "2": "Auto",
            "3": "Stopping",
            "4": "Filter Test Running",
            "5": "Filter Calibration",
            "6": "Night Cool",
            "7": "Holiday Mode",
            "8": "Manual Mode",
            "9": "System Test",
            "255": "Unknown  Mode"
        }
    },
    "1035": {
        "id": 1035,
        "name": "system_condition",
        "name_orig": "System Condition:",
        "desc": "System Condition",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "low_temp_process",
                "name_orig": "Low Temp Process",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "high_temp_process",
                "name_orig": "High Temp Process",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "condensation_process",
                "name_orig": "Condensation Process",
                "pos": "Active",
                "neg": "Inactive"
            },
            {
                "name": "non_critical_hardware_fault",
                "name_orig": "Non Critical Hardware Fault",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "condenser_overheated",
                "name_orig": "Condenser Overheated",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "compressor_locked",
                "name_orig": "Compressor Locked",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "filter_change_needed",
                "name_orig": "Filter Change Needed",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "high_room_temp",
                "name_orig": "High Room Temp",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "abnormal_filter_test_calibration_result",
                "name_orig": "Abnormal Filter Test Calibration Result",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "manual_override_active",
                "name_orig": "Manual Override Active",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "comfort_cool_defrost_warning",
                "name_orig": "Comfort Cool Defrost Warning",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "comfort_cool_condensation_warning",
                "name_orig": "Comfort Cool Condensation Warning",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "boost_mode_active",
                "name_orig": "Boost Mode Active",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "comfort_cool_hotgas_warning",
                "name_orig": "Comfort Cool Hotgas Warning",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "comfort_cool_pressure_warning",
                "name_orig": "Comfort Cool Pressure Warning",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "group_master_not_available_warning",
                "name_orig": "Group Master Not Available Warning",
                "pos": "True",
                "neg": "False"
            }
        ]
    },
    "1036": {
        "id": 1036,
        "name": "system_alarm",
        "name_orig": "System Alarm:",
        "desc": "System Alarm",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "low_temp_alarm",
                "name_orig": "Low Temp Alarm",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "condensation_alarm",
                "name_orig": "Condensation Alarm",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "filter_alarm",
                "name_orig": "Filter Alarm",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "critical_hardware_fault",
                "name_orig": "Critical Hardware Fault",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "cc_condensation_alarm",
                "name_orig": "CC Condensation Alarm",
                "pos": "True",
                "neg": "False"
            }
        ]
    },
    "1037": {
        "id": 1037,
        "name": "am_float_switch",
        "name_orig": "AM Float Switch",
        "desc": "AM Float Switch",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1038": {
        "id": 1038,
        "name": "manuel_start",
        "name_orig": "Manuel Start",
        "desc": "Manuel Start",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1039": {
        "id": 1039,
        "name": "cc_float_switch",
        "name_orig": "CC Float switch",
        "desc": "Comfort Cool Float Switch",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1040": {
        "id": 1040,
        "name": "a_bms_start",
        "name_orig": "A-BMS Start",
        "desc": "A-BMS Start",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1041": {
        "id": 1041,
        "name": "pir",
        "name_orig": "PIR",
        "desc": "PIR",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1042": {
        "id": 1042,
        "name": "alarm_input",
        "name_orig": "Alarm Input",
        "desc": "Alarm Input (e.g. Fire Alarm)",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1043": {
        "id": 1043,
        "name": "recirculation_damper",
        "name_orig": "Recirculation Damper",
        "desc": "Recirculation Damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1044": {
        "id": 1044,
        "name": "main_air_damper",
        "name_orig": "Main Air Damper",
        "desc": "Main Air Damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "Off",
            "1": "On"
        }
    },
    "1045": {
        "id": 1045,
        "name": "general_warning_id_0_15",
        "name_orig": "General warning id 0-15",
        "desc": "General warning id 0-15",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "warning_id_0",
                "name_orig": "Warning ID 0",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_1",
                "name_orig": "Warning ID 1",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_2",
                "name_orig": "Warning ID 2",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_3",
                "name_orig": "Warning ID 3",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_4",
                "name_orig": "Warning ID 4",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_5",
                "name_orig": "Warning ID 5",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_6",
                "name_orig": "Warning ID 6",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_7",
                "name_orig": "Warning ID 7",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_8",
                "name_orig": "Warning ID 8",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_9",
                "name_orig": "Warning ID 9",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_10",
                "name_orig": "Warning ID 10",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_11",
                "name_orig": "Warning ID 11",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_12",
                "name_orig": "Warning ID 12",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_13",
                "name_orig": "Warning ID 13",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_14",
                "name_orig": "Warning ID 14",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_15",
                "name_orig": "Warning ID 15",
                "pos": "True",
                "neg": "False"
            }
        ]
    },
    "1046": {
        "id": 1046,
        "name": "general_warning_id_16_31",
        "name_orig": "General warning id 16-31",
        "desc": "General warning id 16-31",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "warning_id_16",
                "name_orig": "Warning ID 16",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_17",
                "name_orig": "Warning ID 17",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_18",
                "name_orig": "Warning ID 18",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_19",
                "name_orig": "Warning ID 19",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_20",
                "name_orig": "Warning ID 20",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_21",
                "name_orig": "Warning ID 21",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_22",
                "name_orig": "Warning ID 22",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_23",
                "name_orig": "Warning ID 23",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_24",
                "name_orig": "Warning ID 24",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_25",
                "name_orig": "Warning ID 25",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_26",
                "name_orig": "Warning ID 26",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_27",
                "name_orig": "Warning ID 27",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_28",
                "name_orig": "Warning ID 28",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_29",
                "name_orig": "Warning ID 29",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_30",
                "name_orig": "Warning ID 30",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "warning_id_31",
                "name_orig": "Warning ID 31",
                "pos": "True",
                "neg": "False"
            }
        ]
    },
    "1047": {
        "id": 1047,
        "name": "general_alarm_id_0_15",
        "name_orig": "General alarm id 0-15",
        "desc": "General alarm id 0-15",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "alarm_id_0",
                "name_orig": "Alarm ID 0",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_1",
                "name_orig": "Alarm ID 1",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_2",
                "name_orig": "Alarm ID 2",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_3",
                "name_orig": "Alarm ID 3",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_4",
                "name_orig": "Alarm ID 4",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_5",
                "name_orig": "Alarm ID 5",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_6",
                "name_orig": "Alarm ID 6",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_7",
                "name_orig": "Alarm ID 7",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_8",
                "name_orig": "Alarm ID 8",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_9",
                "name_orig": "Alarm ID 9",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_10",
                "name_orig": "Alarm ID 10",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_11",
                "name_orig": "Alarm ID 11",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_12",
                "name_orig": "Alarm ID 12",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_13",
                "name_orig": "Alarm ID 13",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_14",
                "name_orig": "Alarm ID 14",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_15",
                "name_orig": "Alarm ID 15",
                "pos": "True",
                "neg": "False"
            }
        ]
    },
    "1048": {
        "id": 1048,
        "name": "general_alarm_id_16_31",
        "name_orig": "General alarm id 16-31",
        "desc": "General alarm id 16-31",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "alarm_id_16",
                "name_orig": "Alarm ID 16",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_17",
                "name_orig": "Alarm ID 17",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_18",
                "name_orig": "Alarm ID 18",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_19",
                "name_orig": "Alarm ID 19",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_20",
                "name_orig": "Alarm ID 20",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_21",
                "name_orig": "Alarm ID 21",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_22",
                "name_orig": "Alarm ID 22",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_23",
                "name_orig": "Alarm ID 23",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_24",
                "name_orig": "Alarm ID 24",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_25",
                "name_orig": "Alarm ID 25",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_26",
                "name_orig": "Alarm ID 26",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_27",
                "name_orig": "Alarm ID 27",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_28",
                "name_orig": "Alarm ID 28",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_29",
                "name_orig": "Alarm ID 29",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_30",
                "name_orig": "Alarm ID 30",
                "pos": "True",
                "neg": "False"
            },
            {
                "name": "alarm_id_31",
                "name_orig": "Alarm ID 31",
                "pos": "True",
                "neg": "False"
            }
        ]
    },
    "1049": {
        "id": 1049,
        "name": "filter_remaining_service_life_days",
        "name_orig": "Filter, remaining service life [days]",
        "desc": "Remaining service life of filters in days",
        "type": "int",
        "min": 0,
        "max": 1000,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "days",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1050": {
        "id": 1050,
        "name": "air_handling_unit_power_consumption",
        "name_orig": "Air Handling Unit Power Consumption",
        "desc": "Air Handling Unit Power Consumption",
        "type": "int",
        "min": 0,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1051": {
        "id": 1051,
        "name": "air_handling_unit_power_consumption_decimal_points",
        "name_orig": "Air Handling Unit Power Consumption decimal points",
        "desc": "Air Handling Unit Power Consumption decimal points [kWh]",
        "type": "decimal",
        "min": 0,
        "max": 0.999,
        "res": 0.001,
        "default": 0,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1052": {
        "id": 1052,
        "name": "utc_time_in_seconds",
        "name_orig": "UTC Time in seconds",
        "desc": "Seconds since 1/1-2008 00:00:00 Zulu",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "s",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1053": {
        "id": 1053,
        "name": "air_handling_unit_power_consumption_NAMEDUPLICATE_OF_1050",
        "name_orig": "Air Handling Unit Power Consumption",
        "desc": "Air Handling Unit Power Consumption",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "Wh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1054": {
        "id": 1054,
        "name": "remaining_service_life_percent",
        "name_orig": "Remaining Service Life [%]",
        "desc": "Remaining service life of filters in %",
        "type": "int",
        "min": 0,
        "max": 101,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1055": {
        "id": 1055,
        "name": "remaining_service_life_hours",
        "name_orig": "Remaining Service Life [Hours]",
        "desc": "Remaining service life of filters in operating hours",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "h",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1056": {
        "id": 1056,
        "name": "evaporator_in_temperature",
        "name_orig": "Evaporator In Temperature",
        "desc": "",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1057": {
        "id": 1057,
        "name": "evaporator_out_temperature",
        "name_orig": "Evaporator Out Temperature",
        "desc": "",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1058": {
        "id": 1058,
        "name": "hotgas_temperature",
        "name_orig": "Hotgas Temperature",
        "desc": "",
        "type": "decimal",
        "min": -49,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "°C",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1059": {
        "id": 1059,
        "name": "stepper_valve_opening",
        "name_orig": "Stepper Valve Opening",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1060": {
        "id": 1060,
        "name": "analog_in_1",
        "name_orig": "Analog in 1",
        "desc": "0-10V input 1",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1061": {
        "id": 1061,
        "name": "analog_in_2",
        "name_orig": "Analog in 2",
        "desc": "0-10V input 2",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1062": {
        "id": 1062,
        "name": "analog_in_3",
        "name_orig": "Analog in 3",
        "desc": "0-10V input 3",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "V",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1063": {
        "id": 1063,
        "name": "hardware_errors",
        "name_orig": "Hardware errors",
        "desc": "",
        "type": "bitmask",
        "min": 0,
        "max": 4294967295,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "room_temperaturesensor",
                "name_orig": "Room Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "inlet_temperaturesensor",
                "name_orig": "Inlet Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "outside_temperaturesensor",
                "name_orig": "Outside Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "general_purpose_temperaturesensor",
                "name_orig": "General Purpose Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "condenser_temperaturesensor",
                "name_orig": "Condenser Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "evaporator_temperaturesensor",
                "name_orig": "Evaporator Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "exhaust_temperaturesensor_ventilation_unit",
                "name_orig": "Exhaust Temperaturesensor Ventilation Unit",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "outside_temperaturesensor_ventilation_unit",
                "name_orig": "Outside Temperaturesensor Ventilation Unit",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "supplyflow_sensor_1",
                "name_orig": "Supplyflow Sensor 1",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "supplyflow_sensor_2",
                "name_orig": "Supplyflow Sensor 2",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "extractionflow_sensor",
                "name_orig": "Extractionflow Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "co2_sensor",
                "name_orig": "CO2 Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "supply_fan",
                "name_orig": "Supply Fan",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "extraction_fan",
                "name_orig": "Extraction Fan",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "evaporator_in_temperaturesensor",
                "name_orig": "Evaporator In Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "evaporator_out_temperaturesensor",
                "name_orig": "Evaporator Out Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "hotgas_temperaturesensor",
                "name_orig": "Hotgas Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "comfort_cooling_connection_lost",
                "name_orig": "Comfort Cooling Connection Lost",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "comfort_cooling_stepdriver",
                "name_orig": "Comfort Cooling Stepdriver",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "comfort_cooling_frequency_inverter",
                "name_orig": "Comfort Cooling Frequency Inverter",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "humidity_supply_air_sensor",
                "name_orig": "Humidity Supply Air Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "humidity_extraction_air_sensor",
                "name_orig": "Humidity Extraction Air Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "humidity_sensor_settings",
                "name_orig": "Humidity Sensor Settings",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "tvoc_sensor",
                "name_orig": "TVOC Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "room_humidity_sensor",
                "name_orig": "Room Humidity Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "digital_building_management_system_module",
                "name_orig": "Digital Building Management System Module",
                "pos": "Fault",
                "neg": "OK"
            }
        ]
    },
    "1064": {
        "id": 1064,
        "name": "bacnet_device_id",
        "name_orig": "Bacnet Device Id",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1065": {
        "id": 1065,
        "name": "cooling_unit_power_consumption",
        "name_orig": "Cooling Unit Power Consumption",
        "desc": "Cooling Unit Power Consumption [kWh]",
        "type": "int",
        "min": 0,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1066": {
        "id": 1066,
        "name": "cooling_unit_power_consumption_decimal_points",
        "name_orig": "Cooling Unit Power Consumption decimal points",
        "desc": "Cooling Unit Power Consumption decimal points [kWh]",
        "type": "decimal",
        "min": 0,
        "max": 0.999,
        "res": 0.001,
        "default": 0,
        "showInTechnical": false,
        "unit": "kWh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1067": {
        "id": 1067,
        "name": "cooling_unit_power_consumption_NAMEDUPLICATE_OF_1065",
        "name_orig": "Cooling Unit Power Consumption",
        "desc": "Cooling Unit Power Consumption",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "Wh",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1068": {
        "id": 1068,
        "name": "supply_air_pressure_difference",
        "name_orig": "Supply air pressure difference",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 550,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1069": {
        "id": 1069,
        "name": "extracton_air_pressure_difference",
        "name_orig": "Extracton air pressure difference",
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 550,
        "res": 0.1,
        "default": 0,
        "showInTechnical": false,
        "unit": "Pa",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1070": {
        "id": 1070,
        "name": "simplified_operating_mode",
        "name_orig": "Simplified Operating Mode",
        "desc": "Simplified indication of current operating mode",
        "type": "enum",
        "min": 0,
        "max": 5,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "OFF",
            "1": "Standby",
            "2": "Manual Start",
            "3": "Holiday Mode",
            "4": "Auto (Standby)",
            "5": "Auto (Running)"
        }
    },
    "1071": {
        "id": 1071,
        "name": "hardware_errors_lsb",
        "name_orig": "Hardware Errors LSB",
        "desc": "",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "room_temperaturesensor",
                "name_orig": "Room Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "inlet_temperaturesensor",
                "name_orig": "Inlet Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "outside_temperaturesensor",
                "name_orig": "Outside Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "general_purpose_temperaturesensor",
                "name_orig": "General Purpose Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "condenser_temperaturesensor",
                "name_orig": "Condenser Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "evaporator_temperaturesensor",
                "name_orig": "Evaporator Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "exhaust_temperaturesensor_ventilation_unit",
                "name_orig": "Exhaust Temperaturesensor Ventilation Unit",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "outside_temperaturesensor_ventilation_unit",
                "name_orig": "Outside Temperaturesensor Ventilation Unit",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "supplyflow_sensor_1",
                "name_orig": "Supplyflow Sensor 1",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "supplyflow_sensor_2",
                "name_orig": "Supplyflow Sensor 2",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "extractionflow_sensor",
                "name_orig": "Extractionflow Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "co2_sensor",
                "name_orig": "CO2 Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "supply_fan",
                "name_orig": "Supply Fan",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "extraction_fan",
                "name_orig": "Extraction Fan",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "evaporator_in_temperaturesensor",
                "name_orig": "Evaporator In Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "evaporator_out_temperaturesensor",
                "name_orig": "Evaporator Out Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            }
        ]
    },
    "1072": {
        "id": 1072,
        "name": "hardware_errors_msb",
        "name_orig": "Hardware Errors MSB",
        "desc": "",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "hotgas_temperaturesensor",
                "name_orig": "Hotgas Temperaturesensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "comfort_cooling_connection_lost",
                "name_orig": "Comfort Cooling Connection Lost",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "comfort_cooling_stepdriver",
                "name_orig": "Comfort Cooling Stepdriver",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "comfort_cooling_frequency_inverter",
                "name_orig": "Comfort Cooling Frequency Inverter",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "humidity_supply_air_sensor",
                "name_orig": "Humidity Supply Air Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "humidity_extraction_air_sensor",
                "name_orig": "Humidity Extraction Air Sensor",
                "pos": "Fault",
                "neg": "OK"
            },
            {
                "name": "humidity_sensor_settings",
                "name_orig": "Humidity Sensor Settings",
                "pos": "Fault",
                "neg": "OK"
            }
        ]
    },
    "1073": {
        "id": 1073,
        "name": "air_handling_unit_current_power_consumption",
        "name_orig": "Air Handling Unit Current Power Consumption",
        "desc": "",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "W",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1074": {
        "id": 1074,
        "name": "air_quality_tvoc",
        "name_orig": "Air quality TVOC",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "ppb",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1075": {
        "id": 1075,
        "name": "air_quality_raw_signal",
        "name_orig": "Air quality RAW signal",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1076": {
        "id": 1076,
        "name": "rqf_prodco2",
        "name_orig": "RQF_ProdCO2",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1077": {
        "id": 1077,
        "name": "rqf_prodtvoc",
        "name_orig": "RQF_ProdTVOC",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "%",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1078": {
        "id": 1078,
        "name": "started_by_NAMEDUPLICATE_OF_96",
        "name_orig": "Started By",
        "desc": "Started By: Contains the Highest active start priority",
        "type": "enum",
        "min": 0,
        "max": 14,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": {
            "0": "off",
            "1": "A-BMS",
            "2": "Panel",
            "3": "PIR",
            "4": "External start",
            "5": "CO2",
            "6": "Dependent",
            "7": "Halted",
            "8": "0-10V",
            "9": "D-BMS",
            "10": "Timer",
            "11": "Boost",
            "12": "TVOC",
            "13": "Room temperature",
            "14": "Airlinq Online"
        }
    },
    "1800": {
        "id": 1800,
        "name": "various_bits",
        "name_orig": "Various bits",
        "desc": "Various bits for logging",
        "type": "bitmask",
        "min": -32768,
        "max": 32767,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": [],
        "options": [
            {
                "name": "am_float_switch",
                "name_orig": "AM Float Switch",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "manuel_start",
                "name_orig": "Manuel Start",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "comfort_cool_float_switch",
                "name_orig": "Comfort Cool Float Switch",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "analog_bms_start",
                "name_orig": "Analog BMS Start",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "pir",
                "name_orig": "PIR",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "alarm_input",
                "name_orig": "Alarm Input",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "recirculation_damper",
                "name_orig": "Recirculation Damper",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "main_damper",
                "name_orig": "Main Damper",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "run_signal_normal_ventilation",
                "name_orig": "Run Signal Normal Ventilation",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "run_signal_nightcooling",
                "name_orig": "Run Signal Nightcooling",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "run_signal_basic_ventilation",
                "name_orig": "Run Signal Basic Ventilation",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "run_signal_slaves",
                "name_orig": "Run Signal Slaves",
                "pos": "On",
                "neg": "Off"
            },
            {
                "name": "execute_filtertest_on_startup",
                "name_orig": "Execute FilterTest On Startup",
                "pos": "True",
                "neg": "False"
            }
        ]
    },
    "1801": {
        "id": 1801,
        "name": "disconnect_count",
        "name_orig": "Disconnect count",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1802": {
        "id": 1802,
        "name": "last_disconnect_time",
        "name_orig": "Last disconnect time",
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": 1,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1900": {
        "id": 1900,
        "name": "pid_sf_ref",
        "name_orig": "PID_SF_REF",
        "desc": "PID Controller - Supply Fan - REFERENCE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1901": {
        "id": 1901,
        "name": "pid_sf_sen",
        "name_orig": "PID_SF_SEN",
        "desc": "PID Controller - Supply Fan - SENSOR",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1902": {
        "id": 1902,
        "name": "pid_sf_err",
        "name_orig": "PID_SF_ERR",
        "desc": "PID Controller - Supply Fan - ERROR",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1903": {
        "id": 1903,
        "name": "pid_sf_out",
        "name_orig": "PID_SF_OUT",
        "desc": "PID Controller - Supply Fan - OUTPUT",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1904": {
        "id": 1904,
        "name": "pid_sf_a",
        "name_orig": "PID_SF_A",
        "desc": "PID Controller - Supply Fan - A_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1905": {
        "id": 1905,
        "name": "pid_sf_p",
        "name_orig": "PID_SF_P",
        "desc": "PID Controller - Supply Fan - P_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1906": {
        "id": 1906,
        "name": "pid_sf_i",
        "name_orig": "PID_SF_I",
        "desc": "PID Controller - Supply Fan - I_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1907": {
        "id": 1907,
        "name": "pid_sf_d",
        "name_orig": "PID_SF_D",
        "desc": "PID Controller - Supply Fan - D_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1908": {
        "id": 1908,
        "name": "pid_ef_ref",
        "name_orig": "PID_EF_REF",
        "desc": "PID Controller - Extraction Fan - REFERENCE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1909": {
        "id": 1909,
        "name": "pid_ef_sen",
        "name_orig": "PID_EF_SEN",
        "desc": "PID Controller - Extraction Fan - SENSOR",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1910": {
        "id": 1910,
        "name": "pid_ef_err",
        "name_orig": "PID_EF_ERR",
        "desc": "PID Controller - Extraction Fan - ERROR",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1911": {
        "id": 1911,
        "name": "pid_ef_out",
        "name_orig": "PID_EF_OUT",
        "desc": "PID Controller - Extraction Fan - OUTPUT",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1912": {
        "id": 1912,
        "name": "pid_ef_a",
        "name_orig": "PID_EF_A",
        "desc": "PID Controller - Extraction Fan - A_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1913": {
        "id": 1913,
        "name": "pid_ef_p",
        "name_orig": "PID_EF_P",
        "desc": "PID Controller - Extraction Fan - P_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1914": {
        "id": 1914,
        "name": "pid_ef_i",
        "name_orig": "PID_EF_I",
        "desc": "PID Controller - Extraction Fan - I_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    },
    "1915": {
        "id": 1915,
        "name": "pid_ef_d",
        "name_orig": "PID_EF_D",
        "desc": "PID Controller - Extraction Fan - D_VALUE",
        "type": "decimal",
        "min": -1000000,
        "max": 1000000,
        "res": 0.01,
        "default": 0,
        "showInTechnical": false,
        "unit": "",
        "scope": "INDIVIDUAL",
        "guiGroupNameList": []
    }
}

export const paramNames = {
    "rqf": 1,
    "rqt": 2,
    "default_airflow": 3,
    "default_temperature": 4,
    "loop1": 6,
    "user_tool_access_level": 7,
    "temp_0_2v": 8,
    "temp_2_4v": 9,
    "temp_4_6v": 10,
    "temp_6_8v": 11,
    "temp_8_10v": 12,
    "bp_installed": 13,
    "bp_engage": 14,
    "temp_diff": 15,
    "min_temp": 16,
    "ht_rt_flow_increment": 17,
    "loop2": 18,
    "t_measure": 19,
    "interval": 20,
    "wait": 21,
    "log_mode": 22,
    "cool_flow": 23,
    "high_room_temperature_high_limit": 24,
    "high_room_temperature_low_limit": 25,
    "bp_fan_cor": 26,
    "pir_afterrun_time": 27,
    "rt_timer": 28,
    "it_hyst": 29,
    "co2_sensor_installed": 30,
    "rt_diff_high": 31,
    "rt_diff_low": 32,
    "ot_rt_diff": 33,
    "exit_timer": 34,
    "loop3": 35,
    "measuring_time": 36,
    "bp_initial": 37,
    "t_evaporate": 38,
    "supply_airflow_reduction": 39,
    "t_soft_start": 40,
    "pid_log": 41,
    "bp_final": 42,
    "bp_cycles": 43,
    "extp_initial": 44,
    "allow_start_by_external_start": 45,
    "not_in_use": 46,
    "not_in_use_NAMEDUPLICATE_OF_46": 47,
    "humidity_control_installed": 48,
    "humidity_control_enabled": 49,
    "manual_override_time": 50,
    "filter_test_mode": 51,
    "life_span_warning": 52,
    "st_filter_test_max_difference": 53,
    "current_filter_state": 54,
    "life_span_alarm": 55,
    "extp_final": 56,
    "disable_low_temp_alarm": 57,
    "retry_low_temp_alarm": 58,
    "min_fan_voltage": 59,
    "low_inlet": 60,
    "intake_airflow_reduction": 61,
    "min_outlet": 62,
    "wait_low": 63,
    "low_outlet": 64,
    "inlet_increment": 65,
    "outlet_increment": 66,
    "ph__max_etv": 67,
    "ph_sf_reduction": 68,
    "min_inlet": 69,
    "flow_alarm": 70,
    "normal_interval": 71,
    "reboot": 72,
    "st_settling_time": 73,
    "unit_survey_period": 74,
    "nc_it": 75,
    "unit_survey_interval": 76,
    "bp_run_time": 77,
    "nc_min_ot": 78,
    "ch_water_soft_start": 79,
    "nc_flow": 80,
    "nc_high_limit": 81,
    "nc_low_limit": 82,
    "min_flow": 83,
    "language": 84,
    "comfort_heater": 85,
    "hm_flow": 86,
    "hm_temperature": 87,
    "extp_cycles": 88,
    "fan_initial": 89,
    "start_dependency": 90,
    "start_priority_1": 91,
    "start_priority_2": 92,
    "start_priority_3": 93,
    "start_priority_4": 94,
    "start_priority_5": 95,
    "started_by": 96,
    "fan_final": 97,
    "fan_cycles": 98,
    "bp_tc_open": 99,
    "udf": 100,
    "minimum_flow_percent": 101,
    "maximum_flow_percent": 102,
    "udt": 103,
    "temperature_setpoint_minimum": 104,
    "temperature_setpoint_maximum": 105,
    "displacement": 106,
    "normal_ventilation_request": 107,
    "vph_mode": 108,
    "night_cool_request": 109,
    "vph_max": 110,
    "holiday_moderequest": 111,
    "vph_step_size": 112,
    "bp_vc_max_position": 113,
    "bp_tc_cw_rotation": 114,
    "max_volt_sf": 115,
    "bp_tc_ccw_rotation": 116,
    "max_volt_ef": 117,
    "co2_min": 118,
    "co2_max": 119,
    "total_operating_hours": 120,
    "operating_hours_since_last_service": 121,
    "co2_sensor_ppm_range_min": 122,
    "co2_sensor_ppm_range_max": 123,
    "co2_sensor_volt_range_min": 124,
    "co2_sensor_volt_range_max": 125,
    "bp_vc_min_position": 126,
    "test_program_time": 127,
    "retries": 128,
    "pid_sample_t": 129,
    "cc_icc_otv_rqt_temp_difference": 130,
    "cc_icc_min_cooling_time": 131,
    "cc_icc_temperature_timer": 132,
    "cc_hg_temperature_max": 133,
    "sv_total_steps": 134,
    "sv_calibration_steps": 135,
    "sv_moves_between_zeroing": 136,
    "sv_steps_per_second": 137,
    "sv_step_size": 138,
    "sv_open_direction_reversed": 139,
    "cc_valve_pid_kp": 140,
    "cc_valve_pid_ki": 141,
    "cc_valve_pid_kd": 142,
    "cc_valve_pid_ka": 143,
    "cc_valve_pid_min_out": 144,
    "cc_valve_pid_max_out": 145,
    "cc_valve_pid_sample_time": 146,
    "cc_valve_pid_reference": 147,
    "cc_icc_pid_kp": 148,
    "cc_icc_pid_ki": 149,
    "cc_icc_pid_kd": 150,
    "cc_icc_pid_ka": 151,
    "cc_icc_pid_sample_time": 152,
    "cc_hg_pid_kp": 153,
    "cc_hg_pid_ki": 154,
    "cc_hg_pid_kd": 155,
    "cc_hg_pid_ka": 156,
    "cc_evt_pid_kp": 157,
    "cc_evt_pid_ki": 158,
    "cc_evt_pid_kd": 159,
    "cc_evt_pid_ka": 160,
    "cc_icc_mode": 161,
    "cc_icc_inverter_min_voltage": 162,
    "cc_icc_inverter_max_voltage": 163,
    "cc_icc_inverter_min_frequency": 164,
    "cc_icc_inverter_max_frequency": 165,
    "cc_hg_pid_max_out": 166,
    "cc_evt_pid_max_out": 167,
    "cc_t1": 168,
    "cc_t2": 169,
    "cc_t3": 170,
    "cc_t4": 171,
    "room_1_activation": 174,
    "room_2_activation": 175,
    "valve_position_neutral": 176,
    "valve_position_room_1": 177,
    "valve_position_room_2": 178,
    "humidity_sensor_1_temperature": 179,
    "humidity_sensor_2_temperature": 180,
    "fixed_displacement": 190,
    "state_bitfield": 197,
    "masterslave_startstop": 198,
    "unit_alarm_status_bitfield": 199,
    "bp_tc_self_calibration": 203,
    "min_volt_sf": 204,
    "st_meas_time": 205,
    "st_sfstdtacho": 206,
    "min_volt_ef": 207,
    "st_force_interval": 208,
    "md_run_time": 209,
    "flow_measurements_to_average": 210,
    "md_tc_open": 211,
    "flow_hourlog": 212,
    "st_test_flow": 213,
    "st_efstdtacho": 214,
    "st_start_time": 215,
    "qsa_pid_kp": 216,
    "qsa_pid_ki": 217,
    "qsa_pid_kd": 218,
    "qsa_pid_ka": 219,
    "md_tc_cw_rot": 220,
    "md_tc_ccw_rot": 221,
    "night_cool_independent": 222,
    "qea_pid_kp": 223,
    "qea_pid_ki": 224,
    "qea_pid_kd": 225,
    "qea_pid_ka": 226,
    "supply_fan_tacho_signal_at_last_\"filter_test\"": 227,
    "extraction_fan_tacho_signal_at_last_\"filter_test\"": 228,
    "nominal_max_flow": 229,
    "bp_tc_cw_offset": 230,
    "bp_tc_ccw_offset": 231,
    "pre_heater": 232,
    "md_mode": 233,
    "high_temperature_delay": 234,
    "electrical_heater_delay": 235,
    "electrical_heater_increase_flow": 236,
    "electrical_heater_min_flow": 237,
    "electrical_heater_min_tacho": 238,
    "mode": 239,
    "errorcodes_0_3": 240,
    "errorcodes_4_7": 241,
    "errorcodes_8_11": 242,
    "errorcodes_12_15": 243,
    "halt": 244,
    "power_failure___system_restore_state_unit_running_and_display_locked_bit_field": 247,
    "reset_active_errors": 249,
    "air_handling_units": 250,
    "actual_number_of_air_handling_units": 251,
    "show_startup_guide": 252,
    "software_version": 255,
    "whether_filter_test_should_be_forced_during_startup_or_not": 256,
    "communication_id": 257,
    "group_id": 258,
    "group_0_master": 259,
    "group_1_master": 260,
    "group_2_master": 261,
    "group_3_master": 262,
    "group_4_master": 263,
    "group_5_master": 264,
    "group_6_master": 265,
    "group_7_master": 266,
    "group_8_master": 267,
    "group_9_master": 268,
    "group_10_master": 269,
    "group_11_master": 270,
    "group_12_master": 271,
    "group_13_master": 272,
    "group_14_master": 273,
    "group_15_master": 274,
    "group_16_master": 275,
    "group_17_master": 276,
    "group_18_master": 277,
    "group_19_master": 278,
    "stop": 279,
    "t1": 280,
    "t2": 281,
    "t3": 282,
    "t4": 283,
    "t5": 284,
    "t6": 285,
    "t7": 286,
    "t8": 287,
    "ai_1": 290,
    "ai_2": 291,
    "ai_3": 292,
    "ai_4": 293,
    "ai_5": 294,
    "di_1": 296,
    "di_2": 297,
    "di_3": 298,
    "di_4": 299,
    "di_5": 300,
    "di_6": 301,
    "do_1_open_collector": 303,
    "do_2_open_collector": 304,
    "do_3_open_collector": 305,
    "do_4_open_collector": 306,
    "do_5_open_collector": 307,
    "do_k1_relay": 308,
    "do_k2_relay": 309,
    "do_k3_relay": 310,
    "do_k4_relay": 311,
    "do_k5_relay": 312,
    "do_k6_relay": 313,
    "do_k7_relay": 314,
    "do_k8_relay": 315,
    "do_k9_relay": 316,
    "do_k10_relay": 317,
    "do_k11_relay": 318,
    "do_k12_relay": 319,
    "do_led_1": 320,
    "do_led_2": 321,
    "ao_1": 322,
    "ao_2": 323,
    "ao_3": 324,
    "ao_4": 325,
    "ao_5": 326,
    "ao_6": 327,
    "ao_7": 328,
    "tacho_input_1": 329,
    "tacho_input_2": 330,
    "tacho_input_3": 331,
    "panel_flow_function": 333,
    "panel_off_allowed": 334,
    "cc_mode": 335,
    "cc_defrost_exit_temp": 336,
    "cc_min_ot": 337,
    "cc_cdt_alarm_temp": 338,
    "cc_cdt_warning_temp": 339,
    "cc_evt_min___defrost_temp": 340,
    "cc_defrost_warning_time": 341,
    "cc_compressor_wait_time": 342,
    "cc_condensation_time": 343,
    "hc_barometric_pressure_outside": 344,
    "hc_barometric_pressure_room": 345,
    "hc_d_ah_min_a": 346,
    "hc_d_ah_min_b": 347,
    "hc_d_ah_min_c": 348,
    "hc_d_ah_max_a": 349,
    "hc_d_ah_max_b": 350,
    "hc_d_ah_max_c": 351,
    "panel_start_timeout": 352,
    "panel_off_timeout": 353,
    "off_with_timeout_request": 354,
    "end_manual_override_request": 355,
    "airmaster_serial_number_msb": 356,
    "airmaster_serial_number_lsb": 357,
    "hm_supply_air": 358,
    "allow_boost": 359,
    "boost_max_volt_in": 360,
    "boost_max_volt_out": 361,
    "service_address": 362,
    "vph_options": 363,
    "last_filter_change_sec_utc_msb": 364,
    "last_filter_change_sec_utc_lsb": 365,
    "co2_low": 366,
    "co2_mid": 367,
    "start_priority_6": 368,
    "start_priority_7": 369,
    "simulate_mode": 370,
    "simulated_it": 371,
    "simulated_rt": 372,
    "simulated_gpt": 373,
    "simulated_ot": 374,
    "simulated_evt": 375,
    "simulated_cdt": 376,
    "simulated_otv": 377,
    "simulated_etv": 378,
    "simulated_am_float_switch": 379,
    "simulated_cc_float_switch": 380,
    "simulated_external_start_input": 381,
    "simulated_abms_start": 382,
    "simulated_pir_input": 383,
    "simulated_external_stop_input": 384,
    "simulated_abms_temperature_setpoint": 385,
    "simulated_abms_flow_setpoint": 386,
    "simulated_internal_co2": 387,
    "simulated_analog_co2_1": 388,
    "simulated_analog_co2_2": 389,
    "simulated_analog_co2_3": 390,
    "simulated_0_10v_flow": 391,
    "simulated_supply_fan": 392,
    "simulated_extraction_fan": 393,
    "simulated_recirculating_fan": 394,
    "simulated_supply_airflow": 395,
    "simulated_extraction_airflow": 397,
    "simulated_relative_humidity_supply": 398,
    "simulated_relative_humidity_extraction": 399,
    "d_bms_type": 400,
    "modbus_address": 402,
    "modbus_baud_rate": 403,
    "modbus_parity": 404,
    "bacnet_mstp_address": 405,
    "bacnet_mstp_baud_rate": 406,
    "simulated_evt_in": 407,
    "simulated_evt_out": 408,
    "simulated_hotgas_temperature": 409,
    "co2_control_mode": 412,
    "a_bms_defined_temperature": 413,
    "allow_start_by_pir": 414,
    "allow_start_by_co2": 415,
    "allow_start_by_timer": 416,
    "allow_start_by_panel": 417,
    "normal_powerup_count": 420,
    "watchdog_powerup_count": 421,
    "hardfault_count": 422,
    "sf_pid_kp": 423,
    "sf_pid_ki": 424,
    "sf_pid_kd": 425,
    "sf_pid_ka": 426,
    "ef_pid_kp": 427,
    "ef_pid_ki": 428,
    "ef_pid_kd": 429,
    "ef_pid_ka": 430,
    "fan_voltage_control_pid_sample_t": 431,
    "filter_max_life_time_alarm": 432,
    "air_handling_unit_pulseskwh": 433,
    "aqc_p_total_power_consumption_watt_hours_msb": 434,
    "comfort_cooling_pulseskwh": 435,
    "aqc_c_total_power_consumption_watt_hours_msb": 436,
    "rtc_prescaler": 437,
    "timezone___offset_from_utc_in_minutes": 438,
    "automatic_daylight_saving_time": 439,
    "timer_1_days": 440,
    "timer_1_flags": 441,
    "timer_1_start_timer_minutes_since_midnight": 442,
    "timer_1_duration_minutes": 443,
    "timer_1_flow_percent": 444,
    "timer_1_temperature_c": 445,
    "timer_2_days": 446,
    "timer_2_flags": 447,
    "timer_2_start_timer_minutes_since_midnight": 448,
    "timer_2_duration_minutes": 449,
    "timer_2_flow_percent": 450,
    "timer_2_temperature_c": 451,
    "timer_3_days": 452,
    "timer_3_flags": 453,
    "timer_3_start_timer_minutes_since_midnight": 454,
    "timer_3_duration_minutes": 455,
    "timer_3_flow_percent": 456,
    "timer_3_temperature_c": 457,
    "timer_4_days": 458,
    "timer_4_flags": 459,
    "timer_4_start_timer_minutes_since_midnight": 460,
    "timer_4_duration_minutes": 461,
    "timer_4_flow_percent": 462,
    "timer_4_temperature_c": 463,
    "timer_5_days": 464,
    "timer_5_flags": 465,
    "timer_5_start_timer_minutes_since_midnight": 466,
    "timer_5_duration_minutes": 467,
    "timer_5_flow_percent": 468,
    "timer_5_temperature_c": 469,
    "timer_6_days": 470,
    "timer_6_flags": 471,
    "timer_6_start_timer_minutes_since_midnight": 472,
    "timer_6_duration_minutes": 473,
    "timer_6_flow_percent": 474,
    "timer_6_temperature_c": 475,
    "timer_7_days": 476,
    "timer_7_flags": 477,
    "timer_7_start_timer_minutes_since_midnight": 478,
    "timer_7_duration_minutes": 479,
    "timer_7_flow_percent": 480,
    "timer_7_temperature_c": 481,
    "timer_8_night_cool_days": 482,
    "timer_8_night_cool_flags": 483,
    "timer_8_night_cool_start_timer_minutes_since_midnight": 484,
    "timer_8_night_cool_duration_minutes": 485,
    "timer_8_night_cool_flow_percent": 486,
    "timer_8_night_cool_temperature_c": 487,
    "database_version": 488,
    "rtc_calibration": 489,
    "d_bms_auto_vent": 490,
    "d_bms_nightcool": 491,
    "d_bms_holiday_mode": 492,
    "d_bms_airflow": 493,
    "d_bms_temperature": 494,
    "d_bms_co2_ppm": 495,
    "reset_filter_status": 496,
    "run_filter_calibration": 497,
    "start_test_procedure": 498,
    "start_looptest": 499,
    "stop_all_units": 500,
    "operating_mode_request": 501,
    "panel_flow_request_for_d_bms": 502,
    "operating_mode_request_airlinq_online": 503,
    "sf_a_coefficient": 513,
    "sf_b_coefficient": 514,
    "sf_c_coefficient": 515,
    "sf_d_coefficient": 516,
    "sf_e_coefficient": 517,
    "sf_f_coefficient": 518,
    "sf_g_coefficient": 519,
    "sf_h_coefficient": 520,
    "sf_i_coefficient": 521,
    "ef_a_coefficient": 522,
    "ef_b_coefficient": 523,
    "ef_c_coefficient": 524,
    "ef_d_coefficient": 525,
    "ef_e_coefficient": 526,
    "ef_f_coefficient": 527,
    "ef_g_coefficient": 528,
    "ef_h_coefficient": 529,
    "ef_i_coefficient": 530,
    "sf_x1x2_calculation": 531,
    "ef_x1x2_calculation": 532,
    "sf_bypass_opening": 533,
    "ef_bypass_opening": 534,
    "sf_bypass_correction": 535,
    "ef_bypass_correction": 536,
    "st_supply_fan_voltage": 537,
    "st_extraction_fan_voltage": 538,
    "st_supply_fan_dp_reference": 539,
    "st_extraction_fan_dp_reference": 540,
    "cc_icc_subtemp": 541,
    "cc_compressor_max_time_between_master_messages": 542,
    "filter_max_life_time_warning": 543,
    "boost_afterrun_time": 544,
    "boost_auto_off_time": 545,
    "external_stop": 546,
    "sf_calibration": 547,
    "ef_calibration": 548,
    "adaptive_airflow_throw_setpoint": 549,
    "aa_narrow_a": 550,
    "aa_narrow_b": 551,
    "aa_wide_a": 552,
    "aa_wide_b": 553,
    "aa_wide_position": 554,
    "aa_narrow_position": 555,
    "nc_allow_cc": 556,
    "condensate_frost_protection_on_temperature": 557,
    "condensate_frost_protection_hysteresis": 558,
    "ph_pid_kp": 559,
    "ph_pid_ki": 560,
    "ph_pid_kd": 561,
    "ph_pid_ka": 562,
    "ph_pid_min": 563,
    "ph_pid_max": 564,
    "ph_pid_sample_time": 565,
    "ch_pid_kp": 566,
    "ch_pid_ki": 567,
    "ch_pid_kd": 568,
    "ch_pid_ka": 569,
    "ch_pid_min": 570,
    "ch_pid_max": 571,
    "ch_pid_sample_time": 572,
    "ph_pwm_period": 573,
    "ch_pwm_period": 574,
    "group_master_na_warning": 575,
    "pcb_number": 576,
    "pcb_revision": 577,
    "cc_icc_standby_wait_on": 578,
    "cc_icc_standby_wait_off": 579,
    "dp_min": 580,
    "x2_input": 581,
    "dp_scale": 582,
    "tacho_scale": 583,
    "rtmin": 584,
    "auto_calibration_time": 585,
    "ethernet_ip": 586,
    "ethernet_iots_gateway_port_number": 597,
    "it_hysteresis_for_cc_re_init": 598,
    "tc_valve_position_neutral": 599,
    "tc_valve_position_room_1": 600,
    "tc_valve_position_room_2": 601,
    "tc_run_time": 602,
    "tc_cw_rotation": 603,
    "tc_ccw_rotation": 604,
    "tc_0percent_position": 605,
    "tc_calibration_position": 606,
    "tc_self_calibration": 607,
    "tc_cw_offset": 608,
    "tc_ccw_offset": 609,
    "mode_NAMEDUPLICATE_OF_239": 832,
    "off_position": 611,
    "hgt_a_coeff": 612,
    "hgt_b_coeff": 613,
    "screensaver_company_logo_id": 614,
    "screensaver_airquality_mode": 615,
    "screensaver_logo": 616,
    "sl_enabled_time_periode_start_minutes_since_midnight": 617,
    "sl_enabled_time_periode_end_minutes_since_midnight": 618,
    "sl_state_during_normal_operation": 619,
    "bp_fan_cor_a_coefficient": 620,
    "bp_fan_cor_b_coefficient": 621,
    "bp_fan_cor_c_coefficient": 622,
    "bp_fan_cor_low_limit": 623,
    "bp_fan_cor_high_limit": 624,
    "nc_mode": 625,
    "nc_boost_sfv": 626,
    "nc_boost_efv": 627,
    "nc_rtmin": 628,
    "nc_deltat": 629,
    "cc_valve_pid_ke": 630,
    "cc_icc_pid_ke": 631,
    "cc_hg_pid_ke": 632,
    "cc_evt_pid_ke": 633,
    "qsa_pid_ke": 634,
    "qea_pid_ke": 635,
    "sf_pid_ke": 636,
    "ef_pid_ke": 637,
    "ph_pid_ke": 638,
    "ch_pid_ke": 639,
    "control_algorithm_cooling": 640,
    "control_algorithm_heating": 641,
    "control_algorithm_flow_control": 642,
    "self_test_tacho_alarm_count": 643,
    "self_test_tacho_warning_count": 644,
    "self_test_differential_pressure_alarm_count": 645,
    "p_stop": 646,
    "p_sf_total": 647,
    "p_sf": 648,
    "p_ef_total": 649,
    "p_ef": 650,
    "p_fans": 651,
    "dp_sf": 652,
    "q_sf": 653,
    "q_sf_deviation": 654,
    "dp_ef": 655,
    "q_ef": 656,
    "q_ef_deviation": 657,
    "sfp": 658,
    "ch_water_protection_otv": 659,
    "ch_water_protection_rt__it": 660,
    "flash_write_error_count": 661,
    "standby_power": 662,
    "ambient_pressure": 663,
    "r_tvocmin": 664,
    "r_tvocmax": 665,
    "simulated_airquality": 666,
    "fco2": 667,
    "f_tvoc": 668,
    "room_volume": 669,
    "co2_n_avg_now": 670,
    "co2_n_avg_bw": 671,
    "tvoc_n_avg_now": 672,
    "tvoc_n_avg_bw": 673,
    "bp_location": 674,
    "tvoc_control_mode": 675,
    "electric_heater_afterrun": 676,
    "co2_high": 677,
    "co2_warning": 678,
    "tvoc_low": 679,
    "tvoc_mid": 680,
    "tvoc_high": 681,
    "tvoc_warning": 682,
    "tvoc_start": 683,
    "tvoc_sensor_installed": 684,
    "start_priority_8": 685,
    "allow_start_by_tvoc": 686,
    "rh_room_sensor_installed": 687,
    "co2_calibration": 688,
    "co2_start": 689,
    "pir_start_delay": 690,
    "rt_start_offset": 691,
    "screensaver_show_flow": 692,
    "allow_start_by_room_temperatur": 693,
    "pir_1_mode": 694,
    "pir_2_mode": 695,
    "pir_3_mode": 696,
    "threshold_room_temperature": 700,
    "threshold_inlet_temperature": 701,
    "threshold_outside_temperature": 702,
    "threshold_general_purpose_temperature": 703,
    "threshold_evaporator_temperature": 704,
    "threshold_condenser_temperature": 705,
    "threshold_outside_temperature_ventilation": 706,
    "threshold_exhaust_temperature_ventilation": 707,
    "threshold_a_bms_temperature": 708,
    "threshold_co2_level_room": 709,
    "threshold_internal_co2_sensor": 710,
    "threshold_a_co2_1": 711,
    "threshold_a_co2_2": 712,
    "threshold_a_co2_3": 713,
    "threshold_a_bms_flow_percent": 714,
    "threshold_comfort_heater_percent": 715,
    "threshold_bypass_damper_percent": 716,
    "threshold_adaptive_airflow": 717,
    "threshold_cooling_percent": 718,
    "threshold_pre_heater_percent": 719,
    "threshold_supply_fan_voltage": 720,
    "threshold_extraction_fan_voltage": 721,
    "threshold_recirculation_fan_voltage": 722,
    "threshold_supply_flow": 723,
    "threshold_extraction_flow": 724,
    "threshold_supply_flow_1": 725,
    "threshold_supply_flow_2": 726,
    "threshold_supply_fan_rpm": 727,
    "threshold_extraction_fan_rpm": 728,
    "threshold_recirculation_fan_rpm": 729,
    "threshold_requested_temperature": 730,
    "threshold_requested_flow": 731,
    "threshold_relative_humidity_supply_air": 732,
    "threshold_relative_humidity_extraction_air": 733,
    "threshold_system_mode": 734,
    "threshold_system_condition": 735,
    "threshold_system_alarm": 736,
    "threshold_am_float_switch": 737,
    "threshold_manuel_start": 738,
    "threshold_cc_float_switch": 739,
    "threshold_a_bms_start": 740,
    "threshold_pir": 741,
    "threshold_alarm_input": 742,
    "threshold_recirculation_damper": 743,
    "threshold_main_air_damper": 744,
    "threshold_general_warning_id_0_15": 745,
    "threshold_general_warning_id_16_31": 746,
    "threshold_general_alarm_id_0_15": 747,
    "threshold_general_alarm_id_16_31": 748,
    "threshold_filter_remaining_service_life_days": 749,
    "threshold_air_handling_unit_power_consumption": 750,
    "threshold_air_handling_unit_power_consumption_decimal_points": 751,
    "threshold_utc_time_in_seconds": 752,
    "threshold_air_handling_unit_power_consumption_NAMEDUPLICATE_OF_750": 753,
    "threshold_remaining_service_life_percent": 754,
    "threshold_remaining_service_life_hours": 755,
    "threshold_evaporator_in_temperature": 756,
    "threshold_evaporator_out_temperature": 757,
    "threshold_hotgas_temperature": 758,
    "threshold_stepper_valve_opening": 759,
    "threshold_analog_in_1": 760,
    "threshold_analog_in_2": 761,
    "threshold_analog_in_3": 762,
    "threshold_hardware_errors": 763,
    "threshold_bacnet_device_id": 764,
    "threshold_cooling_unit_power_consumption": 765,
    "threshold_cooling_unit_power_consumption_decimal_points": 766,
    "threshold_cooling_unit_power_consumption_NAMEDUPLICATE_OF_765": 767,
    "threshold_supply_air_pressure_difference": 768,
    "threshold_extracton_air_pressure_difference": 769,
    "threshold_simplified_operating_mode": 770,
    "threshold_hardware_errors_lsb": 771,
    "threshold_hardware_errors_msb": 772,
    "threshold_air_handling_unit_current_power_consumption": 773,
    "threshold_air_quality_tvoc": 774,
    "threshold_air_quality_raw_signal": 775,
    "threshold_rqf_prodco2": 776,
    "threshold_rqf_prodtvoc": 777,
    "threshold_started_by": 778,
    "sf_ppr": 821,
    "ef_ppr": 822,
    "sf_model": 823,
    "ef_model": 824,
    "enthalpy_a_coefficient": 825,
    "enthalpy_b_coefficient": 826,
    "enthalpy_c_coefficient": 827,
    "enthalpy_d_coefficient": 828,
    "enthalpy_safety_margin": 829,
    "enthalpy_threshold": 830,
    "cc_condensate_severity": 831,
    "tc_runtime": 833,
    "tc_open": 834,
    "tc_cw_rotation_NAMEDUPLICATE_OF_603": 835,
    "tc_ccw_rotation_NAMEDUPLICATE_OF_604": 836,
    "tc_self_calibration_NAMEDUPLICATE_OF_607": 837,
    "tc_cw_offset_NAMEDUPLICATE_OF_608": 838,
    "tc_ccw_offset_NAMEDUPLICATE_OF_609": 839,
    "vc_min_position": 840,
    "vc_max_position": 841,
    "engage": 842,
    "ch_pid_adaptive_max_flow_pct_low": 843,
    "ch_pid_adaptive_max_flow_pct_high": 844,
    "ch_pid_adaptive_max_ch_pct_low": 845,
    "ch_pid_adaptive_max_ch_pct_high": 846,
    "room_temperature": 1000,
    "inlet_temperature": 1001,
    "outside_temperature": 1002,
    "general_purpose_temperature": 1003,
    "evaporator_temperature": 1004,
    "condenser_temperature": 1005,
    "outside_temperature_ventilation": 1006,
    "exhaust_temperature_ventilation": 1007,
    "a_bms_temperature": 1008,
    "co2_level_room": 1009,
    "internal_co2_sensor": 1010,
    "a_co2_1": 1011,
    "a_co2_2": 1012,
    "a_co2_3": 1013,
    "a_bms_flow_percent": 1014,
    "comfort_heater_percent": 1015,
    "bypass_damper_percent": 1016,
    "adaptive_airflow": 1017,
    "cooling_percent": 1018,
    "pre_heater_percent": 1019,
    "supply_fan_voltage": 1020,
    "extraction_fan_voltage": 1021,
    "recirculation_fan_voltage": 1022,
    "supply_flow": 1023,
    "extraction_flow": 1024,
    "supply_flow_1": 1025,
    "supply_flow_2": 1026,
    "supply_fan_rpm": 1027,
    "extraction_fan_rpm": 1028,
    "recirculation_fan_rpm": 1029,
    "requested_temperature": 1030,
    "requested_flow": 1031,
    "relative_humidity_supply_air": 1032,
    "relative_humidity_extraction_air": 1033,
    "system_mode": 1034,
    "system_condition": 1035,
    "system_alarm": 1036,
    "am_float_switch": 1037,
    "manuel_start": 1038,
    "cc_float_switch": 1039,
    "a_bms_start": 1040,
    "pir": 1041,
    "alarm_input": 1042,
    "recirculation_damper": 1043,
    "main_air_damper": 1044,
    "general_warning_id_0_15": 1045,
    "general_warning_id_16_31": 1046,
    "general_alarm_id_0_15": 1047,
    "general_alarm_id_16_31": 1048,
    "filter_remaining_service_life_days": 1049,
    "air_handling_unit_power_consumption": 1050,
    "air_handling_unit_power_consumption_decimal_points": 1051,
    "utc_time_in_seconds": 1052,
    "air_handling_unit_power_consumption_NAMEDUPLICATE_OF_1050": 1053,
    "remaining_service_life_percent": 1054,
    "remaining_service_life_hours": 1055,
    "evaporator_in_temperature": 1056,
    "evaporator_out_temperature": 1057,
    "hotgas_temperature": 1058,
    "stepper_valve_opening": 1059,
    "analog_in_1": 1060,
    "analog_in_2": 1061,
    "analog_in_3": 1062,
    "hardware_errors": 1063,
    "bacnet_device_id": 1064,
    "cooling_unit_power_consumption": 1065,
    "cooling_unit_power_consumption_decimal_points": 1066,
    "cooling_unit_power_consumption_NAMEDUPLICATE_OF_1065": 1067,
    "supply_air_pressure_difference": 1068,
    "extracton_air_pressure_difference": 1069,
    "simplified_operating_mode": 1070,
    "hardware_errors_lsb": 1071,
    "hardware_errors_msb": 1072,
    "air_handling_unit_current_power_consumption": 1073,
    "air_quality_tvoc": 1074,
    "air_quality_raw_signal": 1075,
    "rqf_prodco2": 1076,
    "rqf_prodtvoc": 1077,
    "started_by_NAMEDUPLICATE_OF_96": 1078,
    "various_bits": 1800,
    "disconnect_count": 1801,
    "last_disconnect_time": 1802,
    "pid_sf_ref": 1900,
    "pid_sf_sen": 1901,
    "pid_sf_err": 1902,
    "pid_sf_out": 1903,
    "pid_sf_a": 1904,
    "pid_sf_p": 1905,
    "pid_sf_i": 1906,
    "pid_sf_d": 1907,
    "pid_ef_ref": 1908,
    "pid_ef_sen": 1909,
    "pid_ef_err": 1910,
    "pid_ef_out": 1911,
    "pid_ef_a": 1912,
    "pid_ef_p": 1913,
    "pid_ef_i": 1914,
    "pid_ef_d": 1915
}
