import React from 'react'

/** Renders a row in the "Configurations" component
 * @param {string} title title key
 * @param {boolean} isInstalled determines if a value should be displayed for the configuration
 * @param {string | JSX.Element} value value for the key
 * @returns 
 */
export default function DeviceConfigurationRow({title, value }) {
    return (
        <div className='d-flex flex-grow-1 mb-1 align-items-center'>
            <span className='ps-2 small text-truncate text-nowrap'>{title}</span>
            <span className='float-end small flex-grow-1 text-end text-nowrap'>
                {value}
            </span>
        </div>
    )
}