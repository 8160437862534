import React from "react";

/** Loadspinner element
 * @param {object} style jsx style object
 * @param {string} height css height (eg: "60px")
 * @param {string} width css height (eg: "60px")
 * @returns {JSX.Element}
 * @constructor
 */
export function LoadSpinner({style={}, height="60px", width="60px"}) {
    return <div className="d-flex justify-content-center align-items-center" style={style}>
        <div className="spinner-border spinner-border-lg text-primary"
             style={{height: height, width: width}}></div>
    </div>
}