// noinspection EqualityComparisonWithCoercionJS

import React from 'react'
import {deviceParams} from '../../../helpers/constants.js'
import SimpleLineChart from './rechart/simplelinechart/SimpleLineChart.js'
import {PropertiesToolTip} from './rechart/tooltips/PropertiesTooltip.js'
import {isAwareDevice} from '../../../helpers/helperfunctions.js'
import {awareTelemNames} from '../../parameters/awareTelemSpec.js'
import MultiLineChart from './rechart/multilinechart/MultiLineChart.js'
import StepChart from './rechart/stepchart/StepChart.js'
import EnumTooltip from './rechart/tooltips/EnumTooltip.js'
import {paramNames, paramSpec} from '../../parameters/parameterSpec.js'
import {mainState, opMode, rcState, startedBy} from 'components/parameters/awareTelemEnumOptions.js'
import {useTranslation} from 'react-i18next'
import {graphTypes} from "./telemetryChartsHelper.js";

export function TelemetryChartsGraph({
                                         graph, getGraphTooltipKeys, telemObj, start,
                                         end, operatingOnly, showSerialInTitle
                                     }) {
    const {t} = useTranslation(['common', 'dashboard', 'telemetry'])
    //Filter data based on whether operating hours should be viewed or not
    const filterOperating = (telemetryDataList) => telemetryDataList.map(dp => dp[paramNames.started_by_NAMEDUPLICATE_OF_96] == 0 ? {[paramNames.started_by_NAMEDUPLICATE_OF_96]: 0} : dp)
    const getFilteredDataList = (dList) => operatingOnly ? filterOperating(dList) : dList
    const getTitle = (graphName) => showSerialInTitle ? `${graphName} - ${telemObj.deviceName}` : graphName

    switch (graph) {
        /*=== Dashboard Graphs ===*/
        case graphTypes.simpleCO2:
            return <SimpleLineChart tooltipKeys={getGraphTooltipKeys(graph)} className="py-3"
                                    tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                    lineKey={isAwareDevice(telemObj.id) ? awareTelemNames.co2_ar : deviceParams.co2}
                                    title={<span>CO₂{showSerialInTitle ? ' - ' + telemObj.deviceName : ''}</span>}
                                    threshold={telemObj?.deviceConstants?.co2_threshold ?? '900'}
                                    startTime={start}
                                    endTime={end}
                                    yPostFix="PPM"/>
        case graphTypes.simpleTVOC:
            return <SimpleLineChart tooltipKeys={getGraphTooltipKeys(graph)} className="py-3"
                                    tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                    lineKey={isAwareDevice(telemObj.id) ? awareTelemNames.tvoc_ar : deviceParams.voc}
                                    title={getTitle(t('telemetry:graph_types.simpleTVOC'))}
                                    threshold={telemObj?.deviceConstants?.tvoc_threshold ?? '220'}
                                    startTime={start}
                                    endTime={end}
                                    yPostFix="PPB"/>
        case graphTypes.multiHumidity:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start} endTime={end} yPostFix="%"
                                   title={getTitle(t('telemetry:graph_types.multiHumidity'))}/>
        case graphTypes.simpleAirflow:
            return <SimpleLineChart tooltipKeys={getGraphTooltipKeys(graph)} className="py-3"
                                    tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                    lineKey={isAwareDevice(telemObj.id) ? awareTelemNames.rqf : deviceParams.airflow}
                                    title={getTitle(t('telemetry:graph_types.simpleAirflow'))}
                                    startTime={start} endTime={end} yPostFix="%"/>
        // If changing, remember to set to not array in telemetryChartsHelper.js
        // return <StepChart lineKey={getGraphTooltipKeys(graph)} className="py-3" tooltip={PropertiesToolTip}
        //                   telemetryList={getFilteredDataList(telemObj.dataList)} optionsLength={100}
        //                   yStepType={'continuous'} title={getTitle(t('telemetry:graph_types.simpleAirflow'))}
        //                   startTime={start} endTime={end}
        // />
        case graphTypes.multiPowerConsumption:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiPowerConsumption'))}
                                   yPostFix="kWh"/>
        case graphTypes.multiTemperaturesHeatPump:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiTemperaturesHeatPump'))}
                                   yPostFix="°C"/>
        case graphTypes.multiDampersAndSetpoints:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiDampersAndSetpoints'))}
                                   yPostFix="%"/>
        case graphTypes.multiCondensateSystem:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiCondensateSystem'))}
                                   yPostFix=""/>
        case graphTypes.multiTemperature:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiTemperature'))} yPostFix="°C"/>
        case graphTypes.multiAirflowM3H:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiAirflowM3H'))} yPostFix="m3/h"/>
        case graphTypes.stepStartedBy:
            return <StepChart lineKey={getGraphTooltipKeys(graph)} className="py-3"
                              telemetryList={getFilteredDataList(telemObj.dataList)}
                              tooltip={EnumTooltip} translationPrefix={'StartedBy'}
                              optionsLength={Object.keys(paramSpec[paramNames.started_by].options).length}
                              startTime={start} endTime={end}
                              title={getTitle(t('telemetry:graph_types.stepStartedBy'))}/>
        case graphTypes.awareStepStartedBy:
            return <StepChart lineKey={getGraphTooltipKeys(graph)} className="py-3"
                              telemetryList={getFilteredDataList(telemObj.dataList)}
                              tooltip={EnumTooltip} translationPrefix={'AwareStartedBy'}
                              optionsLength={Object.keys(startedBy).length}
                              startTime={start} endTime={end}
                              title={getTitle(t('telemetry:graph_types.stepStartedBy'))}/>
        case graphTypes.heater:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start} endTime={end}
                                   title={getTitle(t('telemetry:graph_types.heater'))}
                                   yPostFix="%"/>
        case graphTypes.heating_cooling:
        case graphTypes.heating_cooling_with_eev:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start} endTime={end}
                                   title={getTitle(t('telemetry:graph_types.heating_cooling'))} yPostFix="%"/>

        /*=== Technical Graphs ===*/
        case graphTypes.multiTemperaturesCooling:
            return <MultiLineChart lineKeys={getGraphTooltipKeys(graph)} className="py-3"
                                   tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiTemperaturesCooling'))}
                                   yPostFix="°C"/>
        case graphTypes.simpleCoolingPercent:
            return <SimpleLineChart tooltipKeys={getGraphTooltipKeys(graph)} className="py-3"
                                    tooltip={PropertiesToolTip} telemetryList={getFilteredDataList(telemObj.dataList)}
                                    startTime={start}
                                    endTime={end}
                                    lineKey={paramNames.cooling_percent}
                                    title={getTitle(t('telemetry:graph_types.simpleCoolingPercent'))} yPostFix="%"/>
        case graphTypes.multiCO2:
            return <MultiLineChart className="py-3" tooltip={PropertiesToolTip}
                                   telemetryList={getFilteredDataList(telemObj.dataList)}
                                   lineKeys={getGraphTooltipKeys(graph)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiCO2'))} yPostFix="PPM"/>
        case graphTypes.multiTVOC:
            return <MultiLineChart className="py-3" tooltip={PropertiesToolTip}
                                   telemetryList={getFilteredDataList(telemObj.dataList)}
                                   lineKeys={getGraphTooltipKeys(graph)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiTVOC'))} yPostFix="PPB"/>
        case graphTypes.multiFanRpm:
            return <MultiLineChart className="py-3" tooltip={PropertiesToolTip}
                                   telemetryList={getFilteredDataList(telemObj.dataList)}
                                   lineKeys={getGraphTooltipKeys(graph)}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiFanRpm'))} yPostFix="RPM"/>
        case graphTypes.stepSystemMode:
            return <StepChart className="py-3" tooltip={EnumTooltip}
                              telemetryList={getFilteredDataList(telemObj.dataList)}
                              lineKey={deviceParams.system_mode}
                              optionsLength={Object.keys(paramSpec[paramNames.system_mode].options).length - 1}
                              startTime={start}
                              endTime={end}
                              translationPrefix={'SystemMode'}
                              title={getTitle(t('telemetry:graph_types.stepSystemMode'))}/>
        case graphTypes.awareStepSystemMode:
            return <StepChart className="py-3" tooltip={EnumTooltip}
                              telemetryList={getFilteredDataList(telemObj.dataList)}
                              lineKey={awareTelemNames.op_mode}
                              optionsLength={Object.keys(opMode).length - 1}
                              startTime={start}
                              endTime={end}
                              translationPrefix={'AwareSystemMode'}
                              title={getTitle(t('telemetry:graph_types.stepSystemMode'))}/>
        case graphTypes.stepMainState:
            return <StepChart className="py-3" tooltip={EnumTooltip}
                              telemetryList={getFilteredDataList(telemObj.dataList)}
                              lineKey={awareTelemNames.msm_state}
                              optionsLength={Object.keys(mainState).length}
                              startTime={start}
                              endTime={end}
                              translationPrefix={'MainState'}
                              title={getTitle(t('telemetry:graph_types.stepMainState'))}/>
        case graphTypes.stepRecirculationState:
            return <StepChart className="py-3" tooltip={EnumTooltip}
                              telemetryList={getFilteredDataList(telemObj.dataList)}
                              lineKey={awareTelemNames.rc_state}
                              optionsLength={Object.keys(rcState).length}
                              startTime={start}
                              endTime={end}
                              translationPrefix={'RecircState'}
                              title={getTitle(t('telemetry:graph_types.stepRecirculationState'))}/>
        case graphTypes.multiSystemConditions:
            return <MultiLineChart className="py-3" tooltip={PropertiesToolTip}
                                   telemetryList={getFilteredDataList(telemObj.dataList)}
                                   lineKeys={getGraphTooltipKeys(graph)} stepEnabled={true}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiSystemConditions'))}/>
        case graphTypes.multiSystemWarnings:
            return <MultiLineChart className="py-3" tooltip={PropertiesToolTip}
                                   telemetryList={getFilteredDataList(telemObj.dataList)}
                                   lineKeys={getGraphTooltipKeys(graph)} stepEnabled={true}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiSystemWarnings'))}/>
        case graphTypes.multiSystemAlarms:
            return <MultiLineChart className="py-3" tooltip={PropertiesToolTip}
                                   telemetryList={getFilteredDataList(telemObj.dataList)}
                                   lineKeys={getGraphTooltipKeys(graph)} stepEnabled={true}
                                   startTime={start}
                                   endTime={end}
                                   title={getTitle(t('telemetry:graph_types.multiSystemAlarms'))}/>

        default:
            return <div>Graph type not found: {graph ?? 'undefined'}</div>
    }
}