import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {ArrowRepeat, GearFill, Pencil, Trash} from 'react-bootstrap-icons';
import {Translation} from 'react-i18next';
import {useMsal} from "@azure/msal-react";

/**
 * **DropdownActions** is a table dependency in '../UserManagement.js' which injects a dropdown menu with user-specific actions
 * 
 * @param {object} data value for the corresponding cell 
 * @param {function} deleteHandler passed function to handle delete click
 * @param {function} editHandler passed function to handle edit click
 * @param {function} reInviteHandler passed function to handle re-invite click
 * @param {function} privilegesHandler function to trigger if the user tries to delete a user, which is not allowed to be deleted
 * @param {list} userRole list of roles that is assigned to the logged in user
 * @returns a dropdown menu to inject into the table column
 */
function DropdownActions({ data, deleteHandler, editHandler, reInviteHandler, privilegesHandler, userRole }) {
    const { accounts } = useMsal();

    const tryDelete = () => {
        if (data.userData.appRole === "Administrator" && !userRole.includes("App.Admin")) {
            privilegesHandler()
        }
        else {
            deleteHandler(data.userData.id)
        }
    }

    const tryEdit = () => {
        if (data.userData.appRole === "Administrator" && !userRole.includes("App.Admin")) {
            privilegesHandler()
        }
        else {
            editHandler(data)
        }
    }
    return (
        <Translation ns={['userpage']}>
            {(t) =>
                <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic" className='p-0 shadow-none remove-arrow link-secondary'>
                        <GearFill className="me-1 align-baseline" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="pb-0">
                        <Dropdown.Item onClick={() => tryEdit()}><Pencil className="me-3 mb-1" />{t('userpage:user_management.table.dropdown.edit')}</Dropdown.Item>
                        {
                            (data.userData.externalUserState !== "Accepted") && <Dropdown.Item onClick={() => reInviteHandler(data.userData.mail)}><ArrowRepeat className="me-3 mb-1" />{t('userpage:user_management.table.dropdown.re_inv')}</Dropdown.Item>
                        }
                        {accounts[0].idTokenClaims.oid !== data.userId && // cannot delete self
                            <React.Fragment>
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item className=' mb-2' onClick={() => tryDelete()}><Trash className="me-3 mb-1" />{t('userpage:user_management.table.dropdown.delete')}</Dropdown.Item>
                            </React.Fragment>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            }
        </Translation >
    )
}

export default DropdownActions