import {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {hasAnyRole} from "../authHelper.js";

/**
 * Guard hook that sends user to rootpage if not in allowedRoles
 * @param allowedRoles
 */
export default function useNavigationGuardByRole(allowedRoles) {
    const { accounts } = useMsal();
    const navigate = useNavigate();
    useEffect(() => {
        if (!hasAnyRole(accounts, allowedRoles)) {
            navigate("/")
        }
    }, [])
}