export const awareParamNames = {
    "default_flow": "default_flow",
    "default_temp": "default_temp",
    "min_flow_setpoint": "min_flow_setpoint",
    "max_flow_setpoint": "max_flow_setpoint",
    "min_temp_setpoint": "min_temp_setpoint",
    "max_temp_setpoint": "max_temp_setpoint",
    "co2_control_enabled": "co2_control_enabled",
    "co2_control_min": "co2_control_min",
    "co2_control_max": "co2_control_max",
    "pir_enabled": "pir_enabled",
    "pir_afterrun_timer": "pir_afterrun_timer",
    "main_loop_timer": "main_loop_timer",
    "low_inlet": "low_inlet",
    "preheater_controller_ref": "preheater_controller_ref",
    "min_ot": "min_ot",
    "high_room_temp_high_limit": "high_room_temp_high_limit",
    "temp_diff": "temp_diff",
    "bp_mode": "bp_mode",
    "bp_run_time": "bp_run_time",
    "bp_open_direction": "bp_open_direction",
    "bp_cw_rotation": "bp_cw_rotation",
    "bp_ccw_rotation": "bp_ccw_rotation",
    "bp_self_calibration": "bp_self_calibration",
    "bp_cw_offset": "bp_cw_offset",
    "bp_ccw_offset": "bp_ccw_offset",
    "mds_mode": "mds_mode",
    "mds_run_time": "mds_run_time",
    "mds_open_direction": "mds_open_direction",
    "mds_cw_rotation": "mds_cw_rotation",
    "mds_ccw_rotation": "mds_ccw_rotation",
    "md_min_opening": "md_min_opening",
    "disable_low_temp_alarm": "disable_low_temp_alarm",
    "high_temp_delay": "high_temp_delay",
    "low_temp_alarm_retry_timer": "low_temp_alarm_retry_timer",
    "high_temp_exit_timer": "high_temp_exit_timer",
    "high_temp_rt_diff": "high_temp_rt_diff",
    "high_temp_hyst": "high_temp_hyst",
    "bp_pid_kp": "bp_pid_kp",
    "bp_pid_ki": "bp_pid_ki",
    "bp_pid_kd": "bp_pid_kd",
    "bp_pid_ka": "bp_pid_ka",
    "unbalance_it_controller_kp": "unbalance_it_controller_kp",
    "unbalance_it_controller_ki": "unbalance_it_controller_ki",
    "unbalance_it_controller_kd": "unbalance_it_controller_kd",
    "unbalance_it_controller_ka": "unbalance_it_controller_ka",
    "unbalance_it_controller_max": "unbalance_it_controller_max",
    "comfort_heater_type": "comfort_heater_type",
    "virtual_ph_controller_kp": "virtual_ph_controller_kp",
    "virtual_ph_controller_ki": "virtual_ph_controller_ki",
    "virtual_ph_controller_kd": "virtual_ph_controller_kd",
    "virtual_ph_controller_ka": "virtual_ph_controller_ka",
    "virtual_ph_controller_max": "virtual_ph_controller_max",
    "low_temp_exit_timer": "low_temp_exit_timer",
    "comfort_heater_controller_kp": "comfort_heater_controller_kp",
    "comfort_heater_controller_ki": "comfort_heater_controller_ki",
    "comfort_heater_controller_kd": "comfort_heater_controller_kd",
    "comfort_heater_controller_ka": "comfort_heater_controller_ka",
    "comfort_heater_controller_max": "comfort_heater_controller_max",
    "electrical_heating_surfaces_min_flow": "electrical_heating_surfaces_min_flow",
    "unbalance_et_controller_kp": "unbalance_et_controller_kp",
    "unbalance_et_controller_ki": "unbalance_et_controller_ki",
    "unbalance_et_controller_kd": "unbalance_et_controller_kd",
    "unbalance_et_controller_ka": "unbalance_et_controller_ka",
    "unbalance_et_controller_max": "unbalance_et_controller_max",
    "min_inlet": "min_inlet",
    "min_outlet": "min_outlet",
    "unbalance_et_controller_ref": "unbalance_et_controller_ref",
    "pre_heater_installed": "pre_heater_installed",
    "virtual_pre_heater_options": "virtual_pre_heater_options",
    "preheater_controller_kp": "preheater_controller_kp",
    "preheater_controller_ki": "preheater_controller_ki",
    "preheater_controller_kd": "preheater_controller_kd",
    "preheater_controller_ka": "preheater_controller_ka",
    "preheater_controller_max": "preheater_controller_max",
    "start_priority_1": "start_priority_1",
    "start_priority_2": "start_priority_2",
    "start_priority_3": "start_priority_3",
    "start_priority_4": "start_priority_4",
    "start_priority_5": "start_priority_5",
    "start_priority_6": "start_priority_6",
    "start_priority_dependency": "start_priority_dependency",
    "start_priority_night_cooling_independent": "start_priority_night_cooling_independent",
    "allow_boost": "allow_boost",
    "boost_afterrun_time": "boost_afterrun_time",
    "boost_auto_off_time": "boost_auto_off_time",
    "boost_max_sf_pct": "boost_max_sf_pct",
    "boost_max_ef_pct": "boost_max_ef_pct",
    "fan_supply_min_pct": "fan_supply_min_pct",
    "fan_supply_max_pct": "fan_supply_max_pct",
    "fan_extraction_min_pct": "fan_extraction_min_pct",
    "fan_extraction_max_pct": "fan_extraction_max_pct",
    "manual_override_time": "manual_override_time",
    "bp_min_movement": "bp_min_movement",
    "external_emergency_function": "external_emergency_function",
    "night_cooling_low_limit": "night_cooling_low_limit",
    "night_cooling_high_limit": "night_cooling_high_limit",
    "night_cooling_min_ot": "night_cooling_min_ot",
    "co2_sensor_installed_ar": "co2_sensor_installed_ar",
    "analog_co2_sensor_ppm_range_min": "analog_co2_sensor_ppm_range_min",
    "analog_co2_sensor_ppm_range_max": "analog_co2_sensor_ppm_range_max",
    "analog_co2_sensor_volt_range_min": "analog_co2_sensor_volt_range_min",
    "analog_co2_sensor_volt_range_max": "analog_co2_sensor_volt_range_max",
    "cds_pumpe_operation": "cds_pumpe_operation",
    "condensation_retries_extraction": "condensation_retries_extraction",
    "condensation_evaporation_time": "condensation_evaporation_time",
    "sf_cor_a_coeff": "sf_cor_a_coeff",
    "sf_cor_b_coeff": "sf_cor_b_coeff",
    "sf_cor_c_coeff": "sf_cor_c_coeff",
    "sf_cor_bp_lo_lim": "sf_cor_bp_lo_lim",
    "sf_cor_bp_hi_lim": "sf_cor_bp_hi_lim",
    "sf_cor_lo": "sf_cor_lo",
    "timer_schedule_1_mode": "timer_schedule_1_mode",
    "timer_schedule_1_days": "timer_schedule_1_days",
    "timer_schedule_1_start_time": "timer_schedule_1_start_time",
    "timer_schedule_1_duration": "timer_schedule_1_duration",
    "timer_schedule_1_flow": "timer_schedule_1_flow",
    "timer_schedule_1_temperature": "timer_schedule_1_temperature",
    "filter_test_mode": "filter_test_mode",
    "filter_test_hours_warning": "filter_test_hours_warning",
    "filter_test_hours_alarm": "filter_test_hours_alarm",
    "filter_test_max_lifetime_warning_period": "filter_test_max_lifetime_warning_period",
    "filter_test_max_lifetime_alarm": "filter_test_max_lifetime_alarm",
    "status_led_active_start_time": "status_led_active_start_time",
    "status_led_active_stop_time": "status_led_active_stop_time",
    "external_emergency_contact": "external_emergency_contact",
    "timer_schedule_2_mode": "timer_schedule_2_mode",
    "timer_schedule_2_days": "timer_schedule_2_days",
    "timer_schedule_2_start_time": "timer_schedule_2_start_time",
    "timer_schedule_2_duration": "timer_schedule_2_duration",
    "timer_schedule_2_flow": "timer_schedule_2_flow",
    "timer_schedule_2_temperature": "timer_schedule_2_temperature",
    "loop_test_period": "loop_test_period",
    "loop_test_fans_initial": "loop_test_fans_initial",
    "loop_test_fans_final": "loop_test_fans_final",
    "loop_test_fans_cycles": "loop_test_fans_cycles",
    "loop_test_bp_initial": "loop_test_bp_initial",
    "loop_test_bp_final": "loop_test_bp_final",
    "loop_test_bp_cycles": "loop_test_bp_cycles",
    "ble_advertisement_attenuation": "ble_advertisement_attenuation",
    "support_id": "support_id",
    "loop_test_extp_initial": "loop_test_extp_initial",
    "loop_test_extp_final": "loop_test_extp_final",
    "loop_test_extp_cycles": "loop_test_extp_cycles",
    "sf_cor_rqf_scale": "sf_cor_rqf_scale",
    "special_function_request": "special_function_request",
    "hvac_mode": "hvac_mode",
    "hvac_four_way_valve_active": "hvac_four_way_valve_active",
    "hvac_fc_on_delay": "hvac_fc_on_delay",
    "hvac_fc_power_up_time": "hvac_fc_power_up_time",
    "hvac_fc_power_down_time": "hvac_fc_power_down_time",
    "hvac_fc_volt_cooling_min": "hvac_fc_volt_cooling_min",
    "hvac_fc_volt_cooling_max": "hvac_fc_volt_cooling_max",
    "hvac_fc_volt_heating_min": "hvac_fc_volt_heating_min",
    "hvac_fc_volt_heating_max": "hvac_fc_volt_heating_max",
    "hvac_fc_hz_cooling_min": "hvac_fc_hz_cooling_min",
    "hvac_fc_hz_cooling_max": "hvac_fc_hz_cooling_max",
    "hvac_fc_hz_heating_min": "hvac_fc_hz_heating_min",
    "hvac_fc_hz_heating_max": "hvac_fc_hz_heating_max",
    "hvac_cc_min_flow": "hvac_cc_min_flow",
    "hvac_cc_delay": "hvac_cc_delay",
    "hvac_cc_delta_rqt_min": "hvac_cc_delta_rqt_min",
    "hvac_cc_controller_kp": "hvac_cc_controller_kp",
    "hvac_cc_controller_ki": "hvac_cc_controller_ki",
    "hvac_cc_controller_kd": "hvac_cc_controller_kd",
    "hvac_cc_controller_ka": "hvac_cc_controller_ka",
    "hvac_hgt_controller_kp": "hvac_hgt_controller_kp",
    "hvac_hgt_controller_ki": "hvac_hgt_controller_ki",
    "hvac_hgt_controller_kd": "hvac_hgt_controller_kd",
    "hvac_hgt_controller_ka": "hvac_hgt_controller_ka",
    "hvac_hgt_controller_max": "hvac_hgt_controller_max",
    "hvac_hgt_controller_set": "hvac_hgt_controller_set",
    "hvac_hp_controller_kp": "hvac_hp_controller_kp",
    "hvac_hp_controller_ki": "hvac_hp_controller_ki",
    "hvac_hp_controller_kd": "hvac_hp_controller_kd",
    "hvac_hp_controller_ka": "hvac_hp_controller_ka",
    "hvac_reset_udt_on_power_cycle_only": "hvac_reset_udt_on_power_cycle_only",
    "rci_run_time": "rci_run_time",
    "rci_open_direction": "rci_open_direction",
    "rci_cw_rotation": "rci_cw_rotation",
    "rci_ccw_rotation": "rci_ccw_rotation",
    "aa_mode": "aa_mode",
    "aa_activated": "aa_activated",
    "aa_run_time": "aa_run_time",
    "aa_upward_direction": "aa_upward_direction",
    "aa_cw_rotation": "aa_cw_rotation",
    "aa_ccw_rotation": "aa_ccw_rotation",
    "aa_self_calibration": "aa_self_calibration",
    "loop_test_adaptive_airflow_initial": "loop_test_adaptive_airflow_initial",
    "loop_test_adaptive_airflow_final": "loop_test_adaptive_airflow_final",
    "loop_test_adaptive_airflowcycles": "loop_test_adaptive_airflowcycles",
    "bp_activated": "bp_activated",
    "rci_mode": "rci_mode",
    "modbus_rtu_baud_rate": "modbus_rtu_baud_rate",
    "modbus_rtu_parity": "modbus_rtu_parity",
    "modbus_rtu_frequency_converter_address": "modbus_rtu_frequency_converter_address",
    "fan_supply_control": "fan_supply_control",
    "fan_supply_make": "fan_supply_make",
    "fan_supply_model": "fan_supply_model",
    "fan_supply_tacho_ppr": "fan_supply_tacho_ppr",
    "fan_extraction_control": "fan_extraction_control",
    "fan_extraction_make": "fan_extraction_make",
    "fan_extraction_model": "fan_extraction_model",
    "fan_extraction_tacho_ppr": "fan_extraction_tacho_ppr",
    "cds_lvl_sensor_sup_phe": "cds_lvl_sensor_sup_phe",
    "cds_lvl_sensor_sup_ahe": "cds_lvl_sensor_sup_ahe",
    "cds_lvl_sensor_ext_phe": "cds_lvl_sensor_ext_phe",
    "cds_lvl_sensor_ext_ahe": "cds_lvl_sensor_ext_ahe",
    "condensation_retries_supply": "condensation_retries_supply",
    "co2_sensor_installed_ai": "co2_sensor_installed_ai",
    "co2_sensor_installed_ae": "co2_sensor_installed_ae",
    "co2_sensor_installed_ao": "co2_sensor_installed_ao",
    "rh_sensor_installed_ai": "rh_sensor_installed_ai",
    "rh_sensor_installed_ar": "rh_sensor_installed_ar",
    "rh_sensor_installed_ae": "rh_sensor_installed_ae",
    "rh_sensor_installed_ao": "rh_sensor_installed_ao",
    "analog_rh_sensor_pct_range_min": "analog_rh_sensor_pct_range_min",
    "analog_rh_sensor_pct_range_max": "analog_rh_sensor_pct_range_max",
    "analog_rh_sensor_volt_range_min": "analog_rh_sensor_volt_range_min",
    "analog_rh_sensor_volt_range_max": "analog_rh_sensor_volt_range_max",
    "pressure_switch_installed_vic": "pressure_switch_installed_vic",
    "pressure_switch_installed_voc": "pressure_switch_installed_voc",
    "pressure_transmitter_installed_vti": "pressure_transmitter_installed_vti",
    "pressure_transmitter_installed_lbi": "pressure_transmitter_installed_lbi",
    "pressure_transmitter_installed_vto": "pressure_transmitter_installed_vto",
    "pressure_transmitter_installed_lbo": "pressure_transmitter_installed_lbo",
    "pressure_transmitter_installed_vic": "pressure_transmitter_installed_vic",
    "analog_v_pressure_transmitter_bar_range_min": "analog_v_pressure_transmitter_bar_range_min",
    "analog_v_pressure_transmitter_bar_range_max": "analog_v_pressure_transmitter_bar_range_max",
    "analog_v_pressure_transmitter_volt_range_min": "analog_v_pressure_transmitter_volt_range_min",
    "analog_v_pressure_transmitter_volt_range_max": "analog_v_pressure_transmitter_volt_range_max",
    "analog_i_pressure_transmitter_bar_range_min": "analog_i_pressure_transmitter_bar_range_min",
    "analog_i_pressure_transmitter_bar_range_max": "analog_i_pressure_transmitter_bar_range_max",
    "analog_i_pressure_transmitter_ma_range_min": "analog_i_pressure_transmitter_ma_range_min",
    "analog_i_pressure_transmitter_ma_range_max": "analog_i_pressure_transmitter_ma_range_max",
    "t_sensor_installed_ai": "t_sensor_installed_ai",
    "t_sensor_installed_ar": "t_sensor_installed_ar",
    "t_sensor_installed_ae": "t_sensor_installed_ae",
    "t_sensor_installed_ao": "t_sensor_installed_ao",
    "t_sensor_installed_aop": "t_sensor_installed_aop",
    "t_sensor_installed_aio": "t_sensor_installed_aio",
    "t_sensor_installed_aii": "t_sensor_installed_aii",
    "t_sensor_installed_vto": "t_sensor_installed_vto",
    "t_sensor_installed_vti": "t_sensor_installed_vti",
    "t_sensor_installed_voc": "t_sensor_installed_voc",
    "t_sensor_installed_lbo": "t_sensor_installed_lbo",
    "t_sensor_installed_lbi": "t_sensor_installed_lbi",
    "t_sensor_installed_lie": "t_sensor_installed_lie",
    "t_sensor_installed_loe": "t_sensor_installed_loe",
    "t_sensor_installed_pci": "t_sensor_installed_pci",
    "t_sensor_installed_pco": "t_sensor_installed_pco",
    "t_sensor_installed_vic": "t_sensor_installed_vic",
    "tvoc_sensor_installed_ai": "tvoc_sensor_installed_ai",
    "tvoc_sensor_installed_ar": "tvoc_sensor_installed_ar",
    "tvoc_sensor_installed_ae": "tvoc_sensor_installed_ae",
    "tvoc_sensor_installed_ao": "tvoc_sensor_installed_ao",
    "mde_mode": "mde_mode",
    "mde_run_time": "mde_run_time",
    "mde_open_direction": "mde_open_direction",
    "mde_cw_rotation": "mde_cw_rotation",
    "mde_ccw_rotation": "mde_ccw_rotation",
    "rco_mode": "rco_mode",
    "rco_run_time": "rco_run_time",
    "rco_open_direction": "rco_open_direction",
    "rco_cw_rotation": "rco_cw_rotation",
    "rco_ccw_rotation": "rco_ccw_rotation",
    "eev_controller_kp": "eev_controller_kp",
    "eev_controller_ki": "eev_controller_ki",
    "eev_controller_kd": "eev_controller_kd",
    "eev_controller_ka": "eev_controller_ka",
    "eev_controller_min_output": "eev_controller_min_output",
    "eev_controller_max_output": "eev_controller_max_output",
    "eev_controller_default_position": "eev_controller_default_position",
    "eev_controller_defrost_position": "eev_controller_defrost_position",
    "eev_controller_evaporator_dt": "eev_controller_evaporator_dt",
    "hvac_eev_installed": "hvac_eev_installed",
    "hvac_eev_steps": "hvac_eev_steps",
    "hvac_eev_calibration_steps": "hvac_eev_calibration_steps",
    "hvac_eev_calibration_time": "hvac_eev_calibration_time",
    "hvac_fc_power_mode": "hvac_fc_power_mode",
    "status_led_on_when_running_normal": "status_led_on_when_running_normal",
    "status_led_installed": "status_led_installed",
    "purge_duration": "purge_duration",
    "purge_fan_setpoint": "purge_fan_setpoint",
    "purge_inactivity_time": "purge_inactivity_time",
    "support_phone_number": "support_phone_number",
    "home_phone_number": "home_phone_number"
}

export const awareParamSpec = {
    "default_flow": {
        "id": "default_flow",
        "name": "default_flow",
        "name_orig": "Default Flow",
        "nameDisplay": "Default Flow",
        "number": 1001,
        "desc": "Flow set, if the actual starting signal does not provide one (i.e. PIR, START etc.)",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Operation\\Default Setpoints"
    },
    "default_temp": {
        "id": "default_temp",
        "name": "default_temp",
        "name_orig": "Default Temp",
        "nameDisplay": "Default Temp",
        "number": 1002,
        "desc": "IT set, if the actual starting signal does not provide one (i.e. PIR, START etc.)",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Default Setpoints"
    },
    "min_flow_setpoint": {
        "id": "min_flow_setpoint",
        "name": "min_flow_setpoint",
        "name_orig": "Min Flow Setpoint",
        "nameDisplay": "Min Flow Setpoint",
        "number": 1003,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Operation\\Control Limits"
    },
    "max_flow_setpoint": {
        "id": "max_flow_setpoint",
        "name": "max_flow_setpoint",
        "name_orig": "Max Flow Setpoint",
        "nameDisplay": "Max Flow Setpoint",
        "number": 1004,
        "desc": "OBS. can be overrided by internal procedures",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Operation\\Control Limits"
    },
    "min_temp_setpoint": {
        "id": "min_temp_setpoint",
        "name": "min_temp_setpoint",
        "name_orig": "Min Temp Setpoint",
        "nameDisplay": "Min Temp Setpoint",
        "number": 1005,
        "desc": "",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Control Limits"
    },
    "max_temp_setpoint": {
        "id": "max_temp_setpoint",
        "name": "max_temp_setpoint",
        "name_orig": "Max Temp Setpoint",
        "nameDisplay": "Max Temp Setpoint",
        "number": 1006,
        "desc": "",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Control Limits"
    },
    "co2_control_enabled": {
        "id": "co2_control_enabled",
        "name": "co2_control_enabled",
        "name_orig": "CO2 Control Enabled",
        "nameDisplay": "CO2 Control Enabled",
        "number": 1007,
        "desc": "",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\CO2"
    },
    "co2_control_min": {
        "id": "co2_control_min",
        "name": "co2_control_min",
        "name_orig": "CO2 Control Min",
        "nameDisplay": "CO2 Control Min",
        "number": 1008,
        "desc": "CO2 concentration above this level, will cause a positive override of the flow",
        "type": "int",
        "min": 400,
        "max": 5000,
        "res": 1,
        "unit": "ppm",
        "default": "",
        "menu": "\\Operation\\CO2"
    },
    "co2_control_max": {
        "id": "co2_control_max",
        "name": "co2_control_max",
        "name_orig": "CO2 Control Max",
        "nameDisplay": "CO2 Control Max",
        "number": 1009,
        "desc": "The CO2 level causing maximum air flow",
        "type": "int",
        "min": 400,
        "max": 5000,
        "res": 1,
        "unit": "ppm",
        "default": "",
        "menu": "\\Operation\\CO2"
    },
    "pir_enabled": {
        "id": "pir_enabled",
        "name": "pir_enabled",
        "name_orig": "PIR Enabled",
        "nameDisplay": "PIR Enabled",
        "number": 1010,
        "desc": "",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\PIR"
    },
    "pir_afterrun_timer": {
        "id": "pir_afterrun_timer",
        "name": "pir_afterrun_timer",
        "name_orig": "PIR Afterrun Timer",
        "nameDisplay": "PIR Afterrun Timer",
        "number": 1011,
        "desc": "The amount of time the PIR start signal continuous to be active after last precence detection (max 99 hours)",
        "type": "int",
        "min": 0,
        "max": 1440,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\PIR"
    },
    "main_loop_timer": {
        "id": "main_loop_timer",
        "name": "main_loop_timer",
        "name_orig": "Main Loop Timer",
        "nameDisplay": "Main Loop Timer",
        "number": 1012,
        "desc": "Execute the Main Loop every xxx ms",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": 1,
        "unit": "ms",
        "default": "",
        "menu": "\\Process Configuration\\Main"
    },
    "low_inlet": {
        "id": "low_inlet",
        "name": "low_inlet",
        "name_orig": "Low Inlet",
        "nameDisplay": "Low Inlet",
        "number": 1013,
        "desc": "Low temp: °C difference for reducing fan level (°C difference between decided and actual °C before the fan level is reduced)",
        "type": "decimal",
        "min": 0,
        "max": 60,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "preheater_controller_ref": {
        "id": "preheater_controller_ref",
        "name": "preheater_controller_ref",
        "name_orig": "Preheater Controller Ref",
        "nameDisplay": "Preheater Controller Ref",
        "number": 1014,
        "desc": "Preheater- / Virtual PreHeater Reference",
        "type": "decimal",
        "min": -30,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "min_ot": {
        "id": "min_ot",
        "name": "min_ot",
        "name_orig": "Min OT",
        "nameDisplay": "Min OT",
        "number": 1015,
        "desc": "By – pass minimum outside °C (Minimum outside °C to start by-pass)",
        "type": "decimal",
        "min": -10,
        "max": 25,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "high_room_temp_high_limit": {
        "id": "high_room_temp_high_limit",
        "name": "high_room_temp_high_limit",
        "name_orig": "High Room Temp High Limit",
        "nameDisplay": "High Room Temp High Limit",
        "number": 1016,
        "desc": "High Room Temperature. Increase airflow and lower inlet temperature",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "temp_diff": {
        "id": "temp_diff",
        "name": "temp_diff",
        "name_orig": "Temp Diff",
        "nameDisplay": "Temp Diff",
        "number": 1017,
        "desc": "By – pass °C difference (Temperature difference for starting by-pass)",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "bp_mode": {
        "id": "bp_mode",
        "name": "bp_mode",
        "name_orig": "BP Mode",
        "nameDisplay": "BP Mode",
        "number": 1018,
        "desc": "Engage Automatic by-pass Control",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass",
        "enumType": "DAMPER_MODE",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "bp_run_time": {
        "id": "bp_run_time",
        "name": "bp_run_time",
        "name_orig": "BP Run Time",
        "nameDisplay": "BP Run Time",
        "number": 1019,
        "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "unit": "sec",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass"
    },
    "bp_open_direction": {
        "id": "bp_open_direction",
        "name": "bp_open_direction",
        "name_orig": "BP Open Direction",
        "nameDisplay": "BP Open Direction",
        "number": 1020,
        "desc": "Open Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass",
        "enumType": "DAMPER_DIRECTION",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "bp_cw_rotation": {
        "id": "bp_cw_rotation",
        "name": "bp_cw_rotation",
        "name_orig": "BP CW Rotation",
        "nameDisplay": "BP CW Rotation",
        "number": 1021,
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "bp_ccw_rotation": {
        "id": "bp_ccw_rotation",
        "name": "bp_ccw_rotation",
        "name_orig": "BP CCW Rotation",
        "nameDisplay": "BP CCW Rotation",
        "number": 1022,
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "bp_self_calibration": {
        "id": "bp_self_calibration",
        "name": "bp_self_calibration",
        "name_orig": "BP Self Calibration",
        "nameDisplay": "BP Self Calibration",
        "number": 1023,
        "desc": "The number of actuator moves allowed, before the actuator performs a self-calibration",
        "type": "int",
        "min": 0,
        "max": 65500,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass"
    },
    "bp_cw_offset": {
        "id": "bp_cw_offset",
        "name": "bp_cw_offset",
        "name_orig": "BP CW Offset",
        "nameDisplay": "BP CW Offset",
        "number": 1024,
        "desc": "TC: CW Offset [ms]. Calibration of time for CW rotation (Designed for compensation for \"inertia\" in the damper+actuator)",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "unit": "ms",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass"
    },
    "bp_ccw_offset": {
        "id": "bp_ccw_offset",
        "name": "bp_ccw_offset",
        "name_orig": "BP CCW Offset",
        "nameDisplay": "BP CCW Offset",
        "number": 1025,
        "desc": "TC: CCW Offset [ms]. Calibration of time for CCW rotation (Designed for compensation for \"inertia\" in the damper+actuator)",
        "type": "int",
        "min": -999,
        "max": 999,
        "res": 1,
        "unit": "ms",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass"
    },
    "mds_mode": {
        "id": "mds_mode",
        "name": "mds_mode",
        "name_orig": "MDS Mode",
        "nameDisplay": "MDS Mode",
        "number": 1026,
        "desc": "ON handles both \"TimeControlled\" and \"Self Return\"",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_MODE",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "mds_run_time": {
        "id": "mds_run_time",
        "name": "mds_run_time",
        "name_orig": "MDS Run Time",
        "nameDisplay": "MDS Run Time",
        "number": 1027,
        "desc": "The time it takes for the MD damper to operate from 0-100%",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper"
    },
    "mds_open_direction": {
        "id": "mds_open_direction",
        "name": "mds_open_direction",
        "name_orig": "MDS Open Direction",
        "nameDisplay": "MDS Open Direction",
        "number": 1028,
        "desc": "Open Main Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_DIRECTION",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "mds_cw_rotation": {
        "id": "mds_cw_rotation",
        "name": "mds_cw_rotation",
        "name_orig": "MDS CW Rotation",
        "nameDisplay": "MDS CW Rotation",
        "number": 1029,
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "mds_ccw_rotation": {
        "id": "mds_ccw_rotation",
        "name": "mds_ccw_rotation",
        "name_orig": "MDS CCW Rotation",
        "nameDisplay": "MDS CCW Rotation",
        "number": 1030,
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "md_min_opening": {
        "id": "md_min_opening",
        "name": "md_min_opening",
        "name_orig": "MD Min Opening",
        "nameDisplay": "MD Min Opening",
        "number": 1031,
        "desc": "The minimum MD open% before setting \"status = running\"",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper"
    },
    "disable_low_temp_alarm": {
        "id": "disable_low_temp_alarm",
        "name": "disable_low_temp_alarm",
        "name_orig": "Disable Low Temp Alarm",
        "nameDisplay": "Disable Low Temp Alarm",
        "number": 1032,
        "desc": "Disable the Low Temp Alarm for X minutes after leaving the Start Procedure",
        "type": "int",
        "min": 0,
        "max": 240,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "high_temp_delay": {
        "id": "high_temp_delay",
        "name": "high_temp_delay",
        "name_orig": "High Temp Delay",
        "nameDisplay": "High Temp Delay",
        "number": 1033,
        "desc": "High Temperature Delay (prevent HT after active heating and startup)",
        "type": "int",
        "min": 0,
        "max": 240,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "low_temp_alarm_retry_timer": {
        "id": "low_temp_alarm_retry_timer",
        "name": "low_temp_alarm_retry_timer",
        "name_orig": "Low Temp Alarm Retry Timer",
        "nameDisplay": "Low Temp Alarm Retry Timer",
        "number": 1034,
        "desc": "Time Between Automated Start Attemps if unit was stopped due to Low Temp Alarm",
        "type": "int",
        "min": 0,
        "max": 1440,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "high_temp_exit_timer": {
        "id": "high_temp_exit_timer",
        "name": "high_temp_exit_timer",
        "name_orig": "High Temp Exit Timer",
        "nameDisplay": "High Temp Exit Timer",
        "number": 1035,
        "desc": "High temp: Shutdown timer. Need to run out before closing by-pass damper, when damper level reaches 0V",
        "type": "int",
        "min": 0,
        "max": 240,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "high_temp_rt_diff": {
        "id": "high_temp_rt_diff",
        "name": "high_temp_rt_diff",
        "name_orig": "High Temp RT Diff",
        "nameDisplay": "High Temp RT Diff",
        "number": 1036,
        "desc": "High temp: Max difference between outside temp and room temp before decreasing by-pass damper opening",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 0.1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "high_temp_hyst": {
        "id": "high_temp_hyst",
        "name": "high_temp_hyst",
        "name_orig": "High Temp Hyst",
        "nameDisplay": "High Temp Hyst",
        "number": 1037,
        "desc": "Hysteresis for regulating damper up/down due to low/high inlet temperature",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "bp_pid_kp": {
        "id": "bp_pid_kp",
        "name": "bp_pid_kp",
        "name_orig": "BP PID KP",
        "nameDisplay": "BP PID KP",
        "number": 1038,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "bp_pid_ki": {
        "id": "bp_pid_ki",
        "name": "bp_pid_ki",
        "name_orig": "BP PID KI",
        "nameDisplay": "BP PID KI",
        "number": 1039,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "bp_pid_kd": {
        "id": "bp_pid_kd",
        "name": "bp_pid_kd",
        "name_orig": "BP PID KD",
        "nameDisplay": "BP PID KD",
        "number": 1040,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "bp_pid_ka": {
        "id": "bp_pid_ka",
        "name": "bp_pid_ka",
        "name_orig": "BP PID KA",
        "nameDisplay": "BP PID KA",
        "number": 1041,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "unbalance_it_controller_kp": {
        "id": "unbalance_it_controller_kp",
        "name": "unbalance_it_controller_kp",
        "name_orig": "Unbalance IT Controller KP",
        "nameDisplay": "Unbalance IT Controller KP",
        "number": 1042,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_it_controller_ki": {
        "id": "unbalance_it_controller_ki",
        "name": "unbalance_it_controller_ki",
        "name_orig": "Unbalance IT Controller KI",
        "nameDisplay": "Unbalance IT Controller KI",
        "number": 1043,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_it_controller_kd": {
        "id": "unbalance_it_controller_kd",
        "name": "unbalance_it_controller_kd",
        "name_orig": "Unbalance IT Controller KD",
        "nameDisplay": "Unbalance IT Controller KD",
        "number": 1044,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_it_controller_ka": {
        "id": "unbalance_it_controller_ka",
        "name": "unbalance_it_controller_ka",
        "name_orig": "Unbalance IT Controller KA",
        "nameDisplay": "Unbalance IT Controller KA",
        "number": 1045,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_it_controller_max": {
        "id": "unbalance_it_controller_max",
        "name": "unbalance_it_controller_max",
        "name_orig": "Unbalance IT Controller Max",
        "nameDisplay": "Unbalance IT Controller Max",
        "number": 1046,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1e-10,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "comfort_heater_type": {
        "id": "comfort_heater_type",
        "name": "comfort_heater_type",
        "name_orig": "Comfort Heater Type",
        "nameDisplay": "Comfort Heater Type",
        "number": 1047,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Heating Surfaces",
        "enumType": "HEATER_TYPE",
        "options": {
            "0": "Off",
            "1": "Electric",
            "2": "Water"
        }
    },
    "virtual_ph_controller_kp": {
        "id": "virtual_ph_controller_kp",
        "name": "virtual_ph_controller_kp",
        "name_orig": "Virtual PH Controller KP",
        "nameDisplay": "Virtual PH Controller KP",
        "number": 1048,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "virtual_ph_controller_ki": {
        "id": "virtual_ph_controller_ki",
        "name": "virtual_ph_controller_ki",
        "name_orig": "Virtual PH Controller KI",
        "nameDisplay": "Virtual PH Controller KI",
        "number": 1049,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "virtual_ph_controller_kd": {
        "id": "virtual_ph_controller_kd",
        "name": "virtual_ph_controller_kd",
        "name_orig": "Virtual PH Controller KD",
        "nameDisplay": "Virtual PH Controller KD",
        "number": 1050,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "virtual_ph_controller_ka": {
        "id": "virtual_ph_controller_ka",
        "name": "virtual_ph_controller_ka",
        "name_orig": "Virtual PH Controller KA",
        "nameDisplay": "Virtual PH Controller KA",
        "number": 1051,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "virtual_ph_controller_max": {
        "id": "virtual_ph_controller_max",
        "name": "virtual_ph_controller_max",
        "name_orig": "Virtual PH Controller Max",
        "nameDisplay": "Virtual PH Controller Max",
        "number": 1052,
        "desc": "Maximum Virtual Preheater / Bypass opening",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "",
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "low_temp_exit_timer": {
        "id": "low_temp_exit_timer",
        "name": "low_temp_exit_timer",
        "name_orig": "Low Temp Exit Timer",
        "nameDisplay": "Low Temp Exit Timer",
        "number": 1053,
        "desc": "High temp: Shutdown timer. Need to run out before closing by-pass damper, when damper level reaches 0V",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "comfort_heater_controller_kp": {
        "id": "comfort_heater_controller_kp",
        "name": "comfort_heater_controller_kp",
        "name_orig": "Comfort Heater Controller KP",
        "nameDisplay": "Comfort Heater Controller KP",
        "number": 1054,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Comfort Heater"
    },
    "comfort_heater_controller_ki": {
        "id": "comfort_heater_controller_ki",
        "name": "comfort_heater_controller_ki",
        "name_orig": "Comfort Heater Controller KI",
        "nameDisplay": "Comfort Heater Controller KI",
        "number": 1055,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Comfort Heater"
    },
    "comfort_heater_controller_kd": {
        "id": "comfort_heater_controller_kd",
        "name": "comfort_heater_controller_kd",
        "name_orig": "Comfort Heater Controller KD",
        "nameDisplay": "Comfort Heater Controller KD",
        "number": 1056,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Comfort Heater"
    },
    "comfort_heater_controller_ka": {
        "id": "comfort_heater_controller_ka",
        "name": "comfort_heater_controller_ka",
        "name_orig": "Comfort Heater Controller KA",
        "nameDisplay": "Comfort Heater Controller KA",
        "number": 1057,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Comfort Heater"
    },
    "comfort_heater_controller_max": {
        "id": "comfort_heater_controller_max",
        "name": "comfort_heater_controller_max",
        "name_orig": "Comfort Heater Controller Max",
        "nameDisplay": "Comfort Heater Controller Max",
        "number": 1058,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Comfort Heater"
    },
    "electrical_heating_surfaces_min_flow": {
        "id": "electrical_heating_surfaces_min_flow",
        "name": "electrical_heating_surfaces_min_flow",
        "name_orig": "Electrical Heating Surfaces Min Flow",
        "nameDisplay": "Electrical Heating Surfaces Min Flow",
        "number": 1059,
        "desc": "HS_FLOW = heating%/100 * HS_minFlow, i.e. the min required flow is dynamic…",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\General"
    },
    "unbalance_et_controller_kp": {
        "id": "unbalance_et_controller_kp",
        "name": "unbalance_et_controller_kp",
        "name_orig": "Unbalance ET Controller KP",
        "nameDisplay": "Unbalance ET Controller KP",
        "number": 1060,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_et_controller_ki": {
        "id": "unbalance_et_controller_ki",
        "name": "unbalance_et_controller_ki",
        "name_orig": "Unbalance ET Controller KI",
        "nameDisplay": "Unbalance ET Controller KI",
        "number": 1061,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_et_controller_kd": {
        "id": "unbalance_et_controller_kd",
        "name": "unbalance_et_controller_kd",
        "name_orig": "Unbalance ET Controller KD",
        "nameDisplay": "Unbalance ET Controller KD",
        "number": 1062,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_et_controller_ka": {
        "id": "unbalance_et_controller_ka",
        "name": "unbalance_et_controller_ka",
        "name_orig": "Unbalance ET Controller KA",
        "nameDisplay": "Unbalance ET Controller KA",
        "number": 1063,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_et_controller_max": {
        "id": "unbalance_et_controller_max",
        "name": "unbalance_et_controller_max",
        "name_orig": "Unbalance ET Controller Max",
        "nameDisplay": "Unbalance ET Controller Max",
        "number": 1064,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "",
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "min_inlet": {
        "id": "min_inlet",
        "name": "min_inlet",
        "name_orig": "Min Inlet",
        "nameDisplay": "Min Inlet",
        "number": 1065,
        "desc": "Low temp : Minimum inlet °C (Lowest accepted °C before the unit is stopped and alarm is set)",
        "type": "int",
        "min": -30,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "min_outlet": {
        "id": "min_outlet",
        "name": "min_outlet",
        "name_orig": "Min Outlet",
        "nameDisplay": "Min Outlet",
        "number": 1066,
        "desc": "Low temp : Minimum Exhaust °C (Lowest accepted °C before the unit is stopped and alarm is set)",
        "type": "int",
        "min": -30,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "unbalance_et_controller_ref": {
        "id": "unbalance_et_controller_ref",
        "name": "unbalance_et_controller_ref",
        "name_orig": "Unbalance ET Controller Ref",
        "nameDisplay": "Unbalance ET Controller Ref",
        "number": 1067,
        "desc": "",
        "type": "int",
        "min": -30,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Process Configuration\\Low Temp"
    },
    "pre_heater_installed": {
        "id": "pre_heater_installed",
        "name": "pre_heater_installed",
        "name_orig": "Pre Heater Installed",
        "nameDisplay": "Pre Heater Installed",
        "number": 1068,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Heating Surfaces",
        "enumType": "HEATER_TYPE",
        "options": {
            "0": "Off",
            "1": "Electric",
            "2": "Water"
        }
    },
    "virtual_pre_heater_options": {
        "id": "virtual_pre_heater_options",
        "name": "virtual_pre_heater_options",
        "name_orig": "Virtual Pre Heater Options",
        "nameDisplay": "Virtual Pre Heater Options",
        "number": 1069,
        "desc": "Comfort Heaters that are allowed for Virtual Pre Heater",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Heating Surfaces",
        "enumType": "HEATER_VPH_TYPES",
        "options": {
            "0": "Off",
            "1": "Electric",
            "2": "Water",
            "3": "Electric or Water"
        }
    },
    "preheater_controller_kp": {
        "id": "preheater_controller_kp",
        "name": "preheater_controller_kp",
        "name_orig": "Preheater Controller KP",
        "nameDisplay": "Preheater Controller KP",
        "number": 1070,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Preheater"
    },
    "preheater_controller_ki": {
        "id": "preheater_controller_ki",
        "name": "preheater_controller_ki",
        "name_orig": "Preheater Controller KI",
        "nameDisplay": "Preheater Controller KI",
        "number": 1071,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Preheater"
    },
    "preheater_controller_kd": {
        "id": "preheater_controller_kd",
        "name": "preheater_controller_kd",
        "name_orig": "Preheater Controller KD",
        "nameDisplay": "Preheater Controller KD",
        "number": 1072,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Preheater"
    },
    "preheater_controller_ka": {
        "id": "preheater_controller_ka",
        "name": "preheater_controller_ka",
        "name_orig": "Preheater Controller KA",
        "nameDisplay": "Preheater Controller KA",
        "number": 1073,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Preheater"
    },
    "preheater_controller_max": {
        "id": "preheater_controller_max",
        "name": "preheater_controller_max",
        "name_orig": "Preheater Controller Max",
        "nameDisplay": "Preheater Controller Max",
        "number": 1074,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\Heating\\Preheater"
    },
    "start_priority_1": {
        "id": "start_priority_1",
        "name": "start_priority_1",
        "name_orig": "Start priority 1",
        "nameDisplay": "Start priority 1",
        "number": 1075,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority",
        "enumType": "START_PRIORITY_ITEM",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_2": {
        "id": "start_priority_2",
        "name": "start_priority_2",
        "name_orig": "Start priority 2",
        "nameDisplay": "Start priority 2",
        "number": 1076,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority",
        "enumType": "START_PRIORITY_ITEM",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_3": {
        "id": "start_priority_3",
        "name": "start_priority_3",
        "name_orig": "Start priority 3",
        "nameDisplay": "Start priority 3",
        "number": 1077,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority",
        "enumType": "START_PRIORITY_ITEM",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_4": {
        "id": "start_priority_4",
        "name": "start_priority_4",
        "name_orig": "Start priority 4",
        "nameDisplay": "Start priority 4",
        "number": 1078,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority",
        "enumType": "START_PRIORITY_ITEM",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_5": {
        "id": "start_priority_5",
        "name": "start_priority_5",
        "name_orig": "Start priority 5",
        "nameDisplay": "Start priority 5",
        "number": 1079,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority",
        "enumType": "START_PRIORITY_ITEM",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_6": {
        "id": "start_priority_6",
        "name": "start_priority_6",
        "name_orig": "Start priority 6",
        "nameDisplay": "Start priority 6",
        "number": 1080,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 6,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority",
        "enumType": "START_PRIORITY_ITEM",
        "options": {
            "0": "None",
            "1": "External Start",
            "2": "PIR",
            "3": "Flow 0-10V",
            "4": "UI (user interface)",
            "5": "CO2",
            "6": "Timer"
        }
    },
    "start_priority_dependency": {
        "id": "start_priority_dependency",
        "name": "start_priority_dependency",
        "name_orig": "Start Priority Dependency",
        "nameDisplay": "Start Priority Dependency",
        "number": 1081,
        "desc": "Whether the start priorities are independent or dependent on each other",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority"
    },
    "start_priority_night_cooling_independent": {
        "id": "start_priority_night_cooling_independent",
        "name": "start_priority_night_cooling_independent",
        "name_orig": "Start Priority Night Cooling Independent",
        "nameDisplay": "Start Priority Night Cooling Independent",
        "number": 1082,
        "desc": "",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Start Priority"
    },
    "allow_boost": {
        "id": "allow_boost",
        "name": "allow_boost",
        "name_orig": "Allow Boost",
        "nameDisplay": "Allow Boost",
        "number": 1083,
        "desc": "",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 0,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Boost"
    },
    "boost_afterrun_time": {
        "id": "boost_afterrun_time",
        "name": "boost_afterrun_time",
        "name_orig": "Boost Afterrun Time",
        "nameDisplay": "Boost Afterrun Time",
        "number": 1084,
        "desc": "max 99 hours",
        "type": "int",
        "min": 0,
        "max": 1440,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Boost"
    },
    "boost_auto_off_time": {
        "id": "boost_auto_off_time",
        "name": "boost_auto_off_time",
        "name_orig": "Boost Auto Off Time",
        "nameDisplay": "Boost Auto Off Time",
        "number": 1085,
        "desc": "max 99 hours",
        "type": "int",
        "min": 0,
        "max": 1440,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Boost"
    },
    "boost_max_sf_pct": {
        "id": "boost_max_sf_pct",
        "name": "boost_max_sf_pct",
        "name_orig": "Boost Max SF pct",
        "nameDisplay": "Boost Max SF pct",
        "number": 1086,
        "desc": "max supply fan voltage when Boost is requested ",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Operation\\Boost"
    },
    "boost_max_ef_pct": {
        "id": "boost_max_ef_pct",
        "name": "boost_max_ef_pct",
        "name_orig": "Boost Max EF pct",
        "nameDisplay": "Boost Max EF pct",
        "number": 1087,
        "desc": "max exhaust fan voltage when Boost is requested ",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Operation\\Boost"
    },
    "fan_supply_min_pct": {
        "id": "fan_supply_min_pct",
        "name": "fan_supply_min_pct",
        "name_orig": "Fan Supply Min pct",
        "nameDisplay": "Fan Supply Min pct",
        "number": 1088,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_supply_max_pct": {
        "id": "fan_supply_max_pct",
        "name": "fan_supply_max_pct",
        "name_orig": "Fan Supply Max pct",
        "nameDisplay": "Fan Supply Max pct",
        "number": 1089,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_extraction_min_pct": {
        "id": "fan_extraction_min_pct",
        "name": "fan_extraction_min_pct",
        "name_orig": "Fan Extraction Min pct",
        "nameDisplay": "Fan Extraction Min pct",
        "number": 1090,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_extraction_max_pct": {
        "id": "fan_extraction_max_pct",
        "name": "fan_extraction_max_pct",
        "name_orig": "Fan Extraction Max pct",
        "nameDisplay": "Fan Extraction Max pct",
        "number": 1091,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 0.1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "manual_override_time": {
        "id": "manual_override_time",
        "name": "manual_override_time",
        "name_orig": "Manual Override Time",
        "nameDisplay": "Manual Override Time",
        "number": 1092,
        "desc": "OBS: the 99h max, is due to limits in the App (visual implementation)",
        "type": "int",
        "min": 0,
        "max": 99,
        "res": 1,
        "unit": "hrs",
        "default": "",
        "menu": "\\User Interface\\General"
    },
    "bp_min_movement": {
        "id": "bp_min_movement",
        "name": "bp_min_movement",
        "name_orig": "BP Min Movement",
        "nameDisplay": "BP Min Movement",
        "number": 1093,
        "desc": "Minimum movement time for the bypass damper, to avoid flutter",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass"
    },
    "external_emergency_function": {
        "id": "external_emergency_function",
        "name": "external_emergency_function",
        "name_orig": "External Emergency Function",
        "nameDisplay": "External Emergency Function",
        "number": 1094,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Misc",
        "enumType": "EXTERNAL_EMERGENCY_FUNC",
        "options": {
            "0": "None",
            "1": "Warning",
            "2": "Alarm"
        }
    },
    "night_cooling_low_limit": {
        "id": "night_cooling_low_limit",
        "name": "night_cooling_low_limit",
        "name_orig": "Night Cooling Low Limit",
        "nameDisplay": "Night Cooling Low Limit",
        "number": 1127,
        "desc": "Night cooling: Low limit. Daytime temperature to maintain Night Cooling",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Night Cooling"
    },
    "night_cooling_high_limit": {
        "id": "night_cooling_high_limit",
        "name": "night_cooling_high_limit",
        "name_orig": "Night Cooling High Limit",
        "nameDisplay": "Night Cooling High Limit",
        "number": 1128,
        "desc": "Night cooling: High limit. Daytime temperature to initiate Night Cooling",
        "type": "decimal",
        "min": 0,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Night Cooling"
    },
    "night_cooling_min_ot": {
        "id": "night_cooling_min_ot",
        "name": "night_cooling_min_ot",
        "name_orig": "Night Cooling Min OT",
        "nameDisplay": "Night Cooling Min OT",
        "number": 1129,
        "desc": "Night cooling: minimum outside temperature",
        "type": "decimal",
        "min": -20,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Night Cooling"
    },
    "co2_sensor_installed_ar": {
        "id": "co2_sensor_installed_ar",
        "name": "co2_sensor_installed_ar",
        "name_orig": "CO2 Sensor Installed AR",
        "nameDisplay": "CO2 Sensor Installed AR",
        "number": 1130,
        "desc": "CO2 sensor installed: Air Room",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2",
        "enumType": "CO2_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "analog_co2_sensor_ppm_range_min": {
        "id": "analog_co2_sensor_ppm_range_min",
        "name": "analog_co2_sensor_ppm_range_min",
        "name_orig": "Analog CO2 Sensor ppm Range Min",
        "nameDisplay": "Analog CO2 Sensor ppm Range Min",
        "number": 1131,
        "desc": "Minimum CO2 concentration for the CO2 sensor",
        "type": "int",
        "min": 0,
        "max": 65000,
        "res": 1,
        "unit": "ppm",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2"
    },
    "analog_co2_sensor_ppm_range_max": {
        "id": "analog_co2_sensor_ppm_range_max",
        "name": "analog_co2_sensor_ppm_range_max",
        "name_orig": "Analog CO2 Sensor ppm Range Max",
        "nameDisplay": "Analog CO2 Sensor ppm Range Max",
        "number": 1132,
        "desc": "Maximum CO2 concentration for the CO2 sensor",
        "type": "int",
        "min": 0,
        "max": 65000,
        "res": 1,
        "unit": "ppm",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2"
    },
    "analog_co2_sensor_volt_range_min": {
        "id": "analog_co2_sensor_volt_range_min",
        "name": "analog_co2_sensor_volt_range_min",
        "name_orig": "Analog CO2 Sensor Volt Range Min",
        "nameDisplay": "Analog CO2 Sensor Volt Range Min",
        "number": 1133,
        "desc": "Mimum Volt for the CO2 sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2"
    },
    "analog_co2_sensor_volt_range_max": {
        "id": "analog_co2_sensor_volt_range_max",
        "name": "analog_co2_sensor_volt_range_max",
        "name_orig": "Analog CO2 Sensor Volt Range Max",
        "nameDisplay": "Analog CO2 Sensor Volt Range Max",
        "number": 1134,
        "desc": "Maximum Volt for the CO2 sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2"
    },
    "cds_pumpe_operation": {
        "id": "cds_pumpe_operation",
        "name": "cds_pumpe_operation",
        "name_orig": "CDS Pumpe Operation",
        "nameDisplay": "CDS Pumpe Operation",
        "number": 1135,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Condensation",
        "enumType": "CONDENSATE_PUMP_OP",
        "options": {
            "0": "Off",
            "1": "Common",
            "2": "Individual"
        }
    },
    "condensation_retries_extraction": {
        "id": "condensation_retries_extraction",
        "name": "condensation_retries_extraction",
        "name_orig": "Condensation Retries Extraction",
        "nameDisplay": "Condensation Retries Extraction",
        "number": 1136,
        "desc": "Number of retries with the interval \"T Evaporate\"",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Condensation"
    },
    "condensation_evaporation_time": {
        "id": "condensation_evaporation_time",
        "name": "condensation_evaporation_time",
        "name_orig": "Condensation Evaporation Time",
        "nameDisplay": "Condensation Evaporation Time",
        "number": 1137,
        "desc": "Evaporation Time between condensate tests",
        "type": "int",
        "min": 0,
        "max": 60,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Process Configuration\\Condensation"
    },
    "sf_cor_a_coeff": {
        "id": "sf_cor_a_coeff",
        "name": "sf_cor_a_coeff",
        "name_orig": "SF COR A Coeff",
        "nameDisplay": "SF COR A Coeff",
        "number": 1138,
        "desc": "SF COR A Coefficient",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "sf_cor_b_coeff": {
        "id": "sf_cor_b_coeff",
        "name": "sf_cor_b_coeff",
        "name_orig": "SF COR B Coeff",
        "nameDisplay": "SF COR B Coeff",
        "number": 1139,
        "desc": "SF COR B Coefficient",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "sf_cor_c_coeff": {
        "id": "sf_cor_c_coeff",
        "name": "sf_cor_c_coeff",
        "name_orig": "SF COR C Coeff",
        "nameDisplay": "SF COR C Coeff",
        "number": 1140,
        "desc": "SF COR C Coefficient",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "sf_cor_bp_lo_lim": {
        "id": "sf_cor_bp_lo_lim",
        "name": "sf_cor_bp_lo_lim",
        "name_orig": "SF COR BP Lo Lim",
        "nameDisplay": "SF COR BP Lo Lim",
        "number": 1141,
        "desc": "The minimum BP Position that affects the SF COR",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "sf_cor_bp_hi_lim": {
        "id": "sf_cor_bp_hi_lim",
        "name": "sf_cor_bp_hi_lim",
        "name_orig": "SF COR BP Hi Lim",
        "nameDisplay": "SF COR BP Hi Lim",
        "number": 1142,
        "desc": "The maximum BP Position that affects the SF COR",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "sf_cor_lo": {
        "id": "sf_cor_lo",
        "name": "sf_cor_lo",
        "name_orig": "SF COR Lo",
        "nameDisplay": "SF COR Lo",
        "number": 1143,
        "desc": "The minimum SF COR when BP is open",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "timer_schedule_1_mode": {
        "id": "timer_schedule_1_mode",
        "name": "timer_schedule_1_mode",
        "name_orig": "Timer Schedule 1 Mode",
        "nameDisplay": "Timer Schedule 1 Mode",
        "number": 1144,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 1",
        "enumType": "SCHEDULE_MODE",
        "options": {
            "0": "OFF",
            "1": "Standard Ventilation",
            "2": "Night Cooling",
            "3": "Boost"
        }
    },
    "timer_schedule_1_days": {
        "id": "timer_schedule_1_days",
        "name": "timer_schedule_1_days",
        "name_orig": "Timer Schedule 1 Days",
        "nameDisplay": "Timer Schedule 1 Days",
        "number": 1145,
        "desc": "{0=[Monday|Inactive|Active],\n1=[Tuesday|Inactive|Active],\n2=[wednesday|Inactive|Active],\n3=[Thursday|Inactive|Active,\n4=[Friday|Inactive|Active,\n5=[Saturday|Inactive|Active,\n6=[Sunday|Inactive|Active}",
        "type": "int",
        "min": 0,
        "max": 127,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 1"
    },
    "timer_schedule_1_start_time": {
        "id": "timer_schedule_1_start_time",
        "name": "timer_schedule_1_start_time",
        "name_orig": "Timer Schedule 1 Start Time",
        "nameDisplay": "Timer Schedule 1 Start Time",
        "number": 1146,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 1"
    },
    "timer_schedule_1_duration": {
        "id": "timer_schedule_1_duration",
        "name": "timer_schedule_1_duration",
        "name_orig": "Timer Schedule 1 Duration",
        "nameDisplay": "Timer Schedule 1 Duration",
        "number": 1147,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 1"
    },
    "timer_schedule_1_flow": {
        "id": "timer_schedule_1_flow",
        "name": "timer_schedule_1_flow",
        "name_orig": "Timer Schedule 1 Flow",
        "nameDisplay": "Timer Schedule 1 Flow",
        "number": 1148,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 1"
    },
    "timer_schedule_1_temperature": {
        "id": "timer_schedule_1_temperature",
        "name": "timer_schedule_1_temperature",
        "name_orig": "Timer Schedule 1 Temperature",
        "nameDisplay": "Timer Schedule 1 Temperature",
        "number": 1149,
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 1"
    },
    "filter_test_mode": {
        "id": "filter_test_mode",
        "name": "filter_test_mode",
        "name_orig": "Filter Test Mode",
        "nameDisplay": "Filter Test Mode",
        "number": 1150,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Filter",
        "enumType": "FILTER_TEST_MODE",
        "options": {
            "0": "Off",
            "1": "Timer",
            "2": "Lifetime",
            "3": "Timer and Lifetime"
        }
    },
    "filter_test_hours_warning": {
        "id": "filter_test_hours_warning",
        "name": "filter_test_hours_warning",
        "name_orig": "Filter Test Hours Warning",
        "nameDisplay": "Filter Test Hours Warning",
        "number": 1151,
        "desc": "Time before filter Warning in hours",
        "type": "int",
        "min": 0,
        "max": 8760,
        "res": 1,
        "unit": "hour",
        "default": "",
        "menu": "\\Process Configuration\\Filter"
    },
    "filter_test_hours_alarm": {
        "id": "filter_test_hours_alarm",
        "name": "filter_test_hours_alarm",
        "name_orig": "Filter Test Hours Alarm",
        "nameDisplay": "Filter Test Hours Alarm",
        "number": 1152,
        "desc": "Time before filter Alarm in hours",
        "type": "int",
        "min": 0,
        "max": 8760,
        "res": 1,
        "unit": "hour",
        "default": "",
        "menu": "\\Process Configuration\\Filter"
    },
    "filter_test_max_lifetime_warning_period": {
        "id": "filter_test_max_lifetime_warning_period",
        "name": "filter_test_max_lifetime_warning_period",
        "name_orig": "Filter Test Max Lifetime Warning Period",
        "nameDisplay": "Filter Test Max Lifetime Warning Period",
        "number": 1153,
        "desc": "Warning period in months prior to the alarm.",
        "type": "int",
        "min": 0,
        "max": 12,
        "res": 1,
        "unit": "month(s)",
        "default": "",
        "menu": "\\Process Configuration\\Filter"
    },
    "filter_test_max_lifetime_alarm": {
        "id": "filter_test_max_lifetime_alarm",
        "name": "filter_test_max_lifetime_alarm",
        "name_orig": "Filter Test Max Lifetime Alarm",
        "nameDisplay": "Filter Test Max Lifetime Alarm",
        "number": 1154,
        "desc": "Alarm is activated when the filter is \"Filter Max Life Time, Alarm\" old. (0 months disables the Filter Max Life Time Alarm)",
        "type": "int",
        "min": 0,
        "max": 48,
        "res": 1,
        "unit": "month(s)",
        "default": "",
        "menu": "\\Process Configuration\\Filter"
    },
    "status_led_active_start_time": {
        "id": "status_led_active_start_time",
        "name": "status_led_active_start_time",
        "name_orig": "Status LED Active Start Time",
        "nameDisplay": "Status LED Active Start Time",
        "number": 1155,
        "desc": "Minutes since midnight",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Schedule\\Status LED"
    },
    "status_led_active_stop_time": {
        "id": "status_led_active_stop_time",
        "name": "status_led_active_stop_time",
        "name_orig": "Status LED Active Stop Time",
        "nameDisplay": "Status LED Active Stop Time",
        "number": 1156,
        "desc": "Minutes since midnight",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Schedule\\Status LED"
    },
    "external_emergency_contact": {
        "id": "external_emergency_contact",
        "name": "external_emergency_contact",
        "name_orig": "External Emergency Contact",
        "nameDisplay": "External Emergency Contact",
        "number": 1157,
        "desc": "if the External Emergency contact is \"Normal Open\" or \"Normal Closed\"",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Misc",
        "enumType": "EXTERNAL_EMERGENCY_REL",
        "options": {
            "0": "NO",
            "1": "NC"
        }
    },
    "timer_schedule_2_mode": {
        "id": "timer_schedule_2_mode",
        "name": "timer_schedule_2_mode",
        "name_orig": "Timer Schedule 2 Mode",
        "nameDisplay": "Timer Schedule 2 Mode",
        "number": 1158,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 2",
        "enumType": "SCHEDULE_MODE",
        "options": {
            "0": "OFF",
            "1": "Standard Ventilation",
            "2": "Night Cooling",
            "3": "Boost"
        }
    },
    "timer_schedule_2_days": {
        "id": "timer_schedule_2_days",
        "name": "timer_schedule_2_days",
        "name_orig": "Timer Schedule 2 Days",
        "nameDisplay": "Timer Schedule 2 Days",
        "number": 1159,
        "desc": "{0=[Monday|Inactive|Active],\n1=[Tuesday|Inactive|Active],\n2=[wednesday|Inactive|Active],\n3=[Thursday|Inactive|Active,\n4=[Friday|Inactive|Active,\n5=[Saturday|Inactive|Active,\n6=[Sunday|Inactive|Active}",
        "type": "int",
        "min": 0,
        "max": 127,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 2"
    },
    "timer_schedule_2_start_time": {
        "id": "timer_schedule_2_start_time",
        "name": "timer_schedule_2_start_time",
        "name_orig": "Timer Schedule 2 Start Time",
        "nameDisplay": "Timer Schedule 2 Start Time",
        "number": 1160,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 2"
    },
    "timer_schedule_2_duration": {
        "id": "timer_schedule_2_duration",
        "name": "timer_schedule_2_duration",
        "name_orig": "Timer Schedule 2 Duration",
        "nameDisplay": "Timer Schedule 2 Duration",
        "number": 1161,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1439,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 2"
    },
    "timer_schedule_2_flow": {
        "id": "timer_schedule_2_flow",
        "name": "timer_schedule_2_flow",
        "name_orig": "Timer Schedule 2 Flow",
        "nameDisplay": "Timer Schedule 2 Flow",
        "number": 1162,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 2"
    },
    "timer_schedule_2_temperature": {
        "id": "timer_schedule_2_temperature",
        "name": "timer_schedule_2_temperature",
        "name_orig": "Timer Schedule 2 Temperature",
        "nameDisplay": "Timer Schedule 2 Temperature",
        "number": 1163,
        "desc": "",
        "type": "decimal",
        "min": 8,
        "max": 50,
        "res": 1,
        "unit": "°C",
        "default": "",
        "menu": "\\Operation\\Schedule\\Schedule 2"
    },
    "loop_test_period": {
        "id": "loop_test_period",
        "name": "loop_test_period",
        "name_orig": "Loop Test Period",
        "nameDisplay": "Loop Test Period",
        "number": 1164,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1080,
        "res": 1,
        "unit": "min",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_fans_initial": {
        "id": "loop_test_fans_initial",
        "name": "loop_test_fans_initial",
        "name_orig": "Loop Test Fans Initial",
        "nameDisplay": "Loop Test Fans Initial",
        "number": 1165,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_fans_final": {
        "id": "loop_test_fans_final",
        "name": "loop_test_fans_final",
        "name_orig": "Loop Test Fans Final",
        "nameDisplay": "Loop Test Fans Final",
        "number": 1166,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_fans_cycles": {
        "id": "loop_test_fans_cycles",
        "name": "loop_test_fans_cycles",
        "name_orig": "Loop Test Fans Cycles",
        "nameDisplay": "Loop Test Fans Cycles",
        "number": 1167,
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_bp_initial": {
        "id": "loop_test_bp_initial",
        "name": "loop_test_bp_initial",
        "name_orig": "Loop Test BP Initial",
        "nameDisplay": "Loop Test BP Initial",
        "number": 1168,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_bp_final": {
        "id": "loop_test_bp_final",
        "name": "loop_test_bp_final",
        "name_orig": "Loop Test BP Final",
        "nameDisplay": "Loop Test BP Final",
        "number": 1169,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_bp_cycles": {
        "id": "loop_test_bp_cycles",
        "name": "loop_test_bp_cycles",
        "name_orig": "Loop Test BP Cycles",
        "nameDisplay": "Loop Test BP Cycles",
        "number": 1170,
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "ble_advertisement_attenuation": {
        "id": "ble_advertisement_attenuation",
        "name": "ble_advertisement_attenuation",
        "name_orig": "BLE Advertisement Attenuation",
        "nameDisplay": "BLE Advertisement Attenuation",
        "number": 1171,
        "desc": "",
        "type": "int",
        "min": -18,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Factory\\General"
    },
    "support_id": {
        "id": "support_id",
        "name": "support_id",
        "name_orig": "Support ID",
        "nameDisplay": "Support ID",
        "number": 1172,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 13,
        "res": 1,
        "unit": "",
        "default": "",
        "menu": "\\Support\\Support ID",
        "enumType": "SUPPORT_ID",
        "options": {
            "0": "None",
            "1": "Airmaster AS",
            "2": "Airmaster AB",
            "3": "Airmaster Ltd.",
            "4": "Airmaster AS_DUPLICATE",
            "5": "Helios Ventilateurs",
            "6": "Wesco AG",
            "7": "Itho Daalderop",
            "8": "SAV Systems Ltd.",
            "9": "Airflow Lufttechnik GmbH",
            "10": "Johann WERNIG KG",
            "11": "Sandviken Center -ventilasjonsservice AS",
            "12": "Airmaster AS Norge",
            "13": "AT-Air OY"
        }
    },
    "loop_test_extp_initial": {
        "id": "loop_test_extp_initial",
        "name": "loop_test_extp_initial",
        "name_orig": "Loop Test ExtP Initial",
        "nameDisplay": "Loop Test ExtP Initial",
        "number": 1189,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_extp_final": {
        "id": "loop_test_extp_final",
        "name": "loop_test_extp_final",
        "name_orig": "Loop Test ExtP Final",
        "nameDisplay": "Loop Test ExtP Final",
        "number": 1190,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_extp_cycles": {
        "id": "loop_test_extp_cycles",
        "name": "loop_test_extp_cycles",
        "name_orig": "Loop Test ExtP Cycles",
        "nameDisplay": "Loop Test ExtP Cycles",
        "number": 1191,
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "sf_cor_rqf_scale": {
        "id": "sf_cor_rqf_scale",
        "name": "sf_cor_rqf_scale",
        "name_orig": "SF COR RQF Scale",
        "nameDisplay": "SF COR RQF Scale",
        "number": 1192,
        "desc": "How much RQF affects the SF Correction",
        "type": "decimal",
        "min": -1,
        "max": 1,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\High Temp"
    },
    "special_function_request": {
        "id": "special_function_request",
        "name": "special_function_request",
        "name_orig": "Special Function Request",
        "nameDisplay": "Special Function Request",
        "number": 1193,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Factory\\General",
        "enumType": "SPECIAL_FUNCTION_REQ",
        "options": {
            "43": "Reset NO save",
            "0 ": "Normal Operation",
            "1 ": "Factory Test",
            "2 ": "Loop Test",
            "42 ": "Save and Reboot",
            "66 ": "Switch to Boot Loader Mode",
            "192 ": "Clear Parameter Flash",
            "204 ": "Clear System Flash (Row3 - Calibration Etc.)",
            "205 ": "Mounting Full (with i2c gpio)",
            "206 ": "Mounting Basic (no i2c gpio)"
        }
    },
    "hvac_mode": {
        "id": "hvac_mode",
        "name": "hvac_mode",
        "name_orig": "HVAC Mode",
        "nameDisplay": "HVAC Mode",
        "number": 1194,
        "desc": "Cooling Installed",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC",
        "enumType": "HVAC_MODE",
        "options": {
            "0 ": "Ventilation only",
            "1 ": "Cooling",
            "2 ": "Heating",
            "3 ": "Cooling and Heating"
        }
    },
    "hvac_four_way_valve_active": {
        "id": "hvac_four_way_valve_active",
        "name": "hvac_four_way_valve_active",
        "name_orig": "HVAC Four Way Valve Active",
        "nameDisplay": "HVAC Four Way Valve Active",
        "number": 1195,
        "desc": "Activate to perform the selected task",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC",
        "enumType": "HVAC_4W_VALVE_ACTIVE",
        "options": {
            "0": "Cooling",
            "1": "Heating"
        }
    },
    "hvac_fc_on_delay": {
        "id": "hvac_fc_on_delay",
        "name": "hvac_fc_on_delay",
        "name_orig": "HVAC FC On Delay",
        "nameDisplay": "HVAC FC On Delay",
        "number": 1196,
        "desc": "Ensure pressure equalization, before powering compressor, if not built in to the frequency converter",
        "type": "int",
        "min": 0,
        "max": 600,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_power_up_time": {
        "id": "hvac_fc_power_up_time",
        "name": "hvac_fc_power_up_time",
        "name_orig": "HVAC FC Power Up Time",
        "nameDisplay": "HVAC FC Power Up Time",
        "number": 1197,
        "desc": "Time from when the frequency converter is powered, until ready to control (i.e. wait for pressure equalization)",
        "type": "int",
        "min": 0,
        "max": 600,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_power_down_time": {
        "id": "hvac_fc_power_down_time",
        "name": "hvac_fc_power_down_time",
        "name_orig": "HVAC FC Power Down Time",
        "nameDisplay": "HVAC FC Power Down Time",
        "number": 1198,
        "desc": "Time for the Frequency converter to speed down",
        "type": "int",
        "min": 0,
        "max": 600,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_volt_cooling_min": {
        "id": "hvac_fc_volt_cooling_min",
        "name": "hvac_fc_volt_cooling_min",
        "name_orig": "HVAC FC Volt Cooling Min",
        "nameDisplay": "HVAC FC Volt Cooling Min",
        "number": 1199,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 1e-10,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_volt_cooling_max": {
        "id": "hvac_fc_volt_cooling_max",
        "name": "hvac_fc_volt_cooling_max",
        "name_orig": "HVAC FC Volt Cooling Max",
        "nameDisplay": "HVAC FC Volt Cooling Max",
        "number": 1200,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 1e-10,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_volt_heating_min": {
        "id": "hvac_fc_volt_heating_min",
        "name": "hvac_fc_volt_heating_min",
        "name_orig": "HVAC FC Volt Heating Min",
        "nameDisplay": "HVAC FC Volt Heating Min",
        "number": 1201,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 1e-10,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_volt_heating_max": {
        "id": "hvac_fc_volt_heating_max",
        "name": "hvac_fc_volt_heating_max",
        "name_orig": "HVAC FC Volt Heating Max",
        "nameDisplay": "HVAC FC Volt Heating Max",
        "number": 1202,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 1e-10,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_hz_cooling_min": {
        "id": "hvac_fc_hz_cooling_min",
        "name": "hvac_fc_hz_cooling_min",
        "name_orig": "HVAC FC Hz Cooling Min",
        "nameDisplay": "HVAC FC Hz Cooling Min",
        "number": 1203,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "Hz",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_hz_cooling_max": {
        "id": "hvac_fc_hz_cooling_max",
        "name": "hvac_fc_hz_cooling_max",
        "name_orig": "HVAC FC Hz Cooling Max",
        "nameDisplay": "HVAC FC Hz Cooling Max",
        "number": 1204,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "Hz",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_hz_heating_min": {
        "id": "hvac_fc_hz_heating_min",
        "name": "hvac_fc_hz_heating_min",
        "name_orig": "HVAC FC Hz Heating Min",
        "nameDisplay": "HVAC FC Hz Heating Min",
        "number": 1205,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "Hz",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_hz_heating_max": {
        "id": "hvac_fc_hz_heating_max",
        "name": "hvac_fc_hz_heating_max",
        "name_orig": "HVAC FC Hz Heating Max",
        "nameDisplay": "HVAC FC Hz Heating Max",
        "number": 1206,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "Hz",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_cc_min_flow": {
        "id": "hvac_cc_min_flow",
        "name": "hvac_cc_min_flow",
        "name_orig": "HVAC CC Min Flow",
        "nameDisplay": "HVAC CC Min Flow",
        "number": 1207,
        "desc": "Cool-Flow",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Cooling"
    },
    "hvac_cc_delay": {
        "id": "hvac_cc_delay",
        "name": "hvac_cc_delay",
        "name_orig": "HVAC CC Delay",
        "nameDisplay": "HVAC CC Delay",
        "number": 1208,
        "desc": "Time from 100% BP until Cooling is allowed",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Cooling"
    },
    "hvac_cc_delta_rqt_min": {
        "id": "hvac_cc_delta_rqt_min",
        "name": "hvac_cc_delta_rqt_min",
        "name_orig": "HVAC CC Delta RQT Min",
        "nameDisplay": "HVAC CC Delta RQT Min",
        "number": 1209,
        "desc": "RQT + this, to activate CC",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 1e-10,
        "unit": "C",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Cooling"
    },
    "hvac_cc_controller_kp": {
        "id": "hvac_cc_controller_kp",
        "name": "hvac_cc_controller_kp",
        "name_orig": "HVAC CC Controller KP",
        "nameDisplay": "HVAC CC Controller KP",
        "number": 1210,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Cooling"
    },
    "hvac_cc_controller_ki": {
        "id": "hvac_cc_controller_ki",
        "name": "hvac_cc_controller_ki",
        "name_orig": "HVAC CC Controller KI",
        "nameDisplay": "HVAC CC Controller KI",
        "number": 1211,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Cooling"
    },
    "hvac_cc_controller_kd": {
        "id": "hvac_cc_controller_kd",
        "name": "hvac_cc_controller_kd",
        "name_orig": "HVAC CC Controller KD",
        "nameDisplay": "HVAC CC Controller KD",
        "number": 1212,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Cooling"
    },
    "hvac_cc_controller_ka": {
        "id": "hvac_cc_controller_ka",
        "name": "hvac_cc_controller_ka",
        "name_orig": "HVAC CC Controller KA",
        "nameDisplay": "HVAC CC Controller KA",
        "number": 1213,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Cooling"
    },
    "hvac_hgt_controller_kp": {
        "id": "hvac_hgt_controller_kp",
        "name": "hvac_hgt_controller_kp",
        "name_orig": "HVAC HGT Controller KP",
        "nameDisplay": "HVAC HGT Controller KP",
        "number": 1214,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Hot Gas"
    },
    "hvac_hgt_controller_ki": {
        "id": "hvac_hgt_controller_ki",
        "name": "hvac_hgt_controller_ki",
        "name_orig": "HVAC HGT Controller KI",
        "nameDisplay": "HVAC HGT Controller KI",
        "number": 1215,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Hot Gas"
    },
    "hvac_hgt_controller_kd": {
        "id": "hvac_hgt_controller_kd",
        "name": "hvac_hgt_controller_kd",
        "name_orig": "HVAC HGT Controller KD",
        "nameDisplay": "HVAC HGT Controller KD",
        "number": 1216,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Hot Gas"
    },
    "hvac_hgt_controller_ka": {
        "id": "hvac_hgt_controller_ka",
        "name": "hvac_hgt_controller_ka",
        "name_orig": "HVAC HGT Controller KA",
        "nameDisplay": "HVAC HGT Controller KA",
        "number": 1217,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Hot Gas"
    },
    "hvac_hgt_controller_max": {
        "id": "hvac_hgt_controller_max",
        "name": "hvac_hgt_controller_max",
        "name_orig": "HVAC HGT Controller Max",
        "nameDisplay": "HVAC HGT Controller Max",
        "number": 1218,
        "desc": "Controller out max",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "C",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Hot Gas"
    },
    "hvac_hgt_controller_set": {
        "id": "hvac_hgt_controller_set",
        "name": "hvac_hgt_controller_set",
        "name_orig": "HVAC HGT Controller Set",
        "nameDisplay": "HVAC HGT Controller Set",
        "number": 1219,
        "desc": "Controller set-point",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "C",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Hot Gas"
    },
    "hvac_hp_controller_kp": {
        "id": "hvac_hp_controller_kp",
        "name": "hvac_hp_controller_kp",
        "name_orig": "HVAC HP Controller KP",
        "nameDisplay": "HVAC HP Controller KP",
        "number": 1220,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Heating"
    },
    "hvac_hp_controller_ki": {
        "id": "hvac_hp_controller_ki",
        "name": "hvac_hp_controller_ki",
        "name_orig": "HVAC HP Controller KI",
        "nameDisplay": "HVAC HP Controller KI",
        "number": 1221,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Heating"
    },
    "hvac_hp_controller_kd": {
        "id": "hvac_hp_controller_kd",
        "name": "hvac_hp_controller_kd",
        "name_orig": "HVAC HP Controller KD",
        "nameDisplay": "HVAC HP Controller KD",
        "number": 1222,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Heating"
    },
    "hvac_hp_controller_ka": {
        "id": "hvac_hp_controller_ka",
        "name": "hvac_hp_controller_ka",
        "name_orig": "HVAC HP Controller KA",
        "nameDisplay": "HVAC HP Controller KA",
        "number": 1223,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\Heating"
    },
    "hvac_reset_udt_on_power_cycle_only": {
        "id": "hvac_reset_udt_on_power_cycle_only",
        "name": "hvac_reset_udt_on_power_cycle_only",
        "name_orig": "HVAC Reset UDT On Power Cycle Only",
        "nameDisplay": "HVAC Reset UDT On Power Cycle Only",
        "number": 1224,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\General"
    },
    "rci_run_time": {
        "id": "rci_run_time",
        "name": "rci_run_time",
        "name_orig": "RCI Run Time",
        "nameDisplay": "RCI Run Time",
        "number": 1225,
        "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "unit": "sec",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation"
    },
    "rci_open_direction": {
        "id": "rci_open_direction",
        "name": "rci_open_direction",
        "name_orig": "RCI Open Direction",
        "nameDisplay": "RCI Open Direction",
        "number": 1226,
        "desc": "Open Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_DIRECTION",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "rci_cw_rotation": {
        "id": "rci_cw_rotation",
        "name": "rci_cw_rotation",
        "name_orig": "RCI CW Rotation",
        "nameDisplay": "RCI CW Rotation",
        "number": 1227,
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "rci_ccw_rotation": {
        "id": "rci_ccw_rotation",
        "name": "rci_ccw_rotation",
        "name_orig": "RCI CCW Rotation",
        "nameDisplay": "RCI CCW Rotation",
        "number": 1228,
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "aa_mode": {
        "id": "aa_mode",
        "name": "aa_mode",
        "name_orig": "AA Mode",
        "nameDisplay": "AA Mode",
        "number": 1229,
        "desc": "Adaptive Airflow Installed",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Adaptive Airflow Damper",
        "enumType": "DAMPER_MODE",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "aa_activated": {
        "id": "aa_activated",
        "name": "aa_activated",
        "name_orig": "AA Activated",
        "nameDisplay": "AA Activated",
        "number": 1230,
        "desc": "Adaptive Airflow Active",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Adaptive Airflow Damper"
    },
    "aa_run_time": {
        "id": "aa_run_time",
        "name": "aa_run_time",
        "name_orig": "AA Run Time",
        "nameDisplay": "AA Run Time",
        "number": 1231,
        "desc": "RunTime, is the time it takes the actuator to move from end2end",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "unit": "sec",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Adaptive Airflow Damper"
    },
    "aa_upward_direction": {
        "id": "aa_upward_direction",
        "name": "aa_upward_direction",
        "name_orig": "AA Upward Direction",
        "nameDisplay": "AA Upward Direction",
        "number": 1232,
        "desc": "Rotation direction to point the Adaptive Airflow Damper Upward",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Adaptive Airflow Damper",
        "enumType": "DAMPER_DIRECTION",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "aa_cw_rotation": {
        "id": "aa_cw_rotation",
        "name": "aa_cw_rotation",
        "name_orig": "AA CW Rotation",
        "nameDisplay": "AA CW Rotation",
        "number": 1233,
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Adaptive Airflow Damper",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "aa_ccw_rotation": {
        "id": "aa_ccw_rotation",
        "name": "aa_ccw_rotation",
        "name_orig": "AA CCW Rotation",
        "nameDisplay": "AA CCW Rotation",
        "number": 1234,
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Adaptive Airflow Damper",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "aa_self_calibration": {
        "id": "aa_self_calibration",
        "name": "aa_self_calibration",
        "name_orig": "AA Self Calibration",
        "nameDisplay": "AA Self Calibration",
        "number": 1235,
        "desc": "The number of actuator moves allowed, before the actuator performs a self-calibration",
        "type": "int",
        "min": 0,
        "max": 65500,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Adaptive Airflow Damper"
    },
    "loop_test_adaptive_airflow_initial": {
        "id": "loop_test_adaptive_airflow_initial",
        "name": "loop_test_adaptive_airflow_initial",
        "name_orig": "Loop Test Adaptive Airflow Initial",
        "nameDisplay": "Loop Test Adaptive Airflow Initial",
        "number": 1236,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_adaptive_airflow_final": {
        "id": "loop_test_adaptive_airflow_final",
        "name": "loop_test_adaptive_airflow_final",
        "name_orig": "Loop Test Adaptive Airflow Final",
        "nameDisplay": "Loop Test Adaptive Airflow Final",
        "number": 1237,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "loop_test_adaptive_airflowcycles": {
        "id": "loop_test_adaptive_airflowcycles",
        "name": "loop_test_adaptive_airflowcycles",
        "name_orig": "Loop Test Adaptive AirflowCycles",
        "nameDisplay": "Loop Test Adaptive AirflowCycles",
        "number": 1238,
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 100,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Factory\\Loop Test"
    },
    "bp_activated": {
        "id": "bp_activated",
        "name": "bp_activated",
        "name_orig": "BP Activated",
        "nameDisplay": "BP Activated",
        "number": 1239,
        "desc": "",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Bypass"
    },
    "rci_mode": {
        "id": "rci_mode",
        "name": "rci_mode",
        "name_orig": "RCI Mode",
        "nameDisplay": "RCI Mode",
        "number": 1240,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_MODE",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "modbus_rtu_baud_rate": {
        "id": "modbus_rtu_baud_rate",
        "name": "modbus_rtu_baud_rate",
        "name_orig": "Modbus RTU Baud Rate",
        "nameDisplay": "Modbus RTU Baud Rate",
        "number": 1241,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 5,
        "res": "",
        "unit": "bit/s",
        "default": "",
        "menu": "\\Hardware Configuration\\Modbus RTU",
        "enumType": "BAUD_RATE",
        "options": {
            "0": "9600",
            "1": "19200",
            "2": "38400",
            "3": "57600",
            "4": "115200",
            "5": "230400"
        }
    },
    "modbus_rtu_parity": {
        "id": "modbus_rtu_parity",
        "name": "modbus_rtu_parity",
        "name_orig": "Modbus RTU Parity",
        "nameDisplay": "Modbus RTU Parity",
        "number": 1242,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Modbus RTU",
        "enumType": "PARITY",
        "options": {
            "0": "None (2 stop bits)",
            "1": "Odd (1 stop bit)",
            "2": "Even (1 stop bit)"
        }
    },
    "modbus_rtu_frequency_converter_address": {
        "id": "modbus_rtu_frequency_converter_address",
        "name": "modbus_rtu_frequency_converter_address",
        "name_orig": "Modbus RTU Frequency Converter Address",
        "nameDisplay": "Modbus RTU Frequency Converter Address",
        "number": 1243,
        "desc": "",
        "type": "int",
        "min": 1,
        "max": 247,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Modbus RTU"
    },
    "fan_supply_control": {
        "id": "fan_supply_control",
        "name": "fan_supply_control",
        "name_orig": "Fan Supply Control",
        "nameDisplay": "Fan Supply Control",
        "number": 1244,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan",
        "enumType": "FAN_CONTROL",
        "options": {
            "0": "Off",
            "1": "Voltage",
            "2": "PWM",
            "3": "Modbus"
        }
    },
    "fan_supply_make": {
        "id": "fan_supply_make",
        "name": "fan_supply_make",
        "name_orig": "Fan Supply Make",
        "nameDisplay": "Fan Supply Make",
        "number": 1245,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 0,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_supply_model": {
        "id": "fan_supply_model",
        "name": "fan_supply_model",
        "name_orig": "Fan Supply Model",
        "nameDisplay": "Fan Supply Model",
        "number": 1246,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 0,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_supply_tacho_ppr": {
        "id": "fan_supply_tacho_ppr",
        "name": "fan_supply_tacho_ppr",
        "name_orig": "Fan Supply Tacho PPR",
        "nameDisplay": "Fan Supply Tacho PPR",
        "number": 1247,
        "desc": "Tacho Pulses pr. Revolution",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "puls/revolution",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_extraction_control": {
        "id": "fan_extraction_control",
        "name": "fan_extraction_control",
        "name_orig": "Fan Extraction Control",
        "nameDisplay": "Fan Extraction Control",
        "number": 1248,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan",
        "enumType": "FAN_CONTROL",
        "options": {
            "0": "Off",
            "1": "Voltage",
            "2": "PWM",
            "3": "Modbus"
        }
    },
    "fan_extraction_make": {
        "id": "fan_extraction_make",
        "name": "fan_extraction_make",
        "name_orig": "Fan Extraction Make",
        "nameDisplay": "Fan Extraction Make",
        "number": 1249,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 0,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_extraction_model": {
        "id": "fan_extraction_model",
        "name": "fan_extraction_model",
        "name_orig": "Fan Extraction Model",
        "nameDisplay": "Fan Extraction Model",
        "number": 1250,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 0,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "fan_extraction_tacho_ppr": {
        "id": "fan_extraction_tacho_ppr",
        "name": "fan_extraction_tacho_ppr",
        "name_orig": "Fan Extraction Tacho PPR",
        "nameDisplay": "Fan Extraction Tacho PPR",
        "number": 1251,
        "desc": "Tacho Pulses pr. Revolution",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "puls/revolution",
        "default": "",
        "menu": "\\Hardware Configuration\\Fan"
    },
    "cds_lvl_sensor_sup_phe": {
        "id": "cds_lvl_sensor_sup_phe",
        "name": "cds_lvl_sensor_sup_phe",
        "name_orig": "CDS Lvl Sensor Sup PHE",
        "nameDisplay": "CDS Lvl Sensor Sup PHE",
        "number": 1252,
        "desc": "w. fb.: With Feedback,\nwo. fb.: Without Feedback,\nWhere 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": 0,
        "max": 7,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Condensation",
        "enumType": "CONDENSATE_SENSOR",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "cds_lvl_sensor_sup_ahe": {
        "id": "cds_lvl_sensor_sup_ahe",
        "name": "cds_lvl_sensor_sup_ahe",
        "name_orig": "CDS Lvl Sensor Sup AHE",
        "nameDisplay": "CDS Lvl Sensor Sup AHE",
        "number": 1253,
        "desc": "w. fb.: With Feedback,\nwo. fb.: Without Feedback,\nWhere 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": 0,
        "max": 7,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Condensation",
        "enumType": "CONDENSATE_SENSOR",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "cds_lvl_sensor_ext_phe": {
        "id": "cds_lvl_sensor_ext_phe",
        "name": "cds_lvl_sensor_ext_phe",
        "name_orig": "CDS Lvl Sensor Ext PHE",
        "nameDisplay": "CDS Lvl Sensor Ext PHE",
        "number": 1254,
        "desc": "w. fb.: With Feedback,\nwo. fb.: Without Feedback,\nWhere 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": 0,
        "max": 7,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Condensation",
        "enumType": "CONDENSATE_SENSOR",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "cds_lvl_sensor_ext_ahe": {
        "id": "cds_lvl_sensor_ext_ahe",
        "name": "cds_lvl_sensor_ext_ahe",
        "name_orig": "CDS Lvl Sensor Ext AHE",
        "nameDisplay": "CDS Lvl Sensor Ext AHE",
        "number": 1255,
        "desc": "w. fb.: With Feedback,\nwo. fb.: Without Feedback,\nWhere 'feedback' is a signal telling if the sensor is installed or not.",
        "type": "enum",
        "min": 0,
        "max": 7,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Condensation",
        "enumType": "CONDENSATE_SENSOR",
        "options": {
            "0": "Not Installed",
            "1": "Float Switch (NC)",
            "2": "Electrode Single Lvl Alarm Hi w. fb.",
            "3": "Electrode Single Lvl Alarm Hi wo. fb.",
            "4": "Electrode All Alarm Lvl w. fb.",
            "5": "Electrode All Alarm Lvl wo. fb.",
            "6": "Electrode Multi Lvl w. fb.",
            "7": "Electrode Multi Lvl wo. fb."
        }
    },
    "condensation_retries_supply": {
        "id": "condensation_retries_supply",
        "name": "condensation_retries_supply",
        "name_orig": "Condensation Retries Supply",
        "nameDisplay": "Condensation Retries Supply",
        "number": 1256,
        "desc": "",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\Condensation"
    },
    "co2_sensor_installed_ai": {
        "id": "co2_sensor_installed_ai",
        "name": "co2_sensor_installed_ai",
        "name_orig": "CO2 Sensor Installed AI",
        "nameDisplay": "CO2 Sensor Installed AI",
        "number": 1257,
        "desc": "CO2 sensor installed: Air Inlet",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2",
        "enumType": "CO2_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "co2_sensor_installed_ae": {
        "id": "co2_sensor_installed_ae",
        "name": "co2_sensor_installed_ae",
        "name_orig": "CO2 Sensor Installed AE",
        "nameDisplay": "CO2 Sensor Installed AE",
        "number": 1258,
        "desc": "CO2 sensor installed: Air Exhaust",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2",
        "enumType": "CO2_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "co2_sensor_installed_ao": {
        "id": "co2_sensor_installed_ao",
        "name": "co2_sensor_installed_ao",
        "name_orig": "CO2 Sensor Installed AO",
        "nameDisplay": "CO2 Sensor Installed AO",
        "number": 1259,
        "desc": "CO2 sensor installed: Air Outside",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\CO2",
        "enumType": "CO2_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "rh_sensor_installed_ai": {
        "id": "rh_sensor_installed_ai",
        "name": "rh_sensor_installed_ai",
        "name_orig": "RH Sensor Installed AI",
        "nameDisplay": "RH Sensor Installed AI",
        "number": 1260,
        "desc": "RH sensor installed: Air Inlet",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity",
        "enumType": "RH_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "rh_sensor_installed_ar": {
        "id": "rh_sensor_installed_ar",
        "name": "rh_sensor_installed_ar",
        "name_orig": "RH Sensor Installed AR",
        "nameDisplay": "RH Sensor Installed AR",
        "number": 1261,
        "desc": "RH sensor installed: Air Room",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity",
        "enumType": "RH_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "rh_sensor_installed_ae": {
        "id": "rh_sensor_installed_ae",
        "name": "rh_sensor_installed_ae",
        "name_orig": "RH Sensor Installed AE",
        "nameDisplay": "RH Sensor Installed AE",
        "number": 1262,
        "desc": "RH sensor installed: Air Exhaust",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity",
        "enumType": "RH_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "rh_sensor_installed_ao": {
        "id": "rh_sensor_installed_ao",
        "name": "rh_sensor_installed_ao",
        "name_orig": "RH Sensor Installed AO",
        "nameDisplay": "RH Sensor Installed AO",
        "number": 1263,
        "desc": "RH sensor installed: Air Outside",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity",
        "enumType": "RH_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V"
        }
    },
    "analog_rh_sensor_pct_range_min": {
        "id": "analog_rh_sensor_pct_range_min",
        "name": "analog_rh_sensor_pct_range_min",
        "name_orig": "Analog RH Sensor pct Range Min",
        "nameDisplay": "Analog RH Sensor pct Range Min",
        "number": 1264,
        "desc": "Minimum RH concentration for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity"
    },
    "analog_rh_sensor_pct_range_max": {
        "id": "analog_rh_sensor_pct_range_max",
        "name": "analog_rh_sensor_pct_range_max",
        "name_orig": "Analog RH Sensor pct Range Max",
        "nameDisplay": "Analog RH Sensor pct Range Max",
        "number": 1265,
        "desc": "Maximum RH concentration for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity"
    },
    "analog_rh_sensor_volt_range_min": {
        "id": "analog_rh_sensor_volt_range_min",
        "name": "analog_rh_sensor_volt_range_min",
        "name_orig": "Analog RH Sensor Volt Range Min",
        "nameDisplay": "Analog RH Sensor Volt Range Min",
        "number": 1266,
        "desc": "Mimum Volt for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity"
    },
    "analog_rh_sensor_volt_range_max": {
        "id": "analog_rh_sensor_volt_range_max",
        "name": "analog_rh_sensor_volt_range_max",
        "name_orig": "Analog RH Sensor Volt Range Max",
        "nameDisplay": "Analog RH Sensor Volt Range Max",
        "number": 1267,
        "desc": "Maximum Volt for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\Humidity"
    },
    "pressure_switch_installed_vic": {
        "id": "pressure_switch_installed_vic",
        "name": "pressure_switch_installed_vic",
        "name_orig": "Pressure Switch Installed VIC",
        "nameDisplay": "Pressure Switch Installed VIC",
        "number": 1268,
        "desc": "Pressure Switch Vapor Into Compressor",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure",
        "enumType": "PRESS_SW_INSTALL",
        "options": {
            "0": "Off",
            "1": "Aware",
            "2": "Frequency Converter"
        }
    },
    "pressure_switch_installed_voc": {
        "id": "pressure_switch_installed_voc",
        "name": "pressure_switch_installed_voc",
        "name_orig": "Pressure Switch Installed VOC",
        "nameDisplay": "Pressure Switch Installed VOC",
        "number": 1269,
        "desc": "Pressure Switch Vapor Out of Compressor",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure",
        "enumType": "PRESS_SW_INSTALL",
        "options": {
            "0": "Off",
            "1": "Aware",
            "2": "Frequency Converter"
        }
    },
    "pressure_transmitter_installed_vti": {
        "id": "pressure_transmitter_installed_vti",
        "name": "pressure_transmitter_installed_vti",
        "name_orig": "Pressure Transmitter Installed VTI",
        "nameDisplay": "Pressure Transmitter Installed VTI",
        "number": 1270,
        "desc": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure",
        "enumType": "PRESS_TR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_lbi": {
        "id": "pressure_transmitter_installed_lbi",
        "name": "pressure_transmitter_installed_lbi",
        "name_orig": "Pressure Transmitter Installed LBI",
        "nameDisplay": "Pressure Transmitter Installed LBI",
        "number": 1271,
        "desc": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure",
        "enumType": "PRESS_TR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_vto": {
        "id": "pressure_transmitter_installed_vto",
        "name": "pressure_transmitter_installed_vto",
        "name_orig": "Pressure Transmitter Installed VTO",
        "nameDisplay": "Pressure Transmitter Installed VTO",
        "number": 1272,
        "desc": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure",
        "enumType": "PRESS_TR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_lbo": {
        "id": "pressure_transmitter_installed_lbo",
        "name": "pressure_transmitter_installed_lbo",
        "name_orig": "Pressure Transmitter Installed LBO",
        "nameDisplay": "Pressure Transmitter Installed LBO",
        "number": 1273,
        "desc": "Pressure Tranmitter Liquid Bottom Outside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure",
        "enumType": "PRESS_TR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "pressure_transmitter_installed_vic": {
        "id": "pressure_transmitter_installed_vic",
        "name": "pressure_transmitter_installed_vic",
        "name_orig": "Pressure Transmitter Installed VIC",
        "nameDisplay": "Pressure Transmitter Installed VIC",
        "number": 1274,
        "desc": "Pressure Tranmitter Vapor Into Compressor",
        "type": "enum",
        "min": 0,
        "max": 4,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure",
        "enumType": "PRESS_TR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog 0-10V",
            "3": "Analog 0V5-3V5",
            "4": "Analog 0-20mA"
        }
    },
    "analog_v_pressure_transmitter_bar_range_min": {
        "id": "analog_v_pressure_transmitter_bar_range_min",
        "name": "analog_v_pressure_transmitter_bar_range_min",
        "name_orig": "Analog V Pressure Transmitter Bar Range Min",
        "nameDisplay": "Analog V Pressure Transmitter Bar Range Min",
        "number": 1275,
        "desc": "Minimum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": -100000,
        "max": 4000000,
        "res": 1,
        "unit": "Bar",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "analog_v_pressure_transmitter_bar_range_max": {
        "id": "analog_v_pressure_transmitter_bar_range_max",
        "name": "analog_v_pressure_transmitter_bar_range_max",
        "name_orig": "Analog V Pressure Transmitter Bar Range Max",
        "nameDisplay": "Analog V Pressure Transmitter Bar Range Max",
        "number": 1276,
        "desc": "Maximum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": -100000,
        "max": 4000000,
        "res": 1,
        "unit": "Bar",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "analog_v_pressure_transmitter_volt_range_min": {
        "id": "analog_v_pressure_transmitter_volt_range_min",
        "name": "analog_v_pressure_transmitter_volt_range_min",
        "name_orig": "Analog V Pressure Transmitter Volt Range Min",
        "nameDisplay": "Analog V Pressure Transmitter Volt Range Min",
        "number": 1277,
        "desc": "Mimum Volt for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "analog_v_pressure_transmitter_volt_range_max": {
        "id": "analog_v_pressure_transmitter_volt_range_max",
        "name": "analog_v_pressure_transmitter_volt_range_max",
        "name_orig": "Analog V Pressure Transmitter Volt Range Max",
        "nameDisplay": "Analog V Pressure Transmitter Volt Range Max",
        "number": 1278,
        "desc": "Maximum Volt for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 10,
        "res": 0.1,
        "unit": "V",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "analog_i_pressure_transmitter_bar_range_min": {
        "id": "analog_i_pressure_transmitter_bar_range_min",
        "name": "analog_i_pressure_transmitter_bar_range_min",
        "name_orig": "Analog I Pressure Transmitter Bar Range Min",
        "nameDisplay": "Analog I Pressure Transmitter Bar Range Min",
        "number": 1279,
        "desc": "Minimum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": -100000,
        "max": 4000000,
        "res": 1,
        "unit": "Bar",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "analog_i_pressure_transmitter_bar_range_max": {
        "id": "analog_i_pressure_transmitter_bar_range_max",
        "name": "analog_i_pressure_transmitter_bar_range_max",
        "name_orig": "Analog I Pressure Transmitter Bar Range Max",
        "nameDisplay": "Analog I Pressure Transmitter Bar Range Max",
        "number": 1280,
        "desc": "Maximum Pressure for the Pressure Transmitter",
        "type": "int",
        "min": -100000,
        "max": 4000000,
        "res": 1,
        "unit": "Bar",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "analog_i_pressure_transmitter_ma_range_min": {
        "id": "analog_i_pressure_transmitter_ma_range_min",
        "name": "analog_i_pressure_transmitter_ma_range_min",
        "name_orig": "Analog I Pressure Transmitter mA Range Min",
        "nameDisplay": "Analog I Pressure Transmitter mA Range Min",
        "number": 1281,
        "desc": "Mimum Current for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 20,
        "res": 0.1,
        "unit": "mA",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "analog_i_pressure_transmitter_ma_range_max": {
        "id": "analog_i_pressure_transmitter_ma_range_max",
        "name": "analog_i_pressure_transmitter_ma_range_max",
        "name_orig": "Analog I Pressure Transmitter mA Range Max",
        "nameDisplay": "Analog I Pressure Transmitter mA Range Max",
        "number": 1282,
        "desc": "Maximum Current for the RH sensor",
        "type": "decimal",
        "min": 0,
        "max": 20,
        "res": 0.1,
        "unit": "mA",
        "default": "",
        "menu": "\\Hardware Configuration\\Pressure"
    },
    "t_sensor_installed_ai": {
        "id": "t_sensor_installed_ai",
        "name": "t_sensor_installed_ai",
        "name_orig": "T Sensor Installed AI",
        "nameDisplay": "T Sensor Installed AI",
        "number": 1283,
        "desc": "Temperature Air Inlet",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_ar": {
        "id": "t_sensor_installed_ar",
        "name": "t_sensor_installed_ar",
        "name_orig": "T Sensor Installed AR",
        "nameDisplay": "T Sensor Installed AR",
        "number": 1284,
        "desc": "Temperature Air Room",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_ae": {
        "id": "t_sensor_installed_ae",
        "name": "t_sensor_installed_ae",
        "name_orig": "T Sensor Installed AE",
        "nameDisplay": "T Sensor Installed AE",
        "number": 1285,
        "desc": "Temperature Air Exhaust",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_ao": {
        "id": "t_sensor_installed_ao",
        "name": "t_sensor_installed_ao",
        "name_orig": "T Sensor Installed AO",
        "nameDisplay": "T Sensor Installed AO",
        "number": 1286,
        "desc": "Temperature Air Outside",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_aop": {
        "id": "t_sensor_installed_aop",
        "name": "t_sensor_installed_aop",
        "name_orig": "T Sensor Installed AOP",
        "nameDisplay": "T Sensor Installed AOP",
        "number": 1287,
        "desc": "Temperature Air Out of Passive heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_aio": {
        "id": "t_sensor_installed_aio",
        "name": "t_sensor_installed_aio",
        "name_orig": "T Sensor Installed AIO",
        "nameDisplay": "T Sensor Installed AIO",
        "number": 1288,
        "desc": "Temperature Air Into Outside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_aii": {
        "id": "t_sensor_installed_aii",
        "name": "t_sensor_installed_aii",
        "name_orig": "T Sensor Installed AII",
        "nameDisplay": "T Sensor Installed AII",
        "number": 1289,
        "desc": "Temperature Air Into Inside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_vto": {
        "id": "t_sensor_installed_vto",
        "name": "t_sensor_installed_vto",
        "name_orig": "T Sensor Installed VTO",
        "nameDisplay": "T Sensor Installed VTO",
        "number": 1290,
        "desc": "Temperature Vapor Top Outside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_vti": {
        "id": "t_sensor_installed_vti",
        "name": "t_sensor_installed_vti",
        "name_orig": "T Sensor Installed VTI",
        "nameDisplay": "T Sensor Installed VTI",
        "number": 1291,
        "desc": "Temperature Vapor Top Inside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_voc": {
        "id": "t_sensor_installed_voc",
        "name": "t_sensor_installed_voc",
        "name_orig": "T Sensor Installed VOC",
        "nameDisplay": "T Sensor Installed VOC",
        "number": 1292,
        "desc": "Temperature Vapor Out of Compressor",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_lbo": {
        "id": "t_sensor_installed_lbo",
        "name": "t_sensor_installed_lbo",
        "name_orig": "T Sensor Installed LBO",
        "nameDisplay": "T Sensor Installed LBO",
        "number": 1293,
        "desc": "Temperature Liquid Bottom Outside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_lbi": {
        "id": "t_sensor_installed_lbi",
        "name": "t_sensor_installed_lbi",
        "name_orig": "T Sensor Installed LBI",
        "nameDisplay": "T Sensor Installed LBI",
        "number": 1294,
        "desc": "Temperature Liquid Bottom Inside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_lie": {
        "id": "t_sensor_installed_lie",
        "name": "t_sensor_installed_lie",
        "name_orig": "T Sensor Installed LIE",
        "nameDisplay": "T Sensor Installed LIE",
        "number": 1295,
        "desc": "Temperature Liquid Into Expansion unit",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_loe": {
        "id": "t_sensor_installed_loe",
        "name": "t_sensor_installed_loe",
        "name_orig": "T Sensor Installed LOE",
        "nameDisplay": "T Sensor Installed LOE",
        "number": 1296,
        "desc": "Temperature Liquid Out of Expansion unit",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_pci": {
        "id": "t_sensor_installed_pci",
        "name": "t_sensor_installed_pci",
        "name_orig": "T Sensor Installed PCI",
        "nameDisplay": "T Sensor Installed PCI",
        "number": 1297,
        "desc": "Temperature Phase Change Inside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_pco": {
        "id": "t_sensor_installed_pco",
        "name": "t_sensor_installed_pco",
        "name_orig": "T Sensor Installed PCO",
        "nameDisplay": "T Sensor Installed PCO",
        "number": 1298,
        "desc": "Temperature Phase Change Outside heat exchanger",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "t_sensor_installed_vic": {
        "id": "t_sensor_installed_vic",
        "name": "t_sensor_installed_vic",
        "name_orig": "T Sensor Installed VIC",
        "nameDisplay": "T Sensor Installed VIC",
        "number": 1299,
        "desc": "Temperature Vapor Into Compressor",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Temperature",
        "enumType": "T_SENSOR_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital",
            "2": "Analog NTC",
            "3": "Pressure"
        }
    },
    "tvoc_sensor_installed_ai": {
        "id": "tvoc_sensor_installed_ai",
        "name": "tvoc_sensor_installed_ai",
        "name_orig": "TVOC Sensor Installed AI",
        "nameDisplay": "TVOC Sensor Installed AI",
        "number": 1300,
        "desc": "TVOC sensor installed: Air Inlet",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\VOC",
        "enumType": "TVOC_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "tvoc_sensor_installed_ar": {
        "id": "tvoc_sensor_installed_ar",
        "name": "tvoc_sensor_installed_ar",
        "name_orig": "TVOC Sensor Installed AR",
        "nameDisplay": "TVOC Sensor Installed AR",
        "number": 1301,
        "desc": "TVOC sensor installed: Air Room",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\VOC",
        "enumType": "TVOC_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "tvoc_sensor_installed_ae": {
        "id": "tvoc_sensor_installed_ae",
        "name": "tvoc_sensor_installed_ae",
        "name_orig": "TVOC Sensor Installed AE",
        "nameDisplay": "TVOC Sensor Installed AE",
        "number": 1302,
        "desc": "TVOC sensor installed: Air Exhaust",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\VOC",
        "enumType": "TVOC_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "tvoc_sensor_installed_ao": {
        "id": "tvoc_sensor_installed_ao",
        "name": "tvoc_sensor_installed_ao",
        "name_orig": "TVOC Sensor Installed AO",
        "nameDisplay": "TVOC Sensor Installed AO",
        "number": 1303,
        "desc": "TVOC sensor installed: Air Outside",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\VOC",
        "enumType": "TVOC_INSTALL",
        "options": {
            "0": "Off",
            "1": "Digital"
        }
    },
    "mde_mode": {
        "id": "mde_mode",
        "name": "mde_mode",
        "name_orig": "MDE Mode",
        "nameDisplay": "MDE Mode",
        "number": 1304,
        "desc": "ON handles both \"TimeControlled\" and \"Self Return\"",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_MODE",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "mde_run_time": {
        "id": "mde_run_time",
        "name": "mde_run_time",
        "name_orig": "MDE Run Time",
        "nameDisplay": "MDE Run Time",
        "number": 1305,
        "desc": "The time it takes for the MD damper to operate from 0-100%",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper"
    },
    "mde_open_direction": {
        "id": "mde_open_direction",
        "name": "mde_open_direction",
        "name_orig": "MDE Open Direction",
        "nameDisplay": "MDE Open Direction",
        "number": 1306,
        "desc": "Open Main Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_DIRECTION",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "mde_cw_rotation": {
        "id": "mde_cw_rotation",
        "name": "mde_cw_rotation",
        "name_orig": "MDE CW Rotation",
        "nameDisplay": "MDE CW Rotation",
        "number": 1307,
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "mde_ccw_rotation": {
        "id": "mde_ccw_rotation",
        "name": "mde_ccw_rotation",
        "name_orig": "MDE CCW Rotation",
        "nameDisplay": "MDE CCW Rotation",
        "number": 1308,
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Main Damper",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "rco_mode": {
        "id": "rco_mode",
        "name": "rco_mode",
        "name_orig": "RCO Mode",
        "nameDisplay": "RCO Mode",
        "number": 1309,
        "desc": "",
        "type": "enum",
        "min": 0,
        "max": 3,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_MODE",
        "options": {
            "0": "Off",
            "1": "Time Controlled",
            "2": "Voltage Controlled",
            "3": "Self Return"
        }
    },
    "rco_run_time": {
        "id": "rco_run_time",
        "name": "rco_run_time",
        "name_orig": "RCO Run Time",
        "nameDisplay": "RCO Run Time",
        "number": 1310,
        "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
        "type": "int",
        "min": 0,
        "max": 999,
        "res": 1,
        "unit": "sec",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation"
    },
    "rco_open_direction": {
        "id": "rco_open_direction",
        "name": "rco_open_direction",
        "name_orig": "RCO Open Direction",
        "nameDisplay": "RCO Open Direction",
        "number": 1311,
        "desc": "Open Damper, the direction the actuator rotates, to open the damper",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_DIRECTION",
        "options": {
            "0": "CW",
            "1": "CCW"
        }
    },
    "rco_cw_rotation": {
        "id": "rco_cw_rotation",
        "name": "rco_cw_rotation",
        "name_orig": "RCO CW Rotation",
        "nameDisplay": "RCO CW Rotation",
        "number": 1312,
        "desc": "Settings to rotate Clock Wise (CW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "rco_ccw_rotation": {
        "id": "rco_ccw_rotation",
        "name": "rco_ccw_rotation",
        "name_orig": "RCO CCW Rotation",
        "nameDisplay": "RCO CCW Rotation",
        "number": 1313,
        "desc": "Settings to rotate Counter Clock Wise (CCW)",
        "type": "enum",
        "min": 0,
        "max": 2,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\Dampers\\Recirculation",
        "enumType": "DAMPER_SIGNAL",
        "options": {
            "0": "L2",
            "1": "L3",
            "2": "L2+L3"
        }
    },
    "eev_controller_kp": {
        "id": "eev_controller_kp",
        "name": "eev_controller_kp",
        "name_orig": "EEV Controller KP",
        "nameDisplay": "EEV Controller KP",
        "number": 1314,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_ki": {
        "id": "eev_controller_ki",
        "name": "eev_controller_ki",
        "name_orig": "EEV Controller KI",
        "nameDisplay": "EEV Controller KI",
        "number": 1315,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_kd": {
        "id": "eev_controller_kd",
        "name": "eev_controller_kd",
        "name_orig": "EEV Controller KD",
        "nameDisplay": "EEV Controller KD",
        "number": 1316,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_ka": {
        "id": "eev_controller_ka",
        "name": "eev_controller_ka",
        "name_orig": "EEV Controller KA",
        "nameDisplay": "EEV Controller KA",
        "number": 1317,
        "desc": "",
        "type": "decimal",
        "min": -1000,
        "max": 1000,
        "res": 1e-10,
        "unit": "-",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_min_output": {
        "id": "eev_controller_min_output",
        "name": "eev_controller_min_output",
        "name_orig": "EEV Controller Min Output",
        "nameDisplay": "EEV Controller Min Output",
        "number": 1318,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1e-10,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_max_output": {
        "id": "eev_controller_max_output",
        "name": "eev_controller_max_output",
        "name_orig": "EEV Controller Max Output",
        "nameDisplay": "EEV Controller Max Output",
        "number": 1319,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1e-10,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_default_position": {
        "id": "eev_controller_default_position",
        "name": "eev_controller_default_position",
        "name_orig": "EEV Controller Default Position",
        "nameDisplay": "EEV Controller Default Position",
        "number": 1320,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1e-10,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_defrost_position": {
        "id": "eev_controller_defrost_position",
        "name": "eev_controller_defrost_position",
        "name_orig": "EEV Controller Defrost Position",
        "nameDisplay": "EEV Controller Defrost Position",
        "number": 1321,
        "desc": "",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1e-10,
        "unit": "%",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "eev_controller_evaporator_dt": {
        "id": "eev_controller_evaporator_dt",
        "name": "eev_controller_evaporator_dt",
        "name_orig": "EEV Controller Evaporator DT",
        "nameDisplay": "EEV Controller Evaporator DT",
        "number": 1322,
        "desc": "",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": 1e-10,
        "unit": "C",
        "default": "",
        "menu": "\\Process Configuration\\HVAC\\EEV"
    },
    "hvac_eev_installed": {
        "id": "hvac_eev_installed",
        "name": "hvac_eev_installed",
        "name_orig": "HVAC EEV Installed",
        "nameDisplay": "HVAC EEV Installed",
        "number": 1323,
        "desc": "",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_eev_steps": {
        "id": "hvac_eev_steps",
        "name": "hvac_eev_steps",
        "name_orig": "HVAC EEV Steps",
        "nameDisplay": "HVAC EEV Steps",
        "number": 1324,
        "desc": "Number of steps for the stepper drive to move the valve from end-to-end.",
        "type": "int",
        "min": 0,
        "max": 520,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_eev_calibration_steps": {
        "id": "hvac_eev_calibration_steps",
        "name": "hvac_eev_calibration_steps",
        "name_orig": "HVAC EEV Calibration Steps",
        "nameDisplay": "HVAC EEV Calibration Steps",
        "number": 1325,
        "desc": "Number of steps for the stepper drive to ensure calibration towards closed position. Typically around end-to-end + [10-50] steps.",
        "type": "int",
        "min": 0,
        "max": 520,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_eev_calibration_time": {
        "id": "hvac_eev_calibration_time",
        "name": "hvac_eev_calibration_time",
        "name_orig": "HVAC EEV Calibration Time",
        "nameDisplay": "HVAC EEV Calibration Time",
        "number": 1326,
        "desc": "The time it takes to calibrate, OBS: must be equal to or larger than the time it takes!",
        "type": "int",
        "min": 0,
        "max": 255,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC"
    },
    "hvac_fc_power_mode": {
        "id": "hvac_fc_power_mode",
        "name": "hvac_fc_power_mode",
        "name_orig": "HVAC FC Power Mode",
        "nameDisplay": "HVAC FC Power Mode",
        "number": 1327,
        "desc": "The time it takes to calibrate, OBS: must be equal to or larger than the time it takes!",
        "type": "enum",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Hardware Configuration\\HVAC",
        "enumType": "HVAC_FC_POWER_MODE",
        "options": {
            "0 ": "Power Off 1"
        }
    },
    "status_led_on_when_running_normal": {
        "id": "status_led_on_when_running_normal",
        "name": "status_led_on_when_running_normal",
        "name_orig": "Status LED On When Running Normal",
        "nameDisplay": "Status LED On When Running Normal",
        "number": 1328,
        "desc": "Controls whether the LED should be green when no warnings or alarms are present",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Schedule\\Status LED"
    },
    "status_led_installed": {
        "id": "status_led_installed",
        "name": "status_led_installed",
        "name_orig": "Status LED Installed",
        "nameDisplay": "Status LED Installed",
        "number": 1329,
        "desc": "",
        "type": "bool",
        "min": 0,
        "max": 1,
        "res": 1,
        "unit": "-",
        "default": "",
        "menu": "\\Operation\\Schedule\\Status LED"
    },
    "purge_duration": {
        "id": "purge_duration",
        "name": "purge_duration",
        "name_orig": "Purge Duration",
        "nameDisplay": "Purge Duration",
        "number": 1330,
        "desc": "The time it takes to purge the system.",
        "type": "int",
        "min": 0,
        "max": 100000,
        "res": 1,
        "unit": "s",
        "default": "",
        "menu": "\\Purge\\Duration"
    },
    "purge_fan_setpoint": {
        "id": "purge_fan_setpoint",
        "name": "purge_fan_setpoint",
        "name_orig": "Purge Fan Setpoint",
        "nameDisplay": "Purge Fan Setpoint",
        "number": 1331,
        "desc": "The setpoint used for fans during purge.",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": 1,
        "unit": "%",
        "default": "",
        "menu": "\\Purge\\FanPercent"
    },
    "purge_inactivity_time": {
        "id": "purge_inactivity_time",
        "name": "purge_inactivity_time",
        "name_orig": "Purge Inactivity Time",
        "nameDisplay": "Purge Inactivity Time",
        "number": 1332,
        "desc": "The amount of idle time which activates purge.",
        "type": "int",
        "min": 0,
        "max": 100000,
        "res": 1,
        "unit": "sec",
        "default": "",
        "menu": "\\Purge\\InactivityTime"
    },
    "support_phone_number": {
        "id": "support_phone_number",
        "name": "support_phone_number",
        "name_orig": "Support Phone Number",
        "nameDisplay": "Support Phone Number",
        "number": 2000,
        "desc": 52,
        "type": "string",
        "min": "",
        "max": "",
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Support\\Support Phone"
    },
    "home_phone_number": {
        "id": "home_phone_number",
        "name": "home_phone_number",
        "name_orig": "Home Phone Number",
        "nameDisplay": "Home Phone Number",
        "number": 2002,
        "desc": 52,
        "type": "string",
        "min": "",
        "max": "",
        "res": "",
        "unit": "-",
        "default": "",
        "menu": "\\Support\\Support Phone"
    }
}


// Generated from sqlite file
export const awareTelemNames = {
    "udf": "udf",
    "rqf": "rqf",
    "udt": "udt",
    "rqt": "rqt",
    "trt": "trt",
    "started_by": "started_by",
    "op_mode": "op_mode",
    "ech_1_pct": "ech_1_pct",
    "ech_2_pct": "ech_2_pct",
    "ech_3_pct": "ech_3_pct",
    "cc_pct": "cc_pct",
    "hp_pct": "hp_pct",
    "hvac_pct": "hvac_pct",
    "four_w_valve": "four_w_valve",
    "htp": "htp",
    "hta": "hta",
    "ltp": "ltp",
    "ltala": "ltala",
    "hsa": "hsa",
    "ncp": "ncp",
    "eth0_IP": "eth0_IP",
    "wlan0_IP": "wlan0_IP",
    "lt_offset": "lt_offset",
    "globalErr": "globalErr",
    "comErr": "comErr",
    "globalAlarms": "globalAlarms",
    "globalWarnings": "globalWarnings",
    "sys_time": "sys_time",
    "delta_t_evap": "delta_t_evap",
    "msm_state": "msm_state",
    "rc_state": "rc_state",
    "defrost_state": "defrost_state",
    "freconv_state": "freconv_state",
    "remaining_service_life_days": "remaining_service_life_days",
    "remaining_service_life_pct": "remaining_service_life_pct",
    "remaining_service_life_hrs": "remaining_service_life_hrs",
    "filter_operating_time": "filter_operating_time",
    "comp_rps_pct": "comp_rps_pct",
    "comp_rps": "comp_rps",
    "t_ai": "t_ai",
    "t_ar": "t_ar",
    "t_ae": "t_ae",
    "t_ao": "t_ao",
    "t_aop": "t_aop",
    "t_aio": "t_aio",
    "t_aii": "t_aii",
    "t_vto": "t_vto",
    "t_vti": "t_vti",
    "t_voc": "t_voc",
    "t_lbo": "t_lbo",
    "t_lbi": "t_lbi",
    "t_lie": "t_lie",
    "t_loe": "t_loe",
    "t_pci": "t_pci",
    "t_pco": "t_pco",
    "t_vic": "t_vic",
    "pt_vti": "pt_vti",
    "pt_lbi": "pt_lbi",
    "pt_vto": "pt_vto",
    "pt_lbo": "pt_lbo",
    "pt_vic": "pt_vic",
    "rh_ai": "rh_ai",
    "rh_ar": "rh_ar",
    "rh_ae": "rh_ae",
    "rh_ao": "rh_ao",
    "co2_ai": "co2_ai",
    "co2_ar": "co2_ar",
    "co2_ae": "co2_ae",
    "co2_ao": "co2_ao",
    "tvoc_ai": "tvoc_ai",
    "tvoc_ar": "tvoc_ar",
    "tvoc_ae": "tvoc_ae",
    "tvoc_ao": "tvoc_ao",
    "cds_lvl_sup_phe": "cds_lvl_sup_phe",
    "cds_lvl_sup_ahe": "cds_lvl_sup_ahe",
    "cds_lvl_ext_phe": "cds_lvl_ext_phe",
    "cds_lvl_ext_ahe": "cds_lvl_ext_ahe",
    "cds_valve_sup_phe": "cds_valve_sup_phe",
    "cds_valve_sup_ahe": "cds_valve_sup_ahe",
    "cds_valve_ext_phe": "cds_valve_ext_phe",
    "cds_valve_ext_ahe": "cds_valve_ext_ahe",
    "cds_pump": "cds_pump",
    "cds_pump_sup_phe": "cds_pump_sup_phe",
    "cds_pump_sup_ahe": "cds_pump_sup_ahe",
    "cds_pump_ext_phe": "cds_pump_ext_phe",
    "cds_pump_ext_ahe": "cds_pump_ext_ahe",
    "fan_sup_pm_1_u_rms": "fan_sup_pm_1_u_rms",
    "fan_sup_pm_1_i_rms": "fan_sup_pm_1_i_rms",
    "fan_sup_pm_1_p_act": "fan_sup_pm_1_p_act",
    "fan_sup_pm_1_e": "fan_sup_pm_1_e",
    "fan_ext_pm_1_u_rms": "fan_ext_pm_1_u_rms",
    "fan_ext_pm_1_i_rms": "fan_ext_pm_1_i_rms",
    "fan_ext_pm_1_p_act": "fan_ext_pm_1_p_act",
    "fan_ext_pm_1_e": "fan_ext_pm_1_e",
    "cm_int_pm_u_rms": "cm_int_pm_u_rms",
    "cm_int_pm_i_rms": "cm_int_pm_i_rms",
    "cm_int_pm_p_act": "cm_int_pm_p_act",
    "cm_int_pm_e": "cm_int_pm_e",
    "hpm_pm_u_rms": "hpm_pm_u_rms",
    "hpm_pm_i_rms": "hpm_pm_i_rms",
    "hpm_pm_p_act": "hpm_pm_p_act",
    "hpm_pm_e": "hpm_pm_e",
    "pm_pm_u_rms": "pm_pm_u_rms",
    "pm_pm_i_rms": "pm_pm_i_rms",
    "pm_pm_p_act": "pm_pm_p_act",
    "pm_pm_e": "pm_pm_e",
    "eph_pm_p_act": "eph_pm_p_act",
    "eph_pm_e": "eph_pm_e",
    "damper_main_sup_pos": "damper_main_sup_pos",
    "damper_main_ext_pos": "damper_main_ext_pos",
    "damper_bypass_pos": "damper_bypass_pos",
    "damper_recirc_in_pos": "damper_recirc_in_pos",
    "damper_recirc_out_pos": "damper_recirc_out_pos",
    "damper_inlet_pos": "damper_inlet_pos",
    "fan_sup_1_pwr": "fan_sup_1_pwr",
    "fan_sup_1_pct": "fan_sup_1_pct",
    "fan_sup_1_rpm": "fan_sup_1_rpm",
    "fan_ext_1_pwr": "fan_ext_1_pwr",
    "fan_ext_1_pct": "fan_ext_1_pct",
    "fan_ext_1_rpm": "fan_ext_1_rpm",
    "mov_sensor_active": "mov_sensor_active",
    "eev_pct": "eev_pct",
    "fan_sup_flow": "fan_sup_flow",
    "fan_ext_flow": "fan_ext_flow"
}

export const awareTelemSpec = {
    "udf": {
        "id": "udf",
        "parent": 0,
        "name": "User Defined Air Flow",
        "name_orig": "User Defined Air Flow",
        "desc": "User Defined Air Flow",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "rqf": {
        "id": "rqf",
        "parent": 0,
        "name": "Requested Flow",
        "name_orig": "Requested Flow",
        "desc": "Requested Flow",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "udt": {
        "id": "udt",
        "parent": 0,
        "name": "User Defined Temperature",
        "name_orig": "User Defined Temperature",
        "desc": "User Defined Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "rqt": {
        "id": "rqt",
        "parent": 0,
        "name": "Requested Temperature",
        "name_orig": "Requested Temperature",
        "desc": "Requested Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "trt": {
        "id": "trt",
        "parent": 0,
        "name": "Target Room Temperature",
        "name_orig": "Target Room Temperature",
        "desc": "Target Room Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "started_by": {
        "id": "started_by",
        "parent": 0,
        "name": "Started By",
        "name_orig": "Started By",
        "desc": "Started By",
        "type": "enum",
        "min": "STARTED_BY_NONE",
        "max": "STARTED_BY_BOOST",
        "res": "1.0",
        "format": "%u",
        "enum": "STARTED_BY"
    },
    "op_mode": {
        "id": "op_mode",
        "parent": 0,
        "name": "Operation Mode",
        "name_orig": "Operation Mode",
        "desc": "Operation Mode",
        "type": "enum",
        "min": "OP_MODE_INVALID",
        "max": "OP_MODE_DEEP_SLEEP",
        "res": "1.0",
        "format": "%u",
        "enum": "OP_MODE"
    },
    "ech_1_pct": {
        "id": "ech_1_pct",
        "parent": 0,
        "name": "Electric Comfort Heater 1 %",
        "name_orig": "Electric Comfort Heater 1 %",
        "desc": "Electric Comfort Heater 1 %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "1.0",
        "format": "%.1f",
        "unit": "%"
    },
    "ech_2_pct": {
        "id": "ech_2_pct",
        "parent": 0,
        "name": "Electric Comfort Heater 2 %",
        "name_orig": "Electric Comfort Heater 2 %",
        "desc": "Electric Comfort Heater 2 %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "1.0",
        "format": "%.1f",
        "unit": "%"
    },
    "ech_3_pct": {
        "id": "ech_3_pct",
        "parent": 0,
        "name": "Electric Comfort Heater 3 %",
        "name_orig": "Electric Comfort Heater 3 %",
        "desc": "Electric Comfort Heater 3 %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "1.0",
        "format": "%.1f",
        "unit": "%"
    },
    "cc_pct": {
        "id": "cc_pct",
        "parent": 0,
        "name": "Comfort Cool %",
        "name_orig": "Comfort Cool %",
        "desc": "Comfort Cool %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "hp_pct": {
        "id": "hp_pct",
        "parent": 0,
        "name": "Heat Pump %",
        "name_orig": "Heat Pump %",
        "desc": "Heat Pump %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "hvac_pct": {
        "id": "hvac_pct",
        "parent": 0,
        "name": "HVAC %",
        "name_orig": "HVAC %",
        "desc": "HVAC %",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "four_w_valve": {
        "id": "four_w_valve",
        "parent": 0,
        "name": "fourWayValve",
        "name_orig": "fourWayValve",
        "desc": "fourWayValve",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "htp": {
        "id": "htp",
        "parent": 0,
        "name": "High Temp Process",
        "name_orig": "High Temp Process",
        "desc": "High Temp Process",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "hta": {
        "id": "hta",
        "parent": 0,
        "name": "High Temp Allowed",
        "name_orig": "High Temp Allowed",
        "desc": "High Temp Allowed",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "ltp": {
        "id": "ltp",
        "parent": 0,
        "name": "Low Temp Process",
        "name_orig": "Low Temp Process",
        "desc": "Low Temp Process",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "ltala": {
        "id": "ltala",
        "parent": 0,
        "name": "Low Temp Alarm Allowed",
        "name_orig": "Low Temp Alarm Allowed",
        "desc": "Low Temp Alarm Allowed",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "hsa": {
        "id": "hsa",
        "parent": 0,
        "name": "Heating Surfaces Allowed",
        "name_orig": "Heating Surfaces Allowed",
        "desc": "Heating Surfaces Allowed",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "ncp": {
        "id": "ncp",
        "parent": 0,
        "name": "Night Cool Process",
        "name_orig": "Night Cool Process",
        "desc": "Night Cool Process",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "eth0_IP": {
        "id": "eth0_IP",
        "parent": 0,
        "name": "Ethernet 0 IP",
        "name_orig": "Ethernet 0 IP",
        "desc": "Ethernet 0 IP",
        "type": "string",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%s"
    },
    "wlan0_IP": {
        "id": "wlan0_IP",
        "parent": 0,
        "name": "WLAN 0 IP",
        "name_orig": "WLAN 0 IP",
        "desc": "WLAN 0 IP",
        "type": "string",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%s"
    },
    "lt_offset": {
        "id": "lt_offset",
        "parent": 0,
        "name": "Local Time Offset",
        "name_orig": "Local Time Offset",
        "desc": "Local Time Offset",
        "type": "int",
        "min": -86400,
        "max": 86400,
        "res": "1.0",
        "format": "%d",
        "unit": "s"
    },
    "globalErr": {
        "id": "globalErr",
        "parent": 0,
        "name": "globalErr",
        "name_orig": "globalErr",
        "desc": "globalErr",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "comErr": {
        "id": "comErr",
        "parent": 0,
        "name": "comErr",
        "name_orig": "comErr",
        "desc": "comErr",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "globalAlarms": {
        "id": "globalAlarms",
        "parent": 0,
        "name": "globalAlarms",
        "name_orig": "globalAlarms",
        "desc": "globalAlarms",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "globalWarnings": {
        "id": "globalWarnings",
        "parent": 0,
        "name": "globalWarnings",
        "name_orig": "globalWarnings",
        "desc": "globalWarnings",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "sys_time": {
        "id": "sys_time",
        "parent": 1000,
        "name": "sys_time",
        "name_orig": "sys_time",
        "desc": "sys_time",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "delta_t_evap": {
        "id": "delta_t_evap",
        "parent": 1000,
        "name": "Delta T_evap",
        "name_orig": "Delta T_evap",
        "desc": "Delta T_evap",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "msm_state": {
        "id": "msm_state",
        "parent": 1000,
        "name": "SM state",
        "name_orig": "SM state",
        "desc": "SM state",
        "type": "enum",
        "min": "MAIN_SM_STATE_INITIAL",
        "max": "MAIN_SM_STATE_CLEARERRORS",
        "res": "1.0",
        "format": "%u",
        "enum": "MAIN_SM_STATE"
    },
    "rc_state": {
        "id": "rc_state",
        "parent": 1000,
        "name": "RC state",
        "name_orig": "RC state",
        "desc": "RC state",
        "type": "enum",
        "min": "RC_STATE_INITIAL",
        "max": "RC_STATE_VENT_COOL",
        "res": "1.0",
        "format": "%u",
        "enum": "RC_STATE"
    },
    "defrost_state": {
        "id": "defrost_state",
        "parent": 1000,
        "name": "Defrost state",
        "name_orig": "Defrost state",
        "desc": "Defrost state",
        "type": "enum",
        "min": "DEFROST_STATE_IDLE",
        "max": "DEFROST_STATE_DEFROSTING_INSIDE",
        "res": "1.0",
        "format": "%u",
        "enum": "DEFROST_STATE"
    },
    "freconv_state": {
        "id": "freconv_state",
        "parent": 1000,
        "name": "Frequency Converter State",
        "name_orig": "Frequency Converter State",
        "desc": "Frequency Converter State",
        "type": "enum",
        "min": "FREQCONV_SM_STATE_INITIAL",
        "max": "FREQCONV_SM_STATE_MAX",
        "res": "1.0",
        "format": "%u",
        "enum": "FREQCONV_SM_STATE"
    },
    "remaining_service_life_days": {
        "id": "remaining_service_life_days",
        "parent": 1000,
        "name": "Remaining service life of filters in days",
        "name_orig": "Remaining service life of filters in days",
        "desc": "Remaining service life of filters in days",
        "type": "int",
        "min": 0,
        "max": 1000,
        "res": "1.0",
        "format": "%u",
        "unit": "days"
    },
    "remaining_service_life_pct": {
        "id": "remaining_service_life_pct",
        "parent": 1000,
        "name": "Remaining service life of filters in %",
        "name_orig": "Remaining service life of filters in %",
        "desc": "Remaining service life of filters in %",
        "type": "int",
        "min": 0,
        "max": 101,
        "res": "1.0",
        "format": "%u",
        "unit": "%"
    },
    "remaining_service_life_hrs": {
        "id": "remaining_service_life_hrs",
        "parent": 1000,
        "name": "Remaining service life of filters in operating hours",
        "name_orig": "Remaining service life of filters in operating hours",
        "desc": "Remaining service life of filters in operating hours",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": "1.0",
        "format": "%u",
        "unit": "h"
    },
    "filter_operating_time": {
        "id": "filter_operating_time",
        "parent": 1000,
        "name": "Filter Operating Time",
        "name_orig": "Filter Operating Time",
        "desc": "Filter Operating Time",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "s"
    },
    "comp_rps_pct": {
        "id": "comp_rps_pct",
        "parent": 3000,
        "name": "Compressor RPS pct",
        "name_orig": "Compressor RPS pct",
        "desc": "Compressor RPS pct",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.2f"
    },
    "comp_rps": {
        "id": "comp_rps",
        "parent": 3000,
        "name": "Compressor RPS",
        "name_orig": "Compressor RPS",
        "desc": "Compressor RPS",
        "type": "decimal",
        "min": 0,
        "max": 120,
        "res": "0.1",
        "format": "%.2f"
    },
    "t_ai": {
        "id": "t_ai",
        "parent": 4000,
        "name": "Temp. Air Inlet",
        "name_orig": "Temp. Air Inlet",
        "desc": "Temp. Air Inlet",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_ar": {
        "id": "t_ar",
        "parent": 4000,
        "name": "Temp. Air Room",
        "name_orig": "Temp. Air Room",
        "desc": "Temp. Air Room",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.001",
        "format": "%.3f",
        "unit": "°C"
    },
    "t_ae": {
        "id": "t_ae",
        "parent": 4000,
        "name": "Temp. Air Exhaust",
        "name_orig": "Temp. Air Exhaust",
        "desc": "Temp. Air Exhaust",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_ao": {
        "id": "t_ao",
        "parent": 4000,
        "name": "Temp. Air Outside",
        "name_orig": "Temp. Air Outside",
        "desc": "Temp. Air Outside",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_aop": {
        "id": "t_aop",
        "parent": 4000,
        "name": "Temp. Air Out of Passive heat exchanger",
        "name_orig": "Temp. Air Out of Passive heat exchanger",
        "desc": "Temp. Air Out of Passive heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_aio": {
        "id": "t_aio",
        "parent": 4000,
        "name": "Temp. Air Into Outside heat exchanger",
        "name_orig": "Temp. Air Into Outside heat exchanger",
        "desc": "Temp. Air Into Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_aii": {
        "id": "t_aii",
        "parent": 4000,
        "name": "Temp. Air Into Inside heat exchanger",
        "name_orig": "Temp. Air Into Inside heat exchanger",
        "desc": "Temp. Air Into Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_vto": {
        "id": "t_vto",
        "parent": 4000,
        "name": "Temp. Vapor Top Outside heat exchanger",
        "name_orig": "Temp. Vapor Top Outside heat exchanger",
        "desc": "Temp. Vapor Top Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_vti": {
        "id": "t_vti",
        "parent": 4000,
        "name": "Temp. Vapor Top Inside heat exchanger",
        "name_orig": "Temp. Vapor Top Inside heat exchanger",
        "desc": "Temp. Vapor Top Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_voc": {
        "id": "t_voc",
        "parent": 4000,
        "name": "Temp. Vapor Out of Compressor",
        "name_orig": "Temp. Vapor Out of Compressor",
        "desc": "Temp. Vapor Out of Compressor",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_lbo": {
        "id": "t_lbo",
        "parent": 4000,
        "name": "Temp. Liquid Bottom Outside heat exchanger",
        "name_orig": "Temp. Liquid Bottom Outside heat exchanger",
        "desc": "Temp. Liquid Bottom Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_lbi": {
        "id": "t_lbi",
        "parent": 4000,
        "name": "Temp. Liquid Bottom Inside heat exchanger",
        "name_orig": "Temp. Liquid Bottom Inside heat exchanger",
        "desc": "Temp. Liquid Bottom Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_lie": {
        "id": "t_lie",
        "parent": 4000,
        "name": "Temp. Liquid Into Expansion unit",
        "name_orig": "Temp. Liquid Into Expansion unit",
        "desc": "Temp. Liquid Into Expansion unit",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_loe": {
        "id": "t_loe",
        "parent": 4000,
        "name": "Temp. Liquid Out of Expansion unit",
        "name_orig": "Temp. Liquid Out of Expansion unit",
        "desc": "Temp. Liquid Out of Expansion unit",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_pci": {
        "id": "t_pci",
        "parent": 4000,
        "name": "Temp. Phase Change Inside heat exchanger",
        "name_orig": "Temp. Phase Change Inside heat exchanger",
        "desc": "Temp. Phase Change Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_pco": {
        "id": "t_pco",
        "parent": 4000,
        "name": "Temp. Phase Change Outside heat exchanger",
        "name_orig": "Temp. Phase Change Outside heat exchanger",
        "desc": "Temp. Phase Change Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_vic": {
        "id": "t_vic",
        "parent": 4000,
        "name": "Temp. Vapor Into Compressor",
        "name_orig": "Temp. Vapor Into Compressor",
        "desc": "Temp. Vapor Into Compressor",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "pt_vti": {
        "id": "pt_vti",
        "parent": 5000,
        "name": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "name_orig": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "desc": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_lbi": {
        "id": "pt_lbi",
        "parent": 5000,
        "name": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "name_orig": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "desc": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_vto": {
        "id": "pt_vto",
        "parent": 5000,
        "name": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "name_orig": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "desc": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_lbo": {
        "id": "pt_lbo",
        "parent": 5000,
        "name": "PT_{LBO}",
        "name_orig": "PT_{LBO}",
        "desc": "PT_{LBO}",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_vic": {
        "id": "pt_vic",
        "parent": 5000,
        "name": "Pressure Tranmitter Vapor Into Compressor",
        "name_orig": "Pressure Tranmitter Vapor Into Compressor",
        "desc": "Pressure Tranmitter Vapor Into Compressor",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "rh_ai": {
        "id": "rh_ai",
        "parent": 6000,
        "name": "Relative Humidity Air Inlet",
        "name_orig": "Relative Humidity Air Inlet",
        "desc": "Relative Humidity Air Inlet",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "rh_ar": {
        "id": "rh_ar",
        "parent": 6000,
        "name": "Relative Humidity Air Room",
        "name_orig": "Relative Humidity Air Room",
        "desc": "Relative Humidity Air Room",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "rh_ae": {
        "id": "rh_ae",
        "parent": 6000,
        "name": "Relative Humidity Air Exhaust",
        "name_orig": "Relative Humidity Air Exhaust",
        "desc": "Relative Humidity Air Exhaust",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "rh_ao": {
        "id": "rh_ao",
        "parent": 6000,
        "name": "Relative Humidity Air Outside",
        "name_orig": "Relative Humidity Air Outside",
        "desc": "Relative Humidity Air Outside",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "co2_ai": {
        "id": "co2_ai",
        "parent": 6000,
        "name": "CO2 Air Inlet",
        "name_orig": "CO2 Air Inlet",
        "desc": "CO2 Air Inlet",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "co2_ar": {
        "id": "co2_ar",
        "parent": 6000,
        "name": "CO2 Air Room",
        "name_orig": "CO2 Air Room",
        "desc": "CO2 Air Room",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "co2_ae": {
        "id": "co2_ae",
        "parent": 6000,
        "name": "CO2 Air Exhaust",
        "name_orig": "CO2 Air Exhaust",
        "desc": "CO2 Air Exhaust",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "co2_ao": {
        "id": "co2_ao",
        "parent": 6000,
        "name": "CO2 Air Outside",
        "name_orig": "CO2 Air Outside",
        "desc": "CO2 Air Outside",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "tvoc_ai": {
        "id": "tvoc_ai",
        "parent": 6000,
        "name": "TVOC Air Inlet",
        "name_orig": "TVOC Air Inlet",
        "desc": "TVOC Air Inlet",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "tvoc_ar": {
        "id": "tvoc_ar",
        "parent": 6000,
        "name": "TVOC Air Room",
        "name_orig": "TVOC Air Room",
        "desc": "TVOC Air Room",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "tvoc_ae": {
        "id": "tvoc_ae",
        "parent": 6000,
        "name": "TVOC Air Exhaust",
        "name_orig": "TVOC Air Exhaust",
        "desc": "TVOC Air Exhaust",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "tvoc_ao": {
        "id": "tvoc_ao",
        "parent": 6000,
        "name": "TVOC Air Outside",
        "name_orig": "TVOC Air Outside",
        "desc": "TVOC Air Outside",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "cds_lvl_sup_phe": {
        "id": "cds_lvl_sup_phe",
        "parent": 7000,
        "name": "Level SA Passive Heat Exchanger",
        "name_orig": "Level SA Passive Heat Exchanger",
        "desc": "Level SA Passive Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_lvl_sup_ahe": {
        "id": "cds_lvl_sup_ahe",
        "parent": 7000,
        "name": "Level SA Active Heat Exchanger",
        "name_orig": "Level SA Active Heat Exchanger",
        "desc": "Level SA Active Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_lvl_ext_phe": {
        "id": "cds_lvl_ext_phe",
        "parent": 7000,
        "name": "Level EA Passive Heat Exchanger",
        "name_orig": "Level EA Passive Heat Exchanger",
        "desc": "Level EA Passive Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_lvl_ext_ahe": {
        "id": "cds_lvl_ext_ahe",
        "parent": 7000,
        "name": "Level EA Active Heat Exchanger",
        "name_orig": "Level EA Active Heat Exchanger",
        "desc": "Level EA Active Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_valve_sup_phe": {
        "id": "cds_valve_sup_phe",
        "parent": 7000,
        "name": "Valve SA Passive Heat Exchanger",
        "name_orig": "Valve SA Passive Heat Exchanger",
        "desc": "Valve SA Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_valve_sup_ahe": {
        "id": "cds_valve_sup_ahe",
        "parent": 7000,
        "name": "Valve SA Active Heat Exchanger",
        "name_orig": "Valve SA Active Heat Exchanger",
        "desc": "Valve SA Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_valve_ext_phe": {
        "id": "cds_valve_ext_phe",
        "parent": 7000,
        "name": "Valve EA Passive Heat Exchanger",
        "name_orig": "Valve EA Passive Heat Exchanger",
        "desc": "Valve EA Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_valve_ext_ahe": {
        "id": "cds_valve_ext_ahe",
        "parent": 7000,
        "name": "Valve EA Active Heat Exchanger",
        "name_orig": "Valve EA Active Heat Exchanger",
        "desc": "Valve EA Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump": {
        "id": "cds_pump",
        "parent": 7000,
        "name": "Condensate Pump",
        "name_orig": "Condensate Pump",
        "desc": "Condensate Pump",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_sup_phe": {
        "id": "cds_pump_sup_phe",
        "parent": 7000,
        "name": "Pump Supply Passive Heat Exchanger",
        "name_orig": "Pump Supply Passive Heat Exchanger",
        "desc": "Pump Supply Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_sup_ahe": {
        "id": "cds_pump_sup_ahe",
        "parent": 7000,
        "name": "Pump Supply Active Heat Exchanger",
        "name_orig": "Pump Supply Active Heat Exchanger",
        "desc": "Pump Supply Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_ext_phe": {
        "id": "cds_pump_ext_phe",
        "parent": 7000,
        "name": "Pump Extraction Passive Heat Exchanger",
        "name_orig": "Pump Extraction Passive Heat Exchanger",
        "desc": "Pump Extraction Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_ext_ahe": {
        "id": "cds_pump_ext_ahe",
        "parent": 7000,
        "name": "Pump Extraction Active Heat Exchanger",
        "name_orig": "Pump Extraction Active Heat Exchanger",
        "desc": "Pump Extraction Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "fan_sup_pm_1_u_rms": {
        "id": "fan_sup_pm_1_u_rms",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Urms",
        "name_orig": "Supply Fan, Power Meter #1, Urms",
        "desc": "Supply Fan, Power Meter #1, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "fan_sup_pm_1_i_rms": {
        "id": "fan_sup_pm_1_i_rms",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Irms",
        "name_orig": "Supply Fan, Power Meter #1, Irms",
        "desc": "Supply Fan, Power Meter #1, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "fan_sup_pm_1_p_act": {
        "id": "fan_sup_pm_1_p_act",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Power Actual",
        "name_orig": "Supply Fan, Power Meter #1, Power Actual",
        "desc": "Supply Fan, Power Meter #1, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "fan_sup_pm_1_e": {
        "id": "fan_sup_pm_1_e",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Energy",
        "name_orig": "Supply Fan, Power Meter #1, Energy",
        "desc": "Supply Fan, Power Meter #1, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "fan_ext_pm_1_u_rms": {
        "id": "fan_ext_pm_1_u_rms",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Urms",
        "name_orig": "Extraction Fan, Power Meter #1, Urms",
        "desc": "Extraction Fan, Power Meter #1, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "fan_ext_pm_1_i_rms": {
        "id": "fan_ext_pm_1_i_rms",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Irms",
        "name_orig": "Extraction Fan, Power Meter #1, Irms",
        "desc": "Extraction Fan, Power Meter #1, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "fan_ext_pm_1_p_act": {
        "id": "fan_ext_pm_1_p_act",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Power Actual",
        "name_orig": "Extraction Fan, Power Meter #1, Power Actual",
        "desc": "Extraction Fan, Power Meter #1, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "fan_ext_pm_1_e": {
        "id": "fan_ext_pm_1_e",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Energy",
        "name_orig": "Extraction Fan, Power Meter #1, Energy",
        "desc": "Extraction Fan, Power Meter #1, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "cm_int_pm_u_rms": {
        "id": "cm_int_pm_u_rms",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Urms",
        "name_orig": "Control Module Internal, Power Meter, Urms",
        "desc": "Control Module Internal, Power Meter, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "cm_int_pm_i_rms": {
        "id": "cm_int_pm_i_rms",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Irms",
        "name_orig": "Control Module Internal, Power Meter, Irms",
        "desc": "Control Module Internal, Power Meter, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "cm_int_pm_p_act": {
        "id": "cm_int_pm_p_act",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Power Actual",
        "name_orig": "Control Module Internal, Power Meter, Power Actual",
        "desc": "Control Module Internal, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "cm_int_pm_e": {
        "id": "cm_int_pm_e",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Energy",
        "name_orig": "Control Module Internal, Power Meter, Energy",
        "desc": "Control Module Internal, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "hpm_pm_u_rms": {
        "id": "hpm_pm_u_rms",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Urms",
        "name_orig": "Heat Pump Module, Power Meter, Urms",
        "desc": "Heat Pump Module, Power Meter, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "hpm_pm_i_rms": {
        "id": "hpm_pm_i_rms",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Irms",
        "name_orig": "Heat Pump Module, Power Meter, Irms",
        "desc": "Heat Pump Module, Power Meter, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "hpm_pm_p_act": {
        "id": "hpm_pm_p_act",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Power Actual",
        "name_orig": "Heat Pump Module, Power Meter, Power Actual",
        "desc": "Heat Pump Module, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "hpm_pm_e": {
        "id": "hpm_pm_e",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Energy",
        "name_orig": "Heat Pump Module, Power Meter, Energy",
        "desc": "Heat Pump Module, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "pm_pm_u_rms": {
        "id": "pm_pm_u_rms",
        "parent": 8000,
        "name": "Power Module, Power Meter, Urms",
        "name_orig": "Power Module, Power Meter, Urms",
        "desc": "Power Module, Power Meter, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "pm_pm_i_rms": {
        "id": "pm_pm_i_rms",
        "parent": 8000,
        "name": "Power Module, Power Meter, Irms",
        "name_orig": "Power Module, Power Meter, Irms",
        "desc": "Power Module, Power Meter, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "pm_pm_p_act": {
        "id": "pm_pm_p_act",
        "parent": 8000,
        "name": "Power Module, Power Meter, Power Actual",
        "name_orig": "Power Module, Power Meter, Power Actual",
        "desc": "Power Module, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "pm_pm_e": {
        "id": "pm_pm_e",
        "parent": 8000,
        "name": "Power Module, Power Meter, Energy",
        "name_orig": "Power Module, Power Meter, Energy",
        "desc": "Power Module, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "eph_pm_p_act": {
        "id": "eph_pm_p_act",
        "parent": 8000,
        "name": "Electric Pre Heater, Power Meter, Power Actual",
        "name_orig": "Electric Pre Heater, Power Meter, Power Actual",
        "desc": "Electric Pre Heater, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "eph_pm_e": {
        "id": "eph_pm_e",
        "parent": 8000,
        "name": "Electric Pre Heater, Power Meter, Energy",
        "name_orig": "Electric Pre Heater, Power Meter, Energy",
        "desc": "Electric Pre Heater, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "damper_main_sup_pos": {
        "id": "damper_main_sup_pos",
        "parent": 9000,
        "name": "Main Sup Damper Position",
        "name_orig": "Main Sup Damper Position",
        "desc": "Main Sup Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_main_ext_pos": {
        "id": "damper_main_ext_pos",
        "parent": 9000,
        "name": "Main Ext Damper Position",
        "name_orig": "Main Ext Damper Position",
        "desc": "Main Ext Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_bypass_pos": {
        "id": "damper_bypass_pos",
        "parent": 9000,
        "name": "Bypass Damper Position",
        "name_orig": "Bypass Damper Position",
        "desc": "Bypass Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_recirc_in_pos": {
        "id": "damper_recirc_in_pos",
        "parent": 9000,
        "name": "Recirculation Inside Damper Position",
        "name_orig": "Recirculation Inside Damper Position",
        "desc": "Recirculation Inside Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_recirc_out_pos": {
        "id": "damper_recirc_out_pos",
        "parent": 9000,
        "name": "Recirculation Outside Damper Position",
        "name_orig": "Recirculation Outside Damper Position",
        "desc": "Recirculation Outside Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_inlet_pos": {
        "id": "damper_inlet_pos",
        "parent": 9000,
        "name": "Inlet Damper Position",
        "name_orig": "Inlet Damper Position",
        "desc": "Inlet Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "fan_sup_1_pwr": {
        "id": "fan_sup_1_pwr",
        "parent": 10000,
        "name": "Supply Fan #1 Power",
        "name_orig": "Supply Fan #1 Power",
        "desc": "Supply Fan #1 Power",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "fan_sup_1_pct": {
        "id": "fan_sup_1_pct",
        "parent": 10000,
        "name": "Supply Fan #1 Control",
        "name_orig": "Supply Fan #1 Control",
        "desc": "Supply Fan #1 Control",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "fan_sup_1_rpm": {
        "id": "fan_sup_1_rpm",
        "parent": 10000,
        "name": "Supply Fan #1 Speed",
        "name_orig": "Supply Fan #1 Speed",
        "desc": "Supply Fan #1 Speed",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%u",
        "unit": "rpm"
    },
    "fan_ext_1_pwr": {
        "id": "fan_ext_1_pwr",
        "parent": 10000,
        "name": "Extraction Fan #1 Power",
        "name_orig": "Extraction Fan #1 Power",
        "desc": "Extraction Fan #1 Power",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "fan_ext_1_pct": {
        "id": "fan_ext_1_pct",
        "parent": 10000,
        "name": "Extraction Fan #1 Control",
        "name_orig": "Extraction Fan #1 Control",
        "desc": "Extraction Fan #1 Control",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "fan_ext_1_rpm": {
        "id": "fan_ext_1_rpm",
        "parent": 10000,
        "name": "Extraction Fan #1 Speed",
        "name_orig": "Extraction Fan #1 Speed",
        "desc": "Extraction Fan #1 Speed",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%u",
        "unit": "rpm"
    },
    "mov_sensor_active": {
        "id": "mov_sensor_active",
        "parent": 11000,
        "name": "Movement Sensor Active",
        "name_orig": "Movement Sensor Active",
        "desc": "Movement Sensor Active",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "eev_pct": {
        "id": "eev_pct",
        "parent": 12000,
        "name": "EEV Step Percent",
        "name_orig": "EEV Step Percent",
        "desc": "EEV Step Percent",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "fan_sup_flow": {
        "id": "fan_sup_flow",
        "parent": 13000,
        "name": "Supply Fan Flow",
        "name_orig": "Supply Fan Flow",
        "desc": "Supply Fan Flow",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "m3/h"
    },
    "fan_ext_flow": {
        "id": "fan_ext_flow",
        "parent": 13000,
        "name": "Extraction Fan Flow",
        "name_orig": "Extraction Fan Flow",
        "desc": "Extraction Fan Flow",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "m3/h"
    }
}