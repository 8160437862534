import React from "react";
import {ExclamationTriangle} from "react-bootstrap-icons";

/** Renders a box which displays a percentage-value in a progressbar. (i.e. value must be between 0 - 100)
 * @param {string} className classes for the wrapper of the component
 * @param {string} defectText text to display if the sensor is defect
 * @param {string} activeTitleText to display if the sensor is active
 * @param {JSX.Element} icon to display to the left of the progressbar
 * @param {string | Number} sensorValue current value of the sensor reading
 * @param {string} valuePostFix postfix to append after the value (i.e. %)
 * @param {boolean} hasError defines if the sensor has an error (if an error has occured, the component will display accordingly)
 * @param {boolean} deviceConnected whether device is connected or not. If not connected, show dash (-) instead of value.
 */
export default function PercentageBox({
                                          className, activeTitleText, icon,
                                          sensorValue, valuePostFix, hasError, deviceConnected
                                      }) {
    return (
        <div className={className}>
            {hasError &&
                <div className='telemetry-box-wrapper bg-light'>
                    <ExclamationTriangle className='color-danger' style={{width:'64px', height:'64px'}} />
                    {/*<div className='d-block text-truncate text-muted text-wrap p-2 font-weight-500'>{defectText}</div>*/}
                </div>}
            {!hasError &&
                <div className='rounded bg-airmaster-blue-light text-white p-1 telemetry-box-wrapper'>
                    <div
                        className='w-100 h-100 d-flex flex-column justify-content-center align-items-center position-relative'
                        style={{background: `linear-gradient(to right, ${getComputedStyle(document.body).getPropertyValue('--bs-airmaster-blue-dark')} ${sensorValue}%, transparent 0%)`}}>
                        <div style={{fontSize: '11px', top: '10px'}}
                             className='text-truncate position-absolute'>{activeTitleText}</div>
                        <span className='position-absolute font-weight-500' style={{
                            fontSize: '72px', top: '8px'
                        }}>{deviceConnected ? sensorValue : "-"}
                            <span className='position-absolute'
                                  style={{right: -20, bottom: 20, fontSize: '16px'}}>{valuePostFix}</span>
                        </span>
                        <div style={{bottom: '10px'}} className='position-absolute'>{icon}</div>
                    </div>
                </div>
            }
        </div>
    )
}