import {Pagination} from '@mui/material'
import {useTranslation} from 'react-i18next'
import ComboBox from '../combobox/ComboBox'
import {makeStyles} from "@mui/styles";

/** Renders shared table footer
 * @param {function} setPage function to set the page to view in the table
 * @param {function} setRowsPerPage function to set the amount of rows to be viewed in the table on each page
 * @param {int} rowsPerPage sets the selected rows
 * @param {int} page sets the selected page
 * @param {int} dataLength length of the array of objects shown in the table
 * @param className
 * @returns
 */
export default function TFooter({rowsPerPage, setRowsPerPage, page, setPage, dataLength, className = ''}) {
    const {t} = useTranslation(['overviewpage', "common"])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value))
        setPage(1)
    }

    // Override pagination colors to get secondary background
    const classes = makeStyles(() => ({
            root: {
                // '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
                //     backgroundColor: 'transparent'
                // },
                '& ul > li:not(:first-child):not(:last-child) > button.Mui-selected': {
                    color: 'white',
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--bs-secondary')
                }
            },
        }
    ))()

    return (
        <div className={`d-flex ${className} d-xs-block`}>
            <div className='d-flex align-items-center mb-xs-2'>
                <span className='pe-xs-2 text-nowrap'>{t('overviewpage:table.footer.rows.rows')}</span>
                <ComboBox className='ps-2 pe-2 ps-xs-0' inputValue={handleChangeRowsPerPage} options={[
                    {id: 5, option: 5}, {id: 10, option: 10}, {id: 20, option: 20},
                    {id: 50, option: 50}, {id: 100, option: 100}, {id: 500, option: 500}
                ]} value={rowsPerPage.toString()} hasLabel={false}/>
            </div>
            <div className='pe-0 ms-auto'>
                <Pagination
                    className={classes.root}
                    count={Math.ceil((dataLength) / (rowsPerPage === 0 ? -1 : rowsPerPage))}
                    shape="circular"
                    page={page}
                    onChange={(event, newPage) => setPage(newPage)}
                />
            </div>
        </div>
    )
}