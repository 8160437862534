import React from 'react'
import {FilterCircle, Gear, Hdd, Sliders, Stopwatch} from 'react-bootstrap-icons'
import {useTranslation} from "react-i18next"
import {configurationSubPages} from "../../../helpers/constants.js"
import {isBasic} from "../../../helpers/authHelper.js"
import {useMsal} from "@azure/msal-react"

/** Displays the menu tabs on the right side of the settings window
 * Icon docs / search: https://icons.getbootstrap.com/
 * @param {string} selectedSubSettingsPage currently selected subpage
 * @param {function} setSelectedSubSettingsPage function to set current subpage
 */
export default function MenuTabs({selectedSubSettingsPage, setSelectedSubSettingsPage}) {
    const {t} = useTranslation(['settingspage', 'common'])
    const {accounts} = useMsal()

    function MenuTabItem({isActive, subPageToSelect, iconEle, text}) {
        return <div
            className={`${isActive ? 'active' : ''} menu-tab-link cursor-pointer py-2 mt-2 ps-3`}
            style={{width: '200px'}} onClick={() => setSelectedSubSettingsPage(subPageToSelect)}>
            {iconEle}{text}
        </div>
    }

    return (
        <div className={'border-1 border-end'}>
            <MenuTabItem isActive={selectedSubSettingsPage === configurationSubPages.operation}
                         subPageToSelect={configurationSubPages.operation}
                         iconEle={<Hdd className='me-2'/>} text={t('settingspage:menu.operation')}
            />
            <MenuTabItem isActive={selectedSubSettingsPage === configurationSubPages.setpoints}
                         subPageToSelect={configurationSubPages.setpoints}
                         iconEle={<Sliders className='me-2'/>} text={t('settingspage:menu.setpoints')}
            />
            <MenuTabItem isActive={selectedSubSettingsPage === configurationSubPages.timers}
                         subPageToSelect={configurationSubPages.timers}
                         iconEle={<Stopwatch className='me-2'/>} text={t('settingspage:menu.timers')}
            />
            {!isBasic(accounts) &&
                <MenuTabItem isActive={selectedSubSettingsPage === configurationSubPages.startpriority}
                             subPageToSelect={configurationSubPages.startpriority}
                             iconEle={<Gear className='me-2'/>} text={t('settingspage:menu.priorities')}
                />
            }
            <MenuTabItem isActive={selectedSubSettingsPage === configurationSubPages.filter}
                         subPageToSelect={configurationSubPages.filter}
                         iconEle={<FilterCircle className='me-2'/>} text={t('settingspage:menu.filter')}
            />
        </div>
    )
}