import React from 'react'
import JEOperationSettings from "./operationsettings/JEOperationSettings.js";
import AwareOperationSettings from "./operationsettings/AwareOperationSettings.js";
import JESetpointsSettings from "./setpointssettings/JESetpointsSettings.js";
import AwareSetpointsSettings from "./setpointssettings/AwareSetpointsSettings.js";
import JEStartPrioritySettings from "./startprioritysettings/JEStartPrioritySettings.js";
import AwareStartPrioritySettings from "./startprioritysettings/AwareStartPrioritySettings.js";
import DeviceTimersSettings from "./devicetimerssettings/DeviceTimersSettings.js";
import JEFilterSettings from "./filtersettings/JEFilterSettings.js";
import AwareFilterSettings from "./filtersettings/AwareFilterSettings.js";
import {configurationSubPages} from "../../../helpers/constants";
import {isAwareDevice} from '../../../helpers/helperfunctions.js'

/** Configuration subpages
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {string} selectedSubSettingsPage page to show
 * @param {function} promptForSave save function to trigger displaying group selector or confirmation (for single device)
 */
export default function SubPagesSettings({device, className, selectedSubSettingsPage, promptForSave}) {
    switch (selectedSubSettingsPage) {
        case configurationSubPages.operation:
            if(!isAwareDevice(device.serial_number)) return <JEOperationSettings className={className} device={device} save={promptForSave}/>
            else return <AwareOperationSettings className={className} device={device} save={promptForSave}/>
        case configurationSubPages.setpoints:
            if(!isAwareDevice(device.serial_number)) return <JESetpointsSettings className={className} device={device} save={promptForSave}/>
            else return <AwareSetpointsSettings className={className} device={device} save={promptForSave}/>
        case configurationSubPages.timers:
            return <DeviceTimersSettings className={className} device={device} save={promptForSave}/>
        case configurationSubPages.startpriority:
            if(!isAwareDevice(device.serial_number)) return <JEStartPrioritySettings className={className} device={device} save={promptForSave}/>
            else return <AwareStartPrioritySettings className={className} device={device} save={promptForSave}/>
        case configurationSubPages.filter:
            if(!isAwareDevice(device.serial_number)) return <JEFilterSettings className={className} device={device} save={promptForSave}/>
            else return <AwareFilterSettings className={className} device={device} save={promptForSave}/>
        default:
            throw new Error(`SubPagesSettings: reached default with value ${selectedSubSettingsPage}`)
    }
}