import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    CheckCircle,
    CheckCircleFill,  ExclamationTriangle,
    ExclamationTriangleFill, InfoCircle,
    InfoCircleFill,
    LightningChargeFill
} from 'react-bootstrap-icons';
import PopOverDialog from '../../../../../components/shared/popoverdialog/PopOverDialog'

/** Displays the current status column in device table
 * @param {object} device object for device
 * @param {boolean} listViewChosen boolean to show or hide grid/list-view
 */
export default function DeviceAlarm({listViewChosen, device}) {
    const {t} = useTranslation(['overviewpage', 'telemetry', 'common'])
    const [deviceAlarms, setDeviceAlarms] = useState([])
    const [deviceWarnings, setDeviceWarnings] = useState([])
    const [deviceProcesses, setDeviceProcesses] = useState([])

    useEffect(() => {
        setDeviceAlarms(Object.keys(device.system_alarm).map(obj => t(`telemetry:${obj}`)))
        setDeviceWarnings(Object.keys(device.system_condition).map(obj => t(`telemetry:${obj}`)))
        setDeviceProcesses(Object.keys(device.system_process).map(obj => t(`telemetry:${obj}`)))
    }, [device])

    const constructHTML = () => {
        if (!listViewChosen) {
            return (
                <>
                    {deviceAlarms.length === 0 && deviceWarnings.length === 0 && deviceProcesses.length === 0 &&
                        <>
                            <CheckCircle className='color-success' style={{height: '36px', width: '36px'}}/>
                            <span className='position-absolute' style={{top: '40px', fontSize: '15px'}}>Ok</span>
                        </>
                    }
                    {deviceAlarms.length > 0 && deviceAlarms.map(alarm => {
                        return <span key={alarm} className='px-2 py-1 mb-1 rounded-5 text-white bg-danger text-nowrap'>
                            <ExclamationTriangle className='me-1 mb-1'/> {alarm}
                        </span>
                    })}
                    {deviceWarnings.length > 0 && deviceWarnings.map(warning => {
                        return <span key={warning} className='px-2 py-1 mb-1 rounded-5 text-white bg-warning text-nowrap'>
                            <ExclamationTriangle className='me-1 mb-1'/> {warning}
                        </span>
                    })}
                    {deviceProcesses.length > 0 && deviceProcesses.map(process => {
                        return <span key={process} className='px-2 py-1 mb-1 rounded-5 text-white bg-success text-nowrap'>
                            <InfoCircle className='me-1 mb-1'/> {process}
                        </span>
                    })}
                </>
            )
        } else if (deviceAlarms.concat(deviceWarnings).concat(deviceProcesses).length > 0) {
            return (
                <div className='d-flex'>
                    {deviceAlarms.length !== 0 &&
                        <span
                            className='pe-1'>{t('overviewpage:table.cells.status.alarm', {count: deviceAlarms.length})}</span>
                    }
                    {deviceWarnings.length !== 0 &&
                        <span
                            className='pe-1'>{t('overviewpage:table.cells.status.warning', {count: deviceWarnings.length})}</span>
                    }
                    {deviceProcesses.length !== 0 &&
                        <span
                            className='pe-1'>{t('overviewpage:table.cells.status.process', {count: deviceProcesses.length})}</span>
                    }
                    <PopOverDialog trigger={['hover', 'focus']}
                                   popOverTitel={t('overviewpage:table.cells.status.popover_title')} popOverContent={
                        <>
                            {deviceAlarms.length > 0 && deviceAlarms.map(alarm =>
                                <span key={alarm} className='d-block'>
                                    <ExclamationTriangleFill className='me-1 mb-1 text-danger'/>{alarm}
                                </span>
                            )}
                            {deviceWarnings.length > 0 && deviceWarnings.map(warning =>
                                <span key={warning} className='d-block'>
                                    <ExclamationTriangleFill className='me-1 mb-1 text-warning'/>{warning}
                                </span>
                            )}
                            {deviceProcesses.length > 0 && deviceProcesses.map(process =>
                                <span key={process} className='d-block'>
                                    <InfoCircleFill className='me-1 mb-1 color-telemetry-very-good'/>{process}
                                </span>
                            )}
                        </>
                    }/>
                </div>
            )
        }
        return <span className='d-block color-telemetry-very-good'><CheckCircleFill className='me-1 mb-1'/>Ok</span>
    }

    return (
        device.connected ?
            constructHTML()
            :
            <div className='text-xs-center'>
                <LightningChargeFill className='me-1 mb-1 text-secondary'/><span
                className='d-inline'>{t('common:connectionstate.false')}</span>
            </div>
    )
}