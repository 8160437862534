import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";
import {hasAnyRole, isViewer} from "../../../helpers/authHelper.js";
import {allowedPageRoles} from "../../../helpers/constants.js";

export default function NavTopBar({deviceSerialNumber, currentPage}) {
    const {accounts} = useMsal();
    const {t} = useTranslation(["common"])
    const navigate = useNavigate();

    function preventDefaultAndNavigate(e, navigationUrl) {
        e.preventDefault() // prevent native navigation when clicking on anchor. It needs to be anchor to get middleclick open new tab functionality.
        navigate(navigationUrl)
    }

    return <div className='mt-4 mb-2 w-100 d-flex border-bottom'>
        <a className={`text-decoration-none cursor-pointer dashboard-navbar-link ${currentPage === 'dashboard' ? 'selected' : ''}`}
           href={`/device/${deviceSerialNumber}/dashboard`}
           onClick={e => preventDefaultAndNavigate(e, `/device/${deviceSerialNumber}/dashboard`)}> {t('common:navigation.dashboard')}
        </a>
        {!isViewer(accounts) &&
            <a className={`ms-1 text-decoration-none cursor-pointer dashboard-navbar-link ${currentPage === 'configurations' ? 'selected' : ''}`}
               href={`/device/${deviceSerialNumber}/configurations/operation`}
               onClick={e => preventDefaultAndNavigate(e, `/device/${deviceSerialNumber}/configurations/operation`)}> {t('common:navigation.configuration')}
            </a>
        }
        {hasAnyRole(accounts, allowedPageRoles.DeviceTechnicalPage) &&
            <>
                <a className={`ms-1 text-decoration-none cursor-pointer dashboard-navbar-link ${currentPage === 'technical' ? 'selected' : ''}`}
                   href={`/device/${deviceSerialNumber}/technical`}
                   onClick={e => preventDefaultAndNavigate(e, `/device/${deviceSerialNumber}/technical`)}> {t('common:navigation.technical')}
                </a>
                <a className={`ms-1 text-decoration-none cursor-pointer dashboard-navbar-link ${currentPage === 'parameters' ? 'selected' : ''}`}
                   href={`/device/${deviceSerialNumber}/parameters`}
                   onClick={e => preventDefaultAndNavigate(e, `/device/${deviceSerialNumber}/parameters`)}> {t('common:navigation.parameters')}
                </a>
            </>
        }
    </div>
}
