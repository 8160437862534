import Switch from 'react-bootstrap-switch'
import React from 'react'

/** wrapper for switch
 *  Switch docs: https://www.npmjs.com/package/react-bootstrap-switch?activeTab=readme
 * @param {boolean} value
 * @param {function(boolean)} setValue
 * @returns {Element}
 * @constructor
 */
export function JE_AwareSwitch({value, setValue}) {
    return <Switch value={value} animate={false} onText={'JE'} offText={'Aware'}
                   onChange={(el, state) => setValue(state)}/>
}