import {API_ENDPOINTS} from "./endpoints.js";
import {PublicClientApplication} from "@azure/msal-browser";
import * as Sentry from "@sentry/react";
import {createUUID, sleep} from "../helpers/helperfunctions.js";
import {msalConfig} from "../helpers/authConfig.js";
import {ResponseError} from "../helpers/errors/ResponseError.js";
import {ignoredErrorsList} from "../helpers/constants.js";

// msalInstance lives here but is used various places in application set / get user info
export const msalInstance = new PublicClientApplication(msalConfig);

/** Requests a silent token payload from the MSAL instance with a specified scope to access data in Azure AD using the Microsoft Graph API.
 * If user is not logged in yet, try again up to 10 times
 * @returns Azure AD account token payload */
const tryGetAccountPayload = async (retries = 1) => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        console.log(`${retries} retries while waiting for active account`)
        if (retries >= 10) {
            console.warn("All accounts", JSON.stringify(msalInstance.getAllAccounts()))
            throw new Error("getPayload retried 10 times (2.5 seconds) while waiting for user login")
        }
        await sleep(250)
        return await tryGetAccountPayload(retries + 1)
    }
    let request = {
        account: account,
        scopes: [process.env.REACT_APP_AIRMASTERAPI_USERADMSCOPE]
    }



    return msalInstance.acquireTokenSilent(request).catch(async () => {
        return msalInstance.loginRedirect(request);
    });
}

let contentTypes = {
    JSON: "application/json",
    FORM: "" // don't set header
};

/** requests headers for the scope and assembles the headers.
 * If formdata, we don't need to specify content-type header.
 */
export const createHeaders = async (contentType = contentTypes.JSON) => {
    const payload = await tryGetAccountPayload();
    const headers = new Headers();

    headers.append("authorization", `Bearer ${payload.accessToken}`);
    if (contentType !== contentTypes.FORM) headers.append("content-type", contentType);
    headers.append("transaction-id", createUUID("transact"));
    return headers;
}

export const performRequest = async (endpoint, options) => {
    try {
        const transactionId = Object.fromEntries([...options.headers])['transaction-id']
        let result = await fetch(endpoint, options)
            .catch(error => {
                if (error?.message?.includes("The user aborted a request")) {
                    //  It seems to work fine and creating a lot of noise in Sentry, so removing warning for now
                    // console.warn(`${transactionId} [WARN] during ${options.method} ${endpoint}, got message: ${error.message}`)
                    throw error // catch it in controller
                } else {
                    throw new Error(`${transactionId} [ERROR] Could not ${options.method} ${endpoint}, got message: ${error.message ?? error}`)
                }
            })

        if (result.status === 304) {
            console.log(`%c${transactionId} [NOT MODIFIED] ${options.method} ${endpoint}`, "color:green;")
            return Promise.resolve(null)
        }
        if (!result.ok) {
            const resultText = await result.text()
            throw new Error(`${transactionId} [ERROR] Could not ${options.method} ${endpoint}, got (status: ${result.status}, resultText: ${resultText})`)
        }
        console.log(`%c${transactionId} [SUCCESS] ${options.method} ${endpoint}`, "color:green;")
        return result.status === 204 ? Promise.resolve({}) : result.json()

    } catch (e) {
        let ignoredError = ignoredErrorsList.some(s => e.message.includes(s));
        if (!ignoredError) { // Do not send error to sentry if one of ignored errors
            options.headers = Object.fromEntries([...options.headers])
            const payload = await tryGetAccountPayload();
            Sentry.withScope(scope => {
                // Create multiple contexts because of truncation issue if large body
                scope.setContext("meta", {
                    endpoint: endpoint,
                    method: options.method,
                    userId: payload.idTokenClaims.oid,
                    userEmail: payload.idTokenClaims.preferred_username,
                    transactionId: options.headers['transaction-id']
                })
                scope.setContext("body", {body: options.body}) // can be undefined, but that is fine
                scope.setContext("headers", {headers: JSON.stringify(options.headers)})
                scope.setContext("errorMessage", {errorMessage: e.message})
                scope.setTag("endpoint", endpoint)
                scope.setTag("userId", payload.idTokenClaims.oid)
                scope.setTag("userEmail", payload.idTokenClaims.preferred_username)
                Sentry.captureException(e)
            });
        }
        throw e
    }
}

/** Does a GET request to the AM API using authorization header.
 * @returns object
 */
export const getUserInformation = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(API_ENDPOINTS.GET_USER, options)
}

/** Performs a PATCH request to the AM API using authorization header.
 * The method updates the logged in users profile in Azure AD from a new user object in the data parameter.
 *
 * @param {object} data user object
 * @returns promise to resolve with http action result
 */
export const setAccountData = async (data) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify(data)
    };
    return performRequest(API_ENDPOINTS.EDIT_USER, options)
}

/** Performs a GET request to the AM API to get an open extension in Azure AD.
 * @param {string} extensionId name of the extensionId to GET
 * @returns object
 */
export const getOpenExtension = async (extensionId) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(API_ENDPOINTS.GET_EXTENSION + extensionId, options)
}

/** Performs a PATCH request to the AM API to update an open extension in Azure AD.
 * NOTE: Open extensions does not support PUT, which is why update and create are separated
 * @param {string} extensionId name of the extensionId to PATCH
 * @param {object} data newsletter object to update in graph
 * @returns promise to resolve with http action result
 */
export const updateOpenExtension = async (data, extensionId) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify(data)
    };

    let result = await fetch(API_ENDPOINTS.UPDATE_EXTENSION + extensionId, options).catch(error => {
        throw new Error(`[ERROR] Could not execute 'updateOpenExtension()', got ${error.status}`)
    })

    if (!result.ok) {
        throw new ResponseError(result.status, `[ERROR] 'updateOpenExtension()', got status: ${result.status} and message: ${result.statusText}`)
    }
    console.log(`%c[SUCCESS] Successfully executed 'updateOpenExtension()'`, "color:green;")

    return result.json()
}

/** Performs a CREATE request to the AM API to create a new open extension in Azure AD.
 * NOTE: Open extensions does not support PUT, which is why update and create are separated
 *
 * @param {string} extensionId name of the extensionId to PATCH
 * @param {object} data newsletter object to create in graph
 * @returns promise to resolve with http action result
 */
export const createOpenExtension = async (data, extensionId) => {
    const options = {
        method: "POST",
        headers: await createHeaders(),
        body: JSON.stringify(data)
    };
    return performRequest(API_ENDPOINTS.CREATE_EXTENSION + extensionId, options)
}

/** Performs a DELETE request to AM API, to delete an open extension in Azure AD
 *
 * @param {string} extensionId name of the extensionId to DELETE
 * @returns promise to resolve with http action result
 */
export const deleteOpenExtension = async (extensionId) => {
    const options = {
        method: "DELETE",
        headers: await createHeaders()
    };
    return performRequest(API_ENDPOINTS.CREATE_EXTENSION + extensionId, options)
}

export const getAdministeredUserAllowedItems = async (userId) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.GET_ADMINISTEREDUSERS}/${userId}/allowed`, options)
}

/** Gets all users and corresponding groups that the requesting user governs
 * @returns promise to resolve with action result
 */
export const getAdministeredUsersList = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(API_ENDPOINTS.GET_ADMINISTEREDUSERS_LIST, options)
}

/** Gets all the groups that the user has access to
 * @returns {Promise<array>} array of groups
 */
export const getAllowedGroups = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(API_ENDPOINTS.GET_USERGROUPS, options)
}

/** Gets all the groups with devices that the user has access to
 * @returns {Promise<array>} array of groups with devices
 */
export const getAllowedGroupsWithDevices = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.GET_USERGROUPS}?withdevices=true`, options)
}

/** Edits a meta-data for a targeted user and corresponding groups
 * @param {string} userId id of the user to edit
 * @param {array} groups groups to appoint to the user subject to being edited
 * @param {string} userRole roleId of the user subject to being edited
 * @param {string} preferredLanguage preferredLanguage of the user subject to being edited
 * @returns
 */
export const editExternalUser = async (userId, groups, userRole, preferredLanguage) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify({id: userId, groups: groups, role: userRole, language: preferredLanguage})
    };
    return performRequest(API_ENDPOINTS.EDIT_ADMINISTERED_USER, options)
}

/** Deletes a user from AD and the users' corresponding groups
 * @param {*} userId of the user to delete
 * @returns promise
 */
export const deleteUser = async (userId) => {
    const options = {
        method: "DELETE",
        headers: await createHeaders()
    };
    return performRequest(API_ENDPOINTS.DELETE_ADMINISTERED_USER + userId, options)
}

/** Creates a user i Azure AD, sends an invitation and assigns the user to groups
 * @returns action result
 * @param {[string]} selectedGroupIdList Ids of selected groups
 * @param {string} mail new users mail
 * @param {string} role new users role
 * @param {string} preferredLanguage new users language
 */
export const createUser = async (selectedGroupIdList, mail, role, preferredLanguage) => {
    const options = {
        method: "POST",
        headers: await createHeaders(),
        body: JSON.stringify({
            role: role,
            groups: selectedGroupIdList,
            mail: mail,
            preferredLanguage: preferredLanguage
        })
    };
    return performRequest(API_ENDPOINTS.CREATE_ADMINISTERED_USER, options)
}

/** Calls the AO API in order to reinvite the user by email
 * @param {string} mail mail
 * @returns action result
 */
export const reInviteUser = async (mail) => {
    const options = {
        method: "POST",
        headers: await createHeaders()
    };
    return performRequest(API_ENDPOINTS.INVITE_ADMINISTERED_USER + mail, options)
}

/** Performs a PUT request to the AM API in order to update a single group name and description
 * @returns {Promise} promise to resolve with data
 */
export const updateGroup = async (desc, grpName, grpId, typ) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify({description: desc, name: grpName, id: grpId, type: typ})
    };
    return performRequest(API_ENDPOINTS.EDIT_GROUP, options)
}

/** Performs a PUT request to the AM API in order to update a single device name and description
 * @returns {Promise} promise to resolve with data
 */
export const updateDevice = async (device) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify(device)
    };
    return performRequest(API_ENDPOINTS.EDIT_DEVICE, options)
}

/** Creates a new group in the hierarchy
 * @param {string} parent id of the parent group
 * @param {string} desc group description
 * @param {string} groupName group name
 * @returns {Promise}
 */
export const createNewGroup = async (parent, desc, groupName) => {
    const options = {
        method: "POST",
        headers: await createHeaders(),
        body: JSON.stringify({parent: parent, name: groupName, description: desc})
    };
    return performRequest(API_ENDPOINTS.CREATE_GROUP, options)
}

/** Removes a group from the group hierarchy. (If the group has children and devices, they are moved to the next parent)
 * @param {string} id id of the group to delete
 * @returns
 */
export const removeGroup = async (id) => {
    const options = {
        method: "DELETE",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.DELETE_GROUP}${id}`, options)
}


/** Delete a device
 * @param {string} id id of the device to delete
 * @returns
 */
export const deleteDevice = async (id) => {
    const options = {
        method: "DELETE",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.DELETE_DEVICE}${id}`, options)
}

/** Updates the hierarchy
 * @param {array} groupsToUpdate array with groups and devices that have been edited (Edit entails, new parent)
 * @returns
 */
export const updateGroupsInHierarchy = async (groupsToUpdate) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify(groupsToUpdate)
    };
    return performRequest(API_ENDPOINTS.EDIT_HIERARCHY, options)
}

export const getDeviceBySerialNumber = async (serialNumber) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.GET_DEVICE_BY_SERIAL_NUMBER}?device_serial_number=${serialNumber}`, options)
}
export const getDeviceByIdIfNotModified = async (id, etag) => {
    const headers = await createHeaders()
    headers.append("If-None-Match", etag)
    const options = {method: "GET", headers: headers};
    return await performRequest(`${API_ENDPOINTS.GET_DEVICE_BY_SERIAL_NUMBER}?device_id=${id}`, options)
}

export const getDevices = async (deviceIdList) => {
    const options = {
        method: "POST",
        headers: await createHeaders(),
        body: JSON.stringify(deviceIdList)
    };
    return performRequest(API_ENDPOINTS.LIST_DEVICES, options)
}

export const queryDeviceListWithCount = async (query, abortSignal) => {
    const options = {
        method: "GET",
        headers: await createHeaders(),
        signal: abortSignal
    };
    const queryString = Object.keys(query)
        .filter(key => query[key]) // only query if truthy value
        .map((key) => `${key}=${query[key]}`).join("&")
    return performRequest(`${API_ENDPOINTS.QUERY_DEVICES}?${queryString}`, options)
}

export const getNotificationList = async (userId) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.NOTIFICATION_LIST}?userId=${userId}`, options)
}

export const upsertNotification = async (notification) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify(notification)
    };
    return performRequest(API_ENDPOINTS.NOTIFICATION, options)
}

export const deleteRemoteNotificationById = async (notificationId) => {
    const options = {
        method: "DELETE",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.NOTIFICATION}?notificationId=${notificationId}`, options)
}

export const getFirmwareList = async (deviceFamily) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.DEVICECONFIGURATION_FIRMWARE_LIST}?device_family=${deviceFamily}`, options)
}

export const getFirmwareFile = async (fileName) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.DEVICECONFIGURATION_FIRMWARE_DOWNLOADFILE}/${fileName}`, options)
}

export const getFirmwareFileForVersion = async (parameterVersion) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.DEVICECONFIGURATION_FIRMWARE_DOWNLOAD_PARAMETER_FILE_FOR_VERSION}?parameter_version=${parameterVersion}`, options)
}

export const getFirmwareFilterOptions = async (groupsString, deviceFamily) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    };
    const groupComp = groupsString ? `&groups=${groupsString}` : ""
    return performRequest(`${API_ENDPOINTS.DEVICECONFIGURATION_FIRMWARE_FILTER_OPTIONS}?device_family=${deviceFamily}${groupComp}`, options)
}

export const deleteFirmware = async (firmwareId) => {
    const options = {
        method: "DELETE",
        headers: await createHeaders()
    };
    return performRequest(`${API_ENDPOINTS.DEVICECONFIGURATION_FIRMWARE}/${firmwareId}/delete`, options)
}

export const createFirmware = async (firmwareFormData) => {
    const options = {
        method: "POST",
        headers: await createHeaders(contentTypes.FORM),
        body: firmwareFormData
    };
    return performRequest(API_ENDPOINTS.DEVICECONFIGURATION_FIRMWARE_CREATE, options)
}

export const editFirmware = async (firmware) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify(firmware)
    };
    return performRequest(API_ENDPOINTS.DEVICECONFIGURATION_FIRMWARE_EDIT, options)
}

export const getTelemetry = async (types, deviceid, from, to, discardNotChanged = true) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    let typesString = types.join(",")
    return performRequest(`${API_ENDPOINTS.API_BASE}/api/device/${deviceid}/telemetry/${from}/${to}?types=${typesString}&discard_not_changed=${discardNotChanged}`, options)
}

export const updateConfigurationViaDeviceList = async (configuration, deviceList, comingFromJe) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify({configuration: configuration, devices: deviceList, comingFromJe: comingFromJe})
    };
    return performRequest(API_ENDPOINTS.CONFIGURE_DEVICES, options)
}

export const updateConfigurationViaGroupList = async (configuration, groupList, comingFromJe) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify({configuration: configuration, groups: groupList, comingFromJe: comingFromJe})
    };
    return performRequest(API_ENDPOINTS.CONFIGURE_DEVICES, options)
}

export const updateTimerPeriodsViaDeviceList = async (periodList, deviceList) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify({periodList: periodList, devices: deviceList})
    };
    return performRequest(API_ENDPOINTS.TIMER_PERIODS, options)
}

export const updateTimerPeriodsViaGroupList = async (periodList, groupList) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify({periodList: periodList, groups: groupList})
    };
    return performRequest(API_ENDPOINTS.TIMER_PERIODS, options)
}

export const updateFirmwareConfiguration = async (firmwareId, deviceIdList) => {
    const options = {
        method: "PUT",
        headers: await createHeaders(),
        body: JSON.stringify({firmwareIdToUpdateTo: firmwareId, deviceIdListToUpdate: deviceIdList})
    };
    return performRequest(API_ENDPOINTS.CONFIGURE_DEVICES_FIRMWARE, options)
}

export const getDeviceLog = async (types, deviceid, from, to) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/api/device/${deviceid}/log/${from}/${to}`, options)
}

export const getAdminOperations = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/adminoperations`, options)
}

export const getAdminOperationsLogins = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/adminoperations/logins`, options)
}

export const getMonitorRequestList = async (userEmail, status, daysBack, operationName) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/monitor/requests?userEmail=${userEmail}&status=${status}&daysBack=${daysBack}&operationName=${operationName}`, options)
}

export const getStatisticsList = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/statistics`, options)
}

export const getMonitorTracesList = async (requestParentId) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/monitor/traces?parentId=${requestParentId}`, options)
}

export const getMonitorFnaTracesList = async (invocationId) => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/monitor/fna_traces?invocationId=${invocationId}`, options)
}

export const getMonitorLatestJeTelemetry = async () => {
    const options = {
        method: "GET",
        headers: await createHeaders()
    }
    return performRequest(`${API_ENDPOINTS.API_BASE}/monitor/latest_je_telemetry`, options)
}

export const runNotificationJob = async (frequency) => {
    const options = {
        method: "POST",
        headers: await createHeaders(),
        body: JSON.stringify({frequency: frequency})
    };
    return performRequest(API_ENDPOINTS.NOTIFICATION_RUN_JOB, options)
}

