import {roleConstants} from "./constants.js";
import {intersection} from "lodash";

/** true if user has any of the roles in roles array
 * @param {object} accounts from "const {accounts} = useMsal()"
 * @param {string[]} roles array of roles from roleConstants
 * @returns {boolean}
 */
export function hasAnyRole(accounts, roles) {
    return intersection(accounts[0]?.idTokenClaims?.roles, roles).length !== 0;
}

export function isAdmin(accounts) {
    return accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Admin)
}

export function isTechnicalAdvanced(accounts) {
    return accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Technical)
}

export function isTechnicalBasic(accounts) {
    return accounts[0]?.idTokenClaims?.roles.includes(roleConstants.TechnicalBasic)
}

export function isTechnicalOrMore(accounts) {
    return hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic])
}

export function isPremium(accounts) {
    return accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Premium)
}

export function isBasic(accounts) {
    return accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Basic)
}

export function isViewer(accounts) {
    return accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Viever)
}

export function getRoleAsText(accounts) {
    if (accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Admin))
        return "App.Admin"
    if (accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Technical))
        return "App.Technical"
    if (accounts[0]?.idTokenClaims?.roles.includes(roleConstants.TechnicalBasic))
        return "App.TechnicalBasic"
    if (accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Premium))
        return "App.Premium"
    if (accounts[0]?.idTokenClaims?.roles.includes(roleConstants.Basic))
        return "App.BasicUser"
    return "App.Viewer"
}

export const roleIdToText = (roleId) => {
    switch (roleId) {
        case process.env.REACT_APP_APP_ADMIN:
            return "App.Admin"
        case process.env.REACT_APP_APP_TECHNICALUSER: 
            return "App.Technical"
        case process.env.REACT_APP_APP_TECHNICALBASIC:
            return "App.TechnicalBasic"
        case process.env.REACT_APP_APP_PREMIUMUSER:
            return "App.Premium"
        case process.env.REACT_APP_APP_BASICUSER:
            return "App.BasicUser"
        case process.env.REACT_APP_APP_VIEWER:
            return "App.Viewer"
        default:
            throw new Error(`roleIdToText: No match for role id ${roleId}. possible roles are: [${process.env.REACT_APP_APP_ADMIN}, ${process.env.REACT_APP_APP_TECHNICALUSER}, ${process.env.REACT_APP_APP_TECHNICALBASIC}, ${process.env.REACT_APP_APP_PREMIUMUSER}, ${process.env.REACT_APP_APP_BASICUSER}, ${process.env.REACT_APP_APP_VIEWER}]`)
    }
}