import AdminOperationsLoginFilterSearchBar from "./AdminOperationsLoginFilterSearchBar.js";
import AdminOperationsLoginTableBody from "./AdminOperationsLoginTableBody.js";
import {useEffect, useState} from "react";
import {getComparator} from "../../../helpers/helperfunctions.js";
import {LoadSpinner} from '../../../components/shared/loader/LoadSpinner.js'

export default function AdminOperationsLogin({rawLoginList}) {
    const [search, setSearch] = useState("")
    const [sortObj, setSortObj] = useState({sortValue: "createdDateTime", sortDirection: 'desc'})
    const [filteredSortedLoginList, setFilteredSortedLoginList] = useState([])

    const sortHandler = (headerData) => {
        setSortObj({
            sortDirection: headerData.sortValue === sortObj.sortValue && sortObj.sortDirection === 'desc' ? 'asc' : "desc",
            sortValue: headerData.sortValue
        })
    }

    useEffect(() => {
        let sortedLoginList = [...rawLoginList].sort(getComparator(sortObj.sortDirection, sortObj.sortValue));
        if (search) {
            const searchLower = search.toLowerCase()
            sortedLoginList = sortedLoginList.filter(row =>
                row.createdDateTime.includes(searchLower) || row.userEmail.toLowerCase().includes(searchLower) ||
                row.userDisplayName.toLowerCase().includes(searchLower) || row.countryOrRegion.toLowerCase().includes(searchLower) ||
                row.city.toLowerCase().includes(searchLower) || row.browser.toLowerCase().includes(searchLower) ||
                row.operatingSystem.toLowerCase().includes(searchLower) || row.accessStatus.toLowerCase().includes(searchLower) ||
                row.statusFailureReason.includes(searchLower)
            )
        }
        sortedLoginList = sortedLoginList.slice(0, 50)
        setFilteredSortedLoginList(sortedLoginList)
    }, [rawLoginList, search, sortObj])

    return (
        <div>
            <AdminOperationsLoginFilterSearchBar className={`d-flex justify-content-between align-items-center p-2`}
                                                 searchValue={search} performSearch={setSearch}/>
            {rawLoginList.length === 0 && <LoadSpinner/>}
            {rawLoginList.length > 0 && <>
                <div
                    className="text-nowrap small ps-3 mb-1">{`Total logins sidste 28 dage: ${rawLoginList.length} - Vist: ${filteredSortedLoginList.length}`}</div>
                <AdminOperationsLoginTableBody className={'mb-2'} loginList={filteredSortedLoginList} sort={sortObj}
                                               sortHandler={sortHandler}/>
            </>}
        </div>
    )
}
