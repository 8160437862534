import React from 'react'
import {useTranslation} from 'react-i18next'
import PopOverDialog from '../../../../../components/shared/popoverdialog/PopOverDialog'

/** Displays the name and S/N of the device
 * @param {string} name name of the device
 * @param {string} path path (geological location)
 * @param {string} model device model
 * @param {string} sn serial number of the device
 * @param {boolean} listViewChosen boolean to show or hide grid/list-view
 */
export default function DeviceName({name, path, model, sn, listViewChosen}) {
    const {t} = useTranslation(['overviewpage'])

    function getPopoverContent() {
        return <span className='d-block text-truncate'>
            {t('overviewpage:table.cells.device_details.path')}: <b>{path}</b>
        </span>
    }

    return (
        <>
            {listViewChosen ?
                <span className='d-inline-block text-bold ps-2' style={{fontSize: '14px'}}>
                        {name} - {model ? `${model}, ` : ""}S/N {sn}
                    <PopOverDialog trigger={['hover', 'focus']}
                                   popOverTitel={t('overviewpage:table.cells.device_details.popover_title')}
                                   popOverContent={getPopoverContent()}
                                   size='lg'
                    />
                    </span>
                :
                <>
                    <div className='text-bold' style={{fontSize: '17px'}}>{name}</div>
                    <div className='' style={{fontSize: '14px'}}>Path: {path}</div>
                    <div className='' style={{fontSize: '14px'}}>{model} - S/N: {sn}</div>
                </>
            }
        </>
    )
}