import React from 'react'
import {ArrowUp, Fan, Gear, LightningFill, Power, Shuffle, ThermometerHigh} from 'react-bootstrap-icons'
import {useTranslation} from 'react-i18next'
import DeviceConfigurationRow from './configurationrow/DeviceConfigurationRow.js'
import {awareTelemNames} from '../../../../parameters/awareTelemSpec.js';

/** Renders a square with the most important current settings on the device
 * @param {object} data device object
 */
export default function AwareDeviceStatusConfigurations({device, className, style}) {
    const {t} = useTranslation(["common", "dashboard", "telemetry"])

    const startedByValue = device.latest_telemetry[awareTelemNames.started_by]
    const startedByText = startedByValue === "5" ?
        <span>CO₂</span> : t('telemetry:AwareStartedBy_' + startedByValue)

    const recircInsideDamperPct = device.latest_telemetry[awareTelemNames.damper_recirc_in_pos]
    const recircOutsideDamperPct = device.latest_telemetry[awareTelemNames.damper_recirc_out_pos]

    const hvacPct = parseInt(device.latest_telemetry[awareTelemNames.hvac_pct])

    var heatPumpTitle = t('dashboard:status.configuration.heat_pump');
    if (hvacPct < 0) heatPumpTitle += " (" + t('dashboard:status.cooling') + ")"
    if (hvacPct > 0) heatPumpTitle += " (" + t('dashboard:status.heating') + ")"

    const bypassDamperPct = parseInt(device.latest_telemetry[awareTelemNames.damper_bypass_pos])
    const echPct = parseInt(device.latest_telemetry[awareTelemNames.ech_2_pct])

    function getSpecialConnected() {
        return !!((device.amxHackConnected !== undefined && device.amxHackConnected === true) || device.connected);
    }

    return (
        <div className={className} style={style}>
            <span className='w-100 small text-bold ps-2 d-md-block d-lg-none font-weight-500'>Configurations</span>
            <div className='w-100'>
                <div
                    className={`d-flex flex-grow-1 ps-2 mb-2 align-items-center ${getSpecialConnected() ? 'color-success-dim' : 'color-danger-dim'}`}>
                    <LightningFill className='me-1'/>
                    <span className='small text-truncate text-nowrap'>
                        {t('common:connectionstate.' + getSpecialConnected())}
                    </span>
                </div>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.system_mode')} isInstalled={true}
                                        value={device.connected ? t('telemetry:AwareSystemMode_' + device?.latest_telemetry?.[awareTelemNames.op_mode]) : "-"}
                                        icon={<Gear fill='#44698e'/>}/>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.recirculation_state')}
                                        isInstalled={true}
                                        value={device.connected ? t('telemetry:RecircState_' + device.latest_telemetry[awareTelemNames.rc_state]) : "-"}
                                        icon={<Gear fill='#44698e'/>}/>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.started_by')} isInstalled={true}
                                        value={device.connected ? startedByText : "-"} icon={<Power fill='#44698e'/>}/>
                <DeviceConfigurationRow title={heatPumpTitle + ":"}
                                        isInstalled={true}
                                        value={device.connected ? Math.abs(hvacPct) + " %" : "-"}
                                        icon={<Fan fill='#44698e'/>}/>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.rirc_inside_damper')}
                                        isInstalled={true}
                                        value={device.connected ? recircInsideDamperPct + " %" : "-"}
                                        icon={<ArrowUp fill='#44698e'/>}/>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.rirc_outside_damper')}
                                        isInstalled={true}
                                        value={device.connected ? recircOutsideDamperPct + " %" : "-"}
                                        icon={<ArrowUp fill='#44698e'/>}/>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.bypass_damer')}
                                        isInstalled={true}
                                        value={device.connected ? bypassDamperPct + " %" : "-"}
                                        icon={<Shuffle fill='#44698e'/>}/>
                <DeviceConfigurationRow
                    title={t('dashboard:status.configuration.comfort_electric')}
                    isInstalled={true}
                    value={device.connected ? echPct + " %" : "-"}
                    icon={<ThermometerHigh fill='#44698e'/>}/>
            </div>
        </div>
    )
}