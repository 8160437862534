export const getRoleIdFromRoleText = (role) => {
    switch (role) {
        case "App.Admin":
            return process.env.REACT_APP_APP_ADMIN
        case "App.Technical":
            return process.env.REACT_APP_APP_TECHNICALUSER
        case "App.TechnicalBasic":
            return process.env.REACT_APP_APP_TECHNICALBASIC
        case "App.Premium":
            return process.env.REACT_APP_APP_PREMIUMUSER
        case "App.BasicUser":
            return process.env.REACT_APP_APP_BASICUSER
        case "App.Viewer":
            return process.env.REACT_APP_APP_VIEWER
        default:
            console.warn(`getRoleIdFromRoleText: no corresponding role id for role with text ${role}`)
            return ""
    }
}