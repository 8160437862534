import {useTranslation} from 'react-i18next'
import {formatDateTimeString} from '../../../../../helpers/helperfunctions'

export default function EnumTooltip({title, active, payload, label, translationPrefix}) {
    const {t} = useTranslation(['telemetry'])
    if (active && payload && payload.length) {
        return (
            <div style={{minWidth: "200px"}} className="d-flex flex-column custom-tooltip bg-white shadow-lg rounded">
                <span className='p-2 mb-2 w-100 text-white rounded-top py-2 bg-primary'
                      style={{background: "rgb(103, 167, 221)"}}>{formatDateTimeString(label, true, true)}</span>
                <div className="desc px-2 d-flex pb-2"><span
                    className='text-muted'>{title} </span> <span
                    className='float-end flex-grow-1 text-end ms-2'>{t(`telemetry:${translationPrefix}_${payload[0].value}`)}</span>
                </div>
            </div>
        )
    }
    return null
}