import React, {useState} from 'react'
import DeviceSpecifications from './devicespecifications/DeviceSpecifications';
import {useTranslation} from 'react-i18next';
import DeviceStatus from './devicestatus/DeviceStatus.js';

/** Renders the general status-box for the device, which includes information about airflow and device metadata.
 * @param {object} device object with latest_telemetry and metadata */
export default function DashboardStatusBox({device}) {
    const {t} = useTranslation(["dashboard", "telemetry"])
    const [showSpecifications, setShowSpecifications] = useState(false)

    return (
        <>
            <div className='d-flex'>
                <div
                    className={`me-1 cursor-pointer dashboard-navbar-link ${!showSpecifications ? 'status-spec-selected' : ''}`}
                    onClick={() => setShowSpecifications(false)}
                    style={{paddingBottom: '4px'}}>
                    <span className='px-2'>{t('dashboard:status.navigation.status')}</span>
                </div>
                <div
                    className={`cursor-pointer dashboard-navbar-link ${showSpecifications ? 'status-spec-selected' : ''}`}
                    style={{paddingBottom: '4px'}}
                    onClick={() => setShowSpecifications(true)}>
                    <span className='px-2'>{t('dashboard:status.navigation.specifications')}</span>
                </div>
            </div>

            <div className='boxstyling p-2 rounded-top-left-0'>
                {showSpecifications ?
                    <DeviceSpecifications device={device}/>
                    :
                    <DeviceStatus
                        device={device}
                        deviceAlarms={Object.keys(device.system_alarm).map(obj => t('telemetry:' + obj))}
                        deviceWarnings={Object.keys(device.system_condition).map(obj => t('telemetry:' + obj))}
                        hardwareErrors={Object.keys(device.hardware_errors).map(obj => t('telemetry:' + obj))}
                        deviceProcesses={Object.keys(device.system_process).map(obj => t('telemetry:' + obj))}
                    />}
            </div>
        </>
    )
}