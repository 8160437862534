import {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {upsertCurrentDevice} from "../reduxstore/reducers/deviceReducer.js";
import {isAwareDevice} from "../helperfunctions.js";

export default function useSetAmxOfflineIfTelemetryOld() {
    const dispatch = useDispatch()
    const {currentDevice: device} = useSelector(l => l.device)

    useEffect(() => {
        // Upsert current device to apply amx telemetry logic in deviceReducer.js
        if (isAwareDevice(device.serial_number) && device?.latest_telemetry?.time)
            dispatch(upsertCurrentDevice(device));
    }, [])

}