import {Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {useEffect, useState} from "react";
import {cloneDeep, padStart, toInteger} from "lodash";
import {arrayMax} from "../../../../helpers/helperfunctions.js";
import {useTranslation} from "react-i18next";

/** returns true if input monthDay is in period
 * @param {object} period object with from_month_day and to_month_day props
 * @param {string} currMonthDay format: 01-01
 * @returns {boolean} */
function isMonthDayInPeriod(period, currMonthDay) {
    if (period?.from_month_day <= period?.to_month_day) { // non-wrapping period
        return period?.from_month_day <= currMonthDay && period?.to_month_day >= currMonthDay
    } else { // wrapping period
        return !(period?.to_month_day < currMonthDay && period?.from_month_day > currMonthDay)
    }
}

/** get padded monthDay from Date (eg: 01-01)
 * @param {Date} d
 * @returns {string} */
function getPaddedMonthDayFromDate(d) {
    return `${padStart((d.getMonth() + 1).toString(), 2, '0')}-${padStart(d.getDate().toString(), 2, '0')}`
}

function getNextPeriodInMonthNum(graphMonth) {
    const periodsInMonthNumList = Object.keys(graphMonth)
        .filter(k => k.startsWith(graphMonth.monthName))
        .map(k => toInteger(k.split("_")[0].slice(graphMonth.monthName.length)))
    if (periodsInMonthNumList.length == 0) return 0
    return arrayMax(periodsInMonthNumList) + 1
}

export default function DeviceTimerPeriodsChart({periodList, className}) {
    const {t} = useTranslation(['settingspage', 'common'])

    const graphDataInitial = [
        {monthName: t("common:months_short.Jan")},
        {monthName: t("common:months_short.Feb")},
        {monthName: t("common:months_short.Mar")},
        {monthName: t("common:months_short.Apr")},
        {monthName: t("common:months_short.May")},
        {monthName: t("common:months_short.June")},
        {monthName: t("common:months_short.July")},
        {monthName: t("common:months_short.Aug")},
        {monthName: t("common:months_short.Sept")},
        {monthName: t("common:months_short.Oct")},
        {monthName: t("common:months_short.Nov")},
        {monthName: t("common:months_short.Dec")}
    ]
    const [graphData, setGraphData] = useState(graphDataInitial)
    const [barList, setBarList] = useState([])

    useEffect(() => {
        const periodListWithoutDefault = periodList.filter(p => p.id !== "default")
        const defaultPeriod = periodList.find(p => p.id === "default")

        function getInPeriod(date) {
            return periodListWithoutDefault
                    .find(p => isMonthDayInPeriod(p, getPaddedMonthDayFromDate(date)))
                ?? defaultPeriod
        }

        const graphDataClone = cloneDeep(graphDataInitial)
        const newBarList = []
        let today = new Date(2023, 0, 1)
        let yesterday = new Date(2023, 0, 1)
        let currentPeriod = getInPeriod(today)
        let currentPeriodInMonthLength = 0;

        function recordRange() {
            const graphMonth = graphDataClone[yesterday.getMonth()]
            const nextPeriodInMonthNum = getNextPeriodInMonthNum(graphMonth)
            const graphMonthBarName = graphMonth.monthName + nextPeriodInMonthNum
            graphMonth[`${graphMonthBarName}_periodLength`] = currentPeriodInMonthLength
            graphMonth[`${graphMonthBarName}_periodName`] = currentPeriod.id === "default" ?
                t('settingspage:device_timers.default_period_popover_dialog.title') : currentPeriod.name
            newBarList.push({barName: graphMonthBarName, period: currentPeriod})
            // console.log("added new period to month", graphMonth)
            currentPeriodInMonthLength = 0
        }

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const inPeriod = getInPeriod(today);
            if (inPeriod.id !== currentPeriod.id && currentPeriodInMonthLength !== 0) {
                // console.log(`== Change period: ${currentPeriod.id} -> ${inPeriod.id}`)
                recordRange();
                currentPeriod = inPeriod
            } else if (today.getMonth() !== yesterday.getMonth()) {
                // console.log(`== Change month: ${yesterday.getMonth()} -> ${today.getMonth()}`)
                recordRange()
            }
            if (today.getMonth() === 11 && today.getDate() === 31) {
                // console.log("=== New Years EVE!")
                currentPeriodInMonthLength++
                recordRange()
                break
            }
            currentPeriodInMonthLength++;
            yesterday = today
            today = new Date(2023, today.getMonth(), today.getDate() + 1)
        }

        setGraphData(graphDataClone)
        setBarList(newBarList)
    }, [periodList])

    const containerHeight = 400;
    return (
        <ResponsiveContainer width="100%" height={containerHeight} className={className}>
            <BarChart
                width={1800}
                height={containerHeight}
                data={graphData}
                layout="vertical"
                margin={{top: 20, right: 30, left: 0, bottom: 0}}>

                <XAxis type="number" tickFormatter={e => e} ticks={[0, 7, 14, 21, 28, 31]} domain={[0, 31]}/>
                <YAxis type="category" dataKey="monthName"/>

                {barList.map((bar, idx) =>
                    <Bar key={idx} dataKey={`${bar.barName}_periodLength`} fill={'#ffffff'} stroke={bar.period.color}
                         strokeWidth={'1.5px'}
                         isAnimationActive={false} stackId="a" radius={5}>
                        <LabelList style={{fontSize: '80%', fill: '#263d51'}} dataKey={`${bar.barName}_periodName`}
                                   position="insideCenter"/>
                    </Bar>
                )}

            </BarChart>
        </ResponsiveContainer>
    )
}