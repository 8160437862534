import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {findGroup, getGroupPathWithIdName} from "../../../helpers/helperfunctions.js";
import {useNavigate} from "react-router-dom";
import {setOverviewFilter} from "../../../helpers/reduxstore/reducers/filterReducer.js";

/** Create path with links to group filter
 * @param {object} device
 * @returns {JSX.Element}
 * @constructor
 */
export function GroupNavigationPath({device}) {
    const {allowedGroups} = useSelector(g => g.group)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [deviceGroupParentPath, setDeviceGroupParentPath] = useState([])
    useEffect(() => {
        const parent = findGroup(device.parent, allowedGroups)
        const pathArray = getGroupPathWithIdName(parent, allowedGroups).reverse()
        setDeviceGroupParentPath(pathArray)
    }, [allowedGroups, device])

    const setGroupFilterAndNavigateToOverview = (groupId) => {
        const group = findGroup(groupId, allowedGroups)
        dispatch(setOverviewFilter({filterGroups: [group], page: 1}))
        navigate("/")
    }

    return <div className='' style={{fontSize: '17px'}}>
        {deviceGroupParentPath.length !== 0 && deviceGroupParentPath
            .map(e => <span key={e.id} className='cursor-pointer group-link'
                            onClick={() => setGroupFilterAndNavigateToOverview(e.id)}>{e.name}</span>)
            .reduce((prev, curr) => [prev, " \u2192 ", curr])}
        {deviceGroupParentPath.length !== 0 && ` \u2192 ${device.name}`}
    </div>
}