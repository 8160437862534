import useSetLocation from "../../../helpers/hooks/useSetLocation.js";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {roleConstants} from "../../../helpers/constants";
import {useMsal} from "@azure/msal-react";
import {hasAnyRole} from "../../../helpers/authHelper.js";
import useScrollPosition from "../../../helpers/hooks/useScrollPosition.js";

export default function V2HowToGuidePage() {
    useSetLocation()
    const {accounts} = useMsal();
    const {t} = useTranslation(['helppage'])
    const {user} = useSelector(t => t.user)
    const [highlightedIndexItem, setHighlightedIndexItem] = useState("calendar")
    const scrollPosition = useScrollPosition();

    useEffect(() => {
        // If there is # in url, manually navigate to element. Cannot use built in browser
        // behaviour because it scrolls too far because of header
        console.log(document.location.hash)
        if (document.location.hash && document.location.hash !== "#") {
            const ele = document.querySelector(document.location.hash)
            if (ele) {
                setTimeout(() => {
                    scrollToEle(ele, 'instant')
                }, 100)
            }
        }
    }, [])

    useEffect(() => {
        if (scrollPosition < qs("#notifications").offsetTop - 85) setHighlightedIndexItem("calendar")
        else if (scrollPosition < qs("#moving_multiple_devices").offsetTop - 85) setHighlightedIndexItem("notifications")
        else if (scrollPosition < qs("#renaming_device_or_group").offsetTop - 85) setHighlightedIndexItem("moving_multiple_devices")
        else if (scrollPosition < qs("#new_group").offsetTop - 85) setHighlightedIndexItem("renaming_device_or_group")
        else if (scrollPosition < qs("#moving_devices").offsetTop - 85) setHighlightedIndexItem("new_group")
        else if (scrollPosition < qs("#device_log_update_and_comment").offsetTop - 85) setHighlightedIndexItem("moving_devices")
        else setHighlightedIndexItem("device_log_update_and_comment")
    }, [scrollPosition])

    function highlightedClass(item) {
        return highlightedIndexItem === item ? 'fw-bold color-airmaster-title-blue' : '';
    }

    return <div id='v2HowToGuidePageContainer' className='d-flex justify-content-center'>

        {/*=== floating left index ===*/}
        <div className='d-xs-none m-5' style={{fontSize: '18px'}}>
            <div style={{position: 'sticky', top: '118px'}}>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('calendar')}
                     onClick={() => scrollToEle(qs("#calendar"))}>{t('helppage:v2guide.calendar.title')}</div>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('notifications')}
                     onClick={() => scrollToEle(qs("#notifications"))}>{t('helppage:v2guide.notifications.title')}</div>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('moving_multiple_devices')}
                     onClick={() => scrollToEle(qs("#moving_multiple_devices"))}>{t('helppage:v2guide.moving_multiple_devices.title')}</div>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('renaming_device_or_group')}
                     onClick={() => scrollToEle(qs("#renaming_device_or_group"))}>{t('helppage:v2guide.renaming_device_or_group.title')}</div>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('new_group')}
                     onClick={() => scrollToEle(qs("#new_group"))}>{t('helppage:v2guide.new_group.title')}</div>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('moving_devices')}
                     onClick={() => scrollToEle(qs("#moving_devices"))}>{t('helppage:v2guide.moving_devices.title')}</div>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('device_log_update_and_comment')}
                     onClick={() => scrollToEle(qs("#device_log_update_and_comment"))}>{t('helppage:v2guide.device_log_update_and_comment.title')}</div>
                <div className={'mb-1 cursor-pointer ' + highlightedClass('download_telemetry_data')}
                     onClick={() => scrollToEle(qs("#download_telemetry_data"))}>{t('helppage:v2guide.download_telemetry_data.title')}</div>
                {hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic]) &&
                    <div className={'mb-1 cursor-pointer ' + highlightedClass('internally_to_airmaster')}
                         onClick={() => scrollToEle(qs("#download_telemetry_data"))}>{t('helppage:v2guide.internally_to_airmaster.title')}</div>
                }
            </div>
        </div>

        {/*=== Main content ===*/}
        <div className='max-w-full' style={{width: '1000px'}}>
            <h1 className='mt-4'>Airlinq Online® V.2</h1>
            <h4 className='' style={{color: '#5a5a5a'}}>{t('helppage:v2guide.new_features')}</h4>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='calendar'
                    className={'ms-2 mt-2 color-airmaster-title-blue'}>{t('helppage:v2guide.calendar.title')}</h3>
                <p>{t('helppage:v2guide.calendar.text1')}</p>
                <p>{t('helppage:v2guide.calendar.text2')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/calendar1.png`}/>
                <p>{t('helppage:v2guide.calendar.text3')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/calendar2.png`}/>
                <p>{t('helppage:v2guide.calendar.text4')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/calendar3.png`}/>
                <p>{t('helppage:v2guide.calendar.text5')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/calendar4.png`}/>
            </div>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='notifications'
                    className={'ms-2 mt-2 color-airmaster-title-blue'}>{t('helppage:v2guide.notifications.title')}</h3>
                <p>{t('helppage:v2guide.notifications.text1')}</p>
                <p>{t('helppage:v2guide.notifications.text2')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/notifications1.png`}/>
                <p>{t('helppage:v2guide.notifications.text3')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/notifications2.png`}/>
                <p>{t('helppage:v2guide.notifications.text4')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/notifications3.png`}/>
                <p>{t('helppage:v2guide.notifications.text5')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/notifications4.png`}/>
                <p>{t('helppage:v2guide.notifications.text6')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/notifications5.png`}/>
                <p>{t('helppage:v2guide.notifications.text7')}</p>
            </div>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='moving_multiple_devices'
                    className={'ms-2 mt-2 color-airmaster-title-blue'}>{t('helppage:v2guide.moving_multiple_devices.title')}</h3>
                <p>{t('helppage:v2guide.moving_multiple_devices.text1')}</p>
                <p>{t('helppage:v2guide.moving_multiple_devices.text2')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/moving-multiple-at-once1.png`}/>
                <p>{t('helppage:v2guide.moving_multiple_devices.text3')}</p>
                <ol>
                    <li>{t('helppage:v2guide.moving_multiple_devices.li1')}</li>
                    <li>{t('helppage:v2guide.moving_multiple_devices.li2')}</li>
                    <li>{t('helppage:v2guide.moving_multiple_devices.li3')}</li>
                </ol>
            </div>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='renaming_device_or_group'
                    className={'ms-2 mt-2 color-airmaster-title-blue'}>{t('helppage:v2guide.renaming_device_or_group.title')}</h3>
                <p>{t('helppage:v2guide.renaming_device_or_group.text1')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/renaming-devices-or-groups1.png`}/>
                <p>{t('helppage:v2guide.renaming_device_or_group.text2')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/renaming-devices-or-groups2.png`}/>
            </div>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='new_group'
                    className={'ms-2 mt-2 color-airmaster-title-blue'}>{t('helppage:v2guide.new_group.title')}</h3>
                <p>{t('helppage:v2guide.new_group.text1')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/new-group1.png`}/>
            </div>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='moving_devices'
                    className={'ms-2 mt-2 color-airmaster-title-blue'}>{t('helppage:v2guide.moving_devices.title')}</h3>
                <p>{t('helppage:v2guide.moving_devices.text1')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/moving-devices1.png`}/>
                <p>{t('helppage:v2guide.moving_devices.text2')}</p>
            </div>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='device_log_update_and_comment' className={'ms-2 mt-2 color-airmaster-title-blue'}
                >{t('helppage:v2guide.device_log_update_and_comment.title')}</h3>
                <p>{t('helppage:v2guide.device_log_update_and_comment.text1')}</p>
                <p>{t('helppage:v2guide.device_log_update_and_comment.text2')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/device-log-update-and-comment1.png`}/>
            </div>

            <V2HowToGuideSeparator className='mt-3'/>
            <div className='p-2'>
                <h3 id='download_telemetry_data' className={'ms-2 mt-2 color-airmaster-title-blue'}
                >{t('helppage:v2guide.download_telemetry_data.title')}</h3>
                <p>{t('helppage:v2guide.download_telemetry_data.text1')}</p>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/download-telemetry-data1.png`}/>
                <V2GuideImg src={`/v2guide-images/${user.preferredLanguage}/download-telemetry-data2.png`}/>
            </div>

            {hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic]) && <>
                <V2HowToGuideSeparator className='mt-3'/>
                <div className='p-2 mb-4'>
                    <h3 id='internally_to_airmaster' className={'ms-2 mt-2 color-airmaster-title-blue'}>
                        {t('helppage:v2guide.internally_to_airmaster.title')}
                    </h3>
                    <p>{t('helppage:v2guide.internally_to_airmaster.text1')}</p>
                    <p>{t('helppage:v2guide.internally_to_airmaster.text2')}</p>
                    <p>{t('helppage:v2guide.internally_to_airmaster.text3')}</p>
                </div>
            </>}
        </div>

    </div>
}

function V2HowToGuideSeparator({className}) {
    return <div className={className} style={{height: "1px", background: '#c3c4c5'}}/>
}

function V2GuideImg({src}) {
    return <img className='max-w-full' src={src} alt=''/>;
}

function scrollToEle(ele, behavior = 'smooth') {
    window.scrollTo({left: 0, top: ele.offsetTop - 84, behavior: behavior})
}

function qs(s) {
    return document.querySelector(s)
}
