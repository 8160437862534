export const cdsLevel = {
 "-1": "invalid",
    0: "off",
    1: "low",
    2: "mid",
    3: "high",
}    

export const defrostState = {
    0: "idle",
    1: "defrosting_outside",
    2: "defrosting_inside",
}

export const freqconvState = {
    0: "initial",
    1: "halted",
    2: "stopped",
    3: "started",
    4: "stopping",
    5: "stopping_min_rev",
    6: "stopping_stop",
    7: "started_init",
    8: "started_reset",
    9: "started_running",
    10: "started_reversing",
    11: "started_lubrication",
    12: "started_error",
    13: "started_fatal_error",
    14: "stopped_idle",
    15: "stopped_off",
    16: "fat",
    17: "fat_started",
    18: "fat_success",
    19: "fat_fail",
    20: "max",
}    

export const fwValveState = {
    "-1": "error",
    0: "cooling",
    1: "heating",
}

export const mainState = {
    0: "initial",
    1: "initial_purge",
    2: "initial_damper_calibration",
    3: "startup",
    4: "startup_purge",
    5: "startup_starting",
    6: "running",
    7: "running_defrost",
    8: "running_comfort",
    9: "running_boost_vent",
    10: "running_main_flow",
    11: "running_holiday",
    12: "running_night_cool",
    13: "stopping",
    14: "standby",
    15: "standby_waiting",
    16: "standby_timer",
    17: "standby_permanent",
    18: "testing",
    19: "testing_purge",
    20: "testing_manual",
    21: "testing_manual_override",
    22: "error",
    23: "clear_errors"
}
    
export const opMode = {
    "-1": "invalid",
    0: "standby_waiting",
    1: "standby_timer",
    2: "standby_permanent",
    3: "comfort",
    4: "boost_vent",
    5: "manual_flow",
    6: "manual_temp",
    7: "nightcool",
    8: "holiday",
    9: "deep_sleep",
}

export const rcState = {
    0: "initial",
    1: "recirc",
    2: "vent",
    3: "recirc_heat",
    4: "vent_heat",
    5: "recirc_cool",
    6: "vent_cool",
}

export const startedBy = {
    0: "none",
    1: "ext_start",
    2: "movement_sensor",
    3: "analog",
    4: "ui",
    5: "co2",
    6: "schedule",
    7: "manual",
    8: "boost",
}