import {useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import InputGroup from '../../../components/shared/inputgroup/InputGroup.js'
import ProfileImageSelector from '../../../components/shared/profileimageselector/ProfileImageSelector'
import {syslang, userObject} from '../../../helpers/constants'
import {profileValidator} from '../../../helpers/helperfunctions'
import {updateNewsletterPreferences, updateUserInformation} from '../../../helpers/reduxstore/reducers/userReducer'
import EditButtonGroup from '../../../components/shared/editbuttongroup/EditButtonGroup'
import ComboBox from '../../../components/shared/combobox/ComboBox'

/** Renders the user-profile section of the user-profile page. The component allows the user to change user profile settings. */
export default function UserProfile({style = {}}) {
    const {t} = useTranslation(['userpage', 'common'])
    const dispatch = useDispatch()
    const [editing, setEditing] = useState(false)
    const [userInformation, setUserInformation] = useState(userObject)
    const [newsletterPreferencesCheck, setNewsletterPreferencesCheck] = useState(false)
    const [dialCode, setDialCode] = useState("")
    const [errors, setErrors] = useState({})
    const [touched, setTouched] = useState(false)
    const {user, newsletterPref} = useSelector(state => state.user)

    useEffect(() => {
        setUserInformation(user)
        setNewsletterPreferencesCheck(newsletterPref)
    }, [user, newsletterPref])

    function handleChange(e) {
        const {name, value, checked, type} = e.target
        setUserInformation({
            ...userInformation,
            [name]: type === 'checkbox' ? checked : value,
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        let validationResult = profileValidator(userInformation.givenName, userInformation.surname, userInformation.mail, userInformation.mobilePhone, dialCode)
        setErrors(validationResult)
        if (Object.keys(validationResult).length === 0) {
            if (user !== userInformation)
                dispatch(updateUserInformation(userInformation))
            if (newsletterPref !== newsletterPreferencesCheck)
                dispatch(updateNewsletterPreferences(newsletterPreferencesCheck))
            setTouched(false)
            setEditing(!editing)
        } else {
            setTouched(true)
        }
    }

    const handleCancel = e => {
        e.preventDefault()
        setErrors({})
        setUserInformation(user)
        setNewsletterPreferencesCheck(newsletterPref)
        setTouched(false)
        setEditing(!editing)
    }

    function getPrefLangOrEnglish(preferredLanguage) {
        const foundLang = syslang.find(e => e.id === preferredLanguage)
        if (foundLang) {
            return preferredLanguage
        } else {
            return "en"
        }
    }

    return (
        <div className='p-3 boxstyling h-100' style={style}>
            <div className='text-center profile-img-size mx-auto'>
                <ProfileImageSelector givenName={user.givenName} surname={user.surname}/>
            </div>
            <Form className="p-3" onSubmit={handleSubmit}>
                <InputGroup label={t('userpage:profile_inputs.fname')} className="inputhalfcol pr-lg-1"
                            placeholder={t('userpage:profile_inputs.placeholders.fname')} type='text' name='givenName'
                            value={userInformation.givenName} inputValue={handleChange} disabled={!editing}
                            isInvalid={!!errors.givenName} errorText={errors.givenName}/>
                <InputGroup label={t('userpage:profile_inputs.lname')} className="inputhalfcol pl-lg-1"
                            placeholder={t('userpage:profile_inputs.placeholders.lname')} type='text' name='surname'
                            value={userInformation.surname} inputValue={handleChange} disabled={!editing}
                            isInvalid={!!errors.surname} errorText={errors.surname}/>
                <InputGroup label={t('userpage:profile_inputs.email')} className="inputfullcol"
                            placeholder={t('userpage:profile_inputs.placeholders.email')} type='text' name='mail'
                            value={userInformation.mail} inputValue={handleChange} disabled={!editing}
                            isInvalid={!!errors.mail} errorText={errors.mail}/>
                <Form.Group className='inputfullcol'>
                    <Form.Label>{t('userpage:profile_inputs.phone')}</Form.Label>
                    <PhoneInput inputProps={{name: "mobilePhone"}}
                                disabled={!editing} placeholder={t('userpage:profile_inputs.placeholders.phone')}
                                value={userInformation.mobilePhone} countryCodeEditable={false}
                                onChange={(value, country, e) => {
                                    setDialCode(country.dialCode)
                                    handleChange(e)
                                }}
                                inputClass={((errors.mobilePhone && touched) ? 'react-tel-invalid ' : '') + 'w-100 react-tel-height shadow-none remove-autofill-overlay'}
                                buttonClass={((errors.mobilePhone && touched) ? 'react-tel-btn-invalid' : '')}
                                enableSearch={true} disableSearchIcon={true} regions={'europe'} country={'dk'}/>
                    <Form.Control.Feedback type="invalid"
                                           className={(touched && errors.mobilePhone) ? 'd-block' : 'd-none'} tooltip>
                        {errors.mobilePhone}
                    </Form.Control.Feedback>
                </Form.Group>
                <InputGroup label={t('userpage:profile_inputs.company')} className="inputfullcol"
                            placeholder={t('userpage:profile_inputs.placeholders.company')} type='text'
                            name='companyName' value={userInformation.companyName} inputValue={handleChange}
                            disabled={!editing}/>
                <ComboBox label={t('userpage:profile_inputs.syslang')} className="inputfullcol" name='preferredLanguage'
                          options={syslang} value={getPrefLangOrEnglish(userInformation.preferredLanguage)}
                          disabled={!editing} inputValue={handleChange} blankChoice={''}/>

                {/* missing functionality
                <div className='d-md-inline-block container mt-4 text-nowrap inputhalfcol'>
                    <Checkbox className="d-inline-block" controlId='newsletterCheck'
                              checked={newsletterPreferencesCheck}
                              onChange={(e) => setNewsletterPreferencesCheck(e.target.checked)}
                              label={t('userpage:profile_inputs.newsletterpref')} name="newsletterpref"
                              disabled={!editing}/>
                    <PopOverDialog popOverTitel={t('userpage:profile_inputs.newletter_popover.header')} popOverContent={t('userpage:profile_inputs.newletter_popover.body')} />
                </div>
                */}

                <div className='mt-3 p-3'>
                    {editing
                        ? <EditButtonGroup submitClick={handleSubmit} cancelClick={handleCancel}/>
                        : <Button className="w-100" variant='outline-secondary' onClick={() => setEditing(!editing)}
                                  type="submit">{t('common:buttons.edit')}</Button>}
                </div>
            </Form>
        </div>
    )
}