import RangeGroup from '../../../../components/shared/rangegroup/RangeGroup.js'
import {optionalValidationTypes, toastTypes} from '../../../../helpers/constants.js'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import SwitchGroup from "../../../shared/switchgroup/SwitchGroup.js";
import {Button} from "react-bootstrap";
import InputGroupWithMessages from "../../../shared/inputgroupwithmessages/InputGroupWithMessages.js";
import {getParameterToShow, updateFieldIfChanged} from "../../../../helpers/settingsHelper.js";
import {addParameterIfChanged, validateParameter} from "../../../../helpers/awareSettingsHelper.js";
import {InfoCircleFill} from 'react-bootstrap-icons';
import {cloneDeep} from "lodash";
import PopOverDialog from "../../../shared/popoverdialog/PopOverDialog.js";
import {addToast} from "../../../../helpers/reduxstore/reducers/toastReducer.js";
import {useDispatch} from "react-redux";
import {isBasic, isTechnicalOrMore} from "../../../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";
import SettingsSubHeader from "../../settingscomponents/SettingsSubHeader.js";
import {awareParamNames} from "../../../parameters/awareParamSpec.js";

/** Displays the configuration page for setpoints settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device)
 */
export default function SetpointsSettings({device, className, save}) {
    const {t} = useTranslation(['settingspage', 'common'])
    const dispatch = useDispatch()
    const {accounts} = useMsal();

    const [oldDeviceState, setOldDeviceState] = useState(cloneDeep(device))

    const [airflowControlLimitMin, setAirflowControlLimitMin] = useState(getParameterToShow(device, awareParamNames.min_flow_setpoint, t))
    const [airflowControlLimitMax, setAirflowControlLimitMax] = useState(getParameterToShow(device, awareParamNames.max_flow_setpoint, t))
    const [temperatureSetpoint, setTemperatureSetpoint] = useState(getParameterToShow(device, awareParamNames.default_temp, t))
    const [deltaTempCooling, setDeltaTempCooling] = useState(getParameterToShow(device, awareParamNames.delta_temp_cooling, t))
    const [deltaTempHeating, setDeltaTempHeating] = useState(getParameterToShow(device, awareParamNames.delta_temp_heating, t))
    const [highRoomControlLimitMax, setHighRoomControlLimitMax] = useState(getParameterToShow(device, awareParamNames.max_temp_setpoint, t))
    const [airflowSetpoint, setAirflowSetpoint] = useState(getParameterToShow(device, awareParamNames.default_flow, t))
    const [allowBoost, setAllowBoost] = useState(getParameterToShow(device, awareParamNames.allow_boost, t))
    const [pirAfterRunTime, setPirAfterRunTime] = useState(getParameterToShow(device, awareParamNames.pir_afterrun_timer, t))
    const [co2ControlLimitMin, setCo2ControlLimitMin] = useState(getParameterToShow(device, awareParamNames.co2_control_min, t))
    const [co2ControlLimitMax, setCo2ControlLimitMax] = useState(getParameterToShow(device, awareParamNames.co2_control_max, t))

    useEffect(() => {
        // When device is updated after write, update parameters to show / hide messages
        updateFieldIfChanged(setAirflowControlLimitMin, oldDeviceState, device, awareParamNames.min_flow_setpoint, t)
        updateFieldIfChanged(setAirflowControlLimitMax, oldDeviceState, device, awareParamNames.max_flow_setpoint, t)
        updateFieldIfChanged(setTemperatureSetpoint, oldDeviceState, device, awareParamNames.default_temp, t)
        updateFieldIfChanged(setDeltaTempCooling, oldDeviceState, device, awareParamNames.delta_temp_cooling, t)
        updateFieldIfChanged(setDeltaTempHeating, oldDeviceState, device, awareParamNames.delta_temp_heating, t)
        updateFieldIfChanged(setAirflowSetpoint, oldDeviceState, device, awareParamNames.default_flow, t)
        updateFieldIfChanged(setAllowBoost, oldDeviceState, device, awareParamNames.allow_boost, t)
        updateFieldIfChanged(setPirAfterRunTime, oldDeviceState, device, awareParamNames.pir_afterrun_timer, t)
        updateFieldIfChanged(setCo2ControlLimitMin, oldDeviceState, device, awareParamNames.co2_control_min, t)
        updateFieldIfChanged(setCo2ControlLimitMax, oldDeviceState, device, awareParamNames.co2_control_max, t)
        setOldDeviceState(cloneDeep(device))
    }, [device])

    /**
     * validates parameters. Adds messages to viewmodels if fail. returns true if all is validated, otherwise false.
     * @returns {boolean}
     */
    const validateParameters = () => {
        let validatedList = [true]
        validatedList.push(validateParameter(awareParamNames.min_flow_setpoint, airflowControlLimitMin, setAirflowControlLimitMin, t,
            [{type: optionalValidationTypes.leftLessThanRight, rightModel: airflowControlLimitMax}]))
        validatedList.push(validateParameter(awareParamNames.max_flow_setpoint, airflowControlLimitMax, setAirflowControlLimitMax, t))
        validatedList.push(validateParameter(awareParamNames.default_temp, temperatureSetpoint, setTemperatureSetpoint, t))
        validatedList.push(setDeltaTempCoolingAndValidate(Number(deltaTempCooling.value)))
        validatedList.push(setDeltaTempHeatingAndValidate(Number(deltaTempHeating.value)))
        validatedList.push(validateParameter(awareParamNames.max_temp_setpoint, highRoomControlLimitMax, setHighRoomControlLimitMax, t))
        validatedList.push(validateParameter(awareParamNames.default_flow, airflowSetpoint, setAirflowSetpoint, t))
        validatedList.push(validateParameter(awareParamNames.pir_afterrun_timer, pirAfterRunTime, setPirAfterRunTime, t))
        validatedList.push(validateParameter(awareParamNames.co2_control_min, co2ControlLimitMin, setCo2ControlLimitMin, t,
            [{type: optionalValidationTypes.leftLessThanRight, rightModel: co2ControlLimitMax}]))
        validatedList.push(validateParameter(awareParamNames.co2_control_max, co2ControlLimitMax, setCo2ControlLimitMax, t))
        return validatedList.every(v => v)
    }

    const getValuesToSave = () => {
        const updated_parameters = {}
        addParameterIfChanged(updated_parameters, awareParamNames.min_flow_setpoint, airflowControlLimitMin.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.max_flow_setpoint, airflowControlLimitMax.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.delta_temp_cooling, deltaTempCooling.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.delta_temp_heating, deltaTempHeating.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.default_temp, temperatureSetpoint.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.default_flow, airflowSetpoint.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.allow_boost, allowBoost.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.pir_afterrun_timer, pirAfterRunTime.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.co2_control_min, co2ControlLimitMin.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.co2_control_max, co2ControlLimitMax.value, device)
        console.log(updated_parameters)
        return updated_parameters
    }

    function saveIfValidated(saveForGroup) {
        if (!validateParameters()) return

        let valuesToSave = getValuesToSave();
        if (Object.keys(valuesToSave).length === 0) {
            dispatch(addToast({
                id: "SetpointsSettingsInfoToast",
                type: toastTypes.info,
                title: "No changes",
                body: "No changes to parameters",
                timeSeconds: 3
            }))
            return
        }
        save(valuesToSave, saveForGroup);
    }

    function setTemperatureSetpointAndAdjustDeltas(newTempValue) {
        setDeltaTempCoolingAndValidate(Number(deltaTempCooling.value))
        setDeltaTempHeatingAndValidate(Number(deltaTempHeating.value))
        setTemperatureSetpoint({value: newTempValue})
    }

    function setDeltaTempCoolingAndValidate(newValue) {
        let messages = [];
        if (newValue < 0) {
            messages.push({
                type: "error",
                id: "delta_temp_cooling_less_than_temp_setpoint",
                text: "Cooling setpoint cannot be less than Room Temperature setpoint"
            })
        }
        setDeltaTempCooling({value: newValue, messages: messages})
        return messages.length === 0 // true === no error
    }

    function setDeltaTempHeatingAndValidate(newValue) {
        let messages = [];
        if (newValue > 0) {
            messages.push({
                type: "error",
                id: "delta_temp_heating_more_than_temp_setpoint",
                text: "Heating setpoint cannot be more than Room Temperature setpoint"
            })
        }
        setDeltaTempHeating({value: newValue, messages: messages})
        return messages.length === 0 // true === no error
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation title={t('settingspage:setpoints_settings.title')}
                                        subTitle={t('settingspage:setpoints_settings.description')}/>

            <SettingsSubHeader>{t('settingspage:setpoints_settings.environment_settings')}</SettingsSubHeader>
            <div className="mt-sm-3 d-flex flex-wrap justify-content-xs-center">
                <div className='w-300px'>
                    <RangeGroup
                        label={t('settingspage:parameters.airflow_control_limits')}
                        className='p-4 m-auto'
                        unit='%'
                        leftValue={airflowControlLimitMin.value}
                        leftOnChange={e => setAirflowControlLimitMin({value: e.target.value, messages: []})}
                        leftPlaceholder='Min %'
                        leftMessages={airflowControlLimitMin.messages}
                        rightValue={airflowControlLimitMax.value}
                        rightOnChange={e => setAirflowControlLimitMax({value: e.target.value, messages: []})}
                        rightPlaceholder='Max %'
                        rightMessages={airflowControlLimitMax.messages}
                        type='number'
                        disabled={device.slave}
                        infoelement={<PopOverDialog
                            buttonPosition='position-absolute'
                            popOverTitel={t('settingspage:parameters.airflow_control_limits')}
                            popOverContent={t('settingspage:infomodals.airflow_control_limits')}
                            trigger={['hover', 'touch']}
                            contentAsHtml={true}/>}
                    />
                </div>
                <div className='w-300px'>
                    <InputGroupWithMessages
                        label={t('settingspage:parameters.room_temperature_setpoint')}
                        className='p-4 m-auto'
                        value={temperatureSetpoint.value}
                        unit='°C'
                        disabled={device.slave}
                        inputValue={e => setTemperatureSetpointAndAdjustDeltas(Number(e.target.value))}
                        placeholder={t('common:units.degrees')}
                        messages={temperatureSetpoint.messages}
                        type='number'
                        infoelement={<PopOverDialog
                            popOverTitel={t('settingspage:parameters.room_temperature_setpoint')}
                            popOverContent={t('settingspage:infomodals.room_temperature_setpoint')}
                            trigger={['hover', 'touch']}
                            contentAsHtml={true}/>}
                    />
                </div>
                {isTechnicalOrMore(accounts) &&
                    <div className='w-300px'>
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.delta_temp_cooling')}
                            className='p-4 m-auto'
                            value={Number(deltaTempCooling.value) + Number(temperatureSetpoint.value)}
                            unit='°C'
                            disabled={device.slave}
                            inputValue={e => setDeltaTempCoolingAndValidate(Number(e.target.value) - Number(temperatureSetpoint.value))}
                            placeholder={t('common:units.degrees')}
                            messages={deltaTempCooling.messages}
                            type='number'
                            infoelement={<PopOverDialog
                                popOverTitel={t('settingspage:parameters.delta_temp_cooling')}
                                popOverContent={t('settingspage:infomodals.delta_temp_cooling')}
                                trigger={['hover', 'touch']}
                                contentAsHtml={true}/>}
                        />
                    </div>
                }
                {isTechnicalOrMore(accounts) &&
                    <div className='w-300px'>
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.delta_temp_heating')}
                            className='p-4 m-auto'
                            value={Number(deltaTempHeating.value) + Number(temperatureSetpoint.value)}
                            unit='°C'
                            disabled={device.slave}
                            inputValue={e => setDeltaTempHeatingAndValidate(Number(e.target.value) - Number(temperatureSetpoint.value))}
                            placeholder={t('common:units.degrees')}
                            messages={deltaTempHeating.messages}
                            type='number'
                            infoelement={<PopOverDialog
                                popOverTitel={t('settingspage:parameters.delta_temp_heating')}
                                popOverContent={t('settingspage:infomodals.delta_temp_heating')}
                                trigger={['hover', 'touch']}
                                contentAsHtml={true}/>}
                        />
                    </div>
                }
                <div className='w-300px'>
                    <InputGroupWithMessages
                        label={t('settingspage:parameters.airflow_setpoint')}
                        className='p-4 m-auto'
                        value={airflowSetpoint.value}
                        unit='%'
                        disabled={device.slave}
                        inputValue={e => setAirflowSetpoint({value: e.target.value})}
                        placeholder={t('common:units.percentage')}
                        messages={airflowSetpoint.messages}
                        min={0}
                        max={100}
                        type='number'
                        infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.airflow_setpoint')}
                                                    popOverContent={t('settingspage:infomodals.airflow_setpoint')}
                                                    trigger={['hover', 'touch']}
                                                    contentAsHtml={true}/>}
                    />
                </div>
                <div className='w-300px'>
                    <RangeGroup
                        label={t('settingspage:parameters.co2_control_limit')}
                        className='p-4 m-auto'
                        unit='ppm'
                        leftValue={co2ControlLimitMin.value}
                        leftOnChange={e => setCo2ControlLimitMin({value: e.target.value})}
                        leftPlaceholder={'Min ' + t('common:general.value')}
                        leftMessages={co2ControlLimitMin.messages}
                        rightValue={co2ControlLimitMax.value}
                        rightOnChange={e => setCo2ControlLimitMax({value: e.target.value})}
                        rightPlaceholder={'Max ' + t('common:general.value')}
                        rightMessages={co2ControlLimitMax.messages}
                        type='number'
                    />
                </div>
                <div className='w-300px'>
                    <SwitchGroup
                        label={t('settingspage:parameters.allow_boost')}
                        className='p-4 m-auto'
                        checked={allowBoost.value}
                        onChange={e => setAllowBoost({value: e.target.checked ? true : false})}
                        messages={allowBoost.messages}
                        infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.allow_boost')}
                                                    popOverContent={t('settingspage:infomodals.allow_boost')}
                                                    trigger={['hover', 'touch']}
                                                    contentAsHtml={true}/>}
                    />
                </div>
                {!isBasic(accounts) &&
                    <div className='w-300px'>
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.pir_after_run_time')}
                            className='p-4 m-auto'
                            value={pirAfterRunTime.value}
                            unit={t('common:units.minutes')}
                            inputValue={e => setPirAfterRunTime({value: e.target.value})}
                            disabled={device.slave}
                            placeholder={t('common:units.minutes')}
                            messages={pirAfterRunTime.messages}
                            type='number'
                            infoelement={<PopOverDialog
                                popOverTitel={t('settingspage:parameters.pir_after_run_time')}
                                popOverContent={t('settingspage:infomodals.pir_after_run_time')}
                                trigger={['hover', 'touch']}
                                contentAsHtml={true}/>}
                        />
                    </div>
                }
            </div>

            <div className="alert alert-info" hidden={!device.slave}>
                <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:slave-device-settings')}
                {device.master_serial_number &&
                    <a href={"/device/" + device.master_serial_number + "/configurations/setpoints"}>{device.master_serial_number}</a>}
            </div>

            {/*=== Button container*/}
            <div className='d-inline-block float-end p-2'>
                <Button variant='outline-secondary' disabled={device.slave} onClick={() => saveIfValidated(false)}
                        className='me-2'>
                    {t('settingspage:save_device')}</Button>
                <Button variant='outline-secondary' disabled={device.slave} onClick={() => saveIfValidated(true)}>
                    {t('settingspage:save_group')}</Button>
            </div>
        </div>
    )
}