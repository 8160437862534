import React from 'react'
import {Container} from 'react-bootstrap'
import InformationModal from '../../../components/shared/informationmodal/InformationModal.js'
import {paramSpec} from "../parameterSpec.js";
import {useDispatch} from "react-redux";
import {saveConfigurationByDeviceList} from "../../../helpers/reduxstore/reducers/groupReducer.js";
import {awareParamSpec} from "../awareParamSpec.js";
import {isAwareDevice} from "../../../helpers/helperfunctions.js";

export default function UploadSettingsModal({configObj, setConfigObj, isAware}) {
    const dispatch = useDispatch()

    const confirmUploadSettings = () => {
        console.log(`saving new config for device ${configObj.device.serial_number}`, configObj.changedEntryList.toMap())
        dispatch(saveConfigurationByDeviceList(configObj.changedEntryList.toMap(), [configObj.device.id], !isAwareDevice(configObj.device.serial_number))).then();
        setConfigObj({isVisible: false})
    }

    return <InformationModal dialogClassName='group-selector-modal' show={configObj?.isVisible ?? false}
                             title={'Confirm Upload Settings'}
                             body={
                                 <Container className="py-2 overflow-y-auto" style={{maxHeight: '780px'}}>
                                     <p className='mb-1 border-bottom'>{`File contains ${configObj?.uploadedSettingsMap?.getKeys()?.length ?? 0} parameters`}</p>

                                     {configObj?.uploadedSettingsDiscardedBecauseReadonlyEntryList?.length !== 0 &&
                                         <div className='p-1'>
                                             <p className='mb-1'>Following are readonly so cannot be set</p>
                                             <div className={'d-flex flex-wrap p-1'} style={{fontSize: '13px'}}>
                                                 {configObj?.uploadedSettingsDiscardedBecauseReadonlyEntryList?.map(pair =>
                                                     <span
                                                         className='me-1' key={pair[0]}>{pair[0]}</span>)}
                                             </div>
                                         </div>
                                     }

                                     {(configObj?.changedEntryList?.length === 0 ?? true) &&
                                         <p>No parameters have been changed so no updates will take place</p>}
                                     {(configObj?.changedEntryList?.length > 0) &&
                                         <div className='p-1'>
                                             <p className='mb-1'>Following Parameters are changed and will be
                                                 updated</p>
                                             <div className={'d-flex flex-wrap p-1'}>
                                                 {configObj?.changedEntryList?.map(([key, value]) =>
                                                     <div key={key} className='d-flex px-3 mb-1 justify-content-between'
                                                          style={{flex: '1 0 44%'}}>
                                                         <span
                                                             className='me-2'>{key} ({(isAware ? paramSpec : awareParamSpec)[key]?.name_orig ?? "NAME_NOT_FOUND"}):</span>
                                                         <div className='small d-flex'>
                                                             <div
                                                                 className='bg-danger rounded text-center text-white px-2'>{configObj?.device?.device_twin?.reported?.parameters[key].toString()}</div>
                                                             <span
                                                                 className='text-center mx-2'>{' \u2192 '}</span>
                                                             <div
                                                                 className='bg-success rounded text-center text-white px-2'>{value.toString()}</div>
                                                         </div>
                                                     </div>
                                                 )}
                                             </div>
                                         </div>
                                     }

                                     <div className='p-1' style={{maxHeight: "400px"}}>
                                         <p className='mb-1'>Following Parameters are unchanged</p>
                                         <div className={'d-flex flex-wrap p-1'} style={{fontSize: '13px'}}>
                                             {configObj?.unchangedEntryList?.map(pair => <span
                                                 className='me-1' key={pair[0]}>{pair[0]}</span>)}
                                         </div>
                                     </div>

                                 </Container>
                             }
                             size='lg'
                             accept={confirmUploadSettings}
                             acceptBtnDisabled={configObj?.changedEntryList?.length === 0 ?? true}
                             dismiss={() => setConfigObj({isVisible: false})}/>
}