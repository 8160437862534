import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function FirmwareNavBar({currentPage}) {
    const {t} = useTranslation(['firmwarepage'])
    const navigate = useNavigate()

    function preventDefaultAndNavigate(e, navigationUrl) {
        e.preventDefault() // prevent native navigation when clicking on anchor. It needs to be anchor to get middleclick open new tab functionality.
        navigate(navigationUrl)
    }

    return <div className='mt-4 mb-2 w-100 d-flex border-bottom'>
        <a className={`text-decoration-none cursor-pointer dashboard-navbar-link ${currentPage === 'update' ? 'selected' : ''}`}
           href={"/firmware/update"}
           onClick={e => preventDefaultAndNavigate(e, "/firmware/update")}>{t('firmwarepage:navigation.update')}
        </a>
        <a className={`ms-1 text-decoration-none cursor-pointer dashboard-navbar-link ${currentPage === 'manage' ? 'selected' : ''}`}
           href={"/firmware/manage"}
           onClick={e => preventDefaultAndNavigate(e, "/firmware/manage")}> {t('firmwarepage:navigation.manage')}
        </a>
    </div>
}