import {mainState, opMode, rcState, startedBy} from "./awareTelemEnumOptions.js";

/** This file has been generated. If you see errors or missing telemetry, don't change this file. Instead, fix generate_aware_telemetry_specification_from_json.js and regenerate it. */
export const awareTelemSpec = {
    "udf": {
        "id": "udf",
        "parent": 0,
        "name": "User Defined Air Flow",
        "name_orig": "User Defined Air Flow",
        "desc": "User Defined Air Flow",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "rqf": {
        "id": "rqf",
        "parent": 0,
        "name": "Requested Flow",
        "name_orig": "Requested Flow",
        "desc": "Requested Flow",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "udt": {
        "id": "udt",
        "parent": 0,
        "name": "User Defined Temperature",
        "name_orig": "User Defined Temperature",
        "desc": "User Defined Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "rqt": {
        "id": "rqt",
        "parent": 0,
        "name": "Requested Temperature",
        "name_orig": "Requested Temperature",
        "desc": "Requested Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "trt": {
        "id": "trt",
        "parent": 0,
        "name": "Target Room Temperature",
        "name_orig": "Target Room Temperature",
        "desc": "Target Room Temperature",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "°C"
    },
    "started_by": {
        "id": "started_by",
        "parent": 0,
        "name": "Started By",
        "name_orig": "Started By",
        "desc": "Started By",
        "type": "enum",
        "min": "STARTED_BY_NONE",
        "max": "STARTED_BY_BOOST",
        "res": "1.0",
        "format": "%u",
        "enum": "STARTED_BY",
        "options": startedBy
    },
    "op_mode": {
        "id": "op_mode",
        "parent": 0,
        "name": "Operation Mode",
        "name_orig": "Operation Mode",
        "desc": "Operation Mode",
        "type": "enum",
        "min": "OP_MODE_INVALID",
        "max": "OP_MODE_DEEP_SLEEP",
        "res": "1.0",
        "format": "%u",
        "enum": "OP_MODE",
        "options": opMode
    },
    "ech_1_pct": {
        "id": "ech_1_pct",
        "parent": 0,
        "name": "Electric Comfort Heater 1 %",
        "name_orig": "Electric Comfort Heater 1 %",
        "desc": "Electric Comfort Heater 1 %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "1.0",
        "format": "%.1f",
        "unit": "%"
    },
    "ech_2_pct": {
        "id": "ech_2_pct",
        "parent": 0,
        "name": "Electric Comfort Heater 2 %",
        "name_orig": "Electric Comfort Heater 2 %",
        "desc": "Electric Comfort Heater 2 %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "1.0",
        "format": "%.1f",
        "unit": "%"
    },
    "ech_3_pct": {
        "id": "ech_3_pct",
        "parent": 0,
        "name": "Electric Comfort Heater 3 %",
        "name_orig": "Electric Comfort Heater 3 %",
        "desc": "Electric Comfort Heater 3 %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "1.0",
        "format": "%.1f",
        "unit": "%"
    },
    "cc_pct": {
        "id": "cc_pct",
        "parent": 0,
        "name": "Comfort Cool %",
        "name_orig": "Comfort Cool %",
        "desc": "Comfort Cool %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "hp_pct": {
        "id": "hp_pct",
        "parent": 0,
        "name": "Heat Pump %",
        "name_orig": "Heat Pump %",
        "desc": "Heat Pump %",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "hvac_pct": {
        "id": "hvac_pct",
        "parent": 0,
        "name": "HVAC %",
        "name_orig": "HVAC %",
        "desc": "HVAC %",
        "type": "decimal",
        "min": -100,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "four_w_valve": {
        "id": "four_w_valve",
        "parent": 0,
        "name": "fourWayValve",
        "name_orig": "fourWayValve",
        "desc": "fourWayValve",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "htp": {
        "id": "htp",
        "parent": 0,
        "name": "High Temp Process",
        "name_orig": "High Temp Process",
        "desc": "High Temp Process",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "hta": {
        "id": "hta",
        "parent": 0,
        "name": "High Temp Allowed",
        "name_orig": "High Temp Allowed",
        "desc": "High Temp Allowed",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "ltp": {
        "id": "ltp",
        "parent": 0,
        "name": "Low Temp Process",
        "name_orig": "Low Temp Process",
        "desc": "Low Temp Process",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "ltala": {
        "id": "ltala",
        "parent": 0,
        "name": "Low Temp Alarm Allowed",
        "name_orig": "Low Temp Alarm Allowed",
        "desc": "Low Temp Alarm Allowed",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "hsa": {
        "id": "hsa",
        "parent": 0,
        "name": "Heating Surfaces Allowed",
        "name_orig": "Heating Surfaces Allowed",
        "desc": "Heating Surfaces Allowed",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "ncp": {
        "id": "ncp",
        "parent": 0,
        "name": "Night Cool Process",
        "name_orig": "Night Cool Process",
        "desc": "Night Cool Process",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "eth0_IP": {
        "id": "eth0_IP",
        "parent": 0,
        "name": "Ethernet 0 IP",
        "name_orig": "Ethernet 0 IP",
        "desc": "Ethernet 0 IP",
        "type": "string",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%s"
    },
    "wlan0_IP": {
        "id": "wlan0_IP",
        "parent": 0,
        "name": "WLAN 0 IP",
        "name_orig": "WLAN 0 IP",
        "desc": "WLAN 0 IP",
        "type": "string",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%s"
    },
    "lt_offset": {
        "id": "lt_offset",
        "parent": 0,
        "name": "Local Time Offset",
        "name_orig": "Local Time Offset",
        "desc": "Local Time Offset",
        "type": "int",
        "min": -86400,
        "max": 86400,
        "res": "1.0",
        "format": "%d",
        "unit": "s"
    },
    "globalErr": {
        "id": "globalErr",
        "parent": 0,
        "name": "globalErr",
        "name_orig": "globalErr",
        "desc": "globalErr",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "comErr": {
        "id": "comErr",
        "parent": 0,
        "name": "comErr",
        "name_orig": "comErr",
        "desc": "comErr",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "globalAlarms": {
        "id": "globalAlarms",
        "parent": 0,
        "name": "globalAlarms",
        "name_orig": "globalAlarms",
        "desc": "globalAlarms",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "globalWarnings": {
        "id": "globalWarnings",
        "parent": 0,
        "name": "globalWarnings",
        "name_orig": "globalWarnings",
        "desc": "globalWarnings",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "sys_time": {
        "id": "sys_time",
        "parent": 1000,
        "name": "sys_time",
        "name_orig": "sys_time",
        "desc": "sys_time",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u"
    },
    "delta_t_evap": {
        "id": "delta_t_evap",
        "parent": 1000,
        "name": "Delta T_evap",
        "name_orig": "Delta T_evap",
        "desc": "Delta T_evap",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "msm_state": {
        "id": "msm_state",
        "parent": 1000,
        "name": "SM state",
        "name_orig": "SM state",
        "desc": "SM state",
        "type": "enum",
        "min": "MAIN_SM_STATE_INITIAL",
        "max": "MAIN_SM_STATE_CLEARERRORS",
        "res": "1.0",
        "format": "%u",
        "enum": "MAIN_SM_STATE",
        "options": mainState
    },
    "rc_state": {
        "id": "rc_state",
        "parent": 1000,
        "name": "RC state",
        "name_orig": "RC state",
        "desc": "RC state",
        "type": "enum",
        "min": "RC_STATE_INITIAL",
        "max": "RC_STATE_VENT_COOL",
        "res": "1.0",
        "format": "%u",
        "enum": "RC_STATE",
        "options": rcState
    },
    "defrost_state": {
        "id": "defrost_state",
        "parent": 1000,
        "name": "Defrost state",
        "name_orig": "Defrost state",
        "desc": "Defrost state",
        "type": "enum",
        "min": "DEFROST_STATE_IDLE",
        "max": "DEFROST_STATE_DEFROSTING_INSIDE",
        "res": "1.0",
        "format": "%u",
        "enum": "DEFROST_STATE"
    },
    "freconv_state": {
        "id": "freconv_state",
        "parent": 1000,
        "name": "Frequency Converter State",
        "name_orig": "Frequency Converter State",
        "desc": "Frequency Converter State",
        "type": "enum",
        "min": "FREQCONV_SM_STATE_INITIAL",
        "max": "FREQCONV_SM_STATE_MAX",
        "res": "1.0",
        "format": "%u",
        "enum": "FREQCONV_SM_STATE"
    },
    "remaining_service_life_days": {
        "id": "remaining_service_life_days",
        "parent": 1000,
        "name": "Remaining service life of filters in days",
        "name_orig": "Remaining service life of filters in days",
        "desc": "Remaining service life of filters in days",
        "type": "int",
        "min": 0,
        "max": 1000,
        "res": "1.0",
        "format": "%u",
        "unit": "days"
    },
    "remaining_service_life_pct": {
        "id": "remaining_service_life_pct",
        "parent": 1000,
        "name": "Remaining service life of filters in %",
        "name_orig": "Remaining service life of filters in %",
        "desc": "Remaining service life of filters in %",
        "type": "int",
        "min": 0,
        "max": 101,
        "res": "1.0",
        "format": "%u",
        "unit": "%"
    },
    "remaining_service_life_hrs": {
        "id": "remaining_service_life_hrs",
        "parent": 1000,
        "name": "Remaining service life of filters in operating hours",
        "name_orig": "Remaining service life of filters in operating hours",
        "desc": "Remaining service life of filters in operating hours",
        "type": "int",
        "min": 0,
        "max": 65535,
        "res": "1.0",
        "format": "%u",
        "unit": "h"
    },
    "filter_operating_time": {
        "id": "filter_operating_time",
        "parent": 1000,
        "name": "Filter Operating Time",
        "name_orig": "Filter Operating Time",
        "desc": "Filter Operating Time",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "s"
    },
    "comp_rps_pct": {
        "id": "comp_rps_pct",
        "parent": 3000,
        "name": "Compressor RPS pct",
        "name_orig": "Compressor RPS pct",
        "desc": "Compressor RPS pct",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.2f"
    },
    "comp_rps": {
        "id": "comp_rps",
        "parent": 3000,
        "name": "Compressor RPS",
        "name_orig": "Compressor RPS",
        "desc": "Compressor RPS",
        "type": "decimal",
        "min": 0,
        "max": 120,
        "res": "0.1",
        "format": "%.2f"
    },
    "t_ai": {
        "id": "t_ai",
        "parent": 4000,
        "name": "Temp. Air Inlet",
        "name_orig": "Temp. Air Inlet",
        "desc": "Temp. Air Inlet",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_ar": {
        "id": "t_ar",
        "parent": 4000,
        "name": "Temp. Air Room",
        "name_orig": "Temp. Air Room",
        "desc": "Temp. Air Room",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.001",
        "format": "%.3f",
        "unit": "°C"
    },
    "t_ae": {
        "id": "t_ae",
        "parent": 4000,
        "name": "Temp. Air Exhaust",
        "name_orig": "Temp. Air Exhaust",
        "desc": "Temp. Air Exhaust",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_ao": {
        "id": "t_ao",
        "parent": 4000,
        "name": "Temp. Air Outside",
        "name_orig": "Temp. Air Outside",
        "desc": "Temp. Air Outside",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_aop": {
        "id": "t_aop",
        "parent": 4000,
        "name": "Temp. Air Out of Passive heat exchanger",
        "name_orig": "Temp. Air Out of Passive heat exchanger",
        "desc": "Temp. Air Out of Passive heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_aio": {
        "id": "t_aio",
        "parent": 4000,
        "name": "Temp. Air Into Outside heat exchanger",
        "name_orig": "Temp. Air Into Outside heat exchanger",
        "desc": "Temp. Air Into Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_aii": {
        "id": "t_aii",
        "parent": 4000,
        "name": "Temp. Air Into Inside heat exchanger",
        "name_orig": "Temp. Air Into Inside heat exchanger",
        "desc": "Temp. Air Into Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_vto": {
        "id": "t_vto",
        "parent": 4000,
        "name": "Temp. Vapor Top Outside heat exchanger",
        "name_orig": "Temp. Vapor Top Outside heat exchanger",
        "desc": "Temp. Vapor Top Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_vti": {
        "id": "t_vti",
        "parent": 4000,
        "name": "Temp. Vapor Top Inside heat exchanger",
        "name_orig": "Temp. Vapor Top Inside heat exchanger",
        "desc": "Temp. Vapor Top Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_voc": {
        "id": "t_voc",
        "parent": 4000,
        "name": "Temp. Vapor Out of Compressor",
        "name_orig": "Temp. Vapor Out of Compressor",
        "desc": "Temp. Vapor Out of Compressor",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_lbo": {
        "id": "t_lbo",
        "parent": 4000,
        "name": "Temp. Liquid Bottom Outside heat exchanger",
        "name_orig": "Temp. Liquid Bottom Outside heat exchanger",
        "desc": "Temp. Liquid Bottom Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_lbi": {
        "id": "t_lbi",
        "parent": 4000,
        "name": "Temp. Liquid Bottom Inside heat exchanger",
        "name_orig": "Temp. Liquid Bottom Inside heat exchanger",
        "desc": "Temp. Liquid Bottom Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_lie": {
        "id": "t_lie",
        "parent": 4000,
        "name": "Temp. Liquid Into Expansion unit",
        "name_orig": "Temp. Liquid Into Expansion unit",
        "desc": "Temp. Liquid Into Expansion unit",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_loe": {
        "id": "t_loe",
        "parent": 4000,
        "name": "Temp. Liquid Out of Expansion unit",
        "name_orig": "Temp. Liquid Out of Expansion unit",
        "desc": "Temp. Liquid Out of Expansion unit",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_pci": {
        "id": "t_pci",
        "parent": 4000,
        "name": "Temp. Phase Change Inside heat exchanger",
        "name_orig": "Temp. Phase Change Inside heat exchanger",
        "desc": "Temp. Phase Change Inside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_pco": {
        "id": "t_pco",
        "parent": 4000,
        "name": "Temp. Phase Change Outside heat exchanger",
        "name_orig": "Temp. Phase Change Outside heat exchanger",
        "desc": "Temp. Phase Change Outside heat exchanger",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "t_vic": {
        "id": "t_vic",
        "parent": 4000,
        "name": "Temp. Vapor Into Compressor",
        "name_orig": "Temp. Vapor Into Compressor",
        "desc": "Temp. Vapor Into Compressor",
        "type": "decimal",
        "min": -40,
        "max": 110,
        "res": "0.01",
        "format": "%.2f",
        "unit": "°C"
    },
    "pt_vti": {
        "id": "pt_vti",
        "parent": 5000,
        "name": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "name_orig": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "desc": "Pressure Tranmitter Vapor Top Inside heat exchanger",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_lbi": {
        "id": "pt_lbi",
        "parent": 5000,
        "name": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "name_orig": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "desc": "Pressure Tranmitter Liquid Bottom Inside heat exchanger",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_vto": {
        "id": "pt_vto",
        "parent": 5000,
        "name": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "name_orig": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "desc": "Pressure Tranmitter Vapor Top Outside heat exchanger",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_lbo": {
        "id": "pt_lbo",
        "parent": 5000,
        "name": "PT_{LBO}",
        "name_orig": "PT_{LBO}",
        "desc": "PT_{LBO}",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "pt_vic": {
        "id": "pt_vic",
        "parent": 5000,
        "name": "Pressure Tranmitter Vapor Into Compressor",
        "name_orig": "Pressure Tranmitter Vapor Into Compressor",
        "desc": "Pressure Tranmitter Vapor Into Compressor",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.001",
        "format": "%.3f",
        "unit": "Bar"
    },
    "rh_ai": {
        "id": "rh_ai",
        "parent": 6000,
        "name": "Relative Humidity Air Inlet",
        "name_orig": "Relative Humidity Air Inlet",
        "desc": "Relative Humidity Air Inlet",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "rh_ar": {
        "id": "rh_ar",
        "parent": 6000,
        "name": "Relative Humidity Air Room",
        "name_orig": "Relative Humidity Air Room",
        "desc": "Relative Humidity Air Room",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "rh_ae": {
        "id": "rh_ae",
        "parent": 6000,
        "name": "Relative Humidity Air Exhaust",
        "name_orig": "Relative Humidity Air Exhaust",
        "desc": "Relative Humidity Air Exhaust",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "rh_ao": {
        "id": "rh_ao",
        "parent": 6000,
        "name": "Relative Humidity Air Outside",
        "name_orig": "Relative Humidity Air Outside",
        "desc": "Relative Humidity Air Outside",
        "type": "int",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%d",
        "unit": "%"
    },
    "co2_ai": {
        "id": "co2_ai",
        "parent": 6000,
        "name": "CO2 Air Inlet",
        "name_orig": "CO2 Air Inlet",
        "desc": "CO2 Air Inlet",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "co2_ar": {
        "id": "co2_ar",
        "parent": 6000,
        "name": "CO2 Air Room",
        "name_orig": "CO2 Air Room",
        "desc": "CO2 Air Room",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "co2_ae": {
        "id": "co2_ae",
        "parent": 6000,
        "name": "CO2 Air Exhaust",
        "name_orig": "CO2 Air Exhaust",
        "desc": "CO2 Air Exhaust",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "co2_ao": {
        "id": "co2_ao",
        "parent": 6000,
        "name": "CO2 Air Outside",
        "name_orig": "CO2 Air Outside",
        "desc": "CO2 Air Outside",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppm"
    },
    "tvoc_ai": {
        "id": "tvoc_ai",
        "parent": 6000,
        "name": "TVOC Air Inlet",
        "name_orig": "TVOC Air Inlet",
        "desc": "TVOC Air Inlet",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "tvoc_ar": {
        "id": "tvoc_ar",
        "parent": 6000,
        "name": "TVOC Air Room",
        "name_orig": "TVOC Air Room",
        "desc": "TVOC Air Room",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "tvoc_ae": {
        "id": "tvoc_ae",
        "parent": 6000,
        "name": "TVOC Air Exhaust",
        "name_orig": "TVOC Air Exhaust",
        "desc": "TVOC Air Exhaust",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "tvoc_ao": {
        "id": "tvoc_ao",
        "parent": 6000,
        "name": "TVOC Air Outside",
        "name_orig": "TVOC Air Outside",
        "desc": "TVOC Air Outside",
        "type": "int",
        "min": 0,
        "max": 60000,
        "res": "1.0",
        "format": "%d",
        "unit": "ppb"
    },
    "cds_lvl_sup_phe": {
        "id": "cds_lvl_sup_phe",
        "parent": 7000,
        "name": "Level SA Passive Heat Exchanger",
        "name_orig": "Level SA Passive Heat Exchanger",
        "desc": "Level SA Passive Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_lvl_sup_ahe": {
        "id": "cds_lvl_sup_ahe",
        "parent": 7000,
        "name": "Level SA Active Heat Exchanger",
        "name_orig": "Level SA Active Heat Exchanger",
        "desc": "Level SA Active Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_lvl_ext_phe": {
        "id": "cds_lvl_ext_phe",
        "parent": 7000,
        "name": "Level EA Passive Heat Exchanger",
        "name_orig": "Level EA Passive Heat Exchanger",
        "desc": "Level EA Passive Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_lvl_ext_ahe": {
        "id": "cds_lvl_ext_ahe",
        "parent": 7000,
        "name": "Level EA Active Heat Exchanger",
        "name_orig": "Level EA Active Heat Exchanger",
        "desc": "Level EA Active Heat Exchanger",
        "type": "enum",
        "min": "CDS_LVL_INVALID",
        "max": "CDS_LVL_HIGH",
        "res": "1.0",
        "format": "%d",
        "enum": "CDS_LVL"
    },
    "cds_valve_sup_phe": {
        "id": "cds_valve_sup_phe",
        "parent": 7000,
        "name": "Valve SA Passive Heat Exchanger",
        "name_orig": "Valve SA Passive Heat Exchanger",
        "desc": "Valve SA Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_valve_sup_ahe": {
        "id": "cds_valve_sup_ahe",
        "parent": 7000,
        "name": "Valve SA Active Heat Exchanger",
        "name_orig": "Valve SA Active Heat Exchanger",
        "desc": "Valve SA Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_valve_ext_phe": {
        "id": "cds_valve_ext_phe",
        "parent": 7000,
        "name": "Valve EA Passive Heat Exchanger",
        "name_orig": "Valve EA Passive Heat Exchanger",
        "desc": "Valve EA Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_valve_ext_ahe": {
        "id": "cds_valve_ext_ahe",
        "parent": 7000,
        "name": "Valve EA Active Heat Exchanger",
        "name_orig": "Valve EA Active Heat Exchanger",
        "desc": "Valve EA Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump": {
        "id": "cds_pump",
        "parent": 7000,
        "name": "Condensate Pump",
        "name_orig": "Condensate Pump",
        "desc": "Condensate Pump",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_sup_phe": {
        "id": "cds_pump_sup_phe",
        "parent": 7000,
        "name": "Pump Supply Passive Heat Exchanger",
        "name_orig": "Pump Supply Passive Heat Exchanger",
        "desc": "Pump Supply Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_sup_ahe": {
        "id": "cds_pump_sup_ahe",
        "parent": 7000,
        "name": "Pump Supply Active Heat Exchanger",
        "name_orig": "Pump Supply Active Heat Exchanger",
        "desc": "Pump Supply Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_ext_phe": {
        "id": "cds_pump_ext_phe",
        "parent": 7000,
        "name": "Pump Extraction Passive Heat Exchanger",
        "name_orig": "Pump Extraction Passive Heat Exchanger",
        "desc": "Pump Extraction Passive Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "cds_pump_ext_ahe": {
        "id": "cds_pump_ext_ahe",
        "parent": 7000,
        "name": "Pump Extraction Active Heat Exchanger",
        "name_orig": "Pump Extraction Active Heat Exchanger",
        "desc": "Pump Extraction Active Heat Exchanger",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "fan_sup_pm_1_u_rms": {
        "id": "fan_sup_pm_1_u_rms",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Urms",
        "name_orig": "Supply Fan, Power Meter #1, Urms",
        "desc": "Supply Fan, Power Meter #1, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "fan_sup_pm_1_i_rms": {
        "id": "fan_sup_pm_1_i_rms",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Irms",
        "name_orig": "Supply Fan, Power Meter #1, Irms",
        "desc": "Supply Fan, Power Meter #1, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "fan_sup_pm_1_p_act": {
        "id": "fan_sup_pm_1_p_act",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Power Actual",
        "name_orig": "Supply Fan, Power Meter #1, Power Actual",
        "desc": "Supply Fan, Power Meter #1, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "fan_sup_pm_1_e": {
        "id": "fan_sup_pm_1_e",
        "parent": 8000,
        "name": "Supply Fan, Power Meter #1, Energy",
        "name_orig": "Supply Fan, Power Meter #1, Energy",
        "desc": "Supply Fan, Power Meter #1, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "fan_ext_pm_1_u_rms": {
        "id": "fan_ext_pm_1_u_rms",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Urms",
        "name_orig": "Extraction Fan, Power Meter #1, Urms",
        "desc": "Extraction Fan, Power Meter #1, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "fan_ext_pm_1_i_rms": {
        "id": "fan_ext_pm_1_i_rms",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Irms",
        "name_orig": "Extraction Fan, Power Meter #1, Irms",
        "desc": "Extraction Fan, Power Meter #1, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "fan_ext_pm_1_p_act": {
        "id": "fan_ext_pm_1_p_act",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Power Actual",
        "name_orig": "Extraction Fan, Power Meter #1, Power Actual",
        "desc": "Extraction Fan, Power Meter #1, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "fan_ext_pm_1_e": {
        "id": "fan_ext_pm_1_e",
        "parent": 8000,
        "name": "Extraction Fan, Power Meter #1, Energy",
        "name_orig": "Extraction Fan, Power Meter #1, Energy",
        "desc": "Extraction Fan, Power Meter #1, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "cm_int_pm_u_rms": {
        "id": "cm_int_pm_u_rms",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Urms",
        "name_orig": "Control Module Internal, Power Meter, Urms",
        "desc": "Control Module Internal, Power Meter, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "cm_int_pm_i_rms": {
        "id": "cm_int_pm_i_rms",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Irms",
        "name_orig": "Control Module Internal, Power Meter, Irms",
        "desc": "Control Module Internal, Power Meter, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "cm_int_pm_p_act": {
        "id": "cm_int_pm_p_act",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Power Actual",
        "name_orig": "Control Module Internal, Power Meter, Power Actual",
        "desc": "Control Module Internal, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "cm_int_pm_e": {
        "id": "cm_int_pm_e",
        "parent": 8000,
        "name": "Control Module Internal, Power Meter, Energy",
        "name_orig": "Control Module Internal, Power Meter, Energy",
        "desc": "Control Module Internal, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "hpm_pm_u_rms": {
        "id": "hpm_pm_u_rms",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Urms",
        "name_orig": "Heat Pump Module, Power Meter, Urms",
        "desc": "Heat Pump Module, Power Meter, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "hpm_pm_i_rms": {
        "id": "hpm_pm_i_rms",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Irms",
        "name_orig": "Heat Pump Module, Power Meter, Irms",
        "desc": "Heat Pump Module, Power Meter, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "hpm_pm_p_act": {
        "id": "hpm_pm_p_act",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Power Actual",
        "name_orig": "Heat Pump Module, Power Meter, Power Actual",
        "desc": "Heat Pump Module, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "hpm_pm_e": {
        "id": "hpm_pm_e",
        "parent": 8000,
        "name": "Heat Pump Module, Power Meter, Energy",
        "name_orig": "Heat Pump Module, Power Meter, Energy",
        "desc": "Heat Pump Module, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "pm_pm_u_rms": {
        "id": "pm_pm_u_rms",
        "parent": 8000,
        "name": "Power Module, Power Meter, Urms",
        "name_orig": "Power Module, Power Meter, Urms",
        "desc": "Power Module, Power Meter, Urms",
        "type": "decimal",
        "min": 0,
        "max": 500,
        "res": "0.01",
        "format": "%.2f",
        "unit": "V"
    },
    "pm_pm_i_rms": {
        "id": "pm_pm_i_rms",
        "parent": 8000,
        "name": "Power Module, Power Meter, Irms",
        "name_orig": "Power Module, Power Meter, Irms",
        "desc": "Power Module, Power Meter, Irms",
        "type": "decimal",
        "min": 0,
        "max": 30,
        "res": "0.0001",
        "format": "%.4f",
        "unit": "A"
    },
    "pm_pm_p_act": {
        "id": "pm_pm_p_act",
        "parent": 8000,
        "name": "Power Module, Power Meter, Power Actual",
        "name_orig": "Power Module, Power Meter, Power Actual",
        "desc": "Power Module, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "pm_pm_e": {
        "id": "pm_pm_e",
        "parent": 8000,
        "name": "Power Module, Power Meter, Energy",
        "name_orig": "Power Module, Power Meter, Energy",
        "desc": "Power Module, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "eph_pm_p_act": {
        "id": "eph_pm_p_act",
        "parent": 8000,
        "name": "Electric Pre Heater, Power Meter, Power Actual",
        "name_orig": "Electric Pre Heater, Power Meter, Power Actual",
        "desc": "Electric Pre Heater, Power Meter, Power Actual",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "W"
    },
    "eph_pm_e": {
        "id": "eph_pm_e",
        "parent": 8000,
        "name": "Electric Pre Heater, Power Meter, Energy",
        "name_orig": "Electric Pre Heater, Power Meter, Energy",
        "desc": "Electric Pre Heater, Power Meter, Energy",
        "type": "int",
        "min": 0,
        "max": 4294967295,
        "res": "1.0",
        "format": "%u",
        "unit": "Wh"
    },
    "damper_main_sup_pos": {
        "id": "damper_main_sup_pos",
        "parent": 9000,
        "name": "Main Sup Damper Position",
        "name_orig": "Main Sup Damper Position",
        "desc": "Main Sup Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_main_ext_pos": {
        "id": "damper_main_ext_pos",
        "parent": 9000,
        "name": "Main Ext Damper Position",
        "name_orig": "Main Ext Damper Position",
        "desc": "Main Ext Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_bypass_pos": {
        "id": "damper_bypass_pos",
        "parent": 9000,
        "name": "Bypass Damper Position",
        "name_orig": "Bypass Damper Position",
        "desc": "Bypass Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_recirc_in_pos": {
        "id": "damper_recirc_in_pos",
        "parent": 9000,
        "name": "Recirculation Inside Damper Position",
        "name_orig": "Recirculation Inside Damper Position",
        "desc": "Recirculation Inside Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_recirc_out_pos": {
        "id": "damper_recirc_out_pos",
        "parent": 9000,
        "name": "Recirculation Outside Damper Position",
        "name_orig": "Recirculation Outside Damper Position",
        "desc": "Recirculation Outside Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "damper_inlet_pos": {
        "id": "damper_inlet_pos",
        "parent": 9000,
        "name": "Inlet Damper Position",
        "name_orig": "Inlet Damper Position",
        "desc": "Inlet Damper Position",
        "type": "decimal",
        "min": -1,
        "max": 100,
        "res": "0.01",
        "format": "%.2f",
        "unit": "%"
    },
    "fan_sup_1_pwr": {
        "id": "fan_sup_1_pwr",
        "parent": 10000,
        "name": "Supply Fan #1 Power",
        "name_orig": "Supply Fan #1 Power",
        "desc": "Supply Fan #1 Power",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "fan_sup_1_pct": {
        "id": "fan_sup_1_pct",
        "parent": 10000,
        "name": "Supply Fan #1 Control",
        "name_orig": "Supply Fan #1 Control",
        "desc": "Supply Fan #1 Control",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "fan_sup_1_rpm": {
        "id": "fan_sup_1_rpm",
        "parent": 10000,
        "name": "Supply Fan #1 Speed",
        "name_orig": "Supply Fan #1 Speed",
        "desc": "Supply Fan #1 Speed",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%u",
        "unit": "rpm"
    },
    "fan_ext_1_pwr": {
        "id": "fan_ext_1_pwr",
        "parent": 10000,
        "name": "Extraction Fan #1 Power",
        "name_orig": "Extraction Fan #1 Power",
        "desc": "Extraction Fan #1 Power",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "fan_ext_1_pct": {
        "id": "fan_ext_1_pct",
        "parent": 10000,
        "name": "Extraction Fan #1 Control",
        "name_orig": "Extraction Fan #1 Control",
        "desc": "Extraction Fan #1 Control",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.1",
        "format": "%.1f",
        "unit": "%"
    },
    "fan_ext_1_rpm": {
        "id": "fan_ext_1_rpm",
        "parent": 10000,
        "name": "Extraction Fan #1 Speed",
        "name_orig": "Extraction Fan #1 Speed",
        "desc": "Extraction Fan #1 Speed",
        "type": "int",
        "min": 0,
        "max": 10000,
        "res": "1.0",
        "format": "%u",
        "unit": "rpm"
    },
    "mov_sensor_active": {
        "id": "mov_sensor_active",
        "parent": 11000,
        "name": "Movement Sensor Active",
        "name_orig": "Movement Sensor Active",
        "desc": "Movement Sensor Active",
        "type": "bool",
        "min": 0,
        "max": 0,
        "res": "1.0",
        "format": "%u"
    },
    "eev_pct": {
        "id": "eev_pct",
        "parent": 12000,
        "name": "EEV Step Percent",
        "name_orig": "EEV Step Percent",
        "desc": "EEV Step Percent",
        "type": "decimal",
        "min": 0,
        "max": 100,
        "res": "0.01",
        "format": "%.1f",
        "unit": "%"
    },
    "fan_sup_flow": {
        "id": "fan_sup_flow",
        "parent": 13000,
        "name": "Supply Fan Flow",
        "name_orig": "Supply Fan Flow",
        "desc": "Supply Fan Flow",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "m3/h"
    },
    "fan_ext_flow": {
        "id": "fan_ext_flow",
        "parent": 13000,
        "name": "Extraction Fan Flow",
        "name_orig": "Extraction Fan Flow",
        "desc": "Extraction Fan Flow",
        "type": "decimal",
        "min": 0,
        "max": 10000,
        "res": "0.1",
        "format": "%.1f",
        "unit": "m3/h"
    }
}
export const awareTelemNames = {
    "udf": "udf",
    "rqf": "rqf",
    "udt": "udt",
    "rqt": "rqt",
    "trt": "trt",
    "started_by": "started_by",
    "op_mode": "op_mode",
    "ech_1_pct": "ech_1_pct",
    "ech_2_pct": "ech_2_pct",
    "ech_3_pct": "ech_3_pct",
    "cc_pct": "cc_pct",
    "hp_pct": "hp_pct",
    "hvac_pct": "hvac_pct",
    "four_w_valve": "four_w_valve",
    "htp": "htp",
    "hta": "hta",
    "ltp": "ltp",
    "ltala": "ltala",
    "hsa": "hsa",
    "ncp": "ncp",
    "eth0_IP": "eth0_IP",
    "wlan0_IP": "wlan0_IP",
    "lt_offset": "lt_offset",
    "globalErr": "globalErr",
    "comErr": "comErr",
    "globalAlarms": "globalAlarms",
    "globalWarnings": "globalWarnings",
    "sys_time": "sys_time",
    "delta_t_evap": "delta_t_evap",
    "msm_state": "msm_state",
    "rc_state": "rc_state",
    "defrost_state": "defrost_state",
    "freconv_state": "freconv_state",
    "remaining_service_life_days": "remaining_service_life_days",
    "remaining_service_life_pct": "remaining_service_life_pct",
    "remaining_service_life_hrs": "remaining_service_life_hrs",
    "filter_operating_time": "filter_operating_time",
    "comp_rps_pct": "comp_rps_pct",
    "comp_rps": "comp_rps",
    "t_ai": "t_ai",
    "t_ar": "t_ar",
    "t_ae": "t_ae",
    "t_ao": "t_ao",
    "t_aop": "t_aop",
    "t_aio": "t_aio",
    "t_aii": "t_aii",
    "t_vto": "t_vto",
    "t_vti": "t_vti",
    "t_voc": "t_voc",
    "t_lbo": "t_lbo",
    "t_lbi": "t_lbi",
    "t_lie": "t_lie",
    "t_loe": "t_loe",
    "t_pci": "t_pci",
    "t_pco": "t_pco",
    "t_vic": "t_vic",
    "pt_vti": "pt_vti",
    "pt_lbi": "pt_lbi",
    "pt_vto": "pt_vto",
    "pt_lbo": "pt_lbo",
    "pt_vic": "pt_vic",
    "rh_ai": "rh_ai",
    "rh_ar": "rh_ar",
    "rh_ae": "rh_ae",
    "rh_ao": "rh_ao",
    "co2_ai": "co2_ai",
    "co2_ar": "co2_ar",
    "co2_ae": "co2_ae",
    "co2_ao": "co2_ao",
    "tvoc_ai": "tvoc_ai",
    "tvoc_ar": "tvoc_ar",
    "tvoc_ae": "tvoc_ae",
    "tvoc_ao": "tvoc_ao",
    "cds_lvl_sup_phe": "cds_lvl_sup_phe",
    "cds_lvl_sup_ahe": "cds_lvl_sup_ahe",
    "cds_lvl_ext_phe": "cds_lvl_ext_phe",
    "cds_lvl_ext_ahe": "cds_lvl_ext_ahe",
    "cds_valve_sup_phe": "cds_valve_sup_phe",
    "cds_valve_sup_ahe": "cds_valve_sup_ahe",
    "cds_valve_ext_phe": "cds_valve_ext_phe",
    "cds_valve_ext_ahe": "cds_valve_ext_ahe",
    "cds_pump": "cds_pump",
    "cds_pump_sup_phe": "cds_pump_sup_phe",
    "cds_pump_sup_ahe": "cds_pump_sup_ahe",
    "cds_pump_ext_phe": "cds_pump_ext_phe",
    "cds_pump_ext_ahe": "cds_pump_ext_ahe",
    "fan_sup_pm_1_u_rms": "fan_sup_pm_1_u_rms",
    "fan_sup_pm_1_i_rms": "fan_sup_pm_1_i_rms",
    "fan_sup_pm_1_p_act": "fan_sup_pm_1_p_act",
    "fan_sup_pm_1_e": "fan_sup_pm_1_e",
    "fan_ext_pm_1_u_rms": "fan_ext_pm_1_u_rms",
    "fan_ext_pm_1_i_rms": "fan_ext_pm_1_i_rms",
    "fan_ext_pm_1_p_act": "fan_ext_pm_1_p_act",
    "fan_ext_pm_1_e": "fan_ext_pm_1_e",
    "cm_int_pm_u_rms": "cm_int_pm_u_rms",
    "cm_int_pm_i_rms": "cm_int_pm_i_rms",
    "cm_int_pm_p_act": "cm_int_pm_p_act",
    "cm_int_pm_e": "cm_int_pm_e",
    "hpm_pm_u_rms": "hpm_pm_u_rms",
    "hpm_pm_i_rms": "hpm_pm_i_rms",
    "hpm_pm_p_act": "hpm_pm_p_act",
    "hpm_pm_e": "hpm_pm_e",
    "pm_pm_u_rms": "pm_pm_u_rms",
    "pm_pm_i_rms": "pm_pm_i_rms",
    "pm_pm_p_act": "pm_pm_p_act",
    "pm_pm_e": "pm_pm_e",
    "eph_pm_p_act": "eph_pm_p_act",
    "eph_pm_e": "eph_pm_e",
    "damper_main_sup_pos": "damper_main_sup_pos",
    "damper_main_ext_pos": "damper_main_ext_pos",
    "damper_bypass_pos": "damper_bypass_pos",
    "damper_recirc_in_pos": "damper_recirc_in_pos",
    "damper_recirc_out_pos": "damper_recirc_out_pos",
    "damper_inlet_pos": "damper_inlet_pos",
    "fan_sup_1_pwr": "fan_sup_1_pwr",
    "fan_sup_1_pct": "fan_sup_1_pct",
    "fan_sup_1_rpm": "fan_sup_1_rpm",
    "fan_ext_1_pwr": "fan_ext_1_pwr",
    "fan_ext_1_pct": "fan_ext_1_pct",
    "fan_ext_1_rpm": "fan_ext_1_rpm",
    "mov_sensor_active": "mov_sensor_active",
    "eev_pct": "eev_pct",
    "fan_sup_flow": "fan_sup_flow",
    "fan_ext_flow": "fan_ext_flow"
}