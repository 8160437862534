import {awareTelemNames} from "../components/parameters/awareTelemSpec.js";
import {awareParamSpec} from "../components/parameters/awareParamSpec.js";
import {optionalValidationTypes} from "./constants.js";
import {getParameterToShow} from "./settingsHelper.js";
import {objectEquals} from "./objectEquals.js";
import {cloneDeep} from "lodash";

/** @param {object} device device to get parameter from
 * @param {TFunction<string[], undefined>} t translation service
 * @returns {*}
 */
export const getAirFlow = (device, t) => {
    const messages = []
    const reportedUdf = device?.latest_telemetry?.[awareTelemNames.udf]

    if (!reportedUdf) {
        messages.push({type: "danger", text: "No current value for air flow (UDF)"})
        console.log(`%c=== DEV ERROR? udf does not exist in latest_telemetry`, "color:red;")
        console.log(device?.latest_telemetry)
    }

    const desiredUdf = device.device_twin.desired.parameters[awareTelemNames.udf]

    if (desiredUdf && desiredUdf !== reportedUdf) {
        messages.push({type: "warning", text: t('settingspage:setpoints_settings.messages.waiting_for_update')})
    }
    return {
        value: desiredUdf ? desiredUdf : reportedUdf,
        messages: messages
    }
}

export const getReboot = (device, t) => {
    const messages = []
    const desiredReboot = device.device_twin.desired.reboot

    if (desiredReboot) {
        messages.push({type: "warning", text: t('settingspage:setpoints_settings.messages.waiting_for_update')})
    }
    return {
        value: desiredReboot ? desiredReboot : false,
        messages: messages
    }
}

export function updateFieldIfChanged(setStateFunc, oldDevice, updatedDevice, paramKey, t) {
    if (paramKey === awareTelemNames.udf) {
        let oldObj = getAirFlow(oldDevice, t)
        let newObj = getAirFlow(updatedDevice, t)
        if (objectEquals(oldObj, newObj)) return;
        setStateFunc(() => newObj)
    } else if (paramKey === "reboot") {
        let oldObj = getReboot(oldDevice, t)
        let newObj = getReboot(updatedDevice, t)
        if (objectEquals(oldObj, newObj)) return;
        setStateFunc(() => newObj)
    } else {
        let oldObj = getParameterToShow(oldDevice, paramKey, t)
        let newObj = getParameterToShow(updatedDevice, paramKey, t)
        if (objectEquals(oldObj, newObj))
            return;
        setStateFunc(() => getParameterToShow(updatedDevice, paramKey, t))
    }
}

/**
 * Validate a given parameter. calls setModel to update model. All validation for a single model basically have to be
 * done in one method, because setModel called after each other overwrites. So I send in an array of optional validation
 * objects, which can contain anything. It is ugly, and I am very open to suggestions.
 *
 * @param {int} paramId parameter id
 * @param {object} model format: {value:any, messages:[{type:string, id: string, text: string}]}
 * @param {function(object)} setModel function to update model
 * @param {TFunction<string[], undefined>} t translation function
 * @param {object[]} optionalValidation format: [{type:string, ...}]
 * @returns {boolean} whether validated correct or not
 */
export function validateParameter(paramId, model, setModel, t, optionalValidation = []) {
    let validated = true
    const spec = awareParamSpec[paramId]
    const modelCopy = cloneDeep(model)
    modelCopy.messages = model.messages ? model.messages.filter(m => m.type !== "validation") : []

    // === Single model type specific validations
    if (spec.type === "string") return true
    if (spec.type === "bool") return true
    if (spec.type === "enum") return true
    if (spec.type === "int" || spec.type === "decimal") {
        const floatVal = parseFloat(model.value)
        if (isNaN(floatVal)) { // Is NaN if model.value was empty string
            if (!modelCopy.messages.find(m => m.id === "validation_error_empty_value"))
                modelCopy.messages.push({
                    type: "error",
                    id: "validation_error_empty_value",
                    text: t('settingspage:validation.value_cannot_be_empty')
                })
            validated = false
        } else {
            if (floatVal < spec.min) {
                if (!modelCopy.messages.find(m => m.id === "validation_error_more_than"))
                    modelCopy.messages.push({
                        type: "error",
                        id: "validation_error_more_than",
                        text: t('settingspage:validation.must_be_more_than_min', {min: spec.min})
                    })
                validated = false
            }
            if (floatVal > spec.max) {
                if (!modelCopy.messages.find(m => m.id === "validation_error_less_than"))
                    modelCopy.messages.push({
                        type: "error",
                        id: "validation_error_less_than",
                        text: t('settingspage:validation.must_be_more_than_max', {max: spec.max})
                    })
                validated = false
            }
        }
    } else {
        console.warn(`parameter of type ${spec.type} has no validation`)
    }

    // === Ekstra optional validations
    for (const o of optionalValidation) {
        if (o.type === optionalValidationTypes.leftLessThanRight && parseFloat(model.value) > parseFloat(o.rightModel.value)) {
            if (!modelCopy.messages.find(m => m.id === "validation_error_left_more_than_right"))
                modelCopy.messages.push({
                    type: "error", id: "validation_error_more_less_than_right",
                    text: t('settingspage:validation.left_cannot_be_more_than_right', {
                        left: model.value,
                        right: o.rightModel.value
                    })
                })
            validated = false
        }
    }

    setModel(modelCopy)
    return validated
}

export function addParameterIfChanged(updatedParametersObj, paramId, newValue, device) {
    const spec = awareParamSpec[paramId]
    if (device.device_twin.desired.parameters && device.device_twin.desired.parameters[paramId] && parseFloat(device.device_twin.desired.parameters[paramId]) === parseFloat(newValue)) {
        // if desired exists and is same, this change is already queued
        return
    }

    // noinspection EqualityComparisonWithCoercionJS // implicit cast when equality checking is fine here
    if (device.device_twin.reported.parameters[paramId] != newValue ||
        (device.device_twin.desired.parameters && device.device_twin.desired.parameters[paramId] && parseFloat(device.device_twin.desired.parameters[paramId]) !== parseFloat(newValue))) {
        console.log(`has changed {paramKey: ${paramId}, oldReported: ${device.device_twin.reported.parameters[paramId]}, oldDesired: ${device.device_twin.desired.parameters[paramId]}, new: ${newValue}}`)

        if (spec.type === "enum" || spec.type === "int" || spec.type === "decimal") {
            updatedParametersObj[paramId] = parseFloat(newValue)
        } else {
            updatedParametersObj[paramId] = newValue
        }
    }
}