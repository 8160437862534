import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import GroupsFilterSelector from '../../../../components/shared/groupsfilterselector/GroupsFilterSelector.js'
import InformationModal from '../../../../components/shared/informationmodal/InformationModal'
import ComboBox from '../../../../components/shared/combobox/ComboBox'
import {syslang} from '../../../../helpers/constants'
import {getRoleIdFromRoleText} from '../../../../helpers/getRoleIdFromRoleText.js'
import {getAssignableSystemRoles} from '../../../../helpers/helperfunctions'
import ModalTopText from '../../../../components/shared/modaltoptext/ModalTopText'
import PopOverDialog from '../../../../components/shared/popoverdialog/PopOverDialog'

/** Modal with input fields to edit an existing user. Allows to specify new groups and remove existing.
 * @param {function} setVisibility parent function to control modal visibility
 * @param {function} editUser parent function to handle submit
 * @param {boolean} show property from parent to control visiblity of modal
 * @param {list} userData Data to pass to pre-select groups that the user has already been granted access to
 * @param {string} userRole role of the user subject to editing
 * @param {string} language system language for the user subject to editing
 * @param {[string]} editorRoles Roles of the user performing the editing */
export default function EditUserModal({setVisibility, editUser, show, userData, userRole, language = "", editorRole}) {
    const [selectedgroups, setSelectedGroups] = useState([])
    const [data, setData] = useState([])
    const [preferredLanguage, setPreferredLanguage] = useState("")
    const [roleId, setRoleId] = useState("")
    const [groupsError, setGroupsError] = useState(false)
    const [roleError, setRoleError] = useState(false)
    const {t} = useTranslation(['common', 'userpage'])

    useEffect(() => {
        if (userRole) setRoleId(getRoleIdFromRoleText(userRole))
        setPreferredLanguage(language)
        setRoleError(false)
        setGroupsError(false)
        setData(userData)
    }, [show, userRole, userData, language])

    const selectItem = (selected) => {
        setSelectedGroups(selected)
        if ((selected.length === 0 && roleId !== process.env.REACT_APP_APP_ADMIN) || selected.length > 0) setGroupsError(false)
    }
    const defineRole = (e) => {
        setRoleError(!e)
        setRoleId(e)
        if (e === process.env.REACT_APP_APP_ADMIN) setGroupsError(false)
    }
    const defineLanguage = (e) => {
        setPreferredLanguage(e)
    }
    const acceptEdits = () => {
        if (!roleError) {
            if (roleId === process.env.REACT_APP_APP_ADMIN || (selectedgroups.length > 0 && roleId !== process.env.REACT_APP_APP_ADMIN)) {
                editUser(selectedgroups, roleId, preferredLanguage)
            } else {
                setGroupsError(true)
            }
        }
    }
    return (
        <InformationModal dialogClassName="group-selector-modal" show={show}
                          title={t('userpage:user_management.edit_user_modal.modal_title')}
                          body={
                              <React.Fragment>
                                  <ModalTopText body={t('userpage:user_management.edit_user_modal.body')}/>
                                  <div className="mt-4">
                                      <div className="pe-2 col-sm-6 pe-xs-0 pb-xs-2 col-xs-12 d-inline-block">
                                          <p className="d-inline-block mb-2">{t('userpage:user_management.create_new_user_modal.inputs.labels.role')}</p>
                                          <PopOverDialog
                                              popOverTitel={t('userpage:user_management.edit_user_modal.popover.header')}
                                              popOverContent={t('userpage:user_management.edit_user_modal.popover.body')}
                                              contentAsHtml={true}
                                              className="d-inline-block"/>
                                          <ComboBox inputValue={(e) => defineRole(e.target.value)}
                                                    errorText="Please select"
                                                    isInvalid={roleError} name="role" value={roleId}
                                                    options={getAssignableSystemRoles(editorRole)}
                                                    blankChoice={t('userpage:user_management.create_new_user_modal.inputs.placeholders.role')}
                                                    hasLabel={false}/>
                                      </div>
                                      <ComboBox className="ps-2 col-sm-6 col-xs-12 ps-xs-0 d-inline-block"
                                                inputValue={(e) => defineLanguage(e.target.value)}
                                                errorText="Please select" name="preferredLanguage"
                                                value={preferredLanguage} options={syslang.map(l => ({
                                          id: l.id,
                                          option: t(`common:lng.${l.id}`)
                                      }))}
                                                label={t('userpage:user_management.create_new_user_modal.inputs.labels.lang')}
                                                blankChoice={t('userpage:user_management.create_new_user_modal.inputs.placeholders.lang')}/>
                                  </div>
                                  <div
                                      className={`transition-all admin-selected ${(roleId === process.env.REACT_APP_APP_ADMIN) ? 'fade-in' : 'fade-out'}`}>
                                      <div className="p-2" role="alert">
                                          {t('userpage:user_management.create_new_user_modal.admin_alert')}
                                      </div>
                                  </div>
                                  <GroupsFilterSelector selectItem={(items) => selectItem(items)} hasError={groupsError}
                                                        selectedItems={data}
                                                        dropzoneBody={t('userpage:groupsselector.dropzone_body')}/>
                              </React.Fragment>
                          } size="lg"
                          accept={() => acceptEdits()}
                          dismiss={() => setVisibility()}/>
    )
}
