import React from 'react'
import Form from 'react-bootstrap/Form'
import {isEqual, uniqWith} from "lodash";
import {getBorderErrorOrWarningClassFromMessage} from "../../../helpers/uiHelper.js";

/** Renders an input group with an input control and corresponding Feedback for handling invalid inputs
 * @param {string} className classes to pass to the Form.Group
 * @param {label} label to display title above the input box
 * @param {string} type to decide the type of the input (e.g. text, password, email, number)
 * @param {number} leftValue
 * @param {function} leftOnChange
 * @param {string} leftPlaceholder
 * @param {array[{type: '', text: ''}]} leftMessages
 * @param {number} rightValue
 * @param {function} rightOnChange
 * @param {string} rightPlaceholder
 * @param {array[{type: '', text: ''}]} rightMessages
 * @param {boolean} disabled
 * @param {string} unit which goes at end of each inputfield
 * @param {JSX.Element} infoelement
 */
export default function RangeGroup({
                                       className, label, type, leftValue, leftOnChange,
                                       leftPlaceholder, unit = "", leftMessages = [], rightValue,
                                       rightOnChange, rightPlaceholder, rightMessages = [],
                                       disabled = false, infoelement
                                   }) {
    const getUniquesArray = (arr) => {
        return uniqWith(arr, isEqual)
    }

    return (
        <Form.Group className={className}>
            {label &&
                <div className='mb-2 position-relative'><span
                    dangerouslySetInnerHTML={{__html: label}}></span>{infoelement && infoelement}</div>
            }
            <div className="d-flex align-items-center">
                <div className='d-flex align-items-center'>
                    <Form.Control disabled={disabled} max={rightValue}
                                  className={`shadow-none remove-autofill-overlay ${getBorderErrorOrWarningClassFromMessage(leftMessages)}`}
                                  type={type} value={leftValue} onChange={leftOnChange} placeholder={leftPlaceholder}/>
                    {unit && <span className='ms-1'>{unit}</span>}
                </div>
                <span className='mx-2'>-</span>
                <div className='d-flex align-items-center'>
                    <Form.Control disabled={disabled} min={leftValue}
                                  className={`shadow-none remove-autofill-overlay ${getBorderErrorOrWarningClassFromMessage(rightMessages)}`}
                                  type={type} value={rightValue} onChange={rightOnChange}
                                  placeholder={rightPlaceholder}/>
                    {unit && <span className='ms-1'>{unit}</span>}
                </div>
            </div>

            {/*If some messages are the same, just show one of them*/}
            {getUniquesArray(leftMessages.concat(rightMessages)).map((object, i) =>
                <div className={`color-${object.type}`} key={i}>{object.text}</div>)}
        </Form.Group>
    )
}