/** Validate periods are not overlapping
 * tested by ValidateMonthDayNoOverlap.test.js
 * @param {object} period1
 * @param {object} period2
 * @returns {boolean} Returns true if validation success. false if overlap
 */
export function validateMonthDayNoOverlap(period1, period2) {
    if (period1.from_month_day > period1.to_month_day) { // period1 wraps
        if (period2.from_month_day > period2.to_month_day) { // period2 wraps
            return false // if both wrap there will always be some part overlap
        } else { // period2 does not wrap
            return period1.from_month_day > period2.to_month_day && period1.to_month_day < period2.from_month_day
        }
    } else { // period1 does not wrap
        if (period2.from_month_day > period2.to_month_day) { // period2 wraps
            return period1.from_month_day > period2.to_month_day && period1.to_month_day < period2.from_month_day
        } else { // period2 does not wrap
            if (period1.from_month_day < period2.from_month_day) { // period1 before period2
                return period1.to_month_day < period2.from_month_day
            } else { // period1 after period2
                return period1.from_month_day > period2.to_month_day
            }
        }
    }
}