import React from 'react'
import {ExclamationTriangle} from 'react-bootstrap-icons'

/** Renders a small box which displays the temperature for a given value (displays a small triange if an error is present)
 * @param {boolean} hasError if the sensor which is responsible for collecting the temperature has an error
 * @param {string} value temperature value to display
 * @param {string} title title for the label
 * @param {string} unit unit
 * @param {JSX.Element?} extractsupply
 * @param {string} color color */
export default function DeviceStatusAirflowTemperatureBox({hasError, value, title, unit = "°C", color= '', extractsupply = null}) {
    return (
        <div className='d-flex flex-column align-items-center'>
            {hasError && <ExclamationTriangle className='text-danger ms-1' style={{marginBottom: "3px"}}/>}
            {!hasError && <>
                <div className='font-weight-500 airflow-font-size'
                     style={{marginBottom: "-8px", color: color}}>{value} {unit}</div>
                {extractsupply && extractsupply}
                <div style={{fontSize: '15px'}}>{title}</div>
            </>}
        </div>
    )
}