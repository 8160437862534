import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ComboBoxWithMessages from "../../../components/shared/comboboxwithmessages/ComboBoxWithMessages.js";
import {getMonitorRequestList, getMonitorTracesList} from "../../../api/api.js";
import {Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {formatDateTimeString} from "../../../helpers/helperfunctions.js";
import {LoadSpinner} from "../../../components/shared/loader/LoadSpinner";

export default function AdminOperationsAudit() {
    const {administeredUsers} = useSelector(state => state.userAdministration)
    const [selectedUserMail, setSelectedUserMail] = useState("all")
    const [selectedDaysBack, setSelectedDaysBack] = useState(0)
    const [selectedOperationName, setSelectedOperationName] = useState("all")
    const [selectedStatus, setSelectedStatus] = useState("all")
    const [requestList, setRequestList] = useState([])
    const [loadingRequestList, setLoadingRequestList] = useState(true)
    const [selectedRequestId, setSelectedRequestId] = useState(null)
    const [tracesList, setTracesList] = useState([])
    const [summary, setSummary] = useState("")

    useEffect(() => {
        setLoadingRequestList(true)
        getMonitorRequestList(selectedUserMail, selectedStatus, selectedDaysBack, selectedOperationName).then((response) => {
            setRequestList(response.requestList)
            setSelectedRequestId(response.requestList[0] ? response.requestList[0].id : null)
            setLoadingRequestList(false)
        })
    }, [administeredUsers, selectedUserMail, selectedStatus, selectedDaysBack, selectedOperationName])

    useEffect(() => {
        if (selectedRequestId) {
            getMonitorTracesList(selectedRequestId).then((res) => {
                setTracesList(res.traceList)
                let summaryTrace = res.traceList.find(t => t.message.startsWith("SUM:"));
                setSummary(summaryTrace ? summaryTrace.message.slice(5) : "") // Cut off "SUM: "
            })
        } else {
            setTracesList([])
        }
    }, [selectedRequestId])

    return <div className='ms-3'>
        <p className='mb-1'>Audit tab indeholder interessante brugerhandlinger (dvs. som har ændret et eller andet).</p>
        <p className='mb-1'>Lige nu taget direkte fra Application Insights, så det er meget teknisk og måske ikke så
            brugbart, da den logning vi har lavet ikke rigtig er beregnet til at blive læst af ikke tekniske brugere (og
            vi har ikke tænkt så meget over det heller) men det er en start. Der kunne godt være en opgave i at logge
            mere brugbart fra et support perspektiv og generelt rydde op i loggen, så der er ikke er så meget støj.</p>
        <p><b>Der kan gå op til 5 minutter før logs bliver skrevet til Application Insights.</b></p>

        <div className='d-flex mt-2'>
            <ComboBoxWithMessages style={{maxWidth: '350px'}} className=''
                                  inputValue={e => setSelectedUserMail(e.target.value)} name='selectedUserId'
                                  value={selectedUserMail}
                                  options={[{
                                      id: "all",
                                      option: "Alle brugere"
                                  }, ...administeredUsers.filter(u => u.userData.mail).map(u => ({
                                      id: u.userData.mail,
                                      option: u.userData.mail
                                  }))]}/>
            <ComboBoxWithMessages className='ms-2' inputValue={e => setSelectedStatus(e.target.value)}
                                  name='selectedStatus' value={selectedStatus}
                                  options={[
                                      {id: "all", option: "Alle status"},
                                      {id: "success", option: "Success"},
                                      {id: "failure", option: "Fejl"}
                                  ]}/>
            <ComboBoxWithMessages style={{maxWidth: '350px'}} className='ms-2'
                                  inputValue={e => setSelectedDaysBack(e.target.value)} name='selectedDaysBack'
                                  value={selectedDaysBack}
                                  options={[{id: 0, option: "I dag"}, {id: 1, option: "1 dag"},
                                      {id: 3, option: "3 dage"}, {id: 7, option: "7 dage"},
                                      {id: 14, option: "14 dage"}, {id: 30, option: "30 dage"}]}/>
            <ComboBoxWithMessages style={{maxWidth: '350px'}} className='ms-2'
                                  inputValue={e => setSelectedOperationName(e.target.value)}
                                  name='selectedOperationName'
                                  value={selectedOperationName}
                                  options={[{
                                      id: "all",
                                      option: "Alle operationer"
                                  }, ...getOperationOptionsList().map(e => ({id: e, option: e}))]}/>
        </div>

        {!loadingRequestList && <div className='mt-2'>Antal: {requestList.length}</div>}

        <div className={'mt-2 mb-2 d-flex'}>
            {loadingRequestList && <LoadSpinner/>}
            {!loadingRequestList && <>
                <TableContainer className='pe-0 ps-0 me-2' sx={{flex: '1'}}>
                    <Table stickyHeader aria-label="simple table">
                        <TableBody>
                            {requestList.map(row => (
                                <TableRow hover={true} selected={row.id === selectedRequestId} key={row.id}
                                          sx={{
                                              backgroundColor: 'white',
                                              "td": {
                                                  borderBottom: "1px solid #d9d9d9",
                                                  borderTop: "1px solid #d9d9d9",
                                                  borderLeft: "1px solid #d9d9d9"
                                              },
                                              "td:hover": {
                                                  cursor: 'pointer'
                                              },
                                              "td:last-child": {
                                                  borderRight: "1px solid #d9d9d9"
                                              }
                                          }}
                                          onClick={() => setSelectedRequestId(row.id)}
                                >
                                    <TableCell align="left" className={'d-table-cell p-2'}>
                                        <div className='mb-1'>{row.userEmail} - {row.name}</div>
                                        <div className='d-flex'>
                                            <div>{formatDateTimeString(row.timestamp, true, true, true)}</div>
                                            <div className='mx-2'>-</div>
                                            <div>{Math.floor(row.duration.split(".")[0])} ms</div>
                                            <div className='mx-2'>-</div>
                                            <div>{row.success ?
                                                <div className='color-telemetry-very-good'>Success</div> :
                                                <div className='color-telemetry-very-bad'>Fejl</div>}
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {tracesList.length !== 0 && !loadingRequestList &&
                    <div style={{flex: '1', maxWidth: '60%', border: '1px solid #d9d9d9', background: 'white'}}
                         className='p-2'>
                        {summary && <>
                            <p className='mb-1 text-bold'>Summary</p>
                            <div className='ms-2'>{summary}</div>
                        </>}
                        <p className='mt-2 mb-1 text-bold'>Log</p>
                        {tracesList.map(t => <div className='ms-2' key={t.timestamp}>{severityToText(t.severity)}
                            <tt
                                className='ms-1'>{t.timestamp}</tt> - {t.message}</div>)}
                    </div>
                }

            </>}

        </div>
    </div>
}

function severityToText(severity) {
    if (severity === "1") {
        return <span className='color-airmaster-title-blue'>INFO</span>
    } else if (severity === "2") {
        return <span className='color-warning'>WARN</span>
    }
    return <span className='color-danger'>ERROR</span>
}

// function getOperationOptionsList() {
//     return [
//         {"DeviceConfiguration - Configure Device": "PUT DeviceConfiguration/DeviceConfigurationConfigure"},
//         {"DeviceConfiguration - Upsert Timer Period": "PUT DeviceConfiguration/DeviceconfigurationTimerperiods"},
//         {"ProjectManagement - Edit Device": "PUT ProjectManagement/ProjectManagementDeviceEdit"},
//         {"ProjectManagement - Create Group": "POST ProjectManagement/ProjectManagementGroupCreate"},
//         {"ProjectManagement - Edit Group": "PUT ProjectManagement/ProjectManagementGroupEdit"},
//         {"ProjectManagement - Delete Group": "DELETE ProjectManagement/ProjectManagementGroupDelete"},
//         {"ProjectManagement - Delete Device": "DELETE ProjectManagement/ProjectManagementDeviceDelete"},
//         {"UserManagement - Invite User": "POST UserManagement/UserManagementAdministeredUserCreateUser"},
//         {"UserManagement - Resend Invitation": "POST UserManagement/UserManagementAdministeredUserInvite"},
//         {"UserManagement - Edit Administered User": "PUT UserManagement/UserManagementAdministeredUserEdit"},
//         {"UserManagement - Delete Administered User": "DELETE UserManagement/UserManagementAdministeredUserDeleteUser"},
//         {"UserManagement - Edit Self User": "PUT UserManagement/UserManagementUserEdit"},
//         {"Notification - Upsert Notification": "PUT Notification/PutNotification"},
//         {"Firmware - Configure Device Firmware": "PUT DeviceConfiguration/DeviceConfigurationConfigureFirmware"},
//     ]
// }

function getOperationOptionsList() {
    return [
        "PUT DeviceConfiguration/DeviceConfigurationConfigure",
        "PUT DeviceConfiguration/DeviceconfigurationTimerperiods",
        "PUT DeviceConfiguration/DeviceConfigurationConfigureFirmware",
        "PUT ProjectManagement/ProjectManagementDeviceEdit",
        "POST ProjectManagement/ProjectManagementGroupCreate",
        "PUT ProjectManagement/ProjectManagementGroupEdit",
        "DELETE ProjectManagement/ProjectManagementGroupDelete",
        "DELETE ProjectManagement/ProjectManagementDeviceDelete",
        "POST UserManagement/UserManagementAdministeredUserCreateUser",
        "POST UserManagement/UserManagementAdministeredUserInvite",
        "PUT UserManagement/UserManagementAdministeredUserEdit",
        "DELETE UserManagement/UserManagementAdministeredUserDeleteUser",
        "PUT UserManagement/UserManagementUserEdit",
        "PUT Notification/PutNotification",
    ]
}
