import React from 'react'
import {useTranslation} from 'react-i18next'
import {getCssVarValue, telemetryColorsForDevice} from '../../../../../helpers/helperfunctions'
import PopOverDialog from "../../../../shared/popoverdialog/PopOverDialog.js";

/** Displays the latest airquality measures from telemetry data
 * @param {Object} device
 * @param {number} co2 co2 value from telemetry data
 * @param {number} voc voc value from telemetry data
 * @param {number} humidity humidity value from telemetry data
 * @param {boolean} isCO2Installed determines if the value should be shown
 * @param {boolean} listViewChosen boolean to show or hide grid/list-view
 * @param {String} className custom classes to add to the wrapper
 * @param {boolean} isVocInstalled determines if the value should be shown
 * @param {boolean} isHumidityInstalled determines if the value should be shown
 */
export default function AirQuality({
                                       device, co2, voc, humidity, isCO2Installed, isVocInstalled,
                                       isHumidityInstalled, className, listViewChosen
                                   }) {
    const {t} = useTranslation(['overviewpage'])
    let telemetryColors = telemetryColorsForDevice(device);

    const co2Color = telemetryColors.getCO2ColorObj(co2)?.bg ?? "rgb(117,117,117)"
    const vocColor = telemetryColors.getTVOCColorObj(voc)?.bg ?? "rgb(117,117,117)"
    const humidityColor = getCssVarValue("humidity");

    return (
        listViewChosen ?
            <div className={'justify-content-center d-flex ' + className}>
                {(!isCO2Installed && !isVocInstalled && !isHumidityInstalled) &&
                    <span className='d-inline-block text-bold pe-1'>-</span>}
                {isHumidityInstalled && !(isCO2Installed || isVocInstalled) &&
                    <span className='d-inline-block pe-1' style={{color: humidityColor}}>RHS: <span
                        className='text-nowrap text-bold'>{humidity} %</span></span>}
                {(isCO2Installed || isVocInstalled) &&
                    <>
                        {isCO2Installed &&
                            <span className='d-inline-block pe-1' style={{color: co2Color}}>CO₂: <span
                                className='text-nowrap text-bold'>{co2} ppm</span></span>
                        }
                        {isVocInstalled &&
                            <PopOverDialog popOverTitel={t('overviewpage:table.cells.air_quality.popover_title')}
                                           trigger={['hover', 'focus']} popOverContent={
                                <span style={{color: vocColor}}
                                      className='d-block'>{t(`overviewpage:table.cells.air_quality.voc`)}: <span
                                    className='text-nowrap text-bold'>{voc} pbb</span></span>
                            }/>
                        }
                    </>}
            </div>
            :
            <div className={`d-flex flex-column align-items-center ${className}`}>
                {(!isCO2Installed && !isVocInstalled && !isHumidityInstalled) &&
                    <span className='d-inline-block text-bold pe-1'>-</span>
                }
                {isCO2Installed &&
                    <div className='text-nowrap' style={{color: co2Color, fontSize: '15px'}}>
                        <span>CO₂:</span> <span style={{fontWeight: 'bold'}}>{co2} ppm</span>
                    </div>
                }
                {isVocInstalled &&
                    <div className='text-nowrap' style={{color: vocColor, fontSize: '15px'}}>
                        <span className='me-1'>{t(`overviewpage:table.cells.air_quality.voc`)}:</span>
                        <span style={{fontWeight: 'bold'}}>{voc} ppb</span>
                    </div>
                }
                {isHumidityInstalled &&
                    <div className='text-nowrap' style={{color: humidityColor, fontSize: '15px'}}>
                        <span className='me-1'>RHS:</span>
                        <span style={{fontWeight: 'bold'}}>{humidity} %</span>
                    </div>
                }
            </div>
    )
}