import React from 'react'
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {useTranslation} from 'react-i18next';

/** Displays the Airflow chart in device table
 * @param {number} flow flow value from telemetry data
 * @param {boolean} listViewChosen boolean to show or hide grid/list-view
 * @param {string} className custom classes to add to the wrapper
 */
export default function DeviceFlow({flow, listViewChosen, className}) {
    const {t} = useTranslation(["overviewpage"])
    const flowString = flow === undefined ? "-" : flow + " %" 
    const flowValue = flow === undefined ? 0 : flow
    return (
        <div className={className}>
            {listViewChosen ?
                <span style={{color: "#4f769c"}}>{t('overviewpage:table.cells.air_flow.flow')}<b> {flowString}</b></span>
                :
                <>
                    <div className='d-inline-block' style={{width: 70, height: 70}}>
                        <CircularProgressbar styles={buildStyles({
                            pathTransitionDuration: 0.5,
                            rotation: 1 / 2 + 1 / 8,
                            trailColor: "#eee",
                            pathColor: "#4f769c",
                            textColor: "#4f769c",
                        })} value={flowValue} text={`${flowString}`} circleRatio={0.75}/>
                    </div>
                </>
            }
        </div>
    )
}