import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import DeviceLogTableItem from "./tableitem/DeviceLogTableItem.js";
import LogDropdown from "./logdropdown/LogDropdown";
import PopOverDialog from "../../shared/popoverdialog/PopOverDialog.js";
import {ExclamationTriangle, InfoLg} from "react-bootstrap-icons";
import {useDispatch} from "react-redux";
import {modifyCurrentDevice} from "../../../helpers/reduxstore/reducers/deviceReducer.js";
import {useMsal} from "@azure/msal-react";
import {hasAnyRole} from "../../../helpers/authHelper.js";
import {roleConstants} from "../../../helpers/constants.js";

/** Renders the devicelog for the dashboard page
 * @param {string} className classes for the wrapper of the component
 * @param {[object]} logList
 * @param {function} requestLogData function to fetch log data from the API
 * @param {object} device currently selected device
 */
export default function DeviceLog({className, logList, requestLogData, device}) {
    const dispatch = useDispatch()
    const {t} = useTranslation(['dashboard'])
    const [logListToShow, setLogListToShow] = useState([])
    const logContainerRef = useRef()
    const notesTimeoutRef = useRef(null)
    const {accounts} = useMsal();

    const [deviceBasicNotes, setDeviceBasicNotes] = useState("")
    const [oldDeviceBasicNotes, setOldDeviceBasicNotes] = useState("")
    useEffect(() => {
        if ((device.basicNotes || device.basicNotes === "") && device.basicNotes !== oldDeviceBasicNotes) {
            setDeviceBasicNotes(device.basicNotes)
            setOldDeviceBasicNotes(device.basicNotes)
        }
    }, [device])

    const [showDeviceConfigurations, setShowDeviceConfigurations] = useState(true)
    const [showDeviceAlarms, setShowDeviceAlarms] = useState(true)
    const [showDeviceWarnings, setShowDeviceWarnings] = useState(true)

    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    useEffect(() => {
        requestLogData(fromDate, toDate)
    }, [fromDate, toDate])

    useEffect(() => {
        const logDataList = fromDate && toDate ? logList : device.logs
        setLogListToShow(sortDescendingDate(filterByEvent(logDataList)))
        setTimeout(() => {
            if (logContainerRef.current) // Sometimes we get errors in sentry because this is null, so it must not be set instantly by ref attribute
                logContainerRef.current.scrollBy(0, 1000000) // scroll log container to bottom
        }, 50)
    }, [logList, showDeviceConfigurations, showDeviceAlarms, showDeviceWarnings, fromDate, toDate])

    const sortDescendingDate = (dataLog) => {
        return dataLog.sort((a, b) => new Date(b.ts) - new Date(a.ts))
    }

    const filterByEvent = (dataLog) => {
        let filteredData = []
        if (showDeviceAlarms) filteredData = dataLog.filter(obj => obj.type === 'ALARM')
        if (showDeviceConfigurations)
            filteredData = filteredData.concat(dataLog.filter(obj => (obj.type === 'PARAMETER' || obj.type === 'PARAMETER_CHANGE_REQUEST' || obj.type === 'PROCESS')))
        if (showDeviceWarnings) filteredData = filteredData.concat(dataLog.filter(obj => obj.type === 'WARNING'))
        return filteredData
    }

    function SaveDeviceBasicNote(event) {
        if (notesTimeoutRef.current) window.clearTimeout(notesTimeoutRef.current)
        const newBasicNotes = event.target.value
        if (newBasicNotes === device.basicNotes) return;
        setDeviceBasicNotes(newBasicNotes)
        notesTimeoutRef.current = window.setTimeout(() => {
            dispatch(modifyCurrentDevice(
                {id: device.id, basicNotes: newBasicNotes},
                {
                    type: "success",
                    title: t("dashboard:toasts.saveDeviceNote.title"),
                    body: t("dashboard:toasts.saveDeviceNote.body"),
                    timeSeconds: 3
                }))
        }, 2000)
    }

    return (
        <div className={className}>
            <div className="boxstyling d-flex flex-column p-3 w-100 h-100">

                {/* === HEADER === */}
                <div className="d-flex justify-content-between mb-2">
                    <div className='d-flex'>
                        <h4>{t('dashboard:log.headline')}</h4>
                        <PopOverDialog popOverTitel={t('dashboard:log.filter_dropdown.message_types')} popOverContent={
                            <>
                                <div className={'mb-1'}>
                                    <div className='d-inline-block log-item-icon-sm bg-color-event'>
                                        <InfoLg width='15px' height='15px' viewBox='0 1 16 15'
                                                style={{verticalAlign: 'unset'}}/>
                                    </div>
                                    <span className={'ms-1'}><span
                                        className='text-bold'>{t('dashboard:log.filter_dropdown.events.headline')}</span>: {t('dashboard:log.filter_dropdown.events.text')}</span>
                                </div>
                                <div className={'mb-1'}>
                                    <div className='d-inline-block log-item-icon-sm bg-warning'>
                                        <ExclamationTriangle width='15px' height='15px' viewBox='0 1 16 15'
                                                             style={{verticalAlign: 'unset'}}/>
                                    </div>
                                    <span className={'ms-1'}><span
                                        className='text-bold'>{t('dashboard:log.filter_dropdown.warnings.headline')}</span>: {t('dashboard:log.filter_dropdown.warnings.text')}</span>
                                </div>
                                <div>
                                    <div className='d-inline-block log-item-icon-sm bg-danger'>
                                        <ExclamationTriangle width='15px' height='15px' viewBox='0 1 16 15'
                                                             style={{verticalAlign: 'unset'}}/>
                                    </div>
                                    <span className={'ms-1'}><span
                                        className='text-bold'>{t('dashboard:log.filter_dropdown.alarms.headline')}</span>: {t('dashboard:log.filter_dropdown.alarms.text')}</span>
                                </div>
                            </>
                        } trigger={['touch', 'hover']} size='lg'/>
                    </div>
                    <LogDropdown
                        setShowDeviceAlarms={setShowDeviceAlarms}
                        setShowDeviceConfigurations={setShowDeviceConfigurations}
                        setShowDeviceWarnings={setShowDeviceWarnings}
                        showDeviceAlarms={showDeviceAlarms}
                        showDeviceConfigurations={showDeviceConfigurations}
                        showDeviceWarnings={showDeviceWarnings}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        fromDateVal={fromDate}
                        toDateVal={toDate}
                    />
                </div>

                <div className='overflow-y-auto' ref={logContainerRef}
                     style={{fontSize: '14px', maxHeight: '400px'}}>
                    {logListToShow.map((logItem, idx) => <DeviceLogTableItem key={idx} logItem={logItem}/>)}
                </div>

                {!hasAnyRole(accounts, [roleConstants.Viever, roleConstants.Basic]) &&
                    <textarea className='border-dim rounded-3 p-1 mt-auto' style={{minHeight: '75px', fontSize: '12px'}}
                              onChange={SaveDeviceBasicNote} value={deviceBasicNotes}
                              placeholder={t("dashboard:notes.placeholder")}/>
                }
            </div>
        </div>
    )
}