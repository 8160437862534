import {useTranslation} from 'react-i18next';
import React from "react";
import {JE_AwareSwitch} from '../../../pages/firmware/JE_AwareSwitch.js'
import {SearchInput} from "../../shared/searchinput/SearchInput.js";

export default function ManageFirmwareTableFilterSearch({
                                                            className, searchString, setSearchString, totalFirmwareNum,
                                                            shownFirmwareNum, deviceFamily, setDeviceFamily
                                                        }) {
    const {t} = useTranslation(['firmwarepage', 'common'])

    return (
        <div className={className}>

            <div>
                <JE_AwareSwitch value={deviceFamily === 'je'}
                                setValue={(state) => setDeviceFamily(state ? 'je' : 'aware')}/>

                <span
                    className="ms-3 text-nowrap small">{`${t('firmwarepage:managetable.headers.total')} ${totalFirmwareNum}`}</span>
                {shownFirmwareNum !== totalFirmwareNum && <span
                    className="text-nowrap small">{`, ${t('firmwarepage:managetable.headers.shown')}: ${shownFirmwareNum}`}</span>}
            </div>

            <div className="ms-auto">
                <SearchInput setSearchString={e => setSearchString(e.target.value)} searchString={searchString}
                             placeholder={t('common:search.placeholder')}/>
            </div>
        </div>
    );
}