import AM150 from '../../../../images/devicetypes/AM150.png'
import AM300 from '../../../../images/devicetypes/AM300.png'
import AM500 from '../../../../images/devicetypes/AM500.png'
import AM800 from '../../../../images/devicetypes/AM800.png'
import AM900 from '../../../../images/devicetypes/AM900.png'
import AME900F from '../../../../images/devicetypes/AME900F.png'
import AM1000 from '../../../../images/devicetypes/AM1000.png'
import AMX4 from '../../../../images/devicetypes/AMX4.png'
import AM1200 from '../../../../images/devicetypes/AM1200.png'
import CV200 from '../../../../images/devicetypes/CV200.png'
import DV1000 from '../../../../images/devicetypes/DV1000.png'
import {useTranslation} from "react-i18next";
import React from "react";
import DeviceConfigurationRow from "../devicestatus/configurations/configurationrow/DeviceConfigurationRow.js";

/** renders a view containing metadata about the device, along with device image, installed components and time to service.
 * @param {object} device device */
export default function DeviceSpecifications({device}) {
    const {t} = useTranslation(["dashboard", "parameters", "common"])

    return (
        <div className='d-flex d-xs-block'>
            <div className='boxstyling rounded p-4 px-5 bg-airmaster-blue-dark me-2 text-white'
                 style={{flex: '1', fontSize: '19px'}}>
                <span className='w-100 small text-bold ps-2 d-md-block d-lg-none font-weight-500'>Specifications</span>
                <div className='w-100'>
                    <DeviceConfigurationRow title={'Model:'} isInstalled={true} value={device.model}/>
                    <DeviceConfigurationRow title={t('dashboard:specifications.sn') + ':'} isInstalled={true}
                                            value={device.serial_number}/>

                    <div className='d-flex flex-grow-1 mb-1 align-items-center'>
                        <span
                            className='ps-2 small text-truncate text-nowrap'>{t('dashboard:specifications.installed')}:</span>
                    </div>
                    <div className='mb-1'>
                        <ul className='mb-0' style={{fontSize: '15px'}}>
                            {Object.keys(device.installed_components) // TODO: once things are figured out with heater/cooler, remove those from filter
                                .filter(component => component !== "cooler" && component !== "heater" && device.installed_components[component])
                                .map(component => <li key={component}>{t("parameters:" + component)}</li>)
                            }
                        </ul>
                    </div>

                    <DeviceConfigurationRow title={t('dashboard:specifications.firmware') + ':'} isInstalled={true}
                                            value={device.device_twin.reported.applicationVersion}/>

                </div>
            </div>
            <div className='p-3 d-flex' style={{flex: '3'}}>
                <img src={getImageByModel(device.model)} style={{maxWidth: "100%", maxHeight: "100%"}}
                     className=" h-auto m-auto" alt="device"/>
            </div>
        </div>
    )
}

const getImageByModel = (deviceModel) => {
    switch (deviceModel) {
        case "AM 150":
            return AM150
        case "AMC 150":
            return AM150
        case "AM 300":
            return AM300
        case "AM 500":
            return AM500
        case "AM 800":
            return AM800
        case "AM 900":
            return AM900
        case "AME 900F":
            return AME900F    
        case "AM 1000":
            return AM1000
        case "AM 1200":
            return AM1200
        case "DV 1000":
            return DV1000
        case "CV 200":
            return CV200
        case "AMX 4":
            return AMX4
        default:
            return AM150
    }
}
