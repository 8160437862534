import Button from 'react-bootstrap/Button'
import {Funnel, X} from 'react-bootstrap-icons'
import React from 'react'
import {useTranslation} from 'react-i18next'

/** Renders a button to the FilterModal in which groups can be applied
 * @param {function} removeFilter function to handle when the submit button is pressed
 * @param {function(onClick)} showModal function to render the FilterModal window
 * @param {object[]} filterGroups Array of selected groups from the FilterModal */
export default function FilterButton({removeFilter, showModal, filterGroups}) {
    const {t} = useTranslation(['userpage'])

    return (
        <Button variant='outline-secondary' onClick={showModal}>
            <Funnel/>
            <span className='ms-2'>Filter{filterGroups.length > 0 ? ':' : ''}</span>
            {filterGroups.length > 0 &&
                <span className='ms-2 position-relative'>
                    <span>{filterGroups.length === 1 ? filterGroups[0].name : t('userpage:user_management.filter_btn.badge', {count: filterGroups.length})}</span>
                    <span className="delete-tick" style={{marginLeft: '8px'}}
                          onClick={e => {
                              e.stopPropagation()
                              removeFilter()
                          }}>
                        <X className='text-white' style={{width: "1em", height: "1em", marginTop: '-10px'}}/>
                    </span>
                </span>
            }
        </Button>
    )
}