import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loadCurrentDeviceWrapper} from "../../helpers/deviceHelper.js";
import {globalErrorTypes} from "../../helpers/constants.js";
import {useTranslation} from "react-i18next";
import {removeGlobalError} from "../../helpers/reduxstore/reducers/loadingReducer.js";
import {LoadSpinner} from "../shared/loader/LoadSpinner.js";

export function IfDeviceFound({children}) {
    const {t} = useTranslation(['common'])
    const dispatch = useDispatch()
    const {deviceSerialNumber} = useParams();
    const {currentDevice} = useSelector(l => l.device)
    const {globalErrors} = useSelector(l => l.loading)

    useEffect(() => {
        dispatch(removeGlobalError(globalErrorTypes.deviceNotFound))
        loadCurrentDeviceWrapper(currentDevice, deviceSerialNumber, dispatch)
    }, [])

    const deviceNotFound = () => globalErrors.includes(globalErrorTypes.deviceNotFound);
    const isLoading = () => deviceSerialNumber !== currentDevice.serial_number

    if (deviceNotFound())
        return <div className="d-flex justify-content-center align-items-center" style={{marginTop: "40vh"}}>
            <div>{t('common:globalErrors.deviceNotFound')}</div>
        </div>

    if (isLoading()) return <LoadSpinner style={{marginTop: "40vh"}} height='60px' width='60px'/>

    return children
}