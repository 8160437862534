import {uniqBy} from "lodash";

export const dropHandler = (e, managedNode, itemDroppedCallback, handleDragExit, selectedNodeList) => {
    let type = e.dataTransfer.types.includes('group') ? 'group' : 'device';
    if (type === "group" || selectedNodeList.length <= 1) {
        // There was an error here, so logging some more
        // see https://airmaster-as.sentry.io/issues/4026854769/?alert_rule_id=11952736&alert_timestamp=1679479920122&alert_type=email&environment=prod&project=6654519&referrer=alert_email
        const data = e.dataTransfer.getData(type)
        if (!data) {
            throw Error(JSON.stringify({
                message: `e.dataTransfer.getData(type) was falsy, see error`,
                type: type,
                dataTransfer: e.dataTransfer,
                data: data,
                e, managedNode, itemDroppedCallback, handleDragExit, selectedNodeList
            }))
        }
        let parsedData = JSON.parse(e.dataTransfer.getData(type));
        itemDroppedCallback(parsedData, managedNode)
    } else {
        const data = uniqBy([JSON.parse(e.dataTransfer.getData(type)), ...selectedNodeList.filter(n => n.type === 'device')], 'id')
        itemDroppedCallback(data, managedNode)
    }
    handleDragExit(e)
}

export const toggleGrabbingCursor = (eleRef) => {
    if (eleRef.current.classList.contains("cu-grabbing")) {
        eleRef.current.classList.remove("cu-grabbing")
        eleRef.current.classList.add("cu-grab")
    } else {
        eleRef.current.classList.remove("cu-grab")
        eleRef.current.classList.add("cu-grabbing")
    }
}
export const toggleEditStyles = (isDraggable, selectRef, allowDrag = true) => {
    if (isDraggable) {
        allowDrag && selectRef.current.classList.add('cu-grab')
        selectRef.current.classList.add('active-hover-effect')
    } else {
        allowDrag && selectRef.current.classList.remove('cu-grab')
        selectRef.current.classList.remove('active-hover-effect')
        selectRef.current.classList.add('cursor-pointer', 'hover-effect')
    }
}
export const dragStartEventHandler = (e, node, selectRef, type) => {
    toggleGrabbingCursor(selectRef)
    e.dataTransfer.setData(type, JSON.stringify(node));
}