import AdminOperationsJobFilterSearchBar from "./AdminOperationsJobFilterSearchBar.js"
import AdminOperationsJobTableBody from "./AdminOperationsJobTableBody.js"
import {useEffect, useState} from "react"
import {uniq} from "lodash"
import {getComparator} from "../../../helpers/helperfunctions.js"

export default function AdminOperationsJob({rawJobList}) {
    const [search, setSearch] = useState("")
    const [sortObj, setSortObj] = useState({sortValue: "startTimeISO", sortDirection: 'desc'})
    const [typeFilter, setTypeFilter] = useState("all")
    const [typeFilterOptionsList, setTypeFilterOptionsList] = useState([])
    const [filteredSortedJobList, setFilteredSortedJobList] = useState([])

    useEffect(() => {
        setTypeFilterOptionsList([
            {id: "all", option: "Alle"},
            ...uniq(rawJobList.map(j => j.jobName)).map(name => ({id: name, option: name}))
        ])
    }, [rawJobList])

    const sortHandler = (headerData) => {
        setSortObj({
            sortDirection: headerData.sortValue === sortObj.sortValue && sortObj.sortDirection === 'desc' ? 'asc' : "desc",
            sortValue: headerData.sortValue
        })
    }

    useEffect(() => {
        const sortedJobList = [...rawJobList].sort(getComparator(sortObj.sortDirection, sortObj.sortValue))
        const typeFilteredJobList = typeFilter !== "all" ? sortedJobList.filter(j => j.jobName === typeFilter) : sortedJobList
        if (search) {
            const searchLower = search.toLowerCase()
            setFilteredSortedJobList(typeFilteredJobList.filter(e =>
                e.jobName.toLowerCase().includes(searchLower) || (e.success ? "ja" : "nej").includes(searchLower) ||
                e.startTimeISO.includes(searchLower) || e.runTimeMs.toString().includes(searchLower) ||
                e.description.toLowerCase().toString().includes(searchLower) ||
                e.descriptionDetailed.toLowerCase().toString().includes(searchLower)
            ))
        } else {
            setFilteredSortedJobList(typeFilteredJobList)
        }
    }, [rawJobList, typeFilter, search, sortObj])

    return (
        <div>
            <p className='ms-3'>Jobbeskrivelser</p>
            <AdminOperationsJobFilterSearchBar className={`d-flex justify-content-between align-items-center p-2`}
                                               searchValue={search} setSearch={setSearch}
                                               typeFilterOptionsList={typeFilterOptionsList} typeFilter={typeFilter}
                                               setTypeFilter={setTypeFilter}/>

            <div className='text-nowrap small ps-3 mb-1'>{`Antal: ${filteredSortedJobList.length}`}</div>
            <AdminOperationsJobTableBody jobList={filteredSortedJobList} sort={sortObj} sortHandler={sortHandler}/>

        </div>
    )
}
