import React, {useEffect, useState} from "react";

/** **ProfileImageSelector** component, is a selector which decides whether to show a thumbnail with
 * the user image, or just the user initials (If no image is provided)
 * @param {string} placeholderFontSize determines the font-size of the initials (can be either 'lg' or 'sm')
 * @param {string} image a link with the image to show in the image thumbnail
 * @param {string} givenName is used to construct the initials
 * @param {string} surname is used to construct the initials
 * @param {boolean} randomize is used to determine if thumbnail with initials should randomize style color (defaults to false)
 */
export default function ProfileImageSelector({placeholderFontSize, image, givenName, surname}) {
    const [initials, setInitials] = useState('')
    const [fontSize, setFontSize] = useState({fontSize: "3rem", paddingBottom: '6px'})
    const [profileImage, setInitialImage] = useState(null)

    useEffect(() => {
        setInitialImage(image)
        setUserInitials(givenName, surname)
        selectFontSize(placeholderFontSize)
    }, [givenName, surname, image, placeholderFontSize])

    const setUserInitials = (fname, sname) => {
        if (fname === "" && sname === "") {
            setInitials("//")
        } else if (!fname && !sname) {
            setInitials("//")
        } else {
            setInitials(fname.charAt(0) + sname.charAt(0))
        }
    }
    const selectFontSize = (size) => {
        switch (size) {
            case "sm":
                setFontSize({fontSize: "0.9rem", paddingBottom: '1px'})
                break;
            case "lg":
                setFontSize({fontSize: "2rem", paddingBottom: '5px'})
                break;
            default:
        }
    }

    return (
        <React.Fragment>
            {profileImage ?
                <img className="img-fluid rounded-image d-inline-block h-100" alt=""
                     src={image}
                     data-holder-rendered="true"
                /> :
                <div className='rounded-image d-flex align-items-center h-100 bg-secondary'>
                    <span style={fontSize} className='text-center mx-auto text-white'>{initials}</span>
                </div>}
        </React.Fragment>
    )
}