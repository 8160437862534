import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {PersonPlusFill} from 'react-bootstrap-icons';
import {useTranslation} from 'react-i18next';
import FilterButton from '../../../../components/shared/filterbutton/FilterButton.js';
import FilterModal from '../../../../components/shared/filterModal/FilterModal.js';
import {hasAnyRole} from "../../../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";
import {roleConstants} from "../../../../helpers/constants.js";
import {SearchInput} from "../../../shared/searchinput/SearchInput.js";

/** Renders the table header for the user-groups table
 * @param {function} setSelectedGroups function to set the selected groups from the filter modal
 * @param {string} searchString
 * @param {function} setSearchString function to set the string inserted into the search box
 * @param {function} showAddUserModal function to trigger the visibility of the add user modal (Triggered by the add user button)
 * @param {object[]} selectedGroups list of groups already selected in the filter
 * @param {int} userListToShowNum */
export default function UserTableHead({
                                          setSelectedGroups, searchString, setSearchString, showAddUserModal,
                                          selectedGroups, userListToShowNum
                                      }) {
    const {t} = useTranslation(['userpage'])
    const {accounts} = useMsal();
    const [filterModalVisibility, setFilterModalVisibility] = useState(false)

    return (
        <div className='mt-3 mb-2'>
            <FilterModal selectedItemsList={selectedGroups} show={filterModalVisibility}
                         dismiss={() => setFilterModalVisibility(false)}
                         submit={(data) => {
                             setSelectedGroups(data);
                             setFilterModalVisibility(false)
                         }}
                         modalTitle={t('userpage:filter_modal.title')}
                         modalBody={t('userpage:filter_modal.body')}
                         dropzoneBody={t('userpage:filter_modal.dropzone_body')}
            />
            <div className='d-flex justify-content-between d-xs-block'>
                <div className="d-flex align-items-center mb-xs-2">
                    <FilterButton showModal={() => setFilterModalVisibility(true)}
                                  removeFilter={() => setSelectedGroups([])} hasPopOver={true}
                                  filterGroups={selectedGroups}/>
                    <span className='text-nowrap small ms-2'>
                        {`${t('userpage:user_management.table.users_found')}: ${userListToShowNum}`}
                    </span>
                </div>
                <div className='d-flex align-items-center'>
                    <SearchInput setSearchString={e => setSearchString(e.target.value)} searchString={searchString}
                                 placeholder={t('userpage:user_management.search')}/>
                    {hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical]) &&
                        <Button className="ms-2" variant='outline-secondary' onClick={() => showAddUserModal(true)} type="submit">
                            <span className='d-none d-md-inline pe-1 text-nowrap'>
                                {t('userpage:user_management.add_user_btn')}
                            </span>
                            <PersonPlusFill className='d-inline d-md-none'/>
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
}