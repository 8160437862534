import {TableCell, TableSortLabel} from '@mui/material'
import React from 'react'

/** Renders a header cell
 * @param {function} sortHandler
 * @param {{name: string, sortValue: string}} headerObj
 * @param {{sortValue: string, sortDirection: 'asc' | 'desc'}} sort
 * @param sx
 * @param alignment
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export default function HeaderCell({sortHandler, headerObj, sort, sx, alignment, className}) {
    return (
        <TableCell sortDirection={sort?.sortValue === headerObj?.sortValue ? sort?.sortDirection : false} align={alignment} sx={sx}
                   className={className}>
            {headerObj.sortValue &&
                <TableSortLabel active={sort?.sortValue === headerObj.sortValue} direction={sort?.sortDirection}
                                onClick={() => sortHandler(headerObj)}> <b>{headerObj.name}</b>
                </TableSortLabel>
            }
            {!headerObj.sortValue &&
                <b>{headerObj.name}</b>
            }
        </TableCell>
    )
}