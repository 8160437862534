import React from "react";

/**
 * @param item
 * @param value
 * @param bitmask_index If type is bitmask, specify the index of bitmask to show
 * @param deviceConnected if device is not connected, hide value
 * @param {string} valueOverride If set, use as value
 * @returns {JSX.Element}
 * @constructor
 */
export function DeviceTechnicalTelemetryOverviewItem({item, value, bitmask_index = null, deviceConnected = true, showId = true, valueOverride = ""}) {
    function getName() {
        if(item === undefined) return ""
        return item.type === 'bitmask' ? item.options[bitmask_index].name_orig : item.name_orig
    }

    function getValue() {
        if (valueOverride && valueOverride.length !== 0) return valueOverride
        if (!deviceConnected) return "-"
        if (item.type === "enum") {
            if (!item.options) return "Error: No options object"
            return item.options[value]
        } else if (item.type === "bitmask") {
            // noinspection EqualityComparisonWithCoercionJS
            return value == '1' ? item.options[bitmask_index].pos : item.options[bitmask_index].neg
        }
        return `${value} ${item.unit == "-" || item.unit == undefined ? " " : item.unit}`
    }

    return <div className="d-flex">
        {showId &&   
           <div style={{opacity: '0.5', width: '58px'}}>{`${item.id}${item.type === 'bitmask' ? `.${bitmask_index}` : ''}`}</div>
        }
        <div style={{flex: '1', fontWeight: '600'}}>{getName()}</div>
        <div className='ms-auto me-1'>{getValue()}</div>
    </div>
}