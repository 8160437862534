import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import GroupTreeView from '../treeview/GroupTreeView.js';
import {
    constructPath, deviceIsTouch, findGroup, createPathStringFromArray, removeGroupFromHierarchy
} from '../../../helpers/helperfunctions';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import {XCircleFill} from 'react-bootstrap-icons';
import MobileToggle from './MobileToggle';
import {cloneDeep} from "lodash";
import {Button} from "react-bootstrap";

/** Displays two hierarchies and allows dragging items from source hierarchy to target hierarchy. The component has handlers which controls user actions.
 * @param {string} className className
 * @param {function} selectItem Function to bubble selected items (array of groups objects) to parent component
 * @param {Array} selectedItems Preset items if existing items has already been selected (can be group or id of group)
 * @param {boolean} hasError Property to toggle background color of target hierarchy if no items are present
 * @param {string} dropzoneBody
 * @param {function(object[])} notifyTargetChanges
 * @returns component with two tree hierarchies
 */
export default function GroupsFilterSelector({
                                                 selectItem, selectedItems, hasError = false,
                                                 dropzoneBody, notifyTargetChanges = null
                                             }) {
    const {t} = useTranslation(['common'])
    const [referenceArray, setReferenceArray] = useState([])
    const [sourceArray, setSourceArray] = useState([])
    const [targetArray, setTargetArray] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [sourceSelectedNodeList, setSourceSelectedNodeList] = useState([])
    const [targetSelectedNodeList, setTargetSelectedNodeList] = useState([])
    const [mobileSelectedGroup, setMobileSelectedGroup] = useState(null)
    const {allowedGroups} = useSelector(state => state.group)
    const backgroundRef = useRef()

    useEffect(() => {
        setReferenceArray(cloneDeep(allowedGroups))
        if (selectedItems) {
            const selectedGroups = selectedItems.length > 0 && typeof selectedItems[0] === "string"
                ? selectedItems.map(groupId => findGroup(groupId, allowedGroups)).filter(g => g) : selectedItems
            setSelectedHierarchy(selectedGroups)
        } else {
            setSourceArray(cloneDeep(allowedGroups))
        }
        // eslint-disable-next-line
    }, [allowedGroups, selectedItems])

    useEffect(() => {
        toggleErrorBackground(hasError)
    }, [hasError])

    /** Sets the hierarchy when a user already has predefined items
     * @param {object} selectedGroups groups that a user already has access too */
    const setSelectedHierarchy = (selectedGroups) => {
        let source = cloneDeep(allowedGroups)
        for (let item of selectedGroups) {
            const grp = findGroup(item.id, allowedGroups)
            removeGroupFromHierarchy(source, grp)
        }
        setSourceArray(cloneDeep(source))
        setNewTarget(selectedGroups)
    }

    /** Sets a new target array
     * @param {object[]} objectArr targetArray object */
    const setNewTarget = (objectArr) => {
        const clonedArr = cloneDeep(objectArr)
        setTargetArray(clonedArr)
        selectItem(clonedArr)
        if (notifyTargetChanges) notifyTargetChanges(clonedArr)
    }

    /** Gets an existing hierarchy and embeds the supplied group
     * @param {object[]} selectedArray existing hierarchy
     * @param {Object} grp group to embed into the hierarchy
     * @return assembled hierarchy
     */
    const assembleHierarchy = (selectedArray, grp) => {
        let assembledArray = []
        const existsAtIndex = []
        const newIndex = []
        if (selectedArray.length > 0) {
            for (let i = 0; i < selectedArray.length; i++) {
                if (idExistsInGroup(selectedArray[i], grp.id)) {
                    existsAtIndex.push(i)
                }
                if (idExistsInGroup(grp, selectedArray[i].id)) {
                    newIndex.push(i)
                }
            }
            if (existsAtIndex.length) {
                assembledArray = selectedArray
            } else if (newIndex.length) {
                assembledArray = selectedArray
                for (let i = 0; i < newIndex.length; i++) {
                    assembledArray.splice((newIndex[i] - i), 1)
                }
                assembledArray.push(grp)
            } else {
                assembledArray = selectedArray
                assembledArray.push(grp)
            }
        } else {
            assembledArray.push(grp)
        }
        return assembledArray
    }

    /** Checks if a group id exists in supplied group
     * @param {object} group group object to check for id presence
     * @param {string} id String with id of group to get
     * @returns boolean
     */
    const idExistsInGroup = (group, id) => {
        if (id === group.id) {
            return true
        }
        for (let children of group.children) {
            const res = idExistsInGroup(children, id)
            if (res) {
                return true
            }
        }
        return false
    }

    /** Clears a single object selection */
    const clearSelection = (item) => {
        const grp = findGroup(item.id, referenceArray)
        const topGroup = getTopGroup(targetArray, grp)
        removeGroupFromHierarchy(targetArray, topGroup)
        setNewTarget(targetArray)
        patchSource(sourceArray, topGroup)
        const sourceHierarchy = assembleHierarchy(sourceArray, topGroup)
        setSourceArray(cloneDeep(sourceHierarchy))
        setSelectedItem(null)
    }

    /** Takes a source object and appends a group if it doesn't exist as a children already
     * @param {object} source
     * @param {object} group */
    const patchSource = (source, group) => {
        for (let item of source) {
            if (item.id === group.parent) {
                let contains = false
                for (let child of item.children) {
                    if (child.id === group.id) {
                        contains = true
                    }
                }
                if (!contains) {
                    item.children.push(group)
                }
            }
            item.children && patchSource(item.children, group)
        }
    }

    /** Gets the parent group in a source hierarchy, based on supplied group
     * @param {object} source source object array
     * @param {object} group group from which the parent should
     * @returns the top group
     */
    const getTopGroup = (source, group) => {
        if (group.parent) {
            let parentGrp = findGroup(group.parent, source)
            if (parentGrp) {
                return getTopGroup(source, parentGrp)
            } else {
                return group
            }
        } else {
            return group
        }
    }

    /** Clears all containing arrays on the receiving side, that has been added in dropEventHandler */
    const clearSelections = () => {
        setNewTarget([])
        setSourceArray(cloneDeep(referenceArray))
    }

    /** Takes eventData and constructs HTML hierarchy
     * @param {*} e eventData with dataTransfer object
     */
    const dropEventHandler = (e) => {
        e.preventDefault()
        dragOutEventHandler(e)

        let type = e.dataTransfer.types.includes('group') ? 'group' : 'device';
        let grp = JSON.parse(e.dataTransfer.getData(type))
        addGroupToTarget(grp)
    }
    const addGroupToTarget = (grp) => {
        let group = findGroup(grp.id, referenceArray)
        const assembledArray = assembleHierarchy(targetArray, group)
        setNewTarget(assembledArray)
        removeGroupFromHierarchy(sourceArray, group)
        setSourceArray(cloneDeep(sourceArray))
    }

    /** Changes the color of the dropzone background to green
     * @param {object} e event data
     */
    const dragOverEventHandler = (e) => {
        e.preventDefault()
        if (backgroundRef.current.classList.contains('bg-light') || backgroundRef.current.classList.contains('bg-ltred')) {
            backgroundRef.current.classList.remove('bg-light')
            backgroundRef.current.classList.remove('bg-ltred')
            backgroundRef.current.classList.add('bg-ltgreen')
        }
    }

    /** Optional method to set an error background on the target list
     * @param {boolean} hasError when true, sets the error background
     */
    const toggleErrorBackground = (hasError) => {
        if (hasError && backgroundRef.current.classList.contains('bg-light')) {
            backgroundRef.current.classList.remove('bg-light')
            backgroundRef.current.classList.add('bg-ltred')
        } else if (!hasError && backgroundRef.current.classList.contains('bg-ltred')) {
            backgroundRef.current.classList.remove('bg-ltred')
            backgroundRef.current.classList.add('bg-light')
        }
    }

    /** Changes the color of the dropzone background to grey
     * @param {object} e event data
     */
    const dragOutEventHandler = (e) => {
        e.preventDefault()
        if (!hasError && backgroundRef.current.classList.contains('bg-ltgreen')) {
            backgroundRef.current.classList.remove('bg-ltgreen')
            backgroundRef.current.classList.add('bg-light')
        } else if (backgroundRef.current.classList.contains('bg-ltgreen')) {
            backgroundRef.current.classList.remove('bg-ltgreen')
            backgroundRef.current.classList.add('bg-ltred')
        }
    }

    return <>
        <div className='pt-2 d-flex'>
            <div style={{flex: '1 0 50%', maxWidth: '550px'}} className='bg-light me-md-2 rounded-3 p-1'>
                <div className='overflow-auto text-nowrap w-100 d-inline-block position-relative h-400 h-xs-350'>
                    {/*=== Group hierarchy container ===*/}
                    <GroupTreeView canDragReadOnly={true} setSelectedItem={setMobileSelectedGroup}
                                   selectedNodeList={sourceSelectedNodeList}
                                   setSelectedNodeList={setSourceSelectedNodeList} showSearchField={true}
                                   groups={sourceArray} hasRoot={false} allowDragOver={false} isDraggable={true}/>

                    {/*=== Apply filter button ===*/}
                    <div
                        className={`${mobileSelectedGroup && deviceIsTouch() ? "bottom-selector-visible" : "bottom-selector-hidden"}
                         d-flex align-items-center position-sticky ps-2 col-12`}>
                        <div className=' align-content-center d-flex'>
                            <Button disabled={!mobileSelectedGroup}
                                    variant='outline-secondary' size='sm'
                                    onClick={() => {
                                        addGroupToTarget(mobileSelectedGroup);
                                        setMobileSelectedGroup(null)
                                    }}>
                                {t('common:dropzone.buttons.apply_filter_btn')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{flex: '1 0 40%'}} className='p-1 rounded-3 bg-light d-none d-md-block'>
                <div ref={backgroundRef} id='DropBackground' style={{}}
                     onDrop={(e) => dropEventHandler(e)}
                     onDragOver={(e) => dragOverEventHandler(e)}
                     onDragLeave={(e) => dragOutEventHandler(e)}
                     className='overflow-auto text-nowrap transition-all col-12 d-inline-block bg-light position-relative'>
                    <div className='overflow-auto' style={{height: "360px", pointerEvents: "auto"}}>
                        <GroupTreeView setSelectedItem={setSelectedItem} groups={targetArray} hasRoot={false}
                                       selectedNodeList={targetSelectedNodeList}
                                       setSelectedNodeList={setTargetSelectedNodeList}
                                       allowDragOver={false} isDraggable={false}/>
                    </div>
                    <div id='DropText'
                         className='position-absolute text-center container text-muted disable-pointerevents position-relative text-wrap'
                         style={{marginTop: "-220px"}}>
                        {!targetArray.length && <>
                            <h4>{t('common:dropzone.title')}</h4><span>{dropzoneBody}</span>
                        </>}
                    </div>
                </div>
                {targetArray.length > 0 &&
                    <div className="boxstyling d-flex align-items-center p-1">
                        <Button size='sm' variant='outline-danger' onClick={clearSelections}>
                            {t('common:dropzone.buttons.all')}
                        </Button>
                        {selectedItem && <Button className='ms-2' size='sm' variant='outline-secondary' onClick={() => clearSelection(selectedItem)}>
                            {t('common:dropzone.buttons.branch')}
                        </Button>}
                    </div>
                }
            </div>
        </div>

        <Accordion defaultActiveKey="0" className='d-block d-md-none'>
            <Card>
                <MobileToggle hasError={hasError} eventKey="1" clearSelection={clearSelections}
                              targetArr={targetArray}/>
                <Accordion.Collapse eventKey="1">
                    <Card.Body className='p-2 pt-0 card-body'>
                        {targetArray.map(obj => {
                            return (
                                <div key={obj.id}
                                     className="col-12 border border-dim p-2 mt-2 bg-light rounded-3 align-text-bottom d-flex align-items-center">
                                    <div className="col-4 d-inline-block">{obj.name}</div>
                                    <div className="col-6 d-inline-block"><span
                                        className='filter-path'>{createPathStringFromArray(constructPath(findGroup(obj.parent, sourceArray), sourceArray), obj.name)}</span>
                                    </div>
                                    <div className="col-2 d-inline-block text-end pe-2 align-top"><XCircleFill
                                        className='text-danger cursor-pointer' onClick={() => clearSelection(obj)}/>
                                    </div>
                                </div>
                            )
                        })}
                        {targetArray.length === 0 &&
                            <div className="col-12 p-2 mt-2">
                                <span>{t('common:dropzone.noselected')}</span>
                            </div>
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
}