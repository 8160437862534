import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {constructPath, createPathStringFromArray, findGroup} from '../../helpers/helperfunctions.js';
import {useDispatch, useSelector} from 'react-redux';
import useInterval from '../../helpers/hooks/useInterval';
import DeviceOverviewTableBody from '../../components/overview/overviewtable/tbody/DeviceOverviewTableBody.js';
import TFooter from '../../components/shared/tfooter/TFooter.js';
import {setOverviewFilter} from "../../helpers/reduxstore/reducers/filterReducer.js";
import useSetLocation from "../../helpers/hooks/useSetLocation.js";
import {getCurrentLanguage} from "../../helpers/i18nConfig.js";
import {queryDeviceListWithCount} from '../../api/api.js'
import Button from "react-bootstrap/Button";
import {
    CheckCircle,
    ExclamationTriangle,
    Image,
    LightningCharge,
    ListUl
} from "react-bootstrap-icons";
import FilterModal from "../../components/shared/filterModal/FilterModal.js";
import FilterButton from "../../components/shared/filterbutton/FilterButton.js";
import {SearchInput} from "../../components/shared/searchinput/SearchInput.js";

/** Renders the device overview list */
export default function DeviceOverviewPage() {
    useSetLocation("Airlinq Online")
    const {t} = useTranslation(['overviewpage', 'common', 'telemetry'])
    const dispatch = useDispatch()

    const {overviewFilter} = useSelector(state => state.filter)
    const {allowedGroups} = useSelector(state => state.group)
    const [filterModalVisibility, setFilterModalVisibility] = useState(false)

    const [search, setSearch] = useState(overviewFilter.searchString ?? '')
    const timeoutHandle = useRef(null)

    // when typing in search input, wait some time before sending to backend, so we don't spam too much
    function startSearchTimeout(searchValue) {
        setSearch(searchValue)
        if (timeoutHandle.current !== null) window.clearTimeout(timeoutHandle.current)
        timeoutHandle.current = setTimeout(() => {
            setSearch(searchValue)
            dispatch(setOverviewFilter({searchString: searchValue}))
        }, 1000)
    }

    const [deviceListLoading, setDeviceListLoading] = useState(true)
    const [shownDeviceList, setShownDeviceList] = useState([]);
    const [deviceListWithCount, setDeviceListWithCount] = useState({
        count: 0,
        deviceList: [],
        aggregates: {
            alarm: '-', warning: '-', ok: '-', disconnected: '-'
        }
    })
    const deviceListWithCountAbortControllerRef = useRef(new AbortController())

    useEffect(() => {
        setDeviceListLoading(true)
        if (deviceListWithCountAbortControllerRef.current && deviceListWithCountAbortControllerRef.current.signal)
            deviceListWithCountAbortControllerRef.current.abort("Query renewed")
        deviceListWithCountAbortControllerRef.current = new AbortController()
        dispatchQueryDevices().then()
    }, [overviewFilter])
    useInterval(() => {
        dispatchQueryDevices().then()
    }, 1000 * 60)

    useEffect(() => {
        if (!deviceListWithCount.deviceList) return // spotted in sentry
        const deviceListWithPath = deviceListWithCount.deviceList.map(device => deviceWithPath(device))
        setShownDeviceList(deviceListWithPath)
    }, [deviceListWithCount])

    async function dispatchQueryDevices() {
        // cosmos cannot handle ' or " in search string
        let searchString = overviewFilter.searchString
            ?.replaceAll("'", "")?.replaceAll('"', '')
        const filter = {
            skip: (overviewFilter.page - 1) * overviewFilter.rowsPerPage,
            take: overviewFilter.rowsPerPage,
            state: Object.keys(overviewFilter.deviceListConditionFilter)
                .filter(key => overviewFilter.deviceListConditionFilter[key])
                .join(","), // example output: warning,ok
            search: encodeURIComponent(searchString?.trim() ?? ''),
            groups: overviewFilter.filterGroups.map(g => g.id).join(","),
            sort_direction: overviewFilter?.deviceListSort?.sortDirection ?? "asc",
            sort_value: overviewFilter?.deviceListSort?.sortValue ?? "name",
            lang: getCurrentLanguage()
        }
        try {
            const res = await queryDeviceListWithCount(filter, deviceListWithCountAbortControllerRef.current.signal)
            if (res) {
                // trycatch here while I try to solve removeChild bug
                // eg: https://airmaster-as.sentry.io/issues/4703052076/?project=6654519&query=is%3Aunresolved+%21level%3Awarning+%21level%3Ainfo&referrer=issue-stream&statsPeriod=24h&stream_index=0
                try {
                    setDeviceListWithCount(res)
                } catch (e) {
                    if (e.message.includes("removeChild")) {
                        console.log(res)
                    }
                    throw e
                }
                setDeviceListLoading(false)
            }
        } catch (e) {
            if (e.message.includes("The user aborted a request")) {
                // The user aborted a request. Do nothing. It is logged in api.js. But do not turn off spinner.
            } else {
                setDeviceListLoading(false)
                throw e
            }
        }
    }

    function deviceWithPath(device) {
        return {
            ...device,
            path: createPathStringFromArray(constructPath(findGroup(device.parent, allowedGroups), allowedGroups), device.name)
        }
    }

    /** @param {int} rowsPerPage */
    const dispatchSetOverviewRowsPerPage = (rowsPerPage) => {
        dispatch(setOverviewFilter({rowsPerPage: rowsPerPage, page: 1}))
    }

    /** @param {int} page */
    function setPage(page) {
        dispatch(setOverviewFilter({page}))
    }

    function flipConditionFilter(name) {
        dispatch(setOverviewFilter({
            deviceListConditionFilter: {
                ...overviewFilter.deviceListConditionFilter,
                [name]: !overviewFilter.deviceListConditionFilter[name]
            },
            page: 1
        }))
    }

    return (
        <div className="d-flex flex-column" style={{flex: '1'}}>
            <FilterModal selectedItemsList={overviewFilter.filterGroups} show={filterModalVisibility}
                         dismiss={() => setFilterModalVisibility(false)}
                         submit={chosenGroupList => {
                             dispatch(setOverviewFilter({filterGroups: chosenGroupList, page: 1}));
                             setFilterModalVisibility(false)
                         }}
                         modalTitle={t('overviewpage:filter_modal.title')}
                         modalBody={t('overviewpage:filter_modal.body')}
                         dropzoneBody={t('overviewpage:filter_modal.dropzone_body')}
            />

            {/* === Title and search === */}
            <div className='d-md-flex justify-content-between mt-md-4 mb-md-3 mt-xs-2 mb-xs-2'>
                <div className='mb-xs-2' style={{flex: 2}}>
                    <div className='title-font'
                         data-testid='title'>{t('overviewpage:info_section.info_section_title')}</div>
                    {/*<span style={{fontSize: '1.2rem'}}>{t('overviewpage:info_section.info_section_p')}</span>*/}
                </div>
                <div className='d-flex align-items-end' style={{width: '324px'}}>
                    <SearchInput setSearchString={e => startSearchTimeout(e.target.value)} searchString={search}
                                 placeholder={t('common:search.placeholder')}/>
                </div>
            </div>

            {/* === Filters === */}
            <div className='d-md-flex align-items-center justify-content-between mb-3'>
                <div className='d-flex align-items-center mb-xs-2'>
                    <Button className='me-2' variant='outline-secondary'
                            onClick={() => dispatch(setOverviewFilter({listViewChosen: !overviewFilter.listViewChosen}))}>
                        {overviewFilter.listViewChosen ? <Image/> : <ListUl/>}
                        <span
                            className='ms-2'>{overviewFilter.listViewChosen ? t('overviewpage:viewmode.large') : t('overviewpage:viewmode.small')}</span>
                    </Button>

                    <FilterButton showModal={() => setFilterModalVisibility(true)}
                                  removeFilter={() => dispatch(setOverviewFilter({filterGroups: [], page: 1}))}
                                  hasPopOver={true} filterGroups={overviewFilter.filterGroups}/>

                    <div
                        className='ms-3'>{t('overviewpage:table.headers.total')}: {deviceListWithCount?.count ?? 0}</div>
                </div>

                <div className='d-flex align-items-center flex-wrap'>
                    <Button
                        className={`d-flex align-items-center justify-content-center me-1 mt-xs-2`}
                        variant='outline-success' style={{minWidth: '160px'}}
                        active={overviewFilter.deviceListConditionFilter.ok}
                        onClick={() => flipConditionFilter('ok')}>
                        <CheckCircle/> <span
                        className='ms-1'>{deviceListWithCount?.aggregates?.ok ?? 0} {t('overviewpage:table.status.ok')}</span>
                    </Button>
                    <Button
                        className={`d-flex align-items-center justify-content-center me-1`}
                        variant='outline-warning' style={{minWidth: '160px'}}
                        active={overviewFilter.deviceListConditionFilter.warning}
                        onClick={() => flipConditionFilter('warning')}>
                        <ExclamationTriangle/> <span
                        className='ms-1'>{deviceListWithCount?.aggregates?.warning ?? 0} {t('overviewpage:table.status.warning')}</span>
                    </Button>
                    <Button
                        className={`d-flex align-items-center justify-content-center me-1`}
                        variant='outline-danger' style={{minWidth: '160px'}}
                        active={overviewFilter.deviceListConditionFilter.alarm}
                        onClick={() => flipConditionFilter('alarm')}>
                        <ExclamationTriangle/> <span
                        className='ms-1'>{deviceListWithCount?.aggregates?.alarm ?? 0} {t('overviewpage:table.status.alarm')}</span>
                    </Button>
                    <Button className='d-flex align-items-center justify-content-center' variant='outline-dark'
                            style={{minWidth: '160px'}}
                            active={overviewFilter.deviceListConditionFilter.disconnected}
                            onClick={() => flipConditionFilter('disconnected')}>
                        <LightningCharge/> <span
                        className='ms-1'>{deviceListWithCount?.aggregates?.disconnected ?? 0} {t('common:connectionstate.false')}</span>
                    </Button>
                </div>
            </div>

            {/* === Table === */}
            <DeviceOverviewTableBody
                className={'mb-2'}
                shownDeviceList={shownDeviceList}
                listViewChosen={overviewFilter.listViewChosen}
                deviceListLoading={deviceListLoading}
                deviceListSort={overviewFilter.deviceListSort}
            />

            <TFooter
                className="mb-3"
                setRowsPerPage={dispatchSetOverviewRowsPerPage}
                rowsPerPage={overviewFilter.rowsPerPage}
                dataLength={deviceListWithCount?.count ?? 0}
                setPage={setPage}
                page={overviewFilter.page}
            />

        </div>
    )
}