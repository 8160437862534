import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import React, {useEffect, useState} from 'react';
import HeaderCell from '../../../components/shared/headercell/HeaderCell.js';
import SettingsContentContainer from "../../../components/settings/contentcontainer/SettingsContentContainer.js";
import {formatDateTimeString} from "../../../helpers/helperfunctions.js";
import {getMonitorFnaTracesList} from "../../../api/api.js";

/**
 * @param className
 * @param {[object]} jobList
 * @param {{sortValue: string, sortDirection: 'asc' | 'desc'}} sort
 * @param {function} sortHandler
 * @returns {JSX.Element}
 * @constructor
 */
export default function AdminOperationsJobTableBody({jobList, sort, sortHandler}) {
    const [selectedJob, setSelectedJob] = useState({})
    const [jobLogList, setJobLogList] = useState([])
    useEffect(() => {
        setSelectedJob(jobList[0])
    }, [jobList])

    useEffect(() => {
        setJobLogList([])
        if (selectedJob?.invocationId) {
            getMonitorFnaTracesList(selectedJob.invocationId).then((res) => {
                setJobLogList(res.traceList)
            })
        }
    }, [selectedJob])


    const tableHeaderList = [
        {
            name: "Jobnavn",
            sortValue: "jobName",
            type: "string",
            display: "d-table-cell"
        },
        {
            name: "Success",
            sortValue: "success",
            type: "bool",
            display: "d-table-cell"
        },
        {
            name: "Start",
            sortValue: "startTimeISO",
            type: "string",
            display: "d-table-cell"
        },
        {
            name: 'Køretid (ms)',
            sortValue: "runTimeMs",
            type: "number",
            display: "d-table-cell"
        },
        {
            name: 'Resultat',
            sortValue: "description",
            type: "string",
            display: "d-table-cell"
        }
    ]

    return (
        <div className='mb-2 d-flex'>

            <div className='pe-0 ps-0 flex-1 d-flex'>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow hover>
                            {tableHeaderList.map((obj, i) => {
                                let sx = {backgroundColor: "#ededed", borderRight: "1px solid #d9d9d9"}
                                if (i === 0) {
                                    sx = {
                                        backgroundColor: "#ededed",
                                        borderRight: "1px solid #d9d9d9",
                                        borderTopLeftRadius: "0.8rem"
                                    }
                                } else if (i === tableHeaderList.length - 1) {
                                    sx = {backgroundColor: "#ededed", borderTopRightRadius: "0.8rem"}
                                }
                                return <HeaderCell key={obj.name} sortHandler={sortHandler} headerObj={obj}
                                                   sort={sort} sx={sx} alignment={"center"}
                                                   className={obj.display}/>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobList.map(row => (
                            <TableRow hover={true} selected={row?.timestamp === selectedJob?.timestamp}
                                      onClick={() => setSelectedJob(row)}
                                      key={row.timestamp}
                                      sx={{
                                          backgroundColor: 'white',
                                          "td": {
                                              borderBottom: "1px solid #d9d9d9",
                                              borderTop: "1px solid #d9d9d9",
                                              borderLeft: "1px solid #d9d9d9",
                                              cursor: "pointer"
                                          },
                                          "td:last-child": {
                                              borderRight: "1px solid #d9d9d9"
                                          }
                                      }}>

                                <TableCell className='d-table-cell' align="left">{row.jobName}</TableCell>
                                <TableCell className='d-table-cell' align="left">{row.success ?
                                    <span className='color-telemetry-very-good'>Ja</span> :
                                    <span className='color-telemetry-very-bad'>Nej</span>}</TableCell>
                                <TableCell className='d-table-cell'
                                           align="left">{formatDateTimeString(row.startTimeISO, true, true, true)}</TableCell>
                                <TableCell className='d-table-cell' align="left">{row.runTimeMs}</TableCell>
                                <TableCell sx={{maxWidth: '400px'}} className='d-table-cell' align="left">{row.description}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <SettingsContentContainer className={'bg-white p-2 ms-2 flex-1'}>
                <div>
                    <p className='mb-1 text-bold'>Detaljeret resultat</p>
                    {selectedJob?.descriptionDetailed &&
                        <div className='ms-2'
                            dangerouslySetInnerHTML={{__html: selectedJob?.descriptionDetailed?.replaceAll(";", "<br/>") ?? ""}}></div>
                    }
                    {!(selectedJob?.descriptionDetailed) &&
                        <div className='ms-2'>Intet detaljeret resultat</div>
                    }
                </div>

                {jobLogList.length > 0 &&
                    <div className='mt-4'>
                        <p className='mb-1 text-bold'>Job Log</p>
                        {jobLogList.map(j => <div className='ms-2' key={j.timestamp}>{severityToText(j.severity)}
                            <tt className='ms-1'>{j.timestamp}</tt> - {j.message}</div>)}
                    </div>
                }
            </SettingsContentContainer>

        </div>
    )
}

function severityToText(severity) {
    if (severity === "1") {
        return <span className='color-airmaster-title-blue'>INFO</span>
    } else if (severity === "2") {
        return <span className='color-warning'>WARN</span>
    }
    return <span className='color-danger'>ERROR</span>
}
