import Button from 'react-bootstrap/Button'
import React from 'react'
import {useTranslation} from "react-i18next";

/** Renders a submit and cancel button side-by-side with col-6 sizing each
 * @param {function(submitClick)} onClick function to handle when the submit button is pressed
 * @param {function(cancelClick)} onClick function to handle when the cancel button is pressed
 * @param {boolean} submitActive property to determine if the submit button should be active (Defaults to true)
 * @param {string} size controls the size of the checkbox (values: sm, md, lg)
 */
export default function EditButtonGroup({
                                            cancelClick, submitClick, deleteClick, submitActive = true,
                                            deleteClickActive = false, className, size = 'md',
                                            infoelement = null
                                        }) {
    const {t} = useTranslation(['common', 'grouppage'])
    return (
        <div className={className}>
            {infoelement &&
                <div className="d-inline-block ps-0 pe-1">
                    {infoelement}
                </div>
            }
            {(deleteClick && deleteClickActive) &&
                <Button size={size} variant="danger" onClick={deleteClick} disabled={!submitActive} className='me-2'>
                    {t('common:buttons.delete')}
                </Button>
            }
            <Button size={size} variant='outline-secondary' onClick={submitClick} disabled={!submitActive}
                    type="submit">{t('common:buttons.save')}</Button>
            <Button size={size} variant='outline-secondary' onClick={cancelClick}
                    type="submit" className='ms-2'>{t('common:buttons.cancel')}</Button>
        </div>
    )
}