import React from 'react'
import { useTranslation } from 'react-i18next'
import LogSelection from './logselection/LogSelection'

/** Displays the checkboxes for the device log menu (Fullsize)
 * @param {string} className list of classes to wrap the component
 * @param {boolean} showDeviceAlarms determines if alarms are checked
 * @param {boolean} showDeviceConfigurations determines if configurations are checked
 * @param {boolean} showDeviceWarnings determines if warnings are checked
 * @param {function} setShowDeviceAlarms determines if alarms should be checked/unchecked
 * @param {function} setShowDeviceConfigurations determines if alarms should be checked/unchecked
 * @param {function} setShowDeviceWarnings determines if warnings should be checked/unchecked
 */
export default function FullSizeMenu({className, showDeviceConfigurations, showDeviceAlarms,
                                         showDeviceWarnings, setShowDeviceConfigurations,
                                         setShowDeviceAlarms, setShowDeviceWarnings }) {
    const { t } = useTranslation(['dashboard'])
    return (
        <div className={className}>
            <span className=" text-bold col-11 p-3 mt-3">{t('dashboard:log.filter_dropdown.message_types')}</span>
            <LogSelection
                className="d-flex flex-wrap justify-content-center my-3"
                show={showDeviceConfigurations}
                setShow={setShowDeviceConfigurations}
                circleColor={' bg-color-telemetry-very-good'}
                headline={t('dashboard:log.filter_dropdown.events.headline')}
                text={t('dashboard:log.filter_dropdown.events.text')}
            />
            <LogSelection
                className="d-flex flex-wrap justify-content-center mb-3"
                show={showDeviceWarnings}
                setShow={setShowDeviceWarnings}
                circleColor={'bg-warning-dark'}
                headline={t('dashboard:log.filter_dropdown.warnings.headline')}
                text={t('dashboard:log.filter_dropdown.warnings.text')}
            />
            <LogSelection
                className="d-flex flex-wrap justify-content-center mb-3"
                show={showDeviceAlarms}
                setShow={setShowDeviceAlarms}
                circleColor={'bg-danger-dark'}
                headline={t('dashboard:log.filter_dropdown.alarms.headline')}
                text={t('dashboard:log.filter_dropdown.alarms.text')}
            />
        </div>
    )
}