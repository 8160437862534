import {useTranslation} from 'react-i18next';

export default function ClickableLegends({payload, toggleFilter, disabledFilters = {}}) {
    const {t} = useTranslation(['telemetry'])

    return <div className="d-block d-md-flex justify-content-center mt-2">
        {payload.map(({dataKey, color}) =>
            <span key={dataKey} className="text-center me-5 d-flex cursor-pointer"
                  onClick={() => toggleFilter(dataKey)}>
                        <div
                            className='position-relative align-self-center rounded-circle d-inline-block bg-transparent'
                            style={{
                                minHeight: "12px", minWidth: "12px", width: "12px", height: "12x",
                                border: "1px solid " + color
                            }}
                        >
                            {!disabledFilters[dataKey] &&
                                <div className='top-0 bottom-0 start-0 m-auto end-0 position-absolute rounded-circle'
                                     style={{
                                         minHeight: "6px", minWidth: "6px", width: "6px",
                                         height: "6px", backgroundColor: color
                                     }}/>}
                        </div>
                        <span className='ms-2' style={{color: color}}>{t(dataKey)}</span>
                    </span>
        )}
    </div>
}
