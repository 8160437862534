import React, {useEffect, useRef, useState} from 'react'
import TopInformation from '../../components/shared/topinformation/TopInformation.js'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import UpdateFirmwareTableBody from "../../components/firmware/devicefirmwaretable/UpdateFirmwareTableBody.js"
import {constructPath, findGroup, createPathStringFromArray} from "../../helpers/helperfunctions.js"
import {cloneDeep, difference, uniq} from "lodash"
import DeviceFirmwareTableFilterSearch
    from "../../components/firmware/devicefirmwaretable/DeviceFirmwareTableFilterSearch.js"
import {setFirmwareFilter} from "../../helpers/reduxstore/reducers/filterReducer.js"
import TFooter from "../../components/shared/tfooter/TFooter.js"
import ComboBox from "../../components/shared/combobox/ComboBox.js"
import {Button} from "react-bootstrap"
import InformationModal from "../../components/shared/informationmodal/InformationModal.js"
import {FirmwareNavBar} from "./FirmwareNavBar.js"
import useNavigationGuardByRole from "../../helpers/hooks/useNavigationGuardByRole.js"
import {roleConstants} from "../../helpers/constants.js"
import {saveFirmwareConfiguration} from "../../helpers/reduxstore/reducers/groupReducer.js"
import {getDevices, getFirmwareFilterOptions, getFirmwareList, queryDeviceListWithCount} from "../../api/api.js"
import useInterval from "../../helpers/hooks/useInterval.js"
import useSetLocation from "../../helpers/hooks/useSetLocation.js"

/** Renders firmware page */
export default function UpdateFirmwarePage() {
    useNavigationGuardByRole([roleConstants.Admin])
    useSetLocation()

    const {t} = useTranslation(['firmwarepage', 'common'])
    const dispatch = useDispatch()
    const [deviceListLoading, setDeviceListLoading] = useState(true)
    const [deviceListWithCheck, setDeviceListWithCheck] = useState([])
    const [checkedDeviceIdList, setCheckedDeviceIdList] = useState([]) // used to record checked rows
    const [firmwareListFilterOptions, setFirmwareListFilterOptions] = useState([{option: "Firmware version", id: ""}])
    const [deviceTypeFilterOptions, setDeviceTypeFilterOptions] = useState([{option: "Device model", id: ""}])
    const [firmwareListUpdateOptions, setFirmwareListUpdateOptions] = useState([{option: "Firmware version", id: ""}])
    const [updateFirmwareVersion, setUpdateFirmwareVersion] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const {firmwareFilter} = useSelector(state => state.filter)
    const {allowedGroups} = useSelector(state => state.group)
    const [firmwareList, setFirmwareList] = useState([])
    const [oldDeviceFamily, setOldDeviceFamily] = useState('je') // je or aware

    useEffect(() => {
        let firmwareListOptions = firmwareList.map(item => ({option: item.firmware_version, id: item.id}))
        firmwareListOptions.sort((a, b) => b.option.localeCompare(a.option))
        setFirmwareListUpdateOptions([{option: "Firmware version", id: ""}, ...firmwareListOptions])
    }, [firmwareList])

    const [deviceListWithCount, setDeviceListWithCount] = useState({
        count: 0,
        deviceList: [],
        aggregates: {
            alarm: '-', warning: '-', ok: '-', disconnected: '-'
        }
    })
    const deviceListWithCountAbortControllerRef = useRef(new AbortController())

    useEffect(() => {
        handleFirmwareFilterChange().then()
    }, [firmwareFilter])

    async function handleFirmwareFilterChange() {
        getFirmwareList(firmwareFilter?.deviceFamily ?? "je").then((res) => {
            setFirmwareList(res)
        })
        const filterOptions = await getFirmwareFilterOptions(firmwareFilter.filterGroups.map(g => g.id).join(","), firmwareFilter?.deviceFamily ?? "je")
        setFirmwareListFilterOptions([
            {option: "Firmware version", id: ""},
            ...filterOptions.firmwareVersion.filter(o => o).map(o => {
                return {option: o, id: o}
            })])
        await setDeviceTypeFilterOptions([{option: "Device model", id: ""},
            ...filterOptions.model.filter(o => o).map(o => {
                return {option: o, id: o}
            })])
        if (oldDeviceFamily !== firmwareFilter.deviceFamily) { // when family is changed, checked devices are reset
            setUpdateFirmwareVersion("")
            setCheckedDeviceIdList([])
        }
        setOldDeviceFamily(firmwareFilter.deviceFamily)

        setDeviceListLoading(true)
        if (deviceListWithCountAbortControllerRef.current && deviceListWithCountAbortControllerRef.current.signal)
            deviceListWithCountAbortControllerRef.current.abort("Query renewed")
        deviceListWithCountAbortControllerRef.current = new AbortController()
        dispatchQueryDevices().then()
    }

    useEffect(async () => {
        if (deviceListWithCount?.deviceList) {
            const newDeviceListWithCheck = cloneDeep(deviceListWithCount.deviceList).map(device => deviceWithPathAndCheck(device))
            for (const deviceId of checkedDeviceIdList) {
                const foundDevice = newDeviceListWithCheck.find(d => d.id === deviceId)
                if (foundDevice) foundDevice.checked = true
            }
            setDeviceListWithCheck(newDeviceListWithCheck)
        }
    }, [deviceListWithCount])

    // poll devices and update state if firmwareStatus has changed (or any new devices)
    //  We do not want to update layout all the time because it flickers when there are filters
    useInterval(async () => {
        const remoteDeviceList = await getDevices(deviceListWithCheck.map((d) => d.id))
        const hasChanged = deviceListWithCheck.some((orig) => {
            const remote = remoteDeviceList.find((remote) => remote.id === orig.id)
            if (!remote) return true
            return remote.device_twin.reported.firmwareStatus !== orig.device_twin.reported.firmwareStatus
        })
        if (hasChanged) {
            console.log("device list has changed!")
            dispatchQueryDevices().then()
        }
    }, 1000 * 15)

    async function dispatchQueryDevices() {
        const filter = {
            skip: (firmwareFilter.page - 1) * firmwareFilter.rowsPerPage,
            take: firmwareFilter.rowsPerPage,
            model: firmwareFilter.deviceTypeFilter,
            firmware_version: firmwareFilter.firmwareVersionFilter,
            search: encodeURIComponent(firmwareFilter.searchString ?? ''),
            groups: firmwareFilter.filterGroups.map(g => g.id).join(","),
            sort_direction: firmwareFilter?.deviceListSort?.sortDirection ?? "asc",
            sort_value: firmwareFilter?.deviceListSort?.sortValue ?? "serial_number",
            device_family: firmwareFilter?.deviceFamily ?? "je"
        }

        try {
            const res = await queryDeviceListWithCount(filter, deviceListWithCountAbortControllerRef.current.signal)
            if (res) {
                setDeviceListWithCount(res)
                setDeviceListLoading(false)
            }
        } catch (e) {
            if (e.message.includes("The user aborted a request")) {
                // The user aborted a request. Do nothing. It is logged in api.js. But do not turn off spinner.
            } else {
                setDeviceListLoading(false)
                throw e
            }
        }
    }

    function deviceWithPathAndCheck(device) {
        device.path = createPathStringFromArray(constructPath(findGroup(device.parent, allowedGroups), allowedGroups), device.name)
        device.checked = false
        return device
    }

    const sortHandler = (headerData) => {
        dispatch(setFirmwareFilter({
            deviceListSort: {
                sortDirection: headerData.sortValue === firmwareFilter?.deviceListSort?.sortValue && firmwareFilter?.deviceListSort?.sortDirection === 'asc' ? 'desc' : "asc",
                sortValue: headerData.sortValue
            }
        }))
    }

    const dispatchSetFirmwareRowsPerPage = (rowsPerPage) => {
        dispatch(setFirmwareFilter({rowsPerPage: rowsPerPage, page: 1}))
    }

    function toggleRowChecked(row, value) {
        const newDeviceList = cloneDeep(deviceListWithCheck)
        newDeviceList.find(d => d.id === row.id).checked = value
        setCheckedDeviceIdList(value ? [...checkedDeviceIdList, row.id] : checkedDeviceIdList.filter(id => id !== row.id))
        setDeviceListWithCheck(newDeviceList)
    }

    function toggleAllShownRows() {
        const newDeviceList = cloneDeep(deviceListWithCheck)
        const allChecked = deviceListWithCheck.every(d => d.checked)
        if (!allChecked) setCheckedDeviceIdList(uniq([...checkedDeviceIdList, ...newDeviceList.map(d => d.id)]))
        else setCheckedDeviceIdList(difference(checkedDeviceIdList, newDeviceList.map(d => d.id)))
        for (const device of newDeviceList) {
            device.checked = !allChecked
        }
        setDeviceListWithCheck(newDeviceList)
    }

    const commitChanges = async () => {
        setShowConfirmation(false)
        await dispatch(saveFirmwareConfiguration(updateFirmwareVersion, checkedDeviceIdList))
        setCheckedDeviceIdList([])
    }

    const setFilterGroups = (groupIdList) => {
        dispatch(setFirmwareFilter({
            filterGroups: groupIdList,
            firmwareVersionFilter: "",
            deviceTypeFilter: "",
            page: 1
        }))
    }

    /** @param {int} page */
    function setPage(page) {
        dispatch(setFirmwareFilter({page}))
    }

    return (
        <React.Fragment>
            <InformationModal show={showConfirmation} title={t('firmwarepage:modal.confirm.title')}
                              body={t('firmwarepage:modal.confirm.text', {
                                  count: checkedDeviceIdList.length,
                                  version: firmwareListUpdateOptions.find(f => f.id === updateFirmwareVersion)?.option
                              })} dismiss={() => setShowConfirmation(false)} accept={commitChanges}/>
            <div className="d-flex flex-column" style={{flex: '1'}}>

                <TopInformation title={t('firmwarepage:info_section.info_section_title')}
                                subTitle={t('firmwarepage:info_section.info_section_p')}/>

                <FirmwareNavBar currentPage={'update'}/>

                <DeviceFirmwareTableFilterSearch className="d-block d-md-flex pt-4 pb-2 flex-wrap"
                                                 filterGroups={firmwareFilter.filterGroups}
                                                 setFilterGroups={setFilterGroups}
                                                 firmwareListOptions={firmwareListFilterOptions}
                                                 firmwareVersionFilterValue={firmwareFilter.firmwareVersionFilter}
                                                 deviceTypeFilterValue={firmwareFilter.deviceTypeFilter}
                                                 deviceTypeFilterOptions={deviceTypeFilterOptions}
                                                 deviceFamily={firmwareFilter?.deviceFamily ?? "je"}
                />

                <div className={'mb-2'}>
                <span
                    className="text-nowrap small">{`${t('firmwarepage:updatetable.headers.total')} ${deviceListWithCount.count}`}</span>
                </div>

                <UpdateFirmwareTableBody className={'mb-2'}
                                         deviceList={deviceListWithCheck}
                                         deviceListLoading={deviceListLoading}
                                         sort={firmwareFilter.deviceListSort}
                                         sortHandler={sortHandler}
                                         toggleRowChecked={toggleRowChecked}
                                         toggleAllShownRows={toggleAllShownRows}/>
                <TFooter
                    className="mb-3"
                    setPage={setPage}
                    rowsPerPage={firmwareFilter.rowsPerPage}
                    setRowsPerPage={dispatchSetFirmwareRowsPerPage}
                    dataLength={deviceListWithCount.count}
                    page={firmwareFilter.page}/>

                <div className="d-flex pb-3 align-items-center">
                    <span className="pe-2 text-nowrap">Choose version:</span>
                    <ComboBox className={''} inputValue={(e) => setUpdateFirmwareVersion(e.target.value)}
                              value={updateFirmwareVersion} options={firmwareListUpdateOptions} hasLabel={false}/>

                    <Button className={'mx-2'} variant="primary" onClick={() => setShowConfirmation(true)}
                            disabled={updateFirmwareVersion === "" || checkedDeviceIdList.length === 0}
                            type="submit">{t('firmwarepage:buttons.update_devices', {count: checkedDeviceIdList.length})}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
