import {deviceParams, optionalValidationTypes} from "./constants.js";
import {objectEquals} from "./objectEquals.js";
import {paramSpec} from "../components/parameters/parameterSpec.js";
import {cloneDeep} from "lodash";
import {awareParamSpec} from "../components/parameters/awareParamSpec.js";

/** desired can be an empty object, which we need to check for
 * this only returns true if desired/reported both exists and is not equal
 * @param device
 * @param paramKey
 * @returns {boolean}
 */
function desiredExistsAndNotEqualToReported(device, paramKey) {
    let bothExists = device.device_twin.desired.parameters &&
        device.device_twin.desired.parameters[paramKey] !== undefined &&
        device.device_twin.reported.parameters[paramKey] !== undefined
    if (!bothExists) return false
    // the float comparison is fixed to 5 digits right now. We had some issues where very slight changes were sent to device
    //  for instance parameter 424 changed from 0.0200042725 to 0.020004273 where the device did not pick this up, possibly
    //  because the change was too small and some rounding happens on device, but it still was set as changed in portal because
    //  desired and reported values were different. I don't think this can cause trouble, but if it can then change it here.
    return isNaN(parseFloat(device.device_twin.desired.parameters[paramKey])) ?
        device.device_twin.desired.parameters[paramKey] !== device.device_twin.reported.parameters[paramKey] :
        parseFloat(device.device_twin.desired.parameters[paramKey]).toFixed(5) !== parseFloat(device.device_twin.reported.parameters[paramKey]).toFixed(5)
}

/**
 * If desired parameters exists and includes paramkey, use that value, otherwise show reported value
 * @param device
 * @param paramKey
 * @returns {*}
 */
function getValueToShow(device, paramKey) {
    if (device.device_twin.desired.parameters) {
        return device.device_twin.desired.parameters[paramKey] ?? device.device_twin.reported.parameters[paramKey]
    }
    return device.device_twin.reported.parameters[paramKey]
}

/**
 * show desired param if exists, otherwise reported
 * show warning if different
 * @param {object} device device to get parameter from
 * @param {string|int} paramKey key of parameter to get
 * @param {TFunction<string[], undefined>} t translation service
 * @returns {*}
 */
export const getParameterToShow = (device, paramKey, t) => {
    const messages = []
    if (device.device_twin.reported.parameters[paramKey] === undefined) {
        messages.push({type: "danger", text: "reported does not exist"})
        console.log(`%c=== DEV ERROR? reported.parameters does not exist for parameter ${paramKey}:`, "color:red;")
    }
    if (desiredExistsAndNotEqualToReported(device, paramKey)) {
        messages.push({type: "warning", text: t('settingspage:setpoints_settings.messages.waiting_for_update')})
        // console.log(`%c=== desiredExistsAndNotEqualToReported for ${paramKey}: ${device.device_twin.desired.parameters[deviceParams[paramKey]]} desired not equal to reported ${device.device_twin.reported.parameters[deviceParams[paramKey]]}`, "color:orangered;")
    }
    return {
        value: getValueToShow(device, paramKey),
        messages: messages
    }
}

/**
 * After getting new device, only update layout for field if new state is unequal to old state
 *  otherwise values could be overwritten while user is typing
 * @param setStateFunc
 * @param oldDevice
 * @param updatedDevice
 * @param paramKey
 * @param t
 */
export function updateFieldIfChanged(setStateFunc, oldDevice, updatedDevice, paramKey, t) {
    let oldObj = getParameterToShow(oldDevice, paramKey, t)
    let newObj = getParameterToShow(updatedDevice, paramKey, t)
    if (objectEquals(oldObj, newObj))
        return;
    setStateFunc(() => getParameterToShow(updatedDevice, paramKey, t))
}

/**
 * Validate a given parameter. calls setModel to update model. All validation for a single model basically have to be
 * done in one method, because setModel called after each other overwrites. So I send in an array of optional validation
 * objects, which can contain anythign. It is ugly, and I am very open to suggestions.
 *
 * @param {int} paramId parameter id
 * @param {object} model format: {value:any, messages:[{type:string, id: string, text: string}]}
 * @param {function(object)} setModel function to update model
 * @param {TFunction<string[], undefined>} t translation function
 * @param {object[]} optionalValidation format: [{type:string, ...}]
 * @param {object} dynamicParamSpec If parameter is from remote file, we want to use spec from this file
 * @returns {boolean} whether validated correct or not
 */
export function validateParameter(paramId, model, setModel, t, optionalValidation = [], dynamicParamSpec = null) {
    let validated = true
    const spec = dynamicParamSpec ? dynamicParamSpec[paramId] : paramSpec[paramId] ?? awareParamSpec[paramId]
    if (!spec) throw Error(`Could not find spec for param key ${paramId}`)
    const modelCopy = cloneDeep(model)
    modelCopy.messages = model.messages ? model.messages.filter(m => m.type !== "validation") : []

    // === Single model type specific validations
    if (spec.type === "string") return true
    if (spec.type === "bool") return true
    if (spec.type === "enum") return true
    if (spec.type === "int" || spec.type === "decimal") {
        const floatVal = parseFloat(model.value)
        if (isNaN(floatVal)) { // Is NaN if model.value was empty string
            if (!modelCopy.messages.find(m => m.id === "validation_error_empty_value"))
                modelCopy.messages.push({
                    type: "error",
                    id: "validation_error_empty_value",
                    text: t('settingspage:validation.value_cannot_be_empty')
                })
            validated = false
        } else {
            if (floatVal < spec.min) {
                if (!modelCopy.messages.find(m => m.id === "validation_error_more_than"))
                    modelCopy.messages.push({
                        type: "error",
                        id: "validation_error_more_than",
                        text: t('settingspage:validation.must_be_more_than_min', {min: spec.min})
                    })
                validated = false
            }
            if (floatVal > spec.max) {
                if (!modelCopy.messages.find(m => m.id === "validation_error_less_than"))
                    modelCopy.messages.push({
                        type: "error",
                        id: "validation_error_less_than",
                        text: t('settingspage:validation.must_be_more_than_max', {max: spec.max})
                    })
                validated = false
            }
        }
    } else {
        console.warn(`parameter of type ${spec.type} has no validation`)
    }

    // === Ekstra optional validations
    for (const o of optionalValidation) {
        if (o.type === optionalValidationTypes.leftLessThanRight && parseFloat(model.value) > parseFloat(o.rightModel.value)) {
            if (!modelCopy.messages.find(m => m.id === "validation_error_left_more_than_right"))
                modelCopy.messages.push({
                    type: "error", id: "validation_error_more_less_than_right",
                    text: t('settingspage:validation.left_cannot_be_more_than_right', {
                        left: model.value,
                        right: o.rightModel.value
                    })
                })
            validated = false
        }
    }

    setModel(modelCopy)
    return validated
}

/**
 * Only mark parameter for update if value is different from reported, or desired exists (which means you may be trying to change it back)
 * @param updatedParametersObj
 * @param paramId
 * @param newValue
 * @param device
 */
export function addParameterIfChanged(updatedParametersObj, paramId, newValue, device) {
    if (device.device_twin.desired.parameters && device.device_twin.desired.parameters[paramId] && parseFloat(device.device_twin.desired.parameters[paramId]) === parseFloat(newValue)) {
        // if desired exists and is same, this change is already queued
        return
    }

    // noinspection EqualityComparisonWithCoercionJS // implicit cast when equality checking is fine here
    if (device.device_twin.reported.parameters[paramId] != newValue ||
        (device.device_twin.desired.parameters && device.device_twin.desired.parameters[paramId] && parseFloat(device.device_twin.desired.parameters[paramId]) !== parseFloat(newValue))) {
        console.log(`has changed {paramKey: ${paramId}, oldReported: ${device.device_twin.reported.parameters[paramId]}, oldDesired: ${device.device_twin.desired.parameters[paramId]}, new: ${newValue}}`)
        updatedParametersObj[paramId] = newValue
    }
}

export const alarmsExist = (device) => {
    let deviceAlarms = Object.keys(device.system_alarm);
    return deviceAlarms.length > 0;
}

export const isBMSStartPriority = (device) => {
    const BMSStartValue = "2"
    if (device.device_twin.reported.parameters[deviceParams.priority_1] === BMSStartValue) return true
    if (device.device_twin.reported.parameters[deviceParams.priority_2] === BMSStartValue) return true
    if (device.device_twin.reported.parameters[deviceParams.priority_3] === BMSStartValue) return true
    if (device.device_twin.reported.parameters[deviceParams.priority_4] === BMSStartValue) return true
    if (device.device_twin.reported.parameters[deviceParams.priority_5] === BMSStartValue) return true
    if (device.device_twin.reported.parameters[deviceParams.priority_6] === BMSStartValue) return true
    if (device.device_twin.reported.parameters[deviceParams.priority_7] === BMSStartValue) return true
    if (device.device_twin.reported.parameters[deviceParams.priority_8] === BMSStartValue) return true
    console.log(`%c=== isBMSStartPriority: false`, "color:orange;")
    return false
}