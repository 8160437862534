/* eslint-disable no-undef */
/****** ENDPOINDS TO EXPOSE TO THE EXPRESS API *********/
export const API_ENDPOINTS = {
    API_BASE: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT,
    GET_USER: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/',
    EDIT_USER: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/edit/',
    GET_USERGROUPS: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/groups/list',
    UPDATE_EXTENSION: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/extension/update/',
    CREATE_EXTENSION: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/extension/create/',
    DELETE_EXTENSION: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/extension/delete/',
    GET_EXTENSION: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/extension/',
    GET_ADMINISTEREDUSERS: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/administeredusers',
    GET_ADMINISTEREDUSERS_LIST: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/user/administeredusers/list/',
    DELETE_ADMINISTERED_USER: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/administereduser/delete/',
    CREATE_ADMINISTERED_USER: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/administereduser/create/',
    EDIT_ADMINISTERED_USER: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/administereduser/edit/',
    INVITE_ADMINISTERED_USER: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/usermanagement/administereduser/invite/',
    EDIT_GROUP: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/projectmanagement/group/edit/',
    CREATE_GROUP: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/projectmanagement/group/create/',
    DELETE_GROUP: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/projectmanagement/group/delete/',
    DELETE_DEVICE: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/projectmanagement/device/delete/',
    EDIT_DEVICE: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/projectmanagement/device/edit/',
    EDIT_HIERARCHY: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/projectmanagement/hierarchy/edit/',
    GET_DEVICE_BY_SERIAL_NUMBER: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/api/device',
    LIST_DEVICES: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/api/device/list/',
    QUERY_DEVICES: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/api/device/query',
    NOTIFICATION_LIST: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/notification/list',
    NOTIFICATION: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/notification',
    NOTIFICATION_RUN_JOB: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/notification/job_run',
    DEVICECONFIGURATION_FIRMWARE: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/firmware',
    DEVICECONFIGURATION_FIRMWARE_LIST: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/firmware/list',
    DEVICECONFIGURATION_FIRMWARE_DOWNLOADFILE: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/firmware/downloadfile',
    DEVICECONFIGURATION_FIRMWARE_DOWNLOAD_PARAMETER_FILE_FOR_VERSION: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/firmware/download_parameter_file_for_version',
    DEVICECONFIGURATION_FIRMWARE_FILTER_OPTIONS: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/firmware/filteroptions',
    DEVICECONFIGURATION_FIRMWARE_CREATE: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/firmware/upload',
    DEVICECONFIGURATION_FIRMWARE_EDIT: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/firmware/edit',
    CONFIGURE_DEVICES: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/configure',
    TIMER_PERIODS: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/timerperiods',
    CONFIGURE_DEVICES_FIRMWARE: process.env.REACT_APP_AIRMASTERAPI_ENDPOINT + '/deviceconfiguration/configure/firmware'
}