import React from 'react'

/** Renders the informationbox with text that describes the context of individual pages
 * @param {JSX.Element | string} title of the TopInformation
 * @param {JSX.Element | string} subTitle subtitle of the TopInformation
 * @param {JSX.Element | string} subSubTitle subsubtitle of the TopInformation
 * @param {string} className classes added to the component
 */
export default function TopInformation({title, subTitle, subSubTitle, className = ""}) {
    return (
        <div id='top-information' className={className + ' pt-3 g-0'}>
            <div data-testid='title' className='title-font'>{title}</div>
            <div className={'mb-0'} data-testid='subTitle'>{subTitle}</div>
            {subSubTitle && <div className={'mb-0'} data-testid='subSubTitle'>{subSubTitle}</div>}
        </div>
    )
}