export default function AdminOperationsEmailFilterSearchBar({className, style, searchValue, performSearch}) {
    return (
        <div className={className} style={style}>
            <div className='ms-auto'>
                <input className="form-control shadow-none" value={searchValue}
                       onChange={e => performSearch(e.target.value)}
                       type="search" autoFocus placeholder={'Søg...'}/>
            </div>
        </div>
    )
}