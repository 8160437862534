import {useTranslation} from 'react-i18next'
import {Funnel} from 'react-bootstrap-icons'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import ChartSelectorDropdownItem from './dropdownitem/ChartSelectorDropdownItem.js'
import {cloneDeep} from "lodash";

/** Dropdown selector with graph options
 * @param {string} className classes to wrap the component
 * @param {string[]} graphOptions list of options that can be viewed in the dropdown
 * @param {string[]} graphSelections gets the graphs currently selected in the dropdown
 * @param {function} setGraphSelections sets the graphs currently selected in the dropdown
 */
export default function TelemetryChartsChartSelector({className, graphOptions, graphSelections, setGraphSelections}) {
    const {t} = useTranslation(['dashboard'])

    const addGraphSelection = () => {
        setGraphSelections([...graphSelections, "New"])
    }

    const removeGraphSelection = (idx) => {
        const clone = cloneDeep(graphSelections)
        clone.splice(idx, 1)
        setGraphSelections(clone)
    }

    const changeGraphSelection = ({idx, value}) => {
        const clone = cloneDeep(graphSelections)
        clone[idx] = value
        setGraphSelections(clone)
    }

    return (
        <div className={className}>
            <Dropdown className="text-end d-inline-block col-6">
                <Dropdown.Toggle type="button" id="dropdown-basic" className="" variant='outline-secondary'>
                    <Funnel className="me-1"/>{t('dashboard:dropDownChartSelector.toggle_text')}
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2 px-3 shadow " align="right" id="dropdown-menu-align-right"
                               style={{width: "300px"}}>
                    <span className=" text-bold col-12 py-2 d-block">{t('dashboard:dropDownChartSelector.title')}</span>

                    {graphSelections.map((graph, idx) => <ChartSelectorDropdownItem key={`${graph}_${idx}`} idx={idx}
                                                                                    onChange={changeGraphSelection}
                                                                                    removeSelection={removeGraphSelection}
                                                                                    id={graph}
                                                                                    options={graphOptions.map(e => ({
                                                                                        id: e,
                                                                                        option: t(`telemetry:graph_types.${e}`)
                                                                                    }))}
                                                                                    value={graph}
                                                                                    placeholder={t('dashboard:dropDownChartSelector.placeholder')}
                                                                                    className="my-2"/>)}

                    <Button disabled={graphSelections.length >= graphOptions.length} className="w-100 mt-2"
                            onClick={addGraphSelection} variant='outline-secondary' type="submit">
                        {t('dashboard:dropDownChartSelector.submit_button')}
                    </Button>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}