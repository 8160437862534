import { createSlice } from "@reduxjs/toolkit";
import { userObject, openExtensions, loaders } from "../../constants";
import { setAccountData, getOpenExtension, updateOpenExtension, createOpenExtension, getUserInformation } from "../../../api/api"
import {changeLanguage, getCurrentLanguage} from "../../i18nConfig.js";
import { removeLoading, setLoading, setLoadingError } from "./loadingReducer";


/***************** STATE ****************/
const initialState = {
    user: userObject,
    newsletterPref: false,
};

/************ STATE SLICE **************/

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload
        },
        setNewsletterPreferences(state, action) {
            state.newsletterPref = action.payload
        }
    }
})

/**** EXPORTED ACTIONS AND REDUCERS ****/

export default userSlice.reducer;
export const { setUser, setNewsletterPreferences } = userSlice.actions;

/*************** THUNKS ***************/

/** Gets the user-related data from the graph API
 * Populates object with default preferredLanguage 'en' if unset
 *
 * Yes, we set language twice. First time from cache, because otherwise the default language is shown
 * while user information is retrieved
 *
 * @returns dispatch - updates the user state
 */
export const requestUserInformation = () => {
    return async (dispatch) => {
        try {
            await changeLanguage(getCurrentLanguage())
            let result = await getUserInformation()
            await changeLanguage(result.preferredLanguage)
            dispatch(setUser(result))
        } catch (e) {
            console.error(e.message)
        }
    }
}
/** Gets the users' newsletter preferences from MS graph,
* which are stored in an open extension
* @returns dispatch - updates the fetched preferences
*/
export const requestNewsletterPreferences = () => {
    return async (dispatch) => {
        try {
            dispatch(setNewsletterPreferences(await getOpenExtension(openExtensions.newsletter)))
        } catch (e) {
            console.error(e.message)
        }
    }
}
/** Updates the user information in MSgraph
 * @param {object} data Object with updated user information
 * @returns dispatch - Updates the user state
 */
export const updateUserInformation = (data) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.updateUserInformation))
            let result = await setAccountData(data)
            await changeLanguage(result.preferredLanguage)
            dispatch(setUser(result))
            dispatch(removeLoading(loaders.updateUserInformation))
        } catch (error) {
            dispatch(removeLoading(loaders.updateUserInformation))
            dispatch(setLoadingError(loaders.updateUserInformation))
        }
    }
}
/** Updates or creates the open extension in graph
 * If the extension is not created, a 404 response will be returned, which then prompts for creation
 * @returns dispatch - updates the preferences if operation is ok
 */
export const updateNewsletterPreferences = (data) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.updateNewsletterPreferences))
            let result = await updateOpenExtension(data, openExtensions.newsletter)
            dispatch(setNewsletterPreferences(result))
            dispatch(removeLoading(loaders.updateNewsletterPreferences))
        } catch (error) {
            if (error.code === 404) {
                try {
                    dispatch(setNewsletterPreferences(await createOpenExtension(data, openExtensions.newsletter)))
                } catch (err) {
                    dispatch(setLoadingError(loaders.updateNewsletterPreferences))
                }
            } else {
                dispatch(setLoadingError(loaders.updateNewsletterPreferences))
            }
            dispatch(removeLoading(loaders.updateNewsletterPreferences))
        }
    }
}