import React from 'react'
import {Dropdown} from 'react-bootstrap'
import {Funnel} from 'react-bootstrap-icons'
import {useTranslation} from 'react-i18next'
import FromToDatePickers from '../../../shared/datepickers/FromToDatePickers.js'
import FullSizeMenu from './fullsizemenu/FullSizeMenu'
import MobileMenu from './mobilemenu/MobileMenu'

/** Dropdown list for the device log
 * @param {boolean} showDeviceAlarms determines if alarms are checked
 * @param {boolean} showDeviceConfigurations determines if configurations are checked
 * @param {boolean} showDeviceWarnings determines if warnings are checked
 * @param {function} setShowDeviceAlarms determines if alarms should be checked/unchecked
 * @param {function} setShowDeviceConfigurations determines if alarms should be checked/unchecked
 * @param {function} setShowDeviceWarnings determines if warnings should be checked/unchecked
 * @param {function} setToDate sets the to date in the filter
 * @param {function} setFromDate sets the from date in the filter
 * @returns
 */
export default function LogDropdown({
                                        showDeviceConfigurations, showDeviceAlarms,
                                        showDeviceWarnings, setShowDeviceAlarms,
                                        setShowDeviceConfigurations, setShowDeviceWarnings,
                                        setFromDate, setToDate,
                                        fromDateVal, toDateVal
                                    }) {
    const {t} = useTranslation(['dashboard'])
    return (
        <Dropdown>
            <Dropdown.Toggle type="button" id="dropdown-basic" variant='outline-secondary' >
                <Funnel className="me-1"/> {t('dashboard:log.filter')}
            </Dropdown.Toggle>
            <Dropdown.Menu className="pb-0 shadow w-xs-auto" align="right" id="dropdown-menu-align-right"
                           style={{width: "450px"}}>
                <FullSizeMenu
                    className='d-xs-none'
                    setShowDeviceAlarms={setShowDeviceAlarms}
                    setShowDeviceConfigurations={setShowDeviceConfigurations}
                    setShowDeviceWarnings={setShowDeviceWarnings}
                    showDeviceAlarms={showDeviceAlarms}
                    showDeviceConfigurations={showDeviceConfigurations}
                    showDeviceWarnings={showDeviceWarnings}
                />
                <MobileMenu
                    className="d-md-none d-sm-none p-2"
                    setShowDeviceAlarms={setShowDeviceAlarms}
                    setShowDeviceConfigurations={setShowDeviceConfigurations}
                    setShowDeviceWarnings={setShowDeviceWarnings}
                    showDeviceAlarms={showDeviceAlarms}
                    showDeviceConfigurations={showDeviceConfigurations}
                    showDeviceWarnings={showDeviceWarnings}
                />
                <FromToDatePickers
                    className='d-flex flex-wrap justify-content-center mb-4 px-3'
                    headline={t('dashboard:log.filter_dropdown.date_interval.headline')} monthSpan={6}
                    yearSpan={1} fromDate={fromDateVal} toDate={toDateVal} setFromDate={setFromDate}
                    setToDate={setToDate}
                />
            </Dropdown.Menu>
        </Dropdown>
    )
}