import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export function NoPageFoundPage() {
    const {t} = useTranslation(['common'])
    const navigate = useNavigate();

    return (
        <div className='d-flex justify-content-center'>
            <div style={{marginTop: '200px'}}>
                <h2>{t('common:pageNotFound.title')}</h2>
                <a style={{fontSize: '20px'}} href='' onClick={() => navigate("/")}>{t('common:pageNotFound.link')}</a>
            </div>
        </div>
    )
}