import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_APPLICATION_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT}`,
        knownAuthority: ["login.microsoftonline.com"], // Hopefully fixes 'Untrusted Authority Error' in Sentry
        redirectUri: process.env.REACT_APP_URI,
        postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                //PII = personally identifiable information
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        if (process.env.REACT_APP_DEBUG_AUTH_LOG === "true")
                            console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    },
}