import React from 'react'
import Form from 'react-bootstrap/Form'

/** Standard checkbox with a label
 * @param {string} className styles the entire group
 * @param {string} controlId clicking the label will toggle the checkbox too, similar to a 'for' attribute
 * @param {boolean} checked sets the state of the checkbox
 * @param {string} label sets the label 
 * @param {function(onChange)} onChange to forward the input from the checkbox on change
 * @param {string} name sets the name of the checkbox
 * @param {boolean} disabled disables the checkbox
 * @param {string} size controls the size of the checkbox (values: sm, md, lg)
 */
export default function CheckBox({className, controlId, checked, label, onChange, name, disabled, size = 'md'}) {
    return (
        <Form.Group className={className} controlId={controlId}>
            <Form.Check type="checkbox" size={size} checked={checked} label={label} onChange={onChange} name={name} disabled={disabled} />
        </Form.Group>
    )
}