import React from 'react'
import {Youtube} from 'react-bootstrap-icons'
import logo from '../../../images/svg/logos/RGB_Airmaster-white.svg'

/** Renders the footer bar in bottom of the screen */
export default function FooterBar() {
    function getVersion() {
        return process.env.REACT_APP_GIT_HASH !== "__GIT_HASH_REPLACE__"
            ? `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_GIT_HASH}`
            : process.env.REACT_APP_VERSION
    }

    return (
        <footer id='footer-bar' className='d-flex justify-content-between bg-primary shadow-none w-100 p-3 flex-xs-column'>
            <img src={logo} alt="Airmaster logo" className='footer-logo me-3'/>

            <div className='d-flex align-items-center'>
                <a className='d-inline-block footer-text me-4' href="tel:98624822">(+45) 9862 4822</a>
                <a className='d-inline-block footer-text me-4' href="mailto:info@airmaster.dk">info@airmaster.dk</a>
                <a className='d-inline-block footer-text' target='_blank' href="/airmaster-terms-and-conditions.pdf">Terms
                    and Conditions</a>
            </div>
            <div className='d-flex align-items-center'>
                <span style={{color: '#5c6b7b'}} className="me-3 d-xs-none">{getVersion()}</span>
                <a className="btn btn-danger btn-rounded btn-icon mt-1"
                   href='https://www.youtube.com/user/AirmasterAS/featured'><Youtube/></a>
                <a className="btn btn-primary btn-rounded btn-icon mt-1 ms-2"
                   href='https://www.linkedin.com/company/airmaster-a-s'>
                    <svg aria-hidden="true" data-prefix="fab" data-icon="linkedin-in"
                         className="svg-inline--fa fa-linkedin-in fa-w-14" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="-150 -90 750 700">
                        <path fill="currentColor"
                              d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                    </svg>
                </a>
            </div>
        </footer>
    )
}
