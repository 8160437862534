import {useNavigate} from "react-router-dom";
import React from "react";

/** A wrapper for a simple navigation div, so we don't use useNavigate in NavigationBar.js
 * @param dataTestId
 * @param to
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
export function NavigateToDiv({dataTestId, to, text}) {
    const navigate = useNavigate();
    return <div data-testid={dataTestId} onClick={() => navigate(to)}>{text}</div>
}