import {paramNames} from "../../../parameters/parameterSpec.js";
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from "react-bootstrap";
import ComboBoxWithMessages from "../../../shared/comboboxwithmessages/ComboBoxWithMessages.js";
import {addParameterIfChanged, getParameterToShow, updateFieldIfChanged} from "../../../../helpers/settingsHelper.js";
import {cloneDeep} from "lodash";
import {InfoCircleFill} from 'react-bootstrap-icons';
import PopOverDialog from "../../../shared/popoverdialog/PopOverDialog.js";
import {deviceParams, toastTypes} from "../../../../helpers/constants.js";
import {addToast} from "../../../../helpers/reduxstore/reducers/toastReducer.js";
import {useDispatch} from "react-redux";
import {useMsal} from "@azure/msal-react";
import {isAdmin} from "../../../../helpers/authHelper.js";
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";

/** Displays the configuration page for setpoints settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device)
 */
export default function JEStartPrioritySettings({device, className, save}) {
    const {t} = useTranslation(['settingspage', 'common'])
    const dispatch = useDispatch()
    const {accounts} = useMsal()

    const [oldDeviceState, setOldDeviceState] = useState(cloneDeep(device))
    const [priority1, setPriority1] = useState(getParameterToShow(device, paramNames.start_priority_1, t))
    const [priority2, setPriority2] = useState(getParameterToShow(device, paramNames.start_priority_2, t))
    const [priority3, setPriority3] = useState(getParameterToShow(device, paramNames.start_priority_3, t))
    const [priority4, setPriority4] = useState(getParameterToShow(device, paramNames.start_priority_4, t))
    const [priority5, setPriority5] = useState(getParameterToShow(device, paramNames.start_priority_5, t))
    const [priority6, setPriority6] = useState(getParameterToShow(device, paramNames.start_priority_6, t))
    const [priority7, setPriority7] = useState(getParameterToShow(device, paramNames.start_priority_7, t))
    const [priority8, setPriority8] = useState(getParameterToShow(device, paramNames.start_priority_8, t))
    const [startDependency, setStartDependency] = useState(getParameterToShow(device, paramNames.start_dependency, t))
    const [nightCoolIndependent, setNightCoolIndependent] = useState(getParameterToShow(device, paramNames.night_cool_independent, t))

    useEffect(() => {
        // When device is updated after write, update parameters to show / hide messages
        updateFieldIfChanged(setPriority1, oldDeviceState, device, paramNames.start_priority_1, t)
        updateFieldIfChanged(setPriority2, oldDeviceState, device, paramNames.start_priority_2, t)
        updateFieldIfChanged(setPriority3, oldDeviceState, device, paramNames.start_priority_3, t)
        updateFieldIfChanged(setPriority4, oldDeviceState, device, paramNames.start_priority_4, t)
        updateFieldIfChanged(setPriority5, oldDeviceState, device, paramNames.start_priority_5, t)
        updateFieldIfChanged(setPriority6, oldDeviceState, device, paramNames.start_priority_6, t)
        updateFieldIfChanged(setPriority7, oldDeviceState, device, paramNames.start_priority_7, t)
        updateFieldIfChanged(setPriority8, oldDeviceState, device, paramNames.start_priority_8, t)
        updateFieldIfChanged(setStartDependency, oldDeviceState, device, paramNames.start_dependency, t)
        updateFieldIfChanged(setNightCoolIndependent, oldDeviceState, device, paramNames.night_cool_independent, t)

        setOldDeviceState(cloneDeep(device))
    }, [device])


    const getValuesToSave = () => {
        const updated_parameters = {}
        addParameterIfChanged(updated_parameters, paramNames.start_priority_1, priority1.value, device)
        addParameterIfChanged(updated_parameters, paramNames.start_priority_2, priority2.value, device)
        addParameterIfChanged(updated_parameters, paramNames.start_priority_3, priority3.value, device)
        addParameterIfChanged(updated_parameters, paramNames.start_priority_4, priority4.value, device)
        addParameterIfChanged(updated_parameters, paramNames.start_priority_5, priority5.value, device)
        addParameterIfChanged(updated_parameters, paramNames.start_priority_6, priority6.value, device)
        addParameterIfChanged(updated_parameters, paramNames.start_priority_7, priority7.value, device)
        addParameterIfChanged(updated_parameters, paramNames.start_priority_8, priority8.value, device)
        addParameterIfChanged(updated_parameters, deviceParams.start_dependency, startDependency.value, device)
        addParameterIfChanged(updated_parameters, deviceParams.night_cool_independent, nightCoolIndependent.value, device)
        console.log(updated_parameters)
        return updated_parameters
    }

    function saveIfChanged(saveForGroup) {
        let valuesToSave = getValuesToSave();
        if (Object.keys(valuesToSave).length === 0) {
            dispatch(addToast({
                id: "StartPrioritySettingsInfoToast",
                type: toastTypes.info,
                title: "No changes",
                body: "No changes to parameters",
                timeSeconds: 3
            }))
            return
        }
        save(valuesToSave, saveForGroup);
    }

    let startPriorityOptions = [
        {id: 0, option: t('settingspage:start_priorities.options.None')},
        {id: 1, option: t('settingspage:start_priorities.options.ExternalStart')},
        {id: 2, option: t('settingspage:start_priorities.options.BMS')},
        {id: 3, option: t('settingspage:start_priorities.options.PIR')},
        {id: 4, option: t('settingspage:start_priorities.options.0-10VFlow')},
        {id: 5, option: t('settingspage:start_priorities.options.Panel')},
        {id: 6, option: t('settingspage:start_priorities.options.CO2')},
        {id: 7, option: t('settingspage:start_priorities.options.Timer')},
        {id: 8, option: t('settingspage:start_priorities.options.TVOC')}
    ];

    //start prioritet room temp is only available from parameter file version 1143
    if(device.device_twin.reported.parameterVersion >= "1143") startPriorityOptions.push({id: 9, option: t('settingspage:start_priorities.options.RoomTemperature')})

    const setPriorityText = (optionVal, num) => {
        if (startPriorityOptions[optionVal] === undefined) return Array(2).fill('\xa0').join('') + t('settingspage:priority') + " " + num
        let text = startPriorityOptions[optionVal].option
        let textLength = text.length
        if(textLength<9) {
            return Array(10-textLength).fill('\xa0').join('') + text
        }
        if(textLength > 13) {
            return text.substring(0, 13)
        }
        return text
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation title={t('settingspage:start_priorities.title')}
                            subTitle={t('settingspage:start_priorities.description')}/>

            <div className='my-2'>
                <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 1920 300">
                    <g id="XMLID_00000140004829285056309110000005818014305993834939_">
                        <g>
                            <rect x="280" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.9762 269.6432)" className="st0" width="90" height="90"/>
                        </g>
                        <g>
                            <rect x="280" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.9762 269.6432)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="282.77" y="124.87" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 272.7661 141.9121)" className="st3 st4 st5">{setPriorityText(priority2.value, 2)}</text>
                    <g id="XMLID_00000158024211133107610150000017538825387067882414_">
                        <g>
                            <rect x="476.3" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 56.5187 408.4482)" className="st0" width="90" height="90"/>
                        </g>
                        <g>
                            <rect x="476.3" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 56.5187 408.4482)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="479.06" y="124.87" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 469.062 141.9121)" className="st3 st4 st5">{setPriorityText(priority3.value, 3)}</text>
                    <g id="XMLID_00000166633913670454351680000007580491619856234932_">
                        <g>

                            <rect x="673.29" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 114.2158 547.7412)" className="st0" width="90" height="90"/>
                        </g>
                        <g>

                            <rect x="673.29" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 114.2158 547.7412)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="676.06" y="124.87" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 666.0598 141.9121)" className="st3 st4 st5">{setPriorityText(priority4.value, 4)}</text>
                    <g id="XMLID_00000052076069525976030150000017037658662367250306_">
                        <g>
                            <rect x="74.87" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -61.0574 124.5944)" className="st0" width="90" height="90"/>
                        </g>
                        <g>
                            <rect x="74.87" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -61.0574 124.5944)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="77.64" y="124.87" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 67.6384 141.9121)" className="st3 st4 st5" >{setPriorityText(priority1.value, 1)}</text>
                    <g id="XMLID_00000044154321715420770190000003574818220416022179_">
                        <g>

                            <rect x="867.87" y="90.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 171.3413 685.2744)" className="st0" width="90" height="90"/>
                        </g>
                        <g>

                            <rect x="867.87" y="90.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 171.3413 685.2744)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="870.64" y="124.68" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 860.6384 141.7206)" className="st3 st4 st5">{setPriorityText(priority5.value, 5)}</text>
                    <g id="XMLID_00000161627246749370327450000001782566866946761370_">
                        <g>

                            <rect x="1065.87" y="90.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 229.3341 825.2816)" className="st0" width="90" height="90"/>
                        </g>
                        <g>

                            <rect x="1065.87" y="90.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 229.3341 825.2816)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="1068.64" y="124.68" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 1058.6384 141.7206)" className="st3 st4 st5">{setPriorityText(priority6.value, 6)}</text>
                    <g id="XMLID_00000045579692706316736120000014694065808203509908_">
                        <g>

                            <rect x="1250.87" y="90.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 283.5194 956.0963)" className="st0" width="90" height="90"/>
                        </g>
                        <g>

                            <rect x="1250.87" y="90.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 283.5194 956.0963)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="1253.64" y="124.68" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 1240.6375 141.7191)" className="st3 st4 st5">{setPriorityText(priority7.value, 7)}</text>
                    <g id="XMLID_00000017476162944269356740000013736293588186279357_">
                        <g>

                            <rect x="1444.87" y="91.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 339.6335 1093.5679)" className="st0" width="90" height="90"/>
                        </g>
                        <g>

                            <rect x="1444.87" y="91.81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 339.6335 1093.5679)" className="st1" width="90" height="90"/>
                        </g>
                    </g>
                    <rect x="1447.64" y="125.68" className="st2" width="97.23" height="22.13"/>
                    <text transform="matrix(1 0 0 1 1437.6375 142.7191)" className="st3 st4 st5">{setPriorityText(priority8.value, 8)}</text>
                    <rect x="1005.51" y="271.38" className="st2" width="88.3" height="31.91"/>
                    <g>
                        <g>
                            <line className="st1" x1="184" y1="136.5" x2="186.5" y2="136.5"/>
                            <line className="st6" x1="191.37" y1="136.5" x2="257.07" y2="136.5"/>
                            <line className="st1" x1="259.5" y1="136.5" x2="262" y2="136.5"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="388.5" y1="135.5" x2="391" y2="135.5"/>
                            <line className="st7" x1="395.92" y1="135.5" x2="452.54" y2="135.5"/>
                            <line className="st1" x1="455" y1="135.5" x2="457.5" y2="135.5"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="584.5" y1="135.5" x2="587" y2="135.5"/>
                            <line className="st8" x1="592" y1="135.5" x2="649.5" y2="135.5"/>
                            <line className="st1" x1="652" y1="135.5" x2="654.5" y2="135.5"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="781.5" y1="135.5" x2="784" y2="135.5"/>
                            <line className="st9" x1="788.85" y1="135.5" x2="844.58" y2="135.5"/>
                            <line className="st1" x1="847" y1="135.5" x2="849.5" y2="135.5"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="976.5" y1="135.5" x2="979" y2="135.5"/>
                            <line className="st10" x1="984.08" y1="135.5" x2="1042.46" y2="135.5"/>
                            <line className="st1" x1="1045" y1="135.5" x2="1047.5" y2="135.5"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="1174.5" y1="135.5" x2="1177" y2="135.5"/>
                            <line className="st11" x1="1181.82" y1="135.5" x2="1227.59" y2="135.5"/>
                            <line className="st1" x1="1230" y1="135.5" x2="1232.5" y2="135.5"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="1359.5" y1="135.5" x2="1362" y2="135.54"/>
                            <line className="st12" x1="1366.98" y1="135.61" x2="1414.33" y2="136.32"/>
                            <line className="st1" x1="1416.82" y1="136.36" x2="1419.32" y2="136.39"/>
                            <g>
                                <polygon points="1417.79,141.36 1426.5,136.5 1417.94,131.39"/>
                            </g>
                        </g>
                    </g>
                    <rect x="207.11" y="112.72" className="st2" width="31.89" height="26.28"/>
                    <text transform="matrix(1 0 0 1 207.1064 129.7637)" className="st4 st5">No</text>
                    <rect x="409.85" y="112.72" className="st2" width="31.89" height="26.28"/>
                    <text transform="matrix(1 0 0 1 409.8456 129.7248)" className="st4 st5">No</text>
                    <rect x="603.85" y="112.72" className="st2" width="31.89" height="26.28"/>
                    <text transform="matrix(1 0 0 1 603.8456 128.725)" className="st4 st5">No</text>
                    <rect x="799.85" y="112.72" className="st2" width="31.89" height="26.28"/>
                    <text transform="matrix(1 0 0 1 799.8455 128.7247)" className="st4 st5">No</text>
                    <rect x="997.85" y="112.72" className="st2" width="31.89" height="28.04"/>
                    <text transform="matrix(1 0 0 1 997.8455 129.0012)" className="st4 st5">No</text>
                    <rect x="1187.85" y="112.72" className="st2" width="31.89" height="28.04"/>
                    <text transform="matrix(1 0 0 1 1187.8455 128.725)" className="st4 st5">No</text>
                    <path className="st13" d="M1707.5,200h-4c-33.41,0-60.5-27.09-60.5-60.5v-4c0-33.41,27.09-60.5,60.5-60.5h4
                            c33.41,0,60.5,27.09,60.5,60.5v4C1768,172.91,1740.91,200,1707.5,200z"/>
                    <line className="st2" x1="1704" y1="72" x2="1704" y2="22"/>
                    <g>
                        <g>
                            <line className="st1" x1="120" y1="29.5" x2="122.5" y2="29.49"/>
                            <line className="st8" x1="127.5" y1="29.48" x2="1485" y2="26.51"/>
                            <line className="st1" x1="1487.5" y1="26.51" x2="1490" y2="26.5"/>
                        </g>
                    </g>
                    <path className="st1" d="M1704.5,26.5"/>
                    <path className="st1" d="M1703.5,77.5"/>
                    <g>
                        <g>
                            <line className="st1" x1="119.67" y1="65.33" x2="119.72" y2="62.83"/>
                            <line className="st14" x1="119.83" y1="58.42" x2="120.39" y2="34.2"/>
                            <line className="st1" x1="120.44" y1="32" x2="120.5" y2="29.5"/>
                            <g>
                                <polygon points="124.69,63.98 119.5,72.5 114.72,63.75"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="119.5" y1="242.18" x2="119.56" y2="239.68"/>
                            <line className="st15" x1="119.69" y1="234.25" x2="120.38" y2="204.39"/>
                            <line className="st1" x1="120.44" y1="201.68" x2="120.5" y2="199.18"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="1489.5" y1="73.5" x2="1489.55" y2="71"/>
                            <line className="st16" x1="1489.65" y1="66.33" x2="1490.4" y2="31.33"/>
                            <line className="st1" x1="1490.45" y1="29" x2="1490.5" y2="26.5"/>
                        </g>
                    </g>
                    <rect x="1449.23" y="33.68" className="st2" width="31.89" height="26.28"/>
                    <text transform="matrix(1 0 0 1 1449.2285 50.7247)" className="st4 st5">No</text>
                    <rect x="1377.23" y="110.68" className="st2" width="31.89" height="28.04"/>
                    <text transform="matrix(1 0 0 1 1377.2283 127.7247)" className="st4 st5">No</text>
                    <g>
                        <g>
                            <line className="st1" x1="119.5" y1="242.5" x2="122" y2="242.5"/>
                            <line className="st17" x1="126.99" y1="242.49" x2="1700.51" y2="240.51"/>
                            <line className="st1" x1="1703" y1="240.5" x2="1705.5" y2="240.5"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="1704.2" y1="242.18" x2="1704.26" y2="239.68"/>
                            <line className="st14" x1="1704.36" y1="235.28" x2="1704.93" y2="211.06"/>
                            <line className="st1" x1="1704.98" y1="208.85" x2="1705.04" y2="206.36"/>
                            <g>
                                <polygon points="1709.99,207.93 1705.2,199.18 1700.02,207.7 			"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="324.2" y1="242.18" x2="324.26" y2="239.68"/>
                            <line className="st15" x1="324.39" y1="234.25" x2="325.08" y2="204.39"/>
                            <line className="st1" x1="325.14" y1="201.68" x2="325.2" y2="199.18"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="521.2" y1="242.18" x2="521.26" y2="239.68"/>
                            <line className="st15" x1="521.39" y1="234.25" x2="522.08" y2="204.39"/>
                            <line className="st1" x1="522.14" y1="201.68" x2="522.2" y2="199.18"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="718.2" y1="241.18" x2="718.26" y2="238.68"/>
                            <line className="st15" x1="718.39" y1="233.25" x2="719.08" y2="203.39"/>
                            <line className="st1" x1="719.14" y1="200.68" x2="719.2" y2="198.18"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="912.2" y1="241.18" x2="912.26" y2="238.68"/>
                            <line className="st15" x1="912.39" y1="233.25" x2="913.08" y2="203.39"/>
                            <line className="st1" x1="913.14" y1="200.68" x2="913.2" y2="198.18"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="1110.2" y1="241.18" x2="1110.26" y2="238.68"/>
                            <line className="st15" x1="1110.39" y1="233.25" x2="1111.08" y2="203.39"/>
                            <line className="st1" x1="1111.14" y1="200.68" x2="1111.2" y2="198.18"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="1295.2" y1="241.18" x2="1295.26" y2="238.68"/>
                            <line className="st15" x1="1295.39" y1="233.25" x2="1296.08" y2="203.39"/>
                            <line className="st1" x1="1296.14" y1="200.68" x2="1296.2" y2="198.18"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st1" x1="1489.2" y1="241.18" x2="1489.26" y2="238.68"/>
                            <line className="st15" x1="1489.39" y1="233.25" x2="1490.08" y2="203.39"/>
                            <line className="st1" x1="1490.14" y1="200.68" x2="1490.2" y2="198.18"/>
                        </g>
                    </g>
                    <rect x="1652.57" y="127.26" className="st2" width="125.43" height="22.74"/>
                    <text transform="matrix(1 0 0 1 1652.5747 142.165)" className="st18 st4 st19">Device start</text>
                    <rect x="1583.11" y="219.02" className="st2" width="35.89" height="20.98"/>
                    <text transform="matrix(1 0 0 1 1583.1064 236.0615)" className="st4 st5">Yes</text>
                    <rect x="889.55" y="445.85" className="st2" width="2.13" height="0"/>
                    <rect x="1377.47" y="218.93" className="st2" width="35.89" height="20.98"/>
                    <text transform="matrix(1 0 0 1 1377.4712 235.9748)" className="st4 st5">Yes</text>
                    <rect x="1188.58" y="220.68" className="st2" width="35.89" height="20.98"/>
                    <text transform="matrix(1 0 0 1 1188.5815 237.725)" className="st4 st5">Yes</text>
                    <rect x="997.58" y="220.68" className="st2" width="35.89" height="20.98"/>
                    <text transform="matrix(1 0 0 1 997.5815 237.725)" className="st4 st5">Yes</text>
                    <rect x="800.58" y="219.68" className="st2" width="35.89" height="20.98"/>
                    <text transform="matrix(1 0 0 1 800.5815 236.725)" className="st4 st5">Yes</text>
                    <rect x="605.58" y="220.68" className="st2" width="35.89" height="20.98"/>
                    <text transform="matrix(1 0 0 1 605.5815 237.725)" className="st4 st5">Yes</text>
                    <rect x="404" y="219.68" className="st2" width="34" height="20.98"/>
                    <text transform="matrix(1 0 0 1 404 236.724)" className="st4 st5">Yes</text>
                    <rect x="206.58" y="219.68" className="st2" width="35.89" height="20.98"/>
                    <text transform="matrix(1 0 0 1 206.5815 236.725)" className="st4 st5">Yes</text>
                </svg>


                <div className="mt-sm-3 d-flex flex-wrap">
                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 1'}
                            inputValue={(e) => setPriority1({value: e.target.value})}
                            value={priority1.value}
                            disabled={device.slave || !isAdmin(accounts)}
                            options={startPriorityOptions}
                            messages={priority1.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 2'}
                            inputValue={(e) => setPriority2({value: e.target.value})}
                            value={priority2.value}
                            disabled={device.slave}
                            options={startPriorityOptions}
                            messages={priority2.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 3'}
                            inputValue={(e) => setPriority3({value: e.target.value})}
                            value={priority3.value}
                            disabled={device.slave}
                            options={startPriorityOptions}
                            messages={priority3.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 4'}
                            inputValue={(e) => setPriority4({value: e.target.value})}
                            value={priority4.value}
                            disabled={device.slave}
                            options={startPriorityOptions}
                            messages={priority4.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 5'}
                            inputValue={(e) => setPriority5({value: e.target.value})}
                            value={priority5.value}
                            disabled={device.slave}
                            options={startPriorityOptions}
                            messages={priority5.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 6'}
                            inputValue={(e) => setPriority6({value: e.target.value})}
                            value={priority6.value}
                            disabled={device.slave}
                            options={startPriorityOptions}
                            messages={priority6.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 7'}
                            inputValue={(e) => setPriority7({value: e.target.value})}
                            value={priority7.value}
                            disabled={device.slave}
                            options={startPriorityOptions}
                            messages={priority7.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.priority') + ' 8'}
                            inputValue={(e) => setPriority8({value: e.target.value})}
                            value={priority8.value}
                            disabled={device.slave}
                            options={startPriorityOptions}
                            messages={priority8.messages}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.start_dependency')}
                            inputValue={(e) => setStartDependency({value: e.target.value})}
                            value={startDependency.value}
                            disabled={device.slave}
                            options={[
                                {id: 0, option: t('settingspage:parameters.start_dependency_options.independent')},
                                {id: 1, option: t('settingspage:parameters.start_dependency_options.dependent')}
                            ]}
                            messages={startDependency.messages}
                            infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.start_dependency')}
                                                        popOverContent={t('settingspage:infomodals.start_dependency')}
                                                        trigger={['hover', 'touch']} contentAsHtml={true} size={'lg'}/>}
                        />
                    </div>

                    <div className='w-300px'>
                        <ComboBoxWithMessages
                            className='p-4 m-auto'
                            label={t('settingspage:parameters.night_cool_independent')}
                            inputValue={(e) => setNightCoolIndependent({value: e.target.value})}
                            value={nightCoolIndependent.value}
                            disabled={device.slave}
                            options={[
                                {id: 0, option: t('common:general.no')},
                                {id: 1, option: t('common:general.yes')}
                            ]}
                            messages={nightCoolIndependent.messages}
                        />
                    </div>
                </div>

                <div className="alert alert-info" hidden={!device.slave}>
                    <InfoCircleFill className='me-1 mb-1 text-primary' />{t('settingspage:slave-device-settings')}
                    {device.master_serial_number && <a href={"/device/"+device.master_serial_number+"/configurations/startpriority"}>{device.master_serial_number}</a>}
                </div>

            </div>

            {/*=== Button container*/}
            <div className='d-inline-block float-end p-2'>
                <Button variant='outline-secondary' onClick={() => saveIfChanged(false)} disabled={device.slave} className='me-2'>
                    {t('settingspage:save_device')}</Button>
                <Button variant='outline-secondary' onClick={() => saveIfChanged(true)} disabled={device.slave}>
                    {t('settingspage:save_group')}</Button>
            </div>
        </div>
    )
}