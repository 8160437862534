import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import InputTextArea from '../../../components/shared/inputtextarea/InputTextArea'
import {deviceSerialNumberValidator, stringValidator} from '../../../helpers/helperfunctions'
import {
    deleteDeviceFromHierarchy,
    updateDeviceInformation,
    updateDeviceSerialNumberInformation,
    updateGroupInformation
} from '../../../helpers/reduxstore/reducers/groupReducer'
import InputGroup from '../../../components/shared/inputgroup/InputGroup.js'
import {useMsal} from "@azure/msal-react"
import {isAdmin, isViewer} from "../../../helpers/authHelper.js"
import InformationModal from '../../../components/shared/informationmodal/InformationModal'
import GroupDetailButtonGroup from "./GroupDetailButtonGroup.js"
import {Button} from "react-bootstrap";
import {Pencil} from "react-bootstrap-icons";

/** Renders a component with functionality to edit a device or group. The component provides errorhandling for duplicate group - and device-names on the same hierarchy level.
 * @param {Object} groupData Object with group data
 * @param {string} groupPath String of selected group path
 * @param {[]} groupSiblings Array with group siblings in the group
 * @param {[]} deviceSiblings Array with device siblings in the group
 * @param {boolean} isEditingDisabled Disables the edit button
 */
export default function GroupDetailBox({
                                           groupData,
                                           groupPath,
                                           groupSiblings,
                                           deviceSiblings,
                                           isEditingDisabled = false
                                       }) {
    const {accounts} = useMsal()
    const {t} = useTranslation(['common', 'grouppage', 'inputerrors'])
    const [selectedItem, setSelectedItem] = useState({type: "", description: "", name: "", serial_number: ""})
    const [groupName, setGroupName] = useState("")
    const [groupDescription, setGroupDescription] = useState("")
    const [deviceSerialNumber, setDeviceSerialNumber] = useState("")
    const [siblingGroups, setSiblingGroups] = useState([])
    const [siblingDevices, setSiblingDevices] = useState([])
    const [editing, setEditing] = useState(false)
    const [nameError, setNameError] = useState("")
    const [deviceSerialNumberError, setDeviceSerialNumberError] = useState("")
    const [disableEditing, setDisableEditing] = useState(false)
    const [path, setPath] = useState("")
    const [deleteDeviceModalVisibility, setDeleteDeviceModalVisibility] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        setSelectedItem(groupData)
        setPath(groupPath)
        setGroupName(groupData.name)
        setSiblingGroups(groupSiblings)
        setSiblingDevices(deviceSiblings)
        setGroupDescription(groupData.description)
        if (groupData.type === 'device') setDeviceSerialNumber(groupData.serial_number)

    }, [groupData, groupPath, groupSiblings, deviceSiblings])

    useEffect(() => {
        setDisableEditing(isEditingDisabled)
        if (!isEditingDisabled) {
            handleCancel()
        }
    }, [isEditingDisabled])

    const handleSubmit = (e) => {
        e.preventDefault()
        let validationResult = checkSiblingSameName(reduceSiblings(siblingGroups.concat(siblingDevices)))
        setNameError(validationResult)
        if (!validationResult) {
            if (groupData.name !== groupName || groupData.description !== groupDescription || (groupData.type === 'device' && groupData.serial_number !== deviceSerialNumber)) {
                if (groupData.type === 'group') {
                    dispatch(updateGroupInformation({
                        id: groupData.id,
                        name: groupName,
                        description: groupDescription,
                        type: groupData.type
                    }))
                } else if (groupData.type === 'device') {
                    let validationSerialNumberResult = deviceSerialNumberValidator(deviceSerialNumber)
                    setDeviceSerialNumberError(validationSerialNumberResult)

                    if (!validationSerialNumberResult) {
                        setDeviceSerialNumberError("")
                        if (groupData.serial_number !== deviceSerialNumber) {
                            dispatch(updateDeviceSerialNumberInformation({
                                id: groupData.id,
                                name: groupName,
                                description: groupDescription,
                                serialNumber: deviceSerialNumber,
                                type: groupData.type
                            }))
                        } else {
                            dispatch(updateDeviceInformation({
                                id: groupData.id,
                                name: groupName,
                                description: groupDescription,
                                type: groupData.type
                            }))
                        }
                    }
                }
            }
            setNameError("")
            setEditing(!editing)
        }

    }

    const showDeleteDeviceModal = (e) => {
        e.preventDefault()
        setDeleteDeviceModalVisibility(true)
    }

    const deleteDevice = (e) => {
        e.preventDefault()
        if (groupData.type === 'device') {
            dispatch(deleteDeviceFromHierarchy(groupData.id))
        }
        setDeleteDeviceModalVisibility(false)
    }

    const reduceSiblings = (siblings) => {
        return siblings.map(obj => {
            return obj.name
        })
    }

    const checkSiblingSameName = (concatSiblings) => {
        let isEmpty = stringValidator(groupName)
        if (isEmpty) {
            return isEmpty
        }
        for (let value of concatSiblings) {
            if (value === groupName) {
                return t('inputerrors:name_error')
            }
        }
    }

    const handleCancel = () => {
        setNameError("")
        setDeviceSerialNumberError("")
        setGroupDescription(selectedItem.description)
        setGroupName(selectedItem.name)
        if (selectedItem.type === 'device') {
            setDeviceSerialNumber(selectedItem.serial_number)
        }
        setEditing(false)
    }
    return (
        <div className='pt-4'>
            <InformationModal show={deleteDeviceModalVisibility} accept={deleteDevice}
                              dismiss={() => setDeleteDeviceModalVisibility(false)}
                              title={t('grouppage:group_management.delete_device_modal.title')}
                              body={t('grouppage:group_management.delete_device_modal.body', {device: selectedItem.serial_number})}/>

            <h4 className=''>{t('grouppage:group_details.title', {context: selectedItem.type})}</h4>

            <div className='col-12 pt-md-2'>
                <div className='py-2 col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                    <b className='d-block'>{t('grouppage:group_details.inputs.name')}</b>
                    <InputGroup value={groupName} id="groupName" name="groupName"
                                inputValue={(e) => setGroupName(e.target.value)}
                                disabled={!editing} isInvalid={!!nameError} errorText={nameError} className='pt-2'
                                hasLabel={false}/>
                </div>
                <div className='py-2'>
                    <b className='d-block'>{t('grouppage:group_details.inputs.path')}</b>
                    <span className='pt-2 fill-empty text-muted'>{path}</span>
                </div>
                {selectedItem.type === 'group' &&
                    <div className='py-2'>
                        <b className='d-block'>{t('grouppage:group_details.inputs.contents.title')}</b>
                        {isAdmin(accounts) &&
                            <span className='pt-2 fill-empty text-muted'>{`${t('grouppage:group_details.inputs.contents.group', {count: selectedItem.children.length})} - ${t('grouppage:group_details.inputs.contents.device', {count: selectedItem.devices.length})} - ${selectedItem.allowedusers.length} ${t('grouppage:group_details.inputs.contents.assigned_users')}`}</span>
                        }
                        {!isAdmin(accounts) &&
                            <span
                                className='pt-2 fill-empty text-muted'>{t('grouppage:group_details.inputs.contents.group', {count: selectedItem.children.length}) + " - " + t('grouppage:group_details.inputs.contents.device', {count: selectedItem.devices.length})}</span>
                        }
                    </div>
                }
                {selectedItem.type === 'device' && <>
                    <div className='py-2 col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                        <b className='d-block'>Model</b>
                        <span className='pt-2 fill-empty text-muted'>{selectedItem.model}</span>
                    </div>
                    <div className='py-2 col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                        <b className='d-block'>{t('grouppage:group_details.inputs.serial_number')}</b>
                        {isAdmin(accounts) && /* only admin can change device serial number */
                            <InputGroup value={deviceSerialNumber} id="deviceSerialNumber" name="deviceSerialNumber"
                                        inputValue={(e) => setDeviceSerialNumber(e.target.value)}
                                        disabled={!editing} isInvalid={!!deviceSerialNumberError}
                                        errorText={deviceSerialNumberError} className='pt-2'
                                        hasLabel={false}/>
                        }
                        {!isAdmin(accounts) &&
                            <span className='pt-2 fill-empty text-muted'>{selectedItem.serial_number}</span>
                        }
                    </div>
                </>
                }
                <div className='py-2'>
                    <b className='d-block'>{t('grouppage:group_details.inputs.description')}</b>
                    <InputTextArea className='pt-2' value={groupDescription} type='text' name='description'
                                   inputValue={(e) => setGroupDescription(e)} disabled={!editing}
                                   isCounterHidden={false} textBoxLimit={250}
                                   counterText={t('common:remaining_chars')}/>
                </div>
            </div>
            {!isViewer(accounts) && /* Viewer cannot edit groups */
                <div
                    className={`d-flex ${editing && groupData.type === 'device' ? 'justify-content-between' : 'justify-content-end'}  py-4`}>
                    {editing ? <GroupDetailButtonGroup submitClick={handleSubmit} cancelClick={handleCancel}
                                                       deleteClick={showDeleteDeviceModal}
                                                       deleteClickActive={groupData.type === 'device'}/>
                        : <Button onClick={() => setEditing(!editing)}
                                  variant='outline-secondary'
                                  disabled={(!groupName || groupData.canEdit === 0 || !disableEditing || groupData.id === "")}
                                  style={{minWidth: '150px'}} type="submit"><Pencil
                            className="me-1"/>{t('common:buttons.edit')}</Button>
                    }
                </div>
            }
        </div>
    )
}