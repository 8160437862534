import React, {useEffect} from 'react'
import {useIsAuthenticated, useMsal, useMsalAuthentication} from '@azure/msal-react'
import Spinner from 'react-bootstrap/Spinner';
import {useDispatch, useSelector} from 'react-redux';
import {InteractionType} from "@azure/msal-browser";
import {requestUserInformation} from '../../helpers/reduxstore/reducers/userReducer.js';
import * as Sentry from "@sentry/react";
import {getCurrentHub} from "@sentry/react";
import {getGroupsWithDevices} from "../../helpers/reduxstore/reducers/groupReducer.js";
import {msalInstance} from "../../api/api.js";

export default function AuthTemplate({children}) {
    const dispatch = useDispatch()
    useMsalAuthentication(InteractionType.Redirect, ["User.Read"])
    const isAuthenticated = useIsAuthenticated();
    const {accounts} = useMsal();

    useEffect(async () => {
        if (accounts.length > 0) {

            // handleRedirectPromise should clean up the state after redirect which hopefully removes "interaction_in_progress" error from Sentry
            // https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress
            msalInstance.handleRedirectPromise().then(res => {
                if (res) console.log("msalInstance.handleRedirectPromise detected redirect")
            })

            msalInstance.setActiveAccount(accounts[0]);

            dispatch(requestUserInformation())
            // TODO: reintroduce requestNewsletterPreferences when endpoint exists
            // dispatch(requestNewsletterPreferences())

            // Groups are used on everything other than device pages so we just get it here for now
            // TODO: Monitor performance to see if it is a problem for normal users to get groups and devices
            dispatch(getGroupsWithDevices())

            // if (isAdmin(accounts) || isTechnicalAdvanced(accounts))
            //     // If role is Admin or Advanced technical then we need to know what devices user has access to
            //     //  in both group and telemetry components, so we just get devices here
            //     dispatch(getGroupsWithDevices())
            // else
            //     dispatch(getGroups())

        }
    }, [accounts]);

    const {user} = useSelector(state => state.user);
    useEffect(() => {
        // Set user info on sentry global scope, which is sent to sentry when events happen
        Sentry.setUser({
            id: user.id,
            email: user.mail,
            ip_address: "{{auto}}"
        });

        const client = getCurrentHub().getClient();
        const options = client?.getOptions();
        if (!options.replaysSessionSampleRate && user.mail) {
            console.log(`Enabling sentry replay for user ${user.mail}`)

            /** The sample rate for session-long replays. 1.0 will record all sessions and 0 will record none. */
            options.replaysSessionSampleRate = 0.1;
            /** The sample rate for sessions that has had an error occur. This is independent of `sessionSampleRate`. 1.0 will record all sessions and 0 will record none. */
            options.replaysOnErrorSampleRate = 1;

            const replay = new Sentry.Replay({
                maskAllInputs: false,
                maskAllText: false,
                blockAllMedia: false
            })

            // This will start recording (based on sampling)
            client?.addIntegration?.(replay);
        }
    }, [user])

    return (
        <React.Fragment>
            {isAuthenticated && (
                <div>
                    {children}
                </div>
            )}
            {!isAuthenticated && (
                <div className="spinner-wrapper">
                    <Spinner animation="border" variant="primary" size="md" className="d-inline-block"/>
                    <h4 className="ml-2 d-inline-block text-primary ms-3">Authentication in process.. </h4>
                </div>
            )}
        </React.Fragment>
    )
}
