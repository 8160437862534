import {Area, AreaChart, CartesianGrid, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {arrayMax, formatDateTimeString, getTicks, getTimeDiffHours} from '../../../../../helpers/helperfunctions';
import CustomYAxis from '../customyaxis/CustomYAxis';
import React, {useEffect, useState} from "react";
import {LoadSpinner} from "../../../../shared/loader/LoadSpinner.js";
import {useSelector} from "react-redux";
import {loaders} from "../../../../../helpers/constants.js";
import {uniqueId} from 'lodash'
import {Button} from 'react-bootstrap'

/** Draw a line chart with one line
 * @param tooltip
 * @param lineKey
 * @param {[object]} telemetryList
 * @param title
 * @param threshold
 * @param tooltipKeys
 * @param {string} startTime UTC date ISO string for the start date
 * @param {string} endTime UTC date ISO string for the end date
 * @param {string} yPostFix
 * @returns {JSX.Element}
 * @constructor
 */
export default function SimpleLineChart({
                                            tooltip, lineKey = 'pv', telemetryList = [], title,
                                            threshold, tooltipKeys, startTime, endTime, yPostFix
                                        }) {

    const graphId = "graphid" + uniqueId()
    const {loading} = useSelector(state => state.loading)

    const initialXDomainState = [() => new Date(startTime).getTime(), () => new Date(endTime).getTime()]
    const [xDomain, setXDomain] = useState(initialXDomainState)

    const [telemetryToShowList, setTelemetryToShowList] = useState([])
    useEffect(() => {
        setTelemetryToShowList(telemetryList.map(d => ({...d, ts: new Date(d.ts).getTime()})))
    }, [telemetryList])

    const [zoomRefMouseDown, setZoomRefMouseDown] = useState(0)
    const [zoomRefMouseUp, setZoomRefMouseUp] = useState(0)
    const resetZoom = () => {
        setTelemetryToShowList(telemetryList.map(d => ({...d, ts: new Date(d.ts).getTime()})))
        setXDomain(initialXDomainState)
    }

    const zoom = () => {
        if (zoomRefMouseDown === zoomRefMouseUp || zoomRefMouseUp === 0) {
            setZoomRefMouseDown(0)
            setZoomRefMouseUp(0)
            return
        }
        const [leftTs, rightTs] = zoomRefMouseDown > zoomRefMouseUp ? [zoomRefMouseUp, zoomRefMouseDown] : [zoomRefMouseDown, zoomRefMouseUp]
        let zoomStart = telemetryToShowList.findIndex(t => t.ts === leftTs)
        let zoomEnd = telemetryToShowList.findIndex(t => t.ts === rightTs) + 1
        setTelemetryToShowList(telemetryToShowList.slice(zoomStart, zoomEnd))
        setXDomain([() => leftTs, () => rightTs])
        setZoomRefMouseDown(0)
        setZoomRefMouseUp(0)
    }

    const gradientOffset = stopVal => {
        const dataMax = arrayMax(telemetryToShowList.map(i => i[lineKey]));
        return 100 - (stopVal / dataMax) * 100
    }
    let timeDiff = getTimeDiffHours(
        telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[0].ts : startTime,
        telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[telemetryToShowList.length - 1].ts : endTime
    )
    return (
        <>
            <div className="d-flex align-content-center">
                <span className="text-bold ps-3" style={{fontSize: '20px'}}>{title}</span>
                {telemetryToShowList.length !== telemetryList.length &&
                    <Button onClick={resetZoom} className='ms-2' size='sm' variant='outline-secondary'>Reset Zoom</Button>
                }
            </div>

            {loading.includes(loaders.getTelemetry) &&
                <LoadSpinner style={{marginTop: "100px", marginBottom: "100px"}} height='60px' width='60px'/>
            }

            {!loading.includes(loaders.getTelemetry) &&
                <ResponsiveContainer width="100%" height={350}>
                    <AreaChart width={500} height={300}
                               data={telemetryToShowList}
                               margin={{top: 20, right: 30, left: 20, bottom: 0,}}
                               onMouseDown={e => e?.activeLabel && setZoomRefMouseDown(e.activeLabel)}
                               onMouseMove={e => zoomRefMouseDown && e?.activeLabel && setZoomRefMouseUp(e.activeLabel)}
                               onMouseUp={zoom}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="ts" scale="time" type="number"
                               ticks={getTicks(
                                   telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[0].ts : startTime,
                                   telemetryToShowList.length > 0 && telemetryToShowList.length !== telemetryList.length ? telemetryToShowList[telemetryToShowList.length - 1].ts : endTime
                                   , 8)}
                               tickFormatter={e => formatDateTimeString(e, timeDiff > 24, timeDiff <= (24 * 4))}
                               domain={xDomain}
                        />
                        <YAxis tick={<CustomYAxis postFix={yPostFix}/>}/>
                        <Tooltip content={(e) => tooltip(e, tooltipKeys)}/>
                        <defs>
                            {threshold ?
                                <linearGradient id={`${graphId}splitColor`} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset={gradientOffset(parseInt(threshold)) + '%'} stopColor="#ED2124"
                                          stopOpacity={0.4}/>
                                    <stop offset={gradientOffset(parseInt(threshold)) + '%'} stopColor="#067139"
                                          stopOpacity={0.4}/>
                                </linearGradient>
                                :
                                <linearGradient id={`${graphId}noSplitColor`} x1="0" y1="0" x2="0"
                                                y2="1">
                                    <stop offset='100%' stopColor="rgb(103, 167, 221)" stopOpacity={0.1}/>
                                </linearGradient>
                            }
                        </defs>
                        <Area type="monotone" isAnimationActive={false} dataKey={lineKey} activeDot={{r: 4}} dot={false}
                              stroke={`${threshold ? `url(#${graphId}splitColor)` : 'rgba(103, 167, 221, 0.7)'} `}
                              fill={`${threshold ? `url(#${graphId}splitColor)` : `url(#${graphId}noSplitColor)`}`}/>

                        {zoomRefMouseDown && zoomRefMouseUp ? (
                            <ReferenceArea
                                fill={"gray"}
                                x1={zoomRefMouseDown}
                                x2={zoomRefMouseUp}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </AreaChart>
                </ResponsiveContainer>
            }
        </>
    )
}