import React, {useEffect, useState} from 'react'
import TopInformation from '../../components/shared/topinformation/TopInformation.js'
import {useTranslation} from 'react-i18next';
import useSetLocation from "../../helpers/hooks/useSetLocation.js";
import {
    allowedPageRoles,
    notificationDefault,
    notificationFrequencyConstants,
    roleConstants,
    toastTypes
} from "../../helpers/constants.js";
import SwitchGroup from "../../components/shared/switchgroup/SwitchGroup.js";
import RadioButton from "../../components/shared/radiobutton/RadioButton.js";
import Button from "react-bootstrap/Button";
import SettingsContentContainer from "../../components/settings/contentcontainer/SettingsContentContainer.js";
import {useMsal} from "@azure/msal-react";
import {fetchAdministeredUsersList} from "../../helpers/reduxstore/reducers/userAdministrationReducer.js";
import {useDispatch, useSelector} from "react-redux";
import {hasAnyRole} from "../../helpers/authHelper.js";
import ComboBoxWithMessages from "../../components/shared/comboboxwithmessages/ComboBoxWithMessages.js";
import NotificationsItemTreeView from "./notificationsItemTreeview/NotificationsItemTreeView.js";
import {Grid, Hdd} from "react-bootstrap-icons";
import {findDeviceOrGroup, findGroup} from "../../helpers/helperfunctions.js";
import {cloneDeep, isEqual} from "lodash";
import {
    deleteRemoteNotificationById,
    getNotificationList,
    runNotificationJob,
    upsertNotification
} from "../../api/api.js";
import {addToast} from "../../helpers/reduxstore/reducers/toastReducer.js";
import InformationModal from "../../components/shared/informationmodal/InformationModal.js";
import PopOverDialog from "../../components/shared/popoverdialog/PopOverDialog.js";
import useNavigationGuardByRole from "../../helpers/hooks/useNavigationGuardByRole.js";

/** Render notifications page */
export default function NotificationsPage() {
    useNavigationGuardByRole(allowedPageRoles.NotificationsPage)
    useSetLocation()
    const dispatch = useDispatch()
    const {t} = useTranslation(['notificationspage', 'telemetry'])
    const {accounts} = useMsal();
    const getCurrentUserId = () => accounts[0].idTokenClaims.oid
    const [saveInProgress, setSaveInProgress] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [jobRunning, setJobRunning] = useState(false)

    const rootNotificationGroup = {
        id: "",
        parent: "",
        description: "",
        name: t("notificationspage:all_groups"),
        type: "group"
    }

    const isCurrentAdminOrTechnical = () => hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical])
    const [selectedUserId, setSelectedUserId] = useState(getCurrentUserId())
    const [selectedItem, setSelectedItem] = useState(rootNotificationGroup)
    const [selectedNotification, setSelectedNotification] = useState(cloneDeep(notificationDefault))
    const [existingNotificationList, setExistingNotificationList] = useState([])

    const {administeredUsers} = useSelector(state => state.userAdministration)
    const {allowedGroups} = useSelector(g => g.group)
    const [shownGroups, setShownGroups] = useState([])

    useEffect(() => {
        if (isCurrentAdminOrTechnical()) {
            dispatch(fetchAdministeredUsersList()) // notifications for admins / adv technical is fetched later
        } else {
            fetchNotificationList().catch(console.error)
        }

        // edit self by default
        setSelectedUserId(getCurrentUserId())
    }, [])

    useEffect(() => {
        const selectedUser = administeredUsers.find(u => u.userData.id === selectedUserId)
        const filteredGroups = selectedUser ? filterShownGroups(selectedUser, cloneDeep(allowedGroups)) : cloneDeep(allowedGroups)
        setShownGroups(filteredGroups)
    }, [allowedGroups])

    // filter shown groups when changing selected user via dropdown
    useEffect(() => {
        const selectedUser = administeredUsers.find(u => u.userData.id === selectedUserId)
        if (selectedUser) {
            // if selected user is not admin or self, filter groups to ones selected user has access to
            setShownGroups(filterShownGroups(selectedUser, allowedGroups));
            selectNodeAndConfigIfAny(rootNotificationGroup)

            // get notifications for selected user
            fetchNotificationList().catch(console.error)
        }
    }, [selectedUserId, administeredUsers])

    /** return groups to show to user. If administering another user, only show groups available to that user */
    function filterShownGroups(selectedUser, groups) {
        // if selected user is admin or self, don't filter groups
        if (selectedUser.userData?.appRole === roleConstants.Admin || selectedUserId === getCurrentUserId())
            return allowedGroups
        if (selectedUser.groups.length === 0) console.warn("selectedUser.groups.length is length zero")
        return selectedUser.groups.map(groupId => findGroup(groupId, groups))
    }

    const fetchNotificationList = async () => {
        let notificationList = await getNotificationList(selectedUserId);

        // add or remove warnings and alarms based on default notification
        const validAlarmNames = notificationDefault.alarms.map(a => a.name)
        const validWarningNames = notificationDefault.warnings.map(w => w.name)
        for (const notification of notificationList) {
            for (const alarmName of validAlarmNames) {
                if (!notification.alarms.find(a => a.name === alarmName)) {
                    notification.alarms.push({name: alarmName, enabled: false})
                }
            }
            notification.alarms = notification.alarms.filter(a => validAlarmNames.includes(a.name))

            for (const warningName of validAlarmNames) {
                if (!notification.warnings.find(a => a.name === warningName)) {
                    notification.warnings.push({name: warningName, enabled: false})
                }
            }
            notification.warnings = notification.warnings.filter(a => validWarningNames.includes(a.name))
        }

        setExistingNotificationList(notificationList)
    }

    useEffect(() => {
        const foundNotification = existingNotificationList.find(n => n.itemId === selectedItem?.id)
        setSelectedNotification(foundNotification ?? cloneDeep(notificationDefault))
    }, [existingNotificationList])

    async function deleteNotification() {
        try {
            setShowDeleteConfirmation(false)
            await deleteRemoteNotificationById(selectedNotification.id)
            await fetchNotificationList()
            dispatch(addToast({
                id: "deleteNotificationsSuccess",
                type: toastTypes.success,
                title: t('notificationspage:toast.delete.success.title'),
                body: t('notificationspage:toast.delete.success.body'),
                timeSeconds: 3
            }))
        } catch (e) {
            dispatch(addToast({
                id: "deleteNotificationsError",
                type: toastTypes.error,
                title: t('notificationspage:toast.delete.error.title'),
                body: t('notificationspage:toast.delete.error.body'),
                timeSeconds: 5
            }))
            console.error(e)
        }
    }

    async function save() {
        const notificationToSave = {
            ...cloneDeep(selectedNotification),
            id: selectedNotification.itemId === selectedItem.id ? selectedNotification.id : '', // if itemId is not same, it is overriding parent
            itemId: selectedItem.id,
            itemType: selectedItem.type,
            userId: selectedUserId
        }
        console.log(`Saving notification: ${JSON.stringify(notificationToSave)}`)

        try {
            setSaveInProgress(true)
            await upsertNotification(notificationToSave)
            await fetchNotificationList()
            dispatch(addToast({
                id: "saveNotificationsSuccess",
                type: toastTypes.success,
                title: t('notificationspage:toast.save.success.title'),
                body: t('notificationspage:toast.save.success.body'),
                timeSeconds: 3
            }))
        } catch (e) {
            dispatch(addToast({
                id: "saveNotificationsError",
                type: toastTypes.error,
                title: t('notificationspage:toast.save.error.title'),
                body: t('notificationspage:toast.save.error.body'),
                timeSeconds: 5
            }))
            console.error(e)
        } finally {
            setSaveInProgress(false)
        }
    }

    const selectNodeAndConfigIfAny = (node) => {
        setSelectedNotification(findNotificationForNodeIfAny(node) ?? cloneDeep(notificationDefault))
        setSelectedItem(node)
    }

    const findNotificationForNodeIfAny = (node) => {
        const notification = existingNotificationList.find(c => c.itemId === node.id)
        if (notification) return notification
        if (node.parent) {
            let parentGroup = findGroup(node.parent, shownGroups);
            if (!parentGroup) {
                // user does not have access to the parent group, so cannot search further for notification.
                // Insted of just return null however, check if root notification is here.
                return existingNotificationList.find(c => c.itemId === "")
            }
            return findNotificationForNodeIfAny(parentGroup)
        } else if (node.parent === "") {
            return existingNotificationList.find(c => c.itemId === "")
        }
        return null
    }

    function selectExistingNotification(notification) {
        if (notification.itemId === "") {
            setSelectedItem(rootNotificationGroup)
            setSelectedNotification(notification)
            return
        }

        const item = findDeviceOrGroup(notification.itemId, notification.itemType, shownGroups)
        if (item) {
            setSelectedItem(item)
            setSelectedNotification(notification)

            // open group hierarchy to notification item
            const groupsCopy = cloneDeep(shownGroups)
            setItemAndAllParentsVisible(item, groupsCopy)
            setShownGroups(groupsCopy)
        } else {
            throw Error(`NotificationsPage selectExistingNotification: Could not find item in hierarchy ${JSON.stringify({
                id: notification.itemId,
                type: notification.itemType
            })}`)
        }
    }

    /** Given an item, opens the group hierarchy until that item
     * @param item device or group
     * @param groupsCopy modifiable copy of group hierarchy
     */
    function setItemAndAllParentsVisible(item, groupsCopy) {
        if (item.type === 'group') findGroup(item.id, groupsCopy).isOpen = true
        if (item.parent) {
            let foundGroup = findGroup(item.parent, groupsCopy);
            if (!foundGroup) return
            setItemAndAllParentsVisible(foundGroup, groupsCopy)
        }
    }

    function setGroupOpen(group, value) {
        const groupsCopy = cloneDeep(shownGroups)
        findGroup(group.id, groupsCopy).isOpen = value
        setShownGroups(groupsCopy)
    }

    function setAlarm(name, value) {
        const notificationClone = cloneDeep(selectedNotification);
        notificationClone.alarms.find(w => w.name === name).enabled = value
        setSelectedNotification(notificationClone)
    }

    function setWarning(name, value) {
        const notificationClone = cloneDeep(selectedNotification);
        notificationClone.warnings.find(w => w.name === name).enabled = value
        setSelectedNotification(notificationClone)
    }

    function toggleAllAlarms() {
        const notificationClone = cloneDeep(selectedNotification);
        if (notificationClone.alarms.every(a => a.enabled)) {
            for (const a of notificationClone.alarms) a.enabled = false
        } else {
            for (const a of notificationClone.alarms) a.enabled = true
        }
        setSelectedNotification(notificationClone)
    }

    function toggleAllWarnings() {
        const notificationClone = cloneDeep(selectedNotification);
        if (notificationClone.warnings.every(a => a.enabled)) {
            for (const w of notificationClone.warnings) w.enabled = false
        } else {
            for (const w of notificationClone.warnings) w.enabled = true
        }
        setSelectedNotification(notificationClone)
    }

    /** Disable delete button if we have not selected an item with an existing notification */
    const deleteIsDisabled = () => {
        if (!selectedNotification) return true
        if (!selectedItem) return true
        return !existingNotificationList.find(n => n.itemId === selectedItem.id)
    }

    function getInheritingName() {
        const inheritingGroup = findGroup(selectedNotification.itemId, shownGroups);
        if (inheritingGroup) return inheritingGroup.name
        if (existingNotificationList.some(n => n.itemId === '')) return rootNotificationGroup.name
        return null
    }

    function selectedIdenticalToExisting() {
        const foundNotification = existingNotificationList.find(n => n.id === selectedNotification.id)
        return foundNotification && isEqual(selectedNotification, foundNotification)
    }

    async function runJob(frequency) {
        setJobRunning(true)
        await runNotificationJob(frequency)
        setTimeout(() => {
            setJobRunning(false)
        }, 10 * 1000)
    }

    return <>
        <InformationModal show={showDeleteConfirmation} title={t('notificationspage:modal.delete.title')}
                          body={t('notificationspage:modal.delete.text', {itemName: selectedItem.name})}
                          dismiss={() => setShowDeleteConfirmation(false)}
                          accept={() => deleteNotification()}
        />

        <div className='container-fluid'>
            <TopInformation className='row' title={t('notificationspage:info_section.info_section_title')}
                            subTitle={t('notificationspage:info_section.info_section_p')}/>
        </div>

        {/* === User selection dropdown === */}
        {isCurrentAdminOrTechnical() &&
            <ComboBoxWithMessages label={t('notificationspage:choose_user')} style={{maxWidth: '350px'}}
                                  className='ps-md-3 pt-3' inputValue={e => setSelectedUserId(e.target.value)}
                                  name='selectedUser' value={selectedUserId}
                                  options={administeredUsers.map(u => {
                                      return {id: u.userId, option: u.userData.mail}
                                  })}
            />
        }

        {/* === Existing notifications list (if any) === */}
        {existingNotificationList.length > 0 &&
            <div className='d-md-flex align-items-center ps-md-3 pt-2'>
                <div>{t('notificationspage:existing_notifications')}:</div>
                {existingNotificationList.map(c =>
                    <div key={c.id}
                         className={`cursor-pointer hover-effect ms-2 fw-bold ${selectedItem?.id === c.itemId ? 'node-active' : ''}`}
                         onClick={() => selectExistingNotification(c)}>
                        {c.itemId === '' ? t('notificationspage:all_groups') : findDeviceOrGroup(c.itemId, c.itemType, shownGroups)?.name}
                    </div>
                )}
            </div>
        }

        <div className='ps-md-3 pt-3 d-flex d-xs-block mb-3'>

            {/* === Groups / Device selector ===*/}
            <div style={{flex: '1'}}>
                <div className='boxstyling ps-3 pe-3 d-flex flex-column h-100'>
                    <div className='mt-3 mb-3 d-flex flex-column h-100'>
                        <div className='flex-grow-1 d-flex' style={{height: "400px"}}>
                            <div className='hierarchy-inactive'>
                                <NotificationsItemTreeView groups={shownGroups} selectedNode={selectedItem}
                                                           setGroupOpen={setGroupOpen} rootNode={rootNotificationGroup}
                                                           selectNode={selectNodeAndConfigIfAny}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* === Notification settings ===*/}
            <div style={{flex: '1'}} className='ms-md-2 mt-xs-2'>
                <div className='boxstyling p-3 d-flex flex-column h-100'>
                    {selectedItem &&
                        <>
                            <div className='d-flex align-items-center p-1'>
                                <div className='me-2'>
                                    {selectedItem?.type !== 'device' && <Grid className='icon-styling'/>}
                                    {selectedItem?.type === 'device' && <Hdd className='icon-styling'/>}
                                </div>
                                <h5 className='mb-0'>{selectedItem?.name}</h5>
                            </div>

                            {getInheritingName() && selectedItem.id !== selectedNotification.itemId &&
                                <div>Nedarver fra gruppe <b className='cursor-pointer'
                                                            onClick={() => selectExistingNotification(selectedNotification)}>
                                    {getInheritingName()}
                                </b>
                                </div>
                            }


                            <div className='d-flex align-items-center mt-3'>
                                <SwitchGroup
                                    checked={selectedNotification.enabled}
                                    onChange={() => setSelectedNotification({
                                        ...selectedNotification,
                                        enabled: !selectedNotification.enabled
                                    })}/>
                                <div className='ms-2'>
                                    {t('notificationspage:notifications')} {selectedNotification.enabled ?
                                    <b className='color-telemetry-very-good'>{t('notificationspage:enabled')}</b> : t('notificationspage:disabled')}
                                </div>
                            </div>

                            <div className='mt-3'>
                                <div className='pb-2'>{t('notificationspage:notification_frequency')}</div>
                                <div className='d-md-flex flex-wrap'>
                                    <RadioButton className='me-2' value={notificationFrequencyConstants.DAILY}
                                                 setSelectedValue={v => setSelectedNotification({
                                                     ...selectedNotification,
                                                     frequency: v
                                                 })}
                                                 selectedValue={selectedNotification.frequency}
                                                 label={t('notificationspage:frequency.daily')}
                                    />
                                    <RadioButton className='me-2' value={notificationFrequencyConstants.WEEKLY}
                                                 setSelectedValue={v => setSelectedNotification({
                                                     ...selectedNotification,
                                                     frequency: v
                                                 })}
                                                 selectedValue={selectedNotification.frequency}
                                                 label={t('notificationspage:frequency.weekly')}
                                    />
                                    <RadioButton className='me-2' value={notificationFrequencyConstants.MONTHLY}
                                                 setSelectedValue={v => setSelectedNotification({
                                                     ...selectedNotification,
                                                     frequency: v
                                                 })}
                                                 selectedValue={selectedNotification.frequency}
                                                 label={t('notificationspage:frequency.monthly')}
                                    />
                                </div>
                            </div>

                            <div className='mt-3'>{t('notificationspage:notification_types')}</div>
                            <SettingsContentContainer className='p-3 mt-2 d-flex flex-xs-column'>
                                <div style={{flex: '1'}}>
                                    <div className={'d-flex'}>
                                        <input type='checkbox'
                                               checked={selectedNotification.alarms.every(a => a.enabled)}
                                               onChange={() => toggleAllAlarms()}/>
                                        <h4 className='ms-2'>{t('notificationspage:alarm')}</h4>
                                    </div>
                                    {/*TODO: check if any more alarms has been added */}
                                    {selectedNotification.alarms.map(alarm =>
                                        <div key={alarm.name} className={'d-flex'}>
                                            <input type='checkbox' checked={alarm.enabled}
                                                   onChange={() => setAlarm(alarm.name, !alarm.enabled)}/>
                                            <div className='ms-2'>{t(`telemetry:${alarm.name}`)}</div>
                                        </div>
                                    )}
                                </div>
                                <div style={{flex: '1'}} className='mt-xs-3'>
                                    <div className={'d-flex'}>
                                        <input type='checkbox'
                                               checked={selectedNotification.warnings.every(w => w.enabled)}
                                               onChange={() => toggleAllWarnings()}/>
                                        <h4 className='ms-2'>{t('notificationspage:warning')}</h4>
                                    </div>
                                    {/*TODO: check if any more warnings has been added */}
                                    {selectedNotification.warnings.map(warning =>
                                        <div key={warning.name} className={'d-flex'}>
                                            <input type='checkbox' checked={warning.enabled}
                                                   onChange={() => setWarning(warning.name, !warning.enabled)}/>
                                            <div className='ms-2'>{t(`telemetry:${warning.name}`)}</div>
                                        </div>
                                    )}
                                </div>
                            </SettingsContentContainer>

                        </>
                    }

                    <div className="p-3">
                        <Button className='float-end' variant="outline-secondary" onClick={save}
                                disabled={saveInProgress || selectedIdenticalToExisting()} style={{minWidth: '100px'}}
                                type="submit">
                            {t('common:buttons.save')}
                        </Button>
                        <Button className='float-end me-2' variant="outline-secondary" disabled={deleteIsDisabled()}
                                onClick={() => setShowDeleteConfirmation(true)}
                                style={{minWidth: '100px'}} type="submit">
                            {t('common:buttons.delete')}
                        </Button>
                    </div>

                </div>
            </div>
        </div>

        {process.env.REACT_APP_ENV !== "prod" &&
            <div className="p-3">
                <div className='float-end'>
                    <Button variant="outline-secondary" onClick={() => runJob('DAILY')} disabled={jobRunning}
                            className='me-2'
                            style={{minWidth: '100px', maxWidth: '300px'}} type="submit">Test Daglig Job</Button>
                    <Button variant="outline-secondary" onClick={() => runJob('WEEKLY')} disabled={jobRunning}
                            className='me-2'
                            style={{minWidth: '100px', maxWidth: '300px'}} type="submit">Test Ugentlig Job</Button>
                    <Button variant="outline-secondary" onClick={() => runJob('MONTHLY')} disabled={jobRunning} className='me-2'
                            style={{minWidth: '100px', maxWidth: '300px'}} type="submit">Test Månedlig Job</Button>
                    <PopOverDialog popOverTitel={'Kør Job beskrivelse'}
                                   popOverContent={"Disse knapper sætter en kørsel i gang i testmiljøet. Der kan gå alt mellem 10 sekunder " +
                                       "til 2 minutter baseret på hvor mange anlæg der skal hentes events for og hvor lang perioden er. Når kørslen er færdig " +
                                       "burde der komme en mail <b>HVIS</b> der var nogen relevante events i tidsperioden for de valgte anlæg / grupper. Hvis der ikke kom en mail kan du se kørslen med logs " +
                                       "<a href='https://portal.azure.com/#view/WebsitesExtension/FunctionMenuBlade/~/monitor/resourceId/%2Fsubscriptions%2Fb1ace636-0fd7-4ad2-990f-fdc0bdf9c833%2FresourceGroups%2Frg-airlinq-airmaster-dev-1%2Fproviders%2FMicrosoft.Web%2Fsites%2Ffna-scheduled-jobs-airmaster-dev-1%2Ffunctions%2FSendNotifications'>Her</a>" +
                                       ", hvis du har adgang. Hvis du ikke har adgang, spørg Martin eller Kenneth."}
                                   trigger={['click']} contentAsHtml={true}/>
                </div>
            </div>
        }

    </>
}