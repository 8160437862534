import React, {useEffect, useRef, useState} from 'react'
import Toast from 'react-bootstrap/Toast'
import Spinner from 'react-bootstrap/Spinner'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {addToast, removeToastById} from "../../../helpers/reduxstore/reducers/toastReducer.js";
import {toastTypes} from "../../../helpers/constants.js";
import {removeLoadingError} from "../../../helpers/reduxstore/reducers/loadingReducer.js";
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

// Add more moment locales here if we add more languages
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/nl';
import 'moment/locale/en-gb';

/** Component to wrap pages. The loader use the redux loadingReducer, which determines if the loader should be visible.
 * Do not show global loaders for now. Loaders should be part of specific components.
 * @param {Components} children child components to show
 * @returns {JSXElement} this component
 */
export default function LoadWrapper({children}) {
    const {loading, loadingError} = useSelector(state => state.loading)
    const {toastList} = useSelector(state => state.toast)
    const [toastShownList, setToastShownList] = useState([])
    const displayRef = useRef()
    const {t} = useTranslation(['toast'])
    const dispatch = useDispatch()
    const {user} = useSelector(state => state.user);

    useEffect(() => {
        // Do not show global loadspinner on gets and loads. We use these events to show loadspinners in relevant elements now.
        if (loading.filter(l => !(l.startsWith("get") || l.startsWith("load"))).length > 0) {
            displayRef.current.classList.remove('loader-hidden')
        } else {
            displayRef.current.classList.add('loader-hidden')
        }

        if (loadingError.length > 0) {
            addErrorToast()
        }
    }, [loading, loadingError])

    useEffect(() => {
        // remove shown toasts not in toast list
        let activeToasts = toastShownList.filter(t => toastList.find(r => r.id === t.id) && Date.now() < (t.addedTimeMs + 1000 * t.timeSeconds));

        if (activeToasts.length !== toastShownList.length) {
            console.log(`removing some toasts`)
            setToastShownList(activeToasts)
        }

        // show new toasts from list
        for (const toast of toastList) {
            if (!toastShownList.find(t => t.id === toast.id)) {
                const translatedToast = toast.title.startsWith("toast:") ? {
                    ...toast,
                    title: t(toast.title),
                    body: t(toast.body)
                } : toast
                console.log("adding new toast", JSON.stringify(translatedToast))
                setToastShownList([...toastShownList, translatedToast])
                let msToShow = (translatedToast.addedTimeMs + 1000 * translatedToast.timeSeconds) - Date.now();
                console.log(`Show ${translatedToast.id} toast for ${msToShow} ms`)
                setTimeout(() => {
                    dispatch(removeToastById(translatedToast.id))
                }, msToShow);
            }
        }
    }, [toastList])

    const addErrorToast = () => {
        const loadingErrorId = loadingError[0]
        const loadingErrorShownSeconds = 10
        let newToast = {
            id: t(`toast:errors.${loadingErrorId}.title`),
            title: t(`toast:errors.${loadingErrorId}.title`),
            body: t(`toast:errors.${loadingErrorId}.text`),
            timeSeconds: loadingErrorShownSeconds
        };

        dispatch(addToast(newToast))
        setTimeout(() => {
            dispatch(removeLoadingError(loadingErrorId))
        }, 1000 * loadingErrorShownSeconds)
    }

    function getToastHeaderBgColorClass(type) {
        switch (type) {
            case toastTypes.success:
                return "toast-header-bgcolor-success"
            case toastTypes.info:
                return "toast-header-bgcolor-info"
            case toastTypes.warning:
                return "toast-header-bgcolor-warning"
            case toastTypes.error:
                return "toast-header-bgcolor-error"
        }
        return ""
    }

    return (
        <React.Fragment>
            <div ref={displayRef} className="loader loader-hidden border-0">
                <Spinner animation="border" variant="light" size="sm" className="spinner-styling"/>
            </div>

            {/* MUI DatePicker component needs LocalizationProvider wrapper, so we add it here where we have access to user language*/}
            <LocalizationProvider
                adapterLocale={user.preferredLanguage === 'en' ? 'en-GB' : user.preferredLanguage}
                dateFormats={{monthAndYear: 'MMMM'}} dateAdapter={AdapterMoment}>
                {children}
            </LocalizationProvider>

            <div className="toast-container position-fixed bottom-0 end-0 p-3">
                {toastShownList.map(toast =>
                    <Toast key={toast.id} onClose={() => dispatch(removeToastById(toast.id))}>
                        <div className={`toast-header ${getToastHeaderBgColorClass(toast.type)} py-1`}
                             style={{backgroundColor: 'rgb(251 158 70)'}}>
                            <strong className="me-auto text-white">{toast.title}</strong>
                            <div onClick={() => dispatch(removeToastById(toast.id))}
                                 className="cursor-pointer close ml-2 mb-1 text-white" data-dismiss="toast">
                                <span aria-hidden="true" className="btn-lg p-0">×</span>
                            </div>
                        </div>
                        <div className="toast-body">{toast.body}</div>
                    </Toast>
                )}
            </div>
        </React.Fragment>
    )
}