import {Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis} from 'recharts'
import {formatMinutesAsTimeString} from '../../../helpers/helperfunctions'
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from "react"
import {cloneDeep} from "lodash"

const TOTAL_NUMBER_OF_MINUTES_PER_DAY = 1440

function getTotalTimeUsed(graphData, index, timerNum) {
    let total_time_used = 0
    for (let i = 0; i <= timerNum; i++) {
        total_time_used += graphData[index]["ws" + (i)] === undefined ? 0 : graphData[index]["ws" + (i)]
        total_time_used += graphData[index]["sh" + (i)] === undefined ? 0 : graphData[index]["sh" + (i)]
        total_time_used += graphData[index]["ws" + (i + 1)] === undefined ? 0 : graphData[index]["ws" + (i + 1)]
    }
    return total_time_used
}

function moveAllGraphsStepRight(graphData, nextDayIndex) {
    const numberOfGraphs = graphData[nextDayIndex].num_of_graphs
    for (let i = numberOfGraphs; i > 0; i--) {
        graphData[nextDayIndex]["ws" + (i + 1)] = graphData[nextDayIndex]["ws" + i]
        graphData[nextDayIndex]["sh" + (i + 1)] = graphData[nextDayIndex]["sh" + i]
        graphData[nextDayIndex]["sh" + (i + 1) + "_barcolor"] = graphData[nextDayIndex]["sh" + i + "_barcolor"]
        graphData[nextDayIndex]["sh" + (i + 1) + "_label_name"] = graphData[nextDayIndex]["sh" + i + "_label_name"]
        graphData[nextDayIndex]["sh" + (i + 1) + "_label_airflow"] = graphData[nextDayIndex]["sh" + i + "_label_airflow"]
    }
    graphData[nextDayIndex]["ws" + (1)] = "0"
    graphData[nextDayIndex]["sh" + (1)] = "0"
    graphData[nextDayIndex]["sh" + (1) + "_barcolor"] = ""
    graphData[nextDayIndex]["sh" + (1) + "_label_name"] = ""
    graphData[nextDayIndex]["sh" + (1) + "_label_airflow"] = ""
}

export default function TimeScheduleChart({scheduleList}) {
    const {t} = useTranslation(['settingspage', 'common'])

    let cleanGraphData = [
        {
            name: t('common:days.mon'),
            total_time_used: 0,
            num_of_graphs: 0
        },
        {
            name: t('common:days.tue'),
            total_time_used: 0,
            num_of_graphs: 0
        },
        {
            name: t('common:days.wed'),
            total_time_used: 0,
            num_of_graphs: 0
        },
        {
            name: t('common:days.thu'),
            total_time_used: 0,
            num_of_graphs: 0
        },
        {
            name: t('common:days.fri'),
            total_time_used: 0,
            num_of_graphs: 0
        },
        {
            name: t('common:days.sat'),
            total_time_used: 0,
            num_of_graphs: 0
        },
        {
            name: t('common:days.sun'),
            total_time_used: 0,
            num_of_graphs: 0
        }
    ];
    const [graphDataState, setGraphDataState] = useState(cleanGraphData)

    useEffect(() => {
        const graphData = cloneDeep(cleanGraphData)
        cloneDeep(scheduleList)
            .sort((a, b) => (a.timerStartMinutesSinceMidnight - a.key) - (b.timerStartMinutesSinceMidnight - b.key))
            .forEach(obj => {
                if (obj.isEnabled) {
                    const title = obj.title
                    const daybits = obj.dayBits
                    const airflow = obj.flowPercent + "%"
                    const timerStartMinutesSinceMidnight = obj.timerStartMinutesSinceMidnight
                    let timerDuration = obj.timerDuration
                    const barColor = obj.barColor
                    for (let i = 0; i < 7; i++) {
                        let ignore = false
                        let total_time_used = graphData[i].total_time_used
                        const index = graphData[i].num_of_graphs
                        const exceedDay = (timerStartMinutesSinceMidnight + obj.timerDuration) > TOTAL_NUMBER_OF_MINUTES_PER_DAY

                        if (getDayIsChecked(daybits, i)) {
                            if (exceedDay) {
                                //set the duration lower to fit day
                                timerDuration = TOTAL_NUMBER_OF_MINUTES_PER_DAY - timerStartMinutesSinceMidnight
                                let timerDurationNextDay = (timerStartMinutesSinceMidnight + obj.timerDuration) - TOTAL_NUMBER_OF_MINUTES_PER_DAY
                                const nextDayIndex = (i + 1) % 7
                                const nextDayWhiteSpaceDuration = graphData[nextDayIndex]["ws1"] == undefined ? 0 : graphData[nextDayIndex]["ws1"]
                                const numberOfGraphs = graphData[nextDayIndex].num_of_graphs //2

                                if (nextDayWhiteSpaceDuration == 0) {
                                    graphData[nextDayIndex]["sh1"] = timerDurationNextDay
                                    graphData[nextDayIndex]["sh1_barcolor"] = barColor
                                    graphData[nextDayIndex]["sh1_label_name"] = title
                                    graphData[nextDayIndex]["sh1_label_airflow"] = t('settingspage:device_timers.airflow') + ":" + airflow
                                    graphData[nextDayIndex].total_time_used = timerDurationNextDay
                                    graphData[nextDayIndex].num_of_graphs = 1
                                } else {
                                    if (nextDayWhiteSpaceDuration < timerDurationNextDay) {
                                        timerDurationNextDay = nextDayWhiteSpaceDuration
                                    }
                                    //move all graphs
                                    moveAllGraphsStepRight(graphData, nextDayIndex)
                                    const newNextWhiteSpaceDuration = nextDayWhiteSpaceDuration - timerDurationNextDay
                                    graphData[nextDayIndex]["ws2"] = newNextWhiteSpaceDuration
                                    graphData[nextDayIndex]["ws1"] = 0
                                    graphData[nextDayIndex]["sh1"] = timerDurationNextDay
                                    graphData[nextDayIndex]["sh1_barcolor"] = barColor
                                    graphData[nextDayIndex]["sh1_label_name"] = title
                                    graphData[nextDayIndex]["sh1_label_airflow"] = t('settingspage:device_timers.airflow') + ":" + airflow
                                    graphData[nextDayIndex].num_of_graphs = numberOfGraphs + 1
                                }
                            }

                            if (total_time_used == 0) {
                                const wsMinutes = timerStartMinutesSinceMidnight == 0 ? 0 : timerStartMinutesSinceMidnight
                                graphData[i]["ws" + (index + 1)] = wsMinutes
                                graphData[i]["sh" + (index + 1)] = timerDuration
                                total_time_used += wsMinutes + timerDuration
                            } else {
                                if (total_time_used <= timerStartMinutesSinceMidnight) {
                                    const wsMinutes = timerStartMinutesSinceMidnight - total_time_used
                                    graphData[i]["ws" + (index + 1)] = wsMinutes
                                    total_time_used += wsMinutes
                                    graphData[i]["sh" + (index + 1)] = timerDuration
                                    total_time_used += timerDuration
                                } else {
                                    const previousTotalTimeUsed = getTotalTimeUsed(graphData, i, index - 1)
                                    if (previousTotalTimeUsed <= timerStartMinutesSinceMidnight) {
                                        //check of previous scheduler end after current
                                        const previousEndTime = previousTotalTimeUsed + graphData[i]["sh" + (index)]
                                        const currentEndTime = timerStartMinutesSinceMidnight + timerDuration
                                        const setPreviousTimerDuration = timerStartMinutesSinceMidnight - previousTotalTimeUsed
                                        const previousBarTitle = graphData[i]["sh" + (index) + "_label_name"]
                                        const previousBarAirFlow = graphData[i]["sh" + (index) + "_label_airflow"]
                                        const previousBarColor = graphData[i]["sh" + (index) + "_barcolor"]

                                        if (setPreviousTimerDuration <= 0) {
                                            graphData[i]["sh" + (index) + "_label_name"] = ""
                                            graphData[i]["sh" + (index) + "_label_airflow"] = ""
                                        }

                                        graphData[i]["sh" + (index)] = setPreviousTimerDuration
                                        graphData[i]["sh" + (index + 1) + "_label_airflow"] = t('settingspage:device_timers.airflow') + ":" + airflow
                                        graphData[i]["sh" + (index + 1)] = timerDuration
                                        total_time_used = previousTotalTimeUsed + setPreviousTimerDuration + timerDuration

                                        if (currentEndTime < previousEndTime) {
                                            ignore = true
                                            graphData[i]["sh" + (index + 1) + "_barcolor"] = barColor
                                            graphData[i]["sh" + (index + 1) + "_label_name"] = title
                                            graphData[i]["sh" + (index + 1) + "_label_airflow"] = t('settingspage:device_timers.airflow') + ":" + airflow
                                            const previousBarMissingDuration = previousEndTime - total_time_used
                                            total_time_used += previousBarMissingDuration
                                            graphData[i]["sh" + (index + 2)] = previousBarMissingDuration
                                            graphData[i]["sh" + (index + 2) + "_barcolor"] = previousBarColor
                                            graphData[i]["sh" + (index + 2) + "_label_name"] = previousBarTitle
                                            graphData[i]["sh" + (index + 2) + "_label_airflow"] = previousBarAirFlow
                                            graphData[i].num_of_graphs = index + 2
                                        }
                                    } else {
                                        ignore = true
                                        console.error("Time Scheduler Graph Error: i=" + i + "index=" + index)
                                    }
                                }
                            }
                            if (!ignore) {
                                graphData[i]["sh" + (index + 1) + "_barcolor"] = barColor
                                graphData[i]["sh" + (index + 1) + "_label_name"] = title
                                graphData[i]["sh" + (index + 1) + "_label_airflow"] = t('settingspage:device_timers.airflow') + ":" + airflow
                                graphData[i].num_of_graphs = index + 1
                            }
                        }
                        graphData[i].total_time_used = total_time_used
                    }
                }
                setGraphDataState(graphData)
            })
    }, [scheduleList])

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={1800}
                height={300}
                data={graphDataState}
                layout="vertical"
                margin={{top: 20, right: 30, left: 0, bottom: 0}}>

                <XAxis type="number" tickFormatter={(e) => formatMinutesAsTimeString(e)}
                       ticks={[0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1439]}
                       domain={[0, 1439]}/>
                <YAxis type="category" dataKey="name"/>
                <Bar dataKey="ws1" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh1" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh1_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh1_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh1_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws2" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh2" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh2_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh2_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh2_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws3" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh3" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh3_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh3_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh3_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws4" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh4" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh4_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh4_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh4_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws5" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh5" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh5_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh5_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh5_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws6" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh6" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh6_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh6_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh6_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws7" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh7" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh7_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh7_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh7_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws8" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh8" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh8_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh8_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh8_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws8" fill="#ffffff" stackId="a" isAnimationActive={false}/>
                <Bar dataKey="sh9" stackId="a" isAnimationActive={false} radius={5}>
                    {
                        graphDataState.map((entry, index) => {
                            return <Cell fill={graphDataState[index].sh9_barcolor} key={`cell-${index}`}/>;
                        })
                    }
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh9_label_name"
                               position="insideTop"/>
                    <LabelList style={{fontSize: '60%', fill: '#263d51'}} dataKey="sh9_label_airflow"
                               position="insideBottom"/>
                </Bar>
                <Bar dataKey="ws10" fill="#ffffff" stackId="a" isAnimationActive={false}/>
            </BarChart>
        </ResponsiveContainer>
    )
}

const getDayIsChecked = (dayBits, index) => dayBits[index] === '1'
