import React from 'react'
import {useTranslation} from 'react-i18next'
import PopOverDialog from '../../../../../components/shared/popoverdialog/PopOverDialog'
import {getFormattedNumber} from "../../../../../helpers/helperfunctions.js";


/** Displays the latest room/inlet/outlet from latest telemetry
 * @param {string} room Room temperature from latest telemetry data
 * @param {string} inlet Inlet temperature from latest telemetry data
 * @param {string} outlet Outlet temperature from latest telemetry data
 * @param {string} outside Outside temperature from latest telemetry data
 * @param {string} className custom classes to add to the wrapper
 */
export default function RoomTemperature({className, room, inlet, outlet, outside}) {
    const {t} = useTranslation(['overviewpage'])

    const roomValStr = room === undefined ? "-" : getFormattedNumber(room) + "\u2103"
    const inletValStr = inlet === undefined ? "-" : getFormattedNumber(inlet) + "\u2103"
    const outletValStr = outlet === undefined ? "-" : getFormattedNumber(outlet) + "\u2103"
    const outsideValStr = outside === undefined ? "-" : getFormattedNumber(outside) + "\u2103"
    
    function getPopoverContent() {
        return <>
            <span className='d-block'>
                {t('overviewpage:table.cells.temperature.inlet')}: <b>{`${inletValStr}`}</b>
            </span>
            <span className='d-block'>
                {t('overviewpage:table.cells.temperature.outlet')}: <b>{`${outletValStr}`}</b>
            </span>
            <span className='d-block'>
                {t('overviewpage:table.cells.temperature.outside')}: <b>{`${outsideValStr}`}</b>
            </span>
        </>
    }

    return (
        <div className={className}>
            {t('overviewpage:table.cells.temperature.room')}:
            <span className='d-inline-flex text-bold ps-1'>{roomValStr}</span>
            <PopOverDialog popOverTitel={t('overviewpage:table.cells.temperature.popover_title')}
                           trigger={['hover', 'focus']} style={{padding: "0.25rem 0"}}
                           popOverContent={getPopoverContent()}/>
        </div>
    )
}
