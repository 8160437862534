import {createSlice} from "@reduxjs/toolkit";
import {getDeviceByIdIfNotModified, getDeviceBySerialNumber, updateDevice} from "../../../api/api"
import {emptyDevice} from "../../EmptyDevice.js";
import {pushGlobalError, setLoadingError} from "./loadingReducer.js";
import {globalErrorTypes, loaderErrors} from "../../constants.js";
import {addToast} from "./toastReducer.js";
import {isAwareDevice} from "../../helperfunctions.js";
import moment from "moment";
import {cloneDeep} from "lodash";

/***************** STATE ****************/
const initialState = {
    deviceListWithCount: { // TODO: remove at some point
        count: 0,
        deviceList: [],
        aggregates: {
            alarm: '-', warning: '-', ok: '-', disconnected: '-'
        }
    },
    currentDevice: emptyDevice()
};

/************ STATE SLICE **************/
const deviceSlice = createSlice({
    name: 'device',
    initialState: initialState,
    reducers: {
        upsertCurrentDevice(state, action) {
            let device = action.payload

            if (device.amxHackConnected) {
                device = cloneDeep(device) // Can't mutate original payload
                delete(device.amxHackConnected)
            }

            // Before sending on to Redux, set offline if device is online but latest telemetry more than 5 min ago
            // Also happens in useSetAmxOfflineIfTelemetryOld.js
            if (device.connected && isAwareDevice(device.serial_number) && device?.latest_telemetry?.time) {
                const latestTelemTime = moment(device.latest_telemetry.time * 1000);
                if (latestTelemTime < moment().subtract(5, "minutes")) {
                    device = {...device, connected: false, amxHackConnected: true}
                }
            }

            state.currentDevice = {...state.currentDevice, ...device}
        }
    }
})

/**** EXPORTED ACTIONS AND REDUCERS ****/
export default deviceSlice.reducer;
export const {upsertCurrentDevice} = deviceSlice.actions;

export const loadCurrentDevice = (deviceSerialNumber) => {
    return async (dispatch) => {
        try {
            dispatch(upsertCurrentDevice(await getDeviceBySerialNumber(deviceSerialNumber)))
        } catch (error) {
            if (error.message.includes("status: 404")) {
                dispatch(pushGlobalError(globalErrorTypes.deviceNotFound))
            } else if (error.message.includes("status: 401")) {
                // TODO: removed error message because 401 is often old jwt, which just redirects to login page. Observe this.
                // dispatch(setLoadingError(loaderErrors.unauthorizedOperation))
            } else {
                dispatch(setLoadingError(loaderErrors.loadCurrentDevice))
            }
            console.error(error)
        }
    }
}
export const loadCurrentDeviceIfNotModified = (deviceId, etag) => {
    return async (dispatch) => {
        try {
            const device = await getDeviceByIdIfNotModified(deviceId, etag)
            // Only update device in redux if not null (null means not modified)
            if (device) dispatch(upsertCurrentDevice(device))
        } catch (error) {
            if (error.message.includes("status: 401")) {
                // TODO: removed error message because 401 is often old jwt, which just redirects to login page. Observe this.
                // dispatch(setLoadingError(loaderErrors.unauthorizedOperation))
            } else {
                dispatch(setLoadingError(loaderErrors.loadCurrentDevice))
            }
            console.error(error)
        }
    }
}

export const modifyCurrentDevice = (device, successToast = null) => {
    return async (dispatch) => {
        try {
            await updateDevice(device)
            dispatch(upsertCurrentDevice(device))
            if (successToast) dispatch(addToast(successToast))
        } catch (error) {
            dispatch(setLoadingError(loaderErrors.editDevice))
            console.error(error)
        }
    }
}
