import {useMsal} from '@azure/msal-react'
import React, {useEffect, useRef, useState} from 'react'
import {Building} from 'react-bootstrap-icons'
import {rootObject} from '../../../helpers/constants'
import {dropHandler, toggleEditStyles} from './interactions/Interactions'
import GroupNode from './groupnode/GroupNode.js'
import {isAdmin} from "../../../helpers/authHelper.js";
import {useTranslation} from 'react-i18next'
import {cloneDeep, uniq, uniqBy} from 'lodash'
import {getPathToItems} from '../../../helpers/helperfunctions.js'

/** Renders a hierarchical treeview with groups and devices
 * @param {function} setSelectedItem Method to call with the current selected item in the hierarchy
 * @param {array} groups Array of group objects to build the hierarchy
 * @param {boolean} hasRoot Controls if a root should be displayed in the hierarchy
 * @param {boolean} allowDragover Controls if it should be allowed to drag-and-drop in the same hierarchy
 * @param {boolean} isDraggable Controls if items are draggable
 * @param {function} itemDropped  Function to call when a new item has been dropped (requires allowDragover)
 * @param {boolean} shouldDisplayDevices  Function to call when a new item has been dropped (requires allowDragover)
 * @param {boolean} canDragReadOnly
 * @param {boolean} showSearchField
 * @param {function} resetSelectedNodeList function to reset selected node list
 */
export default function GroupTreeView({
                                          setSelectedItem, groups, selectedNodeList, setSelectedNodeList,
                                          hasRoot = false, allowDragover = false, isDraggable = false,
                                          itemDropped, shouldDisplayDevices = false, canDragReadOnly = false,
                                          showSearchField = false
                                      }) {
    const {t} = useTranslation(['common', 'grouppage'])
    const selectRef = useRef()
    const {accounts} = useMsal();
    const [search, setSearch] = useState("")
    const [groupNameWithPathListWithoutDuplicatesState, setGroupNameWithPathListWithoutDuplicatesState] = useState({})
    const searchTimeoutRef = useRef(null);

    // These sets decide which groups are open / visible. It might seem a little weird to not just save it on the groups themselves.
    //  It is done this way because the group hierarchy can be used in multiple layouts, which should have different
    //  states of openness / visiblity.
    const [groupIsOpenSet, setGroupIsOpenSet] = useState({})
    const [groupIsVisibleSet, setGroupIsVisibleSet] = useState(null)

    useEffect(() => {
        if (showSearchField) {
            const itemNameWithPathList = getPathToItems(groups, shouldDisplayDevices)
            const itemNameWithPathListWithoutDuplicates = uniqBy(itemNameWithPathList, 'id')
            setGroupNameWithPathListWithoutDuplicatesState(itemNameWithPathListWithoutDuplicates)
        }
    }, [groups])

    useEffect(() => {
        if (searchTimeoutRef.current) window.clearTimeout(searchTimeoutRef.current)
        searchTimeoutRef.current = window.setTimeout(() => {
            if (search.trim() === "") {
                setGroupIsVisibleSet(null)
                return
            }

            const searchResults = groupNameWithPathListWithoutDuplicatesState.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase()) || item?.serial_number?.includes(search.toLowerCase())
            )
            const matchIdSet = uniq(searchResults.flatMap(m => m.path)).map(r => [r, r]).toMap()
            setGroupIsOpenSet(matchIdSet)
            setGroupIsVisibleSet(matchIdSet)
        }, 500)
    }, [search])

    useEffect(() => {
        if (hasRoot && selectRef.current) toggleEditStyles(isDraggable, selectRef, false)
    }, [isDraggable, hasRoot])

    const selectNode = (node, event) => {
        setSelectedItem(node)

        if (!isDraggable || !event.ctrlKey) {
            setSelectedNodeList([node])
            return
        }

        // === if is editing, allow for multi select of devices if ctrl key is held down
        if (selectedNodeList.some(n => n.type === 'group')) {
            // if previous selection contains group, start over
            setSelectedNodeList([node])
        } else {
            // else, add or remove device from list
            if (selectedNodeList.find(n => n.id === node.id)) {
                setSelectedNodeList(selectedNodeList.filter(n => n.id !== node.id))
            } else {
                setSelectedNodeList([...selectedNodeList, node])
            }
        }
    }

    const toggleGroupOpen = (groupId) => {
        const groupIsOpenSetClone = cloneDeep(groupIsOpenSet)
        if (groupIsOpenSetClone[groupId])
            delete groupIsOpenSetClone[groupId]
        else
            groupIsOpenSetClone[groupId] = groupId
        setGroupIsOpenSet(groupIsOpenSetClone)
    }

    const handleRootDragEnter = () => {
        selectRef.current.classList.add('dragover-style')
    }
    const handleRootDragExit = () => {
        selectRef.current.classList.remove('dragover-style')
    }
    const handleRootDragover = (e) => {
        e.dataTransfer.types.includes('group') && e.preventDefault()
    }

    function filterSearchedGroups(children) {
        if (!groupIsVisibleSet) return children
        return children.filter(g => groupIsVisibleSet[g.id])
    }

    const getGroupTree = () => (
        filterSearchedGroups(groups).map(group => <div key={group.id} className="unstyled ps-3 ms-2 border-start">
            <GroupNode canDragReadOnly={canDragReadOnly} shouldDisplayDevices={shouldDisplayDevices}
                       node={group} selectNode={selectNode} selectedNodeList={selectedNodeList}
                       allowDragover={allowDragover} isDraggable={isDraggable} groupIsOpenSet={groupIsOpenSet}
                       itemDropped={itemDropped} searchTerm={search} toggleGroupOpen={toggleGroupOpen}
                       isVisibleSet={groupIsVisibleSet}/>
        </div>)
    )

    const getRoot = () => (<div className="unstyled p-1 ps-2 text-secondary">
        <div
            className="text-noselect cursor-pointer list-unstyled hover-effect children-pointer-events-none"
            onDrop={(e) => allowDragover ? dropHandler(e, rootObject, itemDropped, handleRootDragExit, selectedNodeList) : undefined}
            onDragOver={allowDragover ? handleRootDragover : undefined}
            onDragEnter={allowDragover ? handleRootDragEnter : undefined}
            onDragLeave={allowDragover ? handleRootDragExit : undefined}
            onClick={(e) => selectNode(rootObject, e)} ref={selectRef}
        >
            <Building display="inline" className="me-2"/><span className="text-bold text-black">Airmaster A/S</span>
        </div>
        {getGroupTree()}
    </div>)

    return (<>
        {showSearchField &&
            <input className="form-control shadow-none w-100 mb-2" type="search" autoFocus value={search}
                   placeholder={t('common:search.placeholder')} onChange={e => setSearch(e.target.value)}/>
        }
        {isAdmin(accounts) && hasRoot ? getRoot() : <div className='pt-1'>{getGroupTree()}</div>}
    </>)

}
