import TimeScheduleChart from "../../timeschedulechart/TimeScheduleChart.js";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import {InfoCircleFill, PlusLg} from "react-bootstrap-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {
    getMinutesSinceMidnightFromString,
    getTimeHHmmStringFromMinutesSinceMidnight,
    getTimerDuration
} from "../../../../helpers/helperfunctions.js";

export default function TimerScheduleListWithWeekGraphic({
                                                             scheduleList,
                                                             updateScheduleListItemProp,
                                                             removeSchedule,
                                                             setDayIsChecked,
                                                             getDayIsChecked,
                                                             addSchedule,
                                                             isAware = false,
                                                             MAX_SCHEDULE_NUM,
                                                             deviceIsSlave,
                                                             masterSerialNumber,
                                                             userCanEdit = true
                                                         }) {
    const {t} = useTranslation(['settingspage', 'common'])

    function getBorderColorClass(schedule) {
        if (schedule.messages && schedule.messages.some(m => m.type === "error")) return 'border-color-error'
        if (schedule.messages && schedule.messages.some(m => m.type === "validation")) return 'border-color-validation'
        if (schedule.inEditMode) return 'border-active'
        if (schedule.isWaitingForUpdate) return 'border-color-warning'
        return 'border-dim'
    }

    function showWarningsMessage() {
        if(!isAware) return false    
        return scheduleList.filter(s => s.isEnabled).length > 1
    }

    function setTimerStartMinutesSinceMidnight(schedule, value) {
        updateScheduleListItemProp(schedule.key, "timerStartMinutesSinceMidnight", getMinutesSinceMidnightFromString(value))
    }

    function setTimerDuration(schedule, value) {
        updateScheduleListItemProp(schedule.key, "timerDuration", getTimerDuration(schedule.timerStartMinutesSinceMidnight, getMinutesSinceMidnightFromString(value)))
    }

    return <React.Fragment>
            <div className="alert alert-info" hidden={!showWarningsMessage()}>
                <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:device_timers.priority_problems')}
            </div>

        {/*=== Calendar ===*/}
        <TimeScheduleChart scheduleList={scheduleList}/>

        {/*=== Schedule List ===*/}
        {scheduleList.map(schedule => {
            return <div key={schedule.key}>

                {userCanEdit &&
                    <div className='d-flex px-2 justify-content-between'>
                        <div hidden={deviceIsSlave} className={'device-timer-settings-item-anchor'}
                             onClick={() => updateScheduleListItemProp(schedule.key, 'inEditMode', !schedule.inEditMode)}>
                            <span>{schedule.inEditMode ? t('settingspage:device_timers.end_edit') : t('settingspage:device_timers.edit_schedule')}</span>
                        </div>
                        {/*Cannot delete Night Cool schedule*/}
                        {(schedule.key !== 0 && !isAware) &&
                            <div className={'device-timer-settings-item-anchor'} hidden={deviceIsSlave}
                                 onClick={() => removeSchedule(schedule.key)}>{t('settingspage:device_timers.delete_schedule')}</div>}
                    </div>
                }

                <div
                    className={'d-flex d-xs-block border rounded-3 m-2 p-1 p-md-3 justify-content-between ' + getBorderColorClass(schedule)}>
                    <div className='d-flex align-items-center' style={{flex: '2'}}>
                        <h5 className='mb-1' style={{paddingRight: '10px'}}>{schedule.title}</h5>
                        <div className='device-timer-circle' style={{background: schedule.barColor}}></div>
                    </div>
                    <div className='d-block' style={{flex: '3'}}>
                        <p className='mb-1'>
                            <span className={'me-1'}>{t('settingspage:device_timers.airflow')}:</span>
                            {schedule.inEditMode && <input style={{width: '48px'}}
                                                           onChange={(e) => updateScheduleListItemProp(schedule.key, 'flowPercent', parseInt(e.currentTarget.value))}
                                                           type='number' min='0' value={schedule.flowPercent}/>}
                            {!schedule.inEditMode && <span>{schedule.flowPercent}</span>}
                            <span className='me-3'>%</span>

                            <span
                                className={'me-1'}>{isAware ? t('settingspage:device_timers.room_temperature') : t('settingspage:device_timers.inlet_temperature')}:</span>
                            {schedule.inEditMode && <input style={{width: '45px'}}
                                                           onChange={(e) => updateScheduleListItemProp(schedule.key, 'temperatureCelcius', parseInt(e.currentTarget.value))}
                                                           type='number' value={schedule.temperatureCelcius}/>}
                            {!schedule.inEditMode && <span>{schedule.temperatureCelcius}</span>}
                            <span>°C</span>
                        </p>

                        <p className='mb-1'>
                            <span className={'me-1'}>{t('common:general.time')}:</span>
                            {!schedule.inEditMode &&
                                <span>{getTimeHHmmStringFromMinutesSinceMidnight(schedule.timerStartMinutesSinceMidnight)}</span>}
                            {schedule.inEditMode &&
                                <input type="time"
                                       value={getTimeHHmmStringFromMinutesSinceMidnight(schedule.timerStartMinutesSinceMidnight)}
                                       onChange={(e) => setTimerStartMinutesSinceMidnight(schedule, e.currentTarget.value)}
                                       min="00:00" max="24:00"/>}
                            <span> - </span>
                            {!schedule.inEditMode &&
                                <span>{getTimeHHmmStringFromMinutesSinceMidnight(schedule.timerStartMinutesSinceMidnight + schedule.timerDuration)}</span>}
                            {schedule.inEditMode && <input type="time"
                                                           value={getTimeHHmmStringFromMinutesSinceMidnight(schedule.timerStartMinutesSinceMidnight + schedule.timerDuration)}
                                                           onChange={(e) => setTimerDuration(schedule, e.currentTarget.value)}
                                                           min="00:00" max="24:00"/>}
                        </p>
                        <div className='d-flex'>
                            <label className='me-2 d-block d-lg-flex align-items-end'>
                                <span>{t('common:days.mon')}</span>
                                <input className='ms-1 mb-4px' type='checkbox'
                                       onChange={(e) => setDayIsChecked(schedule.key, schedule.dayBits, 0, e.currentTarget.checked)}
                                       checked={getDayIsChecked(schedule.dayBits, 0)}
                                       disabled={!(schedule.inEditMode && schedule.key !== 0)}/>
                            </label>
                            <label className='me-2 d-block d-lg-flex align-items-end'>
                                <span>{t('common:days.tue')}</span>
                                <input className='ms-1 mb-4px' type='checkbox'
                                       onChange={(e) => setDayIsChecked(schedule.key, schedule.dayBits, 1, e.currentTarget.checked)}
                                       checked={getDayIsChecked(schedule.dayBits, 1)}
                                       disabled={!(schedule.inEditMode && schedule.key !== 0)}/>
                            </label>
                            <label className='me-2 d-block d-lg-flex align-items-end'>
                                <span>{t('common:days.wed')}</span>
                                <input className='ms-1 mb-4px' type='checkbox'
                                       onChange={(e) => setDayIsChecked(schedule.key, schedule.dayBits, 2, e.currentTarget.checked)}
                                       checked={getDayIsChecked(schedule.dayBits, 2)}
                                       disabled={!(schedule.inEditMode && schedule.key !== 0)}/>
                            </label>
                            <label className='me-2 d-block d-lg-flex align-items-end'>
                                <span>{t('common:days.thu')}</span>
                                <input className='ms-1 mb-4px' type='checkbox'
                                       onChange={(e) => setDayIsChecked(schedule.key, schedule.dayBits, 3, e.currentTarget.checked)}
                                       checked={getDayIsChecked(schedule.dayBits, 3)}
                                       disabled={!(schedule.inEditMode && schedule.key !== 0)}/>
                            </label>
                            <label className='me-2 d-block d-lg-flex align-items-end'>
                                <span>{t('common:days.fri')}</span>
                                <input className='ms-1 mb-4px' type='checkbox'
                                       onChange={(e) => setDayIsChecked(schedule.key, schedule.dayBits, 4, e.currentTarget.checked)}
                                       checked={getDayIsChecked(schedule.dayBits, 4)}
                                       disabled={!(schedule.inEditMode && schedule.key !== 0)}/>
                            </label>
                            <label className='me-2 d-block d-lg-flex align-items-end'>
                                <span>{t('common:days.sat')}</span>
                                <input className='ms-1 mb-4px' type='checkbox'
                                       onChange={(e) => setDayIsChecked(schedule.key, schedule.dayBits, 5, e.currentTarget.checked)}
                                       checked={getDayIsChecked(schedule.dayBits, 5)}
                                       disabled={!(schedule.inEditMode && schedule.key !== 0)}/>
                            </label>
                            <label className='me-2 d-block d-lg-flex align-items-end'>
                                <span>{t('common:days.sun')}</span>
                                <input className='ms-1 mb-4px' type='checkbox'
                                       onChange={(e) => setDayIsChecked(schedule.key, schedule.dayBits, 6, e.currentTarget.checked)}
                                       checked={getDayIsChecked(schedule.dayBits, 6)}
                                       disabled={!(schedule.inEditMode && schedule.key !== 0)}/>
                            </label>
                        </div>
                    </div>

                    <div className='d-flex align-items-center flex-column justify-content-center d-xs-block p-2'>
                        <Form.Label className='mb-0'
                                    htmlFor={`${schedule.key}-enabled-switch`}>{schedule.isEnabled ? t('common:general.enabled') : t('common:general.disabled')}</Form.Label>
                        <Form.Switch className='sm color-secondary' name={`${schedule.key}-enabled-switch`}
                                     checked={schedule.isEnabled}
                                     disabled={!userCanEdit}
                                     onChange={e => updateScheduleListItemProp(schedule.key, 'isEnabled', e.currentTarget.checked)}/>
                    </div>
                </div>
                {schedule.messages &&
                    <div className='ms-4 mb-1'>{
                        schedule.messages.map(m => <div key={m.id} className={`color-${m.type}`}>{m.text}</div>)
                    }</div>
                }
            </div>
        })}

        {/*Add schedule button*/}
        {userCanEdit &&
            <div className='d-flex w-100 justify-content-end'>
                <Button className={'m-2'} onClick={() => addSchedule()} variant='outline-secondary'
                        disabled={scheduleList.length >= MAX_SCHEDULE_NUM + 1 || deviceIsSlave}>
                    <PlusLg className={'mb-1 me-1'}/>
                    {t('settingspage:device_timers.add_schedule')}</Button>
            </div>
        }

        <div className="alert alert-info" hidden={!deviceIsSlave}>
            <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:slave-device-settings')}
            {masterSerialNumber &&
                <a href={"/device/" + masterSerialNumber + "/configurations/timers"}>{masterSerialNumber}</a>}
        </div>
    </React.Fragment>
}