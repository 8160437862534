import {createSlice} from "@reduxjs/toolkit";
import {
    createNewGroup,
    getAllowedGroups, getAllowedGroupsWithDevices,
    getDevices,
    deleteDevice,
    removeGroup,
    updateConfigurationViaDeviceList, updateConfigurationViaGroupList,
    updateDevice,
    updateFirmwareConfiguration,
    updateGroup,
    updateGroupsInHierarchy, updateTimerPeriodsViaDeviceList, updateTimerPeriodsViaGroupList
} from "../../../api/api"
import {loaders, toastTypes} from "../../constants";
import {removeLoading, setLoading, setLoadingError} from "./loadingReducer";
import {cloneDeep} from "lodash";
import {addToast} from "./toastReducer.js";

/***************** STATE ****************/
const initialState = {
    allowedGroups: []
};

/************ STATE SLICE **************/

const groupSlice = createSlice({
    name: 'group',
    initialState: initialState,
    reducers: {
        setAllowedGroups(state, action) {
            state.allowedGroups = action.payload
        },
        updateGroups(state, action) {
            const previousState = cloneDeep(state.allowedGroups)
            traverseAndUpdateGroup(previousState, action.payload)
            state.allowedGroups = previousState
        },
        addNewDevice(state, action) {
            const previousState = cloneDeep(state.allowedGroups)
            traverseAndAdd(previousState, action.payload)
        },
        updateDevices(state, action) {
            const previousState = cloneDeep(state.allowedGroups)
            action.payload.map((obj) => traverseAndUpdateDevice(previousState, obj))
            state.allowedGroups = previousState
        },
        addGroup(state, action) {
            const previousState = cloneDeep(state.allowedGroups)
            if (action.payload.parent === '') {
                previousState.push(action.payload)
            } else {
                traverseAndAdd(previousState, action.payload)
            }
            state.allowedGroups = previousState
        },
    }
})
const traverseAndAdd = (sourceObj, newObject) => {
    sourceObj.forEach(obj => {
        if (obj.id === newObject.parent) {
            obj.children.push(newObject)
        } else if (obj.children) {
            traverseAndAdd(obj.children, newObject)
        }
    })
}
const traverseAndUpdateDevice = (sourceObj, newObject) => {
    let found = false
    sourceObj.forEach(source => {
        source.devices.forEach(obj => {
            if (obj.id === newObject.id) {
                obj.name = newObject.name
                obj.description = newObject.description
                obj.serial_number = newObject.serial_number
                obj.latest_telemetry = newObject.latest_telemetry
                obj.device_twin = newObject.device_twin
                found = true
            }
        })
        if (!found) {
            traverseAndUpdateDevice(source.children, newObject)
        }
    })
}
const traverseAndUpdateGroup = (sourceObj, newObject) => {
    sourceObj.forEach(obj => {
        if (obj.id === newObject.id) {
            obj.name = newObject.name
            obj.description = newObject.description
        } else if (obj.children) {
            traverseAndUpdateGroup(obj.children, newObject)
        }
    })
}

/**** EXPORTED ACTIONS AND REDUCERS ****/

export default groupSlice.reducer;
export const {setAllowedGroups, updateGroups, updateDevices, addGroup, addNewDevice} = groupSlice.actions;

/*************** THUNKS ***************/

/** **getGroups** Gets the groups that the logged-in user is allowed to read
 * - Populates an array with objects for each group
 *
 * @returns dispatch - updates the groups administered by the user
 */
export const getGroups = () => {
    return async (dispatch) => {
        try {
            dispatch(setAllowedGroups(await getAllowedGroups()))
        } catch (error) {
            console.error(error)
        }
    }
}

export const getGroupsWithDevices = () => {
    return async (dispatch) => {
        try {
            dispatch(setAllowedGroups(await getAllowedGroupsWithDevices()))
        } catch (error) {
            console.log(error)
        }
    }
}

/** Updates the group information in MSgraph
 * @param {object} updateObject Object with updated group information
 * @returns dispatch - Updates the group state
 */
export const updateGroupInformation = (updateObject) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.updateGroupOrDevice))
            dispatch(updateGroups(await updateGroup(updateObject.description, updateObject.name, updateObject.id, updateObject.type)))
        } catch (error) {
            dispatch(setLoadingError(loaders.updateGroupOrDevice))
        } finally {
            dispatch(removeLoading(loaders.updateGroupOrDevice))
        }
    }
}


/** updates the device serial number new object
 * @param {object} updateObject Object with updated device information
 * @returns dispatch - Add new device object
 */
export const updateDeviceSerialNumberInformation = (updateObject) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.updateGroupOrDevice))
            dispatch(addNewDevice([await updateDevice(updateObject)]))
        } catch (error) {
            console.log(error)
            dispatch(setLoadingError(loaders.updateGroupOrDevice))
        } finally {
            dispatch(removeLoading(loaders.updateGroupOrDevice))
        }
    }
}

/** updates the device object
 * @param {object} updateObject Object with updated device information
 * @returns dispatch - Updates the device state
 */
export const updateDeviceInformation = (updateObject) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.updateGroupOrDevice))
            dispatch(updateDevices([await updateDevice(updateObject)]))
        } catch (error) {
            dispatch(setLoadingError(loaders.updateGroupOrDevice))
        } finally {
            dispatch(removeLoading(loaders.updateGroupOrDevice))
        }
    }
}

export const createGroup = (parentId, description, name) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.createGroup))
            dispatch(addGroup(await createNewGroup(parentId, description, name)))
        } catch (error) {
            dispatch(setLoadingError(loaders.createGroup))
        } finally {
            dispatch(removeLoading(loaders.createGroup))
        }
    }
}
export const deleteGroupFromHierarchy = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.deleteGroup))
            await removeGroup(id)
            await dispatch(getGroupsWithDevices())
        } catch (error) {
            dispatch(setLoadingError(loaders.deleteGroup))
        } finally {
            dispatch(removeLoading(loaders.deleteGroup))
        }
    }
}

export const deleteDeviceFromHierarchy = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.deleteDevice))
            await deleteDevice(id)
            await dispatch(getGroupsWithDevices())
        } catch (error) {
            dispatch(setLoadingError(loaders.deleteDevice))
        } finally {
            dispatch(removeLoading(loaders.deleteDevice))
        }
    }
}

export const updateHierarchy = (tempHierarchy, editedArray) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.updateHierarchy))
            await updateGroupsInHierarchy(editedArray)
            dispatch(setAllowedGroups(tempHierarchy))
            dispatch(addToast({
                id: "updateHierarchy",
                type: toastTypes.success,
                title: "toast:success.ChangesSaved.title",
                body: "toast:success.ChangesSaved.text",
                timeSeconds: 3
            }))
        } catch (error) {
            dispatch(setLoadingError(loaders.updateHierarchy))
        } finally {
            dispatch(removeLoading(loaders.updateHierarchy))
        }
    }
}

export const saveConfigurationByDeviceList = (configuration, devices, comingFromJe) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.saveConfiguration))
            await updateConfigurationViaDeviceList(configuration, devices, comingFromJe)
        } catch (error) {
            dispatch(setLoadingError(loaders.saveConfiguration))
        } finally {
            dispatch(removeLoading(loaders.saveConfiguration))
        }
    }
}

export const saveConfigurationByGroupList = (configuration, groupList, comingFromJe) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.saveConfiguration))
            await updateConfigurationViaGroupList(configuration, groupList, comingFromJe)
        } catch (error) {
            if (error.message?.includes("No devices to configure")) {
                dispatch(addToast({
                    id: "NoDevicesToConfigureToast",
                    type: toastTypes.error,
                    title: "toast:errors.NoDevicesToConfigureToast.title",
                    body: "toast:errors.NoDevicesToConfigureToast.text",
                    timeSeconds: 10
                }))
            } else {
                dispatch(setLoadingError(loaders.saveConfiguration))
            }
        } finally {
            dispatch(removeLoading(loaders.saveConfiguration))
        }
    }
}

export const saveFirmwareConfiguration = (firmwareId, deviceIdList) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.saveFirmwareConfiguration))
            await updateFirmwareConfiguration(firmwareId, deviceIdList)
            dispatch(updateDevices(await getDevices(deviceIdList)))
        } catch (error) {
            dispatch(setLoadingError(loaders.saveFirmwareConfiguration))
        } finally {
            dispatch(removeLoading(loaders.saveFirmwareConfiguration))
        }
    }
}

export const saveTimerPeriodsByDeviceList = (periodList, devices) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.saveConfiguration))
            console.log({periodList, devices})
            await updateTimerPeriodsViaDeviceList(periodList, devices)
            dispatch(addToast({
                id: "saveTimerPeriodsByDeviceList",
                type: toastTypes.success,
                title: "toast:success.ChangesSaved.title",
                body: "toast:success.ChangesSaved.text",
                timeSeconds: 3
            }))
        } catch (error) {
            dispatch(setLoadingError(loaders.saveConfiguration))
        } finally {
            dispatch(removeLoading(loaders.saveConfiguration))
        }
    }
}

export const saveTimerPeriodsByGroupList = (periodList, groupList) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.saveConfiguration))
            console.log({periodList, groupList})
            await updateTimerPeriodsViaGroupList(periodList, groupList)
            dispatch(addToast({
                id: "saveTimerPeriodsByGroupList",
                type: toastTypes.success,
                title: "toast:success.ChangesSaved.title",
                body: "toast:success.ChangesSaved.text",
                timeSeconds: 3
            }))
        } catch (error) {
            dispatch(setLoadingError(loaders.saveConfiguration))
        } finally {
            dispatch(removeLoading(loaders.saveConfiguration))
        }
    }
}
