import React from 'react'
import {useTranslation} from 'react-i18next'
import DeviceConfigurationRow from './configurationrow/DeviceConfigurationRow.js'
import {deviceParams} from "../../../../../helpers/constants.js";
import {paramNames} from "../../../../parameters/parameterSpec.js";
import {LightningFill} from "react-bootstrap-icons";

/** Renders a square with the most important current settings on the device
 * @param {object} data device object
 */
export default function DeviceStatusConfigurations({device, className, style}) {
    const {t} = useTranslation(["dashboard", "telemetry"])

    // TODO: translate?
    const getMainDamperValue = (value) => {
        if (value === "1") return "Open"
        if (value !== "0") return value + " %"
        return "Closed"
    }

    const getStartedByText = () => {
        const startedByValue = device?.latest_telemetry?.[deviceParams.started_by]
        return startedByValue === "5" ? <span>CO₂</span> : t('telemetry:StartedBy_' + startedByValue)
    }

    return (
        <div className={className} style={style}>
            <span className='w-100 small text-bold ps-2 d-md-block d-lg-none font-weight-500'>Configurations</span>
            <div className='w-100'>
                <div
                    className={`d-flex flex-grow-1 ps-2 mb-2 align-items-center ${device.connected ? 'color-success-dim' : 'color-danger-dim'}`}>
                    <LightningFill className='me-1'/><span
                    className='small text-truncate text-nowrap'>{t('common:connectionstate.' + device.connected)}</span>
                </div>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.system_mode')}
                                        value={device.connected ? t('telemetry:SystemMode_' + device?.latest_telemetry?.[deviceParams.system_mode]) : "-"}/>
                <DeviceConfigurationRow title={t('dashboard:status.configuration.started_by')}
                                        value={device.connected ? getStartedByText() : "-"}/>

                {device.installed_components.main_damper &&
                    <DeviceConfigurationRow title={t('dashboard:status.configuration.main_damper')}
                                            value={device.connected ? getMainDamperValue(device?.latest_telemetry?.[deviceParams.main_damper]) : "-"}/>
                }
                {device.installed_components.bypass_damper &&
                    <DeviceConfigurationRow title={t('dashboard:status.configuration.bypass_damer')}
                                            value={device.connected ? device?.latest_telemetry?.[deviceParams.bypass_damper] + " %" : "-"}/>
                }

                {(device.installed_components.water_comfort_heater || device.installed_components.electric_comfort_heater) &&
                    <DeviceConfigurationRow
                        title={device.installed_components.water_comfort_heater ? t('dashboard:status.configuration.comfort_water') : t('dashboard:status.configuration.comfort_electric')}
                        value={device.connected ? device?.latest_telemetry?.[deviceParams.comfort_heater] + " %" : "-"}
                    />}

                {device.installed_components.preheater &&
                    <DeviceConfigurationRow title={t('dashboard:status.configuration.pre_heater')}
                                            value={device.connected ? device?.latest_telemetry?.[deviceParams.pre_heater] + " %" : "-"}
                    />}

                {device.installed_components.cooler &&
                    <DeviceConfigurationRow title={t('dashboard:status.cooling')}
                                            value={device.connected ? device?.latest_telemetry?.[paramNames.cooling_percent] + " %" : "-"}
                    />}

            </div>
        </div>
    )
}