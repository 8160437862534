import React from 'react'
import { Check2 } from 'react-bootstrap-icons'

/** Displays a selection in the dropdown menu for the device log (only effective on non-mobile)
 * @param {boolean} show determines if the selection is selected
 * @param {function} setShow sets the selection as selected / deselected
 * @param {string} headline headline for the selection
 * @param {string} text body text for the selection
 * @param {string} className classes for the wrapper
 */
export default function LogSelection({ show, setShow, circleColor, headline, text, className }) {
    return (
        <div className={className}>
            <div className={`dropdown-selection ${show ? 'dropdown-selected-item' : 'bg-white'}`} onClick={() => { setShow(!show) }}>
                <div className={`rounded-circle border border-1 border-dim d-inline-block ${show ? circleColor : ''}`} style={{ minHeight: "25px", minWidth: "25px", width: "25px", height: "25px" }}>
                    <Check2 className="text-white" width='17px' height='17px' viewBox='-3 -3 17 17' style={{ verticalAlign: 'unset' }} />
                </div>
                <div className="d-inline-block ps-3 d-flex flex-wrap">
                    <span className="col-12" style={{ fontWeight: 500, fontSize: '12px' }}>{headline}</span>
                    <span className=" d-inline-block small text-muted" style={{ fontSize: '12px' }}>{text}</span>
                </div>
            </div>
        </div>
    )
}