import React from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../../components/shared/checkbox/CheckBox'

/** **MobileMenu** Displays the checkboxes for the device log menu (Mobile)
 * 
 * @param {string} className list of classes to wrap the component
 * @param {boolean} showDeviceAlarms determines if alarms are checked
 * @param {boolean} showDeviceConfigurations determines if configurations are checked
 * @param {boolean} showDeviceWarnings determines if warnings are checked
 * @param {function} setShowDeviceAlarms determines if alarms should be checked/unchecked
 * @param {function} setShowDeviceConfigurations determines if alarms should be checked/unchecked
 * @param {function} setShowDeviceWarnings determines if warnings should be checked/unchecked
 */

function MobileMenu({ className, showDeviceConfigurations, showDeviceAlarms, showDeviceWarnings, setShowDeviceConfigurations, setShowDeviceAlarms, setShowDeviceWarnings }) {
  const { t } = useTranslation(['dashboard'])
  return (
    <div className={className}>
      <Checkbox label={t('dashboard:log.filter_dropdown.events.headline')} checked={showDeviceConfigurations} onChange={(e) => { setShowDeviceConfigurations(e.target.checked) }} />
      <Checkbox label={t('dashboard:log.filter_dropdown.alarms.headline')} checked={showDeviceAlarms} onChange={(e) => { setShowDeviceAlarms(e.target.checked) }} />
      <Checkbox label={t('dashboard:log.filter_dropdown.warnings.headline')} checked={showDeviceWarnings} onChange={(e) => { setShowDeviceWarnings(e.target.checked) }} />
    </div>
  )
}

export default MobileMenu