import {ChevronDown, ChevronUp, TrashFill} from 'react-bootstrap-icons'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getComparator} from "../../../helpers/helperfunctions.js"
import Spinner from "react-bootstrap/Spinner"
import {Button} from "react-bootstrap"

export default function ManageFirmwareTableBody({
                                                    className, firmwareListToShow, rowsPerPage,
                                                    page = 1, setErrors,
                                                    setSelectedFirmware, setFirmwareToDelete,
                                                    setFirmwareList, setShowDeleteConfirmation, loadingList
                                                }) {
    const {t} = useTranslation(['firmwarepage'])
    const [sort, setSort] = useState({sortDirection: 'desc', sortValue: 'firmware_version'})
    const [paginatedSortedFirmwareList, setPaginatedSortedFirmwareList] = useState([])

    useEffect(() => {
        setPaginatedSortedFirmwareList(paginateSortFirmwareList())
    }, [rowsPerPage, page, firmwareListToShow, sort])

    const sortHandler = (headerData) => {
        setSort({
            sortDirection: headerData.sortValue === sort.sortValue && sort.sortDirection === 'asc' ? 'desc' : "asc",
            sortValue: headerData.sortValue
        })
    }

    const paginateSortFirmwareList = () => {
        const orderedFirmwareList = firmwareListToShow.sort(getComparator(sort.sortDirection, sort.sortValue))
        return rowsPerPage > 0
            ? orderedFirmwareList.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
            : orderedFirmwareList
    }

    function deleteFirmware(row, e) {
        if (row.saved) {
            setFirmwareToDelete(row)
            setShowDeleteConfirmation(true)
        } else {
            setFirmwareList(firmwareListToShow.filter(o => o.id !== row.id))
            setSelectedFirmware(null)
        }
        e.stopPropagation()
    }

    const firmwareHeaders =
        [
            {
                name: t("firmwarepage:managetable.headers.firmware_version"),
                sortValue: "firmware_version",
                type: "string",
                headerClass: ""
            },
            {
                name: t("firmwarepage:managetable.headers.firmware_filename"),
                sortValue: "firmware_filename",
                type: "string",
                headerClass: ""
            },
            {
                name: t("firmwarepage:managetable.headers.parameter_version"),
                sortValue: "parameter_version",
                type: "string",
                headerClass: ""
            },
            {
                name: t("firmwarepage:managetable.headers.parameter_filename"),
                sortValue: "parameter_filename",
                type: "string",
                headerClass: ""
            },
            {
                name: t("firmwarepage:managetable.headers.description"),
                sortValue: "description",
                type: "string",
                headerClass: ""
            }
        ]

    return (
        <div className={className}>
            <table className='w-100 airmaster-table list-view'>
                <thead>
                <tr>
                    {firmwareHeaders.map((header, idx) =>
                        <th key={idx} className={header.headerClass}>
                            <Button onClick={() => sortHandler(header)}
                                    variant='outline-primary'
                                    active={sort?.sortValue === header.sortValue}
                                    style={{borderRadius: '10px', padding: '0.75rem', paddingLeft: '1.5rem'}}
                                    className='d-flex w-100 text-nowrap'>
                                <span>{header.name}</span>
                                {sort?.sortValue === header.sortValue && <>
                                    {sort.sortDirection === "desc" ?
                                        <ChevronDown className='filter-chevron'/> :
                                        <ChevronUp className='filter-chevron'/>}
                                </>}
                            </Button>
                        </th>
                    )}
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {loadingList && <tr className='bg-transparent'>
                    <td className='p-4' colSpan={7}>
                        <div className='d-flex justify-content-center align-items-center'
                             style={{flex: '1'}}>
                            <Spinner style={{height: '50px', width: '50px'}} animation="border"
                                     variant="primary"
                                     size='lg'></Spinner>
                        </div>
                    </td>
                </tr>}
                {!loadingList && paginatedSortedFirmwareList.map(row =>
                    <tr key={row.id} className={`cursor-pointer`} onClick={() => {
                        setSelectedFirmware(row)
                        setErrors({})
                    }}>
                        <td>{row.firmware_version}</td>
                        <td>{row.firmware_filename}</td>
                        <td>{row.parameter_version}</td>
                        <td>{row.parameter_filename}</td>
                        <td>{row.description}</td>
                        <td className='d-table-cell hover-alert'
                            onClick={(e) => deleteFirmware(row, e)}>
                            <div className='d-flex justify-content-center'>
                                <TrashFill/>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}