import 'react-phone-input-2/lib/style.css'
import {useTranslation} from 'react-i18next';
import useSetLocation from "../../helpers/hooks/useSetLocation.js";
import HelpContentContainer from "./HelpContentContainer.js";

/** Renders the help page */
export default function HelpPage() {
    useSetLocation()
    const {t} = useTranslation(['helppage'])

    return (
        <div className="d-block d-lg-grid help-grid-container mt-4">
            <HelpContentContainer title={t('helppage:content.device_overview.title')} className='grid-area-devices'
                                  description={t('helppage:content.device_overview.description')}>
                <ul className='help-ul'>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_1.url')}
                           target='_blank' rel='noreferrer'>
                        {t('helppage:content.device_overview.videos.video_1.text')}
                    </a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_2.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_2.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_3.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_3.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_4.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_4.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_5.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_5.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_6.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_6.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_7.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_7.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_8.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_8.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.device_overview.videos.video_9.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.device_overview.videos.video_9.text')}</a></li>

                </ul>
            </HelpContentContainer>
            <HelpContentContainer title={t('helppage:content.groups.title')} className='grid-area-groups'
                                  description={t('helppage:content.groups.description')}>
                <ul className='help-ul'>
                    <li><a className='ms-2' href={t('helppage:content.groups.videos.video_1.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.groups.videos.video_1.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.groups.videos.video_2.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.groups.videos.video_2.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.groups.videos.video_3.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.groups.videos.video_3.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.groups.videos.video_4.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.groups.videos.video_4.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.groups.videos.video_5.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.groups.videos.video_5.text')}</a></li>
                </ul>
            </HelpContentContainer>
            <HelpContentContainer title={t('helppage:content.profile.title')} className='grid-area-profile'
                                  description={t('helppage:content.profile.description')}>
                <ul className='help-ul'>
                    <li><a className='ms-2' href={t('helppage:content.profile.videos.video_1.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.profile.videos.video_1.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.profile.videos.video_2.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.profile.videos.video_2.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.profile.videos.video_3.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.profile.videos.video_3.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.profile.videos.video_4.url')} target='_blank'
                           rel='noreferrer'>{t('helppage:content.profile.videos.video_4.text')}</a></li>
                </ul>
            </HelpContentContainer>
            <HelpContentContainer title={t('helppage:content.dashboard.title')} className='grid-area-dashboard'
                                  description={t('helppage:content.dashboard.description')}>
                <ul className='help-ul'>
                    <li><a className='ms-2' href={t('helppage:content.dashboard.videos.video_1.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.dashboard.videos.video_1.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.dashboard.videos.video_2.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.dashboard.videos.video_2.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.dashboard.videos.video_3.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.dashboard.videos.video_3.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.dashboard.videos.video_4.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.dashboard.videos.video_4.text')}</a></li>
                </ul>
            </HelpContentContainer>
            <HelpContentContainer title={t('helppage:content.configuration.title')} className='grid-area-configuration'
                                  description={t('helppage:content.configuration.description')}>
                <ul className='help-ul'>
                    <li><a className='ms-2' href={t('helppage:content.configuration.videos.video_1.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.configuration.videos.video_1.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.configuration.videos.video_2.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.configuration.videos.video_2.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.configuration.videos.video_3.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.configuration.videos.video_3.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.configuration.videos.video_4.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.configuration.videos.video_4.text')}</a></li>
                    <li><a className='ms-2' href={t('helppage:content.configuration.videos.video_5.url')}
                           target='_blank'
                           rel='noreferrer'>{t('helppage:content.configuration.videos.video_5.text')}</a></li>
                </ul>
            </HelpContentContainer>
        </div>
    )
}