import {formatDateTimeString} from "../../../../../helpers/helperfunctions";
import {useTranslation} from "react-i18next";

/** Show tooltip for graphs
 * @param active
 * @param payload
 * @param label
 * @param {int[]} tooltipKeys to show. It is not a mistake that this is outside brackets
 * @returns {JSX.Element|null}
 * @constructor
 */
export const PropertiesToolTip = ({active, payload, label}, tooltipKeys = []) => {
    const {t} = useTranslation(['telemetry'])
    if (!(active && payload && payload.length)) return null;
    return (
        <div style={{minWidth: "200px"}} className="d-flex flex-column custom-tooltip bg-white shadow-lg rounded">
            <span className='p-2 mb-2 w-100 text-white rounded-top py-2 bg-primary'
                  style={{background: "rgb(103, 167, 221)"}}>{formatDateTimeString(label, true, true)}</span>
            {tooltipKeys.map(toolTipKey => <div key={toolTipKey} className="desc px-2 d-flex pb-2">
                <span className='text-muted pe-2' dangerouslySetInnerHTML={{__html: t(`telemetry:${toolTipKey}`)}}/>
                <span className='float-end flex-grow-1 text-end ps-2'>{payload[0].payload[toolTipKey]}</span>
            </div>)}
        </div>
    );
}