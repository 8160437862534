import React, {useState} from 'react'
import GroupsFilterSelector from '../groupsfilterselector/GroupsFilterSelector.js'
import InformationModal from '../informationmodal/InformationModal'
import ModalTopText from '../modaltoptext/ModalTopText'

/** Builds a GroupList from a groups hierarchy
 * @param {boolean} show state used to toggle window visibility
 * @param {function} submit function to submit choosen filters (groups)
 * @param {function} dismiss function to cancel choosen filters (groups)
 * @param {[object]} selectedItemsList groups to process
 * @param {string} modalTitle
 * @param {string} modalBody
 * @param {string} dropzoneBody
 * @returns FilterModal window
 */
export default function FilterModal({show, submit, dismiss, selectedItemsList, modalTitle, modalBody, dropzoneBody}) {
    const [items, setItems] = useState()
    return (
        <InformationModal dialogClassName='group-selector-modal' show={show} title={modalTitle}
                          body={
                              <>
                                  <ModalTopText body={modalBody}/>
                                  <GroupsFilterSelector selectItem={(data) => setItems(data)} selectedItems={selectedItemsList}
                                                        dropzoneBody={dropzoneBody}/>
                              </>
                          } size='lg'
                          accept={() => submit(items)}
                          dismiss={() => dismiss()}/>
    )
}