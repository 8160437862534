import React, {Suspense, useEffect} from 'react';
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react';
import reactDom from 'react-dom';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    Routes,
    useLocation,
    useNavigationType
} from 'react-router-dom'
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing"; // must import after import * as Sentry from "@sentry/react";
import {
    CaptureConsole as CaptureConsoleIntegration,
    ExtraErrorData as ExtraErrorDataIntegration
} from "@sentry/integrations";
import Spinner from "react-bootstrap/Spinner";
import {persistor, store} from "./helpers/reduxstore/reduxConfig.js";
import {MsalProvider} from "@azure/msal-react";
import {msalInstance} from "./api/api.js";
import AuthTemplate from "./pages/authtemplate/AuthTemplate.js";
import LoadWrapper from "./components/shared/loader/LoadWrapper.js";
import NavigationBar from "./components/shared/navigationbar/NavigationBar.js";
import './extensions.js'
import {ignoredErrorsList} from "./helpers/constants.js";

const downgradeErrorToWarningContaining = [
    'got message: Load failed', // This and above seem to be a firefox fetch problem, but until I hear about the app not working on firefox I will ignore it https://github.com/github/fetch/issues/310
    'ResizeObserver', // not an error: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    'interaction_in_progress', // apparantly something is wrong in our login flow but I cannot figure out where and it does not seem to impact login, so just ignoring for now
    'Interaction is currently in progress',
    'useMsalAuthentication',
    'redirect_in_iframe',
    'Redirects are not supported for iframed or brokered applications'
]
let releaseVersion = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;
Sentry.init({
    release: releaseVersion,
    dsn: process.env.REACT_APP_SENTRY_IS_ENABLED === "true" ? process.env.REACT_APP_SENTRY_IO_URL : null,
    ignoreErrors: ignoredErrorsList,
    beforeSend(event) {
        if (event.level === "error") {
            const downgradeHits = downgradeErrorToWarningContaining.filter(downgradeStr =>
                event.exception?.values.some(item => item.value.includes(downgradeStr)) ||
                event?.message?.includes(downgradeStr))
            if ((downgradeHits.length > 0) ||
                (event?.message?.startsWith("transact-") && !event.contexts?.headers)) {
                event.level = "warning" // still record it, but we can filter in Sentry
            }
        }
        return event // need to return, otherwise event is thrown away.
    },
    integrations: [
        new Sentry.Integrations.Breadcrumbs({console: process.env.NODE_ENV !== "development"}), // Turn off breadcrumbs during development so we get correct traces in console log
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            )
        }),
        new ExtraErrorDataIntegration({
            depth: 10
        }),
        new CaptureConsoleIntegration({
            levels: ['warn', 'error']
        })
    ],

    environment: process.env.REACT_APP_ENV,
    normalizeDepth: 10,
    tracesSampleRate: 1.0
});


console.log(`=== Environment: ${process.env.REACT_APP_ENV}`)
console.log(`NODE_ENV: ${process.env.NODE_ENV}`)
console.log(`Sentry is ${process.env.REACT_APP_SENTRY_IS_ENABLED === "true" ? "enabled" : "disabled"}`)
console.log(`Release version: ${releaseVersion}`)
if (process.env.REACT_APP_RESET_REDUX === "true") console.log("process.env.REACT_APP_RESET_REDUX flag is set")

function getLoadSpinner() {
    return <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
        <Spinner style={{height: '60px', width: '60px'}} animation="border" variant="primary" size="lg"></Spinner>
    </div>
}


const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
reactDom.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                {/* Wait for language files to load */}
                <Suspense fallback={getLoadSpinner()}>
                    <MsalProvider instance={msalInstance}>
                        <AuthTemplate>
                            <LoadWrapper>
                                <BrowserRouter>
                                    <NavigationBar Routes={SentryRoutes}/>
                                </BrowserRouter>
                            </LoadWrapper>
                        </AuthTemplate>
                    </MsalProvider>
                </Suspense>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
