import React from 'react'
import {Grid} from 'react-bootstrap-icons'
import NotificationsItemGroupNode from './NotificationsItemGroupNode.js'

/** Renders a hierarchical treeview with groups and devices
 * @param {object} selectedNode selected item
 * @param {function} selectNode Method to call with the current selected item in the hierarchy
 * @param {function} setGroupOpen function that takes groupnode and sets it as open or not open
 * @param {[object]} groups Array of group objects to build the hierarchy
 * @param {object} rootNode Root node to show
 */
export default function NotificationsItemTreeView({selectedNode, selectNode, setGroupOpen, groups, rootNode}) {
    return (
        <ul key={""} className='unstyled p-1 ps-2'>
            <li
                onClick={() => selectNode(rootNode)}
                className={`text-noselect cursor-pointer list-unstyled hover-effect ${selectedNode?.id === '' ? 'node-active' : ''}`}>
                <Grid display='inline' className='me-2'/>
                {rootNode.name}
            </li>
            {groups.map(group =>
                <ul key={group.id} className='unstyled border-start ps-2 mb-0'>
                    <NotificationsItemGroupNode node={group} selectedNode={selectedNode} setGroupOpen={setGroupOpen}
                                                selectNode={selectNode}/>
                </ul>
            )}
        </ul>
    )
}
