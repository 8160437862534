import React, {useState} from 'react'
import InformationModal from '../../../components/shared/informationmodal/InformationModal.js';
import {useTranslation} from 'react-i18next';
import AddUserModal from './addusermodal/AddUserModal.js';
import {useDispatch, useSelector} from 'react-redux';
import EditUserModal from './editusermodal/EditUserModal.js';
import {
    AddUser,
    deleteUserAndGroups,
    editUserAndGroups,
    sendInvitation
} from '../../../helpers/reduxstore/reducers/userAdministrationReducer.js';
import {useMsal} from '@azure/msal-react';
import UserTable from './usertable/UserTable'

/** Constructs an overview of users who have been invited to the platform */
export default function UserManagement() {
    const {t} = useTranslation(['userpage', 'common'])
    const dispatch = useDispatch()
    const [deleteUserModalVisibility, setDeleteUserModalVisibility] = useState(false)
    const [privilegesModalVisibility, setPrivilegesModalVisibility] = useState(false)
    const [editModalVisibility, setEditModalVisibility] = useState(false)
    const [editUserGroups, setEditUserGroups] = useState([])
    const [editUserRole, setEditUserRole] = useState(null)
    const [editUserLanguage, setEditUserLanguage] = useState(null)
    const [editUserId, setEditUserId] = useState(null)

    const [addUserModalVisibility, setAddUserModalVisibility] = useState(false)
    const [deleteUserId, setDeleteUserId] = useState("")
    const {administeredUsers} = useSelector(state => state.userAdministration)
    const {accounts} = useMsal();

    const addUser = (email, role, selectedGroups, preferredLanguage) => {
        setAddUserModalVisibility(!addUserModalVisibility)
        dispatch(AddUser(selectedGroups.map(g => g.id), email.trim(), role, preferredLanguage))
    }

    /** Open edit user modal
     * @param {object} user to edit
     */
    const openEditModal = (user) => {
        setEditUserGroups([])
        setEditUserRole(null)
        setEditUserLanguage(null)

        const selectedAdministeredUser = administeredUsers.find(u => u.userId === user.userId)
        if (selectedAdministeredUser) {
            setEditUserGroups(selectedAdministeredUser.groups)
            setEditUserId(selectedAdministeredUser.userId)
            setEditUserRole(selectedAdministeredUser.userData.appRole)
            setEditUserLanguage(selectedAdministeredUser.userData.preferredLanguage)
        }

        setEditModalVisibility(true)
    }
    const editUser = (selectedGroups, role, preferredLanguage) => {
        dispatch(editUserAndGroups(selectedGroups.map(g => g.id), editUserId, role, preferredLanguage))
        setEditModalVisibility(false)
    }
    const openDeleteModal = (userId) => {
        setDeleteUserId(userId)
        setDeleteUserModalVisibility(true)
    }
    const deleteUser = () => {
        dispatch(deleteUserAndGroups(deleteUserId))
        setDeleteUserModalVisibility(false)
    }
    return (
        <>
            <InformationModal show={deleteUserModalVisibility} title={t('userpage:user_management.delete_modal.title')}
                              body={t('userpage:user_management.delete_modal.body')}
                              accept={() => deleteUser()} dismiss={() => setDeleteUserModalVisibility(false)}/>

            <InformationModal show={privilegesModalVisibility} title={t('userpage:user_management.priv_modal.title')}
                              body={t('userpage:user_management.priv_modal.body')}
                              accept={() => setPrivilegesModalVisibility(!privilegesModalVisibility)}
                              dismiss={() => setPrivilegesModalVisibility(!privilegesModalVisibility)}/>
            <AddUserModal creatorRoleList={accounts[0].idTokenClaims.roles}
                          setVisibility={() => setAddUserModalVisibility(!addUserModalVisibility)}
                          show={addUserModalVisibility} addUser={addUser}/>
            <EditUserModal editorRole={accounts[0].idTokenClaims.roles}
                           setVisibility={() => setEditModalVisibility(!editModalVisibility)} show={editModalVisibility}
                           editUser={editUser} userData={editUserGroups} language={editUserLanguage}
                           userRole={editUserRole}/>
            <div className='boxstyling h-100 px-4 d-flex flex-column mt-xs-2'>
                <div className='pt-4'>
                    <div style={{fontSize: '1.5rem'}} className='font-weight-500'>{t('userpage:user_management.info_title')}</div>
                    <div>{t('userpage:user_management.info_body')}</div>
                </div>

                {/*<TopInformation className='pt-4' title={t('userpage:user_management.info_title')}*/}
                {/*                subTitle={t('userpage:user_management.info_body')}/>*/}

                <UserTable showAddUserModal={() => setAddUserModalVisibility(!addUserModalVisibility)}
                           administeredUsers={administeredUsers} openEditModal={openEditModal}
                           openDeleteModal={openDeleteModal}
                           openPrivilegesmodal={() => setPrivilegesModalVisibility(!privilegesModalVisibility)}
                           sendReinvite={(mail) => dispatch(sendInvitation(mail))}/>
            </div>
        </>
    )
}
