import React from 'react'
import {FolderPlus, FolderSymlink, Pencil, Trash} from 'react-bootstrap-icons';
import {useTranslation} from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown'
import PopOverDialog from '../../../components/shared/popoverdialog/PopOverDialog';
import GroupTreeView from '../../../components/shared/treeview/GroupTreeView.js';
import EditButtonGroup from '../../../components/shared/editbuttongroup/EditButtonGroup';
import {useMsal} from "@azure/msal-react";
import {isViewer} from "../../../helpers/authHelper.js";
import {featureFlags} from "../../../helpers/constants.js";

/** Renders a treeview with controlling elements - Allows for performing CRUD operations on the treeview
 * @param {[object]} groupData Array with complete hierarchy
 * @param {object} selectedData object with details about the selected item
 * @param {[object]} selectedNodeList array of selected nodes
 * @param {function(object)} setSelectedNodeList function to select new node
 * @param {function} itemSelect Method to call when a new item is selected
 * @param {function} addGroup Method to call to add a new group
 * @param {function} deleteGroup Method to call to delete a selected group
 * @param {function} editing Method to call if editing is manipulated from component
 * @param {function} dropItem Method to edit hierarchy when an item is dropped
 * @param {function} saveEdits Method to save edits currently made
 * @param {boolean} isEditEnabled propagates from parent to child to control editing
 * @param {function} cancelEdits Method to cancel editing the tree
 */
export default function GroupTreeviewSelector({
                                                  groupData, selectedData, selectedNodeList,
                                                  setSelectedNodeList, itemSelect, addGroup, deleteGroup,
                                                  editing, dropItem, saveEdits, isEditEnabled, cancelEdits
                                              }) {
    const {t} = useTranslation(['grouppage'])
    const {accounts} = useMsal()

    return (
        <div className="mt-4 mb-4 d-flex flex-column">
            {/* === Title === */}
            <h4 className='mt-xs-2'>{t('grouppage:group_management.title')}
                <PopOverDialog popOverTitel={t('grouppage:group_management.popover.popover_header')}
                               popOverContent={t('grouppage:group_management.popover.popover_content')}
                               headerColor="info" trigger={['hover', 'touch']} iconName="infocirclefill"/>
            </h4>


            {/* === groups and devices tree view === */}
            <div className="pt-2 flex-grow-1 d-flex" style={{height: "60vh"}}>

                <div className={isEditEnabled ? 'hierarchy-active me-3' : 'hierarchy-inactive'}
                     style={{flex: 'initial'}}>
                    <GroupTreeView groups={groupData} setSelectedItem={itemSelect}
                                   hasRoot={true} allowDragover={true} selectedNodeList={selectedNodeList}
                                   setSelectedNodeList={setSelectedNodeList} isDraggable={isEditEnabled}
                                   itemDropped={dropItem} shouldDisplayDevices={true} showSearchField={true}
                    />
                </div>

                {isEditEnabled &&
                    <div className={isEditEnabled ? 'hierarchy-active' : 'hierarchy-inactive'}
                         style={{flex: 'initial'}}>
                        <GroupTreeView groups={groupData} setSelectedItem={itemSelect}
                                       hasRoot={true} allowDragover={true} selectedNodeList={selectedNodeList}
                                       setSelectedNodeList={setSelectedNodeList} isDraggable={isEditEnabled}
                                       itemDropped={dropItem} shouldDisplayDevices={true} showSearchField={true}
                        />
                    </div>
                }
            </div>

            {/* === ButtonGroup === */}
            {!isViewer(accounts) &&
                <>
                    {isEditEnabled ?
                        featureFlags.multiDragAndDrop ?
                            <EditButtonGroup size="sm" className="pt-2 ms-auto" cancelClick={cancelEdits}
                                             infoelement={<PopOverDialog
                                                 popOverTitel={t('grouppage:editbuttongroup.info.title')}
                                                 popOverContent={t('grouppage:editbuttongroup.info.content')}
                                                 trigger={['hover', 'touch']}/>}
                                             submitClick={saveEdits}/>
                            : <EditButtonGroup size="sm" className="pt-2 ms-auto" cancelClick={cancelEdits}
                                               submitClick={saveEdits}/>
                        :
                        <Dropdown className="col-6 pt-2 ms-auto">
                            <Dropdown.Toggle type="button" id="dropdown-basic" variant='outline-secondary'
                                             className="col-12 btn">
                                <Pencil className="me-1"/>{t('grouppage:group_management.dropdown_title')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="pb-0" align="right" id="dropdown-menu-align-right">
                                <Dropdown.Item onClick={() => addGroup()}
                                               disabled={selectedData.type === "" || selectedData.type === "device" || selectedData.canEdit === 0}>
                                    <FolderPlus className="me-3 mb-1"/>
                                    {t('grouppage:group_management.dropdown_selections.add')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => deleteGroup()}
                                               disabled={!(selectedData.type === "group")}>
                                    <Trash className="mb-1 me-3"/>
                                    {t('grouppage:group_management.dropdown_selections.delete')}
                                </Dropdown.Item>
                                <div className="dropdown-divider" />
                                <Dropdown.Item className="mb-2" onClick={() => editing(true)}>
                                    <FolderSymlink className="me-3 mb-1"/>
                                    {t('grouppage:group_management.dropdown_selections.edit')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </>
            }
        </div>
    )
}