import React from 'react'
import Form from 'react-bootstrap/Form'
import {getBorderErrorOrWarningClassFromMessage} from "../../../helpers/uiHelper.js";

/** Renders an input group with an input control and corresponding Feedback for handling invalid inputs
 * @param {string} className classes to pass to the Form.Group
 * @param {label} label to display title above the input box
 * @param {boolean} disabled property to control the state of the Form.Control
 * @param {boolean} isInvalid to inform about the validity of the entry
 * @param {string} type to decide the type of the input (e.g. text, password, email)
 * @param {string} name of the input box
 * @param {string} value is a value that can be passed to the input
 * @param {string | html} unit unit which goes at end
 * @param {function(onChange)} inputValue function to forward the input on change
 * @param {string} placeholder to set the default text in the input if value is empty
 * @param {boolean} hasLabel determines if the label element should be added to the dom
 * @param {array[{type: '', text: ''}]} messages warning or error messages to show
 * @param {number} step step value, default 1
 * @param {number} min min value for number input field
 * @param {number} max max value for number input field
 * @param {JSX.Element} infoelement
 */
export default function InputGroupWithMessages({
                                                   className, label, disabled, type, name, value, unit = "",
                                                   inputValue, placeholder, hasLabel = true, messages = [], step = 1,
                                                   min, max, infoelement
                                               }) {
    return (
        <Form.Group className={className}>
            {hasLabel &&
                <div className='mb-2 position-relative'><span
                    dangerouslySetInnerHTML={{__html: label}}></span>{infoelement && infoelement}</div>
            }
            <div className='d-flex align-items-center'>
                {/* MaxWidth so same width as range elements, wanted during new layout demo */}
                <Form.Control style={{maxWidth: '94.8594px'}}
                              className={`shadow-none remove-autofill-overlay ${getBorderErrorOrWarningClassFromMessage(messages)}`}
                              inputMode={type === 'number' ? 'decimal' : undefined} disabled={disabled} type={type}
                              name={name} value={value} onChange={inputValue} step={step} placeholder={placeholder}
                              min={min} max={max}/>
                {unit &&
                    <span className='ms-1' dangerouslySetInnerHTML={{__html: unit}}/>
                }
            </div>
            {messages.map((object, i) => <div className={`color-${object.type}`} key={i}>{object.text}</div>)}
        </Form.Group>
    )
}