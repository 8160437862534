import React from "react";

/** Renders a box which displays a quality number value
 * @param {string} className classes for the wrapper of the component
 * @param {boolean} isInstalled defines if the sensor is installed (if not installed, the component will display accordingly)
 * @param {boolean} hasError defines if the sensor has an error (if an error has occured, the component will display accordingly)
 * @param {string} valuePostFix postfix to append after the value (i.e. PPM)
 * @param {string} activeTitleText to display if the sensor is active
 * @param {string} defectText text to display if the sensor is defect
 * @param {string} notInstalledText text to state if the sensor is not installed
 * @param {string} sensorValue current value of the sensor reading
 * @param {string} darkColor determines the color which should be displayed in the circle background and text color
 * @param {string} lightColor determines the color which should be displayed on the background of the square
 * @param {JSX.Element} icon to display to the left of the sensor value
 * @param {boolean} deviceConnected whether device is connected or not. If not connected, show dash (-) instead of value.
 */
export default function QualityNumberBox({
                                             className, isInstalled, hasError, valuePostFix,
                                             activeTitleText, defectText, notInstalledText, sensorValue,
                                             darkColor, lightColor, icon, deviceConnected
                                         }) {

    return (
        <div className={className}>
            {!isInstalled &&
                <div className='telemetry-box-wrapper bg-light'>
                    <div className='d-block text-truncate text-muted text-wrap'
                         style={{fontWeight: 500}}>{notInstalledText}</div>
                </div>}
            {isInstalled && hasError &&
                <div className='telemetry-box-wrapper bg-light'>
                    <div className='d-block text-truncate text-muted text-wrap'
                         style={{fontWeight: 500}}>{defectText}</div>
                </div>}
            {isInstalled && !hasError &&

                // <div className='telemetry-box-wrapper p-1 position-relative'
                <div className='rounded p-1 position-relative telemetry-box-wrapper'
                     style={{backgroundColor: lightColor, color: darkColor}}>
                    <div
                        className='w-100 h-100 d-flex flex-column justify-content-center align-items-center position-relative'
                        // style={{background: `linear-gradient(to right, ${limitColor} ${(parseFloat(sensorValue) / limit) * 100}%, transparent 0%)`}}
                    >
                        <div style={{fontSize: '11px', top: '10px'}} className='text-truncate position-absolute'>
                            {activeTitleText}
                        </div>
                        <span className='position-absolute font-weight-500' style={{fontSize: '72px', top: '8px'}}>
                            {deviceConnected ? sensorValue : "-"}
                            <span className='position-absolute'
                                  style={{right: -26, bottom: 20, fontSize: '12px'}}>{valuePostFix}</span>
                        </span>
                        <div style={{bottom: '10px'}} className='position-absolute'>{icon}</div>
                    </div>
                </div>

            }
        </div>
    )
}