import React, {useEffect, useRef, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import TopInformation from '../../components/shared/topinformation/TopInformation.js'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux';
import TFooter from "../../components/shared/tfooter/TFooter.js";
import {FirmwareNavBar} from "./FirmwareNavBar.js";
import {CloudArrowDown} from 'react-bootstrap-icons'
import ManageFirmwareTableFilterSearch
    from "../../components/firmware/managefirmwaretable/ManageFirmwareTableFilterSearch.js";
import ManageFirmwareTableBody from "../../components/firmware/managefirmwaretable/ManageFirmwareTableBody.js";
import InformationModal from "../../components/shared/informationmodal/InformationModal.js";
import useNavigationGuardByRole from "../../helpers/hooks/useNavigationGuardByRole.js";
import {roleConstants, toastTypes} from "../../helpers/constants.js";
import {omit} from "lodash";
import {createFirmware, deleteFirmware, editFirmware, getFirmwareFile, getFirmwareList} from "../../api/api.js";
import useSetLocation from "../../helpers/hooks/useSetLocation.js";
import {saveBase64String} from "../../helpers/helperfunctions.js";
import {addToast} from "../../helpers/reduxstore/reducers/toastReducer.js";
import {setFirmwareFilter} from "../../helpers/reduxstore/reducers/filterReducer.js";

/** Renders firmware page */
export default function ManageFirmwarePage() {
    useNavigationGuardByRole([roleConstants.Admin])
    useSetLocation()
    const {t} = useTranslation(['firmwarepage', 'common'])
    const dispatch = useDispatch()
    const [firmwareList, setFirmwareList] = useState([])
    const [firmwareListToShow, setFirmwareListToShow] = useState([])
    const [searchString, setSearchString] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [selectedFirmware, setSelectedFirmware] = useState(null)
    const [firmwareToDelete, setFirmwareToDelete] = useState(null)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [errors, setErrors] = useState({})
    const [loadingList, setLoadingList] = useState(true)
    const {firmwareFilter} = useSelector(state => state.filter)
    const [saveInProgress, setSaveInProgress] = useState(false)
    const [downloadFirmwareFileInProgress, setDownloadFirmwareFileInProgress] = useState("")

    useEffect(() => {
        setSelectedFirmware(null)
        setErrors({});
        getAndStoreFirmwareList().then();
    }, [firmwareFilter])

    function getAndStoreFirmwareList() {
        setLoadingList(true)
        return getFirmwareList(firmwareFilter?.deviceFamily ?? "je").then(resList => {
            setLoadingList(false)
            setFirmwareList(resList.map((f) => {
                return {...f, saved: true}
            }))
        })
    }

    const firmwareFileInputRef = useRef(null);
    const [firmwareFile, setFirmwareFile] = useState(null);
    const selectFirmwareFile = () => {
        if (firmwareFileInputRef.current?.files[0]) {
            setFirmwareFile(firmwareFileInputRef.current.files[0])
            setSelectedFirmware({...selectedFirmware, firmware_filename: firmwareFileInputRef.current.files[0].name})
            setErrors(omit(errors, "firmwareFile"))
        }
    };

    const parameterFileInputRef = useRef(null);
    const [parameterFile, setParameterFile] = useState(null);
    const selectParameterFile = () => {
        if (parameterFileInputRef.current?.files[0]) {
            setParameterFile(parameterFileInputRef.current.files[0])
            setSelectedFirmware({...selectedFirmware, parameter_filename: parameterFileInputRef.current.files[0].name})
            setErrors(omit(errors, "parameterFile"))
        }
    };

    // Filter and search
    useEffect(() => {
        const searchedDevices = firmwareList.filter(firmware => searchFirmwarePropsForSubstring(searchString, firmware));
        setFirmwareListToShow(searchedDevices)
    }, [firmwareList, searchString])

    const searchFirmwarePropsForSubstring = (searchTerm, firmware) => {
        let stringToSearch = `${firmware.description} ${firmware.firmware_filename} 
        ${firmware.firmware_version} ${firmware.parameter_filename}`
        return (stringToSearch.toLowerCase().includes(searchTerm.toString().toLowerCase()))
    }

    async function save() {
        setSaveInProgress(true)
        if (selectedFirmware.saved) {
            try {
                await editFirmware({FirmwareId: selectedFirmware.id, description: selectedFirmware.description})
                await getAndStoreFirmwareList()
                dispatch(addToast({
                    id: "editFirmware",
                    type: toastTypes.success,
                    title: "toast:success.ChangesSaved.title",
                    body: "toast:success.ChangesSaved.text",
                    timeSeconds: 3
                }))
            } catch (e) {
                console.error(e.message)
                dispatch(addToast({
                    id: "editFirmwareError",
                    type: toastTypes.error,
                    title: "toast:errors.saveFirmwareConfiguration.title",
                    body: "toast:errors.saveFirmwareConfiguration.text",
                    timeSeconds: 5
                }))
            } finally {
                setSaveInProgress(false)
            }
        } else {
            // validate
            const errorObj = {}
            if (!firmwareFile) errorObj["firmwareFile"] = t('firmwarepage:errors.missing_firmwarefile')
            if (!selectedFirmware.firmware_version) errorObj["firmwareVersion"] = t('firmwarepage:errors.missing_firmware_version')
            if (!parameterFile) errorObj["parameterFile"] = t('firmwarepage:errors.missing_parameterfile')
            if (!selectedFirmware.parameter_version) errorObj["parameterVersion"] = t('firmwarepage:errors.missing_parameter_version')
            if (Object.keys(errorObj).length > 0) {
                setErrors(errorObj)
                return
            }

            let formData = new FormData();
            formData.set("Description", selectedFirmware.description)
            formData.set("FirmwareFile", firmwareFile)
            formData.set("FirmwareVersion", selectedFirmware.firmware_version)
            formData.set("ParameterFile", parameterFile)
            formData.set("ParameterVersion", selectedFirmware.parameter_version)
            formData.set("IsAware", firmwareFilter?.deviceFamily === 'aware')

            try {
                await createFirmware(formData)
                await getAndStoreFirmwareList()
                dispatch(addToast({
                    id: "createFirmware",
                    type: toastTypes.success,
                    title: "toast:success.ChangesSaved.title",
                    body: "toast:success.ChangesSaved.text",
                    timeSeconds: 3
                }))
            } catch (e) {
                console.error(e.message)
                dispatch(addToast({
                    id: "createFirmwareError",
                    type: toastTypes.error,
                    title: "toast:errors.saveFirmwareConfiguration.title",
                    body: "toast:errors.saveFirmwareConfiguration.text",
                    timeSeconds: 5
                }))
            } finally {
                setSaveInProgress(false)
            }

            setSelectedFirmware(null)
            setErrors({});
            setFirmwareFile(null)
            setParameterFile(null)
        }
    }

    async function executeDeleteFirmware() {
        setShowDeleteConfirmation(false)
        if (selectedFirmware?.id === firmwareToDelete.id) setSelectedFirmware(null)
        if (firmwareToDelete.saved) {
            try {
                await deleteFirmware(firmwareToDelete.id)
                await getAndStoreFirmwareList()
            } catch (e) {
                console.error(e.message)
            }
        }
        setErrors({});
    }

    function addFirmware() {
        let newFirmware = {
            "id": "",
            "firmware_filename": null,
            "firmware_version": "",
            "parameter_filename": null,
            "parameter_version": "",
            "description": ""
        };
        setFirmwareList([newFirmware, ...firmwareList])
        setSelectedFirmware(newFirmware)
        setErrors({});
    }

    const changeSelectedFirmwareVersion = (event) => {
        setSelectedFirmware({...selectedFirmware, firmware_version: event.target.value})
        setErrors(omit(errors, "firmwareVersion"))
    }

    const changeSelectedParameterVersion = (event) => {
        setSelectedFirmware({...selectedFirmware, parameter_version: event.target.value})
        setErrors(omit(errors, "parameterVersion"))
    }

    async function downloadFile(id, fileName) {
        console.log("Downloading file", fileName)
        setDownloadFirmwareFileInProgress(fileName)
        const res = await getFirmwareFile(`${id}_${fileName}`)
        setDownloadFirmwareFileInProgress("")
        saveBase64String(fileName, res.fileContent)
    }

    function setDeviceFamilyFilter(newFamily) {
        dispatch(setFirmwareFilter({deviceFamily: newFamily}))
    }

    return (
        <>
            <InformationModal show={showDeleteConfirmation} title={t('firmwarepage:modal.delete.title')}
                              body={t('firmwarepage:modal.delete.text', {version: firmwareToDelete?.firmware_version ?? ""})}
                              dismiss={() => setShowDeleteConfirmation(false)} accept={executeDeleteFirmware}/>

            <div className="d-flex flex-column" style={{flex: '1'}}>
                <TopInformation title={t('firmwarepage:info_section.info_section_title')}
                                subTitle={t('firmwarepage:info_section.info_section_p')}/>
                <FirmwareNavBar currentPage={'manage'}/>

                <ManageFirmwareTableFilterSearch className="d-block d-md-flex pt-4 pb-2 flex-wrap"
                                                 totalFirmwareNum={firmwareList.length}
                                                 shownFirmwareNum={firmwareListToShow.length}
                                                 searchString={searchString}
                                                 setSearchString={setSearchString}
                                                 deviceFamily={firmwareFilter?.deviceFamily}
                                                 setDeviceFamily={setDeviceFamilyFilter}
                />

                <ManageFirmwareTableBody className={'mb-2'} firmwareListToShow={firmwareListToShow}
                                         loadingList={loadingList} setErrors={setErrors}
                                         rowsPerPage={rowsPerPage} setFirmwareListToShow={setFirmwareListToShow}
                                         page={page} setSelectedFirmware={setSelectedFirmware}
                                         setShowDeleteConfirmation={setShowDeleteConfirmation}
                                         setFirmwareList={setFirmwareList} deviceFamily={firmwareFilter?.deviceFamily}
                                         setFirmwareToDelete={setFirmwareToDelete}/>

                <TFooter className="mb-3" setPage={setPage} rowsPerPage={rowsPerPage}
                         setRowsPerPage={setRowsPerPage} dataLength={firmwareListToShow.length}
                         page={page}/>

                <div>
                    <Button className={'mb-4'} style={{minWidth: '100px'}} variant='outline-secondary'
                            onClick={() => addFirmware()}
                            type="submit">
                        {t("firmwarepage:buttons.add_firmware")}</Button>
                </div>

                {/* === Create / Edit firmware fields === */}
                {selectedFirmware &&
                    <>
                        <div className="d-flex d-xs-block mb-2 position-relative">
                            <div className="p-2" style={{flex: 1}}>

                                <div className="d-flex align-items-center mb-1">
                                    <span style={{flex: 1}}
                                          className="text-nowrap">{t('firmwarepage:edit.firmware_version')}</span>
                                    <Form.Control style={{flex: 2}}
                                                  className={`shadow-none remove-autofill-overlay ${errors.firmwareVersion ? 'border-color-danger' : ''}`}
                                                  value={selectedFirmware.firmware_version}
                                                  disabled={selectedFirmware.saved}
                                                  onChange={changeSelectedFirmwareVersion}
                                                  type="text"/>
                                </div>
                                {errors.firmwareVersion && <div className="color-danger">{errors.firmwareVersion}</div>}

                                <div className="d-flex align-items-center mb-1 mt-2">
                                    <label style={{flex: 1}} htmlFor="firmware_file_input"
                                           className="text-nowrap">{t('firmwarepage:edit.firmware_file')}</label>
                                    <input id="firmware_file_input" className="d-none" type="file"
                                           ref={firmwareFileInputRef} onChange={selectFirmwareFile}/>
                                    <Button style={{flex: 2}} variant='outline-secondary'
                                            className={`${errors.firmwareFile ? 'border-color-danger' : ''}`}
                                            onClick={selectedFirmware.saved ? () => downloadFile(selectedFirmware.id, selectedFirmware.firmware_filename) : () => firmwareFileInputRef.current?.click()}>
                                        {selectedFirmware.firmware_filename
                                            ? <div>{selectedFirmware.firmware_filename} {selectedFirmware.saved &&
                                                <CloudArrowDown/>} {downloadFirmwareFileInProgress === selectedFirmware.firmware_filename ?
                                                <span className="spinner-border spinner-border-lg text-secondary me-2"
                                                      style={{height: '16px', width: '16px'}}/> : <></>}</div>
                                            : t('firmwarepage:edit.firmware_file_btn_placeholder')
                                        }
                                    </Button>
                                </div>
                                {errors.firmwareFile && <div className="color-danger">{errors.firmwareFile}</div>}

                                <div className="d-flex align-items-center mb-1 mt-2">
                                    <span style={{flex: 1}}
                                          className="text-nowrap">{t('firmwarepage:edit.parameter_version')} {firmwareFilter?.deviceFamily === 'aware' ? "*" : ""}</span>
                                    <Form.Control style={{flex: 2}}
                                                  className={`shadow-none remove-autofill-overlay ${errors.parameterVersion ? 'border-color-danger' : ''}`}
                                                  value={selectedFirmware.parameter_version}
                                                  disabled={selectedFirmware.saved}
                                                  onChange={changeSelectedParameterVersion}
                                                  type="text"/>
                                </div>
                                {errors.parameterVersion &&
                                    <div className="color-danger">{errors.parameterVersion}</div>}

                                <div className="d-flex align-items-center mt-2">
                                    <label style={{flex: 1}} htmlFor="parameter_file_input"
                                           className="text-nowrap">{t('firmwarepage:edit.parameter_file')} {firmwareFilter?.deviceFamily === 'aware' ? "*" : ""}</label>
                                    <input id="parameter_file_input" className="d-none" type="file"
                                           ref={parameterFileInputRef} onChange={selectParameterFile}/>
                                    <Button style={{flex: 2}} variant='outline-secondary'
                                            disabled={selectedFirmware.saved && !selectedFirmware.parameter_filename}
                                            className={`${errors.parameterFile ? 'border-color-danger' : ''}`}
                                            onClick={selectedFirmware.saved ? () => downloadFile(selectedFirmware.id, selectedFirmware.parameter_filename) : () => parameterFileInputRef.current?.click()}>

                                        {selectedFirmware.parameter_filename
                                            ? <div>{selectedFirmware.parameter_filename} {selectedFirmware.saved &&
                                                <CloudArrowDown/>} {downloadFirmwareFileInProgress === selectedFirmware.parameter_filename ?
                                                <span className="spinner-border spinner-border-lg text-secondary me-2"
                                                      style={{height: '16px', width: '16px'}}/> : <></>}</div>
                                            : t('firmwarepage:edit.parameter_file_btn_placeholder')
                                        }

                                    </Button>
                                </div>
                                {errors.parameterFile && <div className="color-danger">{errors.parameterFile}</div>}
                            </div>

                            <div className="p-2" style={{flex: 1}}>
                                <Form.Control as="textarea" className="h-100 shadow-none remove-autofill-overlay"
                                              value={selectedFirmware.description ?? ""}
                                              onChange={(event) => setSelectedFirmware({
                                                  ...selectedFirmware,
                                                  description: event.target.value
                                              })} placeholder={t('firmwarepage:edit.description')}/>
                            </div>
                        </div>

                        <div>
                            <Button className={'mb-4'} style={{minWidth: '100px'}} variant='outline-secondary'
                                    type="submit"
                                    disabled={saveInProgress} onClick={save}>
                                {saveInProgress &&
                                    <span className="spinner-border spinner-border-lg text-secondary me-2"
                                          style={{height: '16px', width: '16px'}}/>
                                }
                                {selectedFirmware?.saved ? t("common:buttons.edit") : t("common:buttons.save")}
                            </Button>
                        </div>
                    </>
                }
            </div>
        </>
    )
}