import {createSlice} from "@reduxjs/toolkit";

/**
 * This reducer is meant to keep track of local userselected filter state, like which groups are being vieved or
 * how many items to show in dashboard. So it has nothing to do with device mechanical dustfilters.
 */

/***************** STATE ****************/
const initialState = {
    overviewFilter: {
        rowsPerPage: 10,
        filterGroups: [],
        deviceListConditionFilter: {
            alarm: false, warning: false, ok: false, disconnected: false
        },
        listViewChosen: false,
        deviceListSort: {
            sortDirection: "asc",
            sortValue: "name"
        },
        searchString: "",
        page: 1
    },
    firmwareFilter: {
        filterGroups: [],
        firmwareVersionFilter: "",
        deviceTypeFilter: "",
        rowsPerPage: 10,
        deviceListSort: {
            sortDirection: "asc",
            sortValue: "serial_number"
        },
        searchString: "",
        page: 1,
        deviceFamily: "je"
    }
};

/************ STATE SLICE **************/

const filterSlice = createSlice({
    name: 'filter',
    initialState: initialState,
    reducers: {
        setOverviewFilter(state, action) {
            state.overviewFilter = {...state.overviewFilter, ...action.payload}
        },
        setFirmwareFilter(state, action) {
            state.firmwareFilter = {...state.firmwareFilter, ...action.payload}
        },
    }
})

/**** EXPORTED ACTIONS AND REDUCERS ****/
export default filterSlice.reducer;
export const {setOverviewFilter, setFirmwareFilter} = filterSlice.actions;