import {loadCurrentDevice, loadCurrentDeviceIfNotModified} from "./reduxstore/reducers/deviceReducer.js";

/** retrieve and set device if changed
 * @param device
 * @param deviceSerialNumber
 * @param dispatch */
export function loadCurrentDeviceWrapper(device, deviceSerialNumber, dispatch) {
    if (device.serial_number !== "0000000" && device.serial_number === deviceSerialNumber && device["_etag"]) {
        dispatch(loadCurrentDeviceIfNotModified(device.id, device["_etag"]))
    } else {
        dispatch(loadCurrentDevice(deviceSerialNumber))
    }
}

