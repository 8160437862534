export default function AdminOperationsNavBar({rawResults, currentTab, setCurrentTab}) {
    return <div className='mt-4 mb-3 ms-2 px-0'>
        <div className='d-inline-block text-decoration-none' onClick={() => setCurrentTab("joblog")}>
                    <span
                        className={`cursor-pointer px-3 ${currentTab === 'joblog' ? 'dashboard-navbar-link' : 'text-muted'}`}>
                        Jobkørsler ({rawResults.jobLogList.length})
                    </span>
        </div>
        <div className='d-inline-block text-decoration-none' onClick={() => setCurrentTab("emaillog")}>
                    <span
                        className={`cursor-pointer px-3 ${currentTab === 'emaillog' ? 'dashboard-navbar-link' : 'text-muted'}`}>
                        Emails sendt ({rawResults.emailsSentList.length})
                    </span>
        </div>
        <div className='d-inline-block text-decoration-none' onClick={() => setCurrentTab("loginlog")}>
                    <span
                        className={`cursor-pointer px-3 ${currentTab === 'loginlog' ? 'dashboard-navbar-link' : 'text-muted'}`}>
                        Logins
                    </span>
        </div>
        <div className='d-inline-block text-decoration-none' onClick={() => setCurrentTab("auditlog")}>
                    <span
                        className={`cursor-pointer px-3 ${currentTab === 'auditlog' ? 'dashboard-navbar-link' : 'text-muted'}`}>
                        Audit
                    </span>
        </div>
        <div className='d-inline-block text-decoration-none' onClick={() => setCurrentTab("statistics")}>
                    <span
                        className={`cursor-pointer px-3 ${currentTab === 'statistics' ? 'dashboard-navbar-link' : 'text-muted'}`}>
                        Statistik
                    </span>
        </div>
    </div>
}