import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {fetchDeviceLog} from '../../../helpers/reduxstore/reducers/deviceLogReducer.js'
import NavTopBar from "../../../components/shared/device/NavTopBar.js";
import useSetLocation from "../../../helpers/hooks/useSetLocation.js";
import {IfDeviceFound} from "../../../components/dashboard/IfDeviceFound.js";
import TelemetryBoxes from "../../../components/dashboard/telemetryboxes/TelemetryBoxes.js";
import DashboardStatusBox from "../../../components/dashboard/dashboardstatusbox/DashboardStatusBox.js";
import DeviceLog from "../../../components/dashboard/devicelog/DeviceLog.js";
import TelemetryCharts from "../../../components/dashboard/telemetrycharts/TelemetryCharts.js";
import Spinner from "react-bootstrap/Spinner";
import {DevicePagesTopInformation} from "../technical/DevicePagesTopInformation.js";
import {isAwareDevice} from "../../../helpers/helperfunctions.js";
import {updateSelectedDashboardGraphs} from "../../../helpers/reduxstore/reducers/telemetryReducer.js";
import {
    graphTypes,
    isGraphValidForDevice
} from "../../../components/dashboard/telemetrycharts/telemetryChartsHelper.js";
import useSetAmxOfflineIfTelemetryOld from "../../../helpers/hooks/useSetAmxOfflineIfTelemetryOld.js";

/** Renders the dashboard for individual devices */
export default function DeviceDashboardPage() {
    useSetLocation()
    const [logList, setLogList] = useState([])
    const dispatch = useDispatch()
    const {deviceSerialNumber} = useParams();
    const {selectedDashboardGraphs} = useSelector(t => t.telemetry)
    const {currentDevice} = useSelector(l => l.device)
    // const etagRef = useRef("'none'");
    useSetAmxOfflineIfTelemetryOld()

    // use a cached device for anything other than graphs to bind to. Graphs should not be updated automatically, it
    // makes for bad user experience.
    const [cachedDevice, setCachedDevice] = useState(currentDevice)
    useEffect(() => {
        setCachedDevice(currentDevice)
    }, [currentDevice])

    // TODO: Does not work, still rerenders telemetry graph
    // useInterval(() => {
    //     console.log(etagRef.current)
    //     if (cachedDevice.serial_number !== "0000000" && cachedDevice.serial_number === deviceSerialNumber && etagRef.current) {
    //         getDeviceByIdIfNotModified(cachedDevice.id, etagRef.current).then(d => {
    //             if (d) {
    //                 setCachedDevice(d)
    //                 etagRef.current = d["_etag"]
    //             }
    //         })
    //     } else {
    //         getDeviceBySerialNumber(deviceSerialNumber).then(d => {
    //             setCachedDevice(d)
    //             etagRef.current = d["_etag"]
    //         })
    //     }
    // }, 15 * 1000)

    const {deviceLog} = useSelector(l => l.deviceLog)
    useEffect(() => {
        if (currentDevice.serial_number === "0000000") return;
        setLogList(deviceLog.id !== currentDevice.serial_number ? currentDevice.logs : deviceLog.data)
    }, [deviceLog, currentDevice])

    const requestLogData = (fromDateStr, toDateStr) => {
        if (fromDateStr && toDateStr) {
            // We add one day to toDate because API toDate is not inclusive
            const toDatePlusOne = new Date(toDateStr)
            toDatePlusOne.setDate(toDatePlusOne.getDate() + 1)
            dispatch(fetchDeviceLog(currentDevice.serial_number, fromDateStr, toDatePlusOne.toISOString().split("T")[0]))
        }
    }

    const jeGraphOptionList = [
        ...isGraphValidForDevice(currentDevice, graphTypes.simpleCO2) ? [graphTypes.simpleCO2] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.simpleTVOC) ? [graphTypes.simpleTVOC] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.multiHumidity) ? [graphTypes.multiHumidity] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.simpleAirflow) ? [graphTypes.simpleAirflow] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.multiPowerConsumption) ? [graphTypes.multiPowerConsumption] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.multiTemperature) ? [graphTypes.multiTemperature] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.multiAirflowM3H) ? [graphTypes.multiAirflowM3H] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.stepStartedBy) ? [graphTypes.stepStartedBy] : [],
        ...isGraphValidForDevice(currentDevice, graphTypes.heater) ? [graphTypes.heater] : []
    ]

    const awareGraphOptionList = [
        graphTypes.simpleCO2,
        graphTypes.simpleAirflow,
        graphTypes.multiTemperature,
        graphTypes.multiAirflowM3H,
        graphTypes.awareStepStartedBy,
        graphTypes.heating_cooling,
        graphTypes.stepMainState,
        graphTypes.stepRecirculationState
    ]

    const defaultGraphList = isAwareDevice(deviceSerialNumber)
        ? [graphTypes.simpleCO2, graphTypes.multiTemperature]
        : [
            ...currentDevice.installed_components.co2_sensor ? [graphTypes.simpleCO2] : [],
            ...currentDevice.installed_components.tvoc_sensor ? [graphTypes.simpleTVOC] : [],
            ...!currentDevice.installed_components.co2_sensor && !currentDevice.installed_components.tvoc_sensor ? [graphTypes.simpleAirflow] : []
        ]

    const setGraphSelections = (payload) => {
        dispatch(updateSelectedDashboardGraphs(payload))
    }

    return (
        <IfDeviceFound>
            <DevicePagesTopInformation device={currentDevice}/>
            <NavTopBar deviceSerialNumber={deviceSerialNumber} currentPage="dashboard"/>

            <TelemetryBoxes className="d-grid d-lg-none telemetry-boxes-grid d-xl-none p-0 pb-3 w-100"
                            device={cachedDevice}/>

            <div className="d-block d-lg-flex mb-3">
                <div style={{flex: '1'}} className="me-lg-2">
                    <TelemetryBoxes
                        className="d-none d-lg-grid telemetry-boxes-grid pb-xl-2 ps-0 col-lg-12"
                        device={cachedDevice}/>
                    <DashboardStatusBox device={cachedDevice}/>
                </div>
                <DeviceLog className="d-block col-lg-4 mt-3 mt-lg-0"
                           requestLogData={requestLogData} logList={logList} device={currentDevice}/>
            </div>

            {currentDevice.serial_number === deviceSerialNumber ?
                <TelemetryCharts className="boxstyling p-3 mb-2" device={currentDevice}
                                 graphSelections={selectedDashboardGraphs}
                                 graphOptionList={isAwareDevice(currentDevice.serial_number) ? awareGraphOptionList : jeGraphOptionList}
                                 setGraphSelections={setGraphSelections}
                                 defaultGraphList={defaultGraphList}/>
                :
                <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                    <Spinner style={{height: '60px', width: '60px'}} animation="border" variant="primary"
                             size="lg"></Spinner>
                </div>
            }

        </IfDeviceFound>
    )
}
