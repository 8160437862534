import {createSlice} from "@reduxjs/toolkit";
import {uniq} from "lodash";

/***************** STATE ****************/
const initialState = {
    loading: [], // [string]
    loadingError: [], // [string]
    globalErrors: [], // [string]
};

/************ STATE SLICE **************/

const loadingSlice = createSlice({
    name: 'loading',
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = uniq([...state.loading, action.payload])
        },
        removeLoading(state, action) {
            state.loading = state.loading.filter(obj => obj !== action.payload)
        },
        resetLoading(state) {
            state.loading = []
        },
        setLoadingError(state, action) {
            state.loadingError = uniq([...state.loadingError, action.payload])
        },
        removeLoadingError(state, action) {
            state.loadingError = state.loadingError.filter(obj => obj !== action.payload)
        },
        pushGlobalError(state, action) {
            state.globalErrors = uniq([...state.globalErrors, action.payload])
        },
        removeGlobalError(state, action) {
            state.globalErrors = state.globalErrors.filter(obj => obj !== action.payload)
        }
    }
})

/**** EXPORTED ACTIONS AND REDUCERS ****/

export default loadingSlice.reducer;
export const {
    setLoading,
    removeLoading,
    resetLoading,
    setLoadingError,
    removeLoadingError,
    pushGlobalError,
    removeGlobalError
} = loadingSlice.actions;
