import React from 'react'
import Form from 'react-bootstrap/Form'

/**
 * **InputGroup** component, renders an input group with an input control and corresponding Feedback for handling invalid inputs
 *
 * @param {string} className classes to pass to the Form.Group
 * @param {label} label to display title above the input box
 * @param {boolean} disabled property to control the state of the Form.Control
 * @param {boolean} isInvalid to inform about the validity of the entry
 * @param {boolean} isValid to inform about the validity of the entry
 * @param {string} type to decide the type of the input (e.g. text, password, email)
 * @param {string} name of the input box
 * @param {string} value is a value that can be passed to the input
 * @param {function(onChange)} inputValue function to forward the input on change
 * @param {string} placeholder to set the default text in the input if value is empty
 * @param {string} errorText to set the feedback text if isInvalid is true
 * @param {boolean} hasLabel determines if the label element should be added to the dom
 * @param {object} sx style to pass to the Form.Group
 */


function InputGroup({className, label, disabled, isInvalid, type, name, value,
                        inputValue, placeholder, errorText, hasLabel = true, sx = {}}) {
    return (
        <Form.Group className={className} style={sx}>
            {hasLabel && <Form.Label>{label}</Form.Label>}
            <Form.Control className="shadow-none remove-autofill-overlay" disabled={disabled} isInvalid={isInvalid}
                          type={type} name={name} value={value} onChange={inputValue} placeholder={placeholder}/>
            <Form.Control.Feedback type="invalid" tooltip>
                {errorText}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default InputGroup