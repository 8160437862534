import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {allowedPageRoles, emptyGroupObject, rootObject} from "../../helpers/constants"
import TopInformation from '../../components/shared/topinformation/TopInformation'
import AddGroupModal from '../../components/management/addgroupmodal/AddGroupModal'
import InformationModal from '../../components/shared/informationmodal/InformationModal'
import {
    constructPath,
    findDeviceOrGroup,
    findGroup,
    createPathStringFromArray,
    pick,
    removeGroupFromHierarchy,
    stringValidator
} from '../../helpers/helperfunctions.js'
import {createGroup, deleteGroupFromHierarchy, updateHierarchy} from '../../helpers/reduxstore/reducers/groupReducer'
import GroupTreeviewSelector from '../../components/management/grouptreeviewselector/GroupTreeviewSelector.js'
import GroupDetailBox from '../../components/management/groupdetailbox/GroupDetailBox'
import {cloneDeep, uniqBy} from "lodash"
import useSetLocation from "../../helpers/hooks/useSetLocation.js"
import useNavigationGuardByRole from "../../helpers/hooks/useNavigationGuardByRole.js"

/** Renders the groups page, which inherits functionality to edit the group hierarchy - as well as individual groups and devices */
export default function GroupsPage() {
    useSetLocation()
    const {t} = useTranslation(['grouppage', 'inputerrors'])
    useNavigationGuardByRole(allowedPageRoles.GroupsPage)
    const [addGroupModalVisibility, setAddGroupModalVisibility] = useState(false)
    const [deleteGroupModalVisibility, setDeleteGroupModalVisibility] = useState(false)
    const [selectedData, setSelectedData] = useState(emptyGroupObject)
    const [selectedNodeList, setSelectedNodeList] = useState([])
    const [siblingDevices, setSiblingDevices] = useState([])
    const [siblingGroups, setSiblingGroups] = useState([])
    const [selectedGroupPath, setSelectedGroupPath] = useState('')
    const [addGroupNameError, setAddGroupNameError] = useState('')
    const [editingTree, setEditingTree] = useState(false)
    const [editedGroupHierarchy, setEditedGroupHierarchy] = useState([])
    const [editList, setEditList] = useState([])
    const dispatch = useDispatch()
    const {allowedGroups} = useSelector(state => state.group)

    useEffect(() => {
        if (selectedData.id) {
            setSelectedItem(selectedData)
            setSelectedNodeList([selectedData])
        }
        setEditedGroupHierarchy(allowedGroups)
    }, [allowedGroups])

    /** Sets the object currently selected in the tree. The function accounts for selecting the top group (which has no id)
     * @param {object} item selected item which bubbles from the tree */
    const setSelectedItem = (item) => {
        if (item.id === "") {
            setSelectedData(rootObject)
            resetSelections()
        } else {
            const foundItem = findDeviceOrGroup(item.id, item.type, allowedGroups)
            if (foundItem) {
                let parent = findGroup(foundItem.parent, allowedGroups)
                setSelectedGroupPath(createPathStringFromArray(constructPath(parent, allowedGroups), foundItem.name))
                const [deviceSiblings, groupSiblings] = getSiblings(parent, foundItem, allowedGroups)
                setSiblingDevices(deviceSiblings)
                setSiblingGroups(groupSiblings)
                setSelectedData(foundItem)
            } else {
                setSelectedData(rootObject)
                resetSelections()
            }
        }
    }

    /** Resets the selected data */
    const resetSelections = () => {
        setSiblingDevices([])
        setSiblingGroups([])
        setSelectedGroupPath('')
        setSelectedNodeList([])
    }

    /** Gets the siblings associated with a given item selected in the tree
     * @param {object} parent parent object of the selected item (Used to get sibling devices and groups)
     * @param {object} object Current group or device selected (Is excluded in getting siblings)
     * @param {object} source Source array of groups to get items from
     * @returns Array with device siblings and group siblings
     */
    const getSiblings = (parent, object, source) => {
        let deviceSiblings = []
        let groupSiblings = []

        let groupIdToExclude = (object.type === "group") ? object.id : ""
        let deviceIdToExclude = (object.type === "device") ? object.id : ""

        if (!parent || parent.type === 'root') {
            parent = {children: source, devices: []}
        }
        for (let child of parent.children) {
            if (groupIdToExclude !== child.id) {
                groupSiblings.push(child)
            }
        }
        for (let device of parent.devices) {
            if (deviceIdToExclude !== device.id) {
                deviceSiblings.push(device)
            }
        }
        return [deviceSiblings, groupSiblings]
    }

    /** Checks if the given name of a group, which is being edited, has siblings with the same name
     * @param {*} groupName The group name, which is given on submitting edits
     * @param {*} devices Array of sibling devices
     * @param {*} children Array of sibling groups
     * @returns {string} error message
     */
    const checkChildrenSameName = (groupName, devices, children) => {
        let childrenNames = children.map(obj => {
            return obj.name.toLowerCase()
        })
        childrenNames = childrenNames.concat(devices.map(device => {
            return device.name.toLowerCase()
        }))

        let isEmpty = stringValidator(groupName)
        if (isEmpty) {
            return isEmpty
        }
        if (childrenNames.includes(groupName.toLowerCase())) {
            return t('inputerrors:name_error')
        }
    }

    /** Adds a group in the backend
     * @param {string} groupDescription Given group description
     * @param {string} groupName Given group name
     */
    const addGroup = (groupDescription, groupName) => {
        let error = checkChildrenSameName(
            groupName, selectedData.id === "" ? [] : selectedData.devices, allowedGroups)
        if (!error) {
            dispatch(createGroup(selectedData.id, groupDescription, groupName))
            setAddGroupModalVisibility(false)
        } else {
            setAddGroupNameError(error)
        }
    }

    /** Deletes a selected group in the hierarchy */
    const deleteGroup = () => {
        if (selectedData.type === "group") {
            dispatch(deleteGroupFromHierarchy(selectedData.id))
            setSelectedData(emptyGroupObject)
            resetSelections()
        }
        setDeleteGroupModalVisibility(false)
    }

    /** Moves a dropped group or device(list) to a target group and keeps an array of edits upon editing the tree
     * @param {object|[object]} droppedItem dropped item
     * @param {object} targetGroup target group on which the object is dropped
     */
    const handleItemDrop = (droppedItem, targetGroup) => {
        if (!Array.isArray(droppedItem) && droppedItem.type === "device") droppedItem = [droppedItem]

        if (droppedItem.parent !== targetGroup.id && droppedItem.id !== targetGroup.id) {
            if (Array.isArray(droppedItem)) {
                setEditList(uniqBy([...editList, ...droppedItem.map(item => {
                    return {droppedItem: item, targetItem: targetGroup}
                })], 'droppedItem.id'))
                moveDeviceListToTargetGroup(droppedItem, targetGroup)
                setSelectedNodeList(selectedData ? [selectedData] : [])
            } else {
                setEditList(uniqBy([...editList, {
                    droppedItem: droppedItem, targetItem: targetGroup
                }], 'droppedItem.id'))
                moveGroupToParentGroup(droppedItem, targetGroup)
            }
        }
    }

    /** Updates a group position in the hierarchy
     * @param {object} droppedGroup item which is dropped
     * @param {object} targetParentGroup item on which a group is dropped */
    const moveGroupToParentGroup = (droppedGroup, targetParentGroup) => {
        let tempGroupHierarchy = cloneDeep(editedGroupHierarchy)
        removeGroupFromHierarchy(tempGroupHierarchy, droppedGroup)
        addChildToParentGroup(droppedGroup, targetParentGroup, tempGroupHierarchy)
        setEditedGroupHierarchy(tempGroupHierarchy)
    }

    /** Adds a child to a target group when an item is dropped
     * @param {object} groupToAdd item to add as a child to the target group
     * @param {object} targetParentGroup object which the item is dropped on
     * @param {*} source source array */
    const addChildToParentGroup = (groupToAdd, targetParentGroup, source) => {
        groupToAdd.parent = targetParentGroup.id
        if (targetParentGroup.id === "") {
            source.push(groupToAdd)
        } else {
            let parent = findGroup(targetParentGroup.id, source)
            parent.children.push(groupToAdd)
        }
    }

    /** Moves list of devices to target parent group
     * @param {*} droppedDeviceList Devices to move
     * @param {*} targetGroup Groups to add devices to */
    const moveDeviceListToTargetGroup = (droppedDeviceList, targetGroup) => {
        let tempGroupHierarchy = cloneDeep(editedGroupHierarchy)
        for (const droppedItem of droppedDeviceList) {
            removeChildDeviceParent(droppedItem, tempGroupHierarchy)
            addDeviceToTargetGroup(droppedItem, targetGroup, tempGroupHierarchy)
        }
        setEditedGroupHierarchy(tempGroupHierarchy)
    }

    /** Method ensures to add a device to a target item
     * @param {*} device device to add to the target item
     * @param {*} targetGroup Target group which the device is dropped on
     * @param {*} tempHierarchy array of items in the hierarchy subject to being edited
     */
    const addDeviceToTargetGroup = (device, targetGroup, tempHierarchy) => {
        const deviceCopy = cloneDeep(device)
        let parent = findGroup(targetGroup.id, tempHierarchy)
        deviceCopy.parent = parent.id
        parent.devices.push(deviceCopy)
    }

    /** Removes a device from its current position in the hierarchy
     * @param {*} deviceToRemove device to remove
     * @param {*} source array of source groups upon editing the hierarchy
     */
    const removeChildDeviceParent = (deviceToRemove, source) => {
        let deviceFound = false

        for (const element of source) {
            for (let j = 0; j < element.devices.length; j++) {
                if (element.devices[j].id === deviceToRemove.id) {
                    element.devices.splice(j, 1)
                    deviceFound = true
                    break
                }
            }
            if (deviceFound) {
                break
            } else if (element.children.length > 0) {
                removeChildDeviceParent(deviceToRemove, element.children)
            }

        }
    }

    /** Saves the edits made to the hierarchy */
    const saveEdits = () => {
        const newEditList = alterAndPrepareEdits()
        if (newEditList.length > 0) {
            console.log(`Saving ${newEditList.length} group edits: `, JSON.stringify(newEditList))
            dispatch(updateHierarchy(editedGroupHierarchy, newEditList))
        }
        resetTree()
    }

    /** Prepares array of objects to be sent to the API - The function ensures that no duplicates exist in the array,
     * i.e item dropped 2 places (only send last position) and ensures that no objects are sent to edit,
     * which are dropped the same place as their origional position. The function uses the state **edits**,
     * which hold records of all edits made during editing the hierarchy. */
    const alterAndPrepareEdits = () => {
        const tempEdits = uniqBy(editList, "droppedItem.id")
        const finalEdits = []

        // find item in non-edited allowedGroups hierarchy, to check whether same parent
        for (let i = 0; i < tempEdits.length; i++) {
            let edit = tempEdits[i]
            let uneditedItem = findDeviceOrGroup(edit.droppedItem.id, edit.droppedItem.type, allowedGroups)
            let editedItem = findDeviceOrGroup(edit.droppedItem.id, edit.droppedItem.type, editedGroupHierarchy)
            if (uneditedItem?.parent !== editedItem?.parent) {
                finalEdits.push({
                    droppedItem: pick(editedItem, "id", "type", "parent"),
                    targetItem: pick(edit.targetItem, "id", "type", "parent")
                })
            }
        }
        return finalEdits
    }

    /** resets all states and edits made upon editing the tree */
    const resetTree = () => {
        setEditingTree(false)
        setEditedGroupHierarchy(allowedGroups)
        setEditList([])
        setSelectedNodeList(selectedData ? [selectedData] : [])
    }

    return (
        <div className='d-flex flex-column flex-grow-1'>
            <InformationModal show={deleteGroupModalVisibility} accept={deleteGroup}
                              dismiss={() => setDeleteGroupModalVisibility(false)}
                              title={t('grouppage:group_management.delete_modal.title')}
                              body={t('grouppage:group_management.delete_modal.body')}/>
            <AddGroupModal dismiss={() => setAddGroupModalVisibility(false)} show={addGroupModalVisibility}
                           addGroup={addGroup} errorText={addGroupNameError}/>
            <TopInformation className='row' title={t('grouppage:info_section.info_section_title')}
                            subTitle={t('grouppage:info_section.info_section_p')}/>
            <div className="py-4 d-xs-block d-md-flex">
                <div className='boxstyling ps-4 pe-4 me-md-3 w-md-500px' style={{width: editingTree ? '900px' : ''}}>
                    <GroupTreeviewSelector selectedData={selectedData} cancelEdits={resetTree} saveEdits={saveEdits}
                                           selectedNodeList={selectedNodeList}
                                           setSelectedNodeList={setSelectedNodeList}
                                           isEditEnabled={editingTree} dropItem={handleItemDrop}
                                           editing={(isEditing) => setEditingTree(isEditing)}
                                           groupData={editedGroupHierarchy}
                                           itemSelect={(object) => setSelectedItem(object)}
                                           addGroup={() => {
                                               setAddGroupModalVisibility(true)
                                               setAddGroupNameError('')
                                           }}
                                           deleteGroup={() => setDeleteGroupModalVisibility(true)}/>
                </div>
                <div className='boxstyling ps-4 pe-4 mt-xs-3' style={{minHeight: "500px", flex: '1'}}>
                    {selectedData.id &&
                        <GroupDetailBox isEditingDisabled={!editingTree} groupSiblings={siblingGroups}
                                        deviceSiblings={siblingDevices} groupData={selectedData}
                                        groupPath={selectedGroupPath}/>
                    }
                </div>
            </div>
        </div>
    )
}