import {GroupNavigationPath} from "../../../components/shared/groupnavigationpath/GroupNavigationPath.js";
import {prependZeroes} from "../../../helpers/helperfunctions.js";
import React from "react";

export function DevicePagesTopInformation({device}) {
    return <div className='mt-md-4'>
        <div data-testid='title' className='title-font'>{device.name}</div>
        <GroupNavigationPath device={device}/>
        <div>{device.model} - S/N: {prependZeroes(device.serial_number)}</div>
    </div>
}