import React from 'react'
import Form from 'react-bootstrap/Form'

/** Renders an input group with an input control and corresponding Feedback for handling invalid inputs
 *
 * @param {string} className classes to pass to the Form.Group
 * @param {label} label to display title above the input box
 * @param {boolean} disabled property to control the state of the Form.Control
 * @param {boolean} isInvalid to inform about the validity of the entry
 * @param {boolean} checked current value
 * @param {function(onChange)} onChange function to forward the input on change
 * @param {array[{type: '', text: ''}]} messages warning or error messages to show
 * @param {JSX.Element} infoelement
 */
export default function SwitchGroup({className, label, disabled, checked, onChange, messages = [], infoelement}) {
    const getErrorOrWarningClass = () => {
        if (messages.some(m => m.type === "error")) return "is-error"
        if (messages.some(m => m.type === "warning")) return "is-warning"
        return ""
    }
    return (
        <Form.Group className={className}>
            {label &&
                <div className='mb-2 position-relative'><span dangerouslySetInnerHTML={{__html: label}}></span>{infoelement && infoelement}</div>
            }
            <Form.Switch className={getErrorOrWarningClass()} name="switchlabel" disabled={disabled} checked={checked} onChange={onChange}/>
            {messages.map((object, i) => <div className={`color-${object.type}`} key={i}>{object.text}</div> )}
        </Form.Group>
    )
}