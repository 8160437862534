import TopInformation from "../../components/shared/topinformation/TopInformation.js";
import useNavigationGuardByRole from "../../helpers/hooks/useNavigationGuardByRole.js";
import {roleConstants} from "../../helpers/constants.js";
import {getAdminOperations, getAdminOperationsLogins} from "../../api/api.js";
import {useEffect, useState} from "react";
import AdminOperationsEmail from "./email/AdminOperationsEmail";
import AdminOperationsJob from "./joblog/AdminOperationsJob";
import AdminOperationsNavBar from "./AdminOperationsNavBar.js";
import AdminOperationsAudit from "./audit/AdminOperationsAudit.js";
import {useDispatch} from "react-redux";
import {useMsal} from "@azure/msal-react";
import {fetchAdministeredUsersList} from "../../helpers/reduxstore/reducers/userAdministrationReducer.js";
import AdminOperationsStatistics from "./statistics/AdminOperationsStatistics";
import {useNavigate, useParams} from "react-router-dom";
import AdminOperationsLogin from './logins/AdminOperationsLogin.js'

export default function AdminOperationsPage() {
    useNavigationGuardByRole([roleConstants.Admin])
    const navigate = useNavigate();
    const {subPage} = useParams();

    const dispatch = useDispatch()
    const {accounts} = useMsal();
    const [rawResults, setRawResults] = useState({emailsSentList: [], jobLogList: []})
    const [rawLoginList, setRawLoginList] = useState([])
    useEffect(() => {
        getAdminOperations().then(res => setRawResults(res))
        getAdminOperationsLogins().then(res => setRawLoginList(res))
        if (accounts[0]) dispatch(fetchAdministeredUsersList())
    }, [])

    return (
        <div>
            <TopInformation title={'Driftoversigt'} className={'mb-3'}
                            subTitle={'Denne side indeholder en oversigt driftsmæssigt interessant ting. Lige nu jobs kørt, emails sendt og audit. Kun Admins har adgang til denne side. Hvis nogen har forslag til ændringer eller anden indsigt i systemet der kunne være interessant at have her må I meget gerne skrive til mig (Martin, mra@raunsoft.dk).'}/>

            <AdminOperationsNavBar rawResults={rawResults} currentTab={subPage}
                                   setCurrentTab={tab => navigate(`/adminoperations/${tab}`)}/>

            {subPage === "joblog" && <AdminOperationsJob rawJobList={rawResults.jobLogList}/>}
            {subPage === "emaillog" && <AdminOperationsEmail rawEmailList={rawResults.emailsSentList}/>}
            {subPage === "loginlog" && <AdminOperationsLogin rawLoginList={rawLoginList}/>}
            {subPage === "auditlog" && <AdminOperationsAudit/>}
            {subPage === "statistics" && <AdminOperationsStatistics/>}

        </div>
    )
}