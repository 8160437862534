import {DashCircleFill, PlusCircle} from "react-bootstrap-icons";
import React from "react";
import * as PropTypes from "prop-types";
import {ParameterNode} from "./ParameterNode.js";
import {toggleCollapsed} from "../../../helpers/reduxstore/reducers/technicalParamsReducer.js";
import {useMsal} from "@azure/msal-react";
import {isAdmin, isTechnicalAdvanced} from "../../../helpers/authHelper.js";
import {useDispatch, useSelector} from "react-redux";

CategoryNode.propTypes = {
    node: PropTypes.shape({
        children: PropTypes.array,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }),
    selectedParamId: PropTypes.any,
    indent: PropTypes.number.isRequired,
    showId: PropTypes.bool
};

export function CategoryNode({node, selectedParamId, indent, showId = true}) {
    const dispatch = useDispatch()
    const {accounts} = useMsal();
    const {openNodeIds} = useSelector(state => state.technicalParams)

    const isVisibleToRole = () => {
        if (isAdmin(accounts)) return true
        if (isTechnicalAdvanced(accounts)) return node.isVisibleToTechnicalAdvanced === undefined || node.isVisibleToTechnicalAdvanced === true
        return node.isVisibleToTechnicalBasic
    }
    if (!isVisibleToRole()) return ""

    return <>
        <li id={node?.id} className="text-noselect list-unstyled cursor-pointer hover-effect text-secondary"
            style={{padding: '1px'}}
            onClick={() => dispatch(toggleCollapsed(node.id))}
        >
            {!openNodeIds?.[node.id] ? <PlusCircle display='inline' className='me-2'/> :
                <DashCircleFill display='inline' className='me-2'/>}
            <span className='text-bold text-black'>{node.name}</span>
        </li>
        <ul className="unstyled ps-3 ms-2 border-start">
            {openNodeIds?.[node.id] && node.children.map((child, idx) => child.type === 'category'
                ?
                <CategoryNode node={child} selectedParamId={selectedParamId}
                              indent={indent + 1} showId={showId}
                              key={`${indent + 1}-${idx}`}></CategoryNode>
                : <ParameterNode node={child} selectedParamId={selectedParamId} showId={showId}
                                 key={`${indent + 1}-${idx}`}></ParameterNode>)}
        </ul>
    </>
}