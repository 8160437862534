import React from 'react'
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from 'react-bootstrap/Popover';

/** Infoicon which expands into infomodal with title and jsxbody when triggered
 * @param {String} popOverTitel Title of the popover
 * @param {String | JSXElement} popOverContent body of the popover
 * @param {[String]} trigger triggers for popup. Can be one or more of ['click', 'touch', 'hover']. Probably more as well.
 * @param {boolean} contentAsHtml
 * @param {object} style
 * @param {string} size either 'lg' or 'md'
 * @param {string} buttonPosition
 */
export default function PopOverDialog({
                                          popOverTitel, popOverContent,
                                          trigger = ['touch', 'hover'], contentAsHtml = false,
                                          style = {}, size = 'md', buttonPosition = 'position-relative'
                                      }) {
    return (
        <OverlayTrigger trigger={trigger} placement="top" overlay={
            <Popover className={`shadow ${size === 'lg' ? 'default-width-lg' : 'default-width'}`} id="popover-basic">
                <Popover.Header className='text-white default-header-color' as="h5"><span
                    dangerouslySetInnerHTML={{__html: popOverTitel}}/></Popover.Header>
                {!contentAsHtml && <Popover.Body>{popOverContent}</Popover.Body>}
                {contentAsHtml && <Popover.Body dangerouslySetInnerHTML={{__html: popOverContent}}></Popover.Body>}
            </Popover>
        }>
            <Button variant="popover" style={style}
                    className={"button-pos d-inline-flex align-items-left-top shadow-none " + buttonPosition} size="sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                     className="bi bi-question-circle popover-fill" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>
            </Button>
        </OverlayTrigger>
    )
}