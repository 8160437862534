/** Render help video item
 * @param children
 * @param {string} title
 * @param {string} description
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
export default function HelpContentContainer({children, title, description, className}) {
    return <div className={`d-flex flex-column boxstyling mb-xs-2 ${className}`} style={{padding: '20px 50px'}}>
        <div className='font-weight-500' style={{fontSize: "46px"}}>{title}</div>
        <div className='mb-2 font-weight-500'>{description}</div>
        <div className='w-100 bg-secondary mb-3' style={{height: '1px'}} />
        {children}
    </div>
}


