import React from 'react'
import {DashCircle, PlusCircle} from 'react-bootstrap-icons'
import NotificationsItemDeviceNode from './NotificationsItemDeviceNode.js'

/** Represents an individual group node in the hierarchy - Each node will render additional nodes if either devices or group children exists
 * @param {object} node object for the actual node and its corresponding devices and children
 * @param {function} setGroupOpen function that takes groupnode and sets it as open or not open
 * @param {object} selectedNode currently selected node
 * @param {function} selectNode Method to call when the node has been selected
 */
export default function NotificationsItemGroupNode({node, setGroupOpen, selectedNode, selectNode}) {
    return (
        <React.Fragment key={node.id}>
            <li
                onClick={() => {
                    setGroupOpen(node, !node.isOpen)
                    selectNode(node)
                }}
                className={`text-noselect list-unstyled cursor-pointer hover-effect ${selectedNode?.id === node.id ? 'node-active' : ''}`}>
                {node.isOpen
                    ? <DashCircle display='inline' className='me-2'/>
                    : <PlusCircle display='inline' className='me-2'/>}
                {node.name}
            </li>
            <ul className={`${node.isOpen ? '' : 'listelement-hidden'} unstyled ps-3 ms-2 border-start mb-0`}>
                {node.isOpen && node.children.map(childGroup =>
                    <NotificationsItemGroupNode key={"frag-grp" + childGroup.id} node={childGroup}
                                                setGroupOpen={setGroupOpen}
                                                selectedNode={selectedNode} selectNode={selectNode}/>
                )}
                {node.isOpen && node.devices.map(device =>
                    <NotificationsItemDeviceNode key={"frag-dev" + device.id} node={device}
                                                 selectedNode={selectedNode} selectNode={selectNode}/>
                )}
            </ul>
        </React.Fragment>
    )
}
