import React, {useEffect, useState} from 'react'
import {Container} from 'react-bootstrap'
import InformationModal from '../../../components/shared/informationmodal/InformationModal'
import InputTextArea from '../../../components/shared/inputtextarea/InputTextArea'
import {useTranslation} from 'react-i18next';
import InputGroup from "../../shared/inputgroup/InputGroup.js";

/** Renders with input fields to add a new group. Allows to specify groupname and description.
 * @param {function} addGroup Method to call upon adding a group (Passes name and description)
 * @param {boolean} show Controls whether the modal should be displayed
 * @param {function} dismiss Method to call when the modal is closed (Callback show)
 * @param {string} errorText property depicting whether the typed-in name has an error
 */
export default function AddGroupModal({addGroup, show, dismiss, errorText}) {
    const {t} = useTranslation(['grouppage'])
    const [groupName, setGroupName] = useState("")
    const [groupDescription, setGroupDescription] = useState("")

    useEffect(() => {
        setGroupName("")
        setGroupDescription("")
    }, [show])

    return <InformationModal dialogClassName='group-selector-modal' show={show}
                             title={t('grouppage:group_management.create_new_group_modal.modal_title')}
                             body={
                                 <Container className="py-2">
                                     <b className='mb-1'>{t('grouppage:group_management.create_new_group_modal.inputs.labels.groupname')}</b>
                                     <InputGroup className={'mb-2'} value={groupName} name="groupName"
                                                 inputValue={(e) => setGroupName(e.target.value)}
                                                 isInvalid={!!errorText} errorText={errorText} hasLabel={false}/>
                                     <b className='mb-1'>{t('grouppage:group_management.create_new_group_modal.inputs.labels.description')}</b>
                                     <InputTextArea value={groupDescription} className="" type='text'
                                                    name='description' inputValue={(e) => setGroupDescription(e)}
                                                    isCounterHidden={false} textBoxLimit={100}/>
                                 </Container>
                             }
                             size='lg'
                             accept={() => addGroup(groupDescription, groupName)}
                             dismiss={() => dismiss()}/>

}