import React from 'react'
import {XLg} from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {useTranslation} from 'react-i18next';

/** Modal component which has a dark backdrop. The component provides flexibility in terms of the content to show in the body
 * The primary use-case for this modal is general information
 * @param dialogClassName
 * @param {boolean} show decides if the modal should be shown or not
 * @param {string | JSX.Element} title sets the title of the modal
 * @param {JSX.Element} body injects html into the body of the modal
 * @param {function(callback)} dismiss to return a callback to dismiss the modal
 * @param {function} accept on accept click function
 * @param {string} size
 * @param {boolean} acceptBtnDisabled
 * @param {string} accepBtnText
 */
export default function InformationModal({
                                             dialogClassName, show, title, body,
                                             dismiss, accept, size = 'md', acceptBtnDisabled = false, accepBtnText = undefined
                                         }) {
    const {t} = useTranslation(['common'])
    return <Modal animation={false} show={show} onHide={dismiss} size={size}
                  dialogClassName={dialogClassName}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
        <Modal.Header className='py-2'>
            <Modal.Title className='flex-grow-1'>{title}</Modal.Title>
            <div id='information-modal-dismiss' className='p-1 cursor-pointer' onClick={dismiss}><XLg/></div>
        </Modal.Header>
        <Modal.Body className='py-4 py-xs-2'>{body}</Modal.Body>
        <Modal.Footer>
            <Button variant='outline-secondary' className='col-4' onClick={accept} disabled={acceptBtnDisabled}>
                {accepBtnText ?? t('common:buttons.accept')}
            </Button>
        </Modal.Footer>
    </Modal>
}