import React from 'react'
import useSetLocation from "../../../helpers/hooks/useSetLocation.js";
import useNavigationGuardByRole from "../../../helpers/hooks/useNavigationGuardByRole.js";
import {allowedPageRoles} from "../../../helpers/constants.js";
import {useParams} from "react-router-dom";
import {isAwareDevice} from "../../../helpers/helperfunctions.js";
import OldDeviceParametersPage from "./OldDeviceParametersPage";
import GeneratedDeviceParametersPage from "./GeneratedDeviceParametersPage.js";

export default function DeviceParametersPage() {
    useSetLocation()
    useNavigationGuardByRole(allowedPageRoles.DeviceParametersPage)
    const {deviceSerialNumber} = useParams();
    return isAwareDevice(deviceSerialNumber) ? <GeneratedDeviceParametersPage/> : <OldDeviceParametersPage/>
}
