import {createSlice} from "@reduxjs/toolkit";
import {createUser, deleteUser, editExternalUser, getAdministeredUsersList, reInviteUser} from "../../../api/api"
import {loaders, toastTypes} from "../../constants";
import {removeLoading, setLoading, setLoadingError} from "./loadingReducer";
import {roleIdToText} from "../../authHelper.js";
import {addToast} from "./toastReducer.js";
import {cloneDeep} from 'lodash'

/***************** STATE ****************/
const initialState = {
    administeredUsers: []
};

/************ STATE SLICE **************/

const userAdministrationSlice = createSlice({
    name: 'userAdministration',
    initialState: initialState,
    reducers: {
        addAdministeredUser(state, action) {
            let exists = false
            const previousState = cloneDeep(state.administeredUsers)
            for (let user of previousState) {
                if (user.userId === action.payload.userId) {
                    exists = true
                }
            }
            !exists && state.administeredUsers.push(action.payload);
        },
        refreshAdministeredUsers(state, action) {
            state.administeredUsers = action.payload;
        },
        updateAdministeredUser(state, action) {
            const previousState = JSON.parse(JSON.stringify(state.administeredUsers))
            for (let user of previousState) {
                if (user.userId === action.payload.userId) {
                    user.groups = action.payload.groups
                    if (action.payload.preferredLanguage) {
                        user.userData.preferredLanguage = action.payload.preferredLanguage
                    }
                    if (action.payload.role) {
                        user.userData.appRole = roleIdToText(action.payload.role)
                    }
                }
            }
            state.administeredUsers = previousState
        },
        deleteAdministeredUser(state, action) {
            const previousState = JSON.parse(JSON.stringify(state.administeredUsers))
            for (let i = 0; i < previousState.length; i++) {
                if (previousState[i].userId === action.payload) {
                    previousState.splice(i, 1)
                }
            }
            state.administeredUsers = previousState
        }
    }
})

/**** EXPORTED ACTIONS AND REDUCERS ****/

export default userAdministrationSlice.reducer;
export const {
    addAdministeredUser,
    updateAdministeredUser,
    deleteAdministeredUser,
    refreshAdministeredUsers
} = userAdministrationSlice.actions;

/*************** THUNKS ***************/

/** Gets users which resides in the current users' administered groups and updates state with users
 * - Populates an array with objects for each user
 * @returns dispatch - updates the administeredUsers state */
export const fetchAdministeredUsersList = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.getUsers))
            dispatch(refreshAdministeredUsers(await getAdministeredUsersList()))
        } catch (error) {
            console.log(error)
            dispatch(setLoadingError(loaders.getUsers))
        } finally {
            dispatch(removeLoading(loaders.getUsers))
        }
    }
}

/** Calls api to alter the groups for the userId and updates the state on success response. If the given user is appointed an admin role, groups will be replaced with an empty array.
 * @param {[]} groupIdList Array of groups that the user should have access to
 * @param {string} targetUser id of the user to edit
 * @param {string} userRole role of the target user
 * @param {string} preferredLanguage system language for the target user
 */
export const editUserAndGroups = (groupIdList, targetUser, userRole, preferredLanguage) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.editUser))
            await editExternalUser(targetUser, groupIdList, userRole, preferredLanguage)
            dispatch(updateAdministeredUser({
                "userId": targetUser,
                "groups": (userRole === process.env.REACT_APP_APP_ADMIN) ? [] : groupIdList,
                "preferredLanguage": preferredLanguage,
                "role": userRole
            }))
        } catch (error) {
            console.error(error)
            dispatch(setLoadingError(loaders.editUser))
        } finally {
            dispatch(removeLoading(loaders.editUser))
        }
    }
}
/** Adds a user in AD, assigns user to groups in cosmos and updates administered users on success response
 * @param {[string]} selectedGroupIdList Ids of Groups assigned to the user
 * @param {string} mail Mail of the user
 * @param {string} role System role of the user (Not role ID)
 * @param {string} preferredLanguage Preferred language of the user
 * @returns dispatch
 */
export const AddUser = (selectedGroupIdList, mail, role, preferredLanguage) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.addUser))
            let newUser = await createUser(selectedGroupIdList, mail, role, preferredLanguage)
            if (mail !== newUser.userData.mail) {
                dispatch(addToast({
                    id: "NewUserEmailIsDifferentFromRequestedEmail",
                    type: toastTypes.warning,
                    title: "User email is different from requested email",
                    body: `Requested email was '${mail}' but users assigned email is '${newUser.userData.mail}'. This may be because Azure already knows user. User should log in using '${newUser.userData.mail}'.`,
                    timeSeconds: 60
                }))
            }
            dispatch(addAdministeredUser(newUser))
        } catch (error) {
            if (error.message.includes("Group email address is not supported")) {
                dispatch(addToast({
                    id: "GroupEmailAddressIsNotSupported",
                    type: toastTypes.error,
                    title: "Group Email Adress Not Supported",
                    body: `An error happened while inviting user. '${mail}' is a group (shared) email. Inviting this type of email is not supported.`,
                    timeSeconds: 10
                }))
            } else {
                dispatch(setLoadingError(loaders.addUser))
            }
        } finally {
            dispatch(removeLoading(loaders.addUser))
        }
    }
}
/** Deletes a user AD, removes the user from groups in cosmos and updates list of administered users
 * @param {string} userId Id of the user
 * @returns dispatch
 */
export const deleteUserAndGroups = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.deleteUser))
            await deleteUser(userId)
            dispatch(deleteAdministeredUser(userId))
        } catch (error) {
            console.error(error)
            dispatch(setLoadingError(loaders.deleteUser))
        } finally {
            dispatch(removeLoading(loaders.deleteUser))
        }
    }
}
/** Resends invitation to a user
 * @param {string} mail mail of the user
 * @returns dispatch
 */
export const sendInvitation = (mail) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.inviteUser))
            await reInviteUser(mail)
        } catch (error) {
            console.error(error)
            dispatch(setLoadingError(loaders.inviteUser))
        } finally {
            dispatch(removeLoading(loaders.inviteUser))
        }
    }
}




