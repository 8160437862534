import SettingsContent from '../../../components/settings/settingscontent/SettingsContent.js';
import React from 'react'
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import NavTopBar from "../../../components/shared/device/NavTopBar.js";
import useSetLocation from "../../../helpers/hooks/useSetLocation.js";
import useInterval from "../../../helpers/hooks/useInterval.js";
import {loadCurrentDeviceWrapper} from "../../../helpers/deviceHelper.js";
import {IfDeviceFound} from "../../../components/dashboard/IfDeviceFound.js";
import {DevicePagesTopInformation} from "../technical/DevicePagesTopInformation.js";
import {useTranslation} from "react-i18next";
import {AnyReportedParameters} from "../../../helpers/helperfunctions.js";

/** Displays the settings page for an individual device */
export default function DeviceConfigurationsPage() {
    const {t} = useTranslation(['settingspage'])
    useSetLocation()
    const {deviceSerialNumber} = useParams();
    const {currentDevice} = useSelector(g => g.device)
    const dispatch = useDispatch()

    useInterval(async () => {
        loadCurrentDeviceWrapper(currentDevice, deviceSerialNumber, dispatch)
    }, 5000)

    return (
        <IfDeviceFound>
            <div className='d-flex flex-column flex-grow-1'>
                <DevicePagesTopInformation device={currentDevice}/>
                <NavTopBar deviceSerialNumber={deviceSerialNumber} currentPage='configurations'></NavTopBar>

                {(!currentDevice.connected || !AnyReportedParameters(currentDevice)) && <div className='d-flex flex-grow-1 bg-white rounded-3 p-3 mb-3'>
                    <div>{!currentDevice.connected ? t('settingspage:settings_not_available_device_offline') : t('settingspage:settings_not_available_no_parameters')}</div>
                </div>}
                {(currentDevice.connected && AnyReportedParameters(currentDevice)) && <SettingsContent device={currentDevice}/>}
            </div>
        </IfDeviceFound>
    )
}
