import {CircleFill, Wrench} from "react-bootstrap-icons";
import React from "react";
import * as PropTypes from "prop-types";
import {selectParameterId} from "../../../helpers/reduxstore/reducers/technicalParamsReducer.js";
import {useMsal} from "@azure/msal-react";
import {isTechnicalBasic} from "../../../helpers/authHelper.js";
import {useDispatch} from "react-redux";

ParameterNode.propTypes = {
    node: PropTypes.shape({
        type: PropTypes.string.isRequired,
        data: PropTypes.any
    }),
    selectedParamId: PropTypes.any,
    showId: PropTypes.bool
};

export function ParameterNode({node, selectedParamId, showId = true}) {
    const dispatch = useDispatch()
    const {accounts} = useMsal();
    const isVisibleToRole = () => {
        if (!isTechnicalBasic(accounts)) return true
        // from here its technicalBasic user
        return node.isVisibleToTechnicalBasic
    }
    if (!isVisibleToRole()) return ""

    const getIdText = () => {
        if (node.data.type === 'ip_address_half')
            return `${node.data.id}+${node.data.id + 1}`
        return node.data.id;
    }
    return <li style={{fontSize: '17px'}}
               className={`text-noselect list-unstyled cursor-pointer hover-effect d-flex align-items-center ${node.data.id === selectedParamId ? 'text-secondary' : ''}`}
               onClick={() => dispatch(selectParameterId(node.data.id))}>
        <Wrench className='d-xs-none me-2'/>
        {showId && <span style={{opacity: '0.5'}} className='me-1'>{getIdText()}</span>}
        <span className={`${node.data.id === selectedParamId ? '' : ''} me-1 text-hidden-elipsis`}>
            {node.data.name_orig}</span> = <span
        className={`ms-1 ${getParameterNodeValue(node) === "NOT FOUND" ? 'color-danger' : ''}`}>{getParameterNodeValue(node)}</span>
        {node.data?.messages?.some(m => m.type === 'warning') &&
            <CircleFill className='color-warning ms-auto me-1' style={{height: "10px"}}/>}
        {node.data?.messages?.some(m => m.type === 'danger') &&
            <CircleFill className='color-danger ms-auto me-1' style={{height: "10px"}}/>}
    </li>
}

function getParameterNodeValue(node) {
    if (node.data.type === 'enum') return node.data.options[node.data.value] ?? 'NOT FOUND'
    if (node.data.value === undefined) return 'NOT FOUND'
    if (node.data.type === 'bool') return node.data.value ? "True" : "False"
    if (node.data.type === 'ip_address_half' || node.data.type === 'bitmask' || node.data.type === 'string') return node.data.value
    return `${node.data.value} ${node.data.unit}`;
}
