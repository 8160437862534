import React from 'react'

/** Renders the informationbox with text that describes the context of individual pages
* @param {string} body text in the top of the modal */
export default function ModalTopText({ body, className }) {
    return (
        <div className={className}>
            <p className='mb-1 border border-1 rounded p-2 shadow-sm' style={{background: '#f1f8ff', borderColor: '#e9e9e9'}}>{body}</p>
        </div>
    )
}