import { useContext } from 'react'
import { AccordionContext } from 'react-bootstrap';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { useAccordionButton } from 'react-bootstrap/esm/AccordionButton';

export default function MobileToggle({ eventKey, callback, clearSelection, targetArr, hasError }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className={`cursor-pointer px-2 py-2 ${hasError && "bg-ltred"}`} onClick={decoratedOnClick}>
            <div className='col-8 d-inline-block'>
                {isCurrentEventKey && <ChevronDown />}
                {!isCurrentEventKey && <ChevronRight />} Selected items
            </div>
            <div className='col-4 text-end d-inline-block'>
                <button disabled={!targetArr.length > 0} className={`${(targetArr.length > 0) ? "opacity-100" : "opacity-0"} btn btn-sm btn-danger shadow-none`} onClick={(e) => { e.stopPropagation(); clearSelection() }}>Clear all</button>
            </div>
        </div>
    );
}