import React from 'react'
import Form from 'react-bootstrap/Form'
import {getBorderErrorOrWarningClassFromMessage} from "../../../helpers/uiHelper.js";

/** Form group with corresponding label and automatic population of options, with an optional array of messages
 * @param {string} className styles the entire group
 * @param {string} label sets the label
 * @param {boolean} disabled disables the combobox
 * @param {string | number} value sets the value of the combobox, given there pre-exists a value
 * @param {string} name sets the name of the combobox
 * @param {function(inputValue)} inputValue to forward the input from the combobox on change
 * @param {array[{id: '', option: ''}]} options selections for the combobox
 * @param {string} blankChoice sets the text of the placeholder selection
 * @param {boolean} hasLabel determines if the label should be removed completely
 * @param sm
 * @param {array[{type: '', text: ''}]} messages warning or error messages to show
 * @param {JSX.Element} infoelement
 * @param {object} style
 */
export default function ComboBoxWithMessages({ className, label, disabled, value, name, inputValue, options, blankChoice = "", hasLabel = true, sm = false, messages = [], infoelement = null, style={}}) {
    return (
        <Form.Group className={className} style={style}>
            {hasLabel && <div className='mb-2'><span dangerouslySetInnerHTML={{__html: label}}></span>{infoelement && infoelement}</div>}
            <Form.Control as="select" className={`form-select shadow-none ${sm ? 'btn-sm' : 'btn-md'} ${getBorderErrorOrWarningClassFromMessage(messages)}`} disabled={disabled} value={value} name={name} id={name} onChange={inputValue}>
                <option key='blankChoice' hidden value>{blankChoice}</option>
                {options.map(function (e) {
                    if(e.disabled){
                        return <option value={e.id} key={e.id} disabled>{e.option}</option>
                    }
                    return <option value={e.id} key={e.id}>{e.option}</option>
                })}
            </Form.Control>
            {messages.map((object, i) => <div className={`color-${object.type}`} key={i}>{object.text}</div> )}
        </Form.Group>
    )
}