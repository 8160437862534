import Button from 'react-bootstrap/Button'
import React from 'react'
import {useTranslation} from "react-i18next";

/** @param {function} cancelClick
 * @param {function} submitClick
 * @param {boolean} submitActive
 * @param {function} deleteClick
 * @param {boolean} deleteClickActive
 * @returns {Element}
 */
export default function GroupDetailButtonGroup({
                                                   cancelClick, submitClick, submitActive = true,
                                                   deleteClick, deleteClickActive = false
                                               }) {
    const {t} = useTranslation(['common', 'grouppage'])
    return (
        <>
            {deleteClick && deleteClickActive &&
                <Button variant="danger" onClick={deleteClick} disabled={!submitActive}>
                    {t('common:buttons.delete')}
                </Button>
            }
            <div>
                <Button variant='outline-secondary' onClick={submitClick} disabled={!submitActive}
                        type="submit" className='me-2'>{t('common:buttons.save')}</Button>
                <Button variant='outline-secondary' onClick={cancelClick}
                        type="submit">{t('common:buttons.cancel')}</Button>
            </div>
        </>
    )
}