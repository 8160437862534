import {CategoryNode} from "../../../components/parameters/hierarchy/CategoryNode.js";
import React from "react";

export function ParametersTreeView({hierarchyList, selectedParameterId, showId}) {
    return <div className="boxstyling me-md-2 p-2" style={{flex: '1', maxWidth: '650px'}}>
        <div className="hierarchy-inactive" style={{height: '500px'}}>
            <ul className={"unstyled ps-2 mb-0"}>
                {hierarchyList.map((node, idx) =>
                    <CategoryNode key={`0-${idx}`} node={node} selectedParamId={selectedParameterId} indent={0}
                                  showId={showId}/>)}
            </ul>
        </div>
    </div>
}