import {createSlice} from "@reduxjs/toolkit"

/***************** STATE ****************/
const initialState = {
    selectedDashboardGraphs: null, // null means that no graphs have ever been selected, so we show default in TelemetryCharts.js
    selectedTechnicalGraphs: null
}

/************ STATE SLICE **************/
const telemetrySlice = createSlice({
    name: 'telemetry',
    initialState: initialState,
    reducers: {
        updateSelectedDashboardGraphs(state, action) {
            state.selectedDashboardGraphs = action.payload
        },
        updateSelectedTechnicalGraphs(state, action) {
            state.selectedTechnicalGraphs = action.payload
        }
    }
})


/**** EXPORTED ACTIONS AND REDUCERS ****/
export default telemetrySlice.reducer
export const {updateSelectedDashboardGraphs, updateSelectedTechnicalGraphs} = telemetrySlice.actions
