import {createSlice} from "@reduxjs/toolkit";
import {getParameterToShow} from "../../settingsHelper.js";
import {cloneDeep} from "lodash";

/***************** STATE ****************/
const initialState = {
    selectedParameterId: -1,
    hierarchyList: [],
    paramSpec: null,
    openNodeIds: {}
};

/************ STATE SLICE **************/
const technicalParamsSlice = createSlice({
    name: 'technicalParams',
    initialState,
    reducers: {
        initTechnicalParamsState(state, action) {
            state.hierarchyList = action.payload.hierarchyList
            state.paramSpec = action.payload.paramSpec
        },
        updateTechnicalParamsState(state, action) {
            const {device, t} = action.payload
            const nodeToUpdateList = findLeafNodesInHierarchy(state.hierarchyList)
            for (const nodeToUpdate of nodeToUpdateList) {
                const [value, messages] = getValueAndMessagesForNode(nodeToUpdate, device, t)
                nodeToUpdate.data.value = value
                nodeToUpdate.data.messages = messages
            }
        },
        toggleCollapsed(state, action) {
            const openNodeIdsClone = cloneDeep(state.openNodeIds ?? {})
            if (openNodeIdsClone[action.payload]) {
                delete openNodeIdsClone[action.payload]
            } else {
                openNodeIdsClone[action.payload] = action.payload
            }
            state.openNodeIds = openNodeIdsClone
        },
        selectParameterId(state, action) {
            state.selectedParameterId = action.payload
        }
    }
})

export function findLeafNodesInHierarchy(hierarchyList, resultNodeList = []) {
    for (const node of hierarchyList) {
        if (node.type === 'category') {
            findLeafNodesInHierarchy(node.children, resultNodeList)
        }
        if (node.type === 'parameter') {
            resultNodeList.push(node)
        }
    }
    return resultNodeList
}

export function findNodeInHierarchyById(nodeList, nodeToFindId) {
    // nodeToFindId can be 0, so can't just go !nodeToFindId in conditions below since !0 evals to true
    if (!nodeList || nodeToFindId === undefined || nodeToFindId === null || nodeToFindId === -1) return null;
    for (const node of nodeList) {
        if (node.id === nodeToFindId) return node
        if (node.data && node.data.id === nodeToFindId) return node
        const foundNode = findNodeInHierarchyById(node.children, nodeToFindId)
        if (foundNode) return foundNode
    }
    return null;
}

function getValueAndMessagesForNode(nodeToUpdate, device, t) {
    if (nodeToUpdate.data.type === 'ip_address_half') {
        // Ip addresses are creatively packed into two subsequent parameterlist rows
        // The calculations below turn something like values 65535 and 65280 into ip adress: 255.255.255.0
        let primaryParam = getParameterToShow(device, nodeToUpdate.data.id, t);
        const firstComponent = Math.floor(primaryParam.value / 256)
        const secondComponent = primaryParam.value % 256
        let extraParam = getParameterToShow(device, nodeToUpdate.data.id + 1, t);
        const thirdComponent = Math.floor(extraParam.value / 256)
        const fourthComponent = extraParam.value % 256
        return [`${firstComponent}.${secondComponent}.${thirdComponent}.${fourthComponent}`, primaryParam.messages]
    }

    // if not ip address, just get values like normal
    let parameterToShowViaId = getParameterToShow(device, nodeToUpdate.data.id, t);
    return [parameterToShowViaId.value, parameterToShowViaId.messages]
}


/**** EXPORTED ACTIONS AND REDUCERS ****/
export default technicalParamsSlice.reducer;
export const {
    initTechnicalParamsState,
    updateTechnicalParamsState,
    toggleCollapsed,
    selectParameterId
} = technicalParamsSlice.actions;
