export default function FromToDatePickers({className, headline, monthSpan, yearSpan, fromDate, toDate, setFromDate, setToDate}) {
    const getIsoDate = (months = 0, year = 0) => {
        const today = new Date()
        today.setMonth(today.getMonth() - months)
        today.setFullYear(today.getFullYear() - year)
        let yyyy = today.getFullYear()
        let mm = (today.getMonth() + 1).toString().padStart(2, '0')
        let dd = today.getDate().toString().padStart(2, '0')
        return `${yyyy}-${mm}-${dd}`
    }

    return (
        <div className={className}>
            {headline && <span className="col-12 text-bold pb-3">{headline}</span>}
            <input type="date" className="form-control shadow-none text-muted" value={fromDate}
                   onChange={e => setFromDate(e.target.value)}
                   max={(getIsoDate())} min={getIsoDate(monthSpan, yearSpan)}
                   aria-describedby="setFromDate"/>
            <span className='text-center text-bold mx-2 d-xs-none'>-</span>
            <input type="date" className="form-control shadow-none text-muted" value={toDate}
                   onChange={e => setToDate(e.target.value)}
                   min={fromDate}
                   max={getIsoDate()}
                   aria-describedby="setToDate"/>
        </div>
    )
}
