import React, {useEffect, useState} from 'react'
import {Droplet, EmojiFrown, EmojiNeutral, EmojiSmile, Wind} from 'react-bootstrap-icons';
import {useTranslation} from 'react-i18next';
import {isAwareDevice, telemetryColorsForDevice} from '../../../helpers/helperfunctions';
import {deviceParams} from '../../../helpers/constants';
import QualityNumberBox from './qualitynumberbox/QualityNumberBox';
import PercentageBox from './percentagebox/PercentageBox';
import {awareTelemNames} from "../../parameters/awareTelemSpec.js";

/** Renders the "telemetry boxes" for the device dashboard. The component determines if either voc, co2 and humidity sensor is installed and what the current air quality is for each individual sensor (signified by color)
 * @param {object} device device
 * @param {string} className classes added to the component */
export default function TelemetryBoxes({device, className}) {
    const {t} = useTranslation(['dashboard', 'common'])
    const [CO2Style, setCO2Style] = useState({bg: "", dim: "", smiley: ''})
    const [VOCStyle, setVOCStyle] = useState({bg: "", dim: "", smiley: ''})

    const co2ActiveText = <span dangerouslySetInnerHTML={{__html: t('dashboard:telemetryBoxes.current_co2')}}></span>
    const co2DefectText = <span>CO₂ {t("dashboard:telemetryBoxes.sensor_defect")}</span>
    const co2NotInstalledText = <span>CO₂ {t("dashboard:telemetryBoxes.sensor_not_installed")}</span>;

    let co2Value = isAwareDevice(device.serial_number) ? device?.latest_telemetry?.[awareTelemNames.co2_ar] : device?.latest_telemetry?.[deviceParams.co2];
    let tvocValue = isAwareDevice(device.serial_number) ? device?.latest_telemetry?.[awareTelemNames.tvoc_ar] : device?.latest_telemetry?.[deviceParams.voc];
    useEffect(() => {
        let telemetryColors = telemetryColorsForDevice(device);
        setCO2Style(telemetryColors.getCO2ColorObj(co2Value))
        setVOCStyle(telemetryColors.getTVOCColorObj(tvocValue))
    }, [device])

    function getShowAirflowDefect() {
        return device?.hardware_errors?.HWError_SupplyflowSensor1 || device?.hardware_errors?.HWError_SupplyflowSensor2 ||
            device?.hardware_errors?.HWError_ExtractionflowSensor || device?.hardware_errors?.HWError_SupplyFan ||
            device?.hardware_errors?.HWError_ExtractionFan;
    }

    function getAirflowDefectText() {
        if (!device?.hardware_errors) return ""
        return Object.keys(device?.hardware_errors).map(key => t(`telemetry:${key}`)).join(", ")
    }

    function getSmileyIcon(smiley) {
        if (smiley === "happy")
            return <EmojiSmile width='22px' height='22px' viewBox='0 0 16 16' style={{verticalAlign: 'unset'}}/>
        if (smiley === "mid")
            return <EmojiNeutral width='22px' height='22px' viewBox='0 0 16 16' style={{verticalAlign: 'unset'}}/>
        return <EmojiFrown width='22px' height='22px' viewBox='0 0 16 16' style={{verticalAlign: 'unset'}}/>
    }

    function getRoundedValue(value) {
        if (!value) return "-"
        return Math.round(value);
    }

    return (
        <div className={className}>
            <PercentageBox
                className='align-top d-inline-block'
                defectText={`${t('dashboard:telemetryBoxes.airflow_sensor_defect')}: ${getAirflowDefectText()}`}
                activeTitleText={t('dashboard:telemetryBoxes.current_flow')}
                icon={<Wind width='22px' height='22px' viewBox='0 0 16 15' style={{verticalAlign: 'unset'}}/>}
                sensorValue={getRoundedValue(device?.latest_telemetry?.[deviceParams.airflow])}
                valuePostFix='%'
                hasError={getShowAirflowDefect()}
                deviceConnected={device.connected}
            />
            {device.installed_components.humidity_control_sensor &&
                <PercentageBox
                    className={`align-top d-inline-block`}
                    defectText={t('dashboard:telemetryBoxes.humidity_sensor_defect')}
                    activeTitleText={t('dashboard:telemetryBoxes.current_humidity')}
                    icon={<Droplet width='22px' height='22px' viewBox='0 3 16 11' style={{verticalAlign: 'unset'}}/>}
                    sensorValue={getRoundedValue(device?.latest_telemetry?.[deviceParams.humidity])}
                    valuePostFix='%'
                    hasError={device?.hardware_errors?.HWError_HumidityExtractionAirSensor}
                    deviceConnected={device.connected}
                />
            }
            {(device.installed_components.co2_sensor || device.installed_components.tvoc_sensor) &&
                <>
                    <QualityNumberBox
                        className={`align-top d-inline-block ${!device?.installed_components.co2_sensor ? 'd-xs-none' : ''}`}
                        isInstalled={device?.installed_components.co2_sensor}
                        hasError={device?.hardware_errors?.HWError_CO2Sensor} valuePostFix='PPM'
                        activeTitleText={co2ActiveText} defectText={co2DefectText}
                        notInstalledText={co2NotInstalledText}
                        sensorValue={co2Value}
                        darkColor={CO2Style.bg} lightColor={CO2Style.dim}
                        icon={getSmileyIcon(CO2Style.smiley)} deviceConnected={device.connected}
                        limit={2000} limitColor={CO2Style.dimDarker}
                    />
                    {(device.installed_components.tvoc_sensor) &&
                        <QualityNumberBox
                            className={`align-top d-inline-block ${!device?.installed_components.tvoc_sensor ? 'd-xs-none' : ''}`}
                            isInstalled={device?.installed_components.tvoc_sensor}
                            hasError={false}
                            valuePostFix='PPB'
                            activeTitleText={t('dashboard:telemetryBoxes.current_voc')}
                            defectText={`${t('telemetry:1074')} ${t('dashboard:telemetryBoxes.sensor_defect')}`}
                            notInstalledText={`${t('telemetry:' + deviceParams.voc)} ${t('dashboard:telemetryBoxes.sensor_not_installed')}`}
                            sensorValue={tvocValue}
                            darkColor={VOCStyle.bg} lightColor={VOCStyle.dim}
                            icon={getSmileyIcon(VOCStyle.smiley)} deviceConnected={device.connected}
                        />
                    }
                </>
            }
        </div>
    )
}