import React from 'react'
import DeviceTimersSettingsWithPeriods from "./DeviceTimersSettingsWithPeriods.js";

/** Displays the configuration page for setpoints settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device)
 */
export default function DeviceTimersSettings({device, className, save}) {
    // const {accounts} = useMsal();

    // I think everyone is allowed to see periods at this point, just basic users and lower are not allowed to edit non-active
    return <DeviceTimersSettingsWithPeriods className={className} device={device} save={save}/>

    // return <DeviceTimersSettingsSimple className={className} device={device} save={save}/>
    // if (!featureFlags.calendar) {
    //     return <DeviceTimersSettingsSimple className={className} device={device} save={save}/>
    // }
    //
    // // To make sure that premium and basic users are looking at approximately the same things and that information
    // //  is not hidden from basic users we allow basic users to see periods if more than default exists, but only change the
    // //  currently active period. The rest are grayed out.
    // const showPeriods = device?.period_list?.length > 1 || hasAnyRole(accounts, allowedPageRoles.TimerPeriodEdit)
    // return (
    //     <React.Fragment>
    //         {showPeriods && <DeviceTimersSettingsWithPeriods className={className} device={device} save={save}/>}
    //         {!showPeriods && <DeviceTimersSettingsSimple className={className} device={device} save={save}/>}
    //     </React.Fragment>
    // )

}