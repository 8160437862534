import { createSlice } from "@reduxjs/toolkit";
import { getDeviceLog } from "../../../api/api";
import { loaders } from "../../constants";
import { removeLoading, setLoading, setLoadingError } from "./loadingReducer";

/***************** STATE ****************/
const initialState = {
    deviceLog: []
};

/************ STATE SLICE **************/
const deviceLogSlice = createSlice({
    name: 'deviceLog',
    initialState: initialState,
    reducers: {
        updateDeviceLog(state, action) {
            state.deviceLog = action.payload
        }
    }
})

/**** EXPORTED ACTIONS AND REDUCERS ****/
export default deviceLogSlice.reducer;
export const { updateDeviceLog } = deviceLogSlice.actions;

/*************** THUNKS ***************/
export const fetchDeviceLog = (deviceid, from, to, types) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(loaders.getDeviceLog))
            let data = await getDeviceLog(types ?? [], deviceid, from, to)
            dispatch(updateDeviceLog(data))
        } catch (error) {
            dispatch(setLoadingError(loaders.getDeviceLog))
        } finally {
            dispatch(removeLoading(loaders.getDeviceLog))
        }
    }
}