import GroupsFilterSelector from '../../../components/shared/groupsfilterselector/GroupsFilterSelector.js'
import InformationModal from '../../../components/shared/informationmodal/InformationModal'
import ModalTopText from '../../../components/shared/modaltoptext/ModalTopText'
import React, {useState} from 'react'
import {useTranslation} from "react-i18next";

/** Displays the group modal to use in order to select multiple groups of devices to configure
 * @param {boolean} show determines if the modal should be shown
 * @param {function} dismiss function to call to dismiss the modal
 * @param {function(object[])} setSelectedGroupIds set the groups selected
 * @param {function} accept triggers saving the selection
 * @returns
 */
export default function SaveForGroupsModal({show, dismiss, setSelectedGroupIds, accept}) {
    const {t} = useTranslation(['settingspage'])
    const [acceptBtnDisabled, setAcceptBtnDisabled] = useState(true)

    return (
        <InformationModal
            accept={accept}
            dismiss={dismiss}
            show={show}
            size='lg'
            acceptBtnDisabled={acceptBtnDisabled}
            body={
                <React.Fragment>
                    <ModalTopText body={t('settingspage:modals.saveforgroup.body')}/>
                    <GroupsFilterSelector selectItem={groups => setSelectedGroupIds(groups.map(g => g.id))}
                                          notifyTargetChanges={arr => setAcceptBtnDisabled(arr.length === 0)}
                                          dropzoneBody={t('settingspage:dropzone.body')}/>
                </React.Fragment>
            }
        />
    )
}