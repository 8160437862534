import React from 'react'
import { ExclamationTriangle} from 'react-bootstrap-icons'
import {useParams} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {useTranslation} from "react-i18next";

/** Renders a component for the device status component, which displays a popover if there are any warnings, alarms or errors on the device
 * @param {string[]} deviceAlarms list of alarms for the device
 * @param {string[]} deviceWarnings list of warnings for the device
 * @param {string[]} hardwareErrors list of hardware errors for the device
 * @param {string[]} deviceProcesses List of device processes
 */
export default function DeviceStatusAbnormalities({
                                                      deviceAlarms,
                                                      deviceWarnings,
                                                      hardwareErrors,
                                                      deviceProcesses
                                                  }) {
    const {t} = useTranslation(["dashboard"])
    const {deviceSerialNumber} = useParams();

    function getAlarmTextOrLink(text) {
        if (text.toLocaleLowerCase() !== "filter alarm") return text
        return <a href={`/device/${deviceSerialNumber}/configurations/filter`}>{text}</a>
    }

    return (
        <div className='w-100 d-flex flex-wrap mt-1' style={{columnGap: '0.25rem', rowGap: '0.25rem'}}>
            {deviceProcesses.map(item => <div key={item}
                                              className='rounded p-1 px-2 d-flex align-items-center columns3 bg-success-dim'>
                <Spinner variant='primary' animation="border" size="sm" className="spinner-styling me-2"/>
                {t("dashboard:status.process", {process: item})}
            </div>)}
            {deviceAlarms.map(item => <div key={item}
                                           className='rounded p-1 px-2 d-flex align-items-center columns3 bg-danger-dim'>
                <ExclamationTriangle className='me-1 color-danger'/> {getAlarmTextOrLink(item)}
            </div>)}
            {hardwareErrors.map(item => <div key={item}
                                             className='rounded p-1 px-2 d-flex align-items-center columns3 bg-warning-dim'>
                <ExclamationTriangle className='me-1 color-warning'/> {item}
            </div>)}
            {deviceWarnings.map(item => <div key={item}
                                             className='rounded p-1 px-2 d-flex align-items-center columns3 bg-warning-dim'>
                <ExclamationTriangle className='me-1'/> {item}
            </div>)}
        </div>
    )
}